import { RootState } from "..";
import { IFailureMode } from "../../utilities/types/FailureMode";


export function selectorGetFailureModesByModelId(state: RootState, modelId: string) : IFailureMode[] {

    if (!state.failureModes || !state.failureModes.byId) return [];

    return sortFailureModesByOrderNumberAscending(Object.values(state.failureModes.byId).filter(x => x.modelId === modelId));
}


export function selectorGetFailureModesByFunctionalFailureId(state: RootState, functionalFailureId: string) : IFailureMode[] {

    if (!state.failureModes || !state.failureModes.byId) return [];

    return sortFailureModesByOrderNumberAscending(Object.values(state.failureModes.byId).filter(x => x.functionalFailureId === functionalFailureId));
}


export function selectorGetFailureModeById(state: RootState, failureModeId: string) : IFailureMode | undefined {

    if (!state.failureModes || !state.failureModes.byId) return undefined;

    return state.failureModes.byId[failureModeId];
}


// function sortFailureModesByOrderNumberDescending(failureModes: IFailureMode[]) {
//     return failureModes.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortFailureModesByOrderNumberAscending(failureModes: IFailureMode[]) {
    return failureModes.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.name.toLowerCase < a.name.toLowerCase) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}