import { IDecisionAssetInputFieldMapping } from "../../utilities/types/DecisionAssetInputFieldMapping";


export const ACTIONS_DECISION_ASSET_INPUT_FIELD_MAPPING = {
    INVALIDATE: 'INVALIDATE_DECISION_ASSET_INPUT_FIELD_MAPPINGS',
    RECEIVE: 'RECEIVE_DECISION_ASSET_INPUT_FIELD_MAPPINGS',
    DELETE: 'DELETE_DECISION_ASSET_INPUT_FIELD_MAPPING',
    UPDATE: 'UPDATE_DECISION_ASSET_INPUT_FIELD_MAPPINGS',
}

interface ActionDecisionAssetInputFieldMappingsInvalidate {
    type: typeof ACTIONS_DECISION_ASSET_INPUT_FIELD_MAPPING.INVALIDATE,
    byId: { [key: string]: IDecisionAssetInputFieldMapping },
}

interface ActionDecisionAssetInputFieldMappingsReceive {
    type: typeof ACTIONS_DECISION_ASSET_INPUT_FIELD_MAPPING.RECEIVE
    byId: { [key: string]: IDecisionAssetInputFieldMapping },
}

export interface DecisionAssetInputFieldMappingState {
    byId: { [key: string]: IDecisionAssetInputFieldMapping },
}

export enum DecisionAssetInputFieldMappingSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesDecisionAssetInputFieldMapping = ActionDecisionAssetInputFieldMappingsReceive | ActionDecisionAssetInputFieldMappingsInvalidate;