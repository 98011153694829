import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchSolversProps } from "../../redux/solver/actions";
import { ISolverDetails } from "../../utilities/types/SolverDetails";
import { fetchSearchSolverDetails } from "../../redux/solverDetails/actions";


export interface IUseFetchSolversPageHookProps extends IFetchSearchSolversProps {
    minPageNumberToFetch: number;
}


export const useFetchSolverDetailsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, solverId, text }: IUseFetchSolversPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [allResultsSet, setAllResultsSet] = useState<ISolverDetails[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var solverDetails = await dispatch(fetchSearchSolverDetails({
                    pageSize,
                    pageNumber,
                    solverId,
                    text,
                })) as unknown as ISolverDetails[];

                if (solverDetails && solverDetails.length) {
                    setMorePages(solverDetails.length >= pageSize)
                    setAllResultsSet(solverDetails);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, solverId, text]);

    // Merge any new result sets with existing

    return {
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}