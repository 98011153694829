import { RootState } from "..";
import { IModelHistory } from "../../utilities/types/ModelHistory";


export function selectorGetModelHistoriesByModelId(state: RootState, modelId: string) : IModelHistory[] {

    if (!state.modelHistories || !state.modelHistories.byId) return [];

    return sortModelHistoriesByVersionNumberDescending(Object.values(state.modelHistories.byId).filter(x => x.modelId === modelId));
}


export function selectorGetMaxModelHistoryVersionNumber(state: RootState, modelId: string) {

    var modelHistories = selectorGetModelHistoriesByModelId(state, modelId);

    return modelHistories.length ? modelHistories[0].versionNumber : 0;
}


export function selectorGetModelHistoryById(state: RootState, modelHistoryId: string) : IModelHistory | undefined {

    if (!state.modelHistories || !state.modelHistories.byId) return undefined;

    return state.modelHistories.byId[modelHistoryId];
}


// function sortModelHistoriesByCreatedDescending(modelHistories: IModelHistory[]) {
//     return modelHistories.sort((a, b) => a.created.getTime() - b.created.getTime());
// }


function sortModelHistoriesByVersionNumberDescending(modelHistories: IModelHistory[]) {
    return modelHistories.sort((a, b) => b.versionNumber - a.versionNumber);
}