import { TableContainer, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import {
  DefaultFormatHead,
  ISolverUIResultSetTable as TableData,
  TableCellFormat,
} from "../../utilities/types/SolverUIResultSetTable";

export function RenderTable(tableData: TableData) {
  const headers = tableData.headers ?? [];
  const rows = tableData.data;

  return (
    <>
      {tableData.title && (
        <Typography variant="subtitle1" style={{ marginBottom: "0.25rem" }}>
          {tableData.title}
        </Typography>
      )}

      <TableContainer>
        <Table size="small">
          {headers.length > 0 && (
            <TableHead>
              <TableRow>
                {headers.map((h, hi) => (
                  <TableCell {...GetHeaderStyles(tableData, hi)}>{h}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {rows.map((row, ri) => (
              <TableRow>
                {row.map((d, ci) => (
                  <TableCell {...GetCellStyles(tableData, ri, ci)}>{d}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function GetRowStyles(metaData: TableData, rowIndex: number): TableCellProps {
  return toCellProps(metaData.formatRow?.[rowIndex]);
}

function GetColStyles(metaData: TableData, colIndex: number): TableCellProps {
  return toCellProps(metaData.formatCol?.[colIndex]);
}

function GetHeaderStyles(metaData: TableData, colIndex: number): TableCellProps {
  // Ensure falsey values like '{}' remove default styling. Only 'undefined' values should render the default.
  const rowStyles = toCellProps(metaData.formatHead === undefined ? DefaultFormatHead : metaData.formatHead);
  const colStyles = GetColStyles(metaData, colIndex);
  return mergeCellProps(rowStyles, colStyles);
}

function GetCellStyles(metaData: TableData, rowIndex: number, colIndex: number): TableCellProps {
  const rowStyles = GetRowStyles(metaData, rowIndex);
  const colStyles = GetColStyles(metaData, colIndex);
  return mergeCellProps(rowStyles, colStyles);
}

/** Deep merges two styles so undefined properies don't override values.
 * Defined properties from the second styles will take precedence.
 */
function mergeCellProps(styles1: TableCellProps, styles2: TableCellProps): TableCellProps {
  return {
    align: styles1.align || styles2.align,
    style: {
      fontWeight: styles1.style?.fontWeight || styles2.style?.fontWeight,
      backgroundColor: styles1.style?.backgroundColor || styles2.style?.backgroundColor,
    },
  };
}

/** Converts API properties to actual table cell component properties. */
function toCellProps(format: TableCellFormat | undefined): TableCellProps {
  return format
    ? {
        align: format.align,
        style: {
          fontWeight: format.fontWeight,
          backgroundColor: format.backgroundColor,
        },
      }
    : {};
}
