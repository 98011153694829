import React, { lazy, useState } from "react";
import {
  Typography,
  Button,
  Avatar,
  Grid,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel } from "../../utilities/types/Model";
import { IIdentifier } from "../../utilities/types/Identifier";
import { useFetchIdentifiersPageHook } from "./Hooks";
import { selectorGetIdentifiersByModelId } from "../../redux/identifier/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalIdentifierCreate from "./modals/ModalIdentifierCreate";
import { FileTypeEnum } from "../../utilities/types/File";
import { useFileSourceSingleHook } from "../file/Hooks";
import { fetchSearchIdentifiers } from "../../redux/identifier/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalIdentifierUpdate from "./modals/ModalIdentifierUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/LabelTwoTone";
import EditIcon from "@mui/icons-material/EditTwoTone";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ModalIdentifierToModeCreateMultiple from "../identifierMapping/modals/ModalIdentifierToModeCreateMultple";
import { extractFirstTwoCharacters } from "../../utilities/Helpers";
import SuspenseWrapper from "../generic/SuspenseWrapper";

const LazyModalLinkIdentifierToCauses = lazy(() => import("../identifierMapping/modals/ModalLinkIdentifierToCauses"));

const imageDiameter = 50;

const DivWrapper = styled("div")(({ theme }) => ({
  "& .identifierWrapper": {
    padding: theme.spacing(1),
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
    transition: "background 0.25s ease-out !important",
  },
  "& .flex-box": {
    display: "flex",
    alignItems: "stretch",
  },
  "& .identifierImageWrapper": {
    width: imageDiameter,
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .identifierMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .btnAddIdentifier": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

type IIdentifierModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
};

const IdentifierModelBuilderTab = ({ modelId, canEdit }: IIdentifierModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const { fetching } = useFetchIdentifiersPageHook({
    pageNumber: 1,
    pageSize: 100,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });
  const identifiers = useSelector((store: RootState) => selectorGetIdentifiersByModelId(store, modelId));
  const onUploadComplete = async (identifierId: string) => {
    await dispatch(fetchSearchIdentifiers({ identifierId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <IdentifierModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      identifiers={identifiers}
      loading={fetching}
      onUploadComplete={onUploadComplete}
    />
  );
};

type IIdentifierModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  identifiers: IIdentifier[];
  loading: boolean;
  onUploadComplete(identifierId: string): void;
};

const IdentifierModelBuilderTabDisplay = ({
  model,
  identifiers,
  loading,
  canEdit,
  onUploadComplete,
}: IIdentifierModelBuilderTabDisplayProps) => {
  const [showCreateIdentifierModal, setShowCreateIdentifierModal] = useState(false);
  const [identifierToUpdate, setIdentifierToUpdate] = useState<IIdentifier>();

  function toggleShowCreateIdentifierModal() {
    setShowCreateIdentifierModal(!showCreateIdentifierModal);
  }

  function onIdentifierClick(identifier: IIdentifier) {
    if (canEdit) setIdentifierToUpdate(identifier);
  }

  function hideIdentifierUpdate() {
    setIdentifierToUpdate(undefined);
  }

  if (!model) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <DivWrapper>
      <Button color="primary" variant="outlined" className="btnAddIdentifier" onClick={toggleShowCreateIdentifierModal}>
        Add Identifier
      </Button>
      <AnimationWrapper>
        <div>
          {identifiers.map((identifier) => {
            return (
              <React.Fragment key={identifier.identifierId}>
                <ModelBuilderIdentifier
                  identifier={identifier}
                  canEdit={canEdit}
                  onUploadComplete={onUploadComplete}
                  onIdentifierClick={onIdentifierClick}
                  modelId={model.modelId}
                />
                <Divider light={true} />
              </React.Fragment>
            );
          })}
          {(!identifiers || !identifiers.length) && (
            <WidgetNoResultsPlaceholder text="No identifiers" icon={PlaceholderIcon} flip={true} />
          )}
        </div>
      </AnimationWrapper>

      {loading ? <LoaderAbsoluteCentred loading={loading} /> : null}

      <ModalIdentifierCreate
        open={showCreateIdentifierModal}
        onCancelCallback={toggleShowCreateIdentifierModal}
        onCompleteCallback={toggleShowCreateIdentifierModal}
        modelId={model.modelId}
      />

      {/* Update */}
      {identifierToUpdate && (
        <ModalIdentifierUpdate
          open={identifierToUpdate !== undefined}
          onCancelCallback={hideIdentifierUpdate}
          onCompleteCallback={hideIdentifierUpdate}
          identifier={identifierToUpdate}
          canEdit={canEdit}
        />
      )}
    </DivWrapper>
  );
};

interface IModelBuilderIdentifierProps {
  identifier: IIdentifier;
  canEdit: boolean;
  onUploadComplete(identifierId: string): void;
  onIdentifierClick(identifier: IIdentifier): void;
  modelId: string;
}

function ModelBuilderIdentifier({ identifier, onIdentifierClick, canEdit, modelId }: IModelBuilderIdentifierProps) {
  const imageUrl = useFileSourceSingleHook({ fileId: identifier.mainImageId || "", fileType: FileTypeEnum.Image });
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showModal, toggleIdentifierToModesModal] = useState<boolean>();
  const [showCausesModal, toggleIdentifierToCausesModal] = useState<boolean>();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function onEditIdentifierClick() {
    setMenuAnchorEl(undefined);
    onIdentifierClick(identifier);
  }

  async function onLinkToModesIdentifierClick() {
    setMenuAnchorEl(undefined);
    toggleIdentifierToModesModal((prev) => !prev);
  }

  async function onLinkToCausesIdentifierClick() {
    setMenuAnchorEl(undefined);
    toggleIdentifierToCausesModal((prev) => !prev);
  }

  return (
    <Grid container className="identifierWrapper">
      <Grid item xs={1}>
        <div className="flex-box">
          <div className="identifierImageWrapper">
            {identifier.mainImageId ? (
              <Avatar alt={identifier.code} src={imageUrl} style={{ border: "1px solid rgba(0,0,0,0.1)" }} />
            ) : (
              <Avatar>{extractFirstTwoCharacters(identifier.code || identifier.name)}</Avatar>
            )}
          </div>

          <div
            style={{ width: 5, height: "auto", borderRadius: 28, background: identifier.color ?? "transparent" }}
          ></div>
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className="identifierMainWrapper">
          <div>
            <Typography variant="body1">{identifier.code}</Typography>
            <Typography variant="caption">{identifier.name}</Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={3} style={{ textAlign: "right" }}>
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(undefined)}
        >
          <MenuItem onClick={onEditIdentifierClick}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit identifier" />
          </MenuItem>
          <MenuItem onClick={onLinkToModesIdentifierClick}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Link to multiple modes" />
          </MenuItem>
          <MenuItem onClick={onLinkToCausesIdentifierClick}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Link to multiple causes" />
          </MenuItem>
        </Menu>
      </Grid>
      <LoaderAbsoluteCentred loading={loading} />
      {showModal && (
        <ModalIdentifierToModeCreateMultiple
          open={showModal}
          identifierId={identifier.identifierId}
          modelId={modelId}
          onCancelCallback={onLinkToModesIdentifierClick}
          onCompleteCallback={onLinkToModesIdentifierClick}
        />
      )}

      <SuspenseWrapper>
        {showCausesModal && (
          <LazyModalLinkIdentifierToCauses
            open={showCausesModal}
            identifierId={identifier.identifierId}
            identifierName={identifier.name}
            modelId={modelId}
            onCancelCallback={onLinkToCausesIdentifierClick}
            onCompleteCallback={onLinkToCausesIdentifierClick}
          />
        )}
      </SuspenseWrapper>
    </Grid>
  );
}

export default IdentifierModelBuilderTab;
