import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  IFetchSearchSingleAssetOutputProps,
  fetchSearchSingleAssetOutput,
} from "../../redux/singleAssetOutput/actions";
import { ISingleAssetOutput } from "../../utilities/types/SingleAssetOutput";

export interface IUseFetchSingleAssetOutputPageHookProps extends IFetchSearchSingleAssetOutputProps {
  minPageNumberToFetch: number;
}

export const useFetchSingleAssetOutputPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
}: IUseFetchSingleAssetOutputPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<ISingleAssetOutput[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<ISingleAssetOutput[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var singleAssetOutputs = (await dispatch(
          fetchSearchSingleAssetOutput({
            pageSize,
            pageNumber,
          })
        )) as unknown as ISingleAssetOutput[];

        if (singleAssetOutputs && singleAssetOutputs.length) {
          setMorePages(singleAssetOutputs.length >= pageSize);
          setLastResultSet(singleAssetOutputs);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.outputTypeId === x.outputTypeId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
