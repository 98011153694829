import React, { useState } from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import { IAssetInputFieldCategory } from "../../../utilities/types/AssetInputFieldCategory";
import {
  fetchUpdateAssetInputFieldCategory,
  fetchDeleteAssetInputFieldCategory,
} from "../../../redux/assetInputFieldCategory/actions";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";

interface FormValues {
  name: string;
  description?: string;
  orderNumber: number;
  assetInputFieldCategoryId: string;
}

interface FormProps {
  assetInputFieldCategoryId: string;
  name: string;
  description?: string;
  orderNumber: number;
  onCompleteCallback(assetInputFieldCategory?: IAssetInputFieldCategory): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IAssetInputFieldCategory, AnyAction>;
}

const FormAssetInputFieldCategoryUpdate = (formProps: FormProps) => {
  const { onCancelCallback } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var assetInputFieldCategory = await dispatch(
      fetchUpdateAssetInputFieldCategory({
        ...values,
      })
    );

    UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
    setSubmitting(false);
    onCompleteCallback(assetInputFieldCategory);
  };

  return (
    <Formik
      initialValues={{
        assetInputFieldCategoryId: formProps.assetInputFieldCategoryId,
        name: formProps.name,
        description: formProps.description,
        orderNumber: formProps.orderNumber,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          const { assetInputFieldCategoryId } = formProps;

          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteAssetInputFieldCategory({ assetInputFieldCategoryId }));
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
          }

          // Cleanup
          setDeleting(false);
          onCancelCallback();
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={formProps.assetInputFieldCategoryId} name="assetInputFieldCategoryId" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "name", "name")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete confirmation"
                  subtitle="Confirm category delete"
                  description="Are you sure that you'd like to remove this category?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          name: Yup.string()
            .label("Name")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a name"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          orderNumber: Yup.number().label("Order Number").min(0, "Zero or more.").max(999, "Less than 1000"),
        });
      }}
    />
  );
};

export default FormAssetInputFieldCategoryUpdate;
