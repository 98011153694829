import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { IComponent } from "../../utilities/types/Component";

export interface IFetchSearchAiSuggestComponentProps {
  pageNumber: number;
  pageSize: number;
  modelId: string;
}

export const fetchSearchAiSuggestComponent = async (searchParams: IFetchSearchAiSuggestComponentProps): Promise<IComponent[]> => {
  var headers = await GetDefaultHeaders(true, false, true);

  try {
    var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/AISuggest/Components`, searchParams), {
      method: "GET",
      headers: headers,
    });

    // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
    var parsedResp: IApiResponse = await CheckStatus(apiResponse);
    if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.components) {
      return parsedResp.data.components;
    } else {
      if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
        ShowError("Error searching ai suggest component.");
      }
      return [];
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      ShowExceptionAsMessage(e);
      console.log("Error searching ai suggest component.", e.stack);
    } else {
      // Handle other types of exceptions or unknown errors.
      console.error("Unknown error:", e);
    }
    return [];
  }
};
