import { RootState } from "..";
import { ISolverUIResultSet } from "../../utilities/types/SolverUIResultSet";


export function selectorGetSolverUIResultSetsBySolverJobTaskId(state: RootState, solverJobTaskId: string): ISolverUIResultSet[] {

    if (!state.SolverUIResultSets || !state.SolverUIResultSets.byId) return [];

    return Object.values(state.SolverUIResultSets.byId).filter(x => x.solverJobTaskId === solverJobTaskId);
}


export function selectorGetSolverUIResultSetsBySolverJobTaskIds(state: RootState, solverJobTaskIds: string[]): ISolverUIResultSet[] {

    if (!state.SolverUIResultSets || !state.SolverUIResultSets.byId) return [];

    return Object.values(state.SolverUIResultSets.byId).filter(x => solverJobTaskIds.some(y => y === x.solverJobTaskId));
}


export function selectorGetSolverUIResultSetById(state: RootState, SolverUIResultSetId: string): ISolverUIResultSet | undefined {

    if (!state.SolverUIResultSets || !state.SolverUIResultSets.byId) return undefined;

    return state.SolverUIResultSets.byId[SolverUIResultSetId];
}
