import { IComment } from "../../utilities/types/Comment";


export const ACTIONS_COMMENT = {
    INVALIDATE: 'INVALIDATE_COMMENTS',
    RECEIVE: 'RECEIVE_COMMENTS',
    DELETE: 'DELETE_COMMENT',
    UPDATE: 'UPDATE_COMMENTS',
}

interface ActionCommentsInvalidate {
    type: typeof ACTIONS_COMMENT.INVALIDATE,
    byId: { [key: string]: IComment },
}

interface ActionCommentsReceive {
    type: typeof ACTIONS_COMMENT.RECEIVE
    byId: { [key: string]: IComment },
}

export interface CommentState {
    byId: { [key: string]: IComment },
}

export enum CommentSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesComment = ActionCommentsReceive | ActionCommentsInvalidate;