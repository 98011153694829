import { IFunctionalFailure } from "../../utilities/types/FunctionalFailure";


export const ACTIONS_FUNCTIONAL_FAILURE = {
    INVALIDATE: 'INVALIDATE_FUNCTIONAL_FAILURES',
    RECEIVE: 'RECEIVE_FUNCTIONAL_FAILURES',
    DELETE: 'DELETE_FUNCTIONAL_FAILURE',
    UPDATE: 'UPDATE_FUNCTIONAL_FAILURES',
}

interface ActionFunctionalFailuresInvalidate {
    type: typeof ACTIONS_FUNCTIONAL_FAILURE.INVALIDATE,
    byId: { [key: string]: IFunctionalFailure },
}

interface ActionFunctionalFailuresReceive {
    type: typeof ACTIONS_FUNCTIONAL_FAILURE.RECEIVE
    byId: { [key: string]: IFunctionalFailure },
}

export interface FunctionalFailureState {
    byId: { [key: string]: IFunctionalFailure },
}

export enum FunctionalFailureSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesFunctionalFailure = ActionFunctionalFailuresReceive | ActionFunctionalFailuresInvalidate;