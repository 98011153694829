import React, { useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { DialogActions, Button, Grid, Divider } from "@mui/material";
import HeaderIcon from "@mui/icons-material/PersonTwoTone";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetUserRolePermissionsByRoleId } from "../../../redux/userRolePermission/selectors";
import UserRolePermissionPickList from "../UserRolePermissionPicklist";
import { UserPermissionTypeEnum } from "../../../utilities/types/UserPermission";
import { selectorGetUserRoleById } from "../../../redux/userRole/selectors";
import {
  fetchCreateUserRolePermissions,
  fetchUserRolePermissionsByRoleIdIfNeeded,
} from "../../../redux/userRolePermission/actions";

export interface IModalUserRolePermissionsSelectProps {
  open: boolean;
  onCancelCallback(): void;
  userRoleId: string;
}

function ModalUserRolePermissionsSelect({ onCancelCallback, open, userRoleId }: IModalUserRolePermissionsSelectProps) {
  const dispatch = useDispatch();
  const permissions = useSelector((store: RootState) => selectorGetUserRolePermissionsByRoleId(store, userRoleId));
  const userRole = useSelector((store: RootState) => selectorGetUserRoleById(store, userRoleId));
  const [loading, setLoading] = useState(false);
  const [selectedUserRolePermissions, setSelectedUserRolePermissions] = useState<number[]>([]);

  useEffect(() => {
    dispatch(fetchUserRolePermissionsByRoleIdIfNeeded(userRoleId));
  }, [userRoleId, dispatch]);

  async function onSave() {
    setLoading(true);

    var resp = await dispatch(
      fetchCreateUserRolePermissions({
        targetRoleId: userRoleId,
        userRolePermissionTypeIds: selectedUserRolePermissions,
      })
    );

    setLoading(false);

    if (resp != null) onCancelCallback();
  }

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={`User Role permissions`}
      subtitle={`Configure permissions for ${userRole?.userRoleDescription || "N/A"}.`}
      headerIcon={<HeaderIcon />}
      style={{ maxWidth: 1000, minWidth: 550 }}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <UserRolePermissionPickList
              userRoleId={userRoleId}
              onSelectedItemsChange={setSelectedUserRolePermissions}
              allUserRolePermissions={Object.values(UserPermissionTypeEnum)
                .filter((x) => !isNaN(Number(x)))
                .map((x) => Number(x))}
              userRolePermissionMappings={permissions.map((x) => Number(x.permission))}
              leftLabel="Available"
              rightLabel="Assigned"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider light={true} style={{ marginBottom: 16, marginTop: 8 }} />
      <DialogActions>
        <Button variant="outlined"  onClick={onCancelCallback} fullWidth={true}>
          Close
        </Button>
        <Button variant="contained" color="primary" onClick={onSave} fullWidth={true}>
          Save
        </Button>
      </DialogActions>
      <LoaderAbsoluteCentred loading={loading} />
    </WidgetModalBase>
  );
}

export default ModalUserRolePermissionsSelect;
