import { ActionTypesSummaryOutputJob, SummaryOutputJobState, ACTIONS_SUMMARY_OUTPUT_JOB } from "./types";

const initialState: SummaryOutputJobState = {
  byId: {},
};

export function summaryOutputJobReducer(
  state = initialState,
  action: ActionTypesSummaryOutputJob
): SummaryOutputJobState {
  switch (action.type) {
    case ACTIONS_SUMMARY_OUTPUT_JOB.INVALIDATE: {
      return {
        ...initialState,
      };
    }
    case ACTIONS_SUMMARY_OUTPUT_JOB.DELETE:
      let currentById = Object.assign({}, state.byId);
      delete currentById[Object.keys(action.byId)[0]];

      return {
        ...state,
        byId: currentById,
      };
    case ACTIONS_SUMMARY_OUTPUT_JOB.RECEIVE: {
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
      };
    }
    default:
      return state;
  }
}
