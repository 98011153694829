import { Card as MuiCard, CardContent, CardHeader, Typography, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import AddImage from "../../assets/img/Add.png";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GetOutputLinkByOutput } from "../../routes/RouteLinkHelpers";
import ModalSingleAssetOutputCreate from "./modals/ModalSingleAssetOutputCreate";
import { ISingleAssetOutput } from "../../utilities/types/SingleAssetOutput";

const IMAGE_HEIGHT = 200;

const Card = styled(MuiCard)(({ theme }) => ({
  "& .updatedModelImageWrapper": {
    height: IMAGE_HEIGHT,
    overflow: "hidden",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
  },
  "& .plusImage": {
    alignItems: "center",
    height: IMAGE_HEIGHT,
    left: "50%",
  },
  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .avatar": {
    backgroundColor: blueGrey[800],
    textSizeAdjust: "small",
  },
  "& .header": {
    height: 75,
  },
}));

function AddNewSingleAssetOutputWidget() {
  const [singleAssetOutputCreateModalOpen, setSingleAssetOutputCreateModalOpen] = useState(false);
  const history = useHistory();

  const handleOutputTypeCreateModalToggle = () =>
    setSingleAssetOutputCreateModalOpen(!singleAssetOutputCreateModalOpen);
  const onOutputTypeCreateCancelCallback = () => setSingleAssetOutputCreateModalOpen(false);
  const viewOutputTypeDetails = (singleAssetOutput: ISingleAssetOutput) => {
    history.push(GetOutputLinkByOutput(singleAssetOutput));
  };
  const onOutputTypeCreateCompleteCallback = (singleAssetOutput?: ISingleAssetOutput) => {
    if (singleAssetOutput) {
      setSingleAssetOutputCreateModalOpen(false);
      viewOutputTypeDetails(singleAssetOutput);
    }
  };

  return (
    <Card>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        title="Suggest a new output type"
        subheader={`Creates a new draft single Asset Output to share`}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
      />
      <div className="updatedModelImageWrapper">
        <img src={AddImage} alt="AddImage" className="plusImage" onClick={handleOutputTypeCreateModalToggle} />
      </div>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {"Start a new page here"}
        </Typography>
      </CardContent>
      <ModalSingleAssetOutputCreate
        onCompleteCallback={onOutputTypeCreateCompleteCallback}
        onCancelCallback={onOutputTypeCreateCancelCallback}
        open={singleAssetOutputCreateModalOpen}
      />
    </Card>
  );
}

export default AddNewSingleAssetOutputWidget;
