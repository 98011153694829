import { Button, Divider, Grid } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import React from "react";
import { useCallback } from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";

import { RootState } from "../../../redux";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

import { IIdentifierMapping, IdentifierMappingTypeEnum } from "../../../utilities/types/IdentifierMapping";
import { fetchCreateIdentifierMapping } from "../../../redux/identifierMapping/actions";

import { LinkToModesTable } from "../../component/linkToModesTable";
import { ComponentAndFunctionListType } from "../../component/linkToModesTable/useComponentAndFunctionList";

interface FormValues {
  targetIds: string[];
}

interface FormProps {
  identifierIds: string[];
  defaultSelectedTargetIds: string[];
  modelId: string;
  type: IdentifierMappingTypeEnum;
  componentAndFunctionList: ComponentAndFunctionListType[];
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IIdentifierMapping[], AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { modelId, values, setFieldValue } = props;

  const onSelectionChange = useCallback(
    (rowList: any[]) => {
      const checkedTargetIds = rowList.filter((item: any) => item?.tableData?.checked === true).map((item) => item.id);
      const uniqueArray = Array.from(new Set(checkedTargetIds));
      setFieldValue("targetIds", uniqueArray);
    },
    [setFieldValue]
  );

  return (
    <form onSubmit={props.handleSubmit}>
      <LinkToModesTable
        modelId={modelId}
        handleSelectionChange={onSelectionChange}
        targetIds={values.targetIds}
        showSelection={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid container item xs={12}>
          <div style={{ flex: "1" }} />
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Add
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormApplicabilityMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => ({
    modelId: "",
    targetIds: props.defaultSelectedTargetIds,
    identifierIds: [],
    type: IdentifierMappingTypeEnum.FailureMode,
  }),
  validationSchema: Yup.object().shape({
    targetIds: Yup.string().label("Failure Modes").required("Please provide at least one target"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId, type, componentAndFunctionList } = props;

    const identifierMappingsToCreate = values.targetIds.flatMap((target) => {
      const findRecords = componentAndFunctionList.find((item) => item.id === target);
      return props.identifierIds.map((identifier) => ({
        modelId, // Assuming modelId is obtained from some variable
        targetId: target,
        identifierId: identifier,
        mappingType: (findRecords?.type as unknown as IdentifierMappingTypeEnum) ?? type,
      }));
    });

    // Map dispatch via props
    var createdIdentifierMappings = await dispatch(
      fetchCreateIdentifierMapping({
        identifierMappings: identifierMappingsToCreate,
      })
    );

    setSubmitting(false);

    if (createdIdentifierMappings) onCompleteCallback(createdIdentifierMappings);
  },
})(InnerForm);

export default FormApplicabilityMappingCreate;
