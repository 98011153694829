import { AppThunk } from "..";

import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { TAssetInputFieldValue } from "../../utilities/types/AssetInputField";

import { AssetJobStatusEnum, IAssetJob } from "../../utilities/types/AssetJobs";

import { ACTIONS_ASSET_JOB, AssetJobSearchOrderTypeEnum } from "./types";

export const receiveAssetJobs = (assetJobs: IAssetJob[]) => {
  var byIdObjectToDispatch: { [key: string]: IAssetJob } = {};

  for (var i = 0; i < assetJobs.length; i++) {
    byIdObjectToDispatch[assetJobs[i].assetJobId] = assetJobs[i];
  }

  return {
    type: ACTIONS_ASSET_JOB.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export interface IFetchSearchAssetJobsProps {
  pageNumber: number;
  pageSize: number;
  assetJobId?: string;
  modelId?: string;
  text?: string;
  createdBy?: string;
  orderType?: AssetJobSearchOrderTypeEnum;
  status?: AssetJobStatusEnum;
}

export const fetchSearchAssetJobs =
  (searchParams: IFetchSearchAssetJobsProps): AppThunk<Promise<IAssetJob[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/AssetJobs`, searchParams), {
        method: "GET",
        headers: headers,
      });

      // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetJobs) {
        dispatch(receiveAssetJobs(parsedResp.data.assetJobs));
        return parsedResp.data.assetJobs;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching asset job.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching asset job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

interface IPrerequisites {
  assetJobIds?: string[];
  outputJobIds?: string[];
  solverJobIds?: string[];
}

export interface IFetchCreateAssetJobProps {
  modelId: string;
  fileId: string;
  assetInputFieldValueMap: { [key: string]: TAssetInputFieldValue };
  jobName: string;
  inputBucket: string | null;
  outputBucket: string | null;
  prerequisites?: IPrerequisites;
  terminateOnErrorCount: number | null;
}

export const fetchCreateAssetJob =
  (assetJobToCreate: IFetchCreateAssetJobProps): AppThunk<Promise<IAssetJob>> =>
  async () => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/AssetJobs`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(assetJobToCreate),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetJob) {
        return parsedResp.data.assetJob;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating asset job.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating asset job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface ICancelAssetJobProps {
  assetJobId: string;
}

export const cancelAssetJob =
  (cancelAssetJob: ICancelAssetJobProps): AppThunk<Promise<any>> =>
  async () => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/AssetJobs/cancel`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(cancelAssetJob),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success) {
        return parsedResp.data;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error cancelling asset job.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error cancelling asset job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
