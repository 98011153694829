import React, { Fragment, useState } from "react";
import { Button, Divider, styled } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { IDiscussion } from "../../utilities/types/Discussion";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalDiscussionCreate from "./modals/ModalDiscussionCreate";
import { fetchSearchDiscussions } from "../../redux/discussion/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { selectorGetDiscussionsByEntityTypeAndEntityId } from "../../redux/discussion/selectors";
import { useFetchDiscussionsPageHook } from "./Hooks";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import DiscussionIcon from "@mui/icons-material/QuestionAnswerOutlined";
import DiscussionRow from "./DiscussionRow";

const DivWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  "& .btnAddDiscussion": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
  },
}));

type IDiscussionEnquiryContainerProps = {
  canEdit: boolean;
  entityType: EntityTypeEnum;
  entityId: string;
};

const DiscussionEnquiryContainer = ({ canEdit, entityType, entityId }: IDiscussionEnquiryContainerProps) => {
  const dispatch = useDispatch();
  const discussions = useSelector((store: RootState) =>
    selectorGetDiscussionsByEntityTypeAndEntityId(store, entityType, entityId)
  );
  const [pageNumber, setPageNumber] = useState(1);
  const { fetching, morePages, setReFetch } = useFetchDiscussionsPageHook({
    pageNumber: pageNumber,
    pageSize: 20,
    minPageNumberToFetch: 1,
    entityType,
    entityId,
  });

  const onUploadComplete = async (discussionId: string) => {
    await dispatch(fetchSearchDiscussions({ discussionId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <DiscussionEnquiryContainerDisplay
      canEdit={canEdit}
      discussions={discussions}
      loading={fetching}
      onUploadComplete={onUploadComplete}
      entityId={entityId}
      entityType={entityType}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      morePages={morePages}
      setReFetch={setReFetch}
    />
  );
};

type IDiscussionEnquiryContainerDisplayProps = {
  canEdit: boolean;
  discussions: IDiscussion[];
  loading: boolean;
  onUploadComplete(discussionId: string): void;
  entityId: string;
  entityType: EntityTypeEnum;
  pageNumber: number;
  setPageNumber(pageNumber: number): void;
  morePages: boolean;
  setReFetch: React.Dispatch<React.SetStateAction<boolean>>;
};

const DiscussionEnquiryContainerDisplay = ({
  discussions,
  loading,
  canEdit,
  entityId,
  entityType,
  pageNumber,
  setPageNumber,
  morePages,
  setReFetch,
}: IDiscussionEnquiryContainerDisplayProps) => {
  const [showCreateDiscussionModal, setShowCreateDiscussionModal] = useState(false);

  function toggleShowCreateDiscussionModal() {
    setShowCreateDiscussionModal(!showCreateDiscussionModal);
  }

  const handleRefetch = () => {
    setReFetch(true);
  };
  return (
    <DivWrapper>
      {canEdit && (
        <Button
          color="primary"
          variant="outlined"
          className="btnAddDiscussion"
          onClick={toggleShowCreateDiscussionModal}
        >
          New Discussion
        </Button>
      )}

      {loading && <LoaderAbsoluteCentred loading={loading} />}
      {!loading && (!discussions || !discussions.length) && (
        <WidgetNoResultsPlaceholder text="No discussions" icon={DiscussionIcon} flip={true} />
      )}
      <AnimationWrapper>
        <div>
          {discussions.map((discussion: IDiscussion) => {
            return (
              <Fragment key={discussion.discussionId}>
                <DiscussionRow
                  discussion={discussion}
                  showAdminDetails={false}
                  commentLevel={0}
                  handleRefetch={handleRefetch}
                />
                <Divider />
              </Fragment>
            );
          })}
        </div>
      </AnimationWrapper>

      {showCreateDiscussionModal && (
        <ModalDiscussionCreate
          open={true}
          onCancelCallback={toggleShowCreateDiscussionModal}
          onCompleteCallback={toggleShowCreateDiscussionModal}
          entityId={entityId}
          entityType={entityType}
        />
      )}

      {morePages && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 24,
          }}
        >
          <Button disabled={loading} variant="outlined" onClick={() => setPageNumber(pageNumber + 1)}>
            More
          </Button>
        </div>
      )}
    </DivWrapper>
  );
};

export default DiscussionEnquiryContainer;
