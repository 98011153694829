import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchSearchDiscussions, IFetchSearchDiscussionsProps } from "../../redux/discussion/actions";
import { fetchSearchUserDetails } from "../../redux/userDetail/actions";
import { IDiscussion } from "../../utilities/types/Discussion";

interface IUseFetchDiscussionsPageHookProps extends IFetchSearchDiscussionsProps {
  minPageNumberToFetch: number;
}

export const useFetchDiscussionsPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
  entityId,
  entityType,
  orderType,
}: IUseFetchDiscussionsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IDiscussion[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IDiscussion[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);
      if (reFetch) setReFetch(false);
      try {
        // Retrieve models
        var discussions = (await dispatch(
          fetchSearchDiscussions({
            pageSize,
            pageNumber,
            entityId,
            entityType,
            orderType,
          })
        )) as unknown as IDiscussion[];

        if (discussions && discussions.length) {
          setMorePages(discussions.length >= pageSize);
          setLastResultSet(discussions);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, entityType, entityId, orderType, reFetch]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.discussionId === x.discussionId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  // Load associated models for metadata like images etc
  useLoadNewUsers(allResultsSet.map((x) => x.createdBy));

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
    setReFetch,
  };
};

/** Loads users that haven't been loaded yet so that the redux store contains useful metadata like images. */
function useLoadNewUsers(userIds: string[]) {
  // Get unique user IDs that don't exist in the store yet
  const dispatch = useDispatch();
  const userIdStore = useSelector((store: RootState) => store.userDetails.byId);
  const uniqueIds = Array.from(new Set(userIds));
  const newIds = uniqueIds.filter((id) => !userIdStore[id]);

  // Track user IDs that are in progress and that may or may not be in the store
  const [usersInProgress, setUsersInProgress] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    const updateUsersInProgress = (userId: string, finished: boolean) =>
      setUsersInProgress({ ...usersInProgress, [userId]: finished });
    const fetchUser = async (userDetailId: string) => {
      if (usersInProgress[userDetailId] !== undefined) return; // Skip IDs in progress

      updateUsersInProgress(userDetailId, false); // Ensure we don't check again while it's in progress
      await dispatch(fetchSearchUserDetails({ userDetailId, pageSize: 1, pageNumber: 1 })); // Fetch the user which will populate the store and related metadata
      updateUsersInProgress(userDetailId, true); // Update the useState so the component refreshes with new metadata
    };

    newIds.forEach(fetchUser);
  }, [dispatch, newIds, usersInProgress]);
}
