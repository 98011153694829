import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/FunctionsOutlined";
import FormFunctionUpdate from "../forms/FormFunctionUpdate";
import { IFunction } from "../../../utilities/types/Function";

export interface IModalFunctionUpdateProps {
  open: boolean;
  componentFunction: IFunction;
  onCancelCallback(): void;
  onCompleteCallback(componentFunction?: IFunction): void;
  canEdit: boolean;
}

function ModalFunctionUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  componentFunction,
}: IModalFunctionUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a function"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormFunctionUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          componentFunction={componentFunction}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFunctionUpdate;
