export const INVALIDATE_SYSTEM = 'INVALIDATE_SYSTEM';
export const RECEIVE_SYSTEM = 'RECEIVE_SYSTEM';


interface ActionSystemInvalidate {
    type: typeof INVALIDATE_SYSTEM,
    payload: null,
}

interface ActionSystemReceive {
    type: typeof RECEIVE_SYSTEM
    payload: SystemState
}

export interface SystemState {
    loggedIn?: boolean,
    session?: string,
    username?: string,
    userId?: string,
    accessToken?: string,
}

export type SystemActionTypes = ActionSystemInvalidate | ActionSystemReceive;