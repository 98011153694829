import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import ListIcon from "@mui/icons-material/ListAlt";
import { IAssetInputFieldListValue } from "../../../utilities/types/AssetInputFieldListValue";
import FormAssetInputFieldListValueCreate from "../forms/FormAssetInputFieldListValueCreate";

export interface IModalAssetInputFieldListValueCreateProps {
  open: boolean;
  assetInputFieldId: string;
  onCancelCallback(): void;
  onCompleteCallback(file?: IAssetInputFieldListValue): void;
}

function ModalAssetInputFieldListValueCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  assetInputFieldId,
}: IModalAssetInputFieldListValueCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Add a new list value(s)"}
      subtitle={"Use comma seperated text for adding mulitple items."}
      headerIcon={<ListIcon />}
    >
      <DialogContent>
        <FormAssetInputFieldListValueCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          assetInputFieldId={assetInputFieldId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalAssetInputFieldListValueCreate;
