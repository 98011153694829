import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { IFunction } from "../../utilities/types/Function";

export interface IFetchSearchAiSuggestFunctionProps {
  pageNumber: number;
  pageSize: number;
  modelId: string;
  componentId: string;
}

export const fetchSearchAiSuggestFunction = async (searchParams: IFetchSearchAiSuggestFunctionProps): Promise<IFunction[]> => {
  var headers = await GetDefaultHeaders(true, false, true);

  try {
    var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/AISuggest/Functions`, searchParams), {
      method: "GET",
      headers: headers,
    });

    var parsedResp: IApiResponse = await CheckStatus(apiResponse);
    if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functions) {
      return parsedResp.data.functions;
    } else {
      if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
        ShowError("Error searching ai suggest function.");
      }
      return [];
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      ShowExceptionAsMessage(e);
      console.log("Error searching ai suggest function.", e.stack);
    } else {
      // Handle other types of exceptions or unknown errors.
      console.error("Unknown error:", e);
    }
    return [];
  }
};
