import {
  Avatar,
  Button,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import DialogContent from "@mui/material/DialogContent";
import ModelsIcon from "@mui/icons-material/CategoryTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import UnknownIcon from "@mui/icons-material/DeviceUnknownTwoTone";
import PlaceholderIcon from "@mui/icons-material/LockTwoTone";
import HeaderIcon from "@mui/icons-material/PersonTwoTone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import {
  fetchDeleteEntityPermission,
  fetchEntityPermissionsByUserIdIfNeeded,
} from "../../../redux/entityPermission/actions";
import {
  selectorGetEntityPermissionsByUserId,
  selectorGetEntityPermissionsByUserIdAndEntityId,
} from "../../../redux/entityPermission/selectors";
import { selectorGetUserDetailById } from "../../../redux/userDetail/selectors";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import ModalEntityPermissionsPermissionSelect from "./ModalEntityPermissionsPermissionSelect";

export interface IModalEntityPermissionsEntitySelectProps {
  open: boolean;
  onCancelCallback(): void;
  userDetailId: string;
}

function ModalEntityPermissionsEntitySelect({
  onCancelCallback,
  open,
  userDetailId,
}: IModalEntityPermissionsEntitySelectProps) {
  const dispatch = useDispatch();
  const entityPermissions = useSelector((store: RootState) =>
    selectorGetEntityPermissionsByUserId(store, userDetailId)
  );
  const userDetail = useSelector((store: RootState) => selectorGetUserDetailById(store, userDetailId));
  const [loading] = useState(false);
  const [distinctEntityIds, setDistinctEntityIds] = useState<string[]>([]);
  const [showModalEntityPermissionsPermissionSelect, setShowModalEntityPermissionsPermissionSelect] = useState(false);

  async function toggleShowModalEntityPermissionsPermissionSelect() {
    setShowModalEntityPermissionsPermissionSelect(!showModalEntityPermissionsPermissionSelect);
  }

  useEffect(() => {
    dispatch(fetchEntityPermissionsByUserIdIfNeeded({ userDetailId }));
  }, [userDetailId, dispatch]);

  // Maintain a list of each entity that currently has permissions for this user
  useEffect(() => {
    if (entityPermissions.length <= distinctEntityIds.length) return;

    setDistinctEntityIds(Array.from(new Set(entityPermissions.map((x) => x.entityId))));
  }, [entityPermissions, distinctEntityIds]);

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={`Entity permissions`}
      subtitle={`Configure entity permissions for ${userDetail?.displayName || "N/A"}.`}
      headerIcon={<HeaderIcon />}
      style={{ maxWidth: 600, minWidth: 550 }}
    >
      <DialogContent>
        <Grid container spacing={1}>
          {distinctEntityIds.length === 0 ? (
            <Grid item xs={12}>
              <WidgetNoResultsPlaceholder
                text="No permissions"
                description="No entity permissions have been set."
                icon={PlaceholderIcon}
                flip={true}
              />
            </Grid>
          ) : (
            distinctEntityIds.map((x) => <EntityRow key={x} entityId={x} userId={userDetailId} />)
          )}
        </Grid>
      </DialogContent>
      <Divider light={true} style={{ marginBottom: 16, marginTop: 8 }} />
      <DialogActions>
        <Button variant="outlined" onClick={onCancelCallback} fullWidth={true}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleShowModalEntityPermissionsPermissionSelect}
          fullWidth={true}
        >
          Add
        </Button>
      </DialogActions>
      <LoaderAbsoluteCentred loading={loading} />

      {showModalEntityPermissionsPermissionSelect && (
        <ModalEntityPermissionsPermissionSelect
          open={true}
          entityId={undefined}
          onCancelCallback={toggleShowModalEntityPermissionsPermissionSelect}
          userDetailId={userDetailId}
        />
      )}
    </WidgetModalBase>
  );
}

function EntityRow({ entityId, userId }: { entityId: string; userId: string }) {
  const entityPermissionsByEntity = useSelector((store: RootState) =>
    selectorGetEntityPermissionsByUserIdAndEntityId(store, userId, entityId)
  );
  const theme = useTheme();

  const dispatch = useDispatch();
  const getDeleteCallback = (entityPermissionId: string) => () =>
    dispatch(fetchDeleteEntityPermission({ entityPermissionId })) as any as Promise<void>;

  return (
    <>
      {entityPermissionsByEntity.map((x) => (
        <Grid key={x.entityPermissionId} container alignItems="center" style={{ marginBottom: theme.spacing(2) }}>
          <Grid item xs={2}>
            <EntityTypeIcon entityType={x.entityType} />
          </Grid>
          <div style={{ flex: 1 }}>
            <Typography variant="body1" color="textSecondary" noWrap={true}>
              {EntityTypeEnum[Number(x.entityType)]}
            </Typography>
            <Typography variant="body1" color="textPrimary" noWrap={true}>
              {x.entityId}
            </Typography>
          </div>
          <DeleteButton onDelete={getDeleteCallback(x.entityPermissionId)} />
        </Grid>
      ))}
    </>
  );
}

function EntityTypeIcon({ entityType }: { entityType: EntityTypeEnum }) {
  var icon = <UnknownIcon />;
  switch (entityType) {
    case EntityTypeEnum.Model:
      icon = <ModelsIcon />;
      break;
  }

  return <Avatar>{icon}</Avatar>;
}

const DivWrapper = styled("div")({
  position: "relative",
  "& .buttonProgress": {
    color: grey[500],
    opacity: 0.5,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -16,
    marginLeft: -16,
  },
});

function DeleteButton(props: { onDelete: () => Promise<any> }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const open = () => setIsOpen(true),
    close = () => setIsOpen(false);

  const onDelete = async () => {
    close();
    setIsLoading(true);
    const permission = await props.onDelete();
    if (!permission) setIsLoading(false); // Reset if error occurred
  };

  return (
    <DivWrapper>
      <IconButton aria-label="Delete" title="Delete" onClick={open} disabled={isLoading}>
        <DeleteIcon />
      </IconButton>
      {isLoading && <CircularProgress size={32} className="buttonProgress" />}
      <WidgetModalConfirmationDialog
        open={isOpen}
        title="Delete confirmation"
        subtitle="Confirm entity permission delete"
        description="Are you sure that you'd like to remove this entity permission?"
        onCancelCallback={close}
        onConfirmCallback={onDelete}
        confirmButtonText="Delete"
      />
    </DivWrapper>
  );
}

export default ModalEntityPermissionsEntitySelect;
