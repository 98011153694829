import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, AddQueryStringsToUrl, CheckStatus, ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { selectorGetUserRolePermissionsByRoleId } from './selectors';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IUserRolePermission } from '../../utilities/types/UserRolePermission';
import { ACTIONS_USER_ROLE_PERMISSION } from './types';


export const receiveUserRolePermissions = (userRolePermissions: IUserRolePermission[]) => {

    var byIdObjectToDispatch: { [key: string]: IUserRolePermission } = {};

    for (var i = 0; i < userRolePermissions.length; i++) {
        byIdObjectToDispatch[userRolePermissions[i].userRoleToPermissionMappingId] = userRolePermissions[i];
    }

    return {
        type: ACTIONS_USER_ROLE_PERMISSION.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteUserRolePermission = (userRolePermission: IUserRolePermission) => ({
    type: ACTIONS_USER_ROLE_PERMISSION.DELETE,
    byId: { [userRolePermission.userRoleToPermissionMappingId]: userRolePermission }
});



export interface IFetchSearchUserRolePermissions {
    userRoleToPermissionMappingId?: string,
    pageSize?: number,
    pageNumber?: number,
    userRoleId?: string,
}


export const fetchSearchUserRolePermissions = ({ pageSize, pageNumber, userRoleToPermissionMappingId, userRoleId }: IFetchSearchUserRolePermissions): AppThunk<Promise<IUserRolePermission[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/userRolesToPermissions`, {
            userRoleId,
            pageSize,
            pageNumber,
            userRoleToPermissionMappingId,
        }), {
            method: 'GET',
            headers: headers
        });

        var json = await CheckStatus(apiResponse);
        if (json && json.success && json.data) {

            if (json.data.roleToPermissionMappings && json.data.roleToPermissionMappings.length > 0) {
                dispatch(receiveUserRolePermissions(json.data.roleToPermissionMappings));
            }
        }

        return json;
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving user permissions.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export const fetchUserRolePermissionsByRoleIdIfNeeded = (userRoleId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetUserRolePermissionsByRoleId(getState(), userRoleId)?.length) {
        await dispatch(fetchSearchUserRolePermissions(
            {
                userRoleId,
                pageNumber: 1,
                pageSize: 500,
            }));
    }

    return;
}


export interface IFetchCreateUserRolePermissionsProps {
    userRolePermissionTypeIds: number[],
    targetRoleId: string,
}


export const fetchCreateUserRolePermissions = ({ userRolePermissionTypeIds, targetRoleId }: IFetchCreateUserRolePermissionsProps): AppThunk<Promise<IUserRolePermission>> => async (dispatch, getState) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/userRolesToPermissions`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody({ userRolePermissionTypeIds, targetRoleId }),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.roleToPermissionMappings) {

            // Remove any existing permissions for the user
            var existingPermissions = selectorGetUserRolePermissionsByRoleId(getState(), targetRoleId);
            for (var existingPermission of existingPermissions) {
                dispatch(requestDeleteUserRolePermission(existingPermission));
            }

            dispatch(receiveUserRolePermissions(parsedResp.data.roleToPermissionMappings));
            return parsedResp.data.roleToPermissionMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating user permission.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating user permission.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}