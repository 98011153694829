import { Button, DialogActions, Typography, styled } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import MapIcon from "@mui/icons-material/MapRounded";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetSummaryOutputTypeById } from "../../../redux/summaryOutputType/selectors";
import { selectorGetSummaryOutputTypeInputFieldsBySummaryOutputTypeId } from "../../../redux/summaryOutputTypeInputField/selectors";
import { selectorGetSummaryOutputTypeInputFieldListValuesBySummaryOutputTypeId } from "../../../redux/summaryOutputTypeInputFieldListValue/selectors";
import { DeHumanizeString } from "../../../utilities/Helpers";
import { ISummaryOutputType } from "../../../utilities/types/SummaryOutputType";
import {
  ISummaryOutputTypeInputField,
  SummaryOutputTypeInputFieldTypeEnum,
} from "../../../utilities/types/SummaryOutputTypeInputField";
import { ISummaryOutputTypeInputFieldListValue } from "../../../utilities/types/SummaryOutputTypeInputFieldListValue";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchSummaryOutputTypeInputFieldsPageHook } from "../../summaryOutputTypeInputField/Hooks";
import { useFetchSummaryOutputTypeInputFieldListValuesPageHook } from "../../summaryOutputTypeInputFieldListValue/Hooks";

const PreBlock = styled("pre")(({ theme }) => ({
  width: "100%",
  overflow: "scroll",
  border: "none",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(0,0,0,0.8)",
  color: "#FFF",
  fontFamily:
    "Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif",
  padding: theme.spacing(1),
  maxHeight: 150,
  marginTop: 0,
}));

export interface IModalSummaryOutputTypeDefinitionsProps {
  open: boolean;
  onCancelCallback(): void;
  summaryOutputTypeId: string;
}

function ModalSummaryOutputTypeDefinitions({
  onCancelCallback,
  open,
  summaryOutputTypeId,
}: IModalSummaryOutputTypeDefinitionsProps) {
  const summaryOutputType = useSelector((store: RootState) =>
    selectorGetSummaryOutputTypeById(store, summaryOutputTypeId)
  );
  const summaryOutputTypeFields = useSelector((store: RootState) =>
    selectorGetSummaryOutputTypeInputFieldsBySummaryOutputTypeId(store, summaryOutputTypeId)
  );
  const summaryOutputTypeFieldValues = useSelector((store: RootState) =>
    selectorGetSummaryOutputTypeInputFieldListValuesBySummaryOutputTypeId(store, summaryOutputTypeId)
  );

  const { fetching: fetchingFields } = useFetchSummaryOutputTypeInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 100,
    summaryOutputTypeId: summaryOutputTypeId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingFieldValues } = useFetchSummaryOutputTypeInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    summaryOutputTypeId: summaryOutputTypeId,
    minPageNumberToFetch: 1,
  });

  const hasData = !!summaryOutputType && !!summaryOutputTypeFields.length && !!summaryOutputTypeFieldValues.length;
  const fetching = !hasData && (fetchingFields || fetchingFieldValues);

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Output Type Class Definition"}
      subtitle={"Copy and paste into the report project."}
      headerIcon={<MapIcon />}
      style={{ maxWidth: "1200px" }}
    >
      <DialogContent>
        <LoaderAbsoluteCentred loading={fetching} />
        {!summaryOutputType ? (
          <WidgetNoResultsPlaceholder text="No output type" />
        ) : (
          <>
            <Typography variant="overline">Output Type Id</Typography>
            <PreBlock>public const string Id = "{summaryOutputType.summaryOutputTypeId}";</PreBlock>

            <Typography variant="overline">Field Definitions</Typography>
            <PreBlock>{ClassFieldDefinitions({ summaryOutputType, summaryOutputTypeFields })}</PreBlock>

            <Typography variant="overline">Field Value Definitions</Typography>
            <PreBlock>
              {ClassFieldListValueDefinitions({
                summaryOutputType,
                summaryOutputTypeFields,
                summaryOutputTypeFieldValues,
              })}
            </PreBlock>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth={true} onClick={onCancelCallback}>
          Close
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

function ClassFieldListValueDefinitions({
  summaryOutputType,
  summaryOutputTypeFields,
  summaryOutputTypeFieldValues,
}: {
  summaryOutputType: ISummaryOutputType;
  summaryOutputTypeFields: ISummaryOutputTypeInputField[];
  summaryOutputTypeFieldValues: ISummaryOutputTypeInputFieldListValue[];
}) {
  var fieldsDefinition = `public static class ${DeHumanizeString(summaryOutputType.name)}FieldValues\r\n`;
  fieldsDefinition += `{\r\n`;

  // Add each field to the definition
  for (var summaryOutputTypeField of summaryOutputTypeFields) {
    // We only need this for enumerations
    if (summaryOutputTypeField.type !== SummaryOutputTypeInputFieldTypeEnum.CustomEnumeration) continue;

    fieldsDefinition += `\tpublic static class ${DeHumanizeString(summaryOutputTypeField.label)}FieldValues\r\n`;
    fieldsDefinition += `\t{\r\n`;

    // Add each of the field value properties
    for (var fieldValue of summaryOutputTypeFieldValues) {
      // Skip values that don't belong to this field
      if (fieldValue.summaryOutputTypeInputFieldId !== summaryOutputTypeField.summaryOutputTypeInputFieldId) continue;

      fieldsDefinition += `\t\tpublic const string ${DeHumanizeString(fieldValue.displayText)} = "${
        fieldValue.summaryOutputTypeInputFieldListValueId
      }";\r\n`;
    }

    fieldsDefinition += `\t}\r\n`;
    fieldsDefinition += `\r\n`;
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

function ClassFieldDefinitions({
  summaryOutputType,
  summaryOutputTypeFields,
}: {
  summaryOutputType: ISummaryOutputType;
  summaryOutputTypeFields: ISummaryOutputTypeInputField[];
}) {
  var fieldsDefinition = `public static class ${DeHumanizeString(summaryOutputType.name)}Fields\r\n`;
  fieldsDefinition += `{\r\n`;

  // Add each field to the definition
  for (var summaryOutputTypeField of summaryOutputTypeFields) {
    fieldsDefinition += `\tpublic const string ${DeHumanizeString(summaryOutputTypeField.label)} = "${
      summaryOutputTypeField.summaryOutputTypeInputFieldId
    }";\r\n`;
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

export default ModalSummaryOutputTypeDefinitions;
