export interface ISingleAssetOutput {
  outputTypeId: string;
  name: string;
  description: string;
  inputBucket: string;
  outputBucket: string;
  triggerBucket: string;
  status: SingleAssetOutputStatus;
  mainImageId: string;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
}

export enum SingleAssetOutputSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export enum SingleAssetOutputStatus {
  AdminOnly = 10,
  Preview = 20,
  Enabled = 30,
}
