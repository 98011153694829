import { RootState } from "..";
import { IFunctionalFailure } from "../../utilities/types/FunctionalFailure";


export function selectorGetFunctionalFailuresByModelId(state: RootState, modelId: string) : IFunctionalFailure[] {

    if (!state.functionalFailures || !state.functionalFailures.byId) return [];

    return sortFunctionalFailuresByOrderNumberDescending(Object.values(state.functionalFailures.byId).filter(x => x.modelId === modelId));
}


export function selectorGetFunctionalFailuresByFunctionId(state: RootState, functionId: string) : IFunctionalFailure[] {

    if (!state.functions || !state.functions.byId) return [];

    return sortFunctionalFailuresByOrderNumberDescending(Object.values(state.functionalFailures.byId).filter(x => x.functionId === functionId));
}



export function selectorGetFunctionalFailureById(state: RootState, functionalFailureId: string) : IFunctionalFailure | undefined {

    if (!state.functionalFailures || !state.functionalFailures.byId) return undefined;

    return state.functionalFailures.byId[functionalFailureId];
}


function sortFunctionalFailuresByOrderNumberDescending(functionalFailures: IFunctionalFailure[]) {
    return functionalFailures.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.name < a.name) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}



// function sortFunctionalFailuresByOrderNumberAscending(functionalFailures: IFunctionalFailure[]) {
//     return functionalFailures.sort((a, b) => a.orderNumber - b.orderNumber);
// }