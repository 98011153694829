import { Grid, Typography, IconButton, Switch, styled } from "@mui/material";
import React, { useState, useEffect } from "react";

import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";

import { Configuration } from "../../utilities/Constants";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";

const ContentWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  "& .sectionWrapper": {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginTop: theme.spacing(2),
    maxHeight: 260,
    overflowX: "hidden",
  },
  "& .errorMessage": {
    backgroundColor: theme.palette.error.dark,
    borderRadius: "5px", // Rounded corners
    marginBottom: theme.spacing(1),
    color: "white", // Text color
  },
  "& .warningMessage": {
    backgroundColor: theme.palette.warning.main,
    borderRadius: "5px", // Rounded corners
    marginBottom: theme.spacing(1),
    color: "white", // Text color
  },
  "& .infoMessage": {
    backgroundColor: theme.palette.info.main,
    borderRadius: "5px", // Rounded corners
    marginBottom: theme.spacing(1),
    color: "white", // Text color
  },
  "& .messageItem": {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    //justifyContent: 'space-between',
    width: "100%", // Ensures it takes the full width
  },
  "& .closeButton": {
    backgroundColor: "rgba(0, 0, 0, 0)", // Transparent black
    padding: theme.spacing(1),
    color: "white",
    border: "none",
    cursor: "pointer",
  },
}));

type MessageType = {
  type: number; // You can specify the appropriate type for 'type'
  text: string; // Assuming 'text' is a string
};

type ModelValidationSectionProps = {
  modelId: string;
  handleRefetchModal: () => void;
};

const ModelValidationSection = ({ modelId, handleRefetchModal }: ModelValidationSectionProps) => {
  const [messages, setMessages] = useState([]);
  const [fetchingCode, setFetchingCode] = useState(false);

  const [showNotifications, setShowNotifications] = useState(false); // State for showing notifications

  // ... (other code)

  // Count the number of messages of each type
  const errorCount = messages.filter((message: MessageType) => message.type === 0).length;
  const warningCount = messages.filter((message: MessageType) => message.type === 3).length;

  // Helper function to determine the message class based on the message type
  const getMessageClass = (messageType: number) => {
    switch (messageType) {
      case 0: // Error
        return "errorMessage";
      case 3: // Warning
        return "warningMessage";
      case 2: // Information
        return "infoMessage";
      default:
        return "";
    }
  };

  const fetchMessages = async () => {
    setFetchingCode(true);

    try {
      const response = await fetch(`${Configuration.BASE_API_URL}/models/validate?modelId=${modelId}`);
      if (response.ok) {
        const data = await response.json();
        // Sort the messages by severity (Error < Warning < Information)
        data.messages.sort((a: { type: number }, b: { type: number }) => b.type - a.type);
        setMessages(data.messages);
      } else {
        // Handle the error
      }
    } catch (error) {
      // Handle network error
    } finally {
      setFetchingCode(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [modelId]);

  const handleRefresh = () => {
    // Clear messages and fetch again
    setMessages([]);
    fetchMessages();
    handleRefetchModal();
  };
  const handleRemoveMessage = (index: number) => {
    const updatedMessages = [...messages];
    updatedMessages.splice(index, 1);
    setMessages(updatedMessages);
  };

  return (
    <ContentWrapper>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography>Validation Messages</Typography>
        <div style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
          <Typography variant="caption">Show Information Messages</Typography>
          {/* Add switch to toggle notification messages */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch checked={showNotifications} onChange={() => setShowNotifications(!showNotifications)} />
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="sectionWrapper">
        {(errorCount === 0 && warningCount === 0 && !showNotifications) || messages.length === 0 ? (
          // Show the green tick icon when the list is empty
          <div style={{ textAlign: "center" }}>
            <CheckCircleOutline style={{ fontSize: 100, color: "green" }} />
          </div>
        ) : (
          // Display messages based on the state of showNotifications
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {messages.map((message: MessageType, index) => {
                // Check if the message type should be shown
                if (!showNotifications && message.type === 2) {
                  return null; // Skip notifications
                }
                return (
                  <div key={index} className={getMessageClass(message.type)}>
                    <div className="messageItem">
                      <IconButton className="closeButton" onClick={() => handleRemoveMessage(index)}>
                        <CloseIcon />
                      </IconButton>
                      <Typography>{message.text}</Typography>
                    </div>
                  </div>
                );
              })}
            </Grid>
            <LoaderAbsoluteCentred loading={fetchingCode} />
          </Grid>
        )}
      </div>
    </ContentWrapper>
  );
};

export default ModelValidationSection;
