import { ISolverJobTask } from "../../utilities/types/SolverJobTask";


export const ACTIONS_SOLVER_JOB_TASK = {
    INVALIDATE: 'INVALIDATE_SOLVER_JOB_TASKS',
    RECEIVE: 'RECEIVE_SOLVER_JOB_TASKS',
    DELETE: 'DELETE_SOLVER_JOB_TASK',
    UPDATE: 'UPDATE_SOLVER_JOB_TASKS',
}

interface ActionSolverJobTasksInvalidate {
    type: typeof ACTIONS_SOLVER_JOB_TASK.INVALIDATE,
    byId: { [key: string]: ISolverJobTask },
}

interface ActionSolverJobTasksReceive {
    type: typeof ACTIONS_SOLVER_JOB_TASK.RECEIVE
    byId: { [key: string]: ISolverJobTask },
}

export interface SolverJobTaskState {
    byId: { [key: string]: ISolverJobTask },
}

export enum SolverJobTaskSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverJobTask = ActionSolverJobTasksReceive | ActionSolverJobTasksInvalidate;