import { ACTIONS_FUNCTIONAL_FAILURE, FunctionalFailureSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IFunctionalFailure } from '../../utilities/types/FunctionalFailure';
import { selectorGetFunctionalFailuresByModelId } from './selectors';
import { EntityTypeEnum } from '../../utilities/types/Entity';
import { IFailureMode } from '../../utilities/types/FailureMode';
import { receiveFailureModes } from '../failureMode/actions';
import { receiveIdentifierMappings } from '../identifierMapping/actions';


export const receiveFunctionalFailures = (functionalFailures: IFunctionalFailure[]) => {

    var byIdObjectToDispatch: { [key: string]: IFunctionalFailure } = {};

    for (var i = 0; i < functionalFailures.length; i++) {
        byIdObjectToDispatch[functionalFailures[i].functionalFailureId] = functionalFailures[i];
    }

    return {
        type: ACTIONS_FUNCTIONAL_FAILURE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};

export interface IFetchCloneFunctionalFailureProps {
    sourceId: string,
    destinationId: string,
    includeIdentifiers: boolean,
    entityType: EntityTypeEnum,

}

export const fetchCloneFunctionalFailure = (props: IFetchCloneFunctionalFailureProps): AppThunk<Promise<IFailureMode[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/Cloning/CloneEntity`, {
            method: 'Post',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.failureModes) {
            dispatch(receiveFailureModes(parsedResp.data.failureModes));
            if(parsedResp.data.functionalFailures) dispatch(receiveFunctionalFailures(parsedResp.data.functionalFailures));
            if(parsedResp.data.identifierMappings) dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
            return parsedResp.data.failureModes;
        }

        else{
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
            ShowError("Error cloning failureMode.");
            return null;
        }
    }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating failureMode.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export const requestDeleteFunctionalFailure = (functionalFailure: IFunctionalFailure) => ({
    type: ACTIONS_FUNCTIONAL_FAILURE.DELETE,
    byId: { [functionalFailure.functionalFailureId]: functionalFailure }
});


export interface IFetchCreateFunctionalFailureProps {
    functionId: string,
    modelId: string,
    name: string,
    description: string,
    orderNumber: number,
}

export interface IFetchCreateFunctionalFailuresProps {
    functionalFailures: IFetchCreateFunctionalFailureProps[]
}


export const fetchCreateFunctionalFailure = (functionalFailuresToCreate: IFetchCreateFunctionalFailuresProps): AppThunk<Promise<IFunctionalFailure>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/functionalFailures`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(functionalFailuresToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data) {
            if (parsedResp.data.functionalFailures) dispatch(receiveFunctionalFailures(parsedResp.data.functionalFailures));
            if (parsedResp.data.identifierMappings) dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
            return parsedResp.data.functionalFailures;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating functional failure.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating functional failure.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateFunctionalFailureProps {
    functionalFailureId: string,
    name: string,
    description: string,
    orderNumber: number,
}


export const fetchUpdateFunctionalFailure = (props: IFetchUpdateFunctionalFailureProps): AppThunk<Promise<IFunctionalFailure>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/functionalFailures`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functionalFailures) {
            dispatch(receiveFunctionalFailures(parsedResp.data.functionalFailures));
            return parsedResp.data.functionalFailures;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating functional failure.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating functional failure.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchFunctionalFailuresProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    functionalFailureId?: string,
    text?: string,
    createdBy?: string,
    orderType?: FunctionalFailureSearchOrderTypeEnum,
}


export const fetchSearchFunctionalFailures = (searchParams: IFetchSearchFunctionalFailuresProps): AppThunk<Promise<IFunctionalFailure[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/functionalFailures`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functionalFailures) {
            dispatch(receiveFunctionalFailures(parsedResp.data.functionalFailures));
            return parsedResp.data.functionalFailures;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching functionalFailure.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching functionalFailure.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteFunctionalFailureProps {
    functionalFailureId: string,
}


export const fetchDeleteFunctionalFailure = (props: IFetchDeleteFunctionalFailureProps): AppThunk<Promise<IFunctionalFailure>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/functionalFailures`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functionalFailures && parsedResp.data.functionalFailures.length) {
            dispatch(requestDeleteFunctionalFailure(parsedResp.data.functionalFailures[0]));
            return parsedResp.data.functionalFailures[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting functional failure.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting functional failure.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IEnableFunctionalFailuresByModelIdProps {
    modelId: string,
}

export const enableFunctionalFailuresByModelId = ({ modelId }: IEnableFunctionalFailuresByModelIdProps): AppThunk<Promise<void>> => async (dispatch, getStore) => {
    dispatch(receiveFunctionalFailures(
        selectorGetFunctionalFailuresByModelId(
            getStore(), modelId).map((functionalFailure) => {
        return { ...functionalFailure, disabled: false };
    })));
}