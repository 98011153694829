import { ACTIONS_IDENTIFIER_MAPPING, IdentifierMappingSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IIdentifierMapping, IdentifierMappingTypeEnum } from '../../utilities/types/IdentifierMapping';


export const receiveIdentifierMappings = (identifierMappings: IIdentifierMapping[]) => {

    var byIdObjectToDispatch: { [key: string]: IIdentifierMapping } = {};

    for (var i = 0; i < identifierMappings.length; i++) {
        byIdObjectToDispatch[identifierMappings[i].identifierMappingId] = identifierMappings[i];
    }

    return {
        type: ACTIONS_IDENTIFIER_MAPPING.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteIdentifierMapping = (identifierMapping: IIdentifierMapping) => ({
    type: ACTIONS_IDENTIFIER_MAPPING.DELETE,
    byId: { [identifierMapping.identifierMappingId]: identifierMapping }
});

export const requestDeleteManyIdentifierMapping = (identifierMappings: IIdentifierMapping[]) => {
    var byIdObjectToDispatch: { [key: string]: IIdentifierMapping } = {};

    for (var i = 0; i < identifierMappings.length; i++) {
        byIdObjectToDispatch[identifierMappings[i].identifierMappingId] = identifierMappings[i];
    }

   return { 
        type: ACTIONS_IDENTIFIER_MAPPING.DELETE_MANY,
        byId: byIdObjectToDispatch  
    }
};


export interface IFetchUpdateIdentifierMappingProps {
    identifierMappingId: string,
    modelId: string,
    targetIds: string[],
    identifierIds: string[],
    secondaryTargetId?: string,
    identifierMappingValue?: number,
    type: IdentifierMappingTypeEnum,
}


export const fetchUpdateIdentifierMapping = (identifierMappingToUpdate: IFetchUpdateIdentifierMappingProps): AppThunk<Promise<IIdentifierMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/identifierMappings`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(identifierMappingToUpdate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifierMappings) {
            dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
            return parsedResp.data.identifierMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updatinng identifier mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating identifier mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchCreateIdentifierMappingProps {
    modelId: string,
    targetId: string,
    identifierId: string,    
    secondaryTargetId?: string,
    identifierMappingValue?: number,
    mappingType: IdentifierMappingTypeEnum,
}

export interface IFetchCreateIdentifierMappingsProps {
    identifierMappings: IFetchCreateIdentifierMappingProps[],
}


export const fetchCreateIdentifierMapping = (identifierMappingsToCreate: IFetchCreateIdentifierMappingsProps): AppThunk<Promise<IIdentifierMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/identifierMappings`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(identifierMappingsToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifierMappings) {
            dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
            return parsedResp.data.identifierMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating identifier mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating identifier mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchIdentifierMappingsProps {
    pageNumber: number,
    pageSize: number,
    modelId?: string,
    identifierMappingId?: string,
    identifierMappingValue?: number,
    identifierId?: string,
    targetId?: string,
    secondaryTargetId?: string,
    createdBy?: string,
    orderType?: IdentifierMappingSearchOrderTypeEnum,
}


export const fetchSearchIdentifierMappings = (searchParams: IFetchSearchIdentifierMappingsProps): AppThunk<Promise<IIdentifierMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/identifierMappings`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifierMappings) {
            dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
            return parsedResp.data.identifierMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching identifier mapping.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching identifier mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteIdentifierMappingProps {
    identifierMappingId: string,
}


export const fetchDeleteIdentifierMapping = (props: IFetchDeleteIdentifierMappingProps): AppThunk<Promise<IIdentifierMapping>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/identifierMappings`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifierMappings && parsedResp.data.identifierMappings.length) {
            dispatch(requestDeleteIdentifierMapping(parsedResp.data.identifierMappings[0]));
            return parsedResp.data.identifierMappings[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting identifier mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting identifier mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}