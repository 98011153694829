import { ACTIONS_SINGLE_ASSET_OUTPUT, SingleAssetOutputSearchOrderTypeEnum } from "./types";
import { AppThunk } from "..";
import { Configuration } from "../../utilities/Constants";
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from "../../utilities/ApiUtils";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { ISingleAssetOutput, SingleAssetOutputStatus } from "../../utilities/types/SingleAssetOutput";
import { selectorGetSingleAssetOutputs } from "./selectors";

export const receiveSingleAssetOutput = (singleAssetOutputs: ISingleAssetOutput[]) => {
  var byIdObjectToDispatch: { [key: string]: ISingleAssetOutput } = {};

  for (var i = 0; i < singleAssetOutputs.length; i++) {
    byIdObjectToDispatch[singleAssetOutputs[i].outputTypeId] = singleAssetOutputs[i];
  }

  return {
    type: ACTIONS_SINGLE_ASSET_OUTPUT.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteSingleAssetOutput = (singleAssetOutput: ISingleAssetOutput) => ({
  type: ACTIONS_SINGLE_ASSET_OUTPUT.DELETE,
  byId: { [singleAssetOutput.outputTypeId]: singleAssetOutput },
});

export interface IFetchCreateSingleAssetOutputProps {
  name: string;
  description: string;
  inputBucket: string;
  outputBucket: string;
  triggerBucket: string;
  status: SingleAssetOutputStatus;
}

export const fetchCreateSingleAssetOutput =
  (outputTypeToCreate: IFetchCreateSingleAssetOutputProps): AppThunk<Promise<ISingleAssetOutput>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputTypes`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(outputTypeToCreate),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputTypes) {
        dispatch(receiveSingleAssetOutput(parsedResp.data.outputTypes));
        return parsedResp.data.outputTypes;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating singleAssetOutput.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating singleAssetOutput.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchUpdateSingleAssetOutputProps {
  outputTypeId: string;
  name: string;
  description: string;
  inputBucket: string;
  outputBucket: string;
  triggerBucket: string;
}

export const fetchUpdateSingleAssetOutput =
  (props: IFetchUpdateSingleAssetOutputProps): AppThunk<Promise<ISingleAssetOutput>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputTypes`, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputTypes) {
        dispatch(receiveSingleAssetOutput(parsedResp.data.outputTypes));
        return parsedResp.data.outputTypes;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error updating singleAssetOutput.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error updating singleAssetOutput.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchSingleAssetOutputProps {
  pageNumber: number;
  pageSize: number;
  outputTypeId?: string;
  text?: string;
  createdBy?: string;
  orderType?: SingleAssetOutputSearchOrderTypeEnum;
}

export const fetchSearchSingleAssetOutput =
  (searchParams: IFetchSearchSingleAssetOutputProps): AppThunk<Promise<ISingleAssetOutput[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/outputTypes`, searchParams), {
        method: "GET",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputTypes) {
        for (var outputType of parsedResp.data.outputTypes) {
          delete outputType.mainImage;
        }

        dispatch(receiveSingleAssetOutput(parsedResp.data.outputTypes));
        return parsedResp.data.outputTypes;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching singleAssetOutput.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching singleAssetOutput.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

export interface IFetchDeleteSingleAssetOutputProps {
  outputTypeId: string;
}

export const fetchDeleteSingleAssetOutput =
  (props: IFetchDeleteSingleAssetOutputProps): AppThunk<Promise<ISingleAssetOutput>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/outputTypes`, props), {
        method: "DELETE",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (
        parsedResp &&
        parsedResp.success &&
        parsedResp.data &&
        parsedResp.data.outputTypes &&
        parsedResp.data.outputTypes.length
      ) {
        dispatch(requestDeleteSingleAssetOutput(parsedResp.data.outputTypes[0]));
        return parsedResp.data.outputTypes[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting singleAssetOutput.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting singleAssetOutput.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export const fetchSingleAssetOutputIfNeeded = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  if (!selectorGetSingleAssetOutputs(getState()).length) {
    await dispatch(fetchSearchSingleAssetOutput({ pageNumber: 1, pageSize: 100 }));
  }

  return;
};

export interface IFetchImportSingleAssetOutputProps {
  fileId: string;
}

export const fetchImportSingleAssetOutput =
  (props: IFetchImportSingleAssetOutputProps): AppThunk<Promise<ISingleAssetOutput>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputTypes/import`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputType) {
        dispatch(receiveSingleAssetOutput([parsedResp.data.outputType]));
        return parsedResp.data.outputType;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error while importing output type.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error retrieving output type.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
