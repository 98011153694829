import { Button, DialogContent, Divider, Grid, Typography } from "@mui/material";
import InputIcon from "@mui/icons-material/Input";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchCreateFeedback } from "../../../redux/feedback/actions";
import { FeedbackTypeEnum } from "../../../utilities/types/Feedback";
import { IModel } from "../../../utilities/types/Model";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";

type ContributorModalProps = { isOpen: boolean; close: () => void; model: IModel };
export default function ConfirmModelContributorModal({ isOpen, close, model }: ContributorModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const submit = async () => {
    setIsSubmitting(true);
    await dispatch(
      fetchCreateFeedback({
        type: FeedbackTypeEnum.Contributor,
        contributorModelId: model.modelId,
        text: `Model: ${model.title} (${model.modelId})`,
        pleaseContact: false,
        successPopupMessage: "Request sent",
      })
    );
    setIsSubmitting(false);
    close();
  };

  return (
    <WidgetModalBase
      open={isOpen}
      handleCancel={close}
      title="Please confirm request"
      subtitle="You'll become a contributor to this model"
      headerIcon={<InputIcon />}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        <LoaderAbsoluteCentred loading={isSubmitting} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Once approved you'll be able to participate in discussions and improve this model.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider light={true} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button disabled={isSubmitting} onClick={close}>
              Close
            </Button>
            &nbsp;&nbsp;
            <Button disabled={isSubmitting} onClick={submit} variant="outlined" color="primary">
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </WidgetModalBase>
  );
}
