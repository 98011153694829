import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { ISolverOutputTypeMapping } from "../../utilities/types/SolverOutputTypeMapping";
import { fetchSearchSolverOutputTypeMappings } from "../../redux/solverOutputTypeMapping/actions";


export interface IUseFetchSolverOutputTypeMappingsPageHookProps {
    pageNumber: number,
    pageSize: number,
    minPageNumberToFetch?: number,
    solverId?: string,
    solverOutputTypeMappingId?: string,
}


export const useFetchSolverOutputTypeMappingsPageHook = ({ pageNumber, minPageNumberToFetch = 1, pageSize, solverOutputTypeMappingId, solverId }: IUseFetchSolverOutputTypeMappingsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ISolverOutputTypeMapping[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ISolverOutputTypeMapping[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve results
                var resp: any = await dispatch(fetchSearchSolverOutputTypeMappings({
                    pageSize,
                    pageNumber,
                    solverId,
                    solverOutputTypeMappingId,
                }));

                // Check if there are more pages to show
                if (resp && resp.success && resp.data && resp.data.solverOutputTypeMappings && resp.data.solverOutputTypeMappings.length) {
                    setMorePages(resp.data.solverOutputTypeMappings.length >= pageSize);
                    setLastResultSet(resp.data.solverOutputTypeMappings);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [pageNumber, pageSize, dispatch, minPageNumberToFetch, solverId, solverOutputTypeMappingId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.solverOutputTypeMappingId === x.solverOutputTypeMappingId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}