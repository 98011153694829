import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchCommentsProps, fetchSearchComments } from "../../redux/comment/actions";
import { IComment } from "../../utilities/types/Comment";

interface IUseFetchCommentsPageHookProps extends IFetchSearchCommentsProps {
  minPageNumberToFetch: number;
}

export const useFetchCommentsPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
  discussionId,
  parentId,
}: IUseFetchCommentsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IComment[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IComment[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      if (reFetch) setReFetch(false);

      try {
        // Retrieve models
        var comments = (await dispatch(
          fetchSearchComments({
            pageSize,
            pageNumber,
            discussionId,
            parentId,
          })
        )) as unknown as IComment[];

        if (comments && comments.length) {
          setMorePages(comments.length >= pageSize);
          setLastResultSet(comments);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, discussionId, parentId, reFetch]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.commentId === x.commentId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
    setReFetch,
  };
};
