import { Button, Divider, FormControl, Grid } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import React, { useState } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import { selectorGetUserRoles } from "../../../redux/userRole/selectors";
import { fetchCreateUserToUserRoleMappings } from "../../../redux/userToRoleMapping/actions";
import { IUserRole } from "../../../utilities/types/UserRole";
import { IUserToRoleMapping } from "../../../utilities/types/UserToRoleMapping";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import ModalUserRoleCreate from "../modals/ModalUserRoleCreate";
import PickListUserRoleMapping from "./PickListUserRoleMapping";
import { selectorGetUserToRoleMappingByUserDetailId } from "../../../redux/userToRoleMapping/selectors";

interface FormValues {
  userRoleIds: string[];
}

interface FormProps {
  userDetailId: string;
  onCompleteCallback(UserToRoleMapping?: IUserToRoleMapping[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IUserToRoleMapping, AnyAction>;
  fetchingUserRole: boolean;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const store = useSelector((store: RootState) => store);
  var userRoles = selectorGetUserRoles(store);
  const userRoleMappings = selectorGetUserToRoleMappingByUserDetailId(store, props.userDetailId);

  const existingRoleIds = userRoleMappings.map((item) => item.userRoleId);

  const defaultUserRoles = userRoles.filter((item) => existingRoleIds.includes(item.userRoleId));

  const [showCreateUserRoleModal, setShowCreateUserRoleModal] = useState(false);

  function toggleShowCreateRoleModal() {
    setShowCreateUserRoleModal(!showCreateUserRoleModal);
  }

  const { setFieldValue, fetchingUserRole } = props;
  const onChange = useCallback(
    (ids: IUserRole[]) =>
      setFieldValue(
        "userRoleIds",
        ids.map((id) => id.userRoleId)
      ),
    [setFieldValue]
  );

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <PickListUserRoleMapping
              userRoles={userRoles}
              defaultUserRoles={defaultUserRoles}
              onChange={onChange}
              fetchingUserRole={fetchingUserRole}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid container item xs={12}>
          <Button variant="outlined" onClick={toggleShowCreateRoleModal}>
            Create
          </Button>
          <div style={{ flex: "1" }} />
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Add
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
          <ModalUserRoleCreate
            open={showCreateUserRoleModal}
            onCancelCallback={toggleShowCreateRoleModal}
            onCompleteCallback={toggleShowCreateRoleModal}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const FormUserRoleMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    userRoleIds: [],
    userDetailId: "",
  }),
  validationSchema: Yup.object().shape({
    userRoleIds: Yup.string().label("User Roles").required("Please provide at least one user Role"),
  }),
  handleSubmit: async (values: any, { setSubmitting, props }: any) => {
    const { onCompleteCallback, dispatch, userDetailId } = props;

    // Map dispatch via props
    var createdUserRoleMapping = await dispatch(
      fetchCreateUserToUserRoleMappings({
        ...values,
        userDetailId,
      })
    );

    setSubmitting(false);

    if (createdUserRoleMapping) onCompleteCallback(createdUserRoleMapping);
  },
})(InnerForm);

export default FormUserRoleMappingCreate;
