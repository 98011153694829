import { Chip, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ContactRequestedIcon from "@mui/icons-material/ContactMailTwoTone";
import HeaderIcon from "@mui/icons-material/FeedbackTwoTone";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ViewUserIcon from "@mui/icons-material/PersonOutlineTwoTone";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "../../redux";
import { fetchDeleteFeedback } from "../../redux/feedback/actions";
import { selectorGetFeedbacks } from "../../redux/feedback/selectors";
import { selectorGetUserDetailById } from "../../redux/userDetail/selectors";
import { GetUserLinkByUserId } from "../../routes/RouteLinkHelpers";
import { FeedbackTypeEnum, IFeedback } from "../../utilities/types/Feedback";
import { useAddRemoveContributor } from "../contributor/Hooks";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchFeedbacksPageHook } from "./Hooks";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .feedbackWrapper": {
    border: "1px solid #EEE",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  "& .feedbackMainWrapper": {
    marginLeft: theme.spacing(1),
  },
  "& .contentDiv": {
    maxHeight: 250,
    overflowY: "auto",
  },
}));

type IFeedbackEnquiryContainerProps = {
  canEdit: boolean;
};

const FeedbackEnquiryContainer = ({ canEdit }: IFeedbackEnquiryContainerProps) => {
  const feedbacks = useSelector((store: RootState) => selectorGetFeedbacks(store));
  const { fetching } = useFetchFeedbacksPageHook({
    pageNumber: 1,
    pageSize: 10,
    minPageNumberToFetch: 1,
  });

  return <FeedbackEnquiryContainerDisplay canEdit={canEdit} feedbacks={feedbacks} loading={fetching} />;
};

type IFeedbackEnquiryContainerDisplayProps = {
  canEdit: boolean;
  feedbacks: IFeedback[];
  loading: boolean;
};

const FeedbackEnquiryContainerDisplay = ({ feedbacks, loading, canEdit }: IFeedbackEnquiryContainerDisplayProps) => {
  return (
    <WidgetSectionBase
      headerText="Feedback"
      subheaderText="User feedback."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DivWrapper>
        <div className="contentDiv">
          {loading ? (
            <LoaderAbsoluteCentred loading={loading} />
          ) : (
            <AnimationWrapper>
              <div>
                {feedbacks.map((feedback) => {
                  return <FeedbackRow feedback={feedback} canEdit={canEdit} />;
                })}
                {(!feedbacks || !feedbacks.length) && (
                  <WidgetNoResultsPlaceholder text="No Feedback" icon={HeaderIcon} flip={true} />
                )}
              </div>
            </AnimationWrapper>
          )}
        </div>
      </DivWrapper>
    </WidgetSectionBase>
  );
};

function FeedbackRow({ feedback, canEdit }: { feedback: IFeedback; canEdit: boolean }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const closeMenu = () => setMenuAnchorEl(undefined);

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const user = useSelector((state: RootState) => selectorGetUserDetailById(state, feedback.createdBy));

  return (
    <>
      <Grid container className="feedbackWrapper">
        <Grid item xs={9} style={{ paddingLeft: 8 }}>
          <div className="feedbackMainWrapper">
            <div>
              <Typography variant="body1" noWrap={true}>
                {FeedbackTypeEnum[feedback.type || 0]} {user ? `(${user.displayName})` : null}
              </Typography>
              <Typography style={{ opacity: 0.8, fontSize: "85%", whiteSpace: "pre-wrap" }}>{feedback.text}</Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          {canEdit ? (
            <>
              <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
              >
                <MenuItem component={NavLink} to={GetUserLinkByUserId(feedback.createdBy)}>
                  <ListItemIcon>
                    <ViewUserIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="User" />
                </MenuItem>

                {canEdit && feedback.type === FeedbackTypeEnum.Contributor && (
                  <ContributorMenuItems feedback={feedback} close={closeMenu} />
                )}
              </Menu>
            </>
          ) : null}
        </Grid>
        {feedback.pleaseContact ? (
          <Grid item xs={12} style={{ margin: 16 }}>
            <Chip
              avatar={<ContactRequestedIcon />}
              label="Contact Requested"
              variant="outlined"
              size="small"
              style={{ paddingLeft: 4 }}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

function ContributorMenuItems({ feedback, close }: { feedback: IFeedback; close: () => void }) {
  const { feedbackId, userId, contributorModelId } = feedback;

  const [deleting, deleteFeedback] = useDeleteFeedback(feedbackId, close);
  const [adding, addContributor] = useAddRemoveContributor();
  const approve = () =>
    addContributor({ userId, modelId: contributorModelId as string }).then((success) => {
      success && deleteFeedback();
    });

  const loading = adding || deleting;

  return (
    <>
      <LoaderAbsoluteCentred loading={loading} />
      <MenuItem component="button" disabled={loading} onClick={approve}>
        <ListItemIcon>
          <CheckIcon fontSize="small" style={{ color: "green" }} />
        </ListItemIcon>
        <ListItemText primary="Approve" secondary="Approve contributor access" />
      </MenuItem>

      <MenuItem component="button" disabled={loading} onClick={deleteFeedback}>
        <ListItemIcon>
          <ClearIcon fontSize="small" style={{ color: "red" }} />
        </ListItemIcon>
        <ListItemText primary="Decline" secondary="Decline contributor access" />
      </MenuItem>
    </>
  );
}

function useDeleteFeedback(feedbackId: string, onSuccess: () => void): [boolean, () => void] {
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);

  const deleteFeedback = async () => {
    setUpdating(true);
    await dispatch(fetchDeleteFeedback({ feedbackId }));
    setUpdating(false);
    onSuccess();
  };

  return [updating, deleteFeedback];
}

export default FeedbackEnquiryContainer;
