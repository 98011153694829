import { RootState } from "..";
import { IFeedback } from "../../utilities/types/Feedback";

export function selectorGetFeedbackById(state: RootState, feedbackId: string) : IFeedback | undefined {

    if (!state.feedbacks || !state.feedbacks.byId) return undefined;

    return state.feedbacks.byId[feedbackId];
}


export function selectorGetFeedbacks(state: RootState) : IFeedback[] {

    if (!state.feedbacks || !state.feedbacks.byId) return [];

    return sortFeedbacksByUpdatedDescending(Object.values(state.feedbacks.byId));
}


export function sortFeedbacksByTitleDescending(feedbacks: IFeedback[]) {
    return feedbacks.sort((a, b) => {
        return a.text === b.text ? 0 : a.text < b.text ? -1 : 1;
    });
}


export function sortFeedbacksByTitleAscending(feedbacks: IFeedback[]) {
    return feedbacks.sort((a, b) => {
        return a.text === b.text ? 0 : a.text > b.text ? -1 : 1;
    });
}


export function selectorGetFeedbacksByUpdatedDesc(state: RootState) : IFeedback[] {

    if(!state.feedbacks || !state.feedbacks.byId) return [];

    return sortFeedbacksByUpdatedDescending(Object.values(state.feedbacks.byId));
}


export function sortFeedbacksByUpdatedDescending(feedbacks: IFeedback[]) {
    return feedbacks.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
}