import { RootState } from "..";
import { IDiscussion } from "../../utilities/types/Discussion";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import moment from "moment";

export function selectorGetDiscussionById(state: RootState, discussionId: string): IDiscussion | undefined {

    if (!state.discussions || !state.discussions.byId) return undefined;

    return state.discussions.byId[discussionId];
}


export function selectorGetDiscussions(state: RootState): IDiscussion[] {

    if (!state.discussions || !state.discussions.byId) return [];

    return sortDiscussionsByCreatedAtDescending(Object.values(state.discussions.byId));
}


export function selectorGetDiscussionsByEntityTypeAndEntityId(state: RootState, entityType: EntityTypeEnum, entityId: string): IDiscussion[] {

    if (!state.discussions || !state.discussions.byId) return [];

    return sortDiscussionsByCreatedAtDescending(Object.values(state.discussions.byId))
        .filter(x => x.entityId === entityId && x.entityType === entityType);
}


function sortDiscussionsByCreatedAtDescending(discussions: IDiscussion[]) {
    return discussions.sort((a, b) => (moment.utc(b.created).unix() - moment.utc(a.created).unix()));
}