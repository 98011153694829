import { RECEIVE_SYSTEM, SystemActionTypes, SystemState } from './types';
import { RootState } from '..';

export function receiveSystemState(systemState: SystemState) : SystemActionTypes {
    return {
        type: RECEIVE_SYSTEM,
        payload: systemState,
    }
}


export function selectorGetSystemState(state: RootState) : SystemState | undefined {
    return state.system;
}
