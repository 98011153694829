import React, { useState } from "react";
import { Button } from "@mui/material";
import HeaderIcon from "@mui/icons-material/BubbleChartTwoTone";
import { useHistory } from "react-router-dom";
import { GetAdminImportsPage } from "../../routes/RouteLinkHelpers";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import ModalFileUpload from "../file/modals/ModalFileUpload";
import { GetUserId } from "../../utilities/ApiUtils";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { IFile } from "../../utilities/types/File";

const SingleAssetOutputImportWidget = () => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const history = useHistory();

  const handleUploadModalToggle = () => setUploadModalOpen(!uploadModalOpen);

  const onUploadCompleteCallback = (file?: IFile) => {
    if (file) {
      setUploadModalOpen(false);
      history.push(GetAdminImportsPage());
    }
  };

  return (
    <>
      <WidgetSummaryCountAndButtons
        headerIcon={<HeaderIcon />}
        captionText="Output Types"
        mainText="Manage imports"
        buttons={[
          <Button onClick={handleUploadModalToggle} size="small" variant="outlined" color="primary">
            Browse
          </Button>,
        ]}
      />
      <ModalFileUpload
        handleCancel={handleUploadModalToggle}
        open={uploadModalOpen}
        entityId={GetUserId()}
        entityType={EntityTypeEnum.OutputTypeImport}
        onUploadCompleteCallback={onUploadCompleteCallback}
        canSpecifyPath={false}
      />
    </>
  );
};

export default SingleAssetOutputImportWidget;
