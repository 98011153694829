import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormPlanUpdate from "../forms/FormPlanUpdate";
import { IPlan } from "../../../utilities/types/Plan";
import MapIcon from "@mui/icons-material/MapRounded";

export interface IModalPlanUpdateProps {
  open: boolean;
  plan: IPlan;
  onCancelCallback(): void;
  onCompleteCallback(plan?: IPlan): void;
  canEdit: boolean;
}

function ModalPlanUpdate({ onCancelCallback, open, onCompleteCallback, plan, canEdit }: IModalPlanUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a plan"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormPlanUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          plan={plan}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalPlanUpdate;
