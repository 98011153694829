import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";

import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { DialogActions, Button, Divider, DialogContent, Typography } from "@mui/material";
import PermissionImg from "../../../assets/img/permission.png";
import { RootState } from "../../../redux";
import { ACTIONS_INSUFFICIENT_PERMISSION_MAPPING } from "../../../redux/insufficientPermission/types";

const ModalInsufficientUserPermissions = () => {
  const dispatch = useDispatch();
  const { showModal, message } = useSelector((store: RootState) => store.insufficientPermission);

  const handleCloseModal = () => {
    dispatch({
      type: ACTIONS_INSUFFICIENT_PERMISSION_MAPPING.RESET,
    });
  };

  return (
    <WidgetModalBase
      handleCancel={handleCloseModal}
      open={showModal}
      title="Insufficient permissions"
      subtitle="You don't have sufficient permission to perform this action."
      headerIcon={<InfoIcon />}
      style={{ maxWidth: 550 }}
    >
      <DialogContent style={{ textAlign: "center" }}>
        <img
          src={PermissionImg}
          style={{
            maxWidth: 180,
          }}
          alt="permission"
        />
        {message && <Typography variant="h6">{message}</Typography>}
        <Typography variant="subtitle2">
          This feature is exclusive to our paid subscribers. Please reach out to us for further information and details.
        </Typography>

        <a
          href={"https://www.modla.co/contact"}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Button style={{ marginTop: 16 }} variant="contained">
            Contact Us
          </Button>
        </a>
      </DialogContent>
      <Divider light={true} style={{ marginBottom: 16, marginTop: 8 }} />
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseModal}>
          Close
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
};

export default ModalInsufficientUserPermissions;
