import { ACTIONS_ASSET_INPUT_FIELD_LIST_VALUE, AssetInputFieldListValueSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IAssetInputFieldListValue } from '../../utilities/types/AssetInputFieldListValue';
import { receiveAssetInputFields } from '../assetInputField/actions';


export const receiveAssetInputFieldListValues = (assetInputFieldListValues: IAssetInputFieldListValue[]) => {

    var byIdObjectToDispatch: { [key: string]: IAssetInputFieldListValue } = {};

    for (var i = 0; i < assetInputFieldListValues.length; i++) {
        byIdObjectToDispatch[assetInputFieldListValues[i].assetInputFieldListValueId] = assetInputFieldListValues[i];
    }

    return {
        type: ACTIONS_ASSET_INPUT_FIELD_LIST_VALUE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteAssetInputFieldListValue = (assetInputFieldListValue: IAssetInputFieldListValue) => ({
    type: ACTIONS_ASSET_INPUT_FIELD_LIST_VALUE.DELETE,
    byId: { [assetInputFieldListValue.assetInputFieldListValueId]: assetInputFieldListValue }
});


export interface IFetchCreateAssetInputFieldListValueProps {
    assetInputFieldId: string,
    displayText: string,
    value: number,
    orderNumber: number,
    isDefault: boolean,
}
export interface IFetchCreateAssetInputFieldListValuesProps {
    assetInputFieldListValues: IFetchCreateAssetInputFieldListValueProps[]
}


export const fetchCreateAssetInputFieldListValue = (assetInputFieldListValuesToCreate: IFetchCreateAssetInputFieldListValuesProps): AppThunk<Promise<IAssetInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/assetInputFieldListValues`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(assetInputFieldListValuesToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldListValues) {
            dispatch(receiveAssetInputFieldListValues(parsedResp.data.assetInputFieldListValues));
            dispatch(receiveAssetInputFields(parsedResp.data.assetInputFields));
            return parsedResp.data.assetInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while creating field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateAssetInputFieldListValueProps {
    assetInputFieldListValueId: string,
    displayText: string,
    value: number,
    orderNumber: number,
}


export const fetchUpdateAssetInputFieldListValue = (props: IFetchUpdateAssetInputFieldListValueProps): AppThunk<Promise<IAssetInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/assetInputFieldListValues`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldListValues) {
            dispatch(receiveAssetInputFieldListValues(parsedResp.data.assetInputFieldListValues));
            dispatch(receiveAssetInputFields(parsedResp.data.assetInputFields));
            return parsedResp.data.assetInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchAssetInputFieldListValuesProps {
    pageNumber: number,
    pageSize: number,
    text?: string,
    assetInputFieldId?: string,
    assetInputFieldListValueId?: string,
    modelId: string,
    createdBy?: string,
    orderType?: AssetInputFieldListValueSearchOrderTypeEnum,
}


export const fetchSearchAssetInputFieldListValues = (searchParams: IFetchSearchAssetInputFieldListValuesProps): AppThunk<Promise<IAssetInputFieldListValue[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/assetInputFieldListValues`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldListValues) {
            dispatch(receiveAssetInputFieldListValues(parsedResp.data.assetInputFieldListValues));
            return parsedResp.data.assetInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching field value.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteAssetInputFieldListValueProps {
    assetInputFieldListValueId: string,
}


export const fetchDeleteAssetInputFieldListValue = (props: IFetchDeleteAssetInputFieldListValueProps): AppThunk<Promise<IAssetInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/assetInputFieldListValues`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldListValue) {
            dispatch(requestDeleteAssetInputFieldListValue(parsedResp.data.assetInputFieldListValue));
            return parsedResp.data.assetInputFieldListValue;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting field values.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}