import React from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import ModelsIcon from "@mui/icons-material/PersonTwoTone";
import { NavLink } from "react-router-dom";
import { GetAdminUsersPage } from "../../routes/RouteLinkHelpers";

const UserDetailsManagementWidget = () => {
  return (
    <WidgetSummaryCountAndButtons
      key="UserDetailsManagementWidget"
      headerIcon={<ModelsIcon />}
      captionText="Users"
      mainText="Manage users"
      buttons={[
        <Button
          size="small"
          key="manage-btn"
          variant="outlined"
          color="primary"
          component={NavLink}
          to={GetAdminUsersPage}
        >
          Manage
        </Button>,
      ]}
    />
  );
};

export default UserDetailsManagementWidget;
