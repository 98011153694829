import { IAssetInputFieldCategory } from "../../utilities/types/AssetInputFieldCategory";


export const ACTIONS_ASSET_INPUT_FIELD_CATEGORY = {
    INVALIDATE: 'INVALIDATE_ASSET_INPUT_FIELD_CATEGORIES',
    RECEIVE: 'RECEIVE_ASSET_INPUT_FIELD_CATEGORIES',
    DELETE: 'DELETE_ASSET_INPUT_FIELD_CATEGORY',
    UPDATE: 'UPDATE_ASSET_INPUT_FIELD_CATEGORIES',
}

interface ActionAssetInputFieldCategoriesInvalidate {
    type: typeof ACTIONS_ASSET_INPUT_FIELD_CATEGORY.INVALIDATE,
    byId: { [key: string]: IAssetInputFieldCategory },
}

interface ActionAssetInputFieldCategoriesReceive {
    type: typeof ACTIONS_ASSET_INPUT_FIELD_CATEGORY.RECEIVE
    byId: { [key: string]: IAssetInputFieldCategory },
}

export interface AssetInputFieldCategoryState {
    byId: { [key: string]: IAssetInputFieldCategory },
}

export enum AssetInputFieldCategorySearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
    OrderNumberAsc = 30,
    OrderNumberDesc = 40,
}


export type ActionTypesAssetInputFieldCategory = ActionAssetInputFieldCategoriesReceive | ActionAssetInputFieldCategoriesInvalidate;