import { IUserRolePermission } from "../../utilities/types/UserRolePermission";

export const ACTIONS_USER_ROLE_PERMISSION = {
    INVALIDATE: 'INVALIDATE_USER_ROLE_PERMISSIONS',
    RECEIVE: 'RECEIVE_USER_ROLE_PERMISSIONS',
    UPDATE: 'UPDATE_USER_ROLE_PERMISSIONS',
    DELETE: 'DELETE_USER_ROLE_PERMISSION',
}

interface ActionUserRolePermissionsInvalidate {
    type: typeof ACTIONS_USER_ROLE_PERMISSION.INVALIDATE,
    byId: { [key: string]: IUserRolePermission },
}

interface ActionUserRolePermissionsReceive {
    type: typeof ACTIONS_USER_ROLE_PERMISSION.RECEIVE
    byId: { [key: string]: IUserRolePermission },
}

interface ActionUserRolePermissionsUpdate {
    type: typeof ACTIONS_USER_ROLE_PERMISSION.UPDATE
    byId: { [key: string]: IUserRolePermission },
}

// interface ActionUserRolePermissionDelete {
//     type: typeof ACTIONS_USER_ROLE_PERMISSION.DELETE
//     byId: { [key: string]: IUserRolePermission },
// }

export interface UserRolePermissionState {
    byId: { [key: string]: IUserRolePermission },
}

export type ActionTypesUserRolePermission = ActionUserRolePermissionsInvalidate | ActionUserRolePermissionsReceive | ActionUserRolePermissionsUpdate;