import React from 'react';
import { Switch, Route } from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import WorkflowPage from '../components/workflow/pages/WorkflowPage';


const RouteWorkflow = () => {

    return (
        <Switch>
            <Route render={props => <LayoutDefault routeProps={props}><WorkflowPage {...props} /></LayoutDefault>} />
        </Switch>
    )
}


export default RouteWorkflow;