import React from "react";
import { Avatar, Typography, styled } from "@mui/material";

import { Message } from "@mui/icons-material";
import AnimationWrapper from "../animations/AnimationWrapper";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .noMessagesIconWrapper": {
    opacity: 0.5,
    width: 100,
    height: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
  "& .noMessagesIcon": {
    color: "rgba(0,0,0,0.5)",
    width: 75,
    height: 75,
  },
  "& .noResultsTextWrapper": {
    textAlign: "center",
  },
  "& .noResultsText": {
    opacity: 0.5,
    textAlign: "center",
    fontSize: "85%",
    marginBottom: 0,
  },
  "& .noResultsDescription": {
    opacity: 0.5,
    textAlign: "center",
    marginBottom: 0,
    marginTop: -8,
  },
  "& .spin": {
    animation: "$spin 5000ms infinite",
  },
  "& .flip": {
    animation: "$flip 5000ms infinite",
  },
  "& .fade": {
    animation: "$fade 5000ms infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes flip": {
    "0%": {
      transform: "rotateY(0)",
    },
    "25%": {
      transform: "rotateY(360deg)",
    },
    "75%": {
      transform: "rotateY(0)",
    },
  },
  "@keyframes fade": {
    "0%": {
      opacity: 1,
    },
    "25%": {
      opacity: 0.5,
    },
    "75%": {
      opacity: 1,
    },
  },
}));

interface WidgetNoResultsPlaceholderProps {
  icon?: any;
  text?: string;
  description?: string;
  spin?: boolean;
  flip?: boolean;
  fade?: boolean;
}

export function WidgetNoResultsPlaceholder({
  icon = Message,
  text = "No results",
  description,
  spin = false,
  flip = false,
  fade = false,
}: WidgetNoResultsPlaceholderProps) {
  const Icon = icon;

  return (
    <AnimationWrapper>
      <DivWrapper>
        <Avatar sizes="lg" className="noMessagesIconWrapper">
          <Icon className={`noMessagesIcon ${spin ? "spin" : null} ${flip ? "flip" : null} ${fade ? "fade" : null}`} />
        </Avatar>
        <div className="noResultsTextWrapper">
          <Typography variant="overline" className="noResultsText">
            {text}
          </Typography>
          {description && (
            <Typography variant="caption" component="p" color="textSecondary" className="noResultsDescription">
              {description}
            </Typography>
          )}
        </div>
      </DivWrapper>
    </AnimationWrapper>
  );
}
