import { ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE, SummaryOutputTypeInputFieldListValueSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ISummaryOutputTypeInputFieldListValue } from '../../utilities/types/SummaryOutputTypeInputFieldListValue';
import { receiveSummaryOutputTypeInputFields } from '../summaryOutputTypeInputField/actions';


export const receiveSummaryOutputTypeInputFieldListValues = (summaryOutputTypeInputFieldListValues: ISummaryOutputTypeInputFieldListValue[]) => {

    var byIdObjectToDispatch: { [key: string]: ISummaryOutputTypeInputFieldListValue } = {};

    for (var i = 0; i < summaryOutputTypeInputFieldListValues.length; i++) {
        byIdObjectToDispatch[summaryOutputTypeInputFieldListValues[i].summaryOutputTypeInputFieldListValueId] = summaryOutputTypeInputFieldListValues[i];
    }

    return {
        type: ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSummaryOutputTypeInputFieldListValue = (summaryOutputTypeInputFieldListValue: ISummaryOutputTypeInputFieldListValue) => ({
    type: ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.DELETE,
    byId: { [summaryOutputTypeInputFieldListValue.summaryOutputTypeInputFieldListValueId]: summaryOutputTypeInputFieldListValue }
});


export interface IFetchCreateSummaryOutputTypeInputFieldListValueProps {
    summaryOutputTypeInputFieldId: string,
    displayText: string,
    value: number,
    orderNumber: number,
    isDefault: boolean,
}


export const fetchCreateSummaryOutputTypeInputFieldListValue = (summaryOutputTypeInputFieldListValueToCreate: IFetchCreateSummaryOutputTypeInputFieldListValueProps): AppThunk<Promise<ISummaryOutputTypeInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFieldListValues`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(summaryOutputTypeInputFieldListValueToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputFieldListValues) {
            dispatch(receiveSummaryOutputTypeInputFieldListValues(parsedResp.data.summaryOutputTypeInputFieldListValues));
            dispatch(receiveSummaryOutputTypeInputFields(parsedResp.data.summaryOutputTypeInputFields));
            return parsedResp.data.summaryOutputTypeInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while creating field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSummaryOutputTypeInputFieldListValueProps {
    summaryOutputTypeInputFieldListValueId: string,
    displayText: string,
    value: number,
    orderNumber: number,
}


export const fetchUpdateSummaryOutputTypeInputFieldListValue = (props: IFetchUpdateSummaryOutputTypeInputFieldListValueProps): AppThunk<Promise<ISummaryOutputTypeInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFieldListValues`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputFieldListValues) {
            dispatch(receiveSummaryOutputTypeInputFieldListValues(parsedResp.data.summaryOutputTypeInputFieldListValues));
            dispatch(receiveSummaryOutputTypeInputFields(parsedResp.data.summaryOutputTypeInputFields));
            return parsedResp.data.summaryOutputTypeInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchSummaryOutputTypeInputFieldListValuesProps {
    pageNumber: number,
    pageSize: number,
    text?: string,
    summaryOutputTypeInputFieldId?: string,
    summaryOutputTypeInputFieldListValueId?: string,
    summaryOutputTypeId?: string,
    createdBy?: string,
    orderType?: SummaryOutputTypeInputFieldListValueSearchOrderTypeEnum,
}


export const fetchSearchSummaryOutputTypeInputFieldListValues = (searchParams: IFetchSearchSummaryOutputTypeInputFieldListValuesProps): AppThunk<Promise<ISummaryOutputTypeInputFieldListValue[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFieldListValues`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputFieldListValues) {
            dispatch(receiveSummaryOutputTypeInputFieldListValues(parsedResp.data.summaryOutputTypeInputFieldListValues));
            return parsedResp.data.summaryOutputTypeInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching field value.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSummaryOutputTypeInputFieldListValueProps {
    summaryOutputTypeInputFieldListValueId: string,
}


export const fetchDeleteSummaryOutputTypeInputFieldListValue = (props: IFetchDeleteSummaryOutputTypeInputFieldListValueProps): AppThunk<Promise<ISummaryOutputTypeInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFieldListValues`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputFieldListValue) {
            dispatch(requestDeleteSummaryOutputTypeInputFieldListValue(parsedResp.data.summaryOutputTypeInputFieldListValue));
            return parsedResp.data.summaryOutputTypeInputFieldListValue;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}