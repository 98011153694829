import { IAssetInputFieldListValue } from "../../utilities/types/AssetInputFieldListValue";


export const ACTIONS_ASSET_INPUT_FIELD_LIST_VALUE = {
    INVALIDATE: 'INVALIDATE_ASSET_INPUT_FIELD_LIST_VALUES',
    RECEIVE: 'RECEIVE_ASSET_INPUT_FIELD_LIST_VALUES',
    DELETE: 'DELETE_ASSET_INPUT_FIELD_LIST_VALUE',
    UPDATE: 'UPDATE_ASSET_INPUT_FIELD_LIST_VALUES',
}

interface ActionAssetInputFieldListValuesInvalidate {
    type: typeof ACTIONS_ASSET_INPUT_FIELD_LIST_VALUE.INVALIDATE,
    byId: { [key: string]: IAssetInputFieldListValue },
}

interface ActionAssetInputFieldListValuesReceive {
    type: typeof ACTIONS_ASSET_INPUT_FIELD_LIST_VALUE.RECEIVE
    byId: { [key: string]: IAssetInputFieldListValue },
}

export interface AssetInputFieldListValueState {
    byId: { [key: string]: IAssetInputFieldListValue },
}

export enum AssetInputFieldListValueSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesAssetInputFieldListValue = ActionAssetInputFieldListValuesReceive | ActionAssetInputFieldListValuesInvalidate;