import { IEntityPermission } from "../../utilities/types/EntityPermission";

export const ACTIONS_ENTITY_PERMISSION = {
    INVALIDATE: 'INVALIDATE_ENTITY_PERMISSIONS',
    RECEIVE: 'RECEIVE_ENTITY_PERMISSIONS',
    UPDATE: 'UPDATE_ENTITY_PERMISSIONS',
    DELETE: 'DELETE_ENTITY_PERMISSION',
}

interface ActionEntityPermissionsInvalidate {
    type: typeof ACTIONS_ENTITY_PERMISSION.INVALIDATE,
    byId: { [key: string]: IEntityPermission },
}

interface ActionEntityPermissionsReceive {
    type: typeof ACTIONS_ENTITY_PERMISSION.RECEIVE
    byId: { [key: string]: IEntityPermission },
}

interface ActionEntityPermissionsUpdate {
    type: typeof ACTIONS_ENTITY_PERMISSION.UPDATE
    byId: { [key: string]: IEntityPermission },
}

// interface ActionEntityPermissionDelete {
//     type: typeof ACTIONS_ENTITY_PERMISSION.DELETE
//     byId: { [key: string]: IEntityPermission },
// }

export interface EntityPermissionState {
    byId: { [key: string]: IEntityPermission },
}

export type ActionTypesEntityPermission = ActionEntityPermissionsInvalidate | ActionEntityPermissionsReceive | ActionEntityPermissionsUpdate;