import React, { useMemo } from "react";
import MaterialTable from "@material-table/core";
import { Paper, Checkbox, FormHelperText, styled } from "@mui/material";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
import BuildIcon from "@mui/icons-material/SettingsTwoTone";
import FunctionalFailureIcon from "@mui/icons-material/SmsFailedRounded";
import FailureModeIcon from "@mui/icons-material/DeveloperModeRounded";
import teal from "@mui/material/colors/deepPurple";
import IconOrange from "@mui/material/colors/orange";
import IconIndigo from "@mui/material/colors/indigo";

import { LinkModeTypeEnum } from "./types";
import { useComponentAndFunctionList } from "./useComponentAndFunctionList";

const DivWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  "& .iconWrapper": {
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .faliureModeIcon": {
    backgroundColor: IconOrange[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .funtionFailureIcon": {
    backgroundColor: IconIndigo[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .funtionIcon": {
    backgroundColor: teal[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
}));

type LinkToModesTableSelectionProps = {
  modelId: string;
  targetIds: string[];
  showSelection: true;
  handleSelectionChange: (selectedRows: any[]) => void;
};

type LinkToModesTableNonSelectionProps = {
  modelId: string;
  targetIds: string[];
  showSelection: false;
  fieldName: string;
  setFieldValue: (field: string, value: any) => void;
  showCheckboxCondition?: (rowData: any) => boolean;
  specificType: LinkModeTypeEnum;
  error?: string;
};

type LinkToModesTableProps = LinkToModesTableSelectionProps | LinkToModesTableNonSelectionProps;

export const LinkToModesTable: React.FC<LinkToModesTableProps> = (props) => {
  const { modelId, targetIds, showSelection } = props;

  const { dataList } = useComponentAndFunctionList(
    modelId,
    showSelection,
    !showSelection ? props.specificType : undefined
  );

  const tableData = useMemo(() => {
    return dataList.map((item) => ({
      ...item,
      tableData: {
        ...item.tableData,
        checked: targetIds.includes(item.id),
      },
    }));
  }, [dataList, targetIds]);

  const handleCheckboxChange = (id: string) => {
    if (!showSelection && props.setFieldValue && props.fieldName) {
      const updatedIds = targetIds.includes(id) ? targetIds.filter((item) => item !== id) : [...targetIds, id];
      props.setFieldValue(props.fieldName, updatedIds);
    }
  };

  return (
    <DivWrapper>
      <MaterialTable
        title=""
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={tableData}
        columns={[
          ...(!showSelection
            ? [
                {
                  title: "Select",
                  field: "select",
                  width: "25px",
                  sorting: false,
                  filtering: false,
                  render: (rowData: { id: string }) =>
                    !props?.showCheckboxCondition || props?.showCheckboxCondition(rowData) ? (
                      <Checkbox
                        checked={targetIds.includes(rowData.id)}
                        onChange={() => handleCheckboxChange(rowData.id)}
                      />
                    ) : null,
                },
              ]
            : []),
          {
            title: "Type",
            field: "type",
            width: "50px",
            render: (rowData) => renderIcon(rowData.type),
          },
          { title: "C/F/FF/FM", field: "name" },
        ]}
        parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
        options={{
          selection: showSelection,
          search: false,
          toolbar: false,
          pageSize: 10,
          showSelectAllCheckbox: showSelection,
        }}
        onSelectionChange={showSelection && props.handleSelectionChange ? props.handleSelectionChange : undefined}
      />
      {!showSelection && props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </DivWrapper>
  );
};

const renderIcon = (type: LinkModeTypeEnum) => {
  switch (type) {
    case LinkModeTypeEnum.Component:
      return (
        <div className="iconWrapper">
          <BuildIcon />
        </div>
      );
    case LinkModeTypeEnum.Function:
      return (
        <div className="funtionIcon">
          <FunctionIcon />
        </div>
      );
    case LinkModeTypeEnum.FunctionalFailure:
      return (
        <div className="funtionFailureIcon">
          <FunctionalFailureIcon />
        </div>
      );
    case LinkModeTypeEnum.FailureMode:
      return (
        <div className="faliureModeIcon">
          <FailureModeIcon />
        </div>
      );
    default:
      return null;
  }
};
