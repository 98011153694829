import React from "react";
import DialogContent from "@mui/material/DialogContent";
import SolverIcon from "@mui/icons-material/Functions";

import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { IModalAddUpdateSolverNodeProps } from "../types";
import FormAddUpdateSolver from "../forms/FormAddUpdateSolver";
import { useFetchSolverInputFieldsPageHook } from "../../solverInputField/Hooks";
import { useFetchSolverInputFieldListValuesPageHook } from "../../solverInputFieldListValue/Hooks";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { useFetchSolversPageHook } from "../../solver/Hooks";

const ModalAddUpdateSolverNode: React.FC<IModalAddUpdateSolverNodeProps> = ({
  solverDetails,
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  const { fetching: fetchingSolvers } = useFetchSolversPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 500,
  });
  const { fetching: fetchingSolverFields } = useFetchSolverInputFieldsPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 100,
  });
  const { fetching: fetchingSolverFieldListValues } = useFetchSolverInputFieldListValuesPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 200,
  });

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={solverDetails.open}
      title={solverDetails.isEditMode ? "Update solver" : "Add a new solver"}
      subtitle="Fill in the form to complete."
      headerIcon={<SolverIcon />}
      style={{ maxWidth: 600, minWidth: 550 }}
    >
      <DialogContent>
        <FormAddUpdateSolver
          onCancelCallback={onCancelCallback}
          solverDetails={solverDetails}
          onCompleteCallback={onCompleteCallback}
          onDelete={onDelete}
        />
        <LoaderAbsoluteCentred loading={fetchingSolvers || fetchingSolverFields || fetchingSolverFieldListValues} />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalAddUpdateSolverNode;
