import { FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import { ISolverInputField } from "../../utilities/types/SolverInputField";
import { TSolverInputFieldValue } from "../../utilities/types/Solver";

interface ISolverInputFieldDisplayStringProps {
  solverInputField: ISolverInputField;
  disabled?: boolean;
  onValueChangeCallback(fieldId: string, value: TSolverInputFieldValue): void;
  defaultValueOverride?: TSolverInputFieldValue;
}

export default function SolverInputFieldDisplayString({
  solverInputField,
  defaultValueOverride,
  onValueChangeCallback,
  disabled = false,
}: ISolverInputFieldDisplayStringProps) {
  const defaultValue = defaultValueOverride?.valueString || solverInputField.defaultString;

  const [error, setError] = useState("");
  const [value, setValue] = useState(defaultValue);

  function changeValue(e: any) {
    var valueString = e.target?.value;
    setValue(valueString); // Allow empty values

    if (valueString) {
      setError("");
      onValueChangeCallback(solverInputField.solverInputFieldId, { valueString });
    } else setError("A string is required.");
  }

  return (
    <FormControl fullWidth>
      <TextField
        label={solverInputField.label}
        type="text"
        value={disabled ? defaultValue : value}
        onChange={changeValue}
        error={!!error}
        helperText={error}
        fullWidth
        {...(disabled === true ? { disabled } : null)}
        variant="standard"
      />
    </FormControl>
  );
}
