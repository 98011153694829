import { RootState } from "..";
import { IOutputJobReport, IOutputJob } from "../../utilities/types/SingleAssetOutputJob";

export function selectorGetSingleAssetOutputJobByTaskId(state: RootState, solverJobTaskId: string): IOutputJob[] {
  if (!state.singleAssetOutputJob || !state.singleAssetOutputJob.byId) return [];

  return sortSingleAssetOutputJobByCreatedDescending(
    Object.values(state.singleAssetOutputJob.byId).filter((x) => x.solverJobTaskId === solverJobTaskId)
  );
}

export function selectorGetSingleAssetOutputJobById(
  state: RootState,
  singleAssetOutputJobId: string
): IOutputJob | undefined {
  if (!state.singleAssetOutputJob || !state.singleAssetOutputJob.byId) return undefined;

  return state.singleAssetOutputJob.byId[singleAssetOutputJobId];
}

export function sortSingleAssetOutputJobByCreatedDescending(singleAssetOutputJob: IOutputJob[]) {
  return singleAssetOutputJob.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}

export function sortSingleAssetOutputJobCombinedReportsByCreatedDescending(singleAssetOutputJob: IOutputJobReport[]) {
  return singleAssetOutputJob.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}
