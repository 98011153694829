import { ActionTypesAssetJob, AssetJobState, ACTIONS_ASSET_JOB } from "./types";

const initialState: AssetJobState = {
  byId: {},
};

export function assetJobsReducer(state = initialState, action: ActionTypesAssetJob): AssetJobState {
  switch (action.type) {
    case ACTIONS_ASSET_JOB.INVALIDATE: {
      return {
        ...initialState,
      };
    }
    case ACTIONS_ASSET_JOB.DELETE:
      let currentById = Object.assign({}, state.byId);
      delete currentById[Object.keys(action.byId)[0]];

      return {
        ...state,
        byId: currentById,
      };
    case ACTIONS_ASSET_JOB.RECEIVE: {
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
      };
    }
    default:
      return state;
  }
}
