import React from "react";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/lab/Skeleton";

export const AiSuggestedListSkeleton = () => {
  return (
    <>
      {[1, 2, 3, 4].map((item) => (
        <Typography
          component="div"
          key={item}
          style={{ borderRadius: "8px", height: 50, overflow: "hidden" }}
          variant="body1"
        >
          <Skeleton variant="rectangular" height={50} />
        </Typography>
      ))}
    </>
  );
};
