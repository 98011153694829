import { Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled } from "@mui/material";

import EditUserDetailIcon from "@mui/icons-material/EditOutlined";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ViewUserIcon from "@mui/icons-material/PersonOutlineTwoTone";
import React, { useState } from "react";
import { IUserRole } from "../../utilities/types/UserRole";
import ModalEntityPermissionsEntitySelect from "../entityPermission/modals/ModalEntityPermissionsEntitySelect";
import ModalUserRolePermissionsSelect from "./modals/ModalUserRolePermissionsSelect";
import ModalUserRoleUpdate from "./modals/ModalUserRoleUpdate";

const GridWrapper = styled(Grid)(({ theme }) => ({
  border: "1px solid #EEE",
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  "& .userRoleMainWrapper": {
    marginLeft: theme.spacing(1),
  },
}));

interface IUserRoleManagementContainerRowProps {
  userRole: IUserRole;
}

function UserRolesManagementContainerRow({ userRole }: IUserRoleManagementContainerRowProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showRolePermissionsModal, setShowRolePermissionsModal] = useState(false);
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
  const [showEntityPermissionsModal, setShowEntityPermissionsModal] = useState(false);

  const toggleRolePermissionsModal = () => {
    setShowRolePermissionsModal(!showRolePermissionsModal);
    setMenuAnchorEl(undefined);
  };

  const onCompleteCallback = () => {};

  const toggleUpdateRoleModal = () => {
    setShowUpdateRoleModal(!showUpdateRoleModal);
    setMenuAnchorEl(undefined);
  };

  const toggleEntityPermissionsModal = () => {
    setShowEntityPermissionsModal(!showEntityPermissionsModal);
    setMenuAnchorEl(undefined);
  };

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      <GridWrapper container>
        <Grid item xs={11} style={{ paddingLeft: 8 }}>
          <div className="userRoleMainWrapper">
            <div>
              <Typography variant="body1" noWrap={true}>
                {userRole.userRoleDescription}
              </Typography>

              <Typography style={{ opacity: 0.8, fontSize: "85%" }} noWrap={true}>
                Registered - {userRole.userRoleId}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={1} style={{ textAlign: "right" }}>
          <>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>

            <Menu
              style={{ padding: 0 }}
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              <MenuItem onClick={toggleRolePermissionsModal}>
                <ListItemIcon>
                  <ViewUserIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Role Permissions" />
              </MenuItem>
              <MenuItem onClick={toggleEntityPermissionsModal}>
                <ListItemIcon>
                  <EditUserDetailIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Entity Permissions" />
              </MenuItem>
              <MenuItem onClick={toggleUpdateRoleModal}>
                <ListItemIcon>
                  <EditUserDetailIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Update Role" />
              </MenuItem>
            </Menu>
          </>
        </Grid>
      </GridWrapper>
      {showRolePermissionsModal && (
        <ModalUserRolePermissionsSelect
          open={showRolePermissionsModal}
          onCancelCallback={toggleRolePermissionsModal}
          userRoleId={userRole.userRoleId}
        />
      )}

      {showEntityPermissionsModal && (
        <ModalEntityPermissionsEntitySelect
          open={showEntityPermissionsModal}
          onCancelCallback={toggleEntityPermissionsModal}
          userDetailId={userRole.userRoleId}
        />
      )}
      {showUpdateRoleModal && (
        <ModalUserRoleUpdate
          open={showUpdateRoleModal}
          onCancelCallback={toggleUpdateRoleModal}
          userRole={userRole}
          onCompleteCallback={onCompleteCallback}
        />
      )}
    </>
  );
}

export default UserRolesManagementContainerRow;
