import React, { useCallback, useEffect, useState } from "react";

import {
  Collapse,
  Card as MuiCard,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  styled,
  IconButton,
} from "@mui/material";
import { ImageWithFallback } from "../generic/ImageWithFallback";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetFileById } from "../../redux/file/selectors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { IModel } from "../../utilities/types/Model";
import { Configuration } from "../../utilities/Constants";
import { selectorGetModelHistoriesByModelId } from "../../redux/modelHistory/selectors";
import { GetSolverJobCreateLinkByModelId } from "../../routes/RouteLinkHelpers";
import { Link } from "react-router-dom";
import TimelineIcon from "@mui/icons-material/Timeline";
import EditIcon from "@mui/icons-material/Edit";
import ContributorsIcon from "@mui/icons-material/SupervisorAccount";
import { MenuDropdown } from "../generic/widgets/summaries/MenuDropdown";
import ModelUpdateModal from "./modals/ModalModelUpdate";
import DiscussionsIcon from "../discussion/DiscussionIcon";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import ContributorsModal from "../contributor/modals/ModalContributors";

type MessageType = {
  type: number;
  text: string;
};

interface AssetCollapseCardProps {
  model: IModel;
  showCollapse: boolean;
  setShowCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  canEdit: boolean;
}

const Card = styled(MuiCard)(({ theme }) => ({
  overflow: "unset",
  marginTop: `${theme.spacing(4)} !important`,
  ".inner-wrapper": {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: theme.spacing(2),
    flexWrap: "wrap",
    ".iconContainer": {
      position: "absolute",
      left: 0,
      top: "-28px",
      zIndex: 11,
      display: "grid",
      placeContent: "center",
      height: "75px",
      width: "75px",
      backgroundColor: "#37474f",
      borderRadius: "4px",
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      svg: {
        color: "#FFF",
        width: "1.3em",
        height: "1.3em",
      },
    },
    ".icon-btn": {
      backgroundColor: "#FFF",
      border: "1px solid #EEE",
    },
    ".content-wrapper": {
      cursor: "pointer",
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      gap: 16,
      flex: 1,
      flexShrink: 0,
      position: "relative",
      paddingLeft: theme.spacing(11),
    },
    ".right-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    ".validation-status": {
      display: "flex",
      alignItems: "center",
      gap: 2,
    },
    "& .circle": {
      borderRadius: "50%", // Make it a circle
      width: "28px", // Set the desired size
      height: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white", // Text color
      fontSize: "16px", // Text size
    },
    "& .errorMessageCircle": {
      backgroundColor: theme.palette.error.dark,
    },
    "& .warningMessageCircle": {
      backgroundColor: theme.palette.warning.main,
    },
    "& .infoMessageCircle": {
      backgroundColor: theme.palette.info.main,
    },
  },
  ".card-img": {
    width: 75,
    height: 75,
    borderRadius: 3,
    objectFit: "cover",
  },
  ".collapse-wrapper": {
    ".MuiPaper-elevation": {
      boxShadow: "none",
    },
  },
}));

const AssetCollapseCard: React.FC<AssetCollapseCardProps> = ({
  model,
  showCollapse,
  setShowCollapse,
  children,
  canEdit,
}) => {
  const [messages, setMessages] = useState([]);
  const state = useSelector((state: RootState) => state);
  const modelHistories = selectorGetModelHistoriesByModelId(state, model.modelId);
  const file = model?.mainImageId? selectorGetFileById(state, model.mainImageId) : "";
  const [modelUpdateModalOpen, setModelUpdateModalOpen] = useState(false);
  const [showContributorsModal, setShowContributorsModal] = useState(false);

  const latestModelHisotry = modelHistories.length > 0 ? modelHistories.at(0) : null;
  const errorCount = messages.filter((message: MessageType) => message.type === 0).length;
  const warningCount = messages.filter((message: MessageType) => message.type === 3).length;
  const infoCount = messages.filter((message: MessageType) => message.type === 2).length;

  const toggleShowContributorsModal = () => setShowContributorsModal((prev) => !prev);
  const toggleUpdateModal = () => setModelUpdateModalOpen((prev) => !prev);
  const toggleCollapse = () => setShowCollapse((prev) => !prev);

  const fetchMessages = useCallback(async () => {
    try {
      const response = await fetch(`${Configuration.BASE_API_URL}/models/validate?modelId=${model.modelId}`);
      if (response.ok) {
        const data = await response.json();
        // Sort the messages by severity (Error < Warning < Information)
        data.messages.sort((a: { type: number }, b: { type: number }) => b.type - a.type);
        setMessages(data.messages);
      } else {
        // Handle the error
      }
    } catch (error) {
      // Handle network error
    }
  }, [model.modelId]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const menuItems = [
    <MenuItem key="btn-analyse" component={Link} to={GetSolverJobCreateLinkByModelId(model?.modelId)}>
      <ListItemIcon>
        <TimelineIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Derive Specific Asset" />
    </MenuItem>,
  ];

  return (
    <Card>
      <div className="inner-wrapper">
        <div className="content-wrapper" onClick={toggleCollapse}>
          <div className="iconContainer">
            <ImageWithFallback className="card-img" src={file ? file?.preSignedUrl : ""} />
          </div>
          <div style={{ flex: 1 }}>
            <Typography fontWeight={600}>{model.title}</Typography>
            {latestModelHisotry && (
              <Typography component="span" variant="body2" className="inline" color="textPrimary">
                Version {latestModelHisotry.versionNumber}
              </Typography>
            )}
          </div>
        </div>

        <div className="right-wrapper">
          <div className="validation-status">
            <div className="errorMessageCircle circle">{errorCount}</div>
            <div className="warningMessageCircle circle">{warningCount}</div>
            <div className="infoMessageCircle circle">{infoCount}</div>
          </div>
          <DiscussionsIcon
            discussionCount={model.discussionCount}
            entityId={model.modelId}
            entityType={EntityTypeEnum.Model}
            canDiscuss={true}
            model={model}
          />
          <IconButton onClick={toggleShowContributorsModal}>
            <ContributorsIcon />
          </IconButton>
          {canEdit && (
            <IconButton aria-label="edit" aria-controls="edit-menu" aria-haspopup="true" onClick={toggleUpdateModal}>
              <EditIcon />
            </IconButton>
          )}
          <MenuDropdown menuItems={menuItems} />
          <IconButton aria-label="collapse" aria-controls="collapse-menu" aria-haspopup="true" onClick={toggleCollapse}>
            {showCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      <div>
        <Collapse className={showCollapse ? "collapse-wrapper" : ""} in={showCollapse}>
          {children}
        </Collapse>
      </div>

      <ModelUpdateModal
        open={modelUpdateModalOpen}
        model={model}
        onCancelCallback={toggleUpdateModal}
        onCompleteCallback={toggleUpdateModal}
      />
      <ContributorsModal
        modelId={model.modelId}
        canEdit={canEdit}
        open={showContributorsModal}
        onCancelCallback={toggleShowContributorsModal}
      />
    </Card>
  );
};

export default AssetCollapseCard;
