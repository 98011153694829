import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormSummaryOutputTypeInputFieldCreate from "../forms/FormSummaryOutputTypeInputFieldCreate";
import { ISummaryOutputTypeInputField } from "../../../utilities/types/SummaryOutputTypeInputField";
import HeaderIcon from "@mui/icons-material/InputTwoTone";

export interface IModalSummaryOutputTypeInputFieldCreateProps {
  open: boolean;
  onCancelCallback(): void;
  summaryOutputTypeId: string;
  onCompleteCallback(summaryOutputTypeInputField?: ISummaryOutputTypeInputField): void;
}

function ModalSummaryOutputTypeInputFieldCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  summaryOutputTypeId,
}: IModalSummaryOutputTypeInputFieldCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <FormSummaryOutputTypeInputFieldCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          summaryOutputTypeId={summaryOutputTypeId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSummaryOutputTypeInputFieldCreate;
