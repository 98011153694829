import React from "react";
import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { useFetchSingleAssetOutputPageHook } from "../Hooks";
import { useFetchSummaryOutputTypesPageHook } from "../../summaryOutputType/Hooks";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import AddNewSingleAssetOutputWidget from "../AddNewSingleAssetOutputWidget";
import {
  selectorGetSingleAssetOutputs,
  sortSingleAssetOutputsByNameAsc,
} from "../../../redux/singleAssetOutput/selectors";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import SummaryOutputTypeSearchResultWidget from "../../summaryOutputType/SummaryOutputTypeSearchResultWidget";
import SingleAssetOutputSearchResultWidget from "../SingleAssetOutputSearchResultWidget";
import SearchIcon from "@mui/icons-material/Search";
import {
  selectorGetSummaryOutputTypes,
  sortSummaryOutputTypesByNameAsc,
} from "../../../redux/summaryOutputType/selectors";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetUserPermissionOfType } from "../../../redux/userPermission/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { UserPermissionTypeEnum } from "../../../utilities/types/UserPermission";

const PageSingleAssetOutputSearch = (routeProps: RouteComponentProps<any>) => {
  // Fetch first page of solvers
  useFetchSingleAssetOutputPageHook({
    pageNumber: 1,
    pageSize: 50,
    minPageNumberToFetch: 1,
  });

  // Fetch first page of solvers
  useFetchSummaryOutputTypesPageHook({
    pageNumber: 1,
    pageSize: 50,
    minPageNumberToFetch: 1,
  });
  const canCreateOutputs = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesOutputTypeCreate)
  );

  const oTStateResults = useSelector((store: RootState) => selectorGetSingleAssetOutputs(store));
  const sOTStateResults = useSelector((store: RootState) => selectorGetSummaryOutputTypes(store));

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography variant="h5">Search Outputs</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} style={{ marginTop: 16 }} id="search-results-wrapper">
            {canCreateOutputs ? (
              <AnimationWrapper key={"Test"}>
                <Grid item xs={12} sm={6} md={4}>
                  <AddNewSingleAssetOutputWidget />
                </Grid>
              </AnimationWrapper>
            ) : null}
            {oTStateResults.length ? (
              sortSingleAssetOutputsByNameAsc(oTStateResults).map((oTStateResult, i) => {
                //if (i >= 9) return null;

                return (
                  <AnimationWrapper key={oTStateResult.outputTypeId}>
                    <Grid item xs={12} sm={6} md={4}>
                      <SingleAssetOutputSearchResultWidget singleAssetOutput={oTStateResult} loading={false} />
                    </Grid>
                  </AnimationWrapper>
                );
              })
            ) : (
              <Grid item xs={12}>
                <WidgetNoResultsPlaceholder text="No results" icon={SearchIcon} />
              </Grid>
            )}

            {sOTStateResults.length ? (
              sortSummaryOutputTypesByNameAsc(sOTStateResults).map((sOTStateResult, i) => {
                //if (i >= 9) return null;

                return (
                  <AnimationWrapper key={sOTStateResult.summaryOutputTypeId}>
                    <Grid item xs={12} sm={6} md={4}>
                      <SummaryOutputTypeSearchResultWidget summaryOutputType={sOTStateResult} loading={false} />
                    </Grid>
                  </AnimationWrapper>
                );
              })
            ) : (
              <Grid item xs={12}>
                <WidgetNoResultsPlaceholder text="No results" icon={SearchIcon} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageSingleAssetOutputSearch;
