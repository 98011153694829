import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useSelector } from "react-redux";
import { ISolver } from "../../../utilities/types/Solver";
import MapIcon from "@mui/icons-material/MapRounded";
import { selectorGetSolverInputFieldsBySolverId } from "../../../redux/solverInputField/selectors";
import { selectorGetSolverById } from "../../../redux/solver/selectors";
import { selectorGetSolverInputFieldListValuesBySolverId } from "../../../redux/solverInputFieldListValue/selectors";
import { RootState } from "../../../redux";
import { Typography, DialogActions, Button, styled } from "@mui/material";

import { ISolverInputField, SolverInputFieldTypeEnum } from "../../../utilities/types/SolverInputField";
import { ISolverInputFieldListValue } from "../../../utilities/types/SolverInputFieldListValue";
import { DeHumanizeString } from "../../../utilities/Helpers";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchSolverInputFieldListValuesPageHook } from "../../solverInputFieldListValue/Hooks";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

const PreCode = styled("pre")(({ theme }) => ({
  width: "100%",
  overflow: "scroll",
  border: "none",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(0,0,0,0.8)",
  color: "#FFF",
  fontFamily:
    "Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif",
  padding: theme.spacing(1),
  maxHeight: 150,
  marginTop: 0,
}));

export interface IModalSolverDefinitionsProps {
  open: boolean;
  onCancelCallback(): void;
  solverId: string;
}

function ModalSolverDefinitions({ onCancelCallback, open, solverId }: IModalSolverDefinitionsProps) {
  const solver = useSelector((store: RootState) => selectorGetSolverById(store, solverId));
  const solverFields = useSelector((store: RootState) => selectorGetSolverInputFieldsBySolverId(store, solverId));
  const solverFieldValues = useSelector((store: RootState) =>
    selectorGetSolverInputFieldListValuesBySolverId(store, solverId)
  );

  const { fetching: fetchingValues } = useFetchSolverInputFieldListValuesPageHook({
    minPageNumberToFetch: 0,
    pageNumber: 1,
    pageSize: 500,
    solverId: solverId,
  });

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Solver class definitions"}
      subtitle={"Add these definitions to a project."}
      headerIcon={<MapIcon />}
      style={{ maxWidth: "1200px" }}
    >
      <DialogContent>
        {!solver ? (
          <WidgetNoResultsPlaceholder text="No solver" />
        ) : (
          <>
            <Typography variant="overline">Solver Job Id</Typography>
            <PreCode>public const string Id = "{solver.solverId}";</PreCode>

            <Typography variant="overline">Field Definitions</Typography>
            <PreCode>{ClassFieldDefinitions({ solver, solverFields })}</PreCode>

            <Typography variant="overline">Field Value Definitions</Typography>
            <PreCode>{ClassFieldListValueDefinitions({ solver, solverFields, solverFieldValues })}</PreCode>
          </>
        )}
        <LoaderAbsoluteCentred loading={fetchingValues} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth={true} onClick={onCancelCallback}>
          Close
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

function ClassFieldListValueDefinitions({
  solver,
  solverFields,
  solverFieldValues,
}: {
  solver: ISolver;
  solverFields: ISolverInputField[];
  solverFieldValues: ISolverInputFieldListValue[];
}) {
  var fieldsDefinition = `public static class ${DeHumanizeString(solver.name)}FieldValues\r\n`;
  fieldsDefinition += `{\r\n`;

  // Add each field to the definition
  for (var solverField of solverFields) {
    // We only need this for enumerations
    if (solverField.type !== SolverInputFieldTypeEnum.CustomEnumeration) continue;

    fieldsDefinition += `\tpublic static class ${DeHumanizeString(solverField.label)}FieldValues\r\n`;
    fieldsDefinition += `\t{\r\n`;

    // Add each of the field value properties
    for (var fieldValue of solverFieldValues) {
      // Skip values that don't belong to this field
      if (fieldValue.solverInputFieldId !== solverField.solverInputFieldId) continue;

      fieldsDefinition += `\t\tpublic const string ${DeHumanizeString(fieldValue.displayText)} = "${
        fieldValue.solverInputFieldListValueId
      }";\r\n`;
    }

    fieldsDefinition += `\t}\r\n`;
    fieldsDefinition += `\r\n`;
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

function ClassFieldDefinitions({ solver, solverFields }: { solver: ISolver; solverFields: ISolverInputField[] }) {
  var fieldsDefinition = `public static class ${DeHumanizeString(solver.name)}Fields\r\n`;
  fieldsDefinition += `{\r\n`;

  // Add each field to the definition
  for (var solverField of solverFields) {
    fieldsDefinition += `\tpublic const string ${DeHumanizeString(solverField.label)} = "${
      solverField.solverInputFieldId
    }";\r\n`;
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

export default ModalSolverDefinitions;
