import React from "react";
import { Typography, AppBar, Tabs, Tab, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel } from "../../utilities/types/Model";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import {
  selectorGetUserPermissionIsAdmin,
  selectorGetUserPermissionOfType,
} from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { ScrollPretty } from "../../utilities/Styles";
import HeaderIcon from "@mui/icons-material/AccountTree";
import ComponentModelBuilderTab from "../component/ComponentModelBuilderTab";
import AssetInputFieldCategoryTabs from "../assetInputFieldCategory/AssetInputFieldCategoryTabs";
import IdentifierModelBuilderTab from "../identifier/IdentifierModelBuilderTab";
import PlanModelBuilderTab from "../plan/PlanModelBuilderTab";
import EventModelBuilderTab from "../event/EventModelBuilderTab";
import CostBundleModelBuilderTab from "../costBundle/CostBundleModelBuilderTab";
import TaskModelBuilderTab from "../task/TaskModelBuilderTab";
import DecisionModelBuilderTab from "../decision/DecisionModelBuilderTab";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchIdentifiersPageHook } from "../identifier/Hooks";
import { useFetchIdentifierMappingsPageHook } from "../identifierMapping/Hooks";
import { useFetchEventsPageHook } from "../event/Hooks";
import { useFetchCostBundlesPageHook } from "../costBundle/Hooks";
import { useFetchPlansPageHook } from "../plan/Hooks";
import { useFetchTasksPageHook } from "../task/Hooks";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { grey } from "@mui/material/colors";
import { useFetchAssetInputFieldCategoriesPageHook } from "../assetInputFieldCategory/Hooks";
import { useFetchAssetInputFieldsPageHook } from "../assetInputField/Hooks";
import { useFetchAssetInputFieldListValuesPageHook } from "../assetInputFieldListValue/Hooks";

const DivWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  ...ScrollPretty,
  "& .root": {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    minHeight: 400,
  },
  "& .appBar": {
    backgroundColor: `${theme.palette.background.paper} !important`,
    boxShadow: "none",
    border: "0",
    borderRadius: `0`,
    "& .MuiTabs-root": {
      position: "relative",
      backgroundColor: grey[100],
      borderRadius: theme.spacing(1.5),
      minHeight: "unset",
      padding: theme.spacing(0.5),
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.background.paper,
      boxShadow: "rgb(55 71 79 / 50%) 0rem 0.0625rem 0.3125rem 0.0625rem",
    },

    "& .MuiTabs-flexContainer": {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    "& .MuiTabs-fixed": {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    "& .MuiTabs-vertical": {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    "& .MuiTabs-indicator": {
      height: "100%",
      borderRadius: theme.spacing(1),
      backgroundColor: "#fff",
      transition: "all 500ms ease",
    },
    "& .MuiTabs-scrollButtons": {
      color: theme.palette.primary.main,
    },
    "& .MuiTab-root": {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flex: "1 1 auto",
      flexShrink: 0,
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: theme.spacing(2),
      fontWeight: 400,
      textTransform: "none",
      lineHeight: "inherit",
      padding: `${theme.spacing(0.6)} ${theme.spacing(1.2)}`,
      borderRadius: theme.spacing(1),
      //color: `${theme.palette.primary.main} !important`,
      opacity: "1 !important",

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: "4px",
      },

      "& svg": {
        marginBottom: "0 !important",
        //marginRight: theme.spacing(1.2),
      },
    },
  },
}));

type IModelBuilderMainTabsContainerProps = {
  modelId: string;
};

const ModelBuilderMainTabsContainer = ({ modelId }: IModelBuilderMainTabsContainerProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));

  const canEdit = isAdmin || GetUserId() === model?.createdBy;
  const canDiscuss = true;

  if (!model) {
    return <WidgetNoResultsPlaceholder text="Components not found" fade={true} icon={HeaderIcon} />;
  }

  return <ModelBuilderMainTabsContainerDisplay model={model} canEdit={canEdit} canDiscuss={canDiscuss} />;
};

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export function PreloadModelStructure({ modelId }: { modelId: string }) {
  const { fetching: fetchingIdentifiers } = useFetchIdentifiersPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingIdentifierMappings } = useFetchIdentifierMappingsPageHook({
    pageNumber: 1,
    pageSize: 5000,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingEvents } = useFetchEventsPageHook({
    pageNumber: 1,
    pageSize: 100,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingCostBundles } = useFetchCostBundlesPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingPlans } = useFetchPlansPageHook({
    pageNumber: 1,
    pageSize: 100,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingTasks } = useFetchTasksPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingAssetInputFieldCategories } = useFetchAssetInputFieldCategoriesPageHook({
    pageNumber: 1,
    pageSize: 20,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingAssetInputFields } =useFetchAssetInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingAssetInputFieldValues } =useFetchAssetInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  return {
    fetching:
      fetchingTasks ||
      fetchingPlans ||
      fetchingCostBundles ||
      fetchingEvents ||
      fetchingIdentifierMappings ||
      fetchingIdentifiers ||
      fetchingAssetInputFieldCategories ||
      fetchingAssetInputFieldValues ||
      fetchingAssetInputFields,
    fetchingTasks,
    fetchingPlans,
    fetchingCostBundles,
    fetchingEvents,
    fetchingIdentifierMappings,
    fetchingIdentifiers,
    fetchingAssetInputFieldCategories,
    fetchingAssetInputFieldValues,
    fetchingAssetInputFields,
  };
}

type IModelBuilderMainTabsContainerDisplayProps = {
  model: IModel;
  canEdit: boolean;
  canDiscuss?: boolean;
};

const ModelBuilderMainTabsContainerDisplay = ({
  model,
  canEdit,
  canDiscuss,
}: IModelBuilderMainTabsContainerDisplayProps) => {
  const [value, setValue] = React.useState(2);
  const isAdmin = useSelector((store: RootState) => selectorGetUserPermissionIsAdmin(store));
  const canViewModelCosts = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesCostBundleRead)
  );
  const canViewModelTasks = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesTaskRead)
  );
  const canEditModelCosts = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesCostBundleUpdate)
  );
  const canEditModelTasks = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesTaskUpdate)
  );
  const canEditModelEvents = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesEventUpdate)
  );
  const canViewModelEvents = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesEventRead)
  );
  const canViewModelPlans = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesPlanRead)
  );
  const canEditModelPlans = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesPlanUpdate)
  );
  const canEditModelDecisions = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesDecisionUpdate)
  );
  const canViewModelIdentifiers = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesIdentifierRead)
  );
  const canEditModelIdentifiers = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesIdentifierUpdate)
  );
  const canEditModelInputs = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesAssetInputFieldUpdate)
  );
  const canEditModelComponents = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesComponentUpdate)
  );

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  var { fetching } = PreloadModelStructure({ modelId: model.modelId });

  function applyDecisionsToModelBuilder() {
    console.log("Applied Decisions");
  }

  return (
    <DivWrapper>
      <WidgetSectionBase
        headerText="Asset"
        subheaderText="Define the structure of the asset as well as any sub-entities"
        headerIcon={<HeaderIcon />}
        fullWidthHeader={true}
      >
        <div className="root">
          <AppBar position="static" className="appBar">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Data Inputs" {...a11yProps(0)} />
              <Tab label="Causes" {...a11yProps(1)} />
              <Tab label="Components and Failure Modes" {...a11yProps(2)} />
              <Tab label="Identifiers" {...a11yProps(3)} />
              <Tab label="Costs" {...a11yProps(4)} />
              <Tab label="Tasks" {...a11yProps(5)} />
              <Tab label="Events" {...a11yProps(6)} />
              <Tab label="Plans" {...a11yProps(7)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <AssetInputFieldCategoryTabs
              modelId={model.modelId}
              canEdit={canEditModelInputs}
              enableInputs={false}
              onAssetInputFieldValueChangeCallback={applyDecisionsToModelBuilder}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DecisionModelBuilderTab modelId={model.modelId} canEdit={canEditModelDecisions} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ComponentModelBuilderTab
              modelId={model.modelId}
              canEdit={canEditModelComponents}
              canDiscuss={canDiscuss}
              loading={fetching}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            {isAdmin || canViewModelIdentifiers ? (
              <IdentifierModelBuilderTab modelId={model.modelId} canEdit={canEditModelIdentifiers} />
            ) : (
              <WidgetNoResultsPlaceholder
                text="Tab not found"
                description="No Permissions"
                fade={true}
                icon={HeaderIcon}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {isAdmin || canViewModelCosts ? (
              <CostBundleModelBuilderTab modelId={model.modelId} canEdit={canEditModelCosts} />
            ) : (
              <WidgetNoResultsPlaceholder
                text="Tab not found"
                description="No Permissions"
                fade={true}
                icon={HeaderIcon}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={5}>
            {isAdmin || canViewModelTasks ? (
              <TaskModelBuilderTab modelId={model.modelId} canEdit={canEditModelTasks} />
            ) : (
              <WidgetNoResultsPlaceholder
                text="Tab not found"
                description="No Permissions"
                fade={true}
                icon={HeaderIcon}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={6}>
            {isAdmin || canViewModelEvents ? (
              <EventModelBuilderTab modelId={model.modelId} canEdit={canEditModelEvents} />
            ) : (
              <WidgetNoResultsPlaceholder
                text="Tab not found"
                description="No Permissions"
                fade={true}
                icon={HeaderIcon}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={7}>
            {isAdmin || canViewModelPlans ? (
              <PlanModelBuilderTab modelId={model.modelId} canEdit={canEditModelPlans} />
            ) : (
              <WidgetNoResultsPlaceholder
                text="Tab not found"
                description="No Permissions"
                fade={true}
                icon={HeaderIcon}
              />
            )}
          </TabPanel>
        </div>
      </WidgetSectionBase>
    </DivWrapper>
  );
};

interface ITabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

function TabPanel({ children, value, index }: ITabPanelProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default ModelBuilderMainTabsContainer;
