import { RootState } from "..";
import { ISummaryOutputTypeInputFieldListValue } from "../../utilities/types/SummaryOutputTypeInputFieldListValue";


export function selectorGetSummaryOutputTypeInputFieldListValuesBySummaryOutputTypeId(state: RootState, summaryOutputTypeId: string): ISummaryOutputTypeInputFieldListValue[] {

    if (!state.summaryOutputTypeInputFieldListValues || !state.summaryOutputTypeInputFieldListValues.byId) return [];

    return sortSummaryOutputTypeInputFieldListValuesByOrderNumberAscending(Object.values(state.summaryOutputTypeInputFieldListValues.byId).filter(x => x.summaryOutputTypeId === summaryOutputTypeId));
}


export function selectorGetSummaryOutputTypeInputFieldListValuesBySummaryOutputTypeInputFieldId(state: RootState, summaryOutputTypeInputFieldId: string): ISummaryOutputTypeInputFieldListValue[] {

    if (!state.summaryOutputTypeInputFieldListValues || !state.summaryOutputTypeInputFieldListValues.byId) return [];

    return sortSummaryOutputTypeInputFieldListValuesByOrderNumberAscending(Object.values(state.summaryOutputTypeInputFieldListValues.byId).filter(x => x.summaryOutputTypeInputFieldId === summaryOutputTypeInputFieldId));
}


export function selectorGetSummaryOutputTypeInputFieldListValueById(state: RootState, summaryOutputTypeInputFieldListValueId: string): ISummaryOutputTypeInputFieldListValue | undefined {

    if (!state.summaryOutputTypeInputFieldListValues || !state.summaryOutputTypeInputFieldListValues.byId) return undefined;

    return state.summaryOutputTypeInputFieldListValues.byId[summaryOutputTypeInputFieldListValueId];
}


// function sortSummaryOutputTypeInputFieldListValuesByOrderNumberDescending(summaryOutputTypeInputFieldListValues: ISummaryOutputTypeInputFieldListValue[]) {
//     return summaryOutputTypeInputFieldListValues.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortSummaryOutputTypeInputFieldListValuesByOrderNumberAscending(summaryOutputTypeInputFieldListValues: ISummaryOutputTypeInputFieldListValue[]) {
    return summaryOutputTypeInputFieldListValues.sort((a, b) => a.orderNumber - b.orderNumber);
}