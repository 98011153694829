import { ACTIONS_TASK_APPLICABILITY, TaskApplicabilitySearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ITaskApplicability } from '../../utilities/types/TaskApplicability';


export const receiveTaskApplicabilities = (taskApplicabilities: ITaskApplicability[]) => {

    var byIdObjectToDispatch: { [key: string]: ITaskApplicability } = {};

    for (var i = 0; i < taskApplicabilities.length; i++) {
        byIdObjectToDispatch[taskApplicabilities[i].taskApplicabilityId] = taskApplicabilities[i];
    }

    return {
        type: ACTIONS_TASK_APPLICABILITY.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteTaskApplicability = (taskApplicability: ITaskApplicability) => ({
    type: ACTIONS_TASK_APPLICABILITY.DELETE,
    byId: { [taskApplicability.taskApplicabilityId]: taskApplicability }
});


export interface IFetchCreateTaskApplicabilityProps {
    taskId: string,
    failureModeIds: string[],
    detectionProbability: number,
    pfInterval: number,
    orderNumber: number,
    stepDescription: string,
}


export const fetchCreateTaskApplicability = (taskApplicabilityToCreate: IFetchCreateTaskApplicabilityProps): AppThunk<Promise<ITaskApplicability[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/taskApplicabilities`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(taskApplicabilityToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskApplicabilities) {
            dispatch(receiveTaskApplicabilities(parsedResp.data.taskApplicabilities));
            return parsedResp.data.taskApplicabilities;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating taskApplicability.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating taskApplicability.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateTaskApplicabilityProps {
    taskApplicabilityId: string,
    failureModeId: string,
    detectionProbability: number,
    pfInterval: number,
    orderNumber: number,
    stepDescription: string,
}


export const fetchUpdateTaskApplicability = (props: IFetchUpdateTaskApplicabilityProps): AppThunk<Promise<ITaskApplicability>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/taskApplicabilities`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskApplicabilities) {
            dispatch(receiveTaskApplicabilities(parsedResp.data.taskApplicabilities));
            return parsedResp.data.taskApplicabilities;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating taskApplicability.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating taskApplicability.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchTaskApplicabilitiesProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    taskId: string,
    taskApplicabilityId?: string,
    text?: string,
    createdBy?: string,
    orderType?: TaskApplicabilitySearchOrderTypeEnum,
}


export const fetchSearchTaskApplicabilities = (searchParams: IFetchSearchTaskApplicabilitiesProps): AppThunk<Promise<ITaskApplicability[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/taskApplicabilities`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskApplicabilities) {
            dispatch(receiveTaskApplicabilities(parsedResp.data.taskApplicabilities));
            return parsedResp.data.taskApplicabilities;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching taskApplicability.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching taskApplicability.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteTaskApplicabilityProps {
    taskApplicabilityId: string,
}


export const fetchDeleteTaskApplicability = (props: IFetchDeleteTaskApplicabilityProps): AppThunk<Promise<ITaskApplicability>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/taskApplicabilities`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskApplicabilities && parsedResp.data.taskApplicabilities.length) {
            dispatch(requestDeleteTaskApplicability(parsedResp.data.taskApplicabilities[0]));
            return parsedResp.data.taskApplicabilities[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting taskApplicability.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting taskApplicability.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}