import { Card as MuiCard, CardActions, CardContent, CardHeader, Typography, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const IMAGE_HEIGHT = 200;

const Card = styled(MuiCard)(({ theme }) => ({
  "& .header": {
    height: 75,
  },
  "& .HeaderTitle": {
    fontWeight: "bold !important",
  },
  "& .plusImage": {
    alignItems: "center",
    height: IMAGE_HEIGHT,
    left: "50%",
    borderRadius: "10px",
  },
  "& .updatedModelImageWrapper": {
    overflow: "hidden",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .actionContainer": {
    paddingRight: theme.spacing(2),
  },
}));

interface IGenericLinkWidgetProps {
  title: string;
  subheader: string;
  description: string;
  image: string;
  linkPath: string;
}

function GenericLinkWidget({ title, subheader, image, linkPath, description }: IGenericLinkWidgetProps) {
  return (
    <Card>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        title={title}
        subheader={subheader}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
        classes={{ title: "HeaderTitle" }}
      />
      <Link to={linkPath}>
        <div className="updatedModelImageWrapper">
          <img src={image} alt="ModuleImage" className="plusImage" />
        </div>
      </Link>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className="actionContainer">
        <div style={{ flex: 1 }} />
      </CardActions>
    </Card>
  );
}

export default GenericLinkWidget;
