import {
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
  styled,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import ModelIcon from "@mui/icons-material/Category";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import { Alert } from "@mui/lab";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GetSolverURLBySolverId, GetSolverLinkBySolverId } from "../../routes/RouteLinkHelpers";
import { useIsLoggedIn } from "../../utilities/ApiUtils";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import { ISolver } from "../../utilities/types/Solver";
import FavouriteButton from "../favourite/FavouriteButton";
import FileRendererSingle from "../file/renderers/FileRendererSingle";

const IMAGE_HEIGHT = 200;

const Card = styled(MuiCard)(({ theme }) => ({
  "& .updatedModelImageWrapper": {
    height: IMAGE_HEIGHT,
    overflow: "hidden",
    cursor: "pointer",
  },
  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .avatar": {
    backgroundColor: blueGrey[800],
    textSizeAdjust: "small",
  },
  "& .header": {
    height: 75,
  },
  "& .actionContainer": {
    paddingRight: theme.spacing(2),
  },
}));

function SolverSearchResultWidget({ solver }: { solver: ISolver | undefined; loading: boolean }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(GetSolverURLBySolverId(solver?.solverId ?? ""));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeMenu = () => setMenuAnchorEl(undefined);
  const isLoggedIn = useIsLoggedIn();

  return !solver ? null : (
    <Card>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        action={
          <IconButton aria-label="settings" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={<Typography>{solver?.name}</Typography>}
        subheader={`Last Updated ${moment.utc(solver?.updated).fromNow()}`}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
      />

      <Menu
        style={{ padding: 0 }}
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        disableScrollLock={true}
        onClose={closeMenu}
      >
        <MenuItem key="btn-view-model" component={Link} to={GetSolverLinkBySolverId(solver?.solverId)}>
          <ListItemIcon>
            <ModelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Solver" />
        </MenuItem>
      </Menu>

      <div className="updatedModelImageWrapper">
        <Link to={GetSolverLinkBySolverId(solver?.solverId)}>
          <FileRendererSingle
            fileId={solver?.mainImageId || ""}
            canUpload={false}
            fileType={FileTypeEnum.AssetImage}
            entityId={String(solver?.solverId)}
            entityType={EntityTypeEnum.Solver}
            height={IMAGE_HEIGHT}
          />
        </Link>
      </div>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {solver.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className="actionContainer">
        {isLoggedIn && <FavouriteButton entityType={EntityTypeEnum.Solver} entityId={solver?.solverId ?? ""} />}
        <IconButton aria-label="Share" title="Share" onClick={handleClick}>
          <ShareIcon />
        </IconButton>
        <div style={{ flex: 1 }} />
      </CardActions>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default SolverSearchResultWidget;
