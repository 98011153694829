import { ACTIONS_DECISION_ASSET_INPUT_FIELD_MAPPING, DecisionAssetInputFieldMappingSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IDecisionAssetInputFieldMapping } from '../../utilities/types/DecisionAssetInputFieldMapping';
import { requestDeleteManyIdentifierMapping } from '../identifierMapping/actions';
import { requestDeleteIdentifierMapping } from '../identifierMapping/actions';



export const receiveDecisionAssetInputFieldMappings = (decisionAssetInputFieldMappings: IDecisionAssetInputFieldMapping[]) => {

    var byIdObjectToDispatch: { [key: string]: IDecisionAssetInputFieldMapping } = {};

    for (var i = 0; i < decisionAssetInputFieldMappings.length; i++) {
        byIdObjectToDispatch[decisionAssetInputFieldMappings[i].decisionAssetInputFieldMappingId] = decisionAssetInputFieldMappings[i];
    }

    return {
        type: ACTIONS_DECISION_ASSET_INPUT_FIELD_MAPPING.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteDecisionAssetInputFieldMapping = (decisionAssetInputFieldMapping: IDecisionAssetInputFieldMapping) => ({
    type: ACTIONS_DECISION_ASSET_INPUT_FIELD_MAPPING.DELETE,
    byId: { [decisionAssetInputFieldMapping.decisionAssetInputFieldMappingId]: decisionAssetInputFieldMapping }
});


export interface IFetchCreateDecisionAssetInputFieldMappingProps {
    modelId: string,
    notes: string,
    decisionId: string,
    assetInputFieldId: string,
    orderNumber: number,
}


export const fetchCreateDecisionAssetInputFieldMapping = (decisionAssetInputFieldMappingToCreate: IFetchCreateDecisionAssetInputFieldMappingProps): AppThunk<Promise<IDecisionAssetInputFieldMapping>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/decisionAssetInputFieldMappings`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(decisionAssetInputFieldMappingToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisionAssetInputFieldMappings) {
            dispatch(receiveDecisionAssetInputFieldMappings(parsedResp.data.decisionAssetInputFieldMappings));
            return parsedResp.data.decisionAssetInputFieldMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating decision field mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateDecisionAssetInputFieldMappingProps {
    decisionAssetInputFieldMappingId: string,
    notes: string,
    orderNumber: number,
}


export const fetchUpdateDecisionAssetInputFieldMapping = (props: IFetchUpdateDecisionAssetInputFieldMappingProps): AppThunk<Promise<IDecisionAssetInputFieldMapping>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/decisionAssetInputFieldMappings`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisionAssetInputFieldMappings) {
            dispatch(receiveDecisionAssetInputFieldMappings(parsedResp.data.decisionAssetInputFieldMappings));
            return parsedResp.data.decisionAssetInputFieldMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating decision field mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchDecisionAssetInputFieldMappingsProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    decisionAssetInputFieldMappingId?: string,
    text?: string,
    createdBy?: string,
    orderType?: DecisionAssetInputFieldMappingSearchOrderTypeEnum,
}


export const fetchSearchDecisionAssetInputFieldMappings = (searchParams: IFetchSearchDecisionAssetInputFieldMappingsProps): AppThunk<Promise<IDecisionAssetInputFieldMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/decisionAssetInputFieldMappings`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisionAssetInputFieldMappings) {
            dispatch(receiveDecisionAssetInputFieldMappings(parsedResp.data.decisionAssetInputFieldMappings));
            return parsedResp.data.decisionAssetInputFieldMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching decision field mapping.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteDecisionAssetInputFieldMappingProps {
    decisionAssetInputFieldMappingId: string,
}


export const fetchDeleteDecisionAssetInputFieldMapping = (props: IFetchDeleteDecisionAssetInputFieldMappingProps): AppThunk<Promise<IDecisionAssetInputFieldMapping>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/decisionAssetInputFieldMappings`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifierMappings && parsedResp.data.identifierMappings.length) {
            dispatch(requestDeleteManyIdentifierMapping(parsedResp.data.identifierMappings));
        }
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisionAssetInputFieldMappings && parsedResp.data.decisionAssetInputFieldMappings.length) {
            dispatch(requestDeleteDecisionAssetInputFieldMapping(parsedResp.data.decisionAssetInputFieldMappings[0]));
            dispatch(requestDeleteIdentifierMapping(parsedResp.data.identifierMappings));
            return parsedResp.data.decisionAssetInputFieldMappings[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting decision field mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}