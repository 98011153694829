import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { IModel } from "../../../utilities/types/Model";
import FileRendererSingle from "../../file/renderers/FileRendererSingle";
import { FileTypeEnum } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { Typography, DialogActions, Button, Grid } from "@mui/material";
import HeaderIcon from "@mui/icons-material/Category";
import moment from "moment";

export interface IModalModelSearchResultDisplayProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(file?: IModel): void;
  model: IModel;
}

function ModalModelSearchResultDisplay({ onCancelCallback, open, model }: IModalModelSearchResultDisplayProps) {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={model.title}
      subtitle={"Viewing model details."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent>
        <Grid container style={{ marginTop: -16 }}>
          <Grid item xs={4}>
            <Typography variant="caption">
              <span style={{ opacity: 0.8 }}>Version</span>
              <br />
              {model.versionNumber}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">
              <span style={{ opacity: 0.8 }}>Created</span>
              <br />
              {moment.utc(model.created).fromNow()}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">
              <span style={{ opacity: 0.8 }}>Updated</span>
              <br />
              {moment.utc(model.updated).fromNow()}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <FileRendererSingle
        fileId={model.mainImageId || ""}
        canUpload={false}
        fileType={FileTypeEnum.Image}
        entityId={String(model.modelId)}
        entityType={EntityTypeEnum.Model}
        height={150}
      />
      <DialogContent>
        <Typography variant="body1">{model.title}</Typography>
        <Typography variant="body1" color="textSecondary">
          {model.description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined"  fullWidth={true} onClick={onCancelCallback}>
          Close
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

export default ModalModelSearchResultDisplay;
