import React, { useState } from "react";
import { Avatar, Chip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { FileTypeEnum } from "../../utilities/types/File";
import { useFileSourceSingleHook } from "../file/Hooks";
import { IIdentifierMapping } from "../../utilities/types/IdentifierMapping";
import { selectorGetIdentifierById } from "../../redux/identifier/selectors";
import { IIdentifier } from "../../utilities/types/Identifier";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import { ShowMessage, UpdateMessage, extractFirstTwoCharacters } from "../../utilities/Helpers";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { fetchDeleteIdentifierMapping } from "../../redux/identifierMapping/actions";
import { blueGrey } from "@mui/material/colors";
import ModalIdentifierMappingUpdate from "./modals/ModalIdentifierMappingUpdate";

type IIdentifierMappingRendererProps = {
  canEdit: boolean;
  identifierMapping: IIdentifierMapping;
  showValue: boolean;
};

const IdentifierMappingRenderer = (props: IIdentifierMappingRendererProps) => {
  const { identifierMapping } = props;
  const identifier = useSelector((store: RootState) =>
    selectorGetIdentifierById(store, identifierMapping.identifierId)
  );
  const imageUrl = useFileSourceSingleHook({
    fileId: (identifier && identifier.mainImageId) || "",
    fileType: FileTypeEnum.Image,
  });

  if (!identifier) {
    console.error(`Identifier not found: ${identifierMapping.identifierId}`);
    return null;
  }

  return (
    <>
      <IdentifierMappingRendererDisplay
        identifier={identifier}
        imageUrl={imageUrl}
        identifierMappingValue={identifierMapping.identifierMappingValue}
        {...props}
      />
    </>
  );
};

interface IIdentifierMappingRendererDisplayProps extends IIdentifierMappingRendererProps {
  identifier: IIdentifier;
  imageUrl: string;
  identifierMappingValue?: number;
}

const IdentifierMappingRendererDisplay = (props: IIdentifierMappingRendererDisplayProps) => {
  const { identifier, identifierMapping, canEdit, imageUrl, identifierMappingValue, showValue } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteModal(!showDeleteModal);
  const dispatch = useDispatch();
  const [, setDeleting] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false); // Add this state variable

  async function deleteHandler() {
    // Inform user
    setDeleting(true);
    var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
    setShowDeleteModal(false);

    // Perform delete
    var resp = await dispatch(
      fetchDeleteIdentifierMapping({ identifierMappingId: identifierMapping.identifierMappingId })
    );

    // Cleanup
    setDeleting(false);
    if (resp != null) {
      UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
      toggleDeleteConfirmation();
    }
  }

  const handleChipDoubleClick = () => {
    // Show the update modal when double-clicked
    setShowUpdateModal(true);
  };

  const backgroundColor = identifier.color || blueGrey[50];

  const IdentifierAvatar = identifier.mainImageId ? (
    <Avatar alt={identifier.code} src={imageUrl} />
  ) : (
    <Avatar>{extractFirstTwoCharacters(identifier.code || identifier.name)}</Avatar>
  );

  return (
    <div>
      {canEdit ? (
        <Chip
          avatar={IdentifierAvatar}
          label={showValue ? identifierMappingValue + " - " + identifier.name : identifier.name}
          onDelete={() => toggleDeleteConfirmation()}
          variant="outlined"
          size="small"
          style={{ backgroundColor }}
          onDoubleClick={handleChipDoubleClick}
        />
      ) : (
        <Chip
          avatar={IdentifierAvatar}
          label={showValue ? identifierMappingValue + " - " + identifier.name : identifier.name}
          size="small"
          variant="outlined"
          style={{ backgroundColor }}
        />
      )}
      <ModalIdentifierMappingUpdate
        open={showUpdateModal}
        onCancelCallback={() => setShowUpdateModal(false)} // Close the modal
        identifierMapping={identifierMapping}
        onCompleteCallback={(updatedIdentifierMapping) => {
          // Handle the completion of the update, if needed
          // You can pass the updated identifier mapping data here
          setShowUpdateModal(false); // Close the modal after completion
        }}
      />
      <WidgetModalConfirmationDialog
        open={showDeleteModal}
        title="Delete confirmation"
        subtitle="Confirm identifier delete"
        description="Are you sure that you'd like to remove this identifier?"
        onCancelCallback={toggleDeleteConfirmation}
        onConfirmCallback={deleteHandler}
        confirmButtonText="Delete"
      />
    </div>
  );
};

export default IdentifierMappingRenderer;
