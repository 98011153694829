import { ACTIONS_MODEL_HISTORY, SolverHistorySearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ISolverHistory } from '../../utilities/types/SolverHistory';


export const receiveSolverHistories = (solverHistories: ISolverHistory[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverHistory } = {};

    for (var i = 0; i < solverHistories.length; i++) {
        byIdObjectToDispatch[solverHistories[i].solverHistoryId] = solverHistories[i];
    }

    return {
        type: ACTIONS_MODEL_HISTORY.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverHistory = (solverHistory: ISolverHistory) => ({
    type: ACTIONS_MODEL_HISTORY.DELETE,
    byId: { [solverHistory.solverHistoryId]: solverHistory }
});


export interface IFetchCreateSolverHistoryProps {
    title: string,
    description?: string,
    versionNumber: number,
    solverId: string,
}


export const fetchCreateSolverHistory = (solverHistoryToCreate: IFetchCreateSolverHistoryProps): AppThunk<Promise<ISolverHistory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverHistories`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(solverHistoryToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverHistory) {
            dispatch(receiveSolverHistories([parsedResp.data.solverHistory]));
            return parsedResp.data.solverHistory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while creating history.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSolverHistoryProps {
    title: string,
    description?: string,
    versionNumber: number,
    solverHistoryId: string,
}


export const fetchUpdateSolverHistory = (props: IFetchUpdateSolverHistoryProps): AppThunk<Promise<ISolverHistory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverHistories`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverHistory) {
            dispatch(receiveSolverHistories([parsedResp.data.solverHistory]));
            return parsedResp.data.solverHistory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating history.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchSolverHistoriesProps {
    pageNumber: number,
    pageSize: number,
    solverId?: string,
    text?: string,
    minimumVersionNumber?: number,
    createdBy?: string,
    orderType?: SolverHistorySearchOrderTypeEnum,
}


export const fetchSearchSolverHistories = (searchParams: IFetchSearchSolverHistoriesProps): AppThunk<Promise<ISolverHistory[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverHistories`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverHistories) {
            dispatch(receiveSolverHistories(parsedResp.data.solverHistories));
            return parsedResp.data.solverHistories;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching history.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSolverHistoryProps {
    solverHistoryId: string,
}


export const fetchDeleteSolverHistory = (props: IFetchDeleteSolverHistoryProps): AppThunk<Promise<ISolverHistory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverHistories`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverHistory) {
            dispatch(requestDeleteSolverHistory(parsedResp.data.solverHistory));
            return parsedResp.data.solverHistory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting history.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}