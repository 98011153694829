import React, { useState } from "react";
import { selectorGetSolverHistoriesBySolverId } from "../../redux/solverHistory/selectors";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { List, ListItem, ListItemText, Typography, Divider, styled } from "@mui/material";

import SolverHistoryUpdateModal from "./modals/ModalSolverHistoryUpdate";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { ScrollPretty } from "../../utilities/Styles";
import { ISolverHistory } from "../../utilities/types/SolverHistory";

const DivWrapper = styled("div")(() => ({
  "& .inline": {
    display: "inline",
  },
  "& .listItem": {
    transition: "background-color 0.3s ease-out",
    backgroundColor: "rgba(0,0,0,0.001)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.03)",
    },
    "&:active": {
      cursor: "pointer",
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  "& .list": {
    height: 100,
    overflowY: "scroll",
    textTransform: "capitalize",
    opacity: 0.8,
    border: "1px solid #EEE",
    position: "relative",
  },
  "& .truncateText": {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .opacityWrapper": {
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 0,
    height: 30,
    zIndex: 2,
    background: "linear-gradient(179deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9))",
  },
  ...ScrollPretty,
}));

type SolverHistoryListProps = {
  solverId: string;
  canEdit: boolean;
};

const SolverHistoryList = ({ solverId, canEdit }: SolverHistoryListProps) => {
  const solverHistories = useSelector((state: RootState) => selectorGetSolverHistoriesBySolverId(state, solverId));
  const [solverHistoryToUpdate, setSolverHistoryToUpdate] = useState<ISolverHistory>();

  return (
    <DivWrapper>
      <List className="list">
        {solverHistories.length === 0 && (
          <Typography variant="caption" component="p" style={{ textAlign: "center" }}>
            No history
          </Typography>
        )}
        {solverHistories.map((solverHistory) => {
          return (
            <AnimationWrapper key={solverHistory.solverId}>
              <div>
                <ListItem onClick={() => setSolverHistoryToUpdate(solverHistory)} className="listItem">
                  <ListItemText
                    className="truncateText"
                    primary={solverHistory.title}
                    secondary={
                      <div className="truncateText">
                        <Typography component="span" variant="body2" className="inline" color="textPrimary">
                          Version {solverHistory.versionNumber}
                        </Typography>
                        {solverHistory.description ? (
                          <Typography variant="body2" component="span" color="textSecondary">
                            {" "}
                            — {solverHistory.description}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                  />
                </ListItem>
                <Divider light={true} variant="middle" />
              </div>
            </AnimationWrapper>
          );
        })}
      </List>
      <div className="opacityWrapper">&nbsp;</div>
      {solverHistoryToUpdate ? (
        <SolverHistoryUpdateModal
          open={solverHistoryToUpdate !== undefined}
          onCancelCallback={() => setSolverHistoryToUpdate(undefined)}
          onCompleteCallback={() => setSolverHistoryToUpdate(undefined)}
          solverHistory={solverHistoryToUpdate}
          canEdit={canEdit}
        />
      ) : null}
    </DivWrapper>
  );
};

export default SolverHistoryList;
