import { ITaskImpact } from "../../utilities/types/TaskImpact";


export const ACTIONS_TASK_IMPACT = {
    INVALIDATE: 'INVALIDATE_TASK_IMPACTS',
    RECEIVE: 'RECEIVE_TASK_IMPACTS',
    DELETE: 'DELETE_TASK_IMPACT',
    UPDATE: 'UPDATE_TASK_IMPACTS',
}

interface ActionTaskImpactsInvalidate {
    type: typeof ACTIONS_TASK_IMPACT.INVALIDATE,
    byId: { [key: string]: ITaskImpact },
}

interface ActionTaskImpactsReceive {
    type: typeof ACTIONS_TASK_IMPACT.RECEIVE
    byId: { [key: string]: ITaskImpact },
}

export interface TaskImpactState {
    byId: { [key: string]: ITaskImpact },
}

export enum TaskImpactSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesTaskImpact = ActionTaskImpactsReceive | ActionTaskImpactsInvalidate;