import { RootState } from "..";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { GetUserId } from "../../utilities/ApiUtils";
import { IUserRolePermission } from "../../utilities/types/UserRolePermission";


export function selectorGetUserRolePermissionsByRoleId(state: RootState, userRoleId: string) : IUserRolePermission[] {

    if (!state.userRolePermissions || !state.userRolePermissions.byId) return [];

    return sortUserPermissionsByType(
        Object.values(state.userRolePermissions.byId).filter(x => x.userRoleId === userRoleId)
    );
}

export function selectorGetUserRolePermissionOfType(state: RootState, userId: string = GetUserId(), userPermissionType: UserPermissionTypeEnum) : boolean {

    if(!state.userPermissions || !state.userPermissions.byId) return false;

    return Object.values(state.userPermissions.byId).some(x => x.userDetailId === userId && x.type === userPermissionType);
}

function sortUserPermissionsByType(userRolePermissions: IUserRolePermission[]) {
    return userRolePermissions.sort((a, b) => a.permission - b.permission);
}