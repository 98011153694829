import { Typography, IconButton, InputLabel, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import React, { useState } from "react";
import { ISummaryOutputTypeInputFieldListValue } from "../../utilities/types/SummaryOutputTypeInputFieldListValue";
import { selectorGetSummaryOutputTypeInputFieldListValuesBySummaryOutputTypeInputFieldId } from "../../redux/summaryOutputTypeInputFieldListValue/selectors";
import AddIcon from "@mui/icons-material/AddOutlined";
import ModalSummaryOutputTypeInputFieldListValueCreate from "./modals/ModalSummaryOutputTypeInputFieldListValueCreate";
import ModalSummaryOutputTypeInputFieldListValueUpdate from "./modals/ModalSummaryOutputTypeInputFieldListValueUpdate";

const DivWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& .fieldValueWrapper": {
    border: "1px solid #EEE",
    padding: "3px 5px",
    borderRadius: "4px",
    margin: "5px 0px",
    transition: "background 0.25s ease-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
}));

interface ISummaryOutputTypeInputFieldListValueMaintenanceSectionProps {
  canEdit: boolean;
  summaryOutputTypeInputFieldId: string;
}

function SummaryOutputTypeInputFieldListValueMaintenanceSection({
  summaryOutputTypeInputFieldId,
  canEdit,
}: ISummaryOutputTypeInputFieldListValueMaintenanceSectionProps) {
  const summaryOutputTypeInputFieldListValues = useSelector((store: RootState) =>
    selectorGetSummaryOutputTypeInputFieldListValuesBySummaryOutputTypeInputFieldId(
      store,
      summaryOutputTypeInputFieldId
    )
  );

  return (
    <SummaryOutputTypeInputFieldListValueMaintenanceSectionDisplay
      canEdit={canEdit}
      summaryOutputTypeInputFieldId={summaryOutputTypeInputFieldId}
      summaryOutputTypeInputFieldListValues={summaryOutputTypeInputFieldListValues}
    />
  );
}

interface ISummaryOutputTypeInputFieldListValueMaintenanceSectionDisplayProps {
  canEdit: boolean;
  summaryOutputTypeInputFieldId: string;
  summaryOutputTypeInputFieldListValues: ISummaryOutputTypeInputFieldListValue[];
}

function SummaryOutputTypeInputFieldListValueMaintenanceSectionDisplay({
  summaryOutputTypeInputFieldListValues,
  summaryOutputTypeInputFieldId,
}: ISummaryOutputTypeInputFieldListValueMaintenanceSectionDisplayProps) {
  const [showModalSummaryOutputTypeInputFormListValueCreate, setShowModalSummaryOutputTypeInputFormListValueCreate] =
    useState(false);
  const [summaryOutputTypeInputFieldListValueToEdit, setSummaryOutputTypeInputFieldListValueToEdit] =
    useState<ISummaryOutputTypeInputFieldListValue>();

  const toggleShowModalSummaryOutputTypeInputFormListValueCreate = () =>
    setShowModalSummaryOutputTypeInputFormListValueCreate(!showModalSummaryOutputTypeInputFormListValueCreate);

  return (
    <DivWrapper>
      <InputLabel shrink={true}>Values</InputLabel>
      <div>
        {summaryOutputTypeInputFieldListValues.map((summaryOutputTypeInputFieldListValue) => {
          return (
            <div
              className="fieldValueWrapper"
              onClick={() => setSummaryOutputTypeInputFieldListValueToEdit(summaryOutputTypeInputFieldListValue)}
            >
              <Typography component="p">{summaryOutputTypeInputFieldListValue.displayText}</Typography>
              <Typography component="span" variant="caption" style={{ opacity: 0.75 }}>
                Value is {summaryOutputTypeInputFieldListValue.value}
                {summaryOutputTypeInputFieldListValue.isDefault ? " (default)" : ""}{" "}
              </Typography>
            </div>
          );
        })}
      </div>

      <div>
        <IconButton onClick={toggleShowModalSummaryOutputTypeInputFormListValueCreate}>
          <AddIcon />
        </IconButton>
      </div>
      <ModalSummaryOutputTypeInputFieldListValueCreate
        open={showModalSummaryOutputTypeInputFormListValueCreate}
        summaryOutputTypeInputFieldId={summaryOutputTypeInputFieldId}
        onCancelCallback={toggleShowModalSummaryOutputTypeInputFormListValueCreate}
        onCompleteCallback={toggleShowModalSummaryOutputTypeInputFormListValueCreate}
      />
      {summaryOutputTypeInputFieldListValueToEdit && (
        <ModalSummaryOutputTypeInputFieldListValueUpdate
          onCancelCallback={() => setSummaryOutputTypeInputFieldListValueToEdit(undefined)}
          onCompleteCallback={() => setSummaryOutputTypeInputFieldListValueToEdit(undefined)}
          open={summaryOutputTypeInputFieldListValueToEdit !== undefined}
          summaryOutputTypeInputFieldListValue={summaryOutputTypeInputFieldListValueToEdit}
        />
      )}
    </DivWrapper>
  );
}

export default SummaryOutputTypeInputFieldListValueMaintenanceSection;
