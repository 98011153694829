import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchSearchSingleAssetOutput } from "../../redux/singleAssetOutput/actions";
import {
  fetchSearchSingleAssetOutputJob,
  IFetchSearchSingleAssetOutputJobProps,
} from "../../redux/singleAssetOutputJob/actions";
import { SummaryOutputJobStatusEnum } from "../../utilities/types/SummaryOutputJob";
import { IOutputJob, OutputJobStatusEnum } from "../../utilities/types/SingleAssetOutputJob";
import { useFetchSolverJobSummaryReportsPageHook } from "../solverJobSummaryReports/Hooks";

interface IUseFetchSingleAssetOutputJobPageHookProps extends IFetchSearchSingleAssetOutputJobProps {
  minPageNumberToFetch: number;
  onlyFetchByIds?: boolean;
}

type IUseFetchSingleAssetOutputJobCombinedPageHookProps = {
  searchText?: string;
  pageNumber: number;
  pageSize: number;
  status: OutputJobStatusEnum | SummaryOutputJobStatusEnum | undefined;
  outputTypeId?: string;
  prerequisiteIds?: string;
};

/** Combined the task and summary report fetch hooks into one */
export const useFetchSingleAssetOutputJobCombinedReportPageHook = ({
  pageNumber,
  searchText,
  status,
  pageSize,
  outputTypeId,
  prerequisiteIds,
}: IUseFetchSingleAssetOutputJobCombinedPageHookProps) => {
  // Fetch output types if they haven't alreay loaded
  const dispatch = useDispatch();
  const singleAssetOutputExist = useSelector((store: RootState) => Object.values(store.singleAssetOutput.byId).length);

  useEffect(() => {
    if (!singleAssetOutputExist) {
      if (prerequisiteIds !== undefined && !prerequisiteIds) return;
      dispatch(fetchSearchSingleAssetOutput({ pageNumber, pageSize: pageSize, outputTypeId }));
    }
  }, [singleAssetOutputExist, pageNumber, pageSize, outputTypeId, dispatch, prerequisiteIds]);

  const {
    fetching: fetchingSum,
    lastResultSet: lastResultSetSum,
    morePages: morePagesSum,
  } = useFetchSolverJobSummaryReportsPageHook({
    pageNumber,
    pageSize,
    minPageNumberToFetch: 1,
    status: status as SummaryOutputJobStatusEnum,
    text: searchText,
    ...(prerequisiteIds !== undefined ? { solverJobSummaryReportId: prerequisiteIds, onlyFetchByIds: true } : {}),
  });

  const {
    fetching: fetchingTasks,
    lastResultSet: lastResultSetTasks,
    morePages: morePagesTasks,
  } = useFetchSingleAssetOutputJobPageHook({
    pageNumber,
    pageSize,
    minPageNumberToFetch: 1,
    status: status as OutputJobStatusEnum,
    text: searchText,
    ...(prerequisiteIds !== undefined ? { solverJobTaskReportId: prerequisiteIds, onlyFetchByIds: true } : {}),
  });

  const reports = [...lastResultSetSum, ...lastResultSetTasks];

  const fetching = fetchingSum || fetchingTasks;
  const morePages = morePagesSum || morePagesTasks;
  return {
    reports,
    fetching,
    morePages,
  };
};

export const useFetchSingleAssetOutputJobPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  text,
  pageSize,
  outputTypeId,
  solverJobTaskReportId,
  status,
  onlyFetchByIds = false,
}: IUseFetchSingleAssetOutputJobPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IOutputJob[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IOutputJob[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    if (onlyFetchByIds && !solverJobTaskReportId) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        var singleAssetOutputJob = (await dispatch(
          fetchSearchSingleAssetOutputJob({
            pageSize,
            pageNumber,
            outputTypeId,
            solverJobTaskReportId,
            status,
            text,
          })
        )) as unknown as IOutputJob[];

        if (singleAssetOutputJob && singleAssetOutputJob.length) {
          setMorePages(singleAssetOutputJob.length >= pageSize);
          setLastResultSet(singleAssetOutputJob);
        } else {
          setLastResultSet([]);
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [
    minPageNumberToFetch,
    dispatch,
    pageNumber,
    pageSize,
    outputTypeId,
    solverJobTaskReportId,
    status,
    text,
    onlyFetchByIds,
  ]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.solverJobTaskReportId === x.solverJobTaskReportId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
