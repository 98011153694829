import React from "react";
import { Toolbar, IconButton, Typography, Menu, Grid, Divider, Avatar, styled, MenuProps } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import { DrawerWidth } from "./LayoutConstants";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetUserDetailById } from "../../redux/userDetail/selectors";
import { Logout, useIsLoggedIn } from "../../utilities/ApiUtils";
import PersonIcon from "@mui/icons-material/PersonSharp";
import { IUserDetail } from "../../utilities/types/UserDetail";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import { GetUserLinkByUser } from "../../routes/RouteLinkHelpers";
import { Configuration } from "../../utilities/Constants";
import FeedbackButton from "../feedback/FeedbackButton";
import { selectorGetSystemState } from "../../redux/systemState/actions";

const MainDiv = styled("div")(({ theme }) => ({
  flexGrow: 1,
  "& .menuButton": {
    marginRight: theme.spacing(2),
  },
  "& .title": {
    flexGrow: 1,
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DrawerWidth,
    width: `calc(100% - ${DrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface ILayoutDefaultAppBarProps {
  open: boolean;
  setDrawerOpen(open: boolean): void;
}

const LayoutDefaultAppBar = ({ open, setDrawerOpen }: ILayoutDefaultAppBarProps) => {
  const userId = useSelector((store: RootState) => selectorGetSystemState(store))?.userId || "";
  const isLoggedIn = useIsLoggedIn();
  const userDetails = useSelector((store: RootState) => selectorGetUserDetailById(store, userId));

  return (
    <MainDiv>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar>
          <IconButton
            edge="start"
            className="menuButton"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(!open)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="title" />
          {isLoggedIn && <FeedbackButton />}
          <LoginButton userDetails={userDetails} />
        </Toolbar>
      </AppBar>
    </MainDiv>
  );
};

const MenuLinkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#FFF",
  textDecoration: "none",
  cursor: "pointer",
  transition: "opacity 250ms ease-out",
  "&:hover": {
    opacity: 0.8,
    cursor: "pointer",
  },
  "& .navbarAvatar": {
    marginRight: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: "1px solid rgba(255,255,255,0.2)",
  },
}));

const AvatarImage = styled("img")(({ theme }) => ({
  width: "100px",
  height: "100px",
  objectFit: "cover",
  borderRadius: "50%",
  objectPosition: "50% 50%",
  backgroundColor: "#EEE",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: theme.spacing(1),
  border: "1px solid rgba(0,0,0,0.2)",
}));

const LoginButton = ({ userDetails }: { userDetails?: IUserDetail }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const imageUrl = useFileSourceSingleHook({ fileId: userDetails?.fileId || "", fileType: FileTypeEnum.Image });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!userDetails) {
    return (
      <NavLink to="/login">
        <MenuLinkButton color="inherit" startIcon={<PersonIcon />}>
          Login
        </MenuLinkButton>
      </NavLink>
    );
  }

  return (
    <>
      <MenuLinkButton
        color="inherit"
        startIcon={<Avatar alt={userDetails.displayName} src={imageUrl} className="navbarAvatar" />}
        onClick={handleClick}
        id="btn-user-details"
      >
        <span style={{ textTransform: "none" }}>{userDetails.displayName}</span>
      </MenuLinkButton>
      <StyledMenu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ overflowY: "hidden" }}
      >
        <Grid container direction="column" justifyContent="center" style={{ textAlign: "center" }} spacing={1.5}>
          <Grid item xs={12} mb={1.5}>
            <AvatarImage src={imageUrl} alt={userDetails.displayName} />
            <div style={{ marginTop: 8 }}>
              <Typography variant="caption">{userDetails?.company || "-"}</Typography>
              <Typography variant="subtitle1">{userDetails?.displayName || "-"}</Typography>
            </div>
          </Grid>
          <Divider />
          <Grid item xs={12} mb={1.5}>
            <Button variant="outlined" size="small" component={NavLink} to={GetUserLinkByUser(userDetails)}>
              Manage Account
            </Button>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <a
              href={Configuration.COGNITO_ENDPOINT_LOGOUT}
              onClick={Logout}
              target="_self"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button variant="outlined" size="small">
                Sign Out
              </Button>
            </a>
          </Grid>
        </Grid>
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)<MenuProps>({
  "& .MuiMenu-paper": {
    border: "1px solid #d3d4d5",
    width: 250,
  },
});

export default LayoutDefaultAppBar;
