import React, { useState } from "react";
import { DialogContent, Divider, DialogActions } from "@mui/material";
import { useFetchDiscussionsPageHook } from "./Hooks";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import HeaderIcon from "@mui/icons-material/MessageTwoTone";
import { DiscussionSearchOrderTypeEnum } from "../../redux/discussion/types";
import DiscussionRow from "./DiscussionRow";

const DiscussionAdminContainer = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { fetching, lastResultSet: discussions } = useFetchDiscussionsPageHook({
    pageNumber,
    pageSize: 10,
    minPageNumberToFetch: 1,
    orderType: DiscussionSearchOrderTypeEnum.CreatedDateDesc,
  });

  const handleRefetch = () => {
    setPageNumber(pageNumber);
  };

  return (
    <WidgetSectionBase
      headerText="Discussions"
      subheaderText="Overview of recent discussions."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DialogContent style={{ maxHeight: 250, paddingTop: 0 }}>
        {discussions.map((x) => {
          return (
            <>
              <DiscussionRow discussion={x} showAdminDetails={true} commentLevel={0} handleRefetch={handleRefetch} />
              <Divider />
            </>
          );
        })}
        <LoaderAbsoluteCentred loading={fetching} />
      </DialogContent>
      <DialogActions></DialogActions>
    </WidgetSectionBase>
  );
};

export default DiscussionAdminContainer;
