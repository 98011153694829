import { DecimalPlaces } from "../inputFields/numberFieldUtils";

export interface ISummaryOutputTypeInputField {
    summaryOutputTypeInputFieldId: string,
    summaryOutputTypeId: string,
    label: string,
    description: string,
    defaultValue: number,
    defaultString: string,
    defaultDateTime: Date,
    defaultSummaryOutputTypeInputFieldListValueId?: string,
    minValue: number,
    maxValue: number,
    decimalPlaces: DecimalPlaces,
    tableName: string,
    orderNumber: number,
    type: SummaryOutputTypeInputFieldTypeEnum,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum SummaryOutputTypeInputFieldTypeEnum {
    Number = 10,
    CustomEnumeration = 20,
    String = 30,
    DateTime = 40,
}