import React from "react"

/* This HTML can be generated using an online word to HTML convertor (e.g. https://html-cleaner.com/). If using the sample site be sure to press "Clean HTML" after the conversion has finished and to remove the link at the end. */
export default function TermsAndConditions() {
    return (
        <div>
            <p><h1>Assetwiki.org</h1>requires users to create an account, username and password.&nbsp;</p>
            <p>The purpose of assetwiki.org, is to facilitate the sharing of knowledge and understanding of assets. Each user contribution is recorded and adds to the growing knowledge-base of information around asset classes, and business processes.&nbsp;</p>
            <p>Any user behaviour that is not aligned to this purpose will not be tolerated, and users may be removed if their actions are not aligned to the purpose (above) and continual improvement of the knowledge base.</p>
            <p>The asset models hosted on assetwiki.org, are licensed under a creative commons licence. This allows users to distribute, remix, adapt, and build upon the material in any medium or format, so long as attribution is given to the creators. The license allows for commercial use. If you remix, adapt, or build upon the material, you must license the modified material back to the community under identical terms.</p>
            <p>The solvers hosted on assetwiki.org, are licensed under a pay per use agreement. This DOES NOT allow users to distribute, remix, adapt, and build upon the material in any medium or format. The license allows for commercial use under a subscription model.&nbsp;</p>
            <p>The outputs hosted on assetwiki.org, are licensed under a pay per use agreement. This DOES NOT allow users to distribute, remix, adapt, and build upon the material in any medium or format. The license allows for commercial use under a subscription model.&nbsp;</p>
            <p>Assetwiki.org is proudly hosted by <a href="https://www.modla.co">Modla</a></p>  
        </div>
    )
}