import React from "react";
import { CircularProgress, styled } from "@mui/material";

import { green } from "@mui/material/colors";

const DivWrapper = styled("div")({
  top: "0px",
  left: "0px",
  position: "absolute",
  height: "100%",
  width: "100%",
  zIndex: 999,
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.28)",
  "& .buttonProgress": {
    color: green[500],
    left: "50%",
    top: "50%",
    position: "absolute",
  },
});

interface ILoaderAbsoluteCentred {
  loading: boolean;
  size?: number;
}

function LoaderAbsoluteCentred({ loading, size = 24 }: ILoaderAbsoluteCentred) {
  if (!loading) return null;

  return (
    <DivWrapper>
      <CircularProgress
        size={size}
        className="buttonProgress"
        style={{
          marginTop: -(size / 2),
          marginLeft: -(size / 2),
        }}
      />
    </DivWrapper>
  );
}

export default LoaderAbsoluteCentred;
