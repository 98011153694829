import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import MapIcon from "@mui/icons-material/MapRounded";
import { ISolverDetails } from "../../../utilities/types/SolverDetails";
import FormSolverDetailsCreate from "../forms/FormSolverDetailsCreate";

export interface IModalDetailsCreateProps {
  open: boolean;
  solverId: string;
  onCancelCallback(): void;
  onCompleteCallback(file?: ISolverDetails): void;
}

function ModalSolverDetailsCreate({ onCancelCallback, open, onCompleteCallback, solverId }: IModalDetailsCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create new solver details"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormSolverDetailsCreate
          solverId={solverId}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSolverDetailsCreate;
