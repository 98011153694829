import { ActionTypesFeedback, FeedbackState, ACTIONS_FEEDBACK } from "./types";

const initialState: FeedbackState = {
    byId: {},
}


export function feedbacksReducer(
    state = initialState,
    action: ActionTypesFeedback
): FeedbackState {
    switch(action.type){
        case ACTIONS_FEEDBACK.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_FEEDBACK.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_FEEDBACK.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}