import { ACTIONS_DISCUSSION, DiscussionSearchOrderTypeEnum } from "./types";
import { AppThunk } from "..";
import { Configuration } from "../../utilities/Constants";
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from "../../utilities/ApiUtils";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { DiscussionStatusEnum, IDiscussion } from "../../utilities/types/Discussion";
import { EntityTypeEnum } from "../../utilities/types/Entity";

export const receiveDiscussions = (discussions: IDiscussion[]) => {
  var byIdObjectToDispatch: { [key: string]: IDiscussion } = {};

  for (var i = 0; i < discussions.length; i++) {
    byIdObjectToDispatch[discussions[i].discussionId] = discussions[i];
  }

  return {
    type: ACTIONS_DISCUSSION.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteDiscussion = (discussion: IDiscussion) => ({
  type: ACTIONS_DISCUSSION.DELETE,
  byId: { [discussion.discussionId]: discussion },
});

export interface IFetchCreateDiscussionProps {
  title: string;
  text: string;
  entityType: EntityTypeEnum;
  entityId: string;
}

export const fetchCreateDiscussion =
  (discussionToCreate: IFetchCreateDiscussionProps): AppThunk<Promise<IDiscussion>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/discussions`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(discussionToCreate),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.discussions) {
        dispatch(receiveDiscussions(parsedResp.data.discussions));
        return parsedResp.data.discussions;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating discussion.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating discussion.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchUpdateDiscussionProps {
  discussionId: string;
  title: string;
  text: string;
  entityId: string;
  status?: DiscussionStatusEnum;
}

export const fetchUpdateDiscussion =
  (props: IFetchUpdateDiscussionProps): AppThunk<Promise<IDiscussion>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/discussions`, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.discussions) {
        dispatch(receiveDiscussions(parsedResp.data.discussions));
        return parsedResp.data.discussions;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error updating discussion.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error updating discussion.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchDiscussionsProps {
  pageNumber: number;
  pageSize: number;
  discussionId?: string;
  text?: string;
  createdBy?: string;
  entityType?: EntityTypeEnum;
  entityId?: string;
  orderType?: DiscussionSearchOrderTypeEnum;
}

export const fetchSearchDiscussions =
  (searchParams: IFetchSearchDiscussionsProps): AppThunk<Promise<IDiscussion[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/discussions`, searchParams), {
        method: "GET",
        headers: headers,
      });

      // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.discussions) {
        // Remove unmapped image property
        for (var discussion of parsedResp.data.discussions) {
          delete discussion.mainImage;
        }

        dispatch(receiveDiscussions(parsedResp.data.discussions));
        return parsedResp.data.discussions;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching discussion.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching discussion.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

export interface IFetchDeleteDiscussionProps {
  discussionId: string;
}

export const fetchDeleteDiscussion =
  (props: IFetchDeleteDiscussionProps): AppThunk<Promise<IDiscussion>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/discussions`, props), {
        method: "DELETE",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (
        parsedResp &&
        parsedResp.success &&
        parsedResp.data &&
        parsedResp.data.discussions &&
        parsedResp.data.discussions.length
      ) {
        dispatch(receiveDiscussions(parsedResp.data.discussions));
        return parsedResp.data.discussions[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting discussion.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting discussion.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
