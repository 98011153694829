import React from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IDiscussion } from "../../../utilities/types/Discussion";
import { fetchCreateDiscussion } from "../../../redux/discussion/actions";
import { EntityTypeEnum } from "../../../utilities/types/Entity";

interface FormValues {
  title: string;
  text: string;
}

interface FormProps {
  onCompleteCallback(discussion?: IDiscussion): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IDiscussion, AnyAction>;
  entityType: EntityTypeEnum;
  entityId: string;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "title", "Title")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={5}
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "text", "Text")}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormDiscussionCreate = withFormik<FormProps, FormValues>({
  validationSchema: Yup.object().shape({
    title: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(200, "Please input 200 characters or less")
      .required("Please provide a name"),
    text: Yup.string().label("Text").max(500, "Please input 2500 characters or less"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch } = props;

    // Map dispatch via props
    var createdDiscussion = await dispatch(
      fetchCreateDiscussion({
        ...values,
        entityId: props.entityId,
        entityType: props.entityType,
      })
    );

    setSubmitting(false);

    if (createdDiscussion) onCompleteCallback(createdDiscussion);
  },
})(InnerForm);

export default FormDiscussionCreate;
