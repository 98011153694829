import { RootState } from "..";
import { IPlan } from "../../utilities/types/Plan";


export function selectorGetPlansByModelId(state: RootState, modelId: string) : IPlan[] {

    if (!state.plans || !state.plans.byId) return [];

    return sortPlansByOrderNumberAscending(Object.values(state.plans.byId).filter(x => x.modelId === modelId));
}


export function selectorGetPlanById(state: RootState, planId: string) : IPlan | undefined {

    if (!state.plans || !state.plans.byId) return undefined;

    return state.plans.byId[planId];
}


// function sortPlansByOrderNumberDescending(plans: IPlan[]) {
//     return plans.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortPlansByOrderNumberAscending(plans: IPlan[]) {
    return plans.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.name < a.name) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}