import { IFile } from "../../utilities/types/File";

export const ACTIONS_FILE = {
    INVALIDATE: 'INVALIDATE_FILES',
    RECEIVE: 'RECEIVE_FILES',
    UPDATE: 'UPDATE_FILES',
    DELETE: 'DELETE_FILE',
}

interface ActionFilesInvalidate {
    type: typeof ACTIONS_FILE.INVALIDATE,
    byId: { [key: string]: IFile },
}

interface ActionFilesReceive {
    type: typeof ACTIONS_FILE.RECEIVE
    byId: { [key: string]: IFile },
}

interface ActionFilesUpdate {
    type: typeof ACTIONS_FILE.UPDATE
    byId: { [key: string]: IFile },
}

interface ActionFilesDelete {
    type: typeof ACTIONS_FILE.DELETE
    byId: { [key: string]: IFile },
}

export interface FileState {
    byId: { [key: string]: IFile },
}

export type ActionTypesFile = ActionFilesInvalidate | ActionFilesReceive | ActionFilesUpdate | ActionFilesDelete;