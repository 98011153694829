import React from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IDecisionAssetInputFieldMapping } from "../../../utilities/types/DecisionAssetInputFieldMapping";
import { fetchCreateDecisionAssetInputFieldMapping } from "../../../redux/decisionAssetInputFieldMapping/actions";
import { selectorGetAssetInputFieldsByModelId } from "../../../redux/assetInputField/selectors";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/lab";
import { IAssetInputField } from "../../../utilities/types/AssetInputField";

interface FormValues {
  notes: string;
  orderNumber: number;
  assetInputFieldId: string;
}

interface FormProps {
  modelId: string;
  decisionId: string;
  decisionAssetInputFieldObj?: IAssetInputField;
  onCompleteCallback(decisionAssetInputFieldMapping?: IDecisionAssetInputFieldMapping): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IDecisionAssetInputFieldMapping, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  var inputFields = useSelector((store: RootState) => selectorGetAssetInputFieldsByModelId(store, props.modelId));

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            {...getFormikFieldProps(props, "decisionAssetInputFieldObj", "Field")}
            style={{ display: "block" }}
            options={inputFields}
            getOptionLabel={(option) => option.label}
            onChange={(_, value) => {
              props.setFieldValue("assetInputFieldId", value ? value.assetInputFieldId : "");
              props.setFieldValue("decisionAssetInputFieldObj", value);
            }}
            renderInput={(params) => <TextField variant="standard" {...params} label="Input Field" />}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={props.handleChange}
            type="number"
            variant="standard"
            fullWidth
            margin="normal"
            {...getFormikFieldProps(props, "orderNumber", "Order Number")}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormDecisionAssetInputFieldMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    notes: "",
    assetInputFieldId: "",
    orderNumber: 0,
  }),
  validationSchema: Yup.object().shape({
    notes: Yup.string().label("Description").max(500, "Please input 2500 characters or less"),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
    assetInputFieldId: Yup.string().required("An input field is required"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId, decisionId } = props;

    // Map dispatch via props
    var createdDecisionAssetInputFieldMapping = await dispatch(
      fetchCreateDecisionAssetInputFieldMapping({
        ...values,
        modelId,
        decisionId,
      })
    );

    setSubmitting(false);

    if (createdDecisionAssetInputFieldMapping) onCompleteCallback(createdDecisionAssetInputFieldMapping);
  },
})(InnerForm);

export default FormDecisionAssetInputFieldMappingCreate;
