import React from "react";
import { Typography, styled } from "@mui/material";
import SummaryWidgetBase from "./WidgetSummaryBase";

const DivWrapper = styled("div")(({ theme }) => ({
  textAlign: "right",
  paddingBottom: theme.spacing(2),
  "& .buttonContainer": {
    paddingTop: theme.spacing(2),
    textAlign: "right",
    paddingRight: 0,
  },
}));

type WidgetSummaryCountAndButtonProps = {
  captionText: string;
  mainText?: string;
  buttons: React.ReactNode;
  headerIcon: React.ReactElement<JSX.Element>;
  headerColor?: string;
  style?: { [key: string]: any };
};

const WidgetSummaryCountAndButtons = ({
  captionText,
  mainText,
  buttons,
  headerIcon,
  headerColor,
  style,
}: WidgetSummaryCountAndButtonProps) => {
  return (
    <SummaryWidgetBase headerIcon={headerIcon} headerColor={headerColor} style={style}>
      <DivWrapper>
        <Typography variant="caption" style={{ opacity: 0.75, marginBottom: 0 }}>
          {captionText}
        </Typography>
      </DivWrapper>
      <div className="buttonContainer">{buttons}</div>
    </SummaryWidgetBase>
  );
};

export default WidgetSummaryCountAndButtons;
