import React from "react";
import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import GenericLinkWidget from "../../generic/widgets/GenericLinkWidget";
import AssetImage from "../../../assets/img/Asset.png";
import SolverImage from "../../../assets/img/Solver.png";
import OutputImage from "../../../assets/img/Output.png";
import { IntroductionContent, VisionContent } from "../../content/Content";

const PageHome = (routeProps: RouteComponentProps<any>) => {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid container style={{ marginTop: 16 }}>
          <Grid item xs={12} style={{ paddingRight: 16 }}>
            <IntroductionContent />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography variant="h5">Vision</Typography>
          <VisionContent />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} style={{ marginTop: 16 }} id="search-results-wrapper">
            <AnimationWrapper key={"Test1"}>
              <Grid item xs={12} sm={6} md={4}>
                <GenericLinkWidget
                  title="Assets"
                  subheader="Asset Models and knowledge bases"
                  description="Used to capture current thinking around an asset class or system"
                  image={AssetImage}
                  linkPath="/Search"
                />
              </Grid>
            </AnimationWrapper>
            <AnimationWrapper key={"Test2"}>
              <Grid item xs={12} sm={6} md={4}>
                <GenericLinkWidget
                  title="Solvers"
                  subheader="Methods, optimisations and calculation techniques"
                  description="Use cases to answer business questions"
                  image={SolverImage}
                  linkPath="/Solvers"
                />
              </Grid>
            </AnimationWrapper>
            <AnimationWrapper key={"Test3"}>
              <Grid item xs={12} sm={6} md={4}>
                <GenericLinkWidget
                  title="Outputs"
                  subheader="Documents, dashboards, loaders and exports"
                  description="Used to transform the Solver outputs into a useable format"
                  image={OutputImage}
                  linkPath="/Outputs"
                />
              </Grid>
            </AnimationWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageHome;
