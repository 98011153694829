import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
  InputBase,
  Divider,
  Chip,
  styled,
} from "@mui/material";
import HeaderColor from "@mui/material/colors/blueGrey";

import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import HeaderIcon from "@mui/icons-material/Timeline";
import { useFetchAssetJobsPageHook } from "./Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { useFileSourceSingleHook } from "../file/Hooks";
import SearchIcon from "@mui/icons-material/Search";
import CheckboxIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import CheckboxIconFilled from "@mui/icons-material/RadioButtonCheckedRounded";
import { AddSpacesToSentence, ToTitleCase } from "../../utilities/Helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { GetAssetJobLinkByAssetJobId } from "../../routes/RouteLinkHelpers";

import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { selectorGetModelById } from "../../redux/model/selectors";
import { AssetJobStatusEnum, IAssetJob } from "../../utilities/types/AssetJobs";
import { sortAssetJobsByCreatedDescending } from "../../redux/assetJob/selectors";
import { displayFormattedDate } from "../../utilities/formatter";

const DivWrapper = styled("div")(({ theme }) => ({
  minHeight: 500,
  height: "100%",

  "& .assetJobWrapper": {
    border: "1px solid #EEE",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  "& .assetJobImageWrapper": {
    width: "100%",
    height: "80%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
    maxWidth: "100%",
    left: 0,
    bottom: 0,
    top: 0,
    overflow: "hidden",
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& .assetJobImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    borderRadius: `${theme.shape.borderRadius}px`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .assetJobImageGrid": {
    display: "flex",
    alignItems: "center",
  },
  "& .creatorBar": {
    width: 5,
    height: "80%",
    marginLeft: theme.spacing(1),
    borderRadius: 28,
    background: HeaderColor[800],
  },
  "& .assetJobMainWrapper": {
    marginLeft: theme.spacing(1),
  },

  "& .searchRoot": {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid #DEDEDE",
  },
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: 28,
    margin: 4,
  },
  "& .assetJobsWrapper": {
    maxHeight: 400,
    overflowY: "auto",
  },
  "& .fade": {
    animation: "$fade 2000ms infinite",
  },
  "@keyframes fade": {
    "50%": {
      opacity: 0.3,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

type IAssetJobEnquiryContainerProps = {
  canEdit: boolean;
};

const AssetJobsEnquiryContainer = ({ canEdit }: IAssetJobEnquiryContainerProps) => {
  const [searchStatus, setSearchStatusInternal] = useState<AssetJobStatusEnum>();
  const [searchText, setSearchTextInternal] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);

  const {
    fetching,
    lastResultSet: assetJobs,
    morePages,
  } = useFetchAssetJobsPageHook({
    pageNumber: pageNumber,
    pageSize: 20,
    minPageNumberToFetch: 1,
    status: searchStatus,
    text: searchText,
  });

  function setSearchStatus(status?: AssetJobStatusEnum) {
    setSearchStatusInternal(status);
    setPageNumber(1);
  }

  function setSearchText(text?: string) {
    setSearchTextInternal(text);
    setPageNumber(1);
  }

  return (
    <AssetJobEnquiryContainerDisplay
      canEdit={canEdit}
      assetJobs={sortAssetJobsByCreatedDescending(assetJobs)}
      loading={fetching}
      onSetSearchStatus={(newStatus: AssetJobStatusEnum) => setSearchStatus(newStatus)}
      onSetSearchText={(newText: string) => setSearchText(newText)}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      hasMorePages={morePages}
    />
  );
};

type IAssetJobEnquiryContainerDisplayProps = {
  canEdit: boolean;
  assetJobs: IAssetJob[];
  loading: boolean;
  onSetSearchStatus(newStatus?: AssetJobStatusEnum): void;
  onSetSearchText(newText?: string): void;
  pageNumber: number;
  setPageNumber(page: number): void;
  hasMorePages: boolean;
};

const AssetJobEnquiryContainerDisplay = ({
  assetJobs,
  hasMorePages,
  setPageNumber,
  pageNumber,
  loading,
  canEdit,
  onSetSearchStatus,
  onSetSearchText,
}: IAssetJobEnquiryContainerDisplayProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [statusFilter, setJobStatusToFilterBy] = useState<AssetJobStatusEnum>();
  const [searchText, setSearchTextFilter] = useState<string>();

  function updateJobStatusViaMenu(newStatus?: AssetJobStatusEnum) {
    setJobStatusToFilterBy(newStatus);
    setMenuAnchorEl(undefined);
    onSetSearchStatus(newStatus);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  function onSearchClick(e: any) {
    e.preventDefault();
    onSetSearchText(searchText);
    return false;
  }

  return (
    <WidgetSectionBase
      headerText="Asset Jobs"
      subheaderText="Browse submitted Asset Jobs."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DivWrapper>
        <Paper component="form" className="searchRoot" onSubmit={onSearchClick}>
          <Button className="iconButton" aria-label="menu" onClick={handleMenuClick} size="small">
            {ToTitleCase(statusFilter ? AssetJobStatusEnum[statusFilter].toString() : "All")}
          </Button>
          <Divider className="divider" orientation="vertical" />
          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(undefined)}
          >
            <div style={{ paddingRight: 16, paddingLeft: 16 }}>
              <Typography variant="overline">Status Filters</Typography>
            </div>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(undefined)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === undefined} />
              </ListItemIcon>
              <ListItemText primary="None" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(AssetJobStatusEnum.Pending)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === AssetJobStatusEnum.Pending} />
              </ListItemIcon>
              <ListItemText primary="Pending" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(AssetJobStatusEnum.Progress)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === AssetJobStatusEnum.Progress} />
              </ListItemIcon>
              <ListItemText primary="In Progress" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(AssetJobStatusEnum.Failed)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === AssetJobStatusEnum.Failed} />
              </ListItemIcon>
              <ListItemText primary="Failed" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(AssetJobStatusEnum.Complete)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === AssetJobStatusEnum.Complete} />
              </ListItemIcon>
              <ListItemText primary="Complete" />
            </MenuItem>
          </Menu>
          <InputBase
            className="input"
            placeholder="Search Asset Jobs"
            inputProps={{ "aria-label": "Search Asset Jobs" }}
            onChange={onSearchTextChangeHandler}
            onSubmit={onSearchClick}
          />
          <Divider className="divider" orientation="vertical" />
          <IconButton className="iconButton" aria-label="search" onClick={onSearchClick}>
            <SearchIcon />
          </IconButton>
        </Paper>

        {loading ? (
          <LoaderAbsoluteCentred loading={loading} />
        ) : (
          <AnimationWrapper>
            <div className="assetJobsWrapper">
              {assetJobs.map((assetJob) => {
                return <AssetJobRow key={assetJob.assetJobId} assetJob={assetJob} />;
              })}
              {(!assetJobs || !assetJobs.length) && (
                <div style={{ marginTop: 24 }}>
                  <WidgetNoResultsPlaceholder text="No Jobs" icon={HeaderIcon} flip={true} />
                </div>
              )}
            </div>
          </AnimationWrapper>
        )}
      </DivWrapper>
      <div style={{ textAlign: "center" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              onClick={() => setPageNumber(pageNumber - 1)}
              variant="outlined"
              fullWidth={true}
              {...(pageNumber === 1 ? { disabled: true } : {})}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => setPageNumber(pageNumber + 1)}
              variant="outlined"
              fullWidth={true}
              {...(!hasMorePages ? { disabled: true } : {})}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <LoaderAbsoluteCentred loading={loading} />
      </div>
    </WidgetSectionBase>
  );
};

function StatusFilterIcon({ checked }: { checked: boolean }) {
  return checked ? <CheckboxIconFilled fontSize="small" /> : <CheckboxIcon fontSize="small" />;
}

export function AssetJobRow({ assetJob }: { assetJob: IAssetJob }) {
  const model = useSelector((store: RootState) => selectorGetModelById(store, assetJob ? assetJob.modelId : ""));

  const modelImageUrl = useFileSourceSingleHook({
    fileId: model && model.mainImageId ? model.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });
  const currentUserId = GetUserId();
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state, currentUserId));

  return (
    <>
      <Link
        component={RouterLink}
        to={GetAssetJobLinkByAssetJobId(assetJob.assetJobId)}
        style={{ textDecoration: "none" }}
      >
        <Grid container className="assetJobWrapper">
          <Grid item xs={3} className="assetJobImageGrid">
            <div className="assetJobImageWrapper">
              <div className="assetJobImage" style={{ backgroundImage: `url("${modelImageUrl}")` }} />
            </div>
            {isAdmin && assetJob.createdBy === currentUserId ? <div className="creatorBar"></div> : null}
          </Grid>
          <Grid item xs={9}>
            <div className="assetJobMainWrapper">
              <div>
                <Typography variant="body2" noWrap={true}>
                  {assetJob.name ? `${assetJob.name} ` : ""} - {displayFormattedDate(assetJob.created, "")}
                </Typography>
                {assetJob.persistentErrorMessage ? (
                  <Typography style={{ opacity: 0.8, fontSize: "85%", color: "rgb(200,0,0)" }} noWrap={true}>
                    {assetJob.persistentErrorMessage}
                  </Typography>
                ) : (
                  <Typography style={{ opacity: 0.8, fontSize: "85%" }} noWrap={true}>
                    {assetJob.progressLog}
                  </Typography>
                )}
                {assetJob.status === AssetJobStatusEnum.Failed ? (
                  <Chip
                    label={AssetJobStatusEnum[assetJob.status].toString()}
                    variant="outlined"
                    size="small"
                    className="fade"
                    style={{
                      transform: "scale(0.85)",
                      marginLeft: -4,
                      color: "rgb(255 255 255)",
                      backgroundColor: "rgba(226, 18, 18, 0.90)",
                      borderColor: "#ff7777",
                    }}
                  />
                ) : (
                  <Chip
                    label={AddSpacesToSentence(AssetJobStatusEnum[assetJob.status].toString())}
                    variant="outlined"
                    size="small"
                    style={{
                      transform: "scale(0.85)",
                      marginLeft: -4,
                    }}
                  />
                )}
                {assetJob.status !== AssetJobStatusEnum.Pending ? (
                  <Chip
                    label={`${assetJob.assetCount} ${assetJob.assetCount === 1 ? "asset" : "assets"}`}
                    variant="outlined"
                    size="small"
                    style={{
                      transform: "scale(0.85)",
                      marginLeft: -4,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </Link>
    </>
  );
}

export default AssetJobsEnquiryContainer;
