export interface ISolverUIResultSetChart {
    hidden?: boolean,
    solverUIResultSetChartId: string,
    solverJobTaskId: string,
    name: string,
    chartType: SolverUIResultSetChartTypeEnum,
    chartPlots: [number[]],
}

export enum SolverUIResultSetChartTypeEnum {
    Line = 10,
    BarVertical = 20,
    StackedLine = 30,
}