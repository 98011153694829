import { ACTIONS_SOLVERDETAILS, SolverDetailsSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ISolverDetails } from '../../utilities/types/SolverDetails';


export const receiveSolverDetails = (solverDetails: ISolverDetails[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverDetails } = {};

    for (var i = 0; i < solverDetails.length; i++) {
        byIdObjectToDispatch[solverDetails[i].solverDetailsId] = solverDetails[i];
    }

    return {
        type: ACTIONS_SOLVERDETAILS.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverDetails = (solverDetails: ISolverDetails) => ({
    type: ACTIONS_SOLVERDETAILS.DELETE,
    byId: { [solverDetails.solverDetailsId]: solverDetails }
});


export interface IFetchCreateSolverDetailsProps {
    solverId: string,
    overview: string,
    technicalInfo: string,
    useCases: string,
    references: string,
}


export const fetchCreateSolverDetails = (solverToCreate: IFetchCreateSolverDetailsProps): AppThunk<Promise<ISolverDetails>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverDetails`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(solverToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverDetails) {
            dispatch(receiveSolverDetails([parsedResp.data.solverDetails]));
            return parsedResp.data.solverDetails;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating solver Details.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating solver Details.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSolverDetailsProps {
    solverDetailsId?: string,
    solverId?: string,
    overview?: string,
    technicalInfo?: string,
    useCases?: string,
    references?: string,
}


export const fetchUpdateSolverDetails = (props: IFetchUpdateSolverDetailsProps): AppThunk<Promise<ISolverDetails>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverDetails`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverDetails) {
            dispatch(receiveSolverDetails(parsedResp.data.solverDetails));
            return parsedResp.data.solverDetails;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating solver details.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating solver details.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchSolverDetailsProps {
    pageNumber: number,
    pageSize: number,
    solverDetailsId?: string,
    solverId?: string,
    text?: string,
    createdBy?: string,
    orderType?: SolverDetailsSearchOrderTypeEnum,
}


export const fetchSearchSolverDetails = (searchParams: IFetchSearchSolverDetailsProps): AppThunk<Promise<ISolverDetails[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverDetails`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverDetails) {

            dispatch(receiveSolverDetails(parsedResp.data.solverDetails));
            return parsedResp.data.solverDetails;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching solver details.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching solver details.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSolverDetailsProps {
    solverDetailsId: string,
}


export const fetchDeleteSolverDetails = (props: IFetchDeleteSolverDetailsProps): AppThunk<Promise<ISolverDetails>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverDetails`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverDetails && parsedResp.data.solvers.length) {
            dispatch(requestDeleteSolverDetails(parsedResp.data.solverDetails[0]));
            return parsedResp.data.solverDetails[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting solver details.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting solver details.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}