import { RootState } from "..";
import { ISolverOutputTypeMapping } from "../../utilities/types/SolverOutputTypeMapping";


export function selectorGetSolverOutputTypeMappingsBySolverId(state: RootState, solverId: string) : ISolverOutputTypeMapping[] {

    if (!state.solverOutputTypeMappings || !state.solverOutputTypeMappings.byId) return [];

    return Object.values(state.solverOutputTypeMappings.byId)
        .filter(x => x.solverId === solverId);
}


export function selectorGetSolverOutputTypeMappingById(state: RootState, solverOutputTypeMappingId: string) : ISolverOutputTypeMapping | undefined {

    if (!state.solverOutputTypeMappings || !state.solverOutputTypeMappings.byId) return undefined;

    return state.solverOutputTypeMappings.byId[solverOutputTypeMappingId];
}