import React, { lazy, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { selectorGetFailureModesByFunctionalFailureId } from "../../redux/failureMode/selectors";
import { IFailureMode } from "../../utilities/types/FailureMode";
import FailureModeRenderer from "./FailureModeRenderer";

import { IFunctionalFailure } from "../../utilities/types/FunctionalFailure";
import ModelBuilderNewFailureMode from "./ModelBuilderNewFailureMode";
import SuspenseWrapper from "../generic/SuspenseWrapper";

const LazyModalFailureModeUpdate = lazy(() => import("./modals/ModalFailureModeUpdate"));
const LazyModalFailureModeClone = lazy(() => import("./modals/ModalFailureModeClone"));

type IFailureModesRendererProps = {
  canEdit: boolean;
  componentId: string;
  functionalFailure: IFunctionalFailure;
  loading: boolean;
};

const FailureModesRenderer = (props: IFailureModesRendererProps) => {
  const failureModes = useSelector((store: RootState) =>
    selectorGetFailureModesByFunctionalFailureId(store, props.functionalFailure.functionalFailureId)
  );

  return <FailureModesRendererDisplay {...props} failureModes={failureModes} />;
};

interface IFailureModesRendererDisplayProps extends IFailureModesRendererProps {
  failureModes: IFailureMode[];
}

function FailureModesRendererDisplay(props: IFailureModesRendererDisplayProps) {
  const { failureModes, canEdit } = props;
  const [loading] = useState(false);
  const [failureModeToUpdate, setFailureModeToUpdate] = useState<IFailureMode>();
  const [failureModeToClone, setFailureModeToClone] = useState<IFailureMode>();

  async function closeFailureModeUpdateModal() {
    setFailureModeToUpdate(undefined);
  }
  async function closeFailureModeCloneModal() {
    setFailureModeToClone(undefined);
  }

  return (
    <AnimationWrapper>
      <Grid container>
        <Grid item xs={2} sm={1}></Grid>
        <Grid item xs={10} sm={11}>
          <ModelBuilderNewFailureMode
            functionalFailureId={props.functionalFailure.functionalFailureId}
            modelId={props.functionalFailure.modelId}
            componentId={props.componentId}
            functionId={props.functionalFailure.functionId}
          />
          {failureModes.map((componentFailureMode) => {
            return (
              <FailureModeRenderer
                failureModeId={componentFailureMode.failureModeId}
                key={componentFailureMode.failureModeId}
                canEdit={canEdit}
                editFailureMode={() => setFailureModeToUpdate(componentFailureMode)}
                cloneFailureMode={() => setFailureModeToClone(componentFailureMode)}
              />
            );
          })}

          <LoaderAbsoluteCentred loading={loading} />
        </Grid>
        {failureModeToUpdate && (
          <SuspenseWrapper showFullLoader>
            <LazyModalFailureModeUpdate
              onCancelCallback={closeFailureModeUpdateModal}
              onCompleteCallback={closeFailureModeUpdateModal}
              failureMode={failureModeToUpdate}
              open={true}
              canEdit={canEdit}
              modelId={failureModeToUpdate.modelId}
            />
          </SuspenseWrapper>
        )}
        {failureModeToClone && (
          <SuspenseWrapper showFullLoader>
            <LazyModalFailureModeClone
              onCancelCallback={closeFailureModeCloneModal}
              onCompleteCallback={closeFailureModeCloneModal}
              failureMode={failureModeToClone}
              open={true}
              modelId={failureModeToClone.modelId}
            />
          </SuspenseWrapper>
        )}
      </Grid>
    </AnimationWrapper>
  );
}

export default FailureModesRenderer;
