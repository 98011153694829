import { CircularProgress, IconButton, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchCreateFavourite, fetchDeleteFavourite } from "../../redux/favourite/actions";
import { selectorGetFavouriteByEntity } from "../../redux/favourite/selectors";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import FavouriteIconSvg from "@mui/icons-material/Favorite";
import { red, grey } from "@mui/material/colors";
import { join } from "../../utilities/Styles";

const DivWrapper = styled("div")({
  position: "relative",
  "& .favouriteRed": {
    color: red[500],
  },
  "& .buttonProgress": {
    color: grey[500],
    opacity: 0.5,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -16,
    marginLeft: -16,
  },
});

export default function FavouriteButton({ entityType, entityId }: { entityType: EntityTypeEnum; entityId: string }) {
  const favourite = useSelector((store: RootState) => selectorGetFavouriteByEntity(store, entityType, entityId));

  const [loading, setLoading] = React.useState(false);
  useEffect(() => void setLoading(false), [favourite]); // Stop loading on refresh

  const dispatch = useDispatch();
  const toggleFavouriteState = async () => {
    setLoading(true);
    dispatch(favourite ? fetchDeleteFavourite(favourite) : fetchCreateFavourite({ entityId, entityType }));
  };

  return (
    <DivWrapper>
      <IconButton
        aria-label="Add to favorites"
        title="Add to favorites"
        disabled={loading}
        onClick={toggleFavouriteState}
      >
        <FavouriteIcon isFavourite={!!favourite} />
      </IconButton>
      {loading && <CircularProgress size={32} className="buttonProgress" />}
    </DivWrapper>
  );
}

export function FavouriteIcon({ isFavourite, className }: { isFavourite?: boolean; className?: string }) {
  return <FavouriteIconSvg className={join(className, isFavourite ? "favouriteRed" : "")} />;
}
