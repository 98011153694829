import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { IModel } from "../../../utilities/types/Model";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/Build";
import FormModelUpdate from "../forms/FormModelUpdate";

export interface IModalModelUpdateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(file?: IModel): void;
  model: IModel;
}

function ModalModelUpdate({ onCancelCallback, open, onCompleteCallback, model }: IModalModelUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update model"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormModelUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          {...model}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalModelUpdate;
