import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/Build";
import FormModelHistoryUpdate from "../forms/FormModelHIstoryUpdate";
import { IModelHistory } from "../../../utilities/types/ModelHistory";

export interface IModalModelHistoryUpdateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(file?: IModelHistory): void;
  modelHistory: IModelHistory;
  canEdit: boolean;
}

function ModalModelHistoryUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelHistory,
  canEdit,
}: IModalModelHistoryUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={canEdit ? "Update history" : "View History"}
      subtitle={canEdit ? "Fill in the form to complete." : "Additional permission required to update."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormModelHistoryUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          canEdit={canEdit}
          {...modelHistory}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalModelHistoryUpdate;
