import React from "react";
import DialogContent from "@mui/material/DialogContent";
import OutputIcon from "@mui/icons-material/ExitToApp";

import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { IModalAddUpdateOutputNodeProps } from "../types";
import FormAddUpdateOutput from "../forms/FormAddUpdateOutput";
import { useFetchSummaryOutputTypesPageHook } from "../../summaryOutputType/Hooks";

const ModalAddUpdateOutputNode: React.FC<IModalAddUpdateOutputNodeProps> = ({
  outputDetails,
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  useFetchSummaryOutputTypesPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 500,
  });

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={outputDetails.open}
      title={outputDetails.isEditMode ? "Update ouput" : "Add a new ouput"}
      subtitle="Fill in the form to complete."
      headerIcon={<OutputIcon />}
      style={{ maxWidth: 600, minWidth: 550 }}
    >
      <DialogContent>
        <FormAddUpdateOutput
          onCancelCallback={onCancelCallback}
          outputDetails={outputDetails}
          onCompleteCallback={onCompleteCallback}
          onDelete={onDelete}
        />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalAddUpdateOutputNode;
