import React, { useMemo } from "react";
import { Button, styled } from "@mui/material";
import ReactFlow, { Background, Panel, Controls } from "reactflow";
import "reactflow/dist/style.css";
import AddIcon from "@mui/icons-material/Add";
import { IWorkflow } from "../../utilities/types/Workflow";
import ModalAddUpdateBucketNode from "./modals/ModalAddUpdateBucketNode";
import ModalAddUpdateAssetNode from "./modals/ModalAddUpdateAssetNode";
import ModalAddUpdateOutputNode from "./modals/ModalAddUpdateOutputNode";
import ModalAddUpdateSolverNode from "./modals/ModalAddUpdateSolverNode";
import { NODE_TYPES } from "./constants";
import { useReactFlowHook } from "./Hooks";
import { CustomBucketNode, CustomOutputNode } from "./customNodes";

interface ReactflowWidgetProps {
  setRfInstance: React.Dispatch<React.SetStateAction<any>>;
  selectedWorkFlow?: IWorkflow;
}

const ReactflowWrapper = styled("div")({
  width: "100%",
  height: "700px",
  "& .panelWrapper": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "30px 15px 20px",
  },
});

const ReactflowWidget: React.FC<ReactflowWidgetProps> = ({ setRfInstance, selectedWorkFlow }) => {
  const {
    bucketDetails,
    solverDetails,
    assetDetails,
    outputDetails,
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    handleShowAdd,
    handleCancel,
    handleAddUpdateBucket,
    handleAddUpdateSolver,
    handleAddUpdateAsset,
    handleAddUpdateOutput,
    handleDelete,
    handleOnEdit,
    validateConnection,
    onEdgeUpdateStart,
    onEdgeUpdate,
    onEdgeUpdateEnd,
  } = useReactFlowHook({ selectedWorkFlow });

  const nodeTypes = useMemo(
    () => ({
      bucket: (props: any) => <CustomBucketNode {...props} clientBaseBucket={selectedWorkFlow?.clientBaseBucket} />,
      output: CustomOutputNode,
    }),
    [selectedWorkFlow]
  );

  return (
    <ReactflowWrapper>
      <ReactFlow
        className="reactflow-wrapper"
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        attributionPosition="top-right"
        isValidConnection={(connection) => validateConnection(connection)}
        onNodeDoubleClick={(_, nodeData) => {
          handleOnEdit(nodeData);
        }}
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onInit={setRfInstance}
        maxZoom={1.3}
        minZoom={0.4}
        fitView
      >
        <Background color="#000" gap={16} />
        <Controls />
        <Panel position="top-right" className="panelWrapper">
          {Object.entries(NODE_TYPES).map(([type, nodeType]) => (
            <Button
              key={type}
              variant="contained"
              color="primary"
              style={{ textTransform: "capitalize" }}
              size="small"
              startIcon={<AddIcon />}
              onClick={() => handleShowAdd(nodeType)}
            >
              {NODE_TYPES.BUCKET === nodeType ? "PATH" : type}
            </Button>
          ))}
        </Panel>
      </ReactFlow>
      <ModalAddUpdateBucketNode
        bucketDetails={bucketDetails}
        onCancelCallback={() => handleCancel(NODE_TYPES.BUCKET)}
        onCompleteCallback={handleAddUpdateBucket}
        onDelete={handleDelete}
      />
      <ModalAddUpdateAssetNode
        assetDetails={assetDetails}
        onCancelCallback={() => handleCancel(NODE_TYPES.ASSET)}
        onCompleteCallback={handleAddUpdateAsset}
        onDelete={handleDelete}
      />
      <ModalAddUpdateSolverNode
        solverDetails={solverDetails}
        onCancelCallback={() => handleCancel(NODE_TYPES.SOLVER)}
        onCompleteCallback={handleAddUpdateSolver}
        onDelete={handleDelete}
      />
      <ModalAddUpdateOutputNode
        outputDetails={outputDetails}
        onCancelCallback={() => handleCancel(NODE_TYPES.OUTPUT)}
        onCompleteCallback={handleAddUpdateOutput}
        onDelete={handleDelete}
      />
    </ReactflowWrapper>
  );
};

export default ReactflowWidget;
