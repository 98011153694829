export interface IUserPermission {
  userPermissionId: string;
  type: UserPermissionTypeEnum;
  userDetailId: string;
  created: Date;
  createdBy: string;
}

export enum UserPermissionTypeEnum {
  Admin = 10,

  PageAdminDashboard = 100201,
  PageAdminImports = 100401,
  PageAdminDiscussionManagement = 100601,
  PageAdminFeedbackManagement = 100801,
  PageAdminUserManagement = 101001,
  PageAdminSolverManagement = 101201,
  PageAdminOutputManagement = 101401,
  PageAdminUserRoleManagement = 101601,
  PageAdminMetrics = 101801,
  PageSolverJobsDashboard = 102001,
  PageSolverSearch = 102201,
  PageSolverDetails = 102401,
  PageSolverJobCreate = 102601,
  PageSolverJobDetails = 102801,
  PageUserDetails = 103001,
  PageModelSearch = 103201,
  PageModelDetails = 103401,
  PageSingleAssetOutputSearch = 103601,
  PageSingleAssetOutputDetails = 103801,
  PageVideoSearch = 104001,
  PageVideoDetails = 104201,
  PageToolsSearch = 104401,
  PageToolsDetails = 104601,

  ServicesAssetInputFieldCreate = 700201,
  ServicesAssetInputFieldCategoryCreate = 700401,
  ServicesAssetInputFieldListValueCreate = 700601,
  ServicesCloningCreate = 700801,
  ServicesCommentCreate = 701001,
  ServicesComponentCreate = 701201,
  ServicesCostBundleCreate = 701401,
  ServicesCreditAccountCreate = 701601,
  ServicesCreditTransactionCreate = 701801,
  ServicesCreditTransactionDetailCreate = 702001,
  ServicesDecisionCreate = 702201,
  ServicesDecisionAssetInputFieldMappingCreate = 702401,
  ServicesDiscussionCreate = 702601,
  ServicesEntityPermissionCreate = 702801,
  ServicesEventCreate = 703001,
  ServicesFailureModeCreate = 703201,
  ServicesFavouriteCreate = 703401,
  ServicesFeedbackCreate = 703601,
  ServicesFileCreate = 703801,
  ServicesFunctionCreate = 704001,
  ServicesFunctionalFailureCreate = 704201,
  ServicesIdentifierCreate = 704401,
  ServicesIdentifierMappingCreate = 704601,
  ServicesMetricsCreate = 704801,
  ServicesModelCreate = 705001,
  ServicesModelHistoryCreate = 705201,
  ServicesOutputTypeCreate = 705401,
  ServicesPlanCreate = 705601,
  ServicesRoleToPermissionsMappingCreate = 705801,
  ServicesSolverCreate = 706001,
  ServicesSolverDetailsCreate = 706201,
  ServicesSolverHistoryCreate = 706401,
  ServicesSolverInputFieldCreate = 706601,
  ServicesSolverInputFieldListValueCreate = 706801,
  ServicesSolverJobCreate = 707001,
  ServicesSummaryOutputJobCreate = 707201,
  ServicesSolverJobTaskCreate = 707401,
  ServicesSingleAssetOutputJobCreate = 707601,
  ServicesSolverOutputTypeMappingCreate = 707801,
  ServicesSolverSummaryOutputTypeMappingCreate = 708001,
  ServicesSummaryOutputTypeCreate = 708201,
  ServicesSummaryOutputTypeInputFieldCreate = 708401,
  ServicesSummaryOutputTypeInputFieldListValueCreate = 708601,
  ServicesTaskCreate = 708801,
  ServicesTaskApplicabilityCreate = 709001,
  ServicesTaskImpactCreate = 709201,
  ServicesTermsAgreementCreate = 709401,
  ServicesUserContributionsCreate = 709601,
  ServicesUserDetailCreate = 709801,
  ServicesUserPermissionCreate = 710001,
  ServicesUserRolesCreate = 710201,
  ServicesUserToRolesMappingCreate = 710401,
  ServicesUserTourCreate = 710601,
  ServicesVideoCreate = 710801,
  ServicesAssetInputFieldRead = 700202,
  ServicesAssetInputFieldCategoryRead = 700402,
  ServicesAssetInputFieldListValueRead = 700602,
  ServicesCloningRead = 700802,
  ServicesCommentRead = 701002,
  ServicesComponentRead = 701202,
  ServicesCostBundleRead = 701402,
  ServicesCreditAccountRead = 701602,
  ServicesCreditTransactionRead = 701802,
  ServicesCreditTransactionDetailRead = 702002,
  ServicesDecisionRead = 702202,
  ServicesDecisionAssetInputFieldMappingRead = 702402,
  ServicesDiscussionRead = 702602,
  ServicesEntityPermissionRead = 702802,
  ServicesEventRead = 703002,
  ServicesFailureModeRead = 703202,
  ServicesFavouriteRead = 703402,
  ServicesFeedbackRead = 703602,
  ServicesFileRead = 703802,
  ServicesFunctionRead = 704002,
  ServicesFunctionalFailureRead = 704202,
  ServicesIdentifierRead = 704402,
  ServicesIdentifierMappingRead = 704602,
  ServicesMetricsRead = 704802,
  ServicesModelRead = 705002,
  ServicesModelHistoryRead = 705202,
  ServicesOutputTypeRead = 705402,
  ServicesPlanRead = 705602,
  ServicesRoleToPermissionsMappingRead = 705802,
  ServicesSolverRead = 706002,
  ServicesSolverDetailsRead = 706202,
  ServicesSolverHistoryRead = 706402,
  ServicesSolverInputFieldRead = 706602,
  ServicesSolverInputFieldListValueRead = 706802,
  ServicesSolverJobRead = 707002,
  ServicesSummaryOutputJobRead = 707202,
  ServicesSolverJobTaskRead = 707402,
  ServicesSingleAssetOutputJobRead = 707602,
  ServicesSolverOutputTypeMappingRead = 707802,
  ServicesSolverSummaryOutputTypeMappingRead = 708002,
  ServicesSummaryOutputTypeRead = 708202,
  ServicesSummaryOutputTypeInputFieldRead = 708402,
  ServicesSummaryOutputTypeInputFieldListValueRead = 708602,
  ServicesTaskRead = 708802,
  ServicesTaskApplicabilityRead = 709002,
  ServicesTaskImpactRead = 709202,
  ServicesTermsAgreementRead = 709402,
  ServicesUserContributionsRead = 709602,
  ServicesUserDetailRead = 709802,
  ServicesUserPermissionRead = 710002,
  ServicesUserRolesRead = 710202,
  ServicesUserToRolesMappingRead = 710402,
  ServicesUserTourRead = 710602,
  ServicesVideoRead = 710802,
  ServicesAssetInputFieldUpdate = 700203,
  ServicesAssetInputFieldCategoryUpdate = 700403,
  ServicesAssetInputFieldListValueUpdate = 700603,
  ServicesCloningUpdate = 700803,
  ServicesCommentUpdate = 701003,
  ServicesComponentUpdate = 701203,
  ServicesCostBundleUpdate = 701403,
  ServicesCreditAccountUpdate = 701603,
  ServicesCreditTransactionUpdate = 701803,
  ServicesCreditTransactionDetailUpdate = 702003,
  ServicesDecisionUpdate = 702203,
  ServicesDecisionAssetInputFieldMappingUpdate = 702403,
  ServicesDiscussionUpdate = 702603,
  ServicesEntityPermissionUpdate = 702803,
  ServicesEventUpdate = 703003,
  ServicesFailureModeUpdate = 703203,
  ServicesFavouriteUpdate = 703403,
  ServicesFeedbackUpdate = 703603,
  ServicesFileUpdate = 703803,
  ServicesFunctionUpdate = 704003,
  ServicesFunctionalFailureUpdate = 704203,
  ServicesIdentifierUpdate = 704403,
  ServicesIdentifierMappingUpdate = 704603,
  ServicesMetricsUpdate = 704803,
  ServicesModelUpdate = 705003,
  ServicesModelHistoryUpdate = 705203,
  ServicesOutputTypeUpdate = 705403,
  ServicesPlanUpdate = 705603,
  ServicesRoleToPermissionsMappingUpdate = 705803,
  ServicesSolverUpdate = 706003,
  ServicesSolverDetailsUpdate = 706203,
  ServicesSolverHistoryUpdate = 706403,
  ServicesSolverInputFieldUpdate = 706603,
  ServicesSolverInputFieldListValueUpdate = 706803,
  ServicesSolverJobUpdate = 707003,
  ServicesSummaryOutputJobUpdate = 707203,
  ServicesSolverJobTaskUpdate = 707403,
  ServicesSingleAssetOutputJobUpdate = 707603,
  ServicesSolverOutputTypeMappingUpdate = 707803,
  ServicesSolverSummaryOutputTypeMappingUpdate = 708003,
  ServicesSummaryOutputTypeUpdate = 708203,
  ServicesSummaryOutputTypeInputFieldUpdate = 708403,
  ServicesSummaryOutputTypeInputFieldListValueUpdate = 708603,
  ServicesTaskUpdate = 708803,
  ServicesTaskApplicabilityUpdate = 709003,
  ServicesTaskImpactUpdate = 709203,
  ServicesTermsAgreementUpdate = 709403,
  ServicesUserContributionsUpdate = 709603,
  ServicesUserDetailUpdate = 709803,
  ServicesUserPermissionUpdate = 710003,
  ServicesUserRolesUpdate = 710203,
  ServicesUserToRolesMappingUpdate = 710403,
  ServicesUserTourUpdate = 710603,
  ServicesVideoUpdate = 710803,
  ServicesAssetInputFieldDelete = 700204,
  ServicesAssetInputFieldCategoryDelete = 700404,
  ServicesAssetInputFieldListValueDelete = 700604,
  ServicesCloningDelete = 700804,
  ServicesCommentDelete = 701004,
  ServicesComponentDelete = 701204,
  ServicesCostBundleDelete = 701404,
  ServicesCreditAccountDelete = 701604,
  ServicesCreditTransactionDelete = 701804,
  ServicesCreditTransactionDetailDelete = 702004,
  ServicesDecisionDelete = 702204,
  ServicesDecisionAssetInputFieldMappingDelete = 702404,
  ServicesDiscussionDelete = 702604,
  ServicesEntityPermissionDelete = 702804,
  ServicesEventDelete = 703004,
  ServicesFailureModeDelete = 703204,
  ServicesFavouriteDelete = 703404,
  ServicesFeedbackDelete = 703604,
  ServicesFileDelete = 703804,
  ServicesFunctionDelete = 704004,
  ServicesFunctionalFailureDelete = 704204,
  ServicesIdentifierDelete = 704404,
  ServicesIdentifierMappingDelete = 704604,
  ServicesMetricsDelete = 704804,
  ServicesModelDelete = 705004,
  ServicesModelHistoryDelete = 705204,
  ServicesOutputTypeDelete = 705404,
  ServicesPlanDelete = 705604,
  ServicesRoleToPermissionsMappingDelete = 705804,
  ServicesSolverDelete = 706004,
  ServicesSolverDetailsDelete = 706204,
  ServicesSolverHistoryDelete = 706404,
  ServicesSolverInputFieldDelete = 706604,
  ServicesSolverInputFieldListValueDelete = 706804,
  ServicesSolverJobDelete = 707004,
  ServicesSummaryOutputJobDelete = 707204,
  ServicesSolverJobTaskDelete = 707404,
  ServicesSingleAssetOutputJobDelete = 707604,
  ServicesSolverOutputTypeMappingDelete = 707804,
  ServicesSolverSummaryOutputTypeMappingDelete = 708004,
  ServicesSummaryOutputTypeDelete = 708204,
  ServicesSummaryOutputTypeInputFieldDelete = 708404,
  ServicesSummaryOutputTypeInputFieldListValueDelete = 708604,
  ServicesTaskDelete = 708804,
  ServicesTaskApplicabilityDelete = 709004,
  ServicesTaskImpactDelete = 709204,
  ServicesTermsAgreementDelete = 709404,
  ServicesUserContributionsDelete = 709604,
  ServicesUserDetailDelete = 709804,
  ServicesUserPermissionDelete = 710004,
  ServicesUserRolesDelete = 710204,
  ServicesUserToRolesMappingDelete = 710404,
  ServicesUserTourDelete = 710604,
  ServicesVideoDelete = 710804,
  ServicesBucketCreate = 711001,
  ServicesBucketRead = 711002,
  ServicesBucketUpdate = 711003,
  ServicesBucketDelete = 711004,
  ServicesWorkflowCreate = 711201,
  ServicesWorkflowRead = 711202,
  ServicesWorkflowUpdate = 711203,
  ServicesWorkflowDelete = 711204,
  ServicesWorkflowRun = 711205,
  ServicesAISuggest = 711402,
  ServicesModelExport = 705005,
  ServicesAssetJobCreate = 711601,
  ServicesAssetJobRead = 711602,
  ServicesAssetJobUpdate = 711603,
  ServicesAssetJobDelete = 711604,
  ServicesAssetJobTaskCreate = 711801,
  ServicesAssetJobTaskRead = 711802,
  ServicesAssetJobTaskUpdate = 711803,
  ServicesAssetJobTaskDelete = 711804,
  ActionsApplyDecisions = 800101,
  ActionsAdminImport = 800201,
}
