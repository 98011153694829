import { ACTIONS_ASSET_INPUT_FIELD_CATEGORY, AssetInputFieldCategorySearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IAssetInputFieldCategory } from '../../utilities/types/AssetInputFieldCategory';


export const receiveAssetInputFieldCategories = (assetInputFieldCategories: IAssetInputFieldCategory[]) => {

    var byIdObjectToDispatch: { [key: string]: IAssetInputFieldCategory } = {};

    for (var i = 0; i < assetInputFieldCategories.length; i++) {
        byIdObjectToDispatch[assetInputFieldCategories[i].assetInputFieldCategoryId] = assetInputFieldCategories[i];
    }

    return {
        type: ACTIONS_ASSET_INPUT_FIELD_CATEGORY.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteAssetInputFieldCategory = (assetInputFieldCategory: IAssetInputFieldCategory) => ({
    type: ACTIONS_ASSET_INPUT_FIELD_CATEGORY.DELETE,
    byId: { [assetInputFieldCategory.assetInputFieldCategoryId]: assetInputFieldCategory }
});


export interface IFetchCreateAssetInputFieldCategoryProps {
    name: string,
    description: string,
    modelId: string,
    orderNumber: number
}


export const fetchCreateAssetInputFieldCategory = (assetInputFieldCategoryToCreate: IFetchCreateAssetInputFieldCategoryProps): AppThunk<Promise<IAssetInputFieldCategory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {
        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/assetInputFieldCategories`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(assetInputFieldCategoryToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldCategory) {
            dispatch(receiveAssetInputFieldCategories([parsedResp.data.assetInputFieldCategory]));
            return parsedResp.data.assetInputFieldCategory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while creating field category.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating field category.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateAssetInputFieldCategoryProps {
    assetInputFieldCategoryId: string,
    name: string,
    description?: string,
    orderNumber: number,
}


export const fetchUpdateAssetInputFieldCategory = (props: IFetchUpdateAssetInputFieldCategoryProps): AppThunk<Promise<IAssetInputFieldCategory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/assetInputFieldCategories`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldCategory) {
            dispatch(receiveAssetInputFieldCategories([parsedResp.data.assetInputFieldCategory]));
            return parsedResp.data.assetInputFieldCategory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating field category.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating field category.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchAssetInputFieldCategoriesProps {
    pageNumber: number,
    pageSize: number,
    text?: string,
    assetInputFieldCategoryId?: string,
    modelId: string,
    createdBy?: string,
    orderType?: AssetInputFieldCategorySearchOrderTypeEnum,
}


export const fetchSearchAssetInputFieldCategories = (searchParams: IFetchSearchAssetInputFieldCategoriesProps): AppThunk<Promise<IAssetInputFieldCategory[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/assetInputFieldCategories`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldCategories) {
            dispatch(receiveAssetInputFieldCategories(parsedResp.data.assetInputFieldCategories));
            return parsedResp.data.assetInputFieldCategories;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching field category.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching field category.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteAssetInputFieldCategoryProps {
    assetInputFieldCategoryId: string,
}


export const fetchDeleteAssetInputFieldCategory = (props: IFetchDeleteAssetInputFieldCategoryProps): AppThunk<Promise<IAssetInputFieldCategory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/assetInputFieldCategories`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFieldCategory) {
            dispatch(requestDeleteAssetInputFieldCategory(parsedResp.data.assetInputFieldCategory));
            return parsedResp.data.assetInputFieldCategory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting field category.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting field category.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}