import { ACTIONS_TASK_IMPACT, TaskImpactSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ITaskImpact } from '../../utilities/types/TaskImpact';


export const receiveTaskImpacts = (taskImpacts: ITaskImpact[]) => {

    var byIdObjectToDispatch: { [key: string]: ITaskImpact } = {};

    for (var i = 0; i < taskImpacts.length; i++) {
        byIdObjectToDispatch[taskImpacts[i].taskImpactId] = taskImpacts[i];
    }

    return {
        type: ACTIONS_TASK_IMPACT.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteTaskImpact = (taskImpact: ITaskImpact) => ({
    type: ACTIONS_TASK_IMPACT.DELETE,
    byId: { [taskImpact.taskImpactId]: taskImpact }
});


export interface IFetchCreateTaskImpactProps {
    taskId: string,
    failureModeIds: string[],
    stepDescription: string,
    ageReductionFactor: number,
    orderNumber: number,
}


export const fetchCreateTaskImpact = (taskImpactToCreate: IFetchCreateTaskImpactProps): AppThunk<Promise<ITaskImpact[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/taskImpacts`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(taskImpactToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskImpacts) {
            dispatch(receiveTaskImpacts(parsedResp.data.taskImpacts));
            return parsedResp.data.taskImpacts;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating task impact.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating task impact.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateTaskImpactProps {
    taskImpactId: string,
    failureModeId: string,
    ageReductionFactor: number,
    stepDescription: string,
    orderNumber: number,
}


export const fetchUpdateTaskImpact = (props: IFetchUpdateTaskImpactProps): AppThunk<Promise<ITaskImpact>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/taskImpacts`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskImpacts) {
            dispatch(receiveTaskImpacts(parsedResp.data.taskImpacts));
            return parsedResp.data.taskImpacts;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating task impact.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating task impact.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchTaskImpactsProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    taskId?: string,
    taskImpactId?: string,
    text?: string,
    createdBy?: string,
    orderType?: TaskImpactSearchOrderTypeEnum,
}


export const fetchSearchTaskImpacts = (searchParams: IFetchSearchTaskImpactsProps): AppThunk<Promise<ITaskImpact[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/taskImpacts`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskImpacts) {
            dispatch(receiveTaskImpacts(parsedResp.data.taskImpacts));
            return parsedResp.data.taskImpacts;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching task impact.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching task impact.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteTaskImpactProps {
    taskImpactId: string,
}


export const fetchDeleteTaskImpact = (props: IFetchDeleteTaskImpactProps): AppThunk<Promise<ITaskImpact>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/taskImpacts`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.taskImpacts && parsedResp.data.taskImpacts.length) {
            dispatch(requestDeleteTaskImpact(parsedResp.data.taskImpacts[0]));
            return parsedResp.data.taskImpacts[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting task impact.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting task impact.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}