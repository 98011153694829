import { useMemo } from "react";
import { useSelector } from "react-redux";
import { LinkModeTypeEnum } from "./types";
import { RootState } from "../../../redux";
import { selectorGetComponentsByModelId } from "../../../redux/component/selectors";
import { selectorGetFunctionsByComponentId } from "../../../redux/function/selectors";
import { selectorGetFunctionalFailuresByFunctionId } from "../../../redux/functionalFailure/selectors";
import { selectorGetFailureModesByFunctionalFailureId } from "../../../redux/failureMode/selectors";

export interface ComponentAndFunctionListType {
  id: string;
  name: string;
  type: LinkModeTypeEnum;
  parentId?: string;
  tableData: { checked: boolean };
  showCheckbox: boolean;
  level: number;
}

type HookReturnType = {
  dataList: ComponentAndFunctionListType[];
};

// Hook to fetch and transform the data
export const useComponentAndFunctionList = (
  modelId: string,
  showSelection: boolean,
  specificType?: LinkModeTypeEnum
): HookReturnType => {
  const store = useSelector((store: RootState) => store);

  const components = useMemo(() => selectorGetComponentsByModelId(store, modelId), [store, modelId]);

  const dataList = useMemo(() => {
    let data: ComponentAndFunctionListType[] = [];
    if (components.length < 1) return [];

    components.forEach((component) => {
      data.push({
        id: component.componentId,
        name: component.name,
        type: LinkModeTypeEnum.Component,
        tableData: { checked: false },
        showCheckbox: showSelection || specificType === LinkModeTypeEnum.Component,
        level: 1,
      });

      const functions = selectorGetFunctionsByComponentId(store, component.componentId);
      functions.forEach((func) => {
        data.push({
          id: func.functionId,
          name: func.name,
          parentId: component.componentId,
          type: LinkModeTypeEnum.Function,
          tableData: { checked: false },
          showCheckbox: showSelection || specificType === LinkModeTypeEnum.Function,
          level: 2,
        });

        const functionalFailures = selectorGetFunctionalFailuresByFunctionId(store, func.functionId);
        functionalFailures.forEach((funcFailure) => {
          data.push({
            id: funcFailure.functionalFailureId,
            name: funcFailure.name,
            parentId: func.functionId,
            type: LinkModeTypeEnum.FunctionalFailure,
            tableData: { checked: false },
            showCheckbox: showSelection || specificType === LinkModeTypeEnum.FunctionalFailure,
            level: 3,
          });

          const failureModes = selectorGetFailureModesByFunctionalFailureId(store, funcFailure.functionalFailureId);
          failureModes.forEach((failureMode) => {
            data.push({
              id: failureMode.failureModeId,
              name: failureMode.name,
              parentId: funcFailure.functionalFailureId,
              type: LinkModeTypeEnum.FailureMode,
              tableData: { checked: false },
              showCheckbox: showSelection || specificType === LinkModeTypeEnum.FailureMode,
              level: 4,
            });
          });
        });
      });
    });

    return data;
  }, [components, showSelection, specificType]);

  return {
    dataList,
  };
};
