import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useParams
} from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import PageTaskDetails from '../components/task/pages/PageTaskDetails';
import { useFetchTasksPageHook } from '../components/task/Hooks';
import LoaderAbsoluteCentred from '../components/generic/loaders/LoaderAbsoluteCentred';

interface RouteParams {
    modelId: string; // You can adjust the type accordingly
    taskId: string; // You can adjust the type accordingly
}

const RouteTask = () => {

    let match = useRouteMatch();
    let { modelId, taskId } = useParams<RouteParams>();    

    const { fetching: fetchingTasks } = useFetchTasksPageHook({
        pageNumber: 1,
        pageSize: 200,
        modelId: modelId || '',
        taskId,
        minPageNumberToFetch: 1,
    });

    if(fetchingTasks) return <LoaderAbsoluteCentred loading={true} />

    return (
        <Switch>
            <Route path={`${match.path}`} render={props => <LayoutDefault routeProps={props}><PageTaskDetails {...props} /></LayoutDefault>} />            
        </Switch>
    )
}


export default RouteTask;