import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { ISolverSummaryOutputTypeMapping } from "../../utilities/types/SolverSummaryOutputTypeMapping";
import { ISummaryOutputType } from "../../utilities/types/SummaryOutputType";
import { ListItemAvatar, Avatar, Typography, styled, ListItemButton } from "@mui/material";
import { FileTypeEnum } from "../../utilities/types/File";
import { useFileSourceSingleHook } from "../file/Hooks";

const GridWrapper = styled(Grid)(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  width: "100%",

  "& .list": {
    flex: 1,
    maxWidth: 400,
    minWidth: 250,
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
  },
  "& .listLeft": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listRight": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .paper": {
    height: 230,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
  },
  "& .button": {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: ISummaryOutputType[], b: ISummaryOutputType[]) {
  return a.filter((value) => !b.some((c) => c.summaryOutputTypeId === value.summaryOutputTypeId));
}

function getSummaryOutputTypesByMappings(
  summaryOutputTypes: ISummaryOutputType[],
  mappings: ISolverSummaryOutputTypeMapping[]
) {
  return summaryOutputTypes.filter((x) => mappings.some((y) => y.summaryOutputTypeId === x.summaryOutputTypeId));
}

interface ISolverSummaryOutputTypePickListProps {
  solverId: string;
  onSelectedItemsChange(mappings: ISummaryOutputType[]): void;
  summaryOutputTypes: ISummaryOutputType[];
  summaryOutputTypeMappings: ISolverSummaryOutputTypeMapping[];
  leftLabel?: string;
  rightLabel?: string;
}

export default function SolverSummaryOutputTypePickList({
  summaryOutputTypes,
  summaryOutputTypeMappings,
  onSelectedItemsChange,
  leftLabel,
  rightLabel,
}: ISolverSummaryOutputTypePickListProps) {
  const [right, setRight] = useState<ISummaryOutputType[]>(
    getSummaryOutputTypesByMappings(summaryOutputTypes, summaryOutputTypeMappings)
  );
  const [left, setLeft] = useState<ISummaryOutputType[]>(not(summaryOutputTypes, right));
  const [assignedMappingsCount, setAssignedMappingsCount] = useState<number>(summaryOutputTypeMappings.length);
  const [summaryOutputTypesCount, setSummaryOutputTypesCount] = useState<number>(summaryOutputTypes.length);

  function selectItem(clickedItem: ISummaryOutputType, rightItems: boolean) {
    if (rightItems) {
      let mergedItems = [...left, clickedItem];
      setLeft(mergedItems);
      setRight(not(summaryOutputTypes, mergedItems));
    } else {
      let mergedItems = [...right, clickedItem];
      setRight(mergedItems);
      setLeft(not(summaryOutputTypes, mergedItems));
    }
  }

  // Allow parent component to hook into current items
  useEffect(() => {
    onSelectedItemsChange(right);
  }, [right, onSelectedItemsChange]);

  // Ensure that mappings not initially available are still catered for
  useEffect(() => {
    if (
      summaryOutputTypeMappings.length !== assignedMappingsCount ||
      summaryOutputTypes.length !== summaryOutputTypesCount
    ) {
      setAssignedMappingsCount(summaryOutputTypeMappings.length);
      setSummaryOutputTypesCount(summaryOutputTypes.length);

      var rightSummaryOutputTypes = getSummaryOutputTypesByMappings(summaryOutputTypes, summaryOutputTypeMappings);
      setRight(rightSummaryOutputTypes);
      setLeft(not(summaryOutputTypes, rightSummaryOutputTypes));
    }
  }, [summaryOutputTypeMappings, assignedMappingsCount, summaryOutputTypes, summaryOutputTypesCount]);

  const customList = (items: ISummaryOutputType[], right: boolean) => (
    <Paper className={`paper ${right ? "listRight" : "listLeft"}`}>
      <List dense component="div" role="list" style={{ paddingTop: 0 }}>
        {items.map((value) => (
          <CustomListItem
            key={value.summaryOutputTypeId}
            summaryOutputType={value}
            onClick={() => selectItem(value, right)}
          />
        ))}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <GridWrapper container spacing={2} justifyContent="center" alignItems="center" className="listContainer">
      <Grid item className="list">
        <Typography variant="overline">{leftLabel || "Unlinked"}</Typography>
        {customList(left, false)}
      </Grid>
      <Grid item className="list">
        <Typography variant="overline">{rightLabel || "Linked"}</Typography>
        {customList(right, true)}
      </Grid>
    </GridWrapper>
  );
}

function CustomListItem({ summaryOutputType, onClick }: { summaryOutputType: ISummaryOutputType; onClick(): void }) {
  const labelId = `transfer-list-item-${summaryOutputType.summaryOutputTypeId}-label`;
  const imageUrl = useFileSourceSingleHook({
    fileId: summaryOutputType && summaryOutputType.mainImageId ? summaryOutputType.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  return (
    <ListItemButton key={summaryOutputType.summaryOutputTypeId} role="listitem" onClick={onClick} className="listItem">
      <ListItemAvatar>
        <Avatar alt={summaryOutputType.name} src={imageUrl} />
      </ListItemAvatar>
      <ListItemText id={labelId} primary={summaryOutputType.name} />
    </ListItemButton>
  );
}
