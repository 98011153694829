import { DecimalPlaces } from "../inputFields/numberFieldUtils";

export interface IAssetInputField {
    assetInputFieldId: string,
    modelId: string,
    assetInputFieldCategoryId: string,
    label: string,
    description: string,
    defaultValue: number,
    defaultString: string,
    defaultDateTime: Date,
    defaultAssetInputFieldListValueId?: string,
    minValue: number,
    maxValue: number,
    decimalPlaces: DecimalPlaces,
    orderNumber: number,
    type: AssetInputFieldTypeEnum,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum AssetInputFieldTypeEnum {
    Number = 10,
    CustomEnumeration = 20,
    String = 30,
    DateTime = 40,
}

export type TAssetInputFieldValue = {
    value?: number,
    valueString?: string,
    valueDateTime?: Date,
    assetInputFieldValueId?: string,
}