import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/SettingsTwoTone";
import FormUserRoleCreate from "../forms/FormUserRoleCreate";
import { IUserRole } from "../../../utilities/types/UserRole";

export interface IModalUserRoleCreateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(role?: IUserRole): void;
}

function ModalUserRoleCreate({ onCancelCallback, open, onCompleteCallback }: IModalUserRoleCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new Role"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormUserRoleCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalUserRoleCreate;
