import React from "react";
import { Typography, Modal, styled, IconButton } from "@mui/material";
import HeaderColor from "@mui/material/colors/blueGrey";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AnimationWrapper from "../../animations/AnimationWrapper";
import CloseIcon from "@mui/icons-material/Close";

const ModalContainer = styled("div")(({ theme }) => ({
  maxHeight: "90vh",
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid #CCC",
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  outline: "none",
  "& .modalContent": {
    overflowY: "auto",
  },
  "& .dialogHeaderContainer": {
    paddingLeft: theme.spacing(14.5),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
  },
  "& .iconContainer": {
    position: "absolute",
    left: theme.spacing(2),
    top: `-${theme.spacing(2)}`,
    height: 75,
    width: 75,
    backgroundColor: HeaderColor[600],
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    "& svg": {
      color: "#FFF",
      fontSize: 30,
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    outline: "none",
  },
}));

export interface WidgetModalBaseProps {
  children: React.ReactNode;
  open: boolean;
  title: string;
  subtitle: string;
  handleCancel(): void;
  headerIcon?: React.ReactNode;
  headerColor?: string;
  style?: React.CSSProperties;
  disableBackdropClick?: boolean;
  showCloseBtn?: boolean;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
  };
}

function WidgetModalBase({
  title,
  children,
  subtitle,
  headerColor = HeaderColor[800],
  headerIcon = <AttachFileIcon />,
  open,
  handleCancel,
  style,
  disableBackdropClick = true,
  showCloseBtn = false,
}: WidgetModalBaseProps) {
  const [modalStyle] = React.useState(getModalStyle);

  const maxWidthNum = style?.maxWidth ? style.maxWidth.toString().split("px").join("") : null;
  const maxWidth = `calc(min(100%, ${maxWidthNum || 400}px))`;

  const handleClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }

    handleCancel();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      style={{ overflow: "inherit", outline: "none" }}
      tabIndex={-1}
    >
      <AnimationWrapper>
        <ModalContainer style={{ ...modalStyle, ...style, maxWidth }} tabIndex={-1}>
          <div className="iconContainer" style={{ backgroundColor: headerColor }}>
            {headerIcon}
          </div>
          <div className="dialogHeaderContainer">
            <div>
              <Typography variant="body1" noWrap={true}>
                {title}
              </Typography>
              <Typography variant="caption" component={"span"}>
                {subtitle}
              </Typography>
            </div>
            {showCloseBtn && (
              <IconButton size="small" onClick={() => handleCancel()}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
          <div className="modalContent">{children}</div>
        </ModalContainer>
      </AnimationWrapper>
    </Modal>
  );
}

export default WidgetModalBase;
