import { IFeedback } from "../../utilities/types/Feedback";


export const ACTIONS_FEEDBACK = {
    INVALIDATE: 'INVALIDATE_FEEDBACKS',
    RECEIVE: 'RECEIVE_FEEDBACKS',
    DELETE: 'DELETE_FEEDBACK',
    UPDATE: 'UPDATE_FEEDBACKS',
}

interface ActionFeedbacksInvalidate {
    type: typeof ACTIONS_FEEDBACK.INVALIDATE,
    byId: { [key: string]: IFeedback },
}

interface ActionFeedbacksReceive {
    type: typeof ACTIONS_FEEDBACK.RECEIVE
    byId: { [key: string]: IFeedback },
}

export interface FeedbackState {
    byId: { [key: string]: IFeedback },
}

export enum FeedbackSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesFeedback = ActionFeedbacksReceive | ActionFeedbacksInvalidate;