import { ISingleAssetOutput } from "../../utilities/types/SingleAssetOutput";
import { Typography, styled } from "@mui/material";

import React from "react";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import PlaceholderIcon from "@mui/icons-material/ImportExportTwoTone";

import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";

const DivWrapper = styled("div")(({ theme }) => ({
  minHeight: 200,
  "& .outputTypeJobImageWrapper": {
    height: 150,
    width: "100%",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  "& .outputTypeJobImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
}));

interface IOutputTypeDisplayWidgetProps {
  loading: boolean;
  singleAssetOutput?: ISingleAssetOutput;
}

const SingleAssetOutputDisplayWidget = ({ loading, singleAssetOutput }: IOutputTypeDisplayWidgetProps) => {
  const imageUrl = useFileSourceSingleHook({
    fileId: singleAssetOutput && singleAssetOutput.mainImageId ? singleAssetOutput.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  return (
    <DivWrapper>
      <div className="outputTypeJobImageWrapper">
        <div className="outputTypeJobImage" style={{ backgroundImage: `url("${imageUrl}")` }} />
      </div>
      <div style={{ marginTop: 16 }}>
        {!singleAssetOutput && !loading ? (
          <WidgetNoResultsPlaceholder text="Single Asset Output not found." icon={PlaceholderIcon} flip={true} />
        ) : (
          <Typography variant="caption">{singleAssetOutput?.description}</Typography>
        )}
      </div>

      <LoaderAbsoluteCentred loading={loading} />
    </DivWrapper>
  );
};

export default SingleAssetOutputDisplayWidget;
