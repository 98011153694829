import React, { useState } from "react";
import { IconButton, Menu, styled } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

const MenuItemsDiv = styled("div")({
  textAlign: "right",
});

interface MenuDropdownProps {
  menuItems?: React.ReactNode;
  className?: string;
}

export const MenuDropdown: React.FC<MenuDropdownProps> = ({ menuItems, className }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const closeMenu = () => setMenuAnchorEl(undefined);
  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  if (!menuItems) return null;

  return (
    <MenuItemsDiv className={className}>
      <IconButton aria-label="settings" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        style={{ padding: 0 }}
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        disableScrollLock={true}
        onClose={closeMenu}
      >
        {menuItems}
      </Menu>
    </MenuItemsDiv>
  );
};
