import React from "react";
import { Grid, Divider, Button, FormControlLabel, Switch } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IFunctionalFailure } from "../../../utilities/types/FunctionalFailure";
import { fetchCreateFunctionalFailure } from "../../../redux/functionalFailure/actions";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  name: string[];
  description: string;
  orderNumber: number;
  functionId: string;
  inheritIdentifiers: boolean;
}

interface FormProps {
  functionId?: string;
  modelId: string;
  onCompleteCallback(functionalFailure?: IFunctionalFailure): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IFunctionalFailure, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StringListInput
            label="Functional Failure Name(s)"
            placeholder="E.g. Leaks"
            onStringListChange={handleNameChange}
            stringList={values.name}
            errors={props.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={props.values.inheritIdentifiers}
                onChange={() => {
                  props.setFieldValue("inheritIdentifiers", !props.values.inheritIdentifiers);
                }}
                name="InheritIdentifiers"
              />
            }
            label="Inherit Identifiers"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormFunctionalFailureCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => ({
    name: [],
    description: "",
    orderNumber: 0,
    functionId: props.functionId || "",
    inheritIdentifiers: true,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Label")
        .min(3, "Please input 3 characters or more")
        .max(200, "Please input 200 characters or less")
        .required("Please provide a name")
    ),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const functionalFailuresToCreate = values.name.map((name) => ({
      modelId, // Assuming modelId is obtained from some variable
      name,
      description: values.description,
      orderNumber: values.orderNumber,
      inheritIdentifiers: values.inheritIdentifiers,
      functionId: values.functionId,
    }));

    // Map dispatch via props
    var createdFunctionalFailure = await dispatch(
      fetchCreateFunctionalFailure({
        functionalFailures: functionalFailuresToCreate,
      })
    );

    setSubmitting(false);

    if (createdFunctionalFailure) onCompleteCallback(createdFunctionalFailure);
  },
})(InnerForm);

export default FormFunctionalFailureCreate;
