import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import InputIcon from "@mui/icons-material/LabelTwoTone";
import FormIdentifierUpdate from "../forms/FormIdentifierUpdate";
import { IIdentifier } from "../../../utilities/types/Identifier";
import FileRendererSingle from "../../file/renderers/FileRendererSingle";
import { FileTypeEnum } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { fetchSearchIdentifiers } from "../../../redux/identifier/actions";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetIdentifierById } from "../../../redux/identifier/selectors";
import { RootState } from "../../../redux";

export interface IModalIdentifierUpdateProps {
  open: boolean;
  identifier: IIdentifier;
  onCancelCallback(): void;
  onCompleteCallback(identifier?: IIdentifier): void;
  canEdit: boolean;
}

function ModalIdentifierUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  identifier,
  canEdit,
}: IModalIdentifierUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update identifier"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <RenderImage identifierId={identifier.identifierId} canEdit={canEdit} />
        <FormIdentifierUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          identifier={identifier}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

interface IRenderImageProps {
  identifierId: string;
  canEdit: boolean;
}

function RenderImage({ identifierId, canEdit }: IRenderImageProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const identifier = useSelector((store: RootState) => selectorGetIdentifierById(store, identifierId));

  if (!identifier) return null;

  const onUploadComplete = async () => {
    setLoading(true);
    await dispatch(
      fetchSearchIdentifiers({
        identifierId: identifier.identifierId,
        modelId: identifier.modelId,
        pageNumber: 1,
        pageSize: 1,
      })
    );
    setLoading(false);
  };

  return (
    <div style={{ borderRadius: 4, position: "relative" }}>
      <FileRendererSingle
        fileId={identifier.mainImageId || ""}
        canUpload={canEdit}
        fileType={FileTypeEnum.Image}
        entityId={String(identifier.identifierId)}
        entityType={EntityTypeEnum.Identifier}
        height={150}
        onUploadComplete={onUploadComplete}
        imageUploadHeight={150}
        imageUploadWidth={150}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </div>
  );
}

export default ModalIdentifierUpdate;
