import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import ListIcon from "@mui/icons-material/ListAlt";
import { ISummaryOutputTypeInputFieldListValue } from "../../../utilities/types/SummaryOutputTypeInputFieldListValue";
import FormSummaryOutputTypeInputFieldListValueCreate from "../forms/FormSummaryOutputTypeInputFieldListValueCreate";

export interface IModalSummaryOutputTypeInputFieldListValueCreateProps {
  open: boolean;
  summaryOutputTypeInputFieldId: string;
  onCancelCallback(): void;
  onCompleteCallback(file?: ISummaryOutputTypeInputFieldListValue): void;
}

function ModalSummaryOutputTypeInputFieldListValueCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  summaryOutputTypeInputFieldId,
}: IModalSummaryOutputTypeInputFieldListValueCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Add a new list value"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<ListIcon />}
    >
      <DialogContent>
        <FormSummaryOutputTypeInputFieldListValueCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          summaryOutputTypeInputFieldId={summaryOutputTypeInputFieldId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSummaryOutputTypeInputFieldListValueCreate;
