import { Box, Button, Divider, Grid, Tab, Tabs, TextField, Typography, styled } from "@mui/material";

import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetSolvers } from "../../redux/solver/selectors";
import {
  selectorGetSolverInputFields,
  selectorGetSolverInputFieldsBySolverId,
  selectorGetSolverInputFieldTableNamesBySolverId,
} from "../../redux/solverInputField/selectors";
import { fetchCreateSolverJob } from "../../redux/solverJob/actions";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { GetSolverJobLinkBySolverJobId, openInNewTab } from "../../routes/RouteLinkHelpers";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import { ISolver } from "../../utilities/types/Solver";
import { ISolverInputField } from "../../utilities/types/SolverInputField";
import { ISolverJob, TSolverInputFieldValue } from "../../utilities/types/SolverJob";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { getSolverDefaultFieldValue } from "../assetInputField/defaultFieldHelper";
import FileRendererSingle from "../file/renderers/FileRendererSingle";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useFetchSolversPageHook } from "../solver/Hooks";
import { useFetchSolverInputFieldsPageHook } from "../solverInputField/Hooks";
import SolverInputFieldDisplay from "../solverInputField/SolverInputFieldDisplay";
import { useFetchSolverInputFieldListValuesPageHook } from "../solverInputFieldListValue/Hooks";
import useJobCreateState, { SolverInputValuesObjectMap } from "./WizardState";
import { GetUserId } from "../../utilities/ApiUtils";

export const TabStyleWrapper = styled("div")<{ isFromCreateModal?: boolean }>(({ isFromCreateModal, theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  position: "relative",
  ...(isFromCreateModal && {
    overflow: "auto",
    maxHeight: "400px",
  }),
  "& .tabs": {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "30%",
    minWidth: 200,
  },
  "& .tabWrapper": {
    minWidth: "10px",
    maxWidth: "unset",
    "& span": {
      alignItems: "flex-end",
      textTransform: "capitalize",
      ...(isFromCreateModal && {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: 200,
      }),
    },
  },
  "& .solverJobTabCompleted": {
    backgroundColor: "rgba(45, 144, 70, 0.78)",
    color: "#FFF",
    minWidth: "10px",
    maxWidth: "unset",
    "& span": {
      alignItems: "flex-end",
      textTransform: "capitalize",
    },
  },
  "& .tabPanelWrapper": {
    width: "70%",
    maxWidth: "70%",
    ...(isFromCreateModal && {
      maxHeight: "600px",
      overflow: "auto",
    }),
  },
  "& .file-wrapper": {
    display: "flex",
    alignItems: "start",
    gap: 12,
    "& .img-wrapper": {
      margin: 0,
      width: 100,
      height: "auto",
      overflow: "unset",
      ".image": {
        width: 100,
      },
    },
  },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  " & img": {
    filter: "sepia(20%)",
  },
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: theme.shape.borderRadius,
  height: 100,
  overflow: "hidden",
  margin: `0 auto ${theme.spacing(3)} auto`,
}));

interface IJobSolverSubmitStepProps {
  onCompleteCallback(): void;
  isFromCreateModal?: boolean;
}

function JobCreateSolverJobStep({ onCompleteCallback, isFromCreateModal }: IJobSolverSubmitStepProps) {
  const modelId = useJobCreateState((s) => s.modelId);

  const { fetching: fetchingSolvers } = useFetchSolversPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 100,
  });
  const { fetching: fetchingSolverFields } = useFetchSolverInputFieldsPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 100,
  });
  const { fetching: fetchingSolverFieldListValues } = useFetchSolverInputFieldListValuesPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 200,
  });
  const solvers = useSelector((store: RootState) => selectorGetSolvers(store));
  const solverInputFields = useSelector((store: RootState) => selectorGetSolverInputFields(store));

  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  async function onSubmit(solverId: string, jobName: string, goToNextStep: boolean) {
    setSubmitting(true);

    const { modelId, solverInputValuesObject, prerequisites } = useJobCreateState.getState();
    const { addSelectedSolverJob, markTabSolverJobStep } = useJobCreateState.getState();
    const assetJobId = prerequisites.assetJobIds.length > 0 ? prerequisites.assetJobIds[0] : null;
    var solverJob: ISolverJob = (await dispatch(
      fetchCreateSolverJob({
        modelId,
        solverId,
        jobName,
        solverInputFieldValueMap: solverInputValuesObject[solverId],
        prerequisites: { assetJobIds: prerequisites.assetJobIds, solverJobIds: [], outputJobIds: [] },
        inputBucket: null,
        inputPrefix: assetJobId,
        outputBucket: null,
        outputPrefix: null,
        terminateOnErrorCount: null,
      })
    )) as any;

    if (solverJob) {
      addSelectedSolverJob(solverJob);
      markTabSolverJobStep(solverId, solverJob);
    }

    setSubmitting(false);

    goToNextStep && onCompleteCallback();
  }

  // Prepare inital values for solver input fields
  useEffect(() => {
    // Check if we need to short circuit
    if (solvers.length === Object.values(solverInputFields).length) {
      var fieldsEmpty = false;

      // Ensure that each solver has fields populated, if so we don't need to re-populate as it will trigger infinite loop
      // Go through each field shown
      for (var field of Object.values(solverInputFields)) {
        var solverValues = solverInputFields[field.solverId as any];

        // Check if it exists in current object
        if (solverValues && Object.values(solverValues).length < solverInputFields.length) {
          fieldsEmpty = true;
        }
      }

      if (!fieldsEmpty) return;
    }

    var newCurrentValuesObject: SolverInputValuesObjectMap = {};
    for (var solverInputField of solverInputFields) {
      var value = getSolverDefaultFieldValue(solverInputField);
      let valuesObject = newCurrentValuesObject[solverInputField.solverId] || {};
      valuesObject[solverInputField.solverInputFieldId] = value;
      newCurrentValuesObject[solverInputField.solverId] = valuesObject;
    }

    useJobCreateState.setState({ solverInputValuesObject: newCurrentValuesObject });
  }, [solverInputFields, solvers]);

  return (
    <JobSolverSubmitStepDisplay
      solvers={solvers}
      loading={fetchingSolvers || fetchingSolverFields || fetchingSolverFieldListValues || submitting}
      modelId={modelId}
      onSubmit={onSubmit}
      isFromCreateModal={isFromCreateModal}
      onCompleteCallback={onCompleteCallback}
    />
  );
}

interface IJobSolverSubmitStepDisplayProps {
  solvers: ISolver[];
  loading: boolean;
  isFromCreateModal?: boolean;
  modelId: string;
  onSubmit(solverId: string, jobName: string, goToNextStep: boolean): void;
  onCompleteCallback(): void;
}

function JobSolverSubmitStepDisplay({
  solvers,
  loading,
  isFromCreateModal,
  onSubmit,
  onCompleteCallback,
}: IJobSolverSubmitStepDisplayProps) {
  const [activeSolver, setActiveSolver] = React.useState(0);
  const fileName = useJobCreateState((s) => s.fileName);
  const markedTabs = useJobCreateState((s) => s.markedTabsSolverJobStep);
  const userId = GetUserId();
  const handleTabChange = (e: ChangeEvent<{}>, activeSolver: number) => setActiveSolver(activeSolver);

  return (
    <>
      {fileName && (
        <div>
          <Typography style={{ textAlign: "right" }}>{fileName}</Typography>
        </div>
      )}
      <TabStyleWrapper isFromCreateModal={isFromCreateModal}>
        <Tabs
          id="solver-tabs"
          orientation="vertical"
          variant="scrollable"
          value={activeSolver}
          onChange={handleTabChange}
          className="tabs"
        >
          {solvers.map((solver: ISolver) => {
            var tabIsMarked = !!markedTabs[solver.solverId];
            return (
              <Tab
                className={`${tabIsMarked ? "solverJobTabCompleted" : "tabWrapper"}`}
                label={<Typography variant="button">{solver.name}</Typography>}
                style={{
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
            );
          })}
        </Tabs>
        {solvers.map((solver: ISolver, i: number) => {
          var selectedJob = markedTabs[solver.solverId];
          var tabIsMarked = !!selectedJob;
          return (
            <SolverDisplayTab
              isFromCreateModal={isFromCreateModal}
              value={activeSolver}
              index={i}
              key={solver.solverId}
            >
              <Grid container spacing={isFromCreateModal ? 1 : 3} id="customisation-panel">
                {tabIsMarked ? (
                  <SolverTabContentPostSubmit
                    isFromCreateModal={isFromCreateModal}
                    solverJob={selectedJob}
                    onCompleteCallback={onCompleteCallback}
                  />
                ) : (
                  <SolverTabContentPreSubmit
                    isFromCreateModal={isFromCreateModal}
                    solver={solver}
                    userId={userId}
                    onSubmit={onSubmit}
                  />
                )}
              </Grid>
            </SolverDisplayTab>
          );
        })}
        <LoaderAbsoluteCentred loading={loading} />
      </TabStyleWrapper>
    </>
  );
}

interface ISolverDisplayTabProps {
  children: React.ReactNode;
  value: number;
  index: number;
  isFromCreateModal?: boolean;
}

function SolverDisplayTab({ children, value, index, isFromCreateModal }: ISolverDisplayTabProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="tabPanelWrapper"
    >
      {value === index && (
        <Box px={isFromCreateModal ? 2 : 3} py={isFromCreateModal ? 0.5 : 3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

interface ISolverTabContentPostProps {
  solverJob: ISolverJob;
  isFromCreateModal?: boolean;
  onCompleteCallback(): void;
}

function SolverTabContentPostSubmit({ solverJob, isFromCreateModal, onCompleteCallback }: ISolverTabContentPostProps) {
  const viewJobLink = () => openInNewTab(GetSolverJobLinkBySolverJobId(solverJob?.solverJobId));

  const { markTabSolverJobStep } = useJobCreateState.getState();
  const resetSolverJobTab = () => markTabSolverJobStep(solverJob?.solverId as string, null);

  return (
    <div style={{ textAlign: "center", maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}>
      <Typography variant="body1">Solver Job Submitted</Typography>
      <Typography variant="body1" color="textSecondary">
        Your solver job has been successfully submitted.{" "}
        {!isFromCreateModal &&
          `Submit further solver jobs via the tabs on the side or continue
        by clicking below.`}
      </Typography>
      <div style={{ textAlign: "center", marginTop: 16 }}>
        {!isFromCreateModal && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onCompleteCallback}
            style={{ marginBottom: 16 }}
          >
            Select Outputs
          </Button>
        )}

        <Button variant="outlined" fullWidth onClick={resetSolverJobTab} style={{ marginBottom: 16 }}>
          Run another solver job
        </Button>

        <Button variant="outlined" fullWidth onClick={viewJobLink}>
          View solver job
        </Button>
      </div>
    </div>
  );
}

interface ISolverTabContentPreProps {
  solver: ISolver;
  userId: string;
  onSubmit(solverId: string, jobName: string, goToNextStep: boolean): void;
  isFromCreateModal?: boolean;
  isFromWorkflowSolverNode?: boolean;
}

export function SolverTabContentPreSubmit({
  solver,
  userId,
  onSubmit,
  isFromCreateModal,
  isFromWorkflowSolverNode,
}: ISolverTabContentPreProps) {
  const solverInputFields = useSelector((store: RootState) =>
    selectorGetSolverInputFieldsBySolverId(store, solver ? solver.solverId : "")
  );
  const solverInputFieldTableNames = useSelector((store: RootState) =>
    selectorGetSolverInputFieldTableNamesBySolverId(store, solver ? solver.solverId : "")
  );

  const canCreateJobs = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, userId, UserPermissionTypeEnum.PageSolverJobCreate)
  );
  const [jobName, setJobName] = useState(solver.name);

  const submit = () => onSubmit(solver.solverId, jobName, true);
  const submitAndChoose = () => onSubmit(solver.solverId, jobName, false);

  return (
    <>
      {!isFromWorkflowSolverNode && (
        <>
          <Grid item xs={12}>
            <div className={isFromCreateModal ? "file-wrapper" : ""}>
              <ImageWrapper className="img-wrapper">
                {!solver && <Typography variant="caption">Solver Job not found</Typography>}
                {solver && (
                  <FileRendererSingle
                    fileId={solver.mainImageId}
                    canUpload={false}
                    fileType={FileTypeEnum.Image}
                    entityId={String(solver.solverId)}
                    entityType={EntityTypeEnum.Solver}
                    height={isFromCreateModal ? 80 : 150}
                  />
                )}
              </ImageWrapper>
              <div>
                <Typography color="textPrimary">{solver.name}</Typography>
                <Typography color="textSecondary">{solver.description}</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              label="Job Name"
              defaultValue={jobName}
              value={jobName ?? ""}
              onChange={(e: any) => setJobName(e?.target?.value)}
              fullWidth
              variant="standard"
            />
          </Grid>
        </>
      )}

      {solverInputFields.map((field) => {
        if (field.tableName) return null;

        return (
          <Grid key={field.solverInputFieldId} item xs={12}>
            <SolverField field={field} />
          </Grid>
        );
      })}
      {solverInputFieldTableNames.map((x) => {
        var tableFields = solverInputFields
          .filter((y) => y.tableName === x)
          .sort((a, b) => a.orderNumber - b.orderNumber);

        return (
          <Grid key={x} item xs={12}>
            <Typography variant="overline" component="p" style={{ marginBottom: 8 }}>
              {x}
            </Typography>
            <Grid container spacing={1}>
              {tableFields.map((tableField) => (
                <Grid key={tableField.solverInputFieldId} item xs={6}>
                  <SolverField field={tableField} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        );
      })}
      {!isFromWorkflowSolverNode && (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Divider style={{ marginBottom: 16 }} />
          {canCreateJobs ? (
            <div id="submit-buttons">
              <Button
                variant="outlined"
                onClick={submitAndChoose}
                style={{ paddingLeft: 12, paddingRight: 12, marginRight: 8 }}
              >
                Submit
              </Button>
              {!isFromCreateModal && (
                <Button variant="contained" color="primary" onClick={submit}>
                  Submit and Next
                </Button>
              )}
            </div>
          ) : (
            <Typography variant="caption" color="secondary" style={{ paddingTop: 16 }}>
              Your account has not had solver job submissions enabled.
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
}

function SolverField({ field }: { field: ISolverInputField }) {
  const { solverId, solverInputFieldId } = field;
  const value = useJobCreateState((s) => s.solverInputValuesObject[solverId]?.[solverInputFieldId]);
  const setValue = (fieldId: string, value: TSolverInputFieldValue) =>
    useJobCreateState.getState().setSolverFieldValue(solverId, fieldId, value);

  return (
    <SolverInputFieldDisplay
      solverInputField={field}
      disabled={false}
      onValueChangeCallback={setValue}
      overriddenDefaultValue={value}
    />
  );
}

export default JobCreateSolverJobStep;
