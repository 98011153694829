import React from "react";
import { Typography } from "@mui/material";

export const IntroductionContent = () => {
  return (
      <Typography style={{ paddingTop: 16 }}>
        AssetWiki is a free knowledge-base using an implementation of modla's API, created and edited by volunteers
        around the world.
      </Typography>
  );
};

export const VisionContent = () => {
  return (
      <Typography style={{ paddingTop: 16 }}>
        To capture, share and facilitate the continuous improvement of asset knowledge and asset related business
        process.
      </Typography>
  );
};

const exportedObject = {
  IntroductionContent,
  VisionContent,
};

export default exportedObject;
