import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchSolversProps, fetchSearchSolvers } from "../../redux/solver/actions";
import { ISolver } from "../../utilities/types/Solver";


export interface IUseFetchSolversPageHookProps extends IFetchSearchSolversProps {
    minPageNumberToFetch: number;
}


export const useFetchSolversPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, solverId, text }: IUseFetchSolversPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ISolver[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ISolver[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var solvers = await dispatch(fetchSearchSolvers({
                    pageSize,
                    pageNumber,
                    solverId,
                    text,
                })) as unknown as ISolver[];

                if (solvers && solvers.length) {
                    setMorePages(solvers.length >= pageSize)
                    setLastResultSet(solvers);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, solverId, text]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.solverId === x.solverId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}