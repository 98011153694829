import { IIdentifier } from "../../utilities/types/Identifier";


export const ACTIONS_IDENTIFIER = {
    INVALIDATE: 'INVALIDATE_IDENTIFIERS',
    RECEIVE: 'RECEIVE_IDENTIFIERS',
    DELETE: 'DELETE_IDENTIFIER',
    UPDATE: 'UPDATE_IDENTIFIERS',
}

interface ActionIdentifiersInvalidate {
    type: typeof ACTIONS_IDENTIFIER.INVALIDATE,
    byId: { [key: string]: IIdentifier },
}

interface ActionIdentifiersReceive {
    type: typeof ACTIONS_IDENTIFIER.RECEIVE
    byId: { [key: string]: IIdentifier },
}

export interface IdentifierState {
    byId: { [key: string]: IIdentifier },
}

export enum IdentifierSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesIdentifier = ActionIdentifiersReceive | ActionIdentifiersInvalidate;