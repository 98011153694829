import React, { useState } from "react";
import { Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Grid, styled } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import { ShowMessage, UpdateMessage } from "../../utilities/Helpers";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { selectorGetFunctionById } from "../../redux/function/selectors";
import { IFunction } from "../../utilities/types/Function";
import { fetchDeleteFunction } from "../../redux/function/actions";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
import teal from "@mui/material/colors/deepPurple";
import ModalIdentifierMappingCreate from "../identifierMapping/modals/ModalIdentifierMappingCreate";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import EditFunctionIcon from "@mui/icons-material/Edit";
import AddFunctionalFailureIcon from "@mui/icons-material/SmsFailedOutlined";
import ModalFunctionalFailureCreate from "../functionalFailure/modals/ModalFunctionalFailureCreate";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel, ModelStatusEnum } from "../../utilities/types/Model";
import { selectorGetModelById } from "../../redux/model/selectors";
import { green } from "@mui/material/colors";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";

const DivWrapper = styled("div")(({ theme }) => ({
  border: "1px solid #EEE",
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  paddingBottom: 0,
  "& .firstRowWrapper": {
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .iconWrapper": {
    backgroundColor: teal[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .textWrapper": {
    marginLeft: theme.spacing(3),
  },
  "& .menuWrapper": {
    textAlign: "right",
  },
  "& .greenIcon": {
    color: green[500],
  },
  "& .orangeDraft": {
    color: "orange",
  },
}));

type IFunctionRendererProps = {
  canEdit: boolean;
  functionId: string;
  onFunctionClick(): void;
};

const FunctionRenderer = (props: IFunctionRendererProps) => {
  const { functionId } = props;
  const componentFunction: IFunction | undefined = useSelector((store: RootState) =>
    selectorGetFunctionById(store, functionId)
  );

  if (!componentFunction) {
    console.error(`Component function not found: ${functionId}`);
    return null;
  }

  return <FunctionRendererDisplay componentFunction={componentFunction} {...props} />;
};

interface IFunctionRendererDisplayProps extends IFunctionRendererProps {
  componentFunction: IFunction;
  onFunctionClick(): void;
}

const FunctionRendererDisplay = (props: IFunctionRendererDisplayProps) => {
  const { componentFunction, canEdit, onFunctionClick } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteModal(!showDeleteModal);
  const dispatch = useDispatch();
  const [, setDeleting] = useState(false);
  const [showCreateMappingModel, setShowCreateMappingModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showAddFunctionalFailureModal, setShowAddFunctionalFailureModal] = useState(false);
  const model = useSelector((store: RootState) => selectorGetModelById(store, componentFunction.modelId));
  const canAddFunctionalFailures = useSelector((state: RootState) =>
  selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesFunctionalFailureCreate)
  );

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function toggleCreateMappingModal() {
    setShowCreateMappingModal(!showCreateMappingModel);
  }

  async function toggleShowAddFunctionalFailureModal() {
    setMenuAnchorEl(undefined);
    setShowAddFunctionalFailureModal(!showAddFunctionalFailureModal);
  }

  async function deleteHandler() {
    // Inform user
    setDeleting(true);
    var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
    setShowDeleteModal(false);

    // Perform delete
    var resp = await dispatch(fetchDeleteFunction({ functionId: componentFunction.functionId }));

    // Cleanup
    setDeleting(false);
    if (resp != null) {
      UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
      toggleDeleteConfirmation();
    }
  }

  async function closeEditModal() {
    setMenuAnchorEl(undefined);
    onFunctionClick();
  }

  function onCompleteCallback() {
    setMenuAnchorEl(undefined);
    toggleShowAddFunctionalFailureModal();
    dispatch(EnableComponentHierachy({ model: model as IModel }));
  }

  return (
    <DivWrapper>
      <div className="firstRowWrapper">
        <Grid container>
          <Grid item xs={1}>
            <div className="iconWrapper">
              <FunctionIcon />
            </div>
          </Grid>
          <Grid item xs={10}>
            <div className="textWrapper">
              {componentFunction.status === ModelStatusEnum.Draft ? (
                <Typography className="orangeDraft">{componentFunction.description} (Draft)</Typography>
              ) : (
                <Typography variant="body1">{componentFunction.description}</Typography>
              )}
              <div>
                <IdentifierMappingsRenderer
                  canEdit={canEdit}
                  modelId={componentFunction.modelId}
                  type={IdentifierMappingTypeEnum.Function}
                  targetId={String(componentFunction.functionId)}
                  loading={false}
                  showValues={false}
                />
                <ModalIdentifierMappingCreate
                  open={showCreateMappingModel}
                  onCancelCallback={toggleCreateMappingModal}
                  onCompleteCallback={toggleCreateMappingModal}
                  targetId={String(componentFunction.componentId)}
                  modelId={componentFunction.modelId}
                  identifierMappingType={IdentifierMappingTypeEnum.Function}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className="menuWrapper">
              {canEdit ? (
                <>
                  <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                    <EditFunctionIcon className="greenIcon" />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={() => setMenuAnchorEl(undefined)}
                  >
                    {canAddFunctionalFailures ? (
                      <MenuItem onClick={toggleShowAddFunctionalFailureModal}>
                        <ListItemIcon>
                          <AddFunctionalFailureIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Add functional failure" />
                      </MenuItem>
                    ) : null}
                      <MenuItem onClick={closeEditModal}>
                        <ListItemIcon>
                          <EditFunctionIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Edit function" />
                      </MenuItem>
                  </Menu>
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      <WidgetModalConfirmationDialog
        open={showDeleteModal}
        title="Delete confirmation"
        subtitle="Confirm identifier delete"
        description="Are you sure that you'd like to remove this identifier?"
        onCancelCallback={toggleDeleteConfirmation}
        onConfirmCallback={deleteHandler}
        confirmButtonText="Delete"
      />

      <ModalFunctionalFailureCreate
        open={showAddFunctionalFailureModal}
        onCancelCallback={toggleShowAddFunctionalFailureModal}
        onCompleteCallback={onCompleteCallback}
        modelId={componentFunction.modelId}
        functionId={componentFunction.functionId}
      />
    </DivWrapper>
  );
};

export default FunctionRenderer;
