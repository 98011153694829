import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import InputIcon from "@mui/icons-material/SettingsTwoTone";
import FormComponentUpdate from "../forms/FormComponentUpdate";
import { IComponent } from "../../../utilities/types/Component";
import FileRendererSingle from "../../file/renderers/FileRendererSingle";
import { FileTypeEnum } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { fetchSearchComponents } from "../../../redux/component/actions";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetComponentById } from "../../../redux/component/selectors";
import { RootState } from "../../../redux";

export interface IModalComponentUpdateProps {
  open: boolean;
  component: IComponent;
  onCancelCallback(): void;
  onCompleteCallback(component?: IComponent): void;
  canEdit: boolean;
}

function ModalComponentUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  component,
  canEdit,
}: IModalComponentUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a component"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <RenderImage componentId={component.componentId} canEdit={canEdit} />
        <FormComponentUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          component={component}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

interface IRenderImageProps {
  componentId: string;
  canEdit: boolean;
}

function RenderImage({ componentId, canEdit }: IRenderImageProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const component = useSelector((store: RootState) => selectorGetComponentById(store, componentId));

  if (!component) return null;

  const onUploadComplete = async () => {
    setLoading(true);
    await dispatch(
      fetchSearchComponents({
        componentId: component.componentId,
        modelId: component.modelId,
        pageNumber: 1,
        pageSize: 1,
      })
    );
    setLoading(false);
  };

  return (
    <div style={{ borderRadius: 4, position: "relative" }}>
      <FileRendererSingle
        fileId={component.mainImageId || ""}
        canUpload={canEdit}
        fileType={FileTypeEnum.Image}
        entityId={String(component.componentId)}
        entityType={EntityTypeEnum.Component}
        height={150}
        onUploadComplete={onUploadComplete}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </div>
  );
}

export default ModalComponentUpdate;
