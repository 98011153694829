import React, { useState } from "react";
import { Grid, Breadcrumbs, Link, styled } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import HeaderIcon from "@mui/icons-material/AccountTree";
import { selectorGetSingleAssetOutputById } from "../../../redux/singleAssetOutput/selectors";
import SingleAssetOutputDisplayWidget from "../SingleAssetOutputDisplayWidget";
import { HomeIconLink } from "../../generic/HomeIconLink";
import OutputCollapseCard from "../OutputCollapseCard";

const GridWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const PageSingleAssetOutputDetails = (routeProps: RouteComponentProps<any>) => {
  const {
    match: {
      params: { outputId },
    },
  } = routeProps;
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));
  const output = useSelector((store: RootState) => selectorGetSingleAssetOutputById(store, outputId));
  const canEdit = isAdmin || (output && output.createdBy === GetUserId()) ? true : false;

  if (!output) {
    let description = !canEdit ? "View details permission may be required." : "";
    return (
      <Container maxWidth="xl" style={{ marginTop: 16 }}>
        <WidgetNoResultsPlaceholder text="Output not found" description={description} fade={true} icon={HeaderIcon} />
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <GridWrapper item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <HomeIconLink />
          <Link color="inherit" href="/outputs">
            Output
          </Link>
          <Link
            color="textPrimary"
            aria-current="page"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {output?.name || "Output"}
          </Link>
        </Breadcrumbs>
      </GridWrapper>
      <OutputCollapseCard singleAssetOutput={output} showCollapse={showCollapse} setShowCollapse={setShowCollapse}>
        <Grid container style={{ padding: 16 }}>
          <Grid item xs={12} md={6}>
            <SingleAssetOutputDisplayWidget singleAssetOutput={output} loading={false} />
          </Grid>
        </Grid>
      </OutputCollapseCard>
    </Container>
  );
};

export default PageSingleAssetOutputDetails;
