import DialogContent from "@mui/material/DialogContent";
import BuildIcon from "@mui/icons-material/LabelTwoTone";
import React from "react";
import { useDispatch } from "react-redux";
import { IIdentifierMapping } from "../../../utilities/types/IdentifierMapping";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormIdentifierMappingUpdate from "../forms/FormIdentifierMappingUpdate";

export interface IModalIdentifierMappingUpdateProps {
  open: boolean;
  onCancelCallback(): void;
  identifierMapping: IIdentifierMapping;
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
}

function ModalIdentifierMappingCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  identifierMapping,
}: IModalIdentifierMappingUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update an identifier"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
      style={{ maxWidth: 800 }}
    >
      <DialogContent style={{ paddingTop: 0, paddingBottom: 16 }}>
        <FormIdentifierMappingUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          identifierMapping={identifierMapping}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalIdentifierMappingCreate;
