import React, { useState } from "react";
import { TextField, Grid, Divider, Button, FormControl, IconButton } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IEvent } from "../../../utilities/types/Event";
import { fetchCreateEvent } from "../../../redux/event/actions";
import { useSelector } from "react-redux";
import { selectorGetTasksByModelId } from "../../../redux/task/selectors";
import { selectorGetCostBundlesByModelId } from "../../../redux/costBundle/selectors";
import AddIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ModalCostBundleCreate from "../../costBundle/modals/ModalCostBundleCreate";
import ModalTaskCreate from "../../task/modals/ModalTaskCreate";
import { Autocomplete } from "@mui/lab";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import { ITask } from "../../../utilities/types/Task";
import StringListInput from "../../generic/widgets/StringListInput";

const validationSchema = Yup.object().shape({
  name: Yup.array().of(
    Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(100, "Please input 100 characters or less")
      .required("Please provide a name")
  ),
});

interface FormValues {
  name: string[];
  disabled: boolean;
  costBundleId: string;
  taskId: string;
}

interface FormProps {
  event: IEvent;
  modelId: string;
  costBundleObj?: ICostBundle;
  taskObj?: ITask;
  onCompleteCallback(event?: IEvent): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IEvent, AnyAction>;
}

const FormEventDuplicate = (props: FormProps) => {
  const { modelId, onCancelCallback } = props;
  const store = useSelector((store: RootState) => store);
  const tasks = selectorGetTasksByModelId(store, modelId);
  const costBundles = selectorGetCostBundlesByModelId(store, modelId);
  const [showCostBundleCreate, setShowCostBundleCreate] = useState<boolean>(false);
  const [showTaskCreate, setShowTaskCreate] = useState<boolean>(false);

  function toggleShowCostBundleCreate() {
    setShowCostBundleCreate(!showCostBundleCreate);
  }

  function toggleShowTaskCreate() {
    setShowTaskCreate(!showTaskCreate);
  }

  const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const eventsToCreate = values.name.map((name) => ({
      modelId,
      name,
      disabled: values.disabled,
      costBundleId: values.costBundleId,
      taskId: values.taskId,
    }));

    // Map dispatch via props
    var createdEvent = await dispatch(fetchCreateEvent({ events: eventsToCreate }));

    setSubmitting(false);

    if (createdEvent) onCompleteCallback(createdEvent);
  };

  return (
    <Formik
      initialValues={{
        taskId: props.event.taskId,
        costBundleId: props.event.costBundleId,
        disabled: false,
        name: [props.event.name],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StringListInput
                label="Name(s)"
                onStringListChange={(newStringList) =>
                  props.handleChange({
                    target: {
                      name: "name",
                      value: newStringList,
                    },
                  })
                }
                stringList={props.values.name}
                errors={props.errors.name}
              />
            </Grid>

            <Grid item xs={10}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  {...getFormikFieldProps(props, "costBundleObj", "Cost Bundle")}
                  style={{ display: "block" }}
                  options={costBundles}
                  getOptionLabel={(option) => option.name}
                  defaultValue={costBundles.find((element) => {
                    return element.costBundleId === props.values.costBundleId;
                  })}
                  onChange={(_, value) => {
                    props.setFieldValue("costBundleId", value ? value.costBundleId : "");
                    props.setFieldValue("costBundleObj", value);
                  }}
                  renderInput={(params) => <TextField variant="standard" {...params} label="Cost Bundle" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={toggleShowCostBundleCreate} style={{ marginTop: 8 }}>
                <AddIcon />
              </IconButton>
            </Grid>

            <Grid item xs={10}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  {...getFormikFieldProps(props, "taskObj", "Task")}
                  style={{ display: "block" }}
                  options={tasks}
                  getOptionLabel={(option) => option.name}
                  defaultValue={tasks.find((element) => {
                    return element.taskId === props.values.taskId;
                  })}
                  onChange={(_, value) => {
                    props.setFieldValue("taskId", value ? value.taskId : "");
                    props.setFieldValue("taskObj", value);
                  }}
                  renderInput={(params) => <TextField variant="standard" {...params} label="Task" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={toggleShowTaskCreate} style={{ marginTop: 8 }}>
                <AddIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>
              <Divider light={true} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button disabled={props.isSubmitting} variant="text"  onClick={onCancelCallback}>
                Close
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
                Create
              </Button>
              <LoaderAbsoluteCentred loading={props.isSubmitting} />
            </Grid>
          </Grid>
          {showCostBundleCreate && (
            <ModalCostBundleCreate
              open={showCostBundleCreate}
              onCancelCallback={toggleShowCostBundleCreate}
              modelId={modelId}
              onCompleteCallback={toggleShowCostBundleCreate}
            />
          )}
          {showTaskCreate && (
            <ModalTaskCreate
              open={showTaskCreate}
              onCancelCallback={toggleShowTaskCreate}
              modelId={modelId}
              onCompleteCallback={toggleShowTaskCreate}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default FormEventDuplicate;
