import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FileTable from "../FileTable";
import { EntityTypeEnum } from "../../../utilities/types/Entity";

export interface ModalFileTableProps {
  handleClose(): void;
  open: boolean;
  entityType?: EntityTypeEnum;
}

const ModalFileTable = ({ handleClose, open, entityType }: ModalFileTableProps) => {
  return (
    <WidgetModalBase
      handleCancel={handleClose}
      open={open}
      title="Files List"
      subtitle="Browse the list of files"
      style={{ maxWidth: "875px", width: "100%" }}
      showCloseBtn
    >
      <DialogContent style={{ minHeight: "400px", overflowY: "auto" }}>
        <FileTable entityType={entityType} />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalFileTable;
