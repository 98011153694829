import React from "react";
import { ICostBundleSplitOut } from "../../utilities/types/CostBundle";
import { Box, Typography } from "@mui/material";

const CostBundleSplitOut = ({ spitOut }: { spitOut?: ICostBundleSplitOut }) => {
  if (!spitOut || Object.keys(spitOut).length === 0) return null;

  return (
    <Box mt={2}>
      {Object.keys(spitOut).map((key, index) => (
        <Typography variant="caption" display="block" key={key + index}>
          {key} - {spitOut[key]}
        </Typography>
      ))}
    </Box>
  );
};

export default CostBundleSplitOut;
