import { ActionTypesComponent, ComponentState, ACTIONS_COMPONENT } from "./types";

const initialState: ComponentState = {
    byId: {},
}


export function componentsReducer(
    state = initialState,
    action: ActionTypesComponent
): ComponentState {
    switch(action.type){


        case ACTIONS_COMPONENT.INVALIDATE: {
            return {
                ...initialState,
            }
        }


        case ACTIONS_COMPONENT.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }


        case ACTIONS_COMPONENT.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }

        default:
            return state;
    }
}