import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import { GetUserId } from '../utilities/ApiUtils';
import PageUserDetails from '../components/userDetails/pages/PageUserDetails';
import RouteUser from './RouteUser';


const RouteUsers = () => {

    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/me`} render={props => <LayoutDefault routeProps={props}><PageUserDetails routeProps={props} userId={GetUserId()} /></LayoutDefault>} />
            <Route path={`${match.path}/:userId`}><RouteUser /></Route>
        </Switch>
    )
}


export default RouteUsers;