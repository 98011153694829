import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface IPinnedIdentifiersHook {
  ids: string[];
  addRemovePinnedId: (newId: string) => void;
}

export const usePinnedIdentifiersHook = create<IPinnedIdentifiersHook>()(
  devtools(
    persist<IPinnedIdentifiersHook>(
      (set) => ({
        ids: [],
        addRemovePinnedId: (newId: string) =>
          set((state) => ({
            ids: state.ids.includes(newId) ? state.ids.filter((id) => id !== newId) : [...state.ids, newId], // Add newId at the end
          })),
      }),
      {
        name: "pinned-identifiers",
      }
    )
  )
);
