export interface InsufficientPermissionState {
  message: string;
  showModal: boolean;
}

export const ACTIONS_INSUFFICIENT_PERMISSION_MAPPING = {
  SET: "SET_INSUFFICIENT_PERMISSION_MAPPING",
  RESET: "RESET_INSUFFICIENT_PERMISSION_MAPPING",
};

interface ActionInsufficientPermissionSet {
  type: typeof ACTIONS_INSUFFICIENT_PERMISSION_MAPPING.SET;
  message: string;
}

interface ActionInsufficientPermissionReset {
  type: typeof ACTIONS_INSUFFICIENT_PERMISSION_MAPPING.RESET;
  message: string;
}

export type ActionTypesInsufficientPermissionMapping =
  | ActionInsufficientPermissionReset
  | ActionInsufficientPermissionSet;
