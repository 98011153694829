import React, { useState } from "react";

import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import { useDispatch } from "react-redux";
import { ACTIONS_WORKFLOW_TYPE } from "../../redux/workflow/types";
import ModalWorkflowCreate from "./modals/ModalWorkflowCreate";
import ModalWorkflowUpdate from "./modals/ModalWorkflowUpdate";
import { IWorkflow } from "../../utilities/types/Workflow";

const WorkflowWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  "& .workflowHeader_inner": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    flexWrap: "wrap",
    flex: 1,
    flexShrink: 0,
  },
  "& .workflowHeader_actions": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  "& .workflowHeader_select": {
    maxWidth: "220px",
    minWidth: "150px",
    flex: 1,
  },
}));

interface WorkflowHeaderWidgetProps {
  allWorkflows: IWorkflow[];
  selectedWorkFlow?: IWorkflow;
  handleRunWorkflow: (workflowId: string) => void;
  handleSaveWorkflow: (workflow: IWorkflow) => void;
}

const WorkflowHeaderWidget: React.FC<WorkflowHeaderWidgetProps> = ({
  allWorkflows,
  selectedWorkFlow,
  handleSaveWorkflow,
  handleRunWorkflow,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("xs"));

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showWorkflowModal, setShowWorkflowModal] = useState<boolean>(false);

  const handleChange = (event: any) => {
    dispatch({
      type: ACTIONS_WORKFLOW_TYPE.SET_SELECTED,
      workflowId: event.target.value,
    });
  };

  function handleToggleShowWorkflowModal() {
    setShowWorkflowModal((prev) => !prev);
  }

  function handleToggleShowUpdateWorkflowModal() {
    setShowUpdateModal((prev) => !prev);
  }

  return (
    <WorkflowWrapper>
      <div className="workflowHeader_inner">
        <Typography variant={isSmallDevice ? "h6" : "h5"}>Workflows:</Typography>
        <FormControl size="small" className="workflowHeader_select">
          <Select
            labelId="workflow-type-label"
            value={selectedWorkFlow ? selectedWorkFlow.workflowId : ""}
            onChange={handleChange}
            style={{ display: "block" }}
            inputProps={{ "aria-label": "Without label" }}
            id="workflow-type"
            variant="standard"
          >
            {allWorkflows.map(({ workflowId, workflowName }: IWorkflow) => (
              <MenuItem key={workflowId} value={workflowId}>
                {workflowName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedWorkFlow && (
          <IconButton size={isSmallDevice ? "small" : "medium"} onClick={handleToggleShowUpdateWorkflowModal}>
            <EditIcon />
          </IconButton>
        )}
        <IconButton size={isSmallDevice ? "small" : "medium"} onClick={handleToggleShowWorkflowModal}>
          <AddIcon />
        </IconButton>
      </div>
      {selectedWorkFlow && (
        <div className="workflowHeader_actions">
          <Button variant="contained" color="primary" onClick={() => handleSaveWorkflow(selectedWorkFlow)}>
            Save
          </Button>
          <Button variant="outlined" color="primary" onClick={() => handleRunWorkflow(selectedWorkFlow.workflowId)}>
            Run
          </Button>
        </div>
      )}
      <ModalWorkflowCreate
        open={showWorkflowModal}
        onCancelCallback={handleToggleShowWorkflowModal}
        onCompleteCallback={handleToggleShowWorkflowModal}
      />
      {selectedWorkFlow && (
        <ModalWorkflowUpdate
          open={showUpdateModal}
          workflow={selectedWorkFlow}
          onCancelCallback={handleToggleShowUpdateWorkflowModal}
          onCompleteCallback={handleToggleShowUpdateWorkflowModal}
        />
      )}
    </WorkflowWrapper>
  );
};

export default WorkflowHeaderWidget;
