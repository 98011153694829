import { Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled } from "@mui/material";

import EditUserDetailIcon from "@mui/icons-material/EditOutlined";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ViewUserIcon from "@mui/icons-material/PersonOutlineTwoTone";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux";
import { selectorGetFileByEntityTypeAndEntityId } from "../../redux/file/selectors";
import { GetUserLinkByUserId } from "../../routes/RouteLinkHelpers";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import { IUserDetail } from "../../utilities/types/UserDetail";
import ModalEntityPermissionsEntitySelect from "../entityPermission/modals/ModalEntityPermissionsEntitySelect";
import { useFileSourceSingleHook } from "../file/Hooks";
import ModalUserRoleMappingCreate from "../userRoles/modals/ModalUserRoleMappingCreate";
// import UserRoleMappingsRenderer from "../userRoles/UserRoleMappingsRenderer";

const imageDiameter = 75;
const GridWrapper = styled(Grid)(({ theme }) => ({
  border: "1px solid #EEE",
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  "& .userDetailImageWrapper": {
    width: imageDiameter,
    maxWidth: "12%",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    overflow: "hidden",
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& .userDetailImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  "& .userDetailMainWrapper": {
    marginLeft: theme.spacing(1),
  },
}));

interface IUserDetailsManagementContainerRowProps {
  userDetail: IUserDetail;
}

function UserDetailsManagementContainerRow({ userDetail }: IUserDetailsManagementContainerRowProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const fileId = useSelector((store: RootState) =>
    selectorGetFileByEntityTypeAndEntityId(store, EntityTypeEnum.User, userDetail?.userDetailId || "")
  )?.fileId;
  const imageUrl = useFileSourceSingleHook({ fileId: fileId || "", fileType: FileTypeEnum.Image });
  const [showUserRoleMappingModal, setShowUserRoleMappingModal] = useState(false);
  const [showEntityPermissionsModal, setShowEntityPermissionsModal] = useState(false);

  const history = useHistory();

  const toggleUserRoleMappingModal = () => {
    setShowUserRoleMappingModal(!showUserRoleMappingModal);
    setMenuAnchorEl(undefined);
  };

  const toggleEntityPermissionsModal = () => {
    setShowEntityPermissionsModal(!showEntityPermissionsModal);
    setMenuAnchorEl(undefined);
  };

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      <GridWrapper container>
        <Grid item xs={3} md={2} lg={1}>
          <div className="userDetailImageWrapper">
            <div
              className="userDetailImage"
              style={{ backgroundImage: `url("${imageUrl}")` }}
              onClick={() => history.push(GetUserLinkByUserId(userDetail.userDetailId))}
            />
          </div>
        </Grid>
        <Grid item xs={6} md={7} lg={8} style={{ paddingLeft: 8 }}>
          <div className="userDetailMainWrapper">
            <div>
              <Typography variant="body1" noWrap={true}>
                {userDetail.displayName}
              </Typography>

              <Typography style={{ opacity: 0.8, fontSize: "85%" }} noWrap={true}>
                Registered {moment.utc(userDetail.created).fromNow()} - {userDetail.userDetailId}
              </Typography>
              {/* <UserRoleMappingsRenderer
                                    canEdit={true}
                                    userDetailsId={userDetail.userDetailId}
                                    loading={false}
                                /> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          <>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>

            <Menu
              style={{ padding: 0 }}
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              <MenuItem onClick={toggleUserRoleMappingModal}>
                <ListItemIcon>
                  <ViewUserIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Set User Roles" />
              </MenuItem>
              <MenuItem onClick={toggleEntityPermissionsModal}>
                <ListItemIcon>
                  <EditUserDetailIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Entity Permissions" />
              </MenuItem>
            </Menu>
          </>
        </Grid>
      </GridWrapper>
      {showUserRoleMappingModal && (
        <ModalUserRoleMappingCreate
          open={showUserRoleMappingModal}
          onCancelCallback={toggleUserRoleMappingModal}
          onCompleteCallback={toggleUserRoleMappingModal}
          userDetailId={userDetail.userDetailId}
        />
      )}

      {showEntityPermissionsModal && (
        <ModalEntityPermissionsEntitySelect
          open={showEntityPermissionsModal}
          onCancelCallback={toggleEntityPermissionsModal}
          userDetailId={userDetail.userDetailId}
        />
      )}
    </>
  );
}

export default UserDetailsManagementContainerRow;
