import React from "react";
import Collapse from "@mui/material/Collapse";
import { useDispatch } from "react-redux";
import FormCommentCreate from "../forms/FormCommentCreate";
import { IComment } from "../../../utilities/types/Comment";
import { EntityTypeEnum } from "../../../utilities/types/Entity";

export interface IModalCommentCreateProps {
  open: boolean;
  onCancelCallback(): void;
  discussionId: string;
  parentId: string;
  onCompleteCallback(comment?: IComment): void;
  entityId: string;
  entityType: EntityTypeEnum;
}

function ModalCommentCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  discussionId,
  parentId,
  entityId,
  entityType,
}: IModalCommentCreateProps) {
  const dispatch = useDispatch();

  return (
    <Collapse in={open}>
      <FormCommentCreate
        dispatch={dispatch}
        onCancelCallback={onCancelCallback}
        onCompleteCallback={onCompleteCallback}
        discussionId={discussionId}
        parentId={parentId}
        entityId={entityId}
        entityType={entityType}
      />
    </Collapse>
  );
}

export default ModalCommentCreate;
