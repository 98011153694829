import { ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING, SolverOutputTypeMappingSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ISolverOutputTypeMapping } from '../../utilities/types/SolverOutputTypeMapping';
import { selectorGetSolverOutputTypeMappingsBySolverId } from './selectors';


export const receiveSolverOutputTypeMappings = (solverOutputTypeMappings: ISolverOutputTypeMapping[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverOutputTypeMapping } = {};

    for (var i = 0; i < solverOutputTypeMappings.length; i++) {
        byIdObjectToDispatch[solverOutputTypeMappings[i].solverOutputTypeMappingId] = solverOutputTypeMappings[i];
    }

    return {
        type: ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverOutputTypeMapping = (solverOutputTypeMapping: ISolverOutputTypeMapping) => ({
    type: ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.DELETE,
    byId: { [solverOutputTypeMapping.solverOutputTypeMappingId]: solverOutputTypeMapping }
});


export interface IFetchCreateSolverOutputTypeMappingProps {
    solverId: string,
    outputTypeIds: string[],
}


export const fetchCreateSolverOutputTypeMapping = (request: IFetchCreateSolverOutputTypeMappingProps): AppThunk<Promise<ISolverOutputTypeMapping>> => async (dispatch, getStore) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverOutputTypeMappings`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(request),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverOutputTypeMappings) {

            // Remove all previous mappings for solver to ensure we don't create zombie (client-side) state
            var existingMappings = selectorGetSolverOutputTypeMappingsBySolverId(getStore(), request.solverId);
            for(var mapping of existingMappings){
                dispatch(requestDeleteSolverOutputTypeMapping(mapping));
            }

            // Apply recieved ones
            dispatch(receiveSolverOutputTypeMappings(parsedResp.data.solverOutputTypeMappings));
            return parsedResp.data.solverOutputTypeMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating solver output type mapping.");
                return;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export const fetchOutputTypeMappingsBySolverIdIfNeeded = (solverId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetSolverOutputTypeMappingsBySolverId(getState(), solverId).length) {
        await dispatch(fetchSearchSolverOutputTypeMappings({ solverId, pageNumber: 1, pageSize: 100 }));
    }

    return;
}


export interface IFetchSearchSolverOutputTypeMappingsProps {
    pageNumber: number,
    pageSize: number,
    solverOutputTypeMappingId?: string,
    solverId?: string,
    orderType?: SolverOutputTypeMappingSearchOrderTypeEnum,
}


export const fetchSearchSolverOutputTypeMappings = (searchParams: IFetchSearchSolverOutputTypeMappingsProps): AppThunk<Promise<ISolverOutputTypeMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverOutputTypeMappings`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverOutputTypeMappings) {
            dispatch(receiveSolverOutputTypeMappings(parsedResp.data.solverOutputTypeMappings));
            return parsedResp.data.solverOutputTypeMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching solver output type mapping.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSolverOutputTypeMappingProps {
    solverOutputTypeMappingId: string,
}


export const fetchDeleteSolverOutputTypeMapping = (props: IFetchDeleteSolverOutputTypeMappingProps): AppThunk<Promise<ISolverOutputTypeMapping>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverOutputTypeMappings`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverOutputTypeMappings && parsedResp.data.solverOutputTypeMappings.length) {
            dispatch(requestDeleteSolverOutputTypeMapping(parsedResp.data.solverOutputTypeMappings[0]));
            return parsedResp.data.solverOutputTypeMappings[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting solver output type mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}