import { IComponent } from "../../utilities/types/Component";


export const ACTIONS_COMPONENT = {
    INVALIDATE: 'INVALIDATE_COMPONENTS',
    RECEIVE: 'RECEIVE_COMPONENTS',
    DELETE: 'DELETE_COMPONENT',
    UPDATE: 'UPDATE_COMPONENTS',
}

interface ActionComponentsInvalidate {
    type: typeof ACTIONS_COMPONENT.INVALIDATE,
    byId: { [key: string]: IComponent },
}

interface ActionComponentsReceive {
    type: typeof ACTIONS_COMPONENT.RECEIVE
    byId: { [key: string]: IComponent },
}

export interface ComponentState {
    byId: { [key: string]: IComponent },
}

export enum ComponentSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesComponent = ActionComponentsReceive | ActionComponentsInvalidate;