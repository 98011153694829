import React from 'react';
import { Switch, Route } from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import DashboardPage from '../components/dashboard/pages/DashboardPage';


const RouteDashboard = () => {

    return (
        <Switch>
            <Route render={props => <LayoutDefault routeProps={props}><DashboardPage {...props} /></LayoutDefault>} />
        </Switch>
    )
}


export default RouteDashboard;