import { RootState } from "..";
import { IIdentifier } from "../../utilities/types/Identifier";


export function selectorGetIdentifiersByModelId(state: RootState, modelId?: string) : IIdentifier[] {

    if (!state.identifiers || !state.identifiers.byId) return [];

    return sortIdentifiersByName(Object.values(state.identifiers.byId).filter(x => x.modelId === modelId));
}

export function selectorGetGlobalIdentifiers(state: RootState) : IIdentifier[] {

    if (!state.identifiers || !state.identifiers.byId) return [];

    return sortIdentifiersByName(Object.values(state.identifiers.byId).filter(x => x.modelId === null));
}


export function selectorGetIdentifierById(state: RootState, identifierId: string) : IIdentifier | undefined {

    if (!state.identifiers || !state.identifiers.byId) return undefined;

    return state.identifiers.byId[identifierId];
}


function sortIdentifiersByName(solvers: IIdentifier[]) {
    return solvers.sort((a, b) => {
        return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
    });
}