import { RootState } from "..";
import { ISolverHistory } from "../../utilities/types/SolverHistory";


export function selectorGetSolverHistoriesBySolverId(state: RootState, solverId: string) : ISolverHistory[] {

    if (!state.solverHistories || !state.solverHistories.byId) return [];

    return sortSolverHistoriesByVersionNumberDescending(Object.values(state.solverHistories.byId).filter(x => x.solverId === solverId));
}


export function selectorGetMaxSolverHistoryVersionNumber(state: RootState, solverId: string) {

    var solverHistories = selectorGetSolverHistoriesBySolverId(state, solverId);

    return solverHistories.length ? solverHistories[0].versionNumber : 0;
}


export function selectorGetSolverHistoryById(state: RootState, solverHistoryId: string) : ISolverHistory | undefined {

    if (!state.solverHistories || !state.solverHistories.byId) return undefined;

    return state.solverHistories.byId[solverHistoryId];
}


// function sortSolverHistoriesByCreatedDescending(solverHistories: ISolverHistory[]) {
//     return solverHistories.sort((a, b) => a.created.getTime() - b.created.getTime());
// }


function sortSolverHistoriesByVersionNumberDescending(solverHistories: ISolverHistory[]) {
    return solverHistories.sort((a, b) => b.versionNumber - a.versionNumber);
}