import React, { useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel, ModelStatusEnum } from "../../utilities/types/Model";
import { DecisionActionTypeEnum, IDecision } from "../../utilities/types/Decision";
import { useFetchDecisionsPageHook } from "./Hooks";
import { selectorGetDecisionsByModelId } from "../../redux/decision/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { fetchSearchDecisions } from "../../redux/decision/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalDecisionUpdate from "./modals/ModalDecisionUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/ViewAgenda";
import ModalDecisionCreate from "./modals/ModalDecisionCreate";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import AddIcon from "@mui/icons-material/InputTwoTone";
import PlusIcon from "@mui/icons-material/Add";
import EditComponentIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalDecisionAssetInputFieldMappingCreate from "../decisionAssetInputFieldMapping/modals/ModalDecisionAssetInputFieldMappingCreate";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import { useFetchDecisionAssetInputFieldMappingsPageHook } from "../decisionAssetInputFieldMapping/Hooks";
import DecisionAssetInputFieldMappingsRenderer from "../decisionAssetInputFieldMapping/DecisionAssetInputFieldMappingsRenderer";
import DiscussionsIcon from "../discussion/DiscussionIcon";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import ModelBuilderNewDecision from "./ModelBuilderNewDecision";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { EmptyGuid } from "../../utilities/Helpers";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .decisionWrapper": {
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
  },
  "& .decisionMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(3),
  },
  "& .decisionDivider": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  "& .decisionDescription": {
    whiteSpace: "pre-line",
  },
  "& .orangeDraft": {
    color: "orange",
  },
  "& .MuiAccordionSummary-content": {
    margin: "8px 0",
    "&..Mui-expanded": {
      margin: "8px 0",
    },
  },
  "& .header": {
    fontWeight: "bold",
  },
  "& .headerBar": {
    display: "flex",
    justifyContent: "space-between",
    gap: 8,
    marginLeft: "auto",
    marginRight: 4,
  },
  "& .headerLeft": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 4,
  },
}));

type IDecisionModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
};

const DecisionModelBuilderTab = ({ modelId, canEdit }: IDecisionModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const decisions = useSelector((store: RootState) => selectorGetDecisionsByModelId(store, modelId));

  const { fetching: fetchingDecisions } = useFetchDecisionsPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingDecisionInputMappings } = useFetchDecisionAssetInputFieldMappingsPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const onUploadComplete = async (decisionId: string) => {
    await dispatch(fetchSearchDecisions({ decisionId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <DecisionModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      decisions={decisions}
      loading={fetchingDecisionInputMappings || fetchingDecisions}
      onUploadComplete={onUploadComplete}
    />
  );
};

type IDecisionModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  decisions: IDecision[];
  loading: boolean;
  onUploadComplete(decisionId: string): void;
};

const DecisionModelBuilderTabDisplay = ({
  model,
  decisions,
  loading,
  canEdit,
  onUploadComplete,
}: IDecisionModelBuilderTabDisplayProps) => {
  const [showCreateDecisionModal, setShowCreateDecisionModal] = useState(false);
  const [decisionToUpdate, setDecisionToUpdate] = useState<IDecision>();

  function toggleShowCreateDecisionModal() {
    setShowCreateDecisionModal(!showCreateDecisionModal);
  }

  function onDecisionClick(decision: IDecision) {
    setDecisionToUpdate(decision);
  }

  function hideDecisionUpdate() {
    setDecisionToUpdate(undefined);
  }

  if (!model) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <DivWrapper>
      <ModelBuilderNewDecision model={model} />

      {loading ? (
        <LoaderAbsoluteCentred loading={loading} />
      ) : (
        <AnimationWrapper>
          <div>
            {decisions.map((decision) => (
              <ModelBuilderDecision
                decision={decision}
                canEdit={canEdit}
                onUploadComplete={onUploadComplete}
                onDecisionClick={onDecisionClick}
              />
            ))}
            {(!decisions || !decisions.length) && (
              <WidgetNoResultsPlaceholder text="No decisions" icon={PlaceholderIcon} flip={true} />
            )}
          </div>
        </AnimationWrapper>
      )}

      <ModalDecisionCreate
        open={showCreateDecisionModal}
        onCancelCallback={toggleShowCreateDecisionModal}
        onCompleteCallback={toggleShowCreateDecisionModal}
        modelId={model.modelId}
      />

      {/* Update */}
      {decisionToUpdate && (
        <ModalDecisionUpdate
          open={decisionToUpdate !== undefined}
          onCancelCallback={hideDecisionUpdate}
          onCompleteCallback={hideDecisionUpdate}
          decision={decisionToUpdate}
          canEdit={canEdit}
        />
      )}
    </DivWrapper>
  );
};

interface IModelBuilderDecisionProps {
  decision: IDecision;
  canEdit: boolean;
  onUploadComplete(decisionId: string): void;
  onDecisionClick(decision: IDecision): void;
}

function ModelBuilderDecision({ decision, onDecisionClick, canEdit }: IModelBuilderDecisionProps) {
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showAddMappingModal, setShowAddMappingModal] = useState(false);
  const { modelId, decisionId } = decision;
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));

  async function onDecisionClickWrapper() {
    setMenuAnchorEl(undefined);
    onDecisionClick(decision);
  }

  async function toggleShowAddMappingModal() {
    setShowAddMappingModal(!showAddMappingModal);
    setMenuAnchorEl(undefined);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          <div className="headerLeft">
            {decision.status === ModelStatusEnum.Draft ? (
              <Typography className="orangeDraft">{decision.name} (Draft)</Typography>
            ) : (
              <Typography className="header">
                {decision.name} - {DecisionActionTypeEnum[decision.actionType]}
              </Typography>
            )}
            <IdentifierMappingsRenderer
              type={IdentifierMappingTypeEnum.Decision}
              targetId={String(decision.decisionId)}
              modelId={decision.modelId}
              canEdit={canEdit}
              loading={false}
              showValues={true}
              secondaryTargetId={EmptyGuid}
            />
          </div>
          <div className="headerBar" onClick={(e) => e.stopPropagation()}>
            {canEdit ? (
              <>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={() => setMenuAnchorEl(undefined)}
                >
                  <MenuItem onClick={toggleShowAddMappingModal}>
                    <ListItemIcon>
                      <AddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Link input field" />
                  </MenuItem>
                  <MenuItem onClick={onDecisionClickWrapper}>
                    <ListItemIcon>
                      <EditComponentIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={isAdmin ? "Edit Cause" : "Suggest cause edits"} />
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className="decisionWrapper">
            <Grid item xs={12} md={6}>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    marginBottom: 4,
                  }}
                >
                  <Typography variant="overline" color="textSecondary">
                    Input fields
                  </Typography>
                  <IconButton onClick={toggleShowAddMappingModal} size="small">
                    <PlusIcon />
                  </IconButton>
                </div>
                <DecisionAssetInputFieldMappingsRenderer
                  decisionId={decision.decisionId}
                  modelId={modelId}
                  canEdit={canEdit}
                  loading={loading}
                />
              </div>
            </Grid>
            <Grid item xs={10} md={5}>
              <div className="decisionMainWrapper">
                <div>
                  <Typography variant="overline" color="textSecondary">
                    Logic
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className="decisionDescription">
                    {decision.description}
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={2} md={1} style={{ textAlign: "right" }}>
              <DiscussionsIcon
                discussionCount={decision.discussionCount}
                entityId={decision.decisionId}
                entityType={EntityTypeEnum.Decision}
                canDiscuss={false}
              />
            </Grid>
            <LoaderAbsoluteCentred loading={loading} />
            <ModalDecisionAssetInputFieldMappingCreate
              open={showAddMappingModal}
              onCancelCallback={toggleShowAddMappingModal}
              modelId={modelId}
              decisionId={decisionId}
              onCompleteCallback={toggleShowAddMappingModal}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default DecisionModelBuilderTab;
