import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchTermsAgreementsProps, fetchSearchTermsAgreements } from "../../redux/termsAgreement/actions";
import { ITermsAgreement } from "../../utilities/types/TermsAgreement";


interface IUseFetchTermsAgreementsPageHookProps extends IFetchSearchTermsAgreementsProps {
    minPageNumberToFetch: number;
}


export const useFetchTermsAgreementsPageHook = ({ pageNumber, userId, minPageNumberToFetch, pageSize, termsAgreementId }: IUseFetchTermsAgreementsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ITermsAgreement[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ITermsAgreement[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                var termsAgreements = await dispatch(fetchSearchTermsAgreements({
                    pageSize,
                    pageNumber,
                    userId,
                })) as unknown as ITermsAgreement[];

                if (termsAgreements && termsAgreements.length) {
                    setMorePages(termsAgreements.length >= pageSize)
                    setLastResultSet(termsAgreements);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, termsAgreementId, userId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.termsAgreementId === x.termsAgreementId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
        
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}