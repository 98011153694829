import { RootState } from "..";
import { ISolverInputField } from "../../utilities/types/SolverInputField";


export function selectorGetSolverInputFieldsBySolverId(state: RootState, solverId: string) : ISolverInputField[] {

    if (!state.solverInputFields || !state.solverInputFields.byId) return [];

    return sortSolverInputFieldsByOrderNumberAscending(Object.values(state.solverInputFields.byId).filter(x => x.solverId === solverId));
}

export function selectorGetSolverInputFieldById(state: RootState, solverInputFieldId: string) : ISolverInputField | undefined {

    if (!state.solverInputFields || !state.solverInputFields.byId) return undefined;

    return state.solverInputFields.byId[solverInputFieldId];
}


export function selectorGetSolverInputFields(state: RootState) {
    
    if (!state.solverInputFields || !state.solverInputFields.byId) return [];

    return Object.values(state.solverInputFields.byId);
}


export function selectorGetSolverInputFieldTableNamesBySolverId(state: RootState, solverId: string){
    
    var solverInputFields = selectorGetSolverInputFieldsBySolverId(state, solverId);
    var tableNames = solverInputFields.filter(x => x.tableName && x.tableName !== '').map(x => x.tableName);
    var distinctTableNames = new Set(tableNames);

    return Array.from(distinctTableNames.values());
}


// function sortSolverInputFieldsByOrderNumberDescending(solverInputFields: ISolverInputField[]) {
//     return solverInputFields.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortSolverInputFieldsByOrderNumberAscending(solverInputFields: ISolverInputField[]) {
    return solverInputFields.sort((a, b) => a.orderNumber - b.orderNumber);
}