import React, { useState } from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { Formik } from "formik";

import { getFormikFieldProps } from "../../../utilities/Helpers";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { bucketValidationSchema, NODE_TYPES } from "../constants";
import { IBucketDetails, IBucketFormValues } from "../types";

interface FormAddUpdateBucketProps {
  bucketDetails: IBucketDetails;
  onCancelCallback(): void;
  onCompleteCallback(bucketPath: string, nodeType: NODE_TYPES, nodeId?: string): void;
  onDelete(nodeId: string, nodeType: NODE_TYPES): void;
}

const FormAddUpdateBucket: React.FC<FormAddUpdateBucketProps> = ({
  bucketDetails: { isEditMode, nodeId = "", bucketPath = "" },
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  async function deleteHandler() {
    setShowDeleteConfirmation(false);
    onDelete(nodeId, NODE_TYPES.BUCKET);
  }

  const handleSubmit = async (values: IBucketFormValues) => {
    if (isEditMode) {
      onCompleteCallback(values.bucketPath, NODE_TYPES.BUCKET, nodeId);
      return;
    }
    onCompleteCallback(values.bucketPath, NODE_TYPES.BUCKET);
  };

  return (
    <Formik initialValues={{ bucketPath }} validationSchema={bucketValidationSchema} onSubmit={handleSubmit}>
      {(props) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              onChange={props.handleChange}
              {...getFormikFieldProps(props, "bucketPath", "Path")}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider light={true} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {isEditMode && (
              <Button
                color="secondary"
                disabled={props.isSubmitting}
                style={{ marginRight: 16 }}
                variant="contained"
                onClick={toggleDeleteConfirmation}
              >
                Delete
              </Button>
            )}
            <Button disabled={props.isSubmitting} variant="text" style={{ marginRight: 16 }} onClick={onCancelCallback}>
              Close
            </Button>
            <Button disabled={props.isSubmitting} variant="outlined" color="primary" onClick={props.submitForm}>
              {isEditMode ? "Update" : "Add"}
            </Button>
          </Grid>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
          <WidgetModalConfirmationDialog
            open={showDeleteConfirmation}
            title="Delete bucket"
            subtitle="Confirm bucket delete"
            description="Are you sure that you'd like to remove this bucket?"
            onCancelCallback={toggleDeleteConfirmation}
            onConfirmCallback={deleteHandler}
            confirmButtonText="Delete"
          />
        </Grid>
      )}
    </Formik>
  );
};

export default FormAddUpdateBucket;
