import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button, Box, styled } from "@mui/material";

export interface IModalJobProgressLogProps {
  open: boolean;
  progressLog: string;
  onCancelCallback(): void;
}

const DivWrapper = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  minHeight: 90,
  whiteSpace: "pre-line",
  border: "1px solid #EEE",
  color: "rgba(0,0,0,0.5)",
  maxHeight: 200,
  overflowY: "auto",
}));

function ModalJobProgressLog({ onCancelCallback, open, progressLog }: IModalJobProgressLogProps) {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Progress Log"}
      subtitle=""
      headerIcon={<DescriptionIcon />}
      disableBackdropClick={false}
    >
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            gap: 2,
          }}
        >
          <DivWrapper className="progressLog">{progressLog ? progressLog : "No messages yet."}</DivWrapper>
          <Button variant="text" onClick={onCancelCallback} style={{ marginLeft: "auto" }}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalJobProgressLog;
