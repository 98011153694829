import { RootState } from "..";
import { ITaskImpact } from "../../utilities/types/TaskImpact";


export function selectorGetTaskImpactsByModelId(state: RootState, modelId: string) : ITaskImpact[] {

    if (!state.taskImpacts || !state.taskImpacts.byId) return [];

    return sortTaskImpactsByOrderNumberAscending(Object.values(state.taskImpacts.byId).filter(x => x.modelId === modelId));
}


export function selectorGetTaskImpactsByTaskId(state: RootState, taskId: string) : ITaskImpact[] {

    if (!state.taskImpacts || !state.taskImpacts.byId) return [];

    return sortTaskImpactsByOrderNumberAscending(Object.values(state.taskImpacts.byId).filter(x => x.taskId === taskId));
}


export function selectorGetTaskImpactById(state: RootState, taskImpactId: string) : ITaskImpact | undefined {

    if (!state.taskImpacts || !state.taskImpacts.byId) return undefined;

    return state.taskImpacts.byId[taskImpactId];
}


// function sortTaskImpactsByOrderNumberDescending(taskImpacts: ITaskImpact[]) {
//     return taskImpacts.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortTaskImpactsByOrderNumberAscending(taskImpacts: ITaskImpact[]) {
    return taskImpacts.sort((a, b) => a.orderNumber - b.orderNumber);
}