import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormFeedbackCreate from "../forms/FormFeedbackCreate";
import { IFeedback, FeedbackTypeEnum } from "../../../utilities/types/Feedback";
import MapIcon from "@mui/icons-material/MapRounded";

export interface IModalFeedbackCreateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(feedback?: IFeedback): void;
  titleText: string | undefined;
  subtitleText: string | undefined;
  feedbackType: FeedbackTypeEnum | undefined;
  feedbackText: string | undefined;
}

function ModalFeedbackCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  titleText,
  subtitleText,
  feedbackType,
  feedbackText,
}: IModalFeedbackCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={titleText ?? "Send us your feedback!"}
      subtitle={subtitleText ?? "Suggestions, bug reports or feedback."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormFeedbackCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFeedbackCreate;
