import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/SmsFailedRounded";
import FormFunctionalFailureUpdate from "../forms/FormFunctionalFailureUpdate";
import { IFunctionalFailure } from "../../../utilities/types/FunctionalFailure";

export interface IModalFunctionalFailureUpdateProps {
  open: boolean;
  functionalFailure: IFunctionalFailure;
  onCancelCallback(): void;
  onCompleteCallback(functionalFailure?: IFunctionalFailure): void;
  canEdit: boolean;
}

function ModalFunctionalFailureUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  functionalFailure,
}: IModalFunctionalFailureUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a functional failure"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormFunctionalFailureUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          functionalFailure={functionalFailure}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFunctionalFailureUpdate;
