import HeaderIcon from "@mui/icons-material/AccountTree";

import React from "react";
import { ISolver } from "../../utilities/types/Solver";

import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import SolverInputFieldsList from "../solverInputField/SolverInputFieldsList";
import { Typography, styled } from "@mui/material";

const DivWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  height: 350,
  overflowY: "scroll",
  overflowX: "hidden",
}));

type SolverDetailsSolverInputsDisplayProps = {
  solver?: ISolver;
  canEdit: boolean;
};

const SolverDetailsSolverInputsDisplay = ({ solver, canEdit }: SolverDetailsSolverInputsDisplayProps) => {
  if (!solver) {
    return <WidgetNoResultsPlaceholder text="Solver not found" fade={true} icon={HeaderIcon} />;
  }

  return (
    <div style={{ padding: "0 16px" }}>
      <Typography variant="body1" component="p">
        Solver Input Fields
      </Typography>
      <DivWrapper>
        <SolverInputFieldsList solverId={solver.solverId} canEdit={canEdit} />
      </DivWrapper>
    </div>
  );
};

export default SolverDetailsSolverInputsDisplay;
