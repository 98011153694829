import { TextField } from "@mui/material";
import React, { useState } from "react";
import { DEFAULT_DECIMAL_PLACES, useDecimalValue } from "../../utilities/inputFields/numberFieldUtils";
import { ISolverInputField } from "../../utilities/types/SolverInputField";
import { TSolverInputFieldValue } from "../../utilities/types/SolverJob";

interface ISolverInputFieldDisplayNumberProps {
  solverInputField: ISolverInputField;
  disabled?: boolean;
  onValueChangeCallback(fieldId: string, value: TSolverInputFieldValue): void;
  overriddenDefaultValue?: TSolverInputFieldValue;
}

function SolverInputFieldDisplayNumber({
  solverInputField,
  overriddenDefaultValue,
  onValueChangeCallback,
  disabled = false,
}: ISolverInputFieldDisplayNumberProps) {
  const defaultValue = overriddenDefaultValue?.value || solverInputField.defaultValue;
  const decimalPlaces = solverInputField.decimalPlaces || DEFAULT_DECIMAL_PLACES;

  const [error, setError] = useState("");
  const [value, setValue] = useDecimalValue(defaultValue, decimalPlaces);

  function changeValue(e: any) {
    if (isNaN(e.target.value)) {
      setError("A valid number is required.");
      return;
    }

    setValue(e.target.value); // Allow empty values

    var value = Number(e.target.value);
    if (value < solverInputField.minValue || value > solverInputField.maxValue) {
      setError(`Must be a value between ${solverInputField.minValue} and ${solverInputField.maxValue}`);
      return;
    }

    setError("");
    onValueChangeCallback(solverInputField.solverInputFieldId, { value });
  }

  return (
    <TextField
      id={`txt-${solverInputField.solverInputFieldId}`}
      type="number"
      label={solverInputField.label}
      value={value}
      inputProps={{
        min: solverInputField.minValue,
        max: solverInputField.maxValue,
        step: "any",
      }}
      onChange={changeValue}
      error={error !== ""}
      helperText={error}
      fullWidth
      variant="standard"
      {...(disabled === true ? { disabled } : null)}
    />
  );
}

export default SolverInputFieldDisplayNumber;
