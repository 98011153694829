import { RootState } from "..";
import { IAssetInputFieldCategory } from "../../utilities/types/AssetInputFieldCategory";


export function selectorGetAssetInputFieldCategoriesByModelId(state: RootState, modelId: string) : IAssetInputFieldCategory[] {

    if (!state.assetInputFieldCategories || !state.assetInputFieldCategories.byId) return [];

    return sortAssetInputFieldCategoriesByOrderNumberAscending(Object.values(state.assetInputFieldCategories.byId).filter(x => x.modelId === modelId));
}


export function selectorGetAssetInputFieldCategoryById(state: RootState, assetInputFieldCategoryId: string) : IAssetInputFieldCategory | undefined {

    if (!state.assetInputFieldCategories || !state.assetInputFieldCategories.byId) return undefined;

    return state.assetInputFieldCategories.byId[assetInputFieldCategoryId];
}


// function sortAssetInputFieldCategoriesByCreatedDescending(assetInputFieldCategories: IAssetInputFieldCategory[]) {
//     return assetInputFieldCategories.sort((a, b) => a.created.getTime() - b.created.getTime());
// }


// function sortAssetInputFieldCategoriesByOrderNumberDescending(assetInputFieldCategories: IAssetInputFieldCategory[]) {
//     return assetInputFieldCategories.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortAssetInputFieldCategoriesByOrderNumberAscending(assetInputFieldCategories: IAssetInputFieldCategory[]) {
    return assetInputFieldCategories.sort((a, b) => a.orderNumber - b.orderNumber);
}