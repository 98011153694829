import moment from "moment";
import { useCallback, useState, useEffect } from "react";

type DateTime = Date | string | undefined;
type DateString = string | undefined;

export function useUpdateSplitDateTime(
  defaultDateTime: DateTime,
  onChange: (d: Date) => void
): [DateString, DateString, (d: DateString) => void, (t: DateString) => void, string] {
  const [error, setError] = useState("");
  const [dateTime, setDateTimeRaw] = useState(toUTC(defaultDateTime || new Date()));

  useEffect(() => {
    if (!defaultDateTime) {
      onChange(new Date(dateTime));
    }
  }, []);

  const setDateTime = useCallback(
    (date: Date) => {
      if (!date || isNaN(date.getUTCDate())) {
        setError("Invalid date");
      } else {
        setError("");
        setDateTimeRaw(date);
        onChange(date);
      }
    },
    [onChange]
  );

  const mergeDateTime = useCallback(
    (date?: DateString, time?: DateString) => {
      const _date = date || toDate(dateTime);
      const _time = time || toTime(dateTime);
      setDateTime(toDateTime(_date, _time));
    },
    [dateTime, setDateTime]
  );

  const [date, time] = useSplitDateTime(dateTime);
  const setDate = useCallback((date: DateString) => mergeDateTime(date), [mergeDateTime]);
  const setTime = useCallback((time: DateString) => mergeDateTime(undefined, time), [mergeDateTime]);

  return [date, time, setDate, setTime, error];
}

function toUTC<T extends DateTime>(datetime: T): T {
  if (datetime && typeof datetime === "string") {
    const dateInLocalTimezone = moment(datetime);
    const dateInUTC = dateInLocalTimezone.utc();
    const dateInUTCString = dateInUTC.toISOString();
    return dateInUTCString as T;
  }

  return datetime;
}

/** Splits the server model 'DateTime' field into separate date and time values for the form.
 * There's no decent native combined 'date time' component so we must split the value into two.
 * @see https://v4.mui.com/components/pickers/
 */

//Does this on display. Seems to work correctly.
export function useSplitDateTime(dateTime: DateTime): [DateString, DateString, DateTime] {
  const _dateTime = dateTime ? toUTC(dateTime) : new Date().toISOString();
  const defaultDate = "01/01/1900";
  const defaultTime = "12:00";

  const localDate = moment.utc(_dateTime).local().format("YYYY-MM-DD"); // Change format as needed
  const localTime = moment.utc(_dateTime).local().format("HH:mm"); // Change format as needed

  // Check if the date and time are valid
  const date = localDate !== "Invalid date" ? localDate : defaultDate;
  const time = localTime !== "Invalid date" ? localTime : defaultTime;
  const dateTimeValue = _dateTime;

  return [date, time, dateTimeValue];
}

/** Returns a date string like '2020-01-31'. */
function toDate(dateTime: DateTime): DateString {
  if (dateTime) {
    const momentDate = moment(dateTime, moment.ISO_8601, true); // Parsing the input date using ISO 8601 format
    if (momentDate.isValid()) {
      return momentDate.format("YYYY-MM-DD");
    }
  }
  return undefined;
}
/** Returns a time string like '09:00:00'. */
function toTime(dateTime: DateTime): DateString {
  if (dateTime) {
    const formattedDateTime = moment(dateTime);
    if (formattedDateTime.isValid()) {
      return formattedDateTime.format("HH:mm");
    }
  }
  return undefined;
}

export function toDateTime(date: DateString, time: DateString): Date {
  if (time === "NaN:NaN" || time === undefined) {
    time = "00:00";
  }
  if (date === "") {
    date = "01/02/2003";
  }
  return new Date(`${date}T${time}`);
}
