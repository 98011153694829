import { IPlan } from "../../utilities/types/Plan";


export const ACTIONS_PLAN = {
    INVALIDATE: 'INVALIDATE_PLANS',
    RECEIVE: 'RECEIVE_PLANS',
    DELETE: 'DELETE_PLAN',
    UPDATE: 'UPDATE_PLANS',
}

interface ActionPlansInvalidate {
    type: typeof ACTIONS_PLAN.INVALIDATE,
    byId: { [key: string]: IPlan },
}

interface ActionPlansReceive {
    type: typeof ACTIONS_PLAN.RECEIVE
    byId: { [key: string]: IPlan },
}

export interface PlanState {
    byId: { [key: string]: IPlan },
}

export enum PlanSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesPlan = ActionPlansReceive | ActionPlansInvalidate;