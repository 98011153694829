import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/EventTwoTone";
import FormEventUpdate from "../forms/FormEventUpdate";
import { IEvent } from "../../../utilities/types/Event";

export interface IModalEventUpdateProps {
  open: boolean;
  event: IEvent;
  onCancelCallback(): void;
  onCompleteCallback(event?: IEvent): void;
  canEdit: boolean;
}

function ModalEventUpdate({ onCancelCallback, open, onCompleteCallback, event, canEdit }: IModalEventUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update event"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormEventUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          event={event}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalEventUpdate;
