import { Button, Grid } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import React from "react";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";

import { RootState } from "../../../redux";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { ITaskApplicability } from "../../../utilities/types/TaskApplicability";
import { fetchCreateTaskApplicability } from "../../../redux/taskApplicability/actions";
import { LinkModeTypeEnum, LinkToModesTable } from "../../component/linkToModesTable";

interface FormValues {
  failureModeIds: string[];
  detectionProbability: number;
  pfInterval: number;
  orderNumber: number;
  stepDescription: string;
  subsequentEventId?: string;
  stepLabour: number;
  acceptableLimit: string;
  duration: number;
  labourQuantity: number;
}

interface FormProps {
  taskId: string;
  modelId: string;
  onCompleteCallback(taskApplicabilityMapping?: ITaskApplicability[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ITaskApplicability[], AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = ({
  setFieldValue,
  modelId,
  values,
  errors,
  isSubmitting,
  handleSubmit,
  onCancelCallback,
}) => {
  const error = errors["failureModeIds"] ?? "";

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LinkToModesTable
            modelId={modelId}
            fieldName="failureModeIds"
            setFieldValue={setFieldValue}
            error={error as string}
            targetIds={values.failureModeIds}
            showSelection={false}
            showCheckboxCondition={(rowData) => rowData.showCheckbox}
            specificType={LinkModeTypeEnum.FailureMode}
          />
        </Grid>

        <Grid container item xs={12}>
          <div style={{ flex: "1" }} />
          <Button disabled={isSubmitting} variant="text" onClick={onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary">
            Add
          </Button>
          <LoaderAbsoluteCentred loading={isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormApplicabilityMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    modelId: "",
    failureModeIds: [],
    taskId: "",
    detectionProbability: 1,
    pfInterval: 8760,
    orderNumber: 0,
    stepDescription: "",
    stepLabour: 0,
    labourQuantity: 1,
    duration: 0,
    acceptableLimit: "",
  }),
  validationSchema: Yup.object().shape({
    failureModeIds: Yup.string().label("Failure Modes").required("Please provide at least one failure mode"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, taskId } = props;

    // Map dispatch via props
    var createdTaskApplicabilityMapping = await dispatch(
      fetchCreateTaskApplicability({
        ...values,
        taskId,
      })
    );

    setSubmitting(false);

    if (createdTaskApplicabilityMapping) onCompleteCallback(createdTaskApplicabilityMapping);
  },
})(InnerForm);

export default FormApplicabilityMappingCreate;
