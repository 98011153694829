import { IconButton, styled } from "@mui/material";
import { useState } from "react";
import { Handle, Position } from "reactflow";
import UploadIcon from "@mui/icons-material/CloudUpload";
import ModalFileUpload from "../../file/modals/ModalFileUpload";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { GetUserId } from "../../../utilities/ApiUtils";
import { IFile } from "../../../utilities/types/File";
import { INodeData } from "../types";

const CustomNodeDiv = styled("div")({
  padding: "10px",
  borderRadius: "3px",
  width: "150px",
  fontSize: "12px",
  color: "#222",
  textAlign: "center",
  backgroundColor: "white",
  border: "1px solid #1a192b",
  minWidth: 150,
  wordBreak: "break-word",
  ".node-wrapper": {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    flexWrap: "wrap",
    "> label": {
      flex: 1,
    },
  },
});

interface CustomBucketNodeProps {
  data: INodeData;
  clientBaseBucket?: string;
}

export function CustomBucketNode({ data, clientBaseBucket }: CustomBucketNodeProps) {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const toggleOpen = () => setUploadModalOpen((prev) => !prev);

  const onUploadCompleteCallback = (file?: IFile) => {
    if (file) {
      toggleOpen();
    }
  };
  return (
    <CustomNodeDiv>
      <Handle type="target" position={Position.Left} />
      <div className="node-wrapper">
        <label htmlFor="text">{data.label}</label>
        <IconButton onClick={toggleOpen} color="primary" aria-label="Upload" className="uploadButton" size="medium">
          <UploadIcon />
        </IconButton>

        <ModalFileUpload
          handleCancel={toggleOpen}
          open={uploadModalOpen}
          entityId={GetUserId()}
          entityType={EntityTypeEnum.BulkAssetUpload}
          onUploadCompleteCallback={onUploadCompleteCallback}
          canSpecifyPath={true}
          defaultFileBucketPath={clientBaseBucket +"/"+ data.bucketPath}
        />
      </div>
      <Handle type="source" position={Position.Right} />
    </CustomNodeDiv>
  );
}
