import { IFailureMode } from "../../utilities/types/FailureMode";


export const ACTIONS_FAILURE_MODE = {
    INVALIDATE: 'INVALIDATE_FAILURE_MODES',
    RECEIVE: 'RECEIVE_FAILURE_MODES',
    DELETE: 'DELETE_FAILURE_MODE',
    UPDATE: 'UPDATE_FAILURE_MODES',
}

interface ActionFailureModesInvalidate {
    type: typeof ACTIONS_FAILURE_MODE.INVALIDATE,
    byId: { [key: string]: IFailureMode },
}

interface ActionFailureModesReceive {
    type: typeof ACTIONS_FAILURE_MODE.RECEIVE
    byId: { [key: string]: IFailureMode },
}

export interface FailureModeState {
    byId: { [key: string]: IFailureMode },
}

export enum FailureModeSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesFailureMode = ActionFailureModesReceive | ActionFailureModesInvalidate;