import React from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IIdentifier } from "../../../utilities/types/Identifier";
import { fetchCreateIdentifier } from "../../../redux/identifier/actions";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  modelId?: string;
  name: string[];
  code: string;
  orderNumber: number;
  color: string;
}

interface FormProps {
  modelId?: string;
  onCompleteCallback(identifier?: IIdentifier): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IIdentifier, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StringListInput label="Name(s)" onStringListChange={handleNameChange} stringList={values.name} errors={props.errors.name} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "color", "Color")}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormIdentifierCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: [],
    code: "",
    orderNumber: 0,
    color: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Name")
        .min(3, "Please input 3 characters or more")
        .max(500, "Please input 500 characters or less")
    ),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const identifiersToCreate = values.name.map((name) => ({
      modelId, // Assuming modelId is obtained from some variable
      name,
      orderNumber: values.orderNumber,
      color: values.color,
      code: values.code,
    }));

    // Map dispatch via props
    var createdIdentifier = await dispatch(fetchCreateIdentifier({ identifiers: identifiersToCreate }));

    setSubmitting(false);

    if (createdIdentifier) onCompleteCallback(createdIdentifier);
  },
})(InnerForm);

export default FormIdentifierCreate;
