import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import { IComponent } from "../../../utilities/types/Component";
import FormSelectAiSuggest from "../forms/FormSelectAiSuggest";

export interface ModalAiSuggestComponentProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  onCompleteCallback(component?: IComponent): void;
}

function ModalAiSuggestComponent({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
}: ModalAiSuggestComponentProps) {
  return (
    <WidgetModalBase
      style={{ maxWidth: 900, width: "100%" }}
      handleCancel={onCancelCallback}
      open={open}
      title="AI Suggestions (Beta)"
      subtitle=""
      headerIcon={<ArtTrackIcon />}
    >
      <DialogContent>
        <FormSelectAiSuggest
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalAiSuggestComponent;
