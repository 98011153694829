import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";
import PageSingleAssetOutputSearch from "../components/singleAssetOutput/pages/PageSingleAssetOutputSearch";
import RouteSummaryOutput from "./RouteSummaryOutput";

const RouteSummaryOutputs = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:summaryOutputId`}>
        <RouteSummaryOutput />
      </Route>
      <Route
        path={`/summaryOutputs`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageSingleAssetOutputSearch {...props} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteSummaryOutputs;
