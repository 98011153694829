import { ACTIONS_SOLVER, SolverSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ISolver, SolverStatus } from '../../utilities/types/Solver';
import { receiveSolverInputFields } from '../solverInputField/actions';
import { receiveSolverInputFieldListValues } from '../solverInputFieldListValue/actions';
import { selectorGetSolverById } from './selectors';


export const receiveSolvers = (solvers: ISolver[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolver } = {};

    for (var i = 0; i < solvers.length; i++) {
        byIdObjectToDispatch[solvers[i].solverId] = solvers[i];
    }

    return {
        type: ACTIONS_SOLVER.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolver = (solver: ISolver) => ({
    type: ACTIONS_SOLVER.DELETE,
    byId: { [solver.solverId]: solver }
});


export interface IFetchCreateSolverProps {
    name: string,
    description: string,
    inputBucket: string,
    outputBucket: string,
    summaryOutputBucket: string,
    status: SolverStatus,
    solverCost: number,
}


export const fetchCreateSolver = (solverToCreate: IFetchCreateSolverProps): AppThunk<Promise<ISolver>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solvers`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(solverToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solver) {
            dispatch(receiveSolvers([parsedResp.data.solver]));
            return parsedResp.data.solver;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating solver.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating solver.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSolverProps {
    solverId: string,
    name: string,
    inputBucket: string,
    outputBucket: string,
    summaryOutputBucket: string,
    description: string,
}


export const fetchUpdateSolver = (props: IFetchUpdateSolverProps): AppThunk<Promise<ISolver>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solvers`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solvers) {
            dispatch(receiveSolvers(parsedResp.data.solvers));
            return parsedResp.data.solvers;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating solver.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating solver.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchSolversProps {
    pageNumber: number,
    pageSize: number,
    solverId?: string,
    text?: string,
    createdBy?: string,
    orderType?: SolverSearchOrderTypeEnum,
}


export const fetchSearchSolvers = (searchParams: IFetchSearchSolversProps): AppThunk<Promise<ISolver[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solvers`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solvers) {

            // Remove unmapped image property
            for (var solver of parsedResp.data.solvers) {
                delete solver.mainImage;
            }

            dispatch(receiveSolvers(parsedResp.data.solvers));
            return parsedResp.data.solvers;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching solver.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching solver.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSolverProps {
    solverId: string,
}


export const fetchDeleteSolver = (props: IFetchDeleteSolverProps): AppThunk<Promise<ISolver>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solvers`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solvers && parsedResp.data.solvers.length) {
            dispatch(requestDeleteSolver(parsedResp.data.solvers[0]));
            return parsedResp.data.solvers[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting solver.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting solver.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchImportSolverProps {
    fileId: string,
}


export const fetchImportSolver = (props: IFetchImportSolverProps): AppThunk<Promise<ISolver>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solvers/import`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solver) {
            dispatch(receiveSolvers([parsedResp.data.solver]));
            if(parsedResp.data.assetInputFields) dispatch(receiveSolverInputFields(parsedResp.data.assetInputFields));
            if(parsedResp.data.assetInputFieldListValues) dispatch(receiveSolverInputFieldListValues(parsedResp.data.assetInputFieldListValues));
            
            return parsedResp.data.solver;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while importing solver.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving solver.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export const fetchSolverByIdIfNeeded =
  (solverId: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (!selectorGetSolverById(getState(), solverId)) {
      await dispatch(fetchSearchSolvers({ solverId, pageNumber: 1, pageSize: 1 }));
    }

    return;
  };