import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchSummaryOutputTypeInputFieldsProps, fetchSearchSummaryOutputTypeInputFields } from "../../redux/summaryOutputTypeInputField/actions";
import { ISummaryOutputTypeInputField } from "../../utilities/types/SummaryOutputTypeInputField";


interface IUseFetchSummaryOutputTypeInputFieldsPageHookProps extends IFetchSearchSummaryOutputTypeInputFieldsProps {
    minPageNumberToFetch: number;
}


export const useFetchSummaryOutputTypeInputFieldsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, summaryOutputTypeId }: IUseFetchSummaryOutputTypeInputFieldsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ISummaryOutputTypeInputField[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ISummaryOutputTypeInputField[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var summaryOutputTypeInputFields = await dispatch(fetchSearchSummaryOutputTypeInputFields({
                    pageSize,
                    pageNumber,
                    summaryOutputTypeId,
                })) as unknown as ISummaryOutputTypeInputField[];

                if (summaryOutputTypeInputFields && summaryOutputTypeInputFields.length) {
                    setMorePages(summaryOutputTypeInputFields.length >= pageSize)
                    setLastResultSet(summaryOutputTypeInputFields);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, summaryOutputTypeId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.summaryOutputTypeInputFieldId === x.summaryOutputTypeInputFieldId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}