import React, { useState } from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import HeaderIcon from "@mui/icons-material/ImportExportTwoTone";
import ModalFileUpload from "./modals/ModalFileUpload";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { GetUserId } from "../../utilities/ApiUtils";
import { IFile } from "../../utilities/types/File";


const AdminImportsWidget = () => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const handleUploadModalToggle = () => setUploadModalOpen(!uploadModalOpen);

  const onUploadCompleteCallback = (file?: IFile) => {
    if (file) {
      setUploadModalOpen(false);
    };
  };
  
  return (
    <>
    <WidgetSummaryCountAndButtons
      key="AdminFileUploadWidget"
      headerIcon={<HeaderIcon />}
      captionText="Upload File"
      mainText="File Upload"
      buttons={[
        <Button
          variant="outlined"
          size="small"
          key="btn-browse"
          onClick={handleUploadModalToggle}
        >
          Upload
        </Button>,
      ]}
    />
    <ModalFileUpload
        handleCancel={handleUploadModalToggle}
        open={uploadModalOpen}
        entityId={GetUserId()}
        entityType={EntityTypeEnum.BulkAssetUpload}
        onUploadCompleteCallback={onUploadCompleteCallback}
        canSpecifyPath={true}
      />
    </>
  );
};

export default AdminImportsWidget;
