import React, { useState } from "react";
import { Typography, List, ListItemText, Grid, Button, Divider, styled, ListItemButton } from "@mui/material";
import { ISelectedComponent, useFetchAiSuggestComponentPageHook } from "../Hooks";
import { IComponent } from "../../../utilities/types/Component";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch } from "react-redux";
import { fetchCreateComponent } from "../../../redux/component/actions";
import { ShowMessage } from "../../../utilities/Helpers";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import { AiSuggestedListSkeleton } from "../../generic/skeletons/AiSuggestedListSkeleton";

const ListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: theme.spacing(1),
  "& .listWrapper": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    border: "1px solid #EEE",
    borderRadius: "4px",
    maxHeight: 380,
    height: "100%",
    overflowY: "auto",
  },
  "& .listItem": {
    background: "#FFF",
    border: "1px solid #EEE",
    borderRadius: "4px",
  },
}));

interface FormSelectAiSuggestProps {
  modelId: string;
  onCompleteCallback(): void;
  onCancelCallback(): void;
}

const FormSelectAiSuggest: React.FC<FormSelectAiSuggestProps> = ({ modelId, onCompleteCallback, onCancelCallback }) => {
  const dispatch = useDispatch();
  const { fetching, allResultsSet, setAllResultsSet } = useFetchAiSuggestComponentPageHook({
    pageNumber: 1,
    pageSize: 500,
    minPageNumberToFetch: 1,
    modelId,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<IComponent[]>([]);

  const handleSelect = (item: IComponent, index: number, isFromSelectedItem: boolean) => {
    if (isFromSelectedItem) {
      // Remove the item from the selected list
      const newSelectedItems = selectedItems.filter(
        (selectedItem: IComponent) => selectedItem.componentId !== item.componentId
      );
      setSelectedItems(newSelectedItems);

      const newSuggestedItems = allResultsSet.map((suggestedItem: IComponent, indexKey) =>
        indexKey.toString() === item.componentId ? { ...suggestedItem, selected: false } : suggestedItem
      );

      // Add the item back to the suggested list
      setAllResultsSet(newSuggestedItems); // Add the item back to the suggested list
    } else {
      // Remove the item from the suggested list
      const newSuggestedItems = allResultsSet.map((suggestedItem: IComponent, indexKey) =>
        indexKey === index ? { ...suggestedItem, selected: true } : suggestedItem
      );
      setAllResultsSet(newSuggestedItems);

      // Add the item to the selected list
      setSelectedItems([...selectedItems, { ...item, componentId: index.toString() }]);
    }
  };

  async function handleAdd() {
    if (selectedItems.length === 0) {
      ShowMessage("Please select at least one item from the suggested list.");
      return;
    }
    setSubmitting(true);
    const newList = selectedItems.map((item: IComponent) => ({
      modelId,
      name: item.name,
      description: item.description,
      orderNumber: item.orderNumber,
    }));

    const createdComponent: any = await dispatch(
      fetchCreateComponent({
        components: newList,
      })
    );

    setSubmitting(false);

    if (createdComponent) onCompleteCallback();
  }
  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <ListContainer>
          <Typography variant="body1" component="span">
            Suggested
          </Typography>
          <List component="nav" className="listWrapper">
            {fetching ? (
              <AiSuggestedListSkeleton />
            ) : (
              allResultsSet.map(
                (item: ISelectedComponent, index) =>
                  !item?.selected && (
                    <AnimationWrapper key={`suggested_${index}`}>
                      <ListItemButton className="listItem" onClick={() => handleSelect(item, index, false)}>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </AnimationWrapper>
                  )
              )
            )}
          </List>
        </ListContainer>
      </Grid>

      <Grid item sm={6} xs={12}>
        <div className="listContainer">
          <Typography variant="body1" component="span">
            Selected
          </Typography>
          <List component="nav" className="listWrapper">
            {selectedItems.map((item: IComponent, index) => (
              <AnimationWrapper key={`selected_${index}`}>
                <ListItemButton className="listItem" onClick={() => handleSelect(item, index, true)}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </AnimationWrapper>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button variant="text" disabled={submitting} style={{ marginRight: 16 }} onClick={onCancelCallback}>
          Close
        </Button>
        <Button variant="outlined" disabled={submitting} color="primary" onClick={handleAdd}>
          Add
        </Button>
      </Grid>
      <LoaderAbsoluteCentred loading={submitting} />
    </Grid>
  );
};

export default FormSelectAiSuggest;
