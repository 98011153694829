import React from "react";
import { Grid, Divider, Button } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IAssetInputField, AssetInputFieldTypeEnum } from "../../../utilities/types/AssetInputField";
import { fetchCreateAssetInputField } from "../../../redux/assetInputField/actions";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  modelId: string;
  description: string[];
  defaultValue: number;
  minValue: number;
  maxValue: number;
  orderNumber: number;
  type: AssetInputFieldTypeEnum;
}

interface FormProps {
  modelId: string;
  assetInputFieldCategoryId: string;
  onCompleteCallback(assetInputFields?: IAssetInputField[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IAssetInputField, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, errors, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "description",
        value: newStringList,
      },
    });
  };
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StringListInput
            label="Input Field Name(s)"
            onStringListChange={handleNameChange}
            stringList={values.description}
            errors={errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormAssetInputFieldCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    defaultValue: 0,
    modelId: "",
    assetInputFieldCategoryId: "",
    description: [],
    minValue: 0,
    maxValue: 100,
    orderNumber: 0,
    type: AssetInputFieldTypeEnum.Number,
  }),

  validationSchema: Yup.object().shape({
    description: Yup.array().of(Yup.string().label("Description").min(3, "Please input 3 characters or more").max(500, "Please input 500 characters or less")),
    defaultValue: Yup.number().label("Default Value").max(999999999, "Less than 999999999"),
    minValue: Yup.number().label("Min Value").max(999999999, "Less than 999999999"),
    maxValue: Yup.number().label("Max Value").max(999999999, "Less than 999999999"),
    orderNumber: Yup.number()
      .label("Max Value")
      .min(0, "Zero or more.")
      .required("Please provide an order number")
      .max(999, "Less than 999"),
  }),

  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const inputFieldsToCreate = values.description.map((description) => ({
      modelId, // Assuming modelId is obtained from some variable
      assetInputFieldCategoryId: props.assetInputFieldCategoryId,
      minValue: values.minValue,
      maxValue: values.maxValue,
      orderNumber: values.orderNumber,
      defaultValue: values.defaultValue,
      type: values.type,
      description,
    }));

    // Map dispatch via props
    var createdAssetInputField = await dispatch(
      fetchCreateAssetInputField({
        assetInputFields: inputFieldsToCreate,
      })
    );

    setSubmitting(false);

    if (createdAssetInputField) onCompleteCallback(createdAssetInputField);
  },
})(InnerForm);

export default FormAssetInputFieldCreate;
