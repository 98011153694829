import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchFunctionalFailuresProps, fetchSearchFunctionalFailures } from "../../redux/functionalFailure/actions";
import { IFunctionalFailure } from "../../utilities/types/FunctionalFailure";
import { fetchSearchAiSuggestFunctionalFailure } from "../../redux/aiSuggestFunctionFailure/actions";
import { IFetchSearchAiSuggestFunctionalFailureProps } from "../../redux/aiSuggestFunctionFailure/actions";

interface IUseFetchFunctionalFailuresPageHookProps extends IFetchSearchFunctionalFailuresProps {
  minPageNumberToFetch: number;
}

export const useFetchFunctionalFailuresPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchFunctionalFailuresPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IFunctionalFailure[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IFunctionalFailure[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var functionalFailures = (await dispatch(
          fetchSearchFunctionalFailures({
            pageSize,
            pageNumber,
            modelId,
          })
        )) as unknown as IFunctionalFailure[];

        if (functionalFailures && functionalFailures.length) {
          setMorePages(functionalFailures.length >= pageSize);
          setLastResultSet(functionalFailures);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.functionalFailureId === x.functionalFailureId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};

export interface ISelectedFunctionalFailure extends IFunctionalFailure {
  selected?: boolean;
}

interface IUseFetchAiSuggestFunctionFailurePageHookProps extends IFetchSearchAiSuggestFunctionalFailureProps {
  minPageNumberToFetch: number;
}

export const useFetchAiSuggestFunctionFailuresPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId, componentId, functionId }: IUseFetchAiSuggestFunctionFailurePageHookProps) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IFunctionalFailure[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<ISelectedFunctionalFailure[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        const functionalFailures = (await fetchSearchAiSuggestFunctionalFailure({
          pageSize,
          pageNumber,
          modelId,
          componentId,
          functionId,
        })) as unknown as IFunctionalFailure[];

        if (functionalFailures && functionalFailures.length) {
          setMorePages(functionalFailures.length >= pageSize);
          setLastResultSet(functionalFailures);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, pageNumber, pageSize, modelId, componentId, functionId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.functionId === x.functionId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
