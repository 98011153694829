import React from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import HeaderIcon from "@mui/icons-material/FilterDramaTwoTone";
import { NavLink } from "react-router-dom";
import { GetAdminFeedbackPage } from "../../routes/RouteLinkHelpers";

const AdminManageFeedbackWidget = () => {
  return (
    <WidgetSummaryCountAndButtons
      key="AdminManageFeedbackWidget"
      headerIcon={<HeaderIcon />}
      captionText="Feedback"
      mainText="Feedback"
      buttons={[
        <Button size="small" variant="outlined" color="primary" component={NavLink} to={GetAdminFeedbackPage()}>
          View
        </Button>,
      ]}
    />
  );
};

export default AdminManageFeedbackWidget;
