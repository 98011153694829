import { ACTIONS_SOLVER_INPUT_FIELD_LIST_VALUE, SolverInputFieldListValueSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ISolverInputFieldListValue } from '../../utilities/types/SolverInputFieldListValue';
import { receiveSolverInputFields } from '../solverInputField/actions';


export const receiveSolverInputFieldListValues = (solverInputFieldListValues: ISolverInputFieldListValue[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverInputFieldListValue } = {};

    for (var i = 0; i < solverInputFieldListValues.length; i++) {
        byIdObjectToDispatch[solverInputFieldListValues[i].solverInputFieldListValueId] = solverInputFieldListValues[i];
    }

    return {
        type: ACTIONS_SOLVER_INPUT_FIELD_LIST_VALUE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverInputFieldListValue = (solverInputFieldListValue: ISolverInputFieldListValue) => ({
    type: ACTIONS_SOLVER_INPUT_FIELD_LIST_VALUE.DELETE,
    byId: { [solverInputFieldListValue.solverInputFieldListValueId]: solverInputFieldListValue }
});


export interface IFetchCreateSolverInputFieldListValueProps {
    solverInputFieldId: string,
    displayText: string,
    value: number,
    orderNumber: number,
    isDefault: boolean,
}


export const fetchCreateSolverInputFieldListValue = (solverInputFieldListValueToCreate: IFetchCreateSolverInputFieldListValueProps): AppThunk<Promise<ISolverInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverInputFieldListValues`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(solverInputFieldListValueToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputFieldListValues) {
            dispatch(receiveSolverInputFieldListValues(parsedResp.data.solverInputFieldListValues));
            dispatch(receiveSolverInputFields(parsedResp.data.solverInputFields));
            return parsedResp.data.solverInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while creating field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSolverInputFieldListValueProps {
    solverInputFieldListValueId: string,
    displayText: string,
    value: number,
    orderNumber: number,
}


export const fetchUpdateSolverInputFieldListValue = (props: IFetchUpdateSolverInputFieldListValueProps): AppThunk<Promise<ISolverInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverInputFieldListValues`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputFieldListValues) {
            dispatch(receiveSolverInputFieldListValues(parsedResp.data.solverInputFieldListValues));
            dispatch(receiveSolverInputFields(parsedResp.data.solverInputFields));
            return parsedResp.data.solverInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchSolverInputFieldListValuesProps {
    pageNumber: number,
    pageSize: number,
    text?: string,
    solverInputFieldId?: string,
    solverInputFieldListValueId?: string,
    solverId?: string,
    createdBy?: string,
    orderType?: SolverInputFieldListValueSearchOrderTypeEnum,
}


export const fetchSearchSolverInputFieldListValues = (searchParams: IFetchSearchSolverInputFieldListValuesProps): AppThunk<Promise<ISolverInputFieldListValue[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverInputFieldListValues`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputFieldListValues) {
            dispatch(receiveSolverInputFieldListValues(parsedResp.data.solverInputFieldListValues));
            return parsedResp.data.solverInputFieldListValues;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching field value.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSolverInputFieldListValueProps {
    solverInputFieldListValueId: string,
}


export const fetchDeleteSolverInputFieldListValue = (props: IFetchDeleteSolverInputFieldListValueProps): AppThunk<Promise<ISolverInputFieldListValue>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverInputFieldListValues`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputFieldListValue) {
            dispatch(requestDeleteSolverInputFieldListValue(parsedResp.data.solverInputFieldListValue));
            return parsedResp.data.solverInputFieldListValue;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting field value.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting field value.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}