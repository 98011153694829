import React from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import ModelsIcon from "@mui/icons-material/PersonTwoTone";
import { NavLink } from "react-router-dom";
import { GetAdminUserRolesPage } from "../../routes/RouteLinkHelpers";

const UserRolesManagementWidget = () => {
  return (
    <WidgetSummaryCountAndButtons
      key="AdminUserRolesManagementWidget"
      headerIcon={<ModelsIcon />}
      captionText="User Roles"
      mainText="Manage User Roles"
      buttons={[
        <Button size="small" variant="outlined" color="primary" component={NavLink} to={GetAdminUserRolesPage}>
          Manage
        </Button>,
      ]}
    />
  );
};

export default UserRolesManagementWidget;
