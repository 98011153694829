import React from 'react';
import WidgetSummaryCountAndButtons from '../generic/widgets/summaries/WidgetSummaryCountAndButtons';
import ModelsIcon from '@mui/icons-material/PersonTwoTone';
import IdentifierGlobal from './IdentifierGlobal';


const GlobalIdentifiersWidget = () => {

    return (
        <WidgetSummaryCountAndButtons
            headerIcon={<ModelsIcon />}
            captionText="Global Identifiers"
            mainText="Create and Manage Global Identifiers"
            buttons={
                [
                    <IdentifierGlobal canEdit={true}/>
                ]
            }
        />
    )
}


export default GlobalIdentifiersWidget;