import { IAssetInputField } from "../../utilities/types/AssetInputField";


export const ACTIONS_ASSET_INPUT_FIELD = {
    INVALIDATE: 'INVALIDATE_ASSET_INPUT_FIELDS',
    RECEIVE: 'RECEIVE_ASSET_INPUT_FIELDS',
    DELETE: 'DELETE_ASSET_INPUT_FIELD',
    UPDATE: 'UPDATE_ASSET_INPUT_FIELDS',
}

interface ActionAssetInputFieldsInvalidate {
    type: typeof ACTIONS_ASSET_INPUT_FIELD.INVALIDATE,
    byId: { [key: string]: IAssetInputField },
}

interface ActionAssetInputFieldsReceive {
    type: typeof ACTIONS_ASSET_INPUT_FIELD.RECEIVE
    byId: { [key: string]: IAssetInputField },
}

export interface AssetInputFieldState {
    byId: { [key: string]: IAssetInputField },
}

export enum AssetInputFieldSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
    VersionNumberAsc = 30,
    VersionNumberDesc = 40,
}

export type ActionTypesAssetInputField = ActionAssetInputFieldsReceive | ActionAssetInputFieldsInvalidate;