import { RootState } from "..";
import { IAssetInputFieldListValue } from "../../utilities/types/AssetInputFieldListValue";


export function selectorGetAssetInputFieldListValuesByModelId(state: RootState, modelId: string) : IAssetInputFieldListValue[] {

    if (!state.assetInputFieldListValues || !state.assetInputFieldListValues.byId) return [];

    return sortAssetInputFieldListValuesByOrderNumberAscending(Object.values(state.assetInputFieldListValues.byId).filter(x => x.modelId === modelId));
}


export function selectorGetAssetInputFieldListValuesByAssetInputFieldId(state: RootState, assetInputFieldId: string) : IAssetInputFieldListValue[] {

    if (!state.assetInputFieldListValues || !state.assetInputFieldListValues.byId) return [];

    return sortAssetInputFieldListValuesByOrderNumberAscending(Object.values(state.assetInputFieldListValues.byId).filter(x => x.assetInputFieldId === assetInputFieldId));
}


export function selectorGetAssetInputFieldListValueById(state: RootState, assetInputFieldListValueId: string) : IAssetInputFieldListValue | undefined {

    if (!state.assetInputFieldListValues || !state.assetInputFieldListValues.byId) return undefined;

    return state.assetInputFieldListValues.byId[assetInputFieldListValueId];
}


// function sortAssetInputFieldListValuesByOrderNumberDescending(assetInputFieldListValues: IAssetInputFieldListValue[]) {
//     return assetInputFieldListValues.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortAssetInputFieldListValuesByOrderNumberAscending(assetInputFieldListValues: IAssetInputFieldListValue[]) {
    return assetInputFieldListValues.sort((a, b) => a.orderNumber - b.orderNumber);
}