import { RootState } from "..";
import { ISingleAssetOutput } from "../../utilities/types/SingleAssetOutput";
import { selectorGetSolverOutputTypeMappingsBySolverId } from "../solverOutputTypeMapping/selectors";

export function selectorGetSingleAssetOutputById(
  state: RootState,
  outputTypeId: string
): ISingleAssetOutput | undefined {
  if (!state.singleAssetOutput || !state.singleAssetOutput.byId) return undefined;

  return state.singleAssetOutput.byId[outputTypeId];
}

export function selectorGetSingleAssetOutputs(state: RootState): ISingleAssetOutput[] {
  if (!state.singleAssetOutput || !state.singleAssetOutput.byId) return [];

  return sortSingleAssetOutputsByNameDescending(Object.values(state.singleAssetOutput.byId));
}

export function sortSingleAssetOutputsByNameAsc(singleAssetOutputs: ISingleAssetOutput[]): ISingleAssetOutput[] {
  return singleAssetOutputs.sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1));
}

export function selectorGetSingleAssetOutputsBySolverId(state: RootState, solverId: string): ISingleAssetOutput[] {
  if (
    !state.singleAssetOutput ||
    !state.singleAssetOutput.byId ||
    !state.solverOutputTypeMappings ||
    !state.solverOutputTypeMappings.byId
  )
    return [];

  // Retrieve all mappings that are relevant for this solver
  var mappings = selectorGetSolverOutputTypeMappingsBySolverId(state, solverId);
  if (!mappings.length) return [];

  // Retrieve output types that have been mapped
  var singleAssetOutputs = selectorGetSingleAssetOutputs(state);
  var results = singleAssetOutputs.filter((x) => mappings.some((m) => m.outputTypeId === x.outputTypeId));

  return results;
}

function sortSingleAssetOutputsByNameDescending(singleAssetOutputs: ISingleAssetOutput[]) {
  return singleAssetOutputs.sort((a, b) => {
    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
  });
}
