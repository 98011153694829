import { RootState } from "..";
import { IIdentifierMapping, IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";


export function selectorGetIdentifierMappingsByModelId(state: RootState, modelId: string) : IIdentifierMapping[] {

    if (!state.identifierMappings || !state.identifierMappings.byId) return [];

    return sortIdentifierMappingsByIdDescending(Object.values(state.identifierMappings.byId).filter(x => x.modelId === modelId));
}

export function selectorGetIdentifierMappingsByIdentifierIds(state: RootState, identifierIds: string[]) : IIdentifierMapping[] {

    if (!state.identifierMappings || !state.identifierMappings.byId) return [];

    return sortIdentifierMappingsByIdDescending(Object.values(state.identifierMappings.byId).filter(x =>identifierIds.includes(x.identifierId)));
}

export function selectorGetIdentifierMappingsByTargetAndTypeId(state: RootState, targetId: string, type: IdentifierMappingTypeEnum, secondaryTargetId?: string) : IIdentifierMapping[] {

    if (!state.identifierMappings || !state.identifierMappings.byId) return [];
    if (secondaryTargetId === undefined) return sortIdentifierMappingsByIdDescending(Object.values(state.identifierMappings.byId).filter(x => x.targetId === targetId && x.mappingType === type));
    return sortIdentifierMappingsByIdDescending(Object.values(state.identifierMappings.byId).filter(x => x.targetId === targetId && x.mappingType === type && x.secondaryTargetId === secondaryTargetId));
}


export function selectorGetIdentifierMappingById(state: RootState, identifierMappingId: string) : IIdentifierMapping | undefined {

    if (!state.identifierMappings || !state.identifierMappings.byId) return undefined;

    return state.identifierMappings.byId[identifierMappingId];
}


function sortIdentifierMappingsByIdDescending(identifierMappings: IIdentifierMapping[]) {
    if(!identifierMappings.length) return identifierMappings;
    return identifierMappings.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}

