import { useCallback, useEffect, useMemo, useState } from "react"

type Index<T> = { [_: string]: T }

/** Converts an array to a map.
 * @example
 *      var myArray = [ { id: 1, name: 'A' }, { id: 2, name: 'B' }, { id: 3, name: 'C' } ]
 *      var myMap = arrayToMap(myArray, i => [i.id, i.name])
 *      // -> { 1: A, 2: B, 3: C }
 */
export function easyIndex<I, O>(array: I[], getKeyValue: (item: I) => [string, O]): Index<O> {
    return array.map(getKeyValue)
        .reduce<Index<O>>((map, [key, value]) => ({ ...map, [key]: value }), {})
}

export function useEasyIndexMemo<I, O>(array: I[], getKeyValue: (item: I) => [string, O]): Index<O> {
    // eslint-disable-next-line
    return useMemo(() => easyIndex(array, getKeyValue), [array.length])
}

/** Converts an array to a map via a key.
 * @example
 *      var myArray = [ { id: 1, name: 'A' }, { id: 2, name: 'B' }, { id: 3, name: 'C' } ]
 *      var myMap = arrayToMap(myArray, 'id')
 *      // -> {
 *      //     1: { id: 1, name: 'A' },
 *      //     2: { id: 2, name: 'B' },
 *      //     3: { id: 3, name: 'C' },
 *      // }
 */
export function easyKeyIndex<T>(array: T[], key: keyof T): Index<T> {
    return easyIndex(array, i => [i[key] as any, i])
}

/** Converts an array to a map and caches the results based on the length of the array. */
export function useEasyKeyIndexMemo<T>(array: T[], key: keyof T): Index<T> {
    // eslint-disable-next-line
    return useMemo(() => easyKeyIndex(array, key), [array.length])
}

/** Fires the callback once the deps have stopped changing within the delay period.
 * Use it to fire server calls once the user stops typing for instance.
 * @argument callback: The callback to fire once the deps haven't changed within the period.
 * @argument msDelay: The period to wait until firing the callback. If the deps change in this time then the wait period is reset.
 * @argument deps: The dependencies that must change to trigger the callback to fire
 */
export function useDebounceCallback(callback: () => void, msDelay: number, deps: any[]) {

    // Memoise the callback so it only changes when the custom deps change.
    // If we don't then the callback itself will trigger the useEffect to refresh and cause an infinite loop.
    // eslint-disable-next-line
    const _callback = useCallback(callback, deps)

    useEffect(() => {
        const handler = setTimeout(_callback, msDelay)
        return () => clearTimeout(handler)
        // eslint-disable-next-line
    }, [_callback, msDelay])
}

/** Mimics the useState hook but debounces the value so that it only updates the value hasn't change for the given delay period.
 * Use it to change state that triggers server calls once the user stops typing for instance.
 * @argument initValue: The normal useState init value.
 * @argument msDelay: The period to wait until updating the value. If the callback is fired in this time then the wait period is reset.
 * @returns [the debounced value, the set value callback]
 */
export function useDebounceState<T>(initValue: T, msDelay: number): [T, (_: T) => void] {
    const [valueRaw, setValueRaw] = useState(initValue)
    const [valueDebounce, setValueDebounce] = useState(initValue)
    useDebounceCallback(() => setValueDebounce(valueRaw), msDelay, [valueRaw])
    return [valueDebounce, setValueRaw]
}