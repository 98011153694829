import { ACTIONS_FEEDBACK, FeedbackSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError, ShowMessage } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IFeedback, FeedbackTypeEnum } from '../../utilities/types/Feedback';
import platform from 'platform';
import { MessageTypeEnum } from '../../utilities/types/Message';


export const receiveFeedbacks = (feedbacks: IFeedback[]) => {

    var byIdObjectToDispatch: { [key: string]: IFeedback } = {};

    for (var i = 0; i < feedbacks.length; i++) {
        byIdObjectToDispatch[feedbacks[i].feedbackId] = feedbacks[i];
    }

    return {
        type: ACTIONS_FEEDBACK.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteFeedback = (feedback: IFeedback) => ({
    type: ACTIONS_FEEDBACK.DELETE,
    byId: { [feedback.feedbackId]: feedback }
});


export interface IFetchCreateFeedbackProps {
    type: FeedbackTypeEnum,
    text: string,
    pleaseContact: boolean,
    contributorModelId?: string, // required for contributor feedback types
    successPopupMessage?: string,
}


export const fetchCreateFeedback = (feedbackToCreate: IFetchCreateFeedbackProps): AppThunk<Promise<IFeedback>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);
    var internalProps = {} as IFeedback;

    try {

        // Attempt to retrieve additional properties for debugging
        internalProps.currentUrl = window?.location?.href;
        internalProps.platformDetails = JSON.stringify(platform);
    }
    catch { console.error("Failed to retrieve platform details."); }
    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/feedbacks`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody({
                ...feedbackToCreate,
                ...internalProps,
            }),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.feedbacks) {
            dispatch(receiveFeedbacks(parsedResp.data.feedbacks));
            ShowMessage(feedbackToCreate.successPopupMessage ?? "Feedback sent.", MessageTypeEnum.Success);
            return parsedResp.data.feedbacks[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating feedback.");
                return null;
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating feedback.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchFeedbacksProps {
    pageNumber: number,
    pageSize: number,
    feedbackId?: string,
    text?: string,
    createdBy?: string,
    orderType?: FeedbackSearchOrderTypeEnum,
}


export const fetchSearchFeedbacks = (searchParams: IFetchSearchFeedbacksProps): AppThunk<Promise<IFeedback[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/feedbacks`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.feedbacks) {

            // Remove unmapped image property
            for (var feedback of parsedResp.data.feedbacks) {
                delete feedback.mainImage;
            }

            dispatch(receiveFeedbacks(parsedResp.data.feedbacks));
            return parsedResp.data.feedbacks;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching feedback.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching feedback.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteFeedbackProps {
    feedbackId: string,
}


export const fetchDeleteFeedback = (props: IFetchDeleteFeedbackProps): AppThunk<Promise<IFeedback>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/feedbacks`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.feedbacks && parsedResp.data.feedbacks.length) {
            dispatch(requestDeleteFeedback(parsedResp.data.feedbacks[0]));
            return parsedResp.data.feedbacks[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting feedback.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting feedback.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchImportFeedbackProps {
    fileId: string,
}


export const fetchImportFeedback = (props: IFetchImportFeedbackProps): AppThunk<Promise<IFeedback>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/feedbacks/import`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.feedback) {
            dispatch(receiveFeedbacks([parsedResp.data.feedback]));
            return parsedResp.data.feedback;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while importing feedback.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving feedback.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}