import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchCreateEntityPermissions, fetchDeleteEntityPermission, fetchContributorPermissionsForEntity } from "../../redux/entityPermission/actions";
import { selectorGetEntityPermissionsByEntity } from "../../redux/entityPermission/selectors";
import { fetchSearchUserContributions } from "../../redux/userContribution/actions";
import { useEasyKeyIndexMemo } from "../../utilities/misc";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { EntityPermissionTypeEnum, IEntityPermission } from "../../utilities/types/EntityPermission";
import { IUserContribution } from "../../utilities/types/UserContributions";
import { IUserDetail } from "../../utilities/types/UserDetail";
import { useFetchUserDetailsPageHook } from "../userDetails/Hooks";

export type IUserMeta = { requestedAccess?: boolean }
export type IUser = Pick<IUserDetail, 'userDetailId' | 'displayName' | 'company' | 'title'> & IUserMeta

export type IContributorMeta = { entityPermissionId: string }
export type IContributorUser = IUserDetail & IContributorMeta

/** Fetches the contributors of a given model. All users are also returned for management by admins. */
export function useFetchContributors(modelId: string, searchText?: string): [boolean, IUser[], IContributorUser[]] {
    const { fetching: fetchingUsers, lastResultSet: allUsers } = useFetchUserDetailsPageHook({ pageNumber: 1, pageSize: 10, text: searchText })
    const [fetchingPerms, perms] = useFetchEntityPermissions(modelId)

    const [users, contributors] = useCombineUserDetails(allUsers, perms)

    return [fetchingPerms || fetchingUsers, users, contributors]
}

function useFetchEntityPermissions(modelId: string): [boolean, IEntityPermission[]] {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    useEffect(() => {
        (async () => {
            setFetching(true)
            await dispatch(fetchContributorPermissionsForEntity({ entityId: modelId, entityType: EntityTypeEnum.Model }))
            setFetching(false)
        })();
    }, [dispatch, modelId]);
    const perms = useSelector((state: RootState) => selectorGetEntityPermissionsByEntity(state, modelId, EntityTypeEnum.Model))
    return [fetching, perms]
}

/** Combines users with their respective entity permissions and caches the results. */
function useCombineUserDetails(allUsers: IUserDetail[], perms: IEntityPermission[]): [IUser[], IContributorUser[]] {
    const permsById = useEasyKeyIndexMemo(perms, 'userDetailId')

    // Combine the users with the entity permissions
    return useMemo(() => {
        const _users: IUser[] = [], _contributors: IContributorUser[] = []

        for (const user of allUsers) {
            const contributorDetails = permsById[user.userDetailId]
            if (contributorDetails)
                _contributors.push({ ...user, entityPermissionId: contributorDetails.entityPermissionId })
            else _users.push(user)
        }

        return [sortByName(_users), sortByName(_contributors)]
        // eslint-disable-next-line
    }, [allUsers.length, perms.length])
}

function sortByName<T extends IUser | IContributorUser>(users: T[]): T[] {
    return users.sort((u1, u2) => u1.displayName?.localeCompare(u2.displayName) ?? 0)
}

type AddProps = {
    modelId: string,
    userId: string,
}

type RemoveProps = {
    entityPermissionId: string,
}

export interface IUseFetchUserContributionsPageHookProps {
    pageNumber: number,
    pageSize: number,
    minPageNumberToFetch?: number,
    monthly: boolean,
}


export const useFetchUserContributionsTableHook = ({ pageNumber, minPageNumberToFetch = 1, pageSize, monthly }: IUseFetchUserContributionsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IUserContribution[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IUserContribution[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve results
                var resp: any = await dispatch(fetchSearchUserContributions({
                    pageSize,
                    pageNumber,
                    monthly,

                }));

                // Check if there are more pages to show
                if (resp && resp.success && resp.data && resp.data.userContributions && resp.data.userContributions.length) {
                    setMorePages(resp.data.userContributions.length >= pageSize);
                    setLastResultSet(resp.data.userContributions);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [pageNumber, pageSize, dispatch, minPageNumberToFetch, monthly]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.userContributionId === x.userContributionId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}

/** Returns two functions to allow the create/delete of a contributor to a model. */
export function useAddRemoveContributor(): [boolean, (_: AddProps) => Promise<boolean>, (_: RemoveProps) => Promise<boolean>] {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const addContributor = useCallback(async (props: AddProps) => {
        try {
            setLoading(true)
            await dispatch(fetchCreateEntityPermissions({
                entityPermissions: [EntityPermissionTypeEnum.Contributor],
                targetUserId: props.userId,
                entityId: props.modelId,
                entityType: EntityTypeEnum.Model,
            }))
            return true
        } catch (error) {
            return false
        }
        finally {
            setLoading(false)
        }
    }, [dispatch, setLoading])

    const removeContributor = useCallback(async ({ entityPermissionId }: RemoveProps) => {
        try {
            setLoading(true)
            await dispatch(fetchDeleteEntityPermission({ entityPermissionId }))
            return true
        } catch (error) {
            return false
        }
        finally {
            setLoading(false)
        }
    }, [dispatch, setLoading])

    return [loading, addContributor, removeContributor]
}