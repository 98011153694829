import { IDiscussion } from "../../utilities/types/Discussion";


export const ACTIONS_DISCUSSION = {
    INVALIDATE: 'INVALIDATE_DISCUSSIONS',
    RECEIVE: 'RECEIVE_DISCUSSIONS',
    DELETE: 'DELETE_DISCUSSION',
    UPDATE: 'UPDATE_DISCUSSIONS',
}

interface ActionDiscussionsInvalidate {
    type: typeof ACTIONS_DISCUSSION.INVALIDATE,
    byId: { [key: string]: IDiscussion },
}

interface ActionDiscussionsReceive {
    type: typeof ACTIONS_DISCUSSION.RECEIVE
    byId: { [key: string]: IDiscussion },
}

export interface DiscussionState {
    byId: { [key: string]: IDiscussion },
}

export enum DiscussionSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesDiscussion = ActionDiscussionsReceive | ActionDiscussionsInvalidate;