import { RootState } from "..";
import { IFunction } from "../../utilities/types/Function";


export function selectorGetFunctionsByModelId(state: RootState, modelId: string) : IFunction[] {

    if (!state.functions || !state.functions.byId) return [];

    return sortFunctionsByOrderNumberAscending(Object.values(state.functions.byId).filter(x => x.modelId === modelId));
}


export function selectorGetFunctionsByComponentId(state: RootState, componentId: string) : IFunction[] {

    if (!state.functions || !state.functions.byId) return [];

    return sortFunctionsByOrderNumberAscending(Object.values(state.functions.byId).filter(x => x.componentId === componentId));
}


export function selectorGetFunctionById(state: RootState, functionId: string) : IFunction | undefined {

    if (!state.functions || !state.functions.byId) return undefined;

    return state.functions.byId[functionId];
}


// function sortFunctionsByOrderNumberDescending(functions: IFunction[]) {
//     return functions.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortFunctionsByOrderNumberAscending(functions: IFunction[]) {
    return functions.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.name < a.name) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}