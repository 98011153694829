import React, { useState } from "react";
import { TextField, Grid, Divider, Button, FormControl, Typography } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { IDecisionAssetInputFieldMapping } from "../../../utilities/types/DecisionAssetInputFieldMapping";
import {
  fetchUpdateDecisionAssetInputFieldMapping,
  fetchDeleteDecisionAssetInputFieldMapping,
} from "../../../redux/decisionAssetInputFieldMapping/actions";
import { selectorGetAssetInputFieldById } from "../../../redux/assetInputField/selectors";
import AssetInputFieldDisplay from "../../assetInputField/AssetInputFieldDisplay";
import ModalAssetInputFieldUpdate from "../../assetInputField/modals/ModalAssetInputFieldUpdate";

interface FormValues {
  notes: string;
  orderNumber: number;
}

interface FormProps {
  canEdit: boolean;
  decisionAssetInputFieldMapping: IDecisionAssetInputFieldMapping;
  onCompleteCallback(decisionAssetInputFieldMapping?: IDecisionAssetInputFieldMapping): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IDecisionAssetInputFieldMapping, AnyAction>;
}

const FormDecisionAssetInputFieldMappingUpdate = (formProps: FormProps) => {
  const { onCancelCallback, decisionAssetInputFieldMapping, canEdit } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showInputFieldDetails, setShowInputFieldDetails] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);
  const toggleShowInputFieldDetails = () => setShowInputFieldDetails(!showInputFieldDetails);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, decisionAssetInputFieldMapping } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedDecisionAssetInputFieldMapping = await dispatch(
      fetchUpdateDecisionAssetInputFieldMapping({
        ...values,
        decisionAssetInputFieldMappingId: decisionAssetInputFieldMapping.decisionAssetInputFieldMappingId,
      })
    );

    setSubmitting(false);
    if (updatedDecisionAssetInputFieldMapping) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedDecisionAssetInputFieldMapping);
    }
  };

  var inputField = useSelector((store: RootState) =>
    selectorGetAssetInputFieldById(store, decisionAssetInputFieldMapping.assetInputFieldId)
  );

  return (
    <Formik
      initialValues={{
        ...formProps.decisionAssetInputFieldMapping,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        if (!inputField || inputField === undefined) return null;

        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(
            fetchDeleteDecisionAssetInputFieldMapping({
              decisionAssetInputFieldMappingId: decisionAssetInputFieldMapping.decisionAssetInputFieldMappingId,
            })
          );

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input
              type="hidden"
              value={decisionAssetInputFieldMapping.decisionAssetInputFieldMappingId}
              name="decisionAssetInputFieldMappingId"
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <AssetInputFieldDisplay
                    readOnly={true}
                    assetInputField={inputField}
                    onValueChangeCallback={() => {}}
                  />
                </FormControl>
                <Typography
                  variant="caption"
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={toggleShowInputFieldDetails}
                >
                  View Field Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>

              <Grid item xs={12}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />

                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete mapping"
                  subtitle="Confirm mapping removal"
                  description="Are you sure that you'd like to remove this mapping?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />

                <ModalAssetInputFieldUpdate
                  open={showInputFieldDetails}
                  onCancelCallback={toggleShowInputFieldDetails}
                  onCompleteCallback={toggleShowInputFieldDetails}
                  canEdit={canEdit}
                  assetInputField={inputField}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          notes: Yup.string().label("Description").max(500, "Please input 2500 characters or less"),
          orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
        });
      }}
    />
  );
};

export default FormDecisionAssetInputFieldMappingUpdate;
