import React, { useState } from "react";
import {
  TextField,
  Grid,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Switch,
} from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { ITask, PrimaryLabourEnum, TaskTypeEnum } from "../../../utilities/types/Task";
import { fetchUpdateTask, fetchDeleteTask } from "../../../redux/task/actions";
import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";
import TimeConverter from "../../taskApplicability/forms/TimeConverter";

interface FormValues {
  name: string;
  description: string;
  primaryLabour: number;
  rampTime: number;
  duration: number;
  orderNumber: number;
  type: number;
}

interface FormProps {
  task: ITask;
  onCompleteCallback(task?: ITask): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ITask, AnyAction>;
  canEdit: boolean;
}

const FormTaskUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    task: { taskId },
    canEdit,
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, task } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedTask = await dispatch(
      fetchUpdateTask({
        ...values,
        taskId: task.taskId,
      })
    );

    setSubmitting(false);
    if (updatedTask) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedTask);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.task,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteTask({ taskId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={taskId} name="taskId" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "name", "Name")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel id="lbl-model-type" variant="standard">
                    Primary Labour
                  </InputLabel>
                  <Select
                    labelId="lbl-model-type"
                    {...getFormikFieldProps(props, "primaryLabour", "Primary Labour")}
                    onChange={(e) => {
                      props.setFieldValue("primaryLabour", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-type"
                    variant="standard"
                  >
                    <MenuItem value={PrimaryLabourEnum.Electrical}>Electrical</MenuItem>
                    <MenuItem value={PrimaryLabourEnum.Mechanical}>Mechanical</MenuItem>
                    <MenuItem value={PrimaryLabourEnum.Operations}>Operations</MenuItem>
                    <MenuItem value={PrimaryLabourEnum.ConditionMonitoring}>Condition Monitoring</MenuItem>
                    <MenuItem value={PrimaryLabourEnum.InstrumentTech}>Instrumentation</MenuItem>
                    <MenuItem value={PrimaryLabourEnum.Fitter}>Fitter</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimeConverter
                  label="Duration"
                  defaultValueOverride={props.values.duration}
                  onValueChangeCallback={(value) => {
                    props.setFieldValue("duration", value);
                  }}
                  error={props.errors.duration}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "rampTime", "Ramp Time (hrs)")}
                />
              </Grid>
              {isAdmin ? (
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={props.handleChange}
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="standard"
                    {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ marginRight: "10px" }}>Planned</Typography>
                    <Switch
                      checked={props.values.type === TaskTypeEnum.Impact}
                      onChange={() => {
                        props.setFieldValue(
                          "type",
                          props.values.type === TaskTypeEnum.Impact ? TaskTypeEnum.Applicability : TaskTypeEnum.Impact
                        );
                      }}
                      name="type-switch"
                    />
                    <Typography style={{ marginRight: "10px" }}>Corrective</Typography>
                  </div>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>

              <Grid item xs={12}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  {canEdit ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ flexBasis: "33%" }}
                      onClick={toggleDeleteConfirmation}
                    >
                      Delete
                    </Button>
                  ) : null}

                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    {canEdit ? (
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                      >
                        Update
                      </Button>
                    ) : null}
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete task"
                  subtitle="Confirm task delete"
                  description="Are you sure that you'd like to remove this task?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          name: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(200, "Please input 200 characters or less")
            .required("Please provide a name"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          rampTime: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999999"),
          duration: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999999"),
          type: Yup.number().required(),
          orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
        });
      }}
    />
  );
};

export default FormTaskUpdate;
