import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Chip,
  Card as MuiCard,
  Typography,
  styled,
  IconButton,
  Tooltip,
  Popover,
  Box,
  Button,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PersonIcon from "@mui/icons-material/PersonSharp";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorIcon from "@mui/icons-material/Error";
import RedColors from "@mui/material/colors/red";

import { RootState } from "../../redux";

import { GetUserLinkByUserId } from "../../routes/RouteLinkHelpers";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import { ImageWithFallback } from "../generic/ImageWithFallback";
import { IAssetJob, AssetJobStatusEnum } from "../../utilities/types/AssetJobs";
import ModalJobProgressLog from "../jobs/modals/ModalJobProgressLog";
import { selectorGetModelById } from "../../redux/model/selectors";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { cancelAssetJob } from "../../redux/assetJob/actions";
import { displayFormattedDate } from "../../utilities/formatter";

const getStatusColor = (status: AssetJobStatusEnum) => {
  switch (status) {
    case AssetJobStatusEnum.Progress:
    case AssetJobStatusEnum.GeneratingInProgress:
    case AssetJobStatusEnum.Generating:
      return "info";

    case AssetJobStatusEnum.Complete:
      return "success";

    case AssetJobStatusEnum.Failed:
      return "error";

    case AssetJobStatusEnum.Cancelled:
      return "warning";

    default:
      return "default";
  }
};

interface AssetJobCollapseCardProps {
  assetJob?: IAssetJob;
  showCollapse: boolean;
  setShowCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  setReFetchAssetJob: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

const Card = styled(MuiCard)(({ theme }) => ({
  overflow: "unset",
  marginTop: `${theme.spacing(4)} !important`,
  ".inner-wrapper": {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: theme.spacing(2),
    flexWrap: "wrap",
    ".iconContainer": {
      position: "absolute",
      left: 0,
      top: "-28px",
      zIndex: 11,
      display: "grid",
      placeContent: "center",
      height: "75px",
      width: "75px",
      backgroundColor: "#37474f",
      borderRadius: "4px",
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      svg: {
        color: "#FFF",
        width: "1.3em",
        height: "1.3em",
      },
    },
    ".icon-btn": {
      backgroundColor: "#FFF",
      border: "1px solid #EEE",
    },
    ".content-wrapper": {
      cursor: "pointer",
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      gap: 16,
      flex: 1,
      flexShrink: 0,
      position: "relative",
      paddingLeft: theme.spacing(11),
    },
    ".right-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
  },
  ".card-img": {
    width: 75,
    height: 75,
    borderRadius: 3,
    objectFit: "cover",
  },
  ".collapse-wrapper": {},
}));

const AssetJobCollapseCard: React.FC<AssetJobCollapseCardProps> = ({
  assetJob,
  showCollapse,
  setShowCollapse,
  setReFetchAssetJob,
  children,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);

  const toggleCancelConfirmation = () => setShowCancelConfirmation((prev) => !prev);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isAdmin = useSelector((store: RootState) => selectorGetUserPermissionIsAdmin(store));
  const [logModalVisible, setLogModalVisible] = useState<boolean>(false);
  const toggleCollapse = () => setShowCollapse((prev) => !prev);

  const model = useSelector((store: RootState) => selectorGetModelById(store, assetJob ? assetJob.modelId : ""));

  const modelImageUrl = useFileSourceSingleHook({
    fileId: model && model?.mainImageId ? model.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  async function handleCancelJob() {
    if (!assetJob?.assetJobId) return;

    setLoading(true);
    await dispatch(cancelAssetJob({ assetJobId: assetJob.assetJobId }));
    setReFetchAssetJob(true);
    setLoading(false);
    toggleCancelConfirmation();
  }

  if (!assetJob) return null;

  const jobTitle = assetJob?.name || "Asset Job Details";

  return (
    <Card>
      <div className="inner-wrapper">
        <div className="content-wrapper" onClick={toggleCollapse}>
          <div className="iconContainer">
            <ImageWithFallback className="card-img" src={modelImageUrl ? modelImageUrl : ""} />
          </div>
          <div style={{ flex: 1 }}>
            <Typography fontWeight={600}>{jobTitle}</Typography>

            <Typography component="span" variant="body2" className="inline" color="textPrimary">
              {displayFormattedDate(assetJob.created || assetJob.created, "")} - {displayFormattedDate(assetJob.ended)}
            </Typography>
          </div>
        </div>
        <LoaderAbsoluteCentred loading={loading} />
        <div className="right-wrapper">
          <Chip color={getStatusColor(assetJob.status)} label={AssetJobStatusEnum[assetJob.status]} />
          {assetJob.persistentErrorMessage ? (
            <>
              <Tooltip title="Error Message" placement="top" arrow>
                <IconButton size="small" style={{ border: "1px solid rgba(0,0,0,0.1)" }} onClick={handleClick}>
                  <ErrorIcon style={{ fill: RedColors[600] }} />
                </IconButton>
              </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box p={2} maxWidth={320}>
                  <Typography variant="body2" mb={1}>
                    Error Message
                  </Typography>
                  <Typography variant="body2" color={RedColors[600]}>
                    {assetJob.persistentErrorMessage}
                  </Typography>
                </Box>
              </Popover>
            </>
          ) : null}
          {![AssetJobStatusEnum.Cancelled, AssetJobStatusEnum.Complete, AssetJobStatusEnum.Failed].includes(
            assetJob.status
          ) && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ borderRadius: 16, textTransform: "none" }}
              onClick={toggleCancelConfirmation}
            >
              Cancel Job
            </Button>
          )}

          <WidgetModalConfirmationDialog
            open={showCancelConfirmation}
            title="Cancel Asset Job"
            subtitle={`Confirm cancel ${jobTitle}`}
            description="Are you sure that you'd like to cancel this job?"
            onCancelCallback={toggleCancelConfirmation}
            onConfirmCallback={handleCancelJob}
            confirmButtonText="Confirm"
          />

          <Tooltip title="Progress Log" placement="top" arrow>
            <IconButton
              size="small"
              style={{ border: "1px solid rgba(0,0,0,0.1)" }}
              onClick={() => {
                setReFetchAssetJob(true);
                setLogModalVisible(true);
              }}
            >
              <DescriptionIcon />
            </IconButton>
          </Tooltip>

          {isAdmin ? (
            <Tooltip title="Created By User" placement="top" arrow>
              <IconButton
                size="small"
                style={{ border: "1px solid rgba(0,0,0,0.1)" }}
                component={NavLink}
                target="_blank"
                to={GetUserLinkByUserId(assetJob.createdBy)}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          <IconButton aria-label="collapse" aria-controls="collapse-menu" aria-haspopup="true" onClick={toggleCollapse}>
            {showCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      <div>
        <Collapse className={showCollapse ? "collapse-wrapper" : ""} in={showCollapse}>
          {children}
        </Collapse>
      </div>
      <ModalJobProgressLog
        open={logModalVisible}
        progressLog={assetJob.progressLog}
        onCancelCallback={() => setLogModalVisible(false)}
      />
    </Card>
  );
};

export default AssetJobCollapseCard;
