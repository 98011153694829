import React from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import HeaderIcon from "@mui/icons-material/FilterDramaTwoTone";
import { GetAdminOutputsPage } from "../../routes/RouteLinkHelpers";
import { NavLink } from "react-router-dom";

const AdminManageOutputsWidget = () => {
  return (
    <WidgetSummaryCountAndButtons
      key="AdminManageOutputsWidget"
      headerIcon={<HeaderIcon />}
      captionText="Manage Outputs"
      mainText="Manage Outputs"
      buttons={[
        <Button size="small" variant="outlined" color="primary" component={NavLink} to={GetAdminOutputsPage()}>
          Manage
        </Button>,
      ]}
    />
  );
};

export default AdminManageOutputsWidget;
