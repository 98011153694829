import React from "react";
import { Switch, Route } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";
import PageHome from "../components/home/pages/Home";

const RouteAdmin = () => {
  return (
    <Switch>
      <Route
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageHome {...props} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteAdmin;
