import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchIdentifierMappingsProps, fetchSearchIdentifierMappings } from "../../redux/identifierMapping/actions";
import { IIdentifierMapping } from "../../utilities/types/IdentifierMapping";


interface IUseFetchIdentifierMappingsPageHookProps extends IFetchSearchIdentifierMappingsProps {
    minPageNumberToFetch: number;
}


export const useFetchIdentifierMappingsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchIdentifierMappingsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IIdentifierMapping[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IIdentifierMapping[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var identifierMappings = await dispatch(fetchSearchIdentifierMappings({
                    pageSize,
                    pageNumber,
                    modelId,
                })) as unknown as IIdentifierMapping[];

                if (identifierMappings && identifierMappings.length) {
                    setMorePages(identifierMappings.length >= pageSize)
                    setLastResultSet(identifierMappings);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.identifierMappingId === x.identifierMappingId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}