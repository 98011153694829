import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/SettingsTwoTone";
import { IUserRole } from "../../../utilities/types/UserRole";
import FormUserRoleUpdate from "../forms/FormUserRoleUpdate";

export interface IModalUserRoleUpdateProps {
  open: boolean;
  userRole: IUserRole;
  onCancelCallback(): void;
  onCompleteCallback(userRole?: IUserRole): void;
}

function ModalUserRoleUpdate({ onCancelCallback, open, onCompleteCallback, userRole }: IModalUserRoleUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a userRole"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormUserRoleUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          userRole={userRole}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalUserRoleUpdate;
