import { ISolverDetails } from "../../utilities/types/SolverDetails";


export const ACTIONS_SOLVERDETAILS = {
    INVALIDATE: 'INVALIDATE_SOLVERDETAILS',
    RECEIVE: 'RECEIVE_SOLVERDETAILS',
    DELETE: 'DELETE_SOLVERDETAILS',
    UPDATE: 'UPDATE_SOLVERDETAILS',
}

interface ActionSolverDetailsInvalidate {
    type: typeof ACTIONS_SOLVERDETAILS.INVALIDATE,
    byId: { [key: string]: ISolverDetails },
}

interface ActionSolverDetailsReceive {
    type: typeof ACTIONS_SOLVERDETAILS.RECEIVE
    byId: { [key: string]: ISolverDetails },
}

export interface SolverDetailsState {
    byId: { [key: string]: ISolverDetails },
}

export enum SolverDetailsSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverDetails = ActionSolverDetailsReceive | ActionSolverDetailsInvalidate;