import { Typography, IconButton, InputLabel, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import React, { useState } from "react";
import { IAssetInputFieldListValue } from "../../utilities/types/AssetInputFieldListValue";
import { selectorGetAssetInputFieldListValuesByAssetInputFieldId } from "../../redux/assetInputFieldListValue/selectors";
import AddIcon from "@mui/icons-material/AddOutlined";
import ModalAssetInputFieldListValueCreate from "./modals/ModalAssetInputFieldListValueCreate";
import ModalAssetInputFieldListValueUpdate from "./modals/ModalAssetInputFieldListValueUpdate";

const SectionWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& .fieldValueWrapper": {
    border: "1px solid #EEE",
    padding: "3px 5px",
    borderRadius: "4px",
    margin: "5px 0px",
    transition: "background 0.25s ease-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
}));

interface IAssetInputFieldListValueMaintenanceSectionProps {
  canEdit: boolean;
  assetInputFieldId: string;
}

function AssetInputFieldListValueMaintenanceSection({
  assetInputFieldId,
  canEdit,
}: IAssetInputFieldListValueMaintenanceSectionProps) {
  const assetInputFieldListValues = useSelector((store: RootState) =>
    selectorGetAssetInputFieldListValuesByAssetInputFieldId(store, assetInputFieldId)
  );

  return (
    <AssetInputFieldListValueMaintenanceSectionDisplay
      canEdit={canEdit}
      assetInputFieldId={assetInputFieldId}
      assetInputFieldListValues={assetInputFieldListValues}
    />
  );
}

interface IAssetInputFieldListValueMaintenanceSectionDisplayProps {
  canEdit: boolean;
  assetInputFieldId: string;
  assetInputFieldListValues: IAssetInputFieldListValue[];
}

function AssetInputFieldListValueMaintenanceSectionDisplay({
  assetInputFieldListValues,
  assetInputFieldId,
}: IAssetInputFieldListValueMaintenanceSectionDisplayProps) {
  const [showModalAssetInputFormListValueCreate, setShowModalAssetInputFormListValueCreate] = useState(false);
  const [assetInputFieldListValueToEdit, setAssetInputFieldListValueToEdit] = useState<IAssetInputFieldListValue>();

  const toggleShowModalAssetInputFormListValueCreate = () =>
    setShowModalAssetInputFormListValueCreate(!showModalAssetInputFormListValueCreate);

  return (
    <SectionWrapper>
      <InputLabel shrink={true}>Values</InputLabel>
      <div>
        {assetInputFieldListValues.map((assetInputFieldListValue) => {
          return (
            <div
              key={assetInputFieldListValue.assetInputFieldListValueId}
              className="fieldValueWrapper"
              onClick={() => setAssetInputFieldListValueToEdit(assetInputFieldListValue)}
            >
              <Typography component="p">{assetInputFieldListValue.displayText}</Typography>
              <Typography component="span" variant="caption" style={{ opacity: 0.75 }}>
                Value is {assetInputFieldListValue.value}
                {assetInputFieldListValue.isDefault ? " (default)" : ""}{" "}
              </Typography>
            </div>
          );
        })}
      </div>

      <div>
        <IconButton onClick={toggleShowModalAssetInputFormListValueCreate}>
          <AddIcon />
        </IconButton>
      </div>
      <ModalAssetInputFieldListValueCreate
        open={showModalAssetInputFormListValueCreate}
        assetInputFieldId={assetInputFieldId}
        onCancelCallback={toggleShowModalAssetInputFormListValueCreate}
        onCompleteCallback={toggleShowModalAssetInputFormListValueCreate}
      />
      {assetInputFieldListValueToEdit && (
        <ModalAssetInputFieldListValueUpdate
          onCancelCallback={() => setAssetInputFieldListValueToEdit(undefined)}
          onCompleteCallback={() => setAssetInputFieldListValueToEdit(undefined)}
          open={assetInputFieldListValueToEdit !== undefined}
          assetInputFieldListValue={assetInputFieldListValueToEdit}
        />
      )}
    </SectionWrapper>
  );
}

export default AssetInputFieldListValueMaintenanceSection;
