import { ISummaryOutputType } from "../../utilities/types/SummaryOutputType";
import { Typography, styled } from "@mui/material";

import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import React from "react";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import InputIcon from "@mui/icons-material/ImportExportTwoTone";
import PlaceholderIcon from "@mui/icons-material/ImportExportTwoTone";
import moment from "moment";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";

const DivWrapper = styled("div")(({ theme }) => ({
  minHeight: 200,
  "& .summaryOutputTypeJobImageWrapper": {
    height: 150,
    width: "100%",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& .summaryOutputTypeJobImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
}));

interface ISummaryOutputTypeDisplayWidgetProps {
  loading: boolean;
  summaryOutputType?: ISummaryOutputType;
}

const SummaryOutputTypeDisplayWidget = ({ loading, summaryOutputType }: ISummaryOutputTypeDisplayWidgetProps) => {
  const imageUrl = useFileSourceSingleHook({
    fileId: summaryOutputType && summaryOutputType.mainImageId ? summaryOutputType.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  return (
    <WidgetSectionBase
      headerText={summaryOutputType?.name || "Summary Output Type"}
      subheaderText={`Last updated ${moment.utc(summaryOutputType?.updated).fromNow()}`}
      headerIcon={<InputIcon />}
    >
      <DivWrapper>
        <div className="summaryOutputTypeJobImageWrapper">
          <div className="summaryOutputTypeJobImage" style={{ backgroundImage: `url("${imageUrl}")` }} />
        </div>
        <div style={{ marginTop: 16 }}>
          {!summaryOutputType && !loading ? (
            <WidgetNoResultsPlaceholder text="Summary Output Type not found." icon={PlaceholderIcon} flip={true} />
          ) : (
            <Typography variant="caption">{summaryOutputType?.description}</Typography>
          )}
        </div>

        <LoaderAbsoluteCentred loading={loading} />
      </DivWrapper>
    </WidgetSectionBase>
  );
};

export default SummaryOutputTypeDisplayWidget;
