import React from "react";
import { TextField, Grid, Divider, Button, MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { DecisionActionTypeEnum, IDecision } from "../../../utilities/types/Decision";
import { fetchCreateDecision } from "../../../redux/decision/actions";

interface FormValues {
  name: string;
  description: string;
  disabled: boolean;
  orderNumber: number;
  actionType: DecisionActionTypeEnum;
}

interface FormProps {
  modelId: string;
  onCompleteCallback(decision?: IDecision): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IDecision, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "name", "Cause Logic Name")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={9}
            variant="standard"
            fullWidth
            margin="normal"
            {...getFormikFieldProps(props, "description", "Description of Logic")}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel id="lbl-model-type" variant="standard">
              Type
            </InputLabel>
            <Select
              labelId="lbl-model-type"
              {...getFormikFieldProps(props, "actionType", "Action Type")}
              onChange={(e) => {
                props.setFieldValue("actionType", e.target.value);
              }}
              style={{ display: "block" }}
              variant="standard"
              id="txt-model-type"
            >
              <MenuItem value={DecisionActionTypeEnum.Enable}>Enable</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.ChangeBeta}>ChangeBeta</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.ChangeEta}>ChangeEta</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.ChangeGamma}>ChangeGamma</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.DoNothing}>DoNothing</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.NewEta}>NewEta</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.NewBeta}>NewBeta</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.NewGamma}>NewGamma</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.InitialAge}>InitialAge</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.ChangePF}>ChangePF</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.SetIAtoPercentOfEta}>SetIAtoPercentOfEta</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.SetQuantity}>SetQuantity</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.AddPercentOfEtatoIA}>AddPercentOfEtatoIA</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.AddToInitialAge}>AddToInitialAge</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.AddToEta}>AddToEta</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.OverwriteCostBundles}>OverwriteCostBundles</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.OverwritePlanIntervals}>OverwritePlanIntervals</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.ScaleCosts}>ScaleCosts</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.DisablePlan}>DisablePlan</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.AddToCosts}>AddToCosts</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.SetPlanInterval}>SetPlanInterval</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.SetPlanOffset}>SetPlanOffset</MenuItem>
              <MenuItem value={DecisionActionTypeEnum.AddToCostsAndSplitout}>AddToCostsAndSplitout</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormDecisionCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: "",
    description: "",
    disabled: false,
    orderNumber: 0,
    actionType: DecisionActionTypeEnum.Enable,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide a name"),
    description: Yup.string().label("Description").max(2500, "Please input 2500 characters or less"),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    // Map dispatch via props
    var createdDecision = await dispatch(
      fetchCreateDecision({
        ...values,
        modelId,
      })
    );

    setSubmitting(false);

    if (createdDecision) onCompleteCallback(createdDecision);
  },
})(InnerForm);

export default FormDecisionCreate;
