import { IWorkflow } from "../../utilities/types/Workflow";

export const ACTIONS_WORKFLOW_TYPE = {
  INVALIDATE: "INVALIDATE_WORKFLOW_TYPES",
  RECEIVE: "RECEIVE_WORKFLOW_TYPES",
  DELETE: "DELETE_WORKFLOW_TYPE",
  UPDATE: "UPDATE_WORKFLOW_TYPES",
  SET_SELECTED: "SET_SELECTED_WORKFLOW",
};

export interface WorkflowState {
  byId: { [key: string]: IWorkflow };
  selectedWorkflowId?: string;
}
interface ActionWorkflowInvalidate {
  type: typeof ACTIONS_WORKFLOW_TYPE.INVALIDATE;
  byId: { [key: string]: IWorkflow };
  workflowId?: string;
}

interface ActionWorkflowReceive extends ActionWorkflowInvalidate {
  type: typeof ACTIONS_WORKFLOW_TYPE.RECEIVE;
}

interface ActionWorkflowSelected extends ActionWorkflowInvalidate {
  type: typeof ACTIONS_WORKFLOW_TYPE.SET_SELECTED;
}

export enum WorkflowOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type ActionWorkflowType = ActionWorkflowReceive | ActionWorkflowInvalidate | ActionWorkflowSelected;
