import { ISolverJob } from "../../utilities/types/SolverJob";


export const ACTIONS_SOLVER_JOB = {
    INVALIDATE: 'INVALIDATE_SOLVER_JOBS',
    RECEIVE: 'RECEIVE_SOLVER_JOBS',
    DELETE: 'DELETE_SOLVER_JOB',
    UPDATE: 'UPDATE_SOLVER_JOBS',
}

interface ActionSolverJobsInvalidate {
    type: typeof ACTIONS_SOLVER_JOB.INVALIDATE,
    byId: { [key: string]: ISolverJob },
}

interface ActionSolverJobsReceive {
    type: typeof ACTIONS_SOLVER_JOB.RECEIVE
    byId: { [key: string]: ISolverJob },
}

export interface SolverJobState {
    byId: { [key: string]: ISolverJob },
}

export enum SolverJobSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverJob = ActionSolverJobsReceive | ActionSolverJobsInvalidate;