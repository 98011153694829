import { ACTIONS_FAVOURITE, FavouriteSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IFavourite } from '../../utilities/types/Favourite';
import { EntityTypeEnum } from '../../utilities/types/Entity';


const receiveFavourites = (favourites: IFavourite[], remove?: boolean) => {

    var byIdObjectToDispatch: { [key: string]: IFavourite } = {};

    for (var i = 0; i < favourites.length; i++) {
        byIdObjectToDispatch[favourites[i].favouriteId] = favourites[i];
    }

    return {
        type: remove ? ACTIONS_FAVOURITE.DELETE : ACTIONS_FAVOURITE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};

export interface IFetchCreateFavouriteProps {
    entityType: EntityTypeEnum,
    entityId: string,
}

export const fetchCreateFavourite = (favouriteToCreate: IFetchCreateFavouriteProps): AppThunk<Promise<IFavourite>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/favourites`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(favouriteToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.favourites) {
            dispatch(receiveFavourites(parsedResp.data.favourites));
            return parsedResp.data.favourites;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating favourite.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating favourite.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchDeleteFavouriteProps {
    favouriteId: string,
}

export const fetchDeleteFavourite = (props: IFetchDeleteFavouriteProps): AppThunk<Promise<IFavourite>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/favourites`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.favourites && parsedResp.data.favourites.length) {
            dispatch(receiveFavourites(parsedResp.data.favourites, true));
            return parsedResp.data.favourites[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting favourite.");
                return null;
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting favourite.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchSearchFavouritesProps {
    pageNumber: number,
    pageSize: number,
    favouriteId?: string,
    entityType: EntityTypeEnum.Model, // Add extra supported entites like `EntityTypeEnum.Model ! EntityTypeEnum.Comment`
    entityIds?: string[],
    orderType?: FavouriteSearchOrderTypeEnum,
}


export const fetchSearchFavourites = (searchParams: IFetchSearchFavouritesProps): AppThunk<Promise<IFavourite[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/favourites`, searchParams), {
            method: 'GET',
            headers: headers,
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.favourites) {

            // Remove unmapped image property
            for (var favourite of parsedResp.data.favourites) {
                delete favourite.mainImage;
            }

            dispatch(receiveFavourites(parsedResp.data.favourites));
            return parsedResp.data.favourites;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching favourite.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching favourite.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}
