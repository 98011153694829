import React, { useState } from "react";
import { Box, Input, Button } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";
import { IComment } from "../../../utilities/types/Comment";
import { fetchUpdateComment, fetchDeleteComment } from "../../../redux/comment/actions";

interface FormValues {
  text: string;
}

interface FormProps {
  comment: IComment;
  onCompleteCallback(comment?: IComment): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IComment, AnyAction>;
  canEdit: boolean;
}

const FormCommentUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    comment: { commentId },
    canEdit,
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, comment } = formProps;

    setSubmitting(true);
    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedComment = await dispatch(
      fetchUpdateComment({
        ...values,
        commentId: comment.commentId,
      })
    );

    setSubmitting(false);
    if (updatedComment) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedComment);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.comment,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteComment({ commentId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <Box sx={{ boxShadow: 3, margin: "10px 0 8px 0", padding: 0, borderRadius: 2, position: "relative" }}>
            <form onSubmit={props.handleSubmit}>
              <input type="hidden" value={commentId} name="commentId" />
              <div>
                <Input
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  style={{ padding: 8 }}
                  {...getFormikFieldProps(props, "text", "Text")}
                />

                <div style={{ display: "flex", alignItems: "center", gap: 8, padding: "12px" }}>
                  {canEdit && (
                    <>
                      <Button
                        style={{ borderRadius: "8px" }}
                        type="submit"
                        size="small"
                        disabled={submitting}
                        variant="contained"
                        color="primary"
                      >
                        Update
                      </Button>
                      <Button
                        style={{ borderRadius: "8px" }}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={toggleDeleteConfirmation}
                      >
                        Delete
                      </Button>
                    </>
                  )}

                  <Button disabled={submitting} variant="text" size="small" onClick={onCancelCallback}>
                    Close
                  </Button>

                  <LoaderAbsoluteCentred loading={submitting || deleting} />
                  <WidgetModalConfirmationDialog
                    open={showDeleteConfirmation}
                    title="Delete comment"
                    subtitle="Confirm comment delete"
                    description="Are you sure that you'd like to remove this comment?"
                    onCancelCallback={toggleDeleteConfirmation}
                    onConfirmCallback={deleteHandler}
                    confirmButtonText="Delete"
                  />
                </div>
              </div>
            </form>
          </Box>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          text: Yup.string().label("Text").min(2, "Please input 2 characters or more"),
        });
      }}
    />
  );
};

export default FormCommentUpdate;
