import React from "react";
import { Box, Input, Button } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IComment } from "../../../utilities/types/Comment";
import { fetchCreateComment } from "../../../redux/comment/actions";
import { EntityTypeEnum } from "../../../utilities/types/Entity";

interface FormValues {
  text: string;
}

interface FormProps {
  discussionId: string;
  parentId: string;
  entityId: string;
  entityType: EntityTypeEnum;
  onCompleteCallback(comment?: IComment): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IComment, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  return (
    <Box sx={{ boxShadow: 3, margin: "10px 0 8px 0", padding: 0, borderRadius: 2, position: "relative" }}>
      <form onSubmit={props.handleSubmit}>
        <div>
          <Input
            onChange={props.handleChange}
            multiline
            rows={2}
            style={{ padding: 8 }}
            fullWidth
            {...getFormikFieldProps(props, "text", "Reply to this comment")}
          />

          <div style={{ display: "flex", alignItems: "center", gap: 8, padding: "12px" }}>
            <Button
              type="submit"
              disabled={props.isSubmitting}
              style={{ borderRadius: "8px" }}
              size="small"
              variant="contained"
              color="primary"
            >
              Comment
            </Button>
            <Button disabled={props.isSubmitting} variant="text" size="small" onClick={props.onCancelCallback}>
              Cancel
            </Button>
            <LoaderAbsoluteCentred loading={props.isSubmitting} />
          </div>
        </div>
      </form>
    </Box>
  );
};

const FormCommentCreate = withFormik<FormProps, FormValues>({
  validationSchema: Yup.object().shape({
    text: Yup.string().label("Text").min(2, "Please input 2 characters or more"),
  }),
  handleSubmit: async (values, { setSubmitting, setFieldValue, resetForm, props }) => {
    const { onCompleteCallback, dispatch, discussionId, parentId, entityId, entityType } = props;

    // Map dispatch via props
    var createdComment = await dispatch(
      fetchCreateComment({
        ...values,
        discussionId,
        parentId,
        entityId,
        entityType,
      })
    );

    setSubmitting(false);

    if (createdComment) {
      resetForm({ values: { text: "" } });
      onCompleteCallback(createdComment);
    }
  },
})(InnerForm);

export default FormCommentCreate;
