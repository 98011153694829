import { ActionTypesAssetJobTasks, AssetJobTasksState, ACTIONS_ASSET_JOB_TASK } from "./types";

const initialState: AssetJobTasksState = {
  byId: {},
};

export function assetJobTasksReducer(state = initialState, action: ActionTypesAssetJobTasks): AssetJobTasksState {
  switch (action.type) {
    case ACTIONS_ASSET_JOB_TASK.INVALIDATE: {
      return {
        ...initialState,
      };
    }
    case ACTIONS_ASSET_JOB_TASK.DELETE:
      let currentById = Object.assign({}, state.byId);
      delete currentById[Object.keys(action.byId)[0]];

      return {
        ...state,
        byId: currentById,
      };
    case ACTIONS_ASSET_JOB_TASK.RECEIVE: {
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
      };
    }
    default:
      return state;
  }
}
