import { ACTIONS_FAILURE_MODE, FailureModeSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IFailureMode } from '../../utilities/types/FailureMode';
import { selectorGetFailureModesByModelId } from './selectors';
import { EntityTypeEnum } from '../../utilities/types/Entity';
import { receiveFunctionalFailures } from '../functionalFailure/actions';
import { receiveIdentifierMappings } from '../identifierMapping/actions';
import { receiveEvents } from '../event/actions';


export const receiveFailureModes = (failureModes: IFailureMode[]) => {

    var byIdObjectToDispatch: { [key: string]: IFailureMode } = {};

    for (var i = 0; i < failureModes.length; i++) {
        byIdObjectToDispatch[failureModes[i].failureModeId] = failureModes[i];
    }

    return {
        type: ACTIONS_FAILURE_MODE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteFailureMode = (failureMode: IFailureMode) => ({
    type: ACTIONS_FAILURE_MODE.DELETE,
    byId: { [failureMode.failureModeId]: failureMode }
});


export interface IFetchCreateFailureModeProps {
    modelId: string,
    functionalFailureId: string,
    name: string,
    description: string,
    eta: number,
    beta: number,
    gamma: number,
    orderNumber: number,
    hidden: boolean,
    eventId: string,
    primary: boolean,
    conditionRelatedMode: boolean,
}
export interface IFetchCreateFailureModesProps {
    failureModes: IFetchCreateFailureModeProps[]
}


export const fetchCreateFailureMode = (failureModesToCreate: IFetchCreateFailureModesProps): AppThunk<Promise<IFailureMode>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        const processedFailureModes = failureModesToCreate.failureModes.map(failureMode => ({
            ...failureMode,
            eventId: failureMode.eventId === "" ? null : failureMode.eventId,
        }));

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/failureModes`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody({ failureModes: processedFailureModes }),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data) {
            if (parsedResp.data.failureModes) dispatch(receiveFailureModes(parsedResp.data.failureModes));
            if (parsedResp.data.identifierMappings) dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
                 
            if (parsedResp.data.events){
                dispatch(receiveEvents(parsedResp.data.events));
            }
            
            return parsedResp.data.failureModes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating failureMode.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating failureMode.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateFailureModeProps {
    failureModeId: string,
    name: string,
    description: string,
    eta: number,
    beta: number,
    gamma: number,
    orderNumber: number,
    hidden: boolean,
    eventId: string,
    primary: boolean,
}


export const fetchUpdateFailureMode = (props: IFetchUpdateFailureModeProps): AppThunk<Promise<IFailureMode>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/failureModes`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data) {
            if (parsedResp.data.failureModes) dispatch(receiveFailureModes(parsedResp.data.failureModes));
            return parsedResp.data.failureModes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating failureMode.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating failureMode.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchCloneFailureModeProps {
    sourceId: string,
    destinationId: string,
    includeIdentifiers: boolean,
    entityType: EntityTypeEnum,

}

export const fetchCloneFailureMode = (props: IFetchCloneFailureModeProps): AppThunk<Promise<IFailureMode>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/Cloning/CloneEntity`, {
            method: 'Post',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.failureModes) {
            dispatch(receiveFailureModes(parsedResp.data.failureModes));
            if(parsedResp.data.functionalFailures) dispatch(receiveFunctionalFailures(parsedResp.data.functionalFailures));
            if(parsedResp.data.identifierMappings) dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
            return parsedResp.data.failureModes;
        }

        else{
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
            ShowError("Error cloning failureMode.");
            return null;
        }
    }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating failureMode.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchFailureModesProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    failureModeId?: string,
    text?: string,
    createdBy?: string,
    orderType?: FailureModeSearchOrderTypeEnum,
}


export const fetchSearchFailureModes = (searchParams: IFetchSearchFailureModesProps): AppThunk<Promise<IFailureMode[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/failureModes`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.failureModes) {
            dispatch(receiveFailureModes(parsedResp.data.failureModes));
            return parsedResp.data.failureModes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching failureMode.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching failureMode.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteFailureModeProps {
    failureModeId: string,
}


export const fetchDeleteFailureMode = (props: IFetchDeleteFailureModeProps): AppThunk<Promise<IFailureMode>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/failureModes`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.failureModes && parsedResp.data.failureModes.length) {
            dispatch(requestDeleteFailureMode(parsedResp.data.failureModes[0]));
            return parsedResp.data.failureModes[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting failureMode.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting failureMode.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IEnableFailureModesByModelIdProps {
    modelId: string,
}

export const enableFailureModesByModelId = ({ modelId }: IEnableFailureModesByModelIdProps): AppThunk<Promise<void>> => async (dispatch, getStore) => {
    dispatch(receiveFailureModes(
        selectorGetFailureModesByModelId(
            getStore(), modelId).map((failureMode) => {
        return { ...failureMode, disabled: false };
    })));
}