import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/Input";
import FormSummaryOutputTypeInputFieldUpdate from "../forms/FormSummaryOutputTypeInputFieldUpdate";
import { ISummaryOutputTypeInputField } from "../../../utilities/types/SummaryOutputTypeInputField";

export interface IModalSummaryOutputTypeInputFieldUpdateProps {
  open: boolean;
  summaryOutputTypeInputField: ISummaryOutputTypeInputField;
  onCancelCallback(): void;
  onCompleteCallback(summaryOutputTypeInputField?: ISummaryOutputTypeInputField): void;
  canEdit: boolean;
}

function ModalSummaryOutputTypeInputFieldUpdate({
  onCancelCallback,
  open,
  canEdit,
  onCompleteCallback,
  summaryOutputTypeInputField,
}: IModalSummaryOutputTypeInputFieldUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <FormSummaryOutputTypeInputFieldUpdate
          canEdit={canEdit}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          summaryOutputTypeInputField={summaryOutputTypeInputField}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSummaryOutputTypeInputFieldUpdate;
