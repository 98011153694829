import { FormControl, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";

interface IAssetInputFieldDisplayStringProps {
  assetInputField: IAssetInputField;
  readOnly?: boolean;
  onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
  defaultValueOverride?: TAssetInputFieldValue;
}

export default function AssetInputFieldDisplayString({
  assetInputField,
  defaultValueOverride,
  onValueChangeCallback,
  readOnly = false,
}: IAssetInputFieldDisplayStringProps) {
  const defaultValue = defaultValueOverride?.valueString || assetInputField.defaultString;

  const [error, setError] = useState("");
  const [value, setValue] = useState(defaultValue);

  function changeValue(e: any) {
    var valueString = e.target?.value;
    setValue(valueString); // Allow empty values

    if (valueString) {
      setError("");
      onValueChangeCallback(assetInputField.assetInputFieldId, { valueString });
    } else setError("A string is required.");
  }

  return (
    <FormControl fullWidth>
      <InputLabel style={{ position: "relative", transform: "translate(0, 1.5px) scale(0.75)" }}>
        {assetInputField.description}
      </InputLabel>
      <TextField
        type="text"
        value={readOnly ? defaultValue : value}
        onChange={changeValue}
        error={!!error}
        helperText={error}
        fullWidth
        disabled={readOnly}
        variant="standard"
      />
    </FormControl>
  );
}
