import { ISolver } from "../../utilities/types/Solver";


export const ACTIONS_SOLVER = {
    INVALIDATE: 'INVALIDATE_SOLVERS',
    RECEIVE: 'RECEIVE_SOLVERS',
    DELETE: 'DELETE_SOLVER',
    UPDATE: 'UPDATE_SOLVERS',
}

interface ActionSolversInvalidate {
    type: typeof ACTIONS_SOLVER.INVALIDATE,
    byId: { [key: string]: ISolver },
}

interface ActionSolversReceive {
    type: typeof ACTIONS_SOLVER.RECEIVE
    byId: { [key: string]: ISolver },
}

export interface SolverState {
    byId: { [key: string]: ISolver },
}

export enum SolverSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolver = ActionSolversReceive | ActionSolversInvalidate;