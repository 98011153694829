import { Chip, TextField, FormHelperText, styled } from "@mui/material";
import React, { useMemo, useState } from "react";

const DivWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  "& .container": {
    marginRight: theme.spacing(1),
    maxHeight: 190,
    overflowY: "auto",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  "& .label": {
    maxWidth: "300px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "240px",
    },
  },
}));

interface StringListInputProps {
  stringList: string[];
  errors?: string | string[];
  onStringListChange: (newStringList: string[]) => void;
  label?: string;
  placeholder?: string;
}

function StringListInput({
  stringList,
  onStringListChange,
  label = "",
  placeholder = "E.g. Bearing",
  errors,
}: StringListInputProps) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleUpdateValues = () => {
    // Split the entire input value by commas
    const newStrings = inputValue.split(",").map((str) => str.trim());
    // Filter out empty strings
    const validStrings = newStrings.filter((str) => str.length > 0);
    if (validStrings.length > 0) {
      const updatedList = [...stringList, ...validStrings];
      onStringListChange(updatedList);
      setInputValue("");
    }
  };

  const handleInputKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "," || e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of the key
      handleUpdateValues();
    }
  };

  const validErrors = useMemo(() => {
    if (errors && Array.isArray(errors)) return errors;
    return [];
  }, [errors]);

  const uniqueValidErrors = useMemo(() => {
    // Filter out invalid strings
    const filteredErrors = validErrors.filter((error: any) => error && error.trim() !== "");

    const uniqueValidErrors: string[] = [];
    for (const error of filteredErrors) {
      if (!uniqueValidErrors.includes(error)) {
        uniqueValidErrors.push(error);
      }
    }
    return uniqueValidErrors;
  }, [validErrors]);

  return (
    <DivWrapper>
      <TextField
        fullWidth
        margin="normal"
        label={label}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleUpdateValues}
        onKeyPress={handleInputKeyPress}
        variant="standard"
      />
      <div className="container">
        {stringList.map((string, index) => (
          <Chip
            key={index}
            label={string}
            classes={{
              label: "label",
            }}
            color={validErrors?.length && validErrors[index] ? "secondary" : "default"}
            variant={validErrors?.length && validErrors[index] ? "outlined" : undefined}
            onDelete={() => {
              const updatedList = stringList.filter((_, i) => i !== index);
              onStringListChange(updatedList);
            }}
          />
        ))}
      </div>
      {errors && typeof errors === "string" && <FormHelperText error>{errors}</FormHelperText>}
      {uniqueValidErrors.length > 0 &&
        uniqueValidErrors.slice(0, 3).map((error: string, i) => (
          <FormHelperText key={1} error>
            {error}
          </FormHelperText>
        ))}
    </DivWrapper>
  );
}

export default StringListInput;
