import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormTaskImpactUpdate from "../forms/FormTaskImpactUpdate";
import { ITaskImpact } from "../../../utilities/types/TaskImpact";
import MapIcon from "@mui/icons-material/MapRounded";

export interface IModalTaskImpactUpdateProps {
  open: boolean;
  taskImpact: ITaskImpact;
  onCancelCallback(): void;
  onCompleteCallback(taskImpact?: ITaskImpact): void;
  canEdit: boolean;
}

function ModalTaskImpactUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  taskImpact,
}: IModalTaskImpactUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update an impact"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormTaskImpactUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          taskImpact={taskImpact}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalTaskImpactUpdate;
