import { IAssetJobTask } from "../../utilities/types/AssetJobTask";

export const ACTIONS_ASSET_JOB_TASK = {
  INVALIDATE: "INVALIDATE_ASSET_JOBS_TASK",
  RECEIVE: "RECEIVE_ASSET_JOBS_TASK",
  DELETE: "DELETE_ASSET_JOB_TASK",
  UPDATE: "UPDATE_ASSET_JOBS_TASK",
};

interface ActionAssetJobTasksInvalidate {
  type: typeof ACTIONS_ASSET_JOB_TASK.INVALIDATE;
  byId: { [key: string]: IAssetJobTask };
}

interface ActionAssetJobTasksReceive {
  type: typeof ACTIONS_ASSET_JOB_TASK.RECEIVE;
  byId: { [key: string]: IAssetJobTask };
}

export interface AssetJobTasksState {
  byId: { [key: string]: IAssetJobTask };
}

export enum AssetJobTasksSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type ActionTypesAssetJobTasks = ActionAssetJobTasksReceive | ActionAssetJobTasksInvalidate;
