import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchCostBundlesProps, fetchSearchCostBundles } from "../../redux/costBundle/actions";
import { ICostBundle } from "../../utilities/types/CostBundle";


interface IUseFetchCostBundlesPageHookProps extends IFetchSearchCostBundlesProps {
    minPageNumberToFetch: number;
}


export const useFetchCostBundlesPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchCostBundlesPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ICostBundle[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ICostBundle[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var costBundles = await dispatch(fetchSearchCostBundles({
                    pageSize,
                    pageNumber,
                    modelId,
                })) as unknown as ICostBundle[];

                if (costBundles && costBundles.length) {
                    setMorePages(costBundles.length >= pageSize)
                    setLastResultSet(costBundles);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.costBundleId === x.costBundleId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}