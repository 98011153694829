import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssetIcon from "@mui/icons-material/Apps";
import DashboardIcon from "@mui/icons-material/DashboardTwoTone";
import AdminIcon from "@mui/icons-material/Lock";
import SolverIcon from "@mui/icons-material/Functions";
import BuildIcon from "@mui/icons-material/Build";
import OutputIcon from "@mui/icons-material/ExitToApp";
import HomeIcon from "@mui/icons-material/Home";
import ArcitectureIcon from "@mui/icons-material/Cake";

import { ScrollPretty } from "../../utilities/Styles";
import SidebarBackgroundimage from "../../assets/img/mining-site.jpg";
import { RootState } from "../../redux";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { DrawerWidth } from "./LayoutConstants";
import { GetUserId } from "../../utilities/ApiUtils";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: 0,
  bottom: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  border: 0,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const ToolbarDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  zIndex: 3,
  "& svg": {
    color: "#FFF",
  },
  "& .logoLink": {
    height: "20px",
    paddingLeft: theme.spacing(2),
    marginRight: "auto",
    marginLeft: "auto",
  },
}));

const SidebarList = styled(List, { shouldForwardProp: (prop: any) => prop !== "open" })<{ open: boolean }>(
  ({ theme, open }) => ({
    zIndex: 3,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    flex: 1,
    color: "#FFF",
    overflowY: "auto",
    "& svg": {
      color: "#FFF",
    },
    "& a": {
      transition: "background-color 400ms ease-in-out",
    },
    ...(!open && {
      "& a": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    }),
    "& a:hover": {
      backgroundColor: "rgba(255,255,255, 0.1)",
    },
    "& a.active": {
      backgroundColor: "rgba(255,255,255, 0.1)",
    },
  })
);

const SidebarBgImage = styled("div")({
  backgroundImage: `url(${SidebarBackgroundimage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  "& .sidebarBackgroundImageOverlay": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: "rgba(57,71,77,0.6)",
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop: any) => prop !== "open" })(({ theme, open }) => ({
  width: DrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...ScrollPretty,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    "& .MuiDrawer-paper .logo": {
      height: "15px",
      opacity: 1,
      transition: "opacity 1500ms ease-in-out",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .MuiDrawer-paper .logo": {
      opacity: 0,
      visibility: "hidden",
    },
  }),
}));

interface ILayoutSidebarProps {
  open: boolean;
  setDrawerOpen(open: boolean): void;
}

const LayoutSidebar = ({ open, setDrawerOpen }: ILayoutSidebarProps) => {
  const solverJobDashboard = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.PageSolverJobsDashboard)
  );
  const adminDash = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.PageAdminDashboard)
  );
  const toolsView = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.PageToolsSearch)
  );

  return (
    <Drawer variant="permanent" open={open}>
      <ToolbarDiv>
        <NavLink to="/" className="logoLink">
          <img src="/logo_light.png" alt="AssetWiki" className="logo" />
        </NavLink>
        <IconButton onClick={() => setDrawerOpen(!open)}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </ToolbarDiv>
      <SidebarList open={open}>
        <ListItemButton id="btn-sidebar-home" key="home" component={NavLink} to="/home">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton id="btn-sidebar-models" key="models" component={NavLink} to="/search">
          <ListItemIcon>
            <AssetIcon />
          </ListItemIcon>
          <ListItemText primary="Assets" />
        </ListItemButton>
        <ListItemButton id="btn-sidebar-solvers" key="solvers" component={NavLink} to="/solvers">
          <ListItemIcon>
            <SolverIcon />
          </ListItemIcon>
          <ListItemText primary="Solvers" />
        </ListItemButton>
        <ListItemButton id="btn-sidebar-outputs" key="outputs" component={NavLink} to="/outputs">
          <ListItemIcon>
            <OutputIcon />
          </ListItemIcon>
          <ListItemText primary="Outputs" />
        </ListItemButton>
        {toolsView && (
          <ListItemButton id="btn-sidebar-outputs" key="tools" component={NavLink} to="/tools">
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary="Tools" />
          </ListItemButton>
        )}
        {solverJobDashboard && (
          <ListItemButton key="dashboard" component={NavLink} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
        {adminDash && (
          <ListItemButton key="Workflow" component={NavLink} to="/Workflow">
            <ListItemIcon>
              <ArcitectureIcon />
            </ListItemIcon>
            <ListItemText primary="Workflows" />
          </ListItemButton>
        )}
        {adminDash && (
          <ListItemButton key="admin" component={NavLink} to="/admin">
            <ListItemIcon>
              <AdminIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItemButton>
        )}
      </SidebarList>
      <SidebarBgImage>
        <div className="sidebarBackgroundImageOverlay"></div>
      </SidebarBgImage>
    </Drawer>
  );
};

export default LayoutSidebar;
