import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import RouteAssetJob from "./RouteAssetJob";

const RouteAssetJobs = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:assetJobId`}>
        <RouteAssetJob />
      </Route>
    </Switch>
  );
};

export default RouteAssetJobs;
