import React, { useState } from "react";
import { Grid, Button, styled } from "@mui/material";

import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch, useSelector } from "react-redux";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel } from "../../utilities/types/Model";
import { green } from "@mui/material/colors";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import ModalTaskCreate from "./modals/ModalTaskCreate";
import { selectorGetModelById } from "../../redux/model/selectors";
import { RootState } from "../../redux";

const GridWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
  "& .addComponentButton": {
    color: green[500],
    fontWeight: 500,
    textTransform: "capitalize",
    marginRight: theme.spacing(3),
    transition: "background 200ms ease-out",
    width: "100%",
  },
}));

type ITaskModelNewTaskProps = {
  modelId: string;
};

const ModelBuilderNewTask = ({ modelId }: ITaskModelNewTaskProps) => {
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  const [loading] = useState(false);
  const dispatch = useDispatch();
  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));

  function toggleShowCreateTaskModal() {
    setShowCreateTaskModal(!showCreateTaskModal);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setShowCreateTaskModal(!showCreateTaskModal);
  }

  return (
    <GridWrapper container>
      <Grid item xs={12}>
        <Button
          id="btn-create-Task"
          variant="outlined"
          className="addComponentButton"
          onClick={toggleShowCreateTaskModal}
        >
          <AddCategoryIcon style={{ marginRight: "8px" }} /> Suggest a new task
        </Button>
      </Grid>
      <ModalTaskCreate
        open={showCreateTaskModal}
        onCancelCallback={toggleShowCreateTaskModal}
        onCompleteCallback={onCompleteCallback}
        modelId={model?.modelId ?? ""}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </GridWrapper>
  );
};

export default ModelBuilderNewTask;
