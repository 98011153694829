import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IAssetInputFieldListValue } from "../../utilities/types/AssetInputFieldListValue";
import { IFetchSearchAssetInputFieldListValuesProps, fetchSearchAssetInputFieldListValues } from "../../redux/assetInputFieldListValue/actions";


interface IUseFetchAssetInputFieldListValuesPageHookProps extends IFetchSearchAssetInputFieldListValuesProps {
    minPageNumberToFetch: number;
}


export const useFetchAssetInputFieldListValuesPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchAssetInputFieldListValuesPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IAssetInputFieldListValue[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IAssetInputFieldListValue[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var assetInputFieldListValues = await dispatch(fetchSearchAssetInputFieldListValues({
                    pageSize,
                    pageNumber,
                    modelId,
                })) as unknown as IAssetInputFieldListValue[];

                if (assetInputFieldListValues && assetInputFieldListValues.length) {
                    setMorePages(assetInputFieldListValues.length >= pageSize)
                    setLastResultSet(assetInputFieldListValues);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.assetInputFieldId === x.assetInputFieldId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}