import { AssetInputFieldTypeEnum, IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { ISolverInputField, SolverInputFieldTypeEnum } from "../../utilities/types/SolverInputField";
import { TSolverInputFieldValue } from "../../utilities/types/SolverJob";
import { TSummaryOutputTypeInputFieldValue } from "../../utilities/types/SummaryOutputType";
import { ISummaryOutputTypeInputField, SummaryOutputTypeInputFieldTypeEnum } from "../../utilities/types/SummaryOutputTypeInputField";

export function getSolverDefaultFieldValue(inputField: ISolverInputField) {
    var fieldValue: TSolverInputFieldValue = {};
    switch (inputField.type) {
        case SolverInputFieldTypeEnum.Number:
            fieldValue.value = inputField.defaultValue
            break;
        case SolverInputFieldTypeEnum.CustomEnumeration:
            fieldValue.value = inputField.defaultValue
            fieldValue.solverInputFieldValueId = inputField.defaultSolverInputFieldListValueId
            break;
        case SolverInputFieldTypeEnum.DateTime:
            fieldValue.valueDateTime = inputField.defaultDateTime
            break;
        case SolverInputFieldTypeEnum.String:
            fieldValue.valueString = inputField.defaultString
            break;
    }
    return fieldValue
}

export function getAssetDefaultFieldValue(inputField: IAssetInputField) {
    var fieldValue: TAssetInputFieldValue = {};
    switch (inputField.type) {
        case AssetInputFieldTypeEnum.Number:
            fieldValue.value = inputField.defaultValue
            break;
        case AssetInputFieldTypeEnum.CustomEnumeration:
            fieldValue.value = inputField.defaultValue
            fieldValue.assetInputFieldValueId = inputField.defaultAssetInputFieldListValueId
            break;
        case AssetInputFieldTypeEnum.DateTime:
            fieldValue.valueDateTime = inputField.defaultDateTime
            break;
        case AssetInputFieldTypeEnum.String:
            fieldValue.valueString = inputField.defaultString
            break;
    }
    return fieldValue
}

export function getSummaryOutputTypeDefaultFieldValue(inputField: ISummaryOutputTypeInputField) {
    var fieldValue: TSummaryOutputTypeInputFieldValue = {};
    switch (inputField.type) {
        case SummaryOutputTypeInputFieldTypeEnum.Number:
            fieldValue.value = inputField.defaultValue
            break;
        case SummaryOutputTypeInputFieldTypeEnum.CustomEnumeration:
            fieldValue.value = inputField.defaultValue
            fieldValue.summaryOutputTypeInputFieldValueId = inputField.defaultSummaryOutputTypeInputFieldListValueId
            break;
        case SummaryOutputTypeInputFieldTypeEnum.DateTime:
            fieldValue.valueDateTime = inputField.defaultDateTime
            break;
        case SummaryOutputTypeInputFieldTypeEnum.String:
            fieldValue.valueString = inputField.defaultString
            break;
    }
    return fieldValue
}