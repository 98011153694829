import React, { useState } from "react";
import { Typography, styled } from "@mui/material";
import GreyColors from "@mui/material/colors/grey";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import ModalCommentCreate from "./modals/ModalCommentCreate";
import { IDiscussion } from "../../utilities/types/Discussion";
import { IComment } from "../../utilities/types/Comment";
import ModalDiscussionUpdate from "../discussion/modals/ModalDiscussionUpdate";
import ModalCommentUpdate from "./modals/ModalCommentUpdate";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .action-wrapper": {
    marginTop: theme.spacing(0.6),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.8),
    "& .dots": {
      display: "inline-block",
      width: 4,
      height: 4,
      borderRadius: 4,
      backgroundColor: GreyColors[400],
    },
  },
}));

interface CommentActionsProps {
  canEdit: boolean;
  commentLevel: number;
  entityId: string;
  entityType: EntityTypeEnum;
  discussionOrComment: IDiscussion | IComment;
  isComment: boolean;
  handleRefetch: () => void;
  markAsResolved?: () => void;
  showResolvedAction?: boolean;
  isUpdating?: boolean;
}

const CommentActions: React.FC<CommentActionsProps> = ({
  canEdit,
  commentLevel,
  entityId,
  entityType,
  discussionOrComment,
  isComment,
  showResolvedAction,
  isUpdating,
  markAsResolved,
  handleRefetch,
}) => {
  const [showCreateCommentModal, setShowCreateCommentModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const toggleShowEditModal = () => setShowEditModal((prev) => !prev);
  const toggleCreateModal = () => setShowCreateCommentModal((prev) => !prev);

  return (
    <DivWrapper>
      <div className="action-wrapper">
        {commentLevel <= 4 && (
          <Typography
            variant="caption"
            className="linkText"
            onClick={() => {
              toggleCreateModal();
            }}
          >
            Reply
          </Typography>
        )}

        {canEdit && (
          <>
            {commentLevel <= 4 && <div className="dots" />}
            <Typography
              variant="caption"
              className="linkText"
              onClick={() => {
                toggleShowEditModal();
              }}
            >
              Edit
            </Typography>
            {showResolvedAction && markAsResolved && (
              <>
                <div className="dots" />
                <Typography
                  variant="caption"
                  className="linkText"
                  onClick={() => {
                    if (!isUpdating) markAsResolved();
                  }}
                >
                  Mark As Resolved
                </Typography>
              </>
            )}
          </>
        )}
      </div>

      <ModalCommentCreate
        open={showCreateCommentModal}
        onCancelCallback={toggleCreateModal}
        onCompleteCallback={() => {
          handleRefetch();
          toggleCreateModal();
        }}
        discussionId={discussionOrComment.discussionId}
        parentId={isComment ? (discussionOrComment as IComment).commentId : ""}
        entityId={entityId}
        entityType={entityType}
      />
      {isComment ? (
        <ModalCommentUpdate
          open={showEditModal}
          comment={discussionOrComment as IComment}
          onCancelCallback={toggleShowEditModal}
          onCompleteCallback={() => {
            handleRefetch();
            toggleShowEditModal();
          }}
          canEdit={canEdit}
        />
      ) : (
        <ModalDiscussionUpdate
          open={showEditModal}
          onCancelCallback={toggleShowEditModal}
          onCompleteCallback={() => {
            handleRefetch();
            toggleShowEditModal();
          }}
          discussion={discussionOrComment as IDiscussion}
          canEdit={canEdit}
        />
      )}
    </DivWrapper>
  );
};

export default CommentActions;
