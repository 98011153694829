import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormSolverInputFieldCreate from "../forms/FormSolverInputFieldCreate";
import { ISolverInputField } from "../../../utilities/types/SolverInputField";
import HeaderIcon from "@mui/icons-material/InputTwoTone";

export interface IModalSolverInputFieldCreateProps {
  open: boolean;
  onCancelCallback(): void;
  solverId: string;
  onCompleteCallback(solverInputField?: ISolverInputField): void;
}

function ModalSolverInputFieldCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  solverId,
}: IModalSolverInputFieldCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <FormSolverInputFieldCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          solverId={solverId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSolverInputFieldCreate;
