import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchEventsProps, fetchSearchEvents } from "../../redux/event/actions";
import { IEvent } from "../../utilities/types/Event";


interface IUseFetchEventsPageHookProps extends IFetchSearchEventsProps {
    minPageNumberToFetch: number;
}


export const useFetchEventsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchEventsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IEvent[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IEvent[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var events = await dispatch(fetchSearchEvents({
                    pageSize,
                    pageNumber,
                    modelId,
                })) as unknown as IEvent[];

                if (events && events.length) {
                    setMorePages(events.length >= pageSize)
                    setLastResultSet(events);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.eventId === x.eventId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}