import { TextField } from "@mui/material";
import React, { useState } from "react";
import { DEFAULT_DECIMAL_PLACES, useDecimalValue } from "../../utilities/inputFields/numberFieldUtils";
import { TSummaryOutputTypeInputFieldValue } from "../../utilities/types/SummaryOutputType";
import { ISummaryOutputTypeInputField } from "../../utilities/types/SummaryOutputTypeInputField";

interface ISummaryOutputTypeInputFieldDisplayNumberProps {
  summaryOutputTypeInputField: ISummaryOutputTypeInputField;
  disabled?: boolean;
  onValueChangeCallback(fieldId: string, value: TSummaryOutputTypeInputFieldValue): void;
  overriddenDefaultValue?: TSummaryOutputTypeInputFieldValue;
}

function SummaryOutputTypeInputFieldDisplayNumber({
  summaryOutputTypeInputField,
  overriddenDefaultValue,
  onValueChangeCallback,
  disabled = false,
}: ISummaryOutputTypeInputFieldDisplayNumberProps) {
  const defaultValue = overriddenDefaultValue?.value || summaryOutputTypeInputField.defaultValue;
  const decimalPlaces = summaryOutputTypeInputField.decimalPlaces || DEFAULT_DECIMAL_PLACES;

  const [error, setError] = useState("");
  const [value, setValue] = useDecimalValue(defaultValue, decimalPlaces);

  function changeValue(e: any) {
    if (isNaN(e.target.value)) {
      setError("A valid number is required.");
      return;
    }

    setValue(e.target.value); // Allow empty values

    var value = Number(e.target.value);
    if (value < summaryOutputTypeInputField.minValue || value > summaryOutputTypeInputField.maxValue) {
      setError(
        `Must be a value between ${summaryOutputTypeInputField.minValue} and ${summaryOutputTypeInputField.maxValue}`
      );
      return;
    }

    setError("");
    onValueChangeCallback(summaryOutputTypeInputField.summaryOutputTypeInputFieldId, { value });
  }

  return (
    <TextField
      id={`txt-${summaryOutputTypeInputField.summaryOutputTypeInputFieldId}`}
      type="number"
      label={summaryOutputTypeInputField.label}
      value={value}
      inputProps={{
        min: summaryOutputTypeInputField.minValue,
        max: summaryOutputTypeInputField.maxValue,
      }}
      onChange={changeValue}
      error={error !== ""}
      helperText={error}
      fullWidth
      variant="outlined"
      {...(disabled === true ? { disabled } : null)}
    />
  );
}

export default SummaryOutputTypeInputFieldDisplayNumber;
