import BuildIcon from "@mui/icons-material/CategoryTwoTone";
import React, { useState } from "react";
import { ISolverDetails } from "../../utilities/types/SolverDetails";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "@mui/material";
import { fetchUpdateSolverDetails } from "../../redux/solverDetails/actions";
import { useDispatch } from "react-redux";

type SolverDetailsOverviewDisplayProps = {
  solverDetails?: ISolverDetails;
  canEdit: boolean;
};

const SolverDetailsOverviewDisplay = ({ solverDetails, canEdit }: SolverDetailsOverviewDisplayProps) => {
  const dispatch = useDispatch();
  const [changesMade, setchangesMade] = useState(false);

  const saveDetails = () => {
    setchangesMade(false);
    dispatch(
      fetchUpdateSolverDetails({
        ...solverDetails,
      })
    );
  };
  if (!solverDetails) {
    return null;
  }

  return (
    <WidgetSectionBase headerText="Overview" subheaderText="" headerIcon={<BuildIcon />}>
      <div style={{ maxHeight: "400px", overflowY: "scroll", overflowX: "hidden" }}>
        <ReactQuill
          readOnly={!canEdit}
          modules={{ clipboard: { matchVisual: false } }}
          value={solverDetails.overview || ""}
          style={{ maxHeight: "600px" }}
          onChange={(text: string) => {
            solverDetails.overview = text;
            setchangesMade(true);
          }}
        />
      </div>
      {canEdit ? (
        <div style={{ textAlign: "end" }}>
          <Button color="primary" variant="outlined" onClick={saveDetails} style={{ marginTop: 16 }}>
            {changesMade ? "Unsaved Changes" : "Saved"}
          </Button>
        </div>
      ) : null}
    </WidgetSectionBase>
  );
};

export default SolverDetailsOverviewDisplay;
