import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchComponentsProps, fetchSearchComponents } from "../../redux/component/actions";
import { IComponent } from "../../utilities/types/Component";
import { IFetchSearchAiSuggestComponentProps, fetchSearchAiSuggestComponent } from "../../redux/aiSuggestComponent/actions";

interface IUseFetchComponentsPageHookProps extends IFetchSearchComponentsProps {
  minPageNumberToFetch: number;
}

export const useFetchComponentsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchComponentsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IComponent[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IComponent[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var components = (await dispatch(
          fetchSearchComponents({
            pageSize,
            pageNumber,
            modelId,
          })
        )) as unknown as IComponent[];

        if (components && components.length) {
          setMorePages(components.length >= pageSize);
          setLastResultSet(components);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.componentId === x.componentId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};

interface IUseFetchAiSuggestComponentPageHookProps extends IFetchSearchAiSuggestComponentProps {
  minPageNumberToFetch: number;
}

export interface ISelectedComponent extends IComponent {
  selected?: boolean;
}

export const useFetchAiSuggestComponentPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchAiSuggestComponentPageHookProps) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IComponent[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<ISelectedComponent[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var components = (await fetchSearchAiSuggestComponent({
          pageSize,
          pageNumber,
          modelId,
        })) as unknown as IComponent[];

        if (components && components.length) {
          setMorePages(components.length >= pageSize);
          setLastResultSet(components);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, pageNumber, pageSize, modelId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.componentId === x.componentId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
