import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import BuildIcon from "@mui/icons-material/DeveloperModeRounded";
import FormFailureModeCreate from "../forms/FormFailureModeCreate";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import { selectorGetComponentByFunctionalFailureId } from "../../../redux/component/selectors";
import { RootState } from "../../../redux";

export interface IModalFailureModeCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  functionalFailureId?: string;
  onCompleteCallback(failureMode?: IFailureMode): void;
}

function ModalFailureModeCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  functionalFailureId,
}: IModalFailureModeCreateProps) {
  const dispatch = useDispatch();
  const component = useSelector((store: RootState) =>
    selectorGetComponentByFunctionalFailureId(store, functionalFailureId ?? "")
  );

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new failure mode(s)"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormFailureModeCreate
          componentName={component?.name ?? ""}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          functionalFailureId={functionalFailureId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFailureModeCreate;
