import React, { useState } from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import HeaderIcon from "@mui/icons-material/FolderOpen";
import ModalFileTable from "./modals/ModalFileTable";

const AdminFileListWidget = () => {
  const [fileListModalOpen, setFileListModalOpen] = useState(false);

  const handleFileListModalToggle = () => setFileListModalOpen(!fileListModalOpen);

  return (
    <>
      <WidgetSummaryCountAndButtons
        key="AdminFileListWidget"
        headerIcon={<HeaderIcon />}
        captionText="View Files"
        mainText="File List"
        buttons={[
          <Button variant="outlined" size="small" key="btn-browse" onClick={handleFileListModalToggle}>
            View Files
          </Button>,
        ]}
      />
      <ModalFileTable handleClose={handleFileListModalToggle} open={fileListModalOpen} />
    </>
  );
};

export default AdminFileListWidget;
