import { IUserPermission } from '../../utilities/types/UserPermission'
import { ACTIONS_USER_PERMISSION } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, AddQueryStringsToUrl, CheckStatus, ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { selectorGetUserPermissionsByUserId } from './selectors';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';


export const receiveUserPermissions = (userPermissions: IUserPermission[]) => {

    var byIdObjectToDispatch: { [key: string]: IUserPermission } = {};

    for (var i = 0; i < userPermissions.length; i++) {
        byIdObjectToDispatch[userPermissions[i].userPermissionId] = userPermissions[i];
    }

    return {
        type: ACTIONS_USER_PERMISSION.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteUserPermission = (userPermission: IUserPermission) => ({
    type: ACTIONS_USER_PERMISSION.DELETE,
    byId: { [userPermission.userPermissionId]: userPermission }
});



export interface IFetchSearchUserPermissions {
    userPermissionId?: string,
    pageSize?: number,
    pageNumber?: number,
    userDetailId?: string,
}


export const fetchSearchUserPermissions = ({ pageSize, pageNumber, userPermissionId, userDetailId }: IFetchSearchUserPermissions): AppThunk<Promise<IUserPermission[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/userPermissions`, {
            userDetailId,
            pageSize,
            pageNumber,
            userPermissionId,
        }), {
            method: 'GET',
            headers: headers
        });

        var json = await CheckStatus(apiResponse);
        if (json && json.success && json.data) {

            if (json.data.userPermissions && json.data.userPermissions.length > 0) {
                dispatch(receiveUserPermissions(json.data.userPermissions));
            }
        }

        return json;
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving user permissions.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export const fetchUserPermissionsByUserIdIfNeeded = (userDetailId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetUserPermissionsByUserId(getState(), userDetailId)?.length) {
        await dispatch(fetchSearchUserPermissions(
            {
                userDetailId,
                pageNumber: 1,
                pageSize: 500,
            }));
    }

    return;
}


export interface IFetchCreateUserPermissionsProps {
    userPermissionTypeIds: number[],
    targetUserId: string,
}


export const fetchCreateUserPermissions = ({ userPermissionTypeIds, targetUserId }: IFetchCreateUserPermissionsProps): AppThunk<Promise<IUserPermission>> => async (dispatch, getState) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/userPermissions`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody({ userPermissionTypeIds, targetUserId }),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userPermissions) {

            // Remove any existing permissions for the user
            var existingPermissions = selectorGetUserPermissionsByUserId(getState(), targetUserId);
            for (var existingPermission of existingPermissions) {
                dispatch(requestDeleteUserPermission(existingPermission));
            }

            dispatch(receiveUserPermissions(parsedResp.data.userPermissions));
            return parsedResp.data.userPermissions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating user permission.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating user permission.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}