import { ActionTypesPlan, PlanState, ACTIONS_PLAN } from "./types";

const initialState: PlanState = {
    byId: {},
}


export function plansReducer(
    state = initialState,
    action: ActionTypesPlan
): PlanState {
    switch(action.type){
        case ACTIONS_PLAN.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_PLAN.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_PLAN.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}