import { ACTIONS_EVENT, EventSearchOrderTypeEnum } from "./types";
import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { PrepareBody, ShowError } from "../../utilities/Helpers";

import { AppThunk } from "..";
import { Configuration } from "../../utilities/Constants";
import { IApiResponse } from "../../utilities/types/Api";
import { IEvent } from "../../utilities/types/Event";

export const receiveEvents = (events: IEvent[]) => {
  var byIdObjectToDispatch: { [key: string]: IEvent } = {};

  for (var i = 0; i < events.length; i++) {
    byIdObjectToDispatch[events[i].eventId] = events[i];
  }

  return {
    type: ACTIONS_EVENT.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteEvent = (event: IEvent) => ({
  type: ACTIONS_EVENT.DELETE,
  byId: { [event.eventId]: event },
});

export interface IFetchCreateEventProps {
  modelId: string;
  name: string;
  costBundleId: string;
  taskId: string;
}

export interface IFetchCreateEventsProps {
  events: IFetchCreateEventProps[];
}

export const fetchCreateEvent =
  (eventToCreate: IFetchCreateEventsProps): AppThunk<Promise<IEvent>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/events`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(eventToCreate),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.events) {
        dispatch(receiveEvents(parsedResp.data.events));
        return parsedResp.data.events;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating event.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating event.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchUpdateEventProps {
  eventId: string;
  name: string;
  description: string;
  costBundleId: string;
  taskId: string;
}

export const fetchUpdateEvent =
  (props: IFetchUpdateEventProps): AppThunk<Promise<IEvent>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/events`, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.events) {
        dispatch(receiveEvents(parsedResp.data.events));
        return parsedResp.data.events;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error updating event.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error updating event.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchEventsProps {
  pageNumber: number;
  pageSize: number;
  modelId: string;
  eventId?: string;
  text?: string;
  createdBy?: string;
  orderType?: EventSearchOrderTypeEnum;
}

export const fetchSearchEvents =
  (searchParams: IFetchSearchEventsProps): AppThunk<Promise<IEvent[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/events`, searchParams), {
        method: "GET",
        headers: headers,
      });

      // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.events) {
        dispatch(receiveEvents(parsedResp.data.events));
        return parsedResp.data.events;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching event.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching event.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

export interface IFetchDeleteEventProps {
  eventId: string;
}

export const fetchDeleteEvent =
  (props: IFetchDeleteEventProps): AppThunk<Promise<IEvent>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/events`, props), {
        method: "DELETE",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.events && parsedResp.data.events.length) {
        dispatch(requestDeleteEvent(parsedResp.data.events[0]));
        return parsedResp.data.events[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting event.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting event.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
