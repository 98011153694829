import React, { useState } from "react";
import {
  Typography,
  Grid,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel } from "../../utilities/types/Model";
import { ITask, TaskTypeEnum } from "../../utilities/types/Task";
import { selectorGetTasksByModelId } from "../../redux/task/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { fetchSearchTasks } from "../../redux/task/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalTaskUpdate from "./modals/ModalTaskUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/AssignmentTwoTone";
import { GetTaskLinkByTask } from "../../routes/RouteLinkHelpers";
import MoreDetailsIcon from "@mui/icons-material/LinkTwoTone";
import MenuIcon from "@mui/icons-material/MenuTwoTone";
import EditTaskIcon from "@mui/icons-material/EditTwoTone";
import InspectIcon from "@mui/icons-material/SearchTwoTone";
import CorrectiveIcon from "@mui/icons-material/GavelTwoTone";
import ModelBuilderNewTask from "./ModelBuilderNewTask";
import { StepLabourEnum } from "../../utilities/types/TaskApplicability";
import ModalTaskApplicabilityCreateMultiple from "../taskApplicability/modals/ModalTaskApplicabilityCreateMultple";
import ModalTaskImpactCreateMultiple from "../taskImpact/modals/ModalTaskImpactCreateMultple";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .taskWrapper": {
    padding: theme.spacing(1),
    position: "relative",
  },
  "& .taskMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
}));

type ITaskModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
};

const TaskModelBuilderTab = ({ modelId, canEdit }: ITaskModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const tasks = useSelector((store: RootState) => selectorGetTasksByModelId(store, modelId));
  const onUploadComplete = async (taskId: string) => {
    await dispatch(fetchSearchTasks({ taskId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <TaskModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      tasks={tasks}
      loading={false}
      onUploadComplete={onUploadComplete}
    />
  );
};

type ITaskModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  tasks: ITask[];
  loading: boolean;
  onUploadComplete(taskId: string): void;
};

const TaskModelBuilderTabDisplay = ({
  model,
  tasks,
  loading,
  canEdit,
  onUploadComplete,
}: ITaskModelBuilderTabDisplayProps) => {
  const [taskToUpdate, setTaskToUpdate] = useState<ITask>();
  const [showUpdateTaskModal, setShowUpdateTaskModal] = useState(false);
  const [showAddApplicabilitiesModal, setShowAddApplicabilitiesModal] = useState(false);
  const [showAddImpactsModal, setShowAddImpactsModal] = useState(false);

  function onTaskClick(task: ITask, menuItem: string) {
    setTaskToUpdate(task);
    if (menuItem === "clickAddApplicabilities") setShowAddApplicabilitiesModal(true);
    if (menuItem === "clickAddImpacts") setShowAddImpactsModal(true);
    if (menuItem === "clickEdit") setShowUpdateTaskModal(true);
  }

  function hideTaskUpdate() {
    setTaskToUpdate(undefined);
    setShowUpdateTaskModal(false);
  }
  function hideAddApplicabilities() {
    setTaskToUpdate(undefined);
    setShowAddApplicabilitiesModal(false);
  }
  function hideAddImpacts() {
    setTaskToUpdate(undefined);
    setShowAddImpactsModal(false);
  }

  if (!model) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <DivWrapper>
      <ModelBuilderNewTask modelId={model.modelId} />

      {loading ? (
        <LoaderAbsoluteCentred loading={loading} />
      ) : (
        <AnimationWrapper>
          <div>
            {tasks.map((task) => {
              return (
                <React.Fragment key={task.taskId}>
                  <ModelBuilderTask
                    task={task}
                    canEdit={canEdit}
                    onUploadComplete={onUploadComplete}
                    onTaskClick={onTaskClick}
                    modelName={model.title}
                  />
                  <Divider light={true} />
                </React.Fragment>
              );
            })}
            {(!tasks || !tasks.length) && (
              <WidgetNoResultsPlaceholder text="No tasks" icon={PlaceholderIcon} flip={true} />
            )}
          </div>
        </AnimationWrapper>
      )}

      {/* Update */}
      {showUpdateTaskModal && taskToUpdate && (
        <ModalTaskUpdate
          open={showUpdateTaskModal !== undefined}
          onCancelCallback={hideTaskUpdate}
          onCompleteCallback={hideTaskUpdate}
          task={taskToUpdate}
          canEdit={canEdit}
        />
      )}
      {showAddApplicabilitiesModal && (
        <ModalTaskApplicabilityCreateMultiple
          open={showAddApplicabilitiesModal === true}
          onCancelCallback={hideAddApplicabilities}
          onCompleteCallback={hideAddApplicabilities}
          taskId={taskToUpdate?.taskId ?? ""}
          modelId={model.modelId}
        />
      )}
      {showAddImpactsModal && (
        <ModalTaskImpactCreateMultiple
          open={showAddImpactsModal === true}
          onCancelCallback={hideAddImpacts}
          onCompleteCallback={hideAddImpacts}
          taskId={taskToUpdate?.taskId ?? ""}
          modelId={model.modelId}
        />
      )}
    </DivWrapper>
  );
};

interface IModelBuilderTaskProps {
  task: ITask;
  modelName: string;
  canEdit: boolean;
  onUploadComplete(taskId: string): void;
  onTaskClick(task: ITask, menuItem: string): void;
}

function ModelBuilderTask({ task, onTaskClick, canEdit, modelName }: IModelBuilderTaskProps) {
  const [loading] = useState(false);

  async function onTaskClickWrapper(event: any, menuItem: string) {
    setMenuAnchorEl(undefined);
    onTaskClick(task, menuItem);
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function viewTaskDetails() {
    window.open(GetTaskLinkByTask(task, modelName), "_blank");
  }

  return (
    <Grid container className="taskWrapper">
      <Grid item xs={1}>
        {task.type === TaskTypeEnum.Impact ? <CorrectiveIcon /> : <InspectIcon />}
      </Grid>
      <Grid item xs={8}>
        <div className="taskMainWrapper">
          <div>
            <Typography variant="body1" style={{ textTransform: "capitalize" }}>
              {task.name}
            </Typography>
            <Typography variant="caption">{task.description}</Typography>
            <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
              Labour:{" "}
            </Typography>
            <Typography variant="caption" component="span" color="textSecondary">
              {StepLabourEnum[task.primaryLabour]}
            </Typography>
            <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
              Ramp Time:{" "}
            </Typography>
            <Typography variant="caption" component="span" color="textSecondary">
              {task.rampTime}
            </Typography>
            <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
              Duration:{" "}
            </Typography>
            <Typography variant="caption" component="span" color="textSecondary">
              {task.duration}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={3} style={{ textAlign: "right" }}>
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(undefined)}
        >
          <MenuItem onClick={viewTaskDetails}>
            <ListItemIcon>
              <MoreDetailsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Full Details" />
          </MenuItem>
          {canEdit
            ? [
                <MenuItem key="editTask" onClick={(event) => onTaskClickWrapper(event, "clickEdit")}>
                  <ListItemIcon>
                    <EditTaskIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Edit task" />
                </MenuItem>,
                <MenuItem
                  key="addApplicabilities"
                  onClick={(event) => onTaskClickWrapper(event, "clickAddApplicabilities")}
                >
                  <ListItemIcon>
                    <EditTaskIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Bulk Add Applicabilities" />
                </MenuItem>,
                <MenuItem key="addImpacts" onClick={(event) => onTaskClickWrapper(event, "clickAddImpacts")}>
                  <ListItemIcon>
                    <EditTaskIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Bulk Add Impacts" />
                </MenuItem>,
              ]
            : null}
        </Menu>
      </Grid>
      <LoaderAbsoluteCentred loading={loading} />
    </Grid>
  );
}

export default TaskModelBuilderTab;
