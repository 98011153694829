import { IIdentifierMapping } from "../../utilities/types/IdentifierMapping";


export const ACTIONS_IDENTIFIER_MAPPING = {
    INVALIDATE: 'INVALIDATE_IDENTIFIER_MAPPINGS',
    RECEIVE: 'RECEIVE_IDENTIFIER_MAPPINGS',
    DELETE: 'DELETE_IDENTIFIER_MAPPING',
    UPDATE: 'UPDATE_IDENTIFIER_MAPPINGS',
    DELETE_MANY: 'DELETE_MANY_IDENTIFIER_MAPPING',
}

interface ActionIdentifierMappingsInvalidate {
    type: typeof ACTIONS_IDENTIFIER_MAPPING.INVALIDATE,
    byId: { [key: string]: IIdentifierMapping },
}

interface ActionIdentifierMappingsReceive {
    type: typeof ACTIONS_IDENTIFIER_MAPPING.RECEIVE
    byId: { [key: string]: IIdentifierMapping },
}

export interface IdentifierMappingState {
    byId: { [key: string]: IIdentifierMapping },
}

export enum IdentifierMappingSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesIdentifierMapping = ActionIdentifierMappingsReceive | ActionIdentifierMappingsInvalidate;