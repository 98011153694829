import { ISolverInputField } from "../../utilities/types/SolverInputField";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetSolverInputFieldListValuesBySolverInputFieldId } from "../../redux/solverInputFieldListValue/selectors";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { TSolverInputFieldValue } from "../../utilities/types/SolverJob";

interface ISolverInputFieldDisplayEnumerationProps {
  onValueChangeCallback(fieldId: string, value: TSolverInputFieldValue): void;
  solverInputField: ISolverInputField;
  disabled?: boolean;
  overriddenDefaultValue?: TSolverInputFieldValue;
}

function SolverInputFieldDisplayEnumeration({
  solverInputField,
  disabled,
  onValueChangeCallback,
  overriddenDefaultValue,
}: ISolverInputFieldDisplayEnumerationProps) {
  const enumerationFieldValues = useSelector((store: RootState) =>
    selectorGetSolverInputFieldListValuesBySolverInputFieldId(store, solverInputField.solverInputFieldId)
  );
  const labelRef: any = useRef();
  // const labelWidth = labelRef.current !== undefined ? (labelRef.current as any).clientWidth : 0;
  const [error, setError] = useState("");
  const [currentVal, setCurrentVal] = useState(solverInputField.defaultValue);

  function changeValue(e: any) {
    if (isNaN(e.target.value)) {
      setError("A valid number is required.");
      return;
    }

    var index = Number(e.target.value);

    setError("");
    setCurrentVal(index);

    // Invoke callback
    onValueChangeCallback(solverInputField.solverInputFieldId, {
      value: enumerationFieldValues[index].value,
      solverInputFieldValueId: enumerationFieldValues[index].solverInputFieldListValueId,
    });
  }

  // Determine which value should be populated
  useEffect(() => {
    var defaultValueId = overriddenDefaultValue
      ? overriddenDefaultValue.solverInputFieldValueId
      : solverInputField.defaultSolverInputFieldListValueId;
    var defaultValueIndex = enumerationFieldValues.findIndex((x) => x.solverInputFieldListValueId === defaultValueId);
    setCurrentVal(defaultValueIndex);
  }, [enumerationFieldValues, solverInputField, overriddenDefaultValue]);

  return (
    <FormControl fullWidth={true} variant="outlined">
      <InputLabel id={`lbl-field-${solverInputField.solverInputFieldId}`} ref={labelRef} variant="standard">
        {solverInputField.label}
      </InputLabel>
      <Select
        labelId="lbl-model-type"
        style={{ display: "block", overflow: "hidden" }}
        id="txt-model-type"
        value={currentVal}
        error={!enumerationFieldValues.length || error !== ""}
        {...(disabled === true ? { disabled } : null)}
        onChange={changeValue}
        variant="standard"
      >
        {enumerationFieldValues.map((enumerationFieldValue, i) => {
          return (
            <MenuItem key={i} value={i}>
              {enumerationFieldValue.displayText}
            </MenuItem>
          );
        })}
      </Select>
      {error ? <FormHelperText error={error !== ""}>{error}</FormHelperText> : null}
      {!enumerationFieldValues.length ? <FormHelperText>Edit this field to add options.</FormHelperText> : null}
    </FormControl>
  );
}

export default SolverInputFieldDisplayEnumeration;
