import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/Input";
import FormAssetInputFieldUpdate from "../forms/FormAssetInputFieldUpdate";
import { IAssetInputField } from "../../../utilities/types/AssetInputField";

export interface IModalAssetInputFieldUpdateProps {
  open: boolean;
  assetInputField: IAssetInputField;
  onCancelCallback(): void;
  onCompleteCallback(assetInputField?: IAssetInputField): void;
  canEdit: boolean;
}

function ModalAssetInputFieldUpdate({
  onCancelCallback,
  open,
  canEdit,
  onCompleteCallback,
  assetInputField,
}: IModalAssetInputFieldUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <FormAssetInputFieldUpdate
          canEdit={canEdit}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          assetInputField={assetInputField}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalAssetInputFieldUpdate;
