import { EntityTypeEnum } from "./Entity";

export interface IEntityPermission {
    entityPermissionId: string,
    type: EntityPermissionTypeEnum,
    userDetailId: string,
    entityId: string,
    entityType: EntityTypeEnum,
}

export enum EntityPermissionTypeEnum {
    Contributor = 10,
}