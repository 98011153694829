import { IModel } from "../../utilities/types/Model";

export const ACTIONS_MODEL = {
    INVALIDATE: 'INVALIDATE_MODELS',
    RECEIVE: 'RECEIVE_MODELS',
    UPDATE: 'UPDATE_MODELS',
    DELETE: 'DELETE_MODELS',
}

interface ActionModelsInvalidate {
    type: typeof ACTIONS_MODEL.INVALIDATE,
    byId: { [key: string]: IModel },
}

interface ActionModelsReceive {
    type: typeof ACTIONS_MODEL.RECEIVE
    byId: { [key: string]: IModel },
}

interface ActionModelsUpdate {
    type: typeof ACTIONS_MODEL.UPDATE
    byId: { [key: string]: IModel },
}

interface ActionModelsDelete {
    type: typeof ACTIONS_MODEL.DELETE
    byId: { [key: string]: IModel },
}

export interface ModelState {
    byId: { [key: string]: IModel },
}

export type ActionTypesModel = ActionModelsInvalidate | ActionModelsReceive | ActionModelsUpdate | ActionModelsDelete;