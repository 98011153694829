export enum LinkModeTypeEnum {
  Component = 10,
  Function = 20,
  FunctionalFailure = 30,
  FailureMode = 40,
  Decision = 50,
  TaskApplicability = 60,
  Model = 70,
  CostBundle = 80,
  Plan = 90,
}
