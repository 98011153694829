import Message from "../../utilities/types/Message";
import { Snackbar as MuiSnackbar, IconButton, styled } from "@mui/material";
import { green, amber } from "@mui/material/colors";
import React from "react";
import { requestMessageDelete } from "../../redux/message/actions";
import { useSelector, useDispatch } from "react-redux";
import { selectorGetMessageNewest } from "../../redux/message/selectors";
import { RootState } from "../../redux";
import Close from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import SuccessIcon from "@mui/icons-material/CheckCircle";

const Snackbar = styled(MuiSnackbar)(({ theme }) => ({
  "&.success": {
    backgroundColor: green[600],
  },
  "&.error": {
    backgroundColor: theme.palette.error.dark,
  },
  "&.info": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&.warning": {
    backgroundColor: amber[700],
  },
  "& .icon": {
    fontSize: 20,
  },
  "& .iconVariant": {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  "& .message": {
    display: "flex",
    alignItems: "center",
  },
}));

function MessagesContainer() {
  const message = useSelector((state: RootState) => selectorGetMessageNewest(state));
  const dispatch = useDispatch();

  if (message == null) return null;

  const getClassForType = (message: Message) => {
    if (message.type === 0) return "error";
    if (message.type === 1) return "success";
    if (message.type === 2) return "info";
    if (message.type === 3) return "warning";

    return "";
  };

  const getIconForMessage = (message: Message) => {
    if (message.type === 0) return <ErrorIcon className="icon iconVariant" />;
    if (message.type === 1) return <SuccessIcon className="icon iconVariant" />;
    if (message.type === 2) return <InfoIcon className="icon iconVariant" />;
    if (message.type === 3) return <WarningIcon className="icon iconVariant" />;

    console.error(`Invalid message type: ${message.type}`);
  };

  const handleClose = (reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(requestMessageDelete(message));
  };

  const handleExited = () => {
    dispatch(requestMessageDelete(message));
  };

  // Render using the provided custom renderer
  return (
    <Snackbar
      key={message.messageId}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={message != null}
      autoHideDuration={6000}
      onClose={(e: any, reason: string) => handleClose(reason)}
      TransitionProps={{
        onExited: handleExited,
      }}
      className={getClassForType(message)}
      ContentProps={{
        "aria-describedby": "message-id",
        className: getClassForType(message),
      }}
      message={
        <span id="message-id" className="message">
          {getIconForMessage(message)} {message.text}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => dispatch(requestMessageDelete(message))}
        >
          <Close />
        </IconButton>,
      ]}
    />
  );
}

export default MessagesContainer;
