import { ACTIONS_IDENTIFIER, IdentifierSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IIdentifier } from '../../utilities/types/Identifier';


export const receiveIdentifiers = (identifiers: IIdentifier[]) => {

    var byIdObjectToDispatch: { [key: string]: IIdentifier } = {};

    for (var i = 0; i < identifiers.length; i++) {
        byIdObjectToDispatch[identifiers[i].identifierId] = identifiers[i];
    }

    return {
        type: ACTIONS_IDENTIFIER.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteIdentifier = (identifier: IIdentifier) => ({
    type: ACTIONS_IDENTIFIER.DELETE,
    byId: { [identifier.identifierId]: identifier }
});


export interface IFetchCreateIdentifierProps {
    modelId?: string,
    name: string,
    code: string,
    orderNumber: number,
}

export interface IFetchCreateIdentifiersProps {
    identifiers: IFetchCreateIdentifierProps[]
}


export const fetchCreateIdentifier = (identifiersToCreate: IFetchCreateIdentifiersProps): AppThunk<Promise<IIdentifier>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/identifiers`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(identifiersToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifiers) {
            dispatch(receiveIdentifiers(parsedResp.data.identifiers));
            return parsedResp.data.identifiers;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating identifier.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating identifier.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateIdentifierProps {
    identifierId: string,
    name: string,
    code: string,
    orderNumber: number,
}


export const fetchUpdateIdentifier = (props: IFetchUpdateIdentifierProps): AppThunk<Promise<IIdentifier>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/identifiers`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifiers) {
            dispatch(receiveIdentifiers(parsedResp.data.identifiers));
            return parsedResp.data.identifiers;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating identifier.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating identifier.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchIdentifiersProps {
    pageNumber: number,
    pageSize: number,
    modelId?: string,
    identifierId?: string,
    text?: string,
    createdBy?: string,
    orderType?: IdentifierSearchOrderTypeEnum,
}


export const fetchSearchIdentifiers = (searchParams: IFetchSearchIdentifiersProps): AppThunk<Promise<IIdentifier[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/identifiers`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifiers) {
            dispatch(receiveIdentifiers(parsedResp.data.identifiers));
            return parsedResp.data.identifiers;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching identifier.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching identifier.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteIdentifierProps {
    identifierId: string,
}


export const fetchDeleteIdentifier = (props: IFetchDeleteIdentifierProps): AppThunk<Promise<IIdentifier>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/identifiers`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.identifiers && parsedResp.data.identifiers.length) {
            dispatch(requestDeleteIdentifier(parsedResp.data.identifiers[0]));
            return parsedResp.data.identifiers[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting identifier.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting identifier.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}