import { RootState } from "..";
import { IAssetJob } from "../../utilities/types/AssetJobs";

export function selectorGetAssetJobsByModelId(state: RootState, modelId: string): IAssetJob[] {
  if (!state.assetJobs || !state.assetJobs.byId) return [];

  return sortAssetJobsByCreatedDescending(Object.values(state.assetJobs.byId).filter((x) => x.modelId === modelId));
}

export function selectorGetAssetJobs(state: RootState): IAssetJob[] {
  if (!state.assetJobs || !state.assetJobs.byId) return [];

  return sortAssetJobsByCreatedDescending(Object.values(state.assetJobs.byId));
}

export function selectorGetAssetJobById(state: RootState, solverJobId: string): IAssetJob | undefined {
  if (!state.assetJobs || !state.assetJobs.byId) return undefined;

  return state.assetJobs.byId[solverJobId];
}

export function sortAssetJobsByCreatedDescending(assetJobs: IAssetJob[]) {
  return assetJobs.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}
