import { ITaskApplicability } from "../../utilities/types/TaskApplicability";


export const ACTIONS_TASK_APPLICABILITY = {
    INVALIDATE: 'INVALIDATE_TASK_APPLICABILITIES',
    RECEIVE: 'RECEIVE_TASK_APPLICABILITIES',
    DELETE: 'DELETE_TASK_APPLICABILITY',
    UPDATE: 'UPDATE_TASK_APPLICABILITIES',
}

interface ActionTaskApplicabilitiesInvalidate {
    type: typeof ACTIONS_TASK_APPLICABILITY.INVALIDATE,
    byId: { [key: string]: ITaskApplicability },
}

interface ActionTaskApplicabilitiesReceive {
    type: typeof ACTIONS_TASK_APPLICABILITY.RECEIVE
    byId: { [key: string]: ITaskApplicability },
}

export interface TaskApplicabilityState {
    byId: { [key: string]: ITaskApplicability },
}

export enum TaskApplicabilitySearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesTaskApplicability = ActionTaskApplicabilitiesReceive | ActionTaskApplicabilitiesInvalidate;