import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { NavLink } from "react-router-dom";

export const HomeIconLink = () => {
  return (
    <NavLink
      to="/"
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: 20,
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <HomeIcon fontSize="inherit" />
    </NavLink>
  );
};
