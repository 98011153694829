export interface ISolverJobTask {
    solverJobTaskId: string,
    solverId: string,
    solverJobId: string,
    assetId: string,
    status: SolverJobTaskStatusEnum,
    started: Date,
    ended?: Date,
    runs: number,
    progressLog: string,
    persistentErrorMessage: string,
    inputBucket: string,
    inputFileKey: string,
    outputBucket: string,
    inputPrefix: string,
    outputPrefix: string,
    summaryOutputBucket: string,
    userId: string,
    preSignedUrl: string,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum SolverJobTaskStatusEnum {
    Pending = 10,
    Progress = 20,
    Failed = 30,
    Complete = 40,
    Cancelled = 50,
}