import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RouteManagerCustom from "./routes";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import store from "./redux";
import HeaderColor from "@mui/material/colors/blueGrey";
import { Amplify } from "aws-amplify";
import { Configuration } from "./utilities/Constants";
import { ScrollPretty } from "./utilities/Styles";
// Initialisation info
console.log(`Environment Name: ${process.env.REACT_APP_ENV_DISPLAY_NAME}`);
console.log(`Cognito Endpoint: ${process.env.REACT_APP_COGNITO_ENDPOINT}`);
console.log(`Cognito Logout Endpoint: ${process.env.REACT_APP_COGNITO_LOGOUT_ENDPOINT}`);

const theme = createTheme({
  palette: {
    primary: {
      main: HeaderColor[800],
    },
    secondary: {
      main: "#E72525",
    },
  },
  typography: {
    allVariants: {
      //fontFamily: 'Open Sans',
      fontFamily:
        "Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans- serif",
    },
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
  },components: {
    MuiCssBaseline: {
      styleOverrides: {
         ...ScrollPretty,
      }
    }
  }
});

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    //identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_Id9H41evf",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "60p855b8rsuh767hlksoad2ojk",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // OPTIONAL - Cookie expiration in days
    expires: 30,
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // OPTIONAL - customized storage object
    //storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    //clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "modla-dev.auth.ap-southeast-2.amazoncognito.com",
      // scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: Configuration.BASE_URL,
      redirectSignOut: Configuration.BASE_URL,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  Analytics: {
    disabled: true,
  },
});

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme} key="app">
        <Provider store={store}>
          <Router>
            <RouteManagerCustom />
          </Router>
        </Provider>
      </ThemeProvider>

      <CssBaseline key="css-baseline" />
    </>
  );
};

export default App;
