import React from "react";
import DialogContent from "@mui/material/DialogContent";
import AssetIcon from "@mui/icons-material/Apps";

import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { IModalAddUpdateAssetNodeProps } from "../types";
import FormAddUpdateAsset from "../forms/FormAddUpdateAsset";

const ModalAddUpdateAssetNode: React.FC<IModalAddUpdateAssetNodeProps> = ({
  assetDetails,
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={assetDetails.open}
      title={assetDetails.isEditMode ? "Update asset" : "Add a new asset"}
      subtitle="Fill in the form to complete."
      headerIcon={<AssetIcon />}
    >
      <DialogContent>
        <FormAddUpdateAsset
          onCancelCallback={onCancelCallback}
          assetDetails={assetDetails}
          onCompleteCallback={onCompleteCallback}
          onDelete={onDelete}
        />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalAddUpdateAssetNode;
