import React, { useState } from "react";
import { Autocomplete } from "@mui/lab";
import {
  TextField,
  Grid,
  Divider,
  FormControlLabel,
  Button,
  Switch,
  FormControl,
  Checkbox,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useSelector } from "react-redux";

import { getFormikFieldProps } from "../../../utilities/Helpers";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

import { RootState } from "../../../redux";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import { fetchCreateFailureMode } from "../../../redux/failureMode/actions";
import ModalEventCreate from "../../event/modals/ModalEventCreate";
import { IEvent } from "../../../utilities/types/Event";
import { selectorGetEventsByModelId } from "../../../redux/event/selectors";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  name: string[];
  description: string;
  orderNumber: number;
  eta: number;
  beta: number;
  gamma: number;
  hidden: boolean;
  functionalFailureId: string;
  eventId: string;
  primary: boolean;
  createCorrespondingEvent: boolean;
  inheritIdentifiers: boolean;
  conditionRelatedMode: boolean;
}

interface FormProps {
  modelId: string;
  functionalFailureId?: string;
  componentName: string;
  eventObj?: IEvent;
  onCompleteCallback(failureMode?: IFailureMode): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IFailureMode, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };

  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  function toggleShowCreateEventModal() {
    setShowCreateEventModal(!showCreateEventModal);
  }

  var events = useSelector((store: RootState) => selectorGetEventsByModelId(store, props.modelId));

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StringListInput
            label="Failure Mode Name(s)"
            placeholder="E.g. Cracked"
            onStringListChange={handleNameChange}
            stringList={values.name}
            errors={props.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={props.values.createCorrespondingEvent}
                onChange={() => {
                  props.setFieldValue("createCorrespondingEvent", !props.values.createCorrespondingEvent);
                }}
                name="CreateEvent"
              />
            }
            label="Create Corresponding Event"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={props.values.inheritIdentifiers}
                onChange={() => {
                  props.setFieldValue("inheritIdentifiers", !props.values.inheritIdentifiers);
                }}
                name="InheritIdentifiers"
              />
            }
            label="Inherit Identifiers"
          />
        </Grid>
        {props.values.createCorrespondingEvent === false && (
          <>
            <Grid item xs={10}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  {...getFormikFieldProps(props, "eventObj", "Event")}
                  style={{ display: "block" }}
                  defaultValue={events.find((element) => {
                    return element.eventId === props.values.eventId;
                  })}
                  options={events}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => {
                    props.setFieldValue("eventId", value ? value.eventId : "");
                    props.setFieldValue("eventObj", value);
                  }}
                  renderInput={(params) => <TextField variant="standard" {...params} label="Event" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={toggleShowCreateEventModal}>
                <AddIcon />
              </IconButton>
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6} style={{ alignSelf: "flex-end" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.hidden}
                onChange={() => props.setFieldValue("hidden", !props.values.hidden)}
                value={true}
                color="primary"
              />
            }
            label="Hidden"
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ alignSelf: "flex-end" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.conditionRelatedMode}
                onChange={() => props.setFieldValue("conditionRelatedMode", !props.values.conditionRelatedMode)}
                value={true}
                color="primary"
              />
            }
            label="Condition Related Mode"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
          <ModalEventCreate
            open={showCreateEventModal}
            onCancelCallback={toggleShowCreateEventModal}
            modelId={props.modelId}
            onCompleteCallback={toggleShowCreateEventModal}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const FormFailureModeCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => ({
    name: [],
    description: "",
    orderNumber: 0,
    functionalFailureId: props.functionalFailureId || "",
    eta: 8760,
    beta: 1,
    gamma: 0,
    hidden: false,
    eventId: "",
    primary: false,
    createCorrespondingEvent: true,
    inheritIdentifiers: true,
    conditionRelatedMode: false,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Label")
        .min(3, "Please input 3 characters or more")
        .max(1000, "Please input 1000 characters or less")
        .required("Please provide a name")
    ),
    functionalFailureId: Yup.string().required(),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const failureModesToCreate = values.name.map((name) => ({
      modelId, // Assuming modelId is obtained from some variable
      name: "[" + props.componentName + "] " + name,
      description: values.description,
      orderNumber: values.orderNumber,
      inheritIdentifiers: values.inheritIdentifiers,
      functionalFailureId: values.functionalFailureId,
      eta: values.eta,
      beta: values.beta,
      gamma: values.gamma,
      hidden: values.hidden,
      eventId: values.eventId,
      primary: values.primary,
      createCorrespondingEvent: values.createCorrespondingEvent,
      conditionRelatedMode: values.conditionRelatedMode,
    }));

    // Map dispatch via props
    var createdFailureMode = await dispatch(
      fetchCreateFailureMode({
        failureModes: failureModesToCreate,
      })
    );

    setSubmitting(false);

    if (createdFailureMode) onCompleteCallback(createdFailureMode);
  },
})(InnerForm);

export default FormFailureModeCreate;
