import { Button, Typography } from "@mui/material";

import HeaderIcon from "@mui/icons-material/AccountTree";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchSearchSolvers } from "../../redux/solver/actions";
import { selectorGetMaxSolverHistoryVersionNumber } from "../../redux/solverHistory/selectors";
import { ShowMessage, UpdateMessage } from "../../utilities/Helpers";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { ISolver } from "../../utilities/types/Solver";
import FileRendererSingle from "../file/renderers/FileRendererSingle";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import SolverHistoryCreateModal from "../solverHistory/modals/ModalSolverHistoryCreate";
import SolverHistoryList from "../solverHistory/SolverHistoryList";
import { selectorGetSolverDetailsBySolverId } from "../../redux/solverDetails/selectors";
import ModalSolverDetailsCreate from "./modals/ModalDetailsCreate";

type SolverDetailsSummaryDisplayProps = {
  solver?: ISolver;
  canEdit: boolean;
};

const SolverDetailsSummaryDisplay = ({ solver, canEdit }: SolverDetailsSummaryDisplayProps) => {
  const [fetchingSolver, setFetchingSolver] = useState(false);
  const dispatch = useDispatch();

  const [solverDetailsModalOpen, setSolverDetailsModalOpen] = useState(false);
  const [solverHistoryCreateModalOpen, setSolverHistoryCreateModalOpen] = useState(false);

  const toggleCreateDetailsModal = () => setSolverDetailsModalOpen(!solverDetailsModalOpen);
  const toggleHistoryCreateModal = () => setSolverHistoryCreateModalOpen(!solverHistoryCreateModalOpen);
  const maxSolverHistoryVersionNumber = useSelector((store: RootState) =>
    selectorGetMaxSolverHistoryVersionNumber(store, solver ? solver.solverId : "")
  );
  const solverDetails = useSelector((store: RootState) =>
    selectorGetSolverDetailsBySolverId(store, solver ? solver.solverId : "")
  );

  if (!solver) {
    return <WidgetNoResultsPlaceholder text="Solver not found" fade={true} icon={HeaderIcon} />;
  }

  // Used to refresh the image link when an image is uploaded
  const onUploadComplete = async () => {
    var message = await ShowMessage("Retrieving solver", MessageTypeEnum.Information);
    setFetchingSolver(true);

    // Fetch the updated solver
    var solvers = await dispatch(fetchSearchSolvers({ solverId: solver.solverId, pageNumber: 1, pageSize: 1 }));
    if (solvers && solvers.length) {
      UpdateMessage({ ...message, text: "Solver updated", type: MessageTypeEnum.Success });
    } else {
      UpdateMessage({ ...message, text: "Unable retrieve updated solver", type: MessageTypeEnum.Error });
    }

    setFetchingSolver(false);
  };

  return (
    <>
      {!solver && <Typography variant="caption">Solver not found.</Typography>}
      {solver && (
        <FileRendererSingle
          fileId={solver.mainImageId}
          canUpload={canEdit}
          fileType={FileTypeEnum.Image}
          entityId={String(solver.solverId)}
          entityType={EntityTypeEnum.Solver}
          height={150}
          onUploadComplete={onUploadComplete}
        />
      )}
      <LoaderAbsoluteCentred loading={fetchingSolver} />

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ width: "90%" }}>
          <SolverHistoryList solverId={solver.solverId} canEdit={canEdit} />
        </div>
        {canEdit ? (
          <Button onClick={toggleHistoryCreateModal}>
            <AddIcon />
          </Button>
        ) : null}
      </div>

      <div
        style={{
          marginTop: 10,
          textAlign: "right",
          flexDirection: "row",
          flexWrap: "nowrap",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flex: "0, 1, auto",
          }}
        >
          {canEdit && solverDetails === undefined ? (
            <Button color="primary" variant="outlined" style={{ marginLeft: 16 }} onClick={toggleCreateDetailsModal}>
              Add Details
            </Button>
          ) : null}
        </div>
      </div>

      {solverHistoryCreateModalOpen && (
        <SolverHistoryCreateModal
          versionNumber={maxSolverHistoryVersionNumber + 1}
          open={solverHistoryCreateModalOpen}
          onCancelCallback={toggleHistoryCreateModal}
          onCompleteCallback={toggleHistoryCreateModal}
          solverId={solver.solverId}
        />
      )}
      {solverDetailsModalOpen && (
        <ModalSolverDetailsCreate
          solverId={solver.solverId}
          open={solverDetailsModalOpen}
          onCancelCallback={toggleCreateDetailsModal}
          onCompleteCallback={toggleCreateDetailsModal}
        />
      )}
    </>
  );
};

export default SolverDetailsSummaryDisplay;
