export interface IAssetJob {
  assetJobId: string;
  modelId: string;
  name: string;
  status: number;
  started: Date;
  ended?: Date | null;
  assetCount: number;
  assetsGenerated: number;
  fileId?: string | null;
  inputBucket: string;
  outputBucket: string;
  terminateOnErrorCount: number;
  inputValues: AssetJobInputValues;
  progressLog: string;
  persistentErrorMessage: string;
  userId: string;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
  prerequisites: {
    assetJobIds: string[];
    outputJobIds: string[];
    solverJobIds: string[];
  };
}

interface AssetJobInputValues {
  assetInputFieldValueMappings: any;
  costBreakoutValueMappings: any;
}

export enum AssetJobStatusEnum {
  Pending = 10,
  Progress = 20,
  Failed = 30,
  Complete = 40,
  Cancelled = 50,
  Generating = 60,
  GeneratingInProgress = 70,
}
