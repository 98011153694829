import { RootState } from "..";
import { ITask } from "../../utilities/types/Task";


export function selectorGetTasksByModelId(state: RootState, modelId: string) : ITask[] {

    if (!state.tasks || !state.tasks.byId) return [];

    return sortTasksByOrderNumberAscending(Object.values(state.tasks.byId).filter(x => x.modelId === modelId));
}


export function selectorGetTaskById(state: RootState, taskId: string) : ITask | undefined {

    if (!state.tasks || !state.tasks.byId) return undefined;

    return state.tasks.byId[taskId];
}


// function sortTasksByOrderNumberDescending(tasks: ITask[]) {
//     return tasks.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortTasksByOrderNumberAscending(tasks: ITask[]) {
    return tasks.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.name < a.name) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}