import { ActionTypesSummaryOutputTypeInputField, SummaryOutputTypeInputFieldState, ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD } from "./types";

const initialState: SummaryOutputTypeInputFieldState = {
    byId: {},
}


export function summaryOutputTypeInputFieldsReducer(
    state = initialState,
    action: ActionTypesSummaryOutputTypeInputField
): SummaryOutputTypeInputFieldState {
    switch (action.type) {
        case ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}