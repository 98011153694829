import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import HeaderIcon from "@mui/icons-material/BubbleChartTwoTone";
import { DialogActions, Button, Divider } from "@mui/material";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { useFetchSolverOutputTypeMappingsPageHook } from "../Hooks";
import { ISingleAssetOutput } from "../../../utilities/types/SingleAssetOutput";
import { fetchCreateSolverOutputTypeMapping } from "../../../redux/solverOutputTypeMapping/actions";
import { useDispatch, useSelector } from "react-redux";
import SolverSingleAssetOutputsPickList from "../SolverSingleAssetOutputsPickList";
import { selectorGetSolverOutputTypeMappingsBySolverId } from "../../../redux/solverOutputTypeMapping/selectors";
import { selectorGetSingleAssetOutputs } from "../../../redux/singleAssetOutput/selectors";
import { RootState } from "../../../redux";
import { useFetchSingleAssetOutputPageHook } from "../../singleAssetOutput/Hooks";

export interface IModalSolverOutputTypeMappingsProps {
  open: boolean;
  onCancelCallback(): void;
  solverId: string;
}

function ModalSolverOutputTypeMappings({ onCancelCallback, open, solverId }: IModalSolverOutputTypeMappingsProps) {
  const dispatch = useDispatch();
  const [selectedSolverOutputTypes, setSelectedOutputTypes] = useState<ISingleAssetOutput[]>([]);
  const [saving, setSaving] = useState(false);
  const { fetching: fetchingValues } = useFetchSolverOutputTypeMappingsPageHook({
    minPageNumberToFetch: 0,
    pageNumber: 1,
    pageSize: 500,
    solverId: solverId,
  });

  const { fetching: fetchingOValues } = useFetchSingleAssetOutputPageHook({
    minPageNumberToFetch: 0,
    pageNumber: 1,
    pageSize: 500,
  });

  const singleAssetOutputs = useSelector((store: RootState) => selectorGetSingleAssetOutputs(store));
  const outputTypeMappings = useSelector((store: RootState) =>
    selectorGetSolverOutputTypeMappingsBySolverId(store, solverId)
  );

  async function onSaveClick() {
    setSaving(true);

    var resp = await dispatch(
      fetchCreateSolverOutputTypeMapping({
        solverId,
        outputTypeIds: selectedSolverOutputTypes.map((x) => x.outputTypeId),
      })
    );

    setSaving(false);
    if (resp !== null) onCancelCallback();
  }

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Manage solver output type mappings"}
      subtitle={"Configure output types that can be used with this solver."}
      headerIcon={<HeaderIcon />}
      style={{ minWidth: 475 }}
    >
      <DialogContent style={{ marginBottom: 16 }}>
        <SolverSingleAssetOutputsPickList
          singleAssetOutputs={singleAssetOutputs}
          outputTypeMappings={outputTypeMappings}
          solverId={solverId}
          onSelectedItemsChange={setSelectedOutputTypes}
        />
        <LoaderAbsoluteCentred loading={fetchingValues || fetchingOValues || saving} />
      </DialogContent>
      <Divider light={true} />
      <DialogActions style={{ marginTop: 8 }}>
        <Button
          variant="outlined"
          // style={{ display: 'flex', flexGrow: 1 }}
          onClick={onCancelCallback}
          fullWidth={true}
        >
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          // style={{ display: 'flex', flexGrow: 1 }}
          onClick={onSaveClick}
          fullWidth={true}
        >
          Submit
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

export default ModalSolverOutputTypeMappings;
