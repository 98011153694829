import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/SettingsTwoTone";
import FormComponentCreate from "../forms/FormComponentCreate";
import { IComponent } from "../../../utilities/types/Component";

export interface IModalComponentCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  onCompleteCallback(component?: IComponent): void;
}

function ModalComponentCreate({ onCancelCallback, open, onCompleteCallback, modelId }: IModalComponentCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new component(s)"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormComponentCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalComponentCreate;
