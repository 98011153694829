import React from 'react';
import RouteModel from './RouteModel';
import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import PageModelSearch from '../components/model/pages/PageModelSearch';


const RouteModels = () => {

    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:modelName/:modelId`}><RouteModel/></Route>
            <Route path={`/search`} render={props => <LayoutDefault routeProps={props}><PageModelSearch {...props} /></LayoutDefault>} />
        </Switch>
    )
}


export default RouteModels;