import { Badge, IconButton } from "@mui/material";

import DisplayIcon from "@mui/icons-material/QuestionAnswerOutlined";
import React, { useState } from "react";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { IModel } from "../../utilities/types/Model";
import ModalDiscussionsList from "./modals/ModalDiscussionsList";

export interface IDiscussionsIconProps {
  discussionCount: number;
  entityType: EntityTypeEnum;
  entityId: string;
  model?: IModel; // used for model contributor requests
  canDiscuss: boolean | undefined;
}

function DiscussionsIcon({ discussionCount, entityId, entityType, canDiscuss }: IDiscussionsIconProps) {
  const [showDiscussionsList, setShowDiscussionsList] = useState(false);
  const toggleDiscussionsList = () => setShowDiscussionsList(!showDiscussionsList);

  return (
    <>
      {canDiscuss ? (
        <IconButton onClick={toggleDiscussionsList}>
          <Badge badgeContent={discussionCount > 9 ? "9+" : discussionCount} color="error">
            <DisplayIcon />
          </Badge>
        </IconButton>
      ) : null}

      {showDiscussionsList && (
        <ModalDiscussionsList
          open={showDiscussionsList}
          onCancelCallback={toggleDiscussionsList}
          onCompleteCallback={toggleDiscussionsList}
          entityId={entityId}
          entityType={entityType}
        />
      )}

      {/* Only model contributor requests currently supported */}
    </>
  );
}

export default DiscussionsIcon;
