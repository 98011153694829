import React from "react";
import { Grid, Divider, Button } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { RootState } from "../../../redux";
import { IComponent } from "../../../utilities/types/Component";
import { fetchCreateComponent } from "../../../redux/component/actions";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  modelId: string;
  description: string;
  orderNumber: number;
  name: string[];
}

interface FormProps {
  modelId: string;
  onCompleteCallback(component?: IComponent): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IComponent, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, errors, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StringListInput label="Component Name(s)" onStringListChange={handleNameChange} stringList={values.name} errors={errors.name} />
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormComponentCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    modelId: "",
    name: [],
    description: "",
    orderNumber: 0,
    primary: true,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Label")
        .min(3, "Please input 3 characters or more")
        .max(500, "Please input 500 characters or less")
        .required("Please provide a name")
    ),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const componentsToCreate = values.name.map((name) => ({
      modelId, // Assuming modelId is obtained from some variable
      name,
      description: values.description,
      orderNumber: values.orderNumber,
    }));

    // Map dispatch via props
    var createdComponent = await dispatch(
      fetchCreateComponent({
        components: componentsToCreate,
      })
    );

    setSubmitting(false);

    if (createdComponent) onCompleteCallback(createdComponent);
  },
})(InnerForm);

export default FormComponentCreate;
