import { RootState } from "..";
import { IFile } from "../../utilities/types/File";
import { EntityTypeEnum } from "../../utilities/types/Entity";

export function selectorGetFileById(state: RootState, fileId: string): IFile | undefined {
  if (!state.files || !state.messages.byId) return undefined;

  return state.files.byId[fileId];
}

export function selectorGetFilesByEntityType(state: RootState, entityType: EntityTypeEnum): IFile[] {
  if (!state.files || !state.files.byId) return [];

  return sortFilesByCreatedDescending(Object.values(state.files.byId).filter((x) => x.entityType === entityType));
}

export function selectorGetFileByEntityTypeAndEntityId(
  state: RootState,
  entityType: EntityTypeEnum,
  entityId: string
): IFile | undefined {
  if (!state.files || !state.files.byId) return undefined;

  return Object.values(state.files.byId).find((x) => x.entityType === entityType && x.entityId === entityId);
}

function sortFilesByCreatedDescending(files: IFile[]) {
  return files.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}
