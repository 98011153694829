import { Typography, IconButton, InputLabel, styled } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import React, { useState } from "react";
import { ISolverInputFieldListValue } from "../../utilities/types/SolverInputFieldListValue";
import { selectorGetSolverInputFieldListValuesBySolverInputFieldId } from "../../redux/solverInputFieldListValue/selectors";
import AddIcon from "@mui/icons-material/AddOutlined";
import ModalSolverInputFieldListValueCreate from "./modals/ModalSolverInputFieldListValueCreate";
import ModalSolverInputFieldListValueUpdate from "./modals/ModalSolverInputFieldListValueUpdate";

const DivWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),

  "& .fieldValueWrapper": {
    border: "1px solid #EEE",
    padding: "3px 5px",
    borderRadius: "4px",
    margin: "5px 0px",
    transition: "background 0.25s ease-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
}));

interface ISolverInputFieldListValueMaintenanceSectionProps {
  canEdit: boolean;
  solverInputFieldId: string;
}

function SolverInputFieldListValueMaintenanceSection({
  solverInputFieldId,
  canEdit,
}: ISolverInputFieldListValueMaintenanceSectionProps) {
  const solverInputFieldListValues = useSelector((store: RootState) =>
    selectorGetSolverInputFieldListValuesBySolverInputFieldId(store, solverInputFieldId)
  );

  return (
    <SolverInputFieldListValueMaintenanceSectionDisplay
      canEdit={canEdit}
      solverInputFieldId={solverInputFieldId}
      solverInputFieldListValues={solverInputFieldListValues}
    />
  );
}

interface ISolverInputFieldListValueMaintenanceSectionDisplayProps {
  canEdit: boolean;
  solverInputFieldId: string;
  solverInputFieldListValues: ISolverInputFieldListValue[];
}

function SolverInputFieldListValueMaintenanceSectionDisplay({
  solverInputFieldListValues,
  solverInputFieldId,
}: ISolverInputFieldListValueMaintenanceSectionDisplayProps) {
  const [showModalSolverInputFormListValueCreate, setShowModalSolverInputFormListValueCreate] = useState(false);
  const [solverInputFieldListValueToEdit, setSolverInputFieldListValueToEdit] = useState<ISolverInputFieldListValue>();

  const toggleShowModalSolverInputFormListValueCreate = () =>
    setShowModalSolverInputFormListValueCreate(!showModalSolverInputFormListValueCreate);

  return (
    <DivWrapper>
      <InputLabel shrink={true}>Values</InputLabel>
      <div>
        {solverInputFieldListValues.map((solverInputFieldListValue) => {
          return (
            <div
              className="fieldValueWrapper"
              onClick={() => setSolverInputFieldListValueToEdit(solverInputFieldListValue)}
            >
              <Typography component="p">{solverInputFieldListValue.displayText}</Typography>
              <Typography component="span" variant="caption" style={{ opacity: 0.75 }}>
                Value is {solverInputFieldListValue.value}
                {solverInputFieldListValue.isDefault ? " (default)" : ""}{" "}
              </Typography>
            </div>
          );
        })}
      </div>

      <div>
        <IconButton onClick={toggleShowModalSolverInputFormListValueCreate}>
          <AddIcon />
        </IconButton>
      </div>
      <ModalSolverInputFieldListValueCreate
        open={showModalSolverInputFormListValueCreate}
        solverInputFieldId={solverInputFieldId}
        onCancelCallback={toggleShowModalSolverInputFormListValueCreate}
        onCompleteCallback={toggleShowModalSolverInputFormListValueCreate}
      />
      {solverInputFieldListValueToEdit && (
        <ModalSolverInputFieldListValueUpdate
          onCancelCallback={() => setSolverInputFieldListValueToEdit(undefined)}
          onCompleteCallback={() => setSolverInputFieldListValueToEdit(undefined)}
          open={solverInputFieldListValueToEdit !== undefined}
          solverInputFieldListValue={solverInputFieldListValueToEdit}
        />
      )}
    </DivWrapper>
  );
}

export default SolverInputFieldListValueMaintenanceSection;
