import { Button, Divider, FormControl, Grid, TextField } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import React, { useState } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import { selectorGetGlobalIdentifiers, selectorGetIdentifiersByModelId } from "../../../redux/identifier/selectors";
import { fetchCreateIdentifierMapping } from "../../../redux/identifierMapping/actions";
import { IIdentifier } from "../../../utilities/types/Identifier";
import { IdentifierMappingTypeEnum, IIdentifierMapping } from "../../../utilities/types/IdentifierMapping";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import ModalIdentifierCreate from "../../identifier/modals/ModalIdentifierCreate";
import IdentifierMappingPicklist from "./PicklistIdentifierMapping";
import { getFormikFieldProps } from "../../../utilities/Helpers";

interface FormValues {
  identifierIds: string[];
  identifierMappingValue?: number;
}

interface FormProps {
  type: IdentifierMappingTypeEnum;
  targetIds: string[];
  modelId: string;
  secondaryTargetId?: string;
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IIdentifierMapping, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  var modelIdentifiers = useSelector((store: RootState) => selectorGetIdentifiersByModelId(store, props.modelId));
  var globalIdentifiers = useSelector((store: RootState) => selectorGetGlobalIdentifiers(store));
  var identifiers = props.targetIds.includes(props.modelId) ? globalIdentifiers : modelIdentifiers;
  const [showCreateIdentifierModal, setShowCreateIdentifierModal] = useState(false);

  function toggleShowCreateIdentifierModal() {
    setShowCreateIdentifierModal(!showCreateIdentifierModal);
  }

  const { setFieldValue } = props;

  const onChange = useCallback(
    (ids: IIdentifier[]) => {
      setFieldValue(
        "identifierIds",
        ids.map((id) => id.identifierId)
      );
    },
    [setFieldValue]
  );

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <IdentifierMappingPicklist identifiers={identifiers} onChange={onChange} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={props.handleChange}
            type="number"
            inputProps={{ step: "0.1", required: false }}
            fullWidth
            defaultValue={0}
            variant="standard"
            margin="normal"
            {...getFormikFieldProps(props, "identifierMappingValue", "Value")}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid container item xs={12}>
          <Button variant="outlined" onClick={toggleShowCreateIdentifierModal}>
            Create
          </Button>
          <div style={{ flex: "1" }} />
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Add
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
          <ModalIdentifierCreate
            open={showCreateIdentifierModal}
            onCancelCallback={toggleShowCreateIdentifierModal}
            modelId={props.modelId}
            onCompleteCallback={toggleShowCreateIdentifierModal}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const FormIdentifierMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    modelId: "",
    identifierIds: [],
    targetIds: [],
  }),
  validationSchema: Yup.object().shape({
    identifierIds: Yup.string().label("Identifiers").required("Please provide at least one identifier"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId, targetIds, type, secondaryTargetId } = props;

    const identifierMappingsToCreate = targetIds.flatMap((target) =>
      values.identifierIds.map((identifier: any) => ({
        modelId, // Assuming modelId is obtained from some variable
        targetId: target,
        identifierId: identifier,
        mappingType: type, // Assuming 'type' is obtained from some variable
        secondaryTargetId: secondaryTargetId,
        identifierMappingValue: values.identifierMappingValue,
      }))
    );

    // Map dispatch via props
    var createdIdentifierMapping = await dispatch(
      fetchCreateIdentifierMapping({
        identifierMappings: identifierMappingsToCreate,
      })
    );

    setSubmitting(false);

    if (createdIdentifierMapping) onCompleteCallback(createdIdentifierMapping);
  },
})(InnerForm);

export default FormIdentifierMappingCreate;
