import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel } from "../../utilities/types/Model";
import { IPlan } from "../../utilities/types/Plan";
import { selectorGetPlansByModelId } from "../../redux/plan/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalPlanCreate from "./modals/ModalPlanCreate";
import { fetchSearchPlans } from "../../redux/plan/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalPlanUpdate from "./modals/ModalPlanUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import MapIcon from "@mui/icons-material/MapRounded";
import EditIcon from "@mui/icons-material/EditTwoTone";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import { EmptyGuid } from "../../utilities/Helpers";

const imageDiameter = 50;

const DivWrapper = styled("div")(({ theme }) => ({
  "& .planWrapper": {
    padding: theme.spacing(1),
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
  },
  "& .planImageWrapper": {
    width: imageDiameter,
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .planMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .btnAddPlan": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

type IPlanModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
};

const PlanModelBuilderTab = ({ modelId, canEdit }: IPlanModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const plans = useSelector((store: RootState) => selectorGetPlansByModelId(store, modelId));

  const onUploadComplete = async (planId: string) => {
    await dispatch(fetchSearchPlans({ planId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <PlanModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      plans={plans}
      loading={false}
      onUploadComplete={onUploadComplete}
    />
  );
};

type IPlanModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  plans: IPlan[];
  loading: boolean;
  onUploadComplete(planId: string): void;
};

const PlanModelBuilderTabDisplay = ({
  model,
  plans,
  loading,
  canEdit,
  onUploadComplete,
}: IPlanModelBuilderTabDisplayProps) => {
  const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);
  const [planToUpdate, setPlanToUpdate] = useState<IPlan>();

  function toggleShowCreatePlanModal() {
    setShowCreatePlanModal(!showCreatePlanModal);
  }

  function onPlanClick(plan: IPlan) {
    setPlanToUpdate(plan);
  }

  function hidePlanUpdate() {
    setPlanToUpdate(undefined);
  }

  if (!model) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <DivWrapper>
        <Button color="primary" variant="outlined" className="btnAddPlan" onClick={toggleShowCreatePlanModal}>
          Add Plan
        </Button>

      {loading ? (
        <LoaderAbsoluteCentred loading={loading} />
      ) : (
        <AnimationWrapper>
          <div>
            {plans.map((plan) => {
              return (
                <React.Fragment key={plan.planId}>
                  <ModelBuilderPlan
                    plan={plan}
                    canEdit={canEdit}
                    onUploadComplete={onUploadComplete}
                    onPlanClick={onPlanClick}
                  />
                  <Divider light={true} />
                </React.Fragment>
              );
            })}
            {(!plans || !plans.length) && <WidgetNoResultsPlaceholder text="No plans" icon={MapIcon} flip={true} />}
          </div>
        </AnimationWrapper>
      )}

      <ModalPlanCreate
        open={showCreatePlanModal}
        onCancelCallback={toggleShowCreatePlanModal}
        onCompleteCallback={toggleShowCreatePlanModal}
        modelId={model.modelId}
      />

      {/* Update */}
      {planToUpdate && (
        <ModalPlanUpdate
          open={planToUpdate !== undefined}
          onCancelCallback={hidePlanUpdate}
          onCompleteCallback={hidePlanUpdate}
          plan={planToUpdate}
          canEdit={canEdit}
        />
      )}
    </DivWrapper>
  );
};

interface IModelBuilderPlanProps {
  plan: IPlan;
  canEdit: boolean;
  onUploadComplete(planId: string): void;
  onPlanClick(plan: IPlan): void;
}

function ModelBuilderPlan({ plan, onPlanClick, canEdit }: IModelBuilderPlanProps) {
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function onEditIdentifierClick() {
    setMenuAnchorEl(undefined);
    onPlanClick(plan);
  }

  return (
    <Grid container className="planWrapper">
      <Grid item xs={9}>
        <div className="planMainWrapper">
          <div>
            <Typography variant="body1">{plan.code}</Typography>
            <Typography variant="caption">
              {plan.name} - {plan.eventInterval}
            </Typography>
          </div>
          <IdentifierMappingsRenderer
              type={IdentifierMappingTypeEnum.Plan}
              targetId={String(plan.planId)}
              modelId={plan.modelId}
              canEdit={canEdit}
              loading={false}
              showValues={true}
              secondaryTargetId={EmptyGuid}
            />
        </div>
      </Grid>
      <Grid item xs={3} style={{ textAlign: "right" }}>
        {canEdit ? (
          <>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              <MenuItem onClick={onEditIdentifierClick}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit Plan" />
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Grid>
      <LoaderAbsoluteCentred loading={loading} />
    </Grid>
  );
}

export default PlanModelBuilderTab;
