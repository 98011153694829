import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, AddQueryStringsToUrl, CheckStatus, ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { selectorGetUserToRoleMappingByUserDetailId } from './selectors';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ACTIONS_USER_TO_ROLE_MAPPING } from './types';
import { IUserToRoleMapping } from '../../utilities/types/UserToRoleMapping';


export const receiveUserToRoleMapping = (userToRoleMapping: IUserToRoleMapping[]) => {

    var byIdObjectToDispatch: { [key: string]: IUserToRoleMapping } = {};

    for (var i = 0; i < userToRoleMapping.length; i++) {
        byIdObjectToDispatch[userToRoleMapping[i].userToRoleMappingId] = userToRoleMapping[i];
    }

    return {
        type: ACTIONS_USER_TO_ROLE_MAPPING.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};

export const requestDeleteUserToRoleMapping = (userToRoleMapping: IUserToRoleMapping) => ({
    type: ACTIONS_USER_TO_ROLE_MAPPING.DELETE,
    byId: { [userToRoleMapping.userToRoleMappingId]: userToRoleMapping }
});



export interface IFetchSearchUserToRoleMapping {
    userToRoleMappingId?: string,
    pageSize?: number,
    pageNumber?: number,
    userDetailId?: string,
    userRoleId?: string,
}


export const fetchSearchUserToRoleMappings = ({ pageSize, pageNumber, userToRoleMappingId, userRoleId, userDetailId }: IFetchSearchUserToRoleMapping): AppThunk<Promise<IUserToRoleMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/userToUserRoles`, {
            userRoleId,
            pageSize,
            pageNumber,
            userToRoleMappingId,
            userDetailId,
        }), {
            method: 'GET',
            headers: headers
        });

        var json = await CheckStatus(apiResponse);
        if (json && json.success && json.data) {

            if (json.data.userToRoleMappings && json.data.userToRoleMappings.length > 0) {
                dispatch(receiveUserToRoleMapping(json.data.userToRoleMappings));
            }
        }

        return json;
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving user permissions.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchCreateUserToRoleMappingProps {
    userRoleIds: string[],
    userDetailId: string,
}


export const fetchCreateUserToUserRoleMappings = ({ userRoleIds, userDetailId }: IFetchCreateUserToRoleMappingProps): AppThunk<Promise<IUserToRoleMapping[]>> => async (dispatch, getState) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/userToUserRoles`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody({ userRoleIds, userDetailId }),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userToRoleMappings) {

            // Remove any existing permissions for the user
            var existingPermissions = selectorGetUserToRoleMappingByUserDetailId(getState(), userDetailId);
            for (var existingPermission of existingPermissions) {
                dispatch(requestDeleteUserToRoleMapping(existingPermission));
            }

            dispatch(receiveUserToRoleMapping(parsedResp.data.userToRoleMappings));
            return parsedResp.data.userToRoleMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating user permission.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating user permission.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteUserToRoleMappingProps {
    userToRoleMappingId: string,
}

export const fetchDeleteUserToUserRoleMappings = (props: IFetchDeleteUserToRoleMappingProps): AppThunk<Promise<IUserToRoleMapping[]>> => async (dispatch, getState) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/identifierMappings`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userToRoleMappings && parsedResp.data.userToRoleMappings.length) {
            dispatch(requestDeleteUserToRoleMapping(parsedResp.data.userToRoleMappings[0]));
            return parsedResp.data.userToRoleMappings[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting identifier mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating user permission.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}