import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchFailureModesProps, fetchSearchFailureModes } from "../../redux/failureMode/actions";
import { IFailureMode } from "../../utilities/types/FailureMode";
import { IFetchSearchAiSuggestFailureModeProps, fetchSearchAiSuggestFailureMode } from "../../redux/aiSuggestFailureMode/actions";

interface IUseFetchFailureModesPageHookProps extends IFetchSearchFailureModesProps {
  minPageNumberToFetch: number;
}

export const useFetchFailureModesPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchFailureModesPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IFailureMode[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IFailureMode[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var failureModes = (await dispatch(
          fetchSearchFailureModes({
            pageSize,
            pageNumber,
            modelId,
          })
        )) as unknown as IFailureMode[];

        if (failureModes && failureModes.length) {
          setMorePages(failureModes.length >= pageSize);
          setLastResultSet(failureModes);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.failureModeId === x.failureModeId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};

export interface ISelectedFailureMode extends IFailureMode {
  selected?: boolean;
}

interface IUseFetchAiSuggestFailureModePageHookProps extends IFetchSearchAiSuggestFailureModeProps {
  minPageNumberToFetch: number;
}

export const useFetchAiSuggestFailureModePageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId, componentId, functionId, functionalFailureId }: IUseFetchAiSuggestFailureModePageHookProps) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IFailureMode[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<ISelectedFailureMode[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        const failureModes = (await fetchSearchAiSuggestFailureMode({
          pageSize,
          pageNumber,
          modelId,
          componentId,
          functionId,
          functionalFailureId,
        })) as unknown as IFailureMode[];

        if (failureModes && failureModes.length) {
          setMorePages(failureModes.length >= pageSize);
          setLastResultSet(failureModes);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, pageNumber, pageSize, modelId, componentId, functionId, functionalFailureId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.functionalFailureId === x.functionalFailureId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
