import React from "react";
import { Grid, Breadcrumbs, Link, styled } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import { GetAdminPage } from "../../../routes/RouteLinkHelpers";
import SingleAssetOutputEnquiryContainer from "../SingleAssetOutputEnquiryContainer";
import SummaryOutputTypeEnquiryContainer from "../../summaryOutputType/SummaryOutputTypeEnquiryContainer";
import { HomeIconLink } from "../../generic/HomeIconLink";

const GridWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

interface IPageOutputManagementProps {
  routeProps: RouteComponentProps<any>;
}

const PageSingleAssetOutputManagement = ({ routeProps }: IPageOutputManagementProps) => {
  return (
    <Container maxWidth="xl">
      <AnimationWrapper>
        <GridWrapper item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIconLink />
            <Link color="inherit" href={GetAdminPage()}>
              Admin
            </Link>
            <Link color="textPrimary" aria-current="page" style={{ textDecoration: "none" }}>
              Outputs
            </Link>
          </Breadcrumbs>
        </GridWrapper>
      </AnimationWrapper>
      <AnimationWrapper>
        <Grid container spacing={5} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={6}>
            <SingleAssetOutputEnquiryContainer canEdit={true} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SummaryOutputTypeEnquiryContainer canEdit={true} />
          </Grid>
        </Grid>
      </AnimationWrapper>
    </Container>
  );
};

export default PageSingleAssetOutputManagement;
