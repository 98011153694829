import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchSearchSolverJobs, IFetchSearchSolverJobsProps } from "../../redux/solverJob/actions";
import { ISolverJob } from "../../utilities/types/SolverJob";
import { useLoadNewModels } from "../model/Hooks";

interface IUseFetchSolverJobsPageHookProps extends IFetchSearchSolverJobsProps {
  minPageNumberToFetch: number;
  loadNewModals?: boolean;
  onlyFetchByIds?: boolean;
}

export const useFetchSolverJobsPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
  modelId,
  solverJobId,
  status,
  text,
  assetJobId,
  onlyFetchByIds = false,
  loadNewModals = true,
}: IUseFetchSolverJobsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<ISolverJob[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<ISolverJob[]>([]);
  const [reFetch, setReFetch] = useState<boolean>(false);

  useEffect(() => {
    // This allows us to prsolverJob initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    if (onlyFetchByIds && !solverJobId) {
      return;
    }

    (async () => {
      setFetching(true);

      if (reFetch) setReFetch(false);

      try {
        var solverJobs = (await dispatch(
          fetchSearchSolverJobs({
            pageSize,
            pageNumber,
            status,
            text,
            modelId,
            solverJobId,
            assetJobId,
          })
        )) as unknown as ISolverJob[];

        if (solverJobs && solverJobs.length) {
          setMorePages(solverJobs.length >= pageSize);
          setLastResultSet(solverJobs);
        } else {
          setLastResultSet([]);
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [
    minPageNumberToFetch,
    dispatch,
    pageNumber,
    pageSize,
    modelId,
    solverJobId,
    assetJobId,
    status,
    text,
    onlyFetchByIds,
    reFetch,
  ]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.solverJobId === x.solverJobId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  // Load associated models for metadata like images etc
  useLoadNewModels(loadNewModals ? allResultsSet.map((x) => x.modelId) : []);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
    setReFetchSolverJob: setReFetch,
  };
};
