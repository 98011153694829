import React from "react";
import Collapse from "@mui/material/Collapse";

import { useDispatch } from "react-redux";
import FormCommentUpdate from "../forms/FormCommentUpdate";
import { IComment } from "../../../utilities/types/Comment";

export interface IModalCommentUpdateProps {
  open: boolean;
  comment: IComment;
  onCancelCallback(): void;
  onCompleteCallback(): void;
  canEdit: boolean;
}

function ModalCommentUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  comment,
  canEdit,
}: IModalCommentUpdateProps) {
  const dispatch = useDispatch();

  return (
    <Collapse in={open}>
      <FormCommentUpdate
        dispatch={dispatch}
        onCancelCallback={onCancelCallback}
        onCompleteCallback={onCompleteCallback}
        comment={comment}
        canEdit={canEdit}
      />
    </Collapse>
  );
}

export default ModalCommentUpdate;
