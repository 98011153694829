import React from "react";
import { TSummaryOutputTypeInputFieldValue } from "../../utilities/types/SummaryOutputType";
import { ISummaryOutputTypeInputField, SummaryOutputTypeInputFieldTypeEnum } from "../../utilities/types/SummaryOutputTypeInputField";
import SummaryOutputTypeInputFieldDisplayDateTime from "./SummaryOutputTypeInputFieldDisplayDateTime";
import SummaryOutputTypeInputFieldDisplayNumber from "./SummaryOutputTypeInputFieldDisplayNumber";
import SummaryOutputTypeInputFieldDisplayString from "./SummaryOutputTypeInputFieldDisplayString";
import SummaryOutputTypeInputFieldDisplayEnumeration from "./SummaryOutputTypeInputFieldEnumeration";


interface ISummaryOutputTypeInputFieldDisplayProps {
    summaryOutputTypeInputField: ISummaryOutputTypeInputField,
    disabled?: boolean,
    onValueChangeCallback(fieldId: string, value: TSummaryOutputTypeInputFieldValue): void,
    overriddenDefaultValue?: TSummaryOutputTypeInputFieldValue,
}

function SummaryOutputTypeInputFieldDisplay(props: ISummaryOutputTypeInputFieldDisplayProps) {
    switch (props.summaryOutputTypeInputField.type) {
        case SummaryOutputTypeInputFieldTypeEnum.Number:
            return <SummaryOutputTypeInputFieldDisplayNumber {...props} />;
        case SummaryOutputTypeInputFieldTypeEnum.CustomEnumeration:
            return <SummaryOutputTypeInputFieldDisplayEnumeration {...props} />;
        case SummaryOutputTypeInputFieldTypeEnum.String:
            return <SummaryOutputTypeInputFieldDisplayString {...props} />;
        case SummaryOutputTypeInputFieldTypeEnum.DateTime:
            return <SummaryOutputTypeInputFieldDisplayDateTime {...props} />;
        default:
            return <>Invalid field type</>;
    }
}


export default SummaryOutputTypeInputFieldDisplay;