import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/AssignmentTwoTone";
import FormTaskCreate from "../forms/FormTaskCreate";
import { ITask } from "../../../utilities/types/Task";

export interface IModalTaskCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  onCompleteCallback(task?: ITask): void;
}

function ModalTaskCreate({ onCancelCallback, open, onCompleteCallback, modelId }: IModalTaskCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new task(s)"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormTaskCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalTaskCreate;
