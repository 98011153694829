import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetAssetInputFieldListValuesByAssetInputFieldId } from "../../redux/assetInputFieldListValue/selectors";
import { IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";

interface IAssetInputFieldDisplayEnumerationProps {
  onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
  assetInputField: IAssetInputField;
  readOnly?: boolean;
  defaultValueOverride?: TAssetInputFieldValue;
}

function AssetInputFieldDisplayEnumeration({
  assetInputField,
  readOnly: disabled,
  defaultValueOverride,
  onValueChangeCallback,
}: IAssetInputFieldDisplayEnumerationProps) {
  const enumerationFieldValues = useSelector((store: RootState) =>
    selectorGetAssetInputFieldListValuesByAssetInputFieldId(store, assetInputField.assetInputFieldId)
  );
  const [error, setError] = useState("");
  const [currentVal, setCurrentVal] = useState(assetInputField.defaultValue);

  function changeValue(e: any) {
    if (isNaN(e.target.value)) {
      setError("A valid number is required.");
      return;
    }

    var index = Number(e.target.value);

    setError("");
    setCurrentVal(index);

    // Invoke callback
    onValueChangeCallback(assetInputField.assetInputFieldId, {
      value: enumerationFieldValues[index].value,
      assetInputFieldValueId: enumerationFieldValues[index].assetInputFieldListValueId,
    });
  }

  useEffect(() => {
    var defaultValueId = defaultValueOverride
      ? defaultValueOverride.assetInputFieldValueId
      : assetInputField.defaultAssetInputFieldListValueId;
    var defaultValueIndex = enumerationFieldValues.findIndex((x) => x.assetInputFieldListValueId === defaultValueId);

    setCurrentVal(defaultValueIndex);
  }, [enumerationFieldValues, assetInputField, defaultValueOverride]);

  return (
    <FormControl fullWidth={true}>
      <InputLabel
        style={{ position: "relative", transform: "translate(0, 1.5px) scale(0.75)" }}
        id={`lbl-field-${assetInputField.assetInputFieldId}`}
        variant="standard"
      >
        {assetInputField.description}
      </InputLabel>
      <Select
        labelId="lbl-model-type"
        style={{ display: "block", marginTop: "0px" }}
        id="txt-model-type"
        value={currentVal}
        error={!enumerationFieldValues.length || error !== ""}
        {...(disabled === true ? { disabled } : null)}
        onChange={changeValue}
        variant="standard"
      >
        {enumerationFieldValues.map((enumerationFieldValue, i) => {
          return (
            <MenuItem key={enumerationFieldValue.assetInputFieldListValueId} value={i}>
              {enumerationFieldValue.displayText}
            </MenuItem>
          );
        })}
      </Select>
      {error ? <FormHelperText error={error !== ""}>{error}</FormHelperText> : null}
      {!enumerationFieldValues.length ? <FormHelperText>Edit this field to add options.</FormHelperText> : null}
    </FormControl>
  );
}

export default AssetInputFieldDisplayEnumeration;
