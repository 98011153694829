import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import CategoryIcon from "@mui/icons-material/Category";
import { IAssetInputFieldCategory } from "../../../utilities/types/AssetInputFieldCategory";
import FormAssetInputFieldCategoryUpdate from "../forms/FormAssetInputFieldCategoryUpdate";

export interface IModalAssetInputFieldCategoryUpdateProps {
  open: boolean;
  assetInputFieldCategory: IAssetInputFieldCategory;
  onCancelCallback(): void;
  onCompleteCallback(file?: IAssetInputFieldCategory): void;
}

function ModalAssetInputFieldCategoryUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  assetInputFieldCategory,
}: IModalAssetInputFieldCategoryUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a field category"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<CategoryIcon />}
    >
      <DialogContent>
        <FormAssetInputFieldCategoryUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          {...assetInputFieldCategory}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalAssetInputFieldCategoryUpdate;
