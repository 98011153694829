import { RootState } from "..";
import { ISolverUIResultSetChart } from "../../utilities/types/SolverUIResultSetChart";


export function selectorGetSolverUIResultSetChartsBySolverJobTaskId(state: RootState, solverJobTaskId: string) : ISolverUIResultSetChart[] {

    if (!state.SolverUIResultSetCharts || !state.SolverUIResultSetCharts.byId) return [];

    return sortEventsByNameAscending(Object.values(state.SolverUIResultSetCharts.byId).filter(x => x.solverJobTaskId === solverJobTaskId));
}


export function selectorGetSolverUIResultSetChartById(state: RootState, SolverUIResultSetChartId: string) : ISolverUIResultSetChart | undefined {

    if (!state.SolverUIResultSetCharts || !state.SolverUIResultSetCharts.byId) return undefined;

    return state.SolverUIResultSetCharts.byId[SolverUIResultSetChartId];
}

function sortEventsByNameAscending(charts: ISolverUIResultSetChart[]) {
    return charts.sort((a, b) => b.name > a.name  ? -1 : 1);
}


export function selectorGetSolverUIResultSetChartsBySolverJobTaskIds(state: RootState, solverJobTaskIds: string[]) : ISolverUIResultSetChart[] {

    if (!state?.SolverUIResultSetCharts?.byId) return [];

    return Object.values(state.SolverUIResultSetCharts.byId).filter(x => solverJobTaskIds.some(y => y === x.solverJobTaskId));
}
