import { Button, Divider, FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import { default as React } from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import { fetchSearchAssetInputFields } from "../../../redux/assetInputField/actions";
import { fetchSearchAssetInputFieldCategories } from "../../../redux/assetInputFieldCategory/actions";
import { fetchCreateCostBundle } from "../../../redux/costBundle/actions";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  name: string[];
  variants: string[];
  code: string;
  disabled: boolean;
  cost: number;
  orderNumber: number;
  createCorrespondingInput: boolean;
}

interface FormProps {
  modelId: string;
  onCompleteCallback(costBundle?: ICostBundle): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ICostBundle, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, errors, isSubmitting, handleChange, handleSubmit, setFieldValue, onCancelCallback } = props;

  const handleStringListInputChange = (key: string, newStringList: string[]) => {
    handleChange({
      target: {
        name: key,
        value: newStringList,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StringListInput
            label="Name(s)"
            onStringListChange={(newStringList) => handleStringListInputChange("name", newStringList)}
            stringList={values.name}
            errors={errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <StringListInput
            label="Variant(s)"
            placeholder="Variant 1"
            onStringListChange={(newStringList) => handleStringListInputChange("variants", newStringList)}
            stringList={values.variants}
            errors={errors.variants}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChange}
            type="number"
            variant="standard"
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            margin="normal"
            {...getFormikFieldProps(props, "cost", "Cost")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChange}
            type="number"
            variant="standard"
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            margin="normal"
            {...getFormikFieldProps(props, "orderNumber", "Order Number")}
          />
        </Grid>

        <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
          <FormControlLabel
            control={
              <Switch
                checked={values.createCorrespondingInput}
                onChange={() => {
                  setFieldValue("createCorrespondingInput", !values.createCorrespondingInput);
                }}
                name="CreateInput"
              />
            }
            label="Create Corresponding Input"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={isSubmitting} variant="text"  onClick={onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormCostBundleCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: [],
    variants: [],
    code: "",
    disabled: false,
    cost: 0,
    orderNumber: 0,
    createCorrespondingInput: true,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Name")
        .min(3, "Please input 3 characters or more")
        .max(500, "Please input 500 characters or less")
    ),
    variants: Yup.array().of(
      Yup.string()
        .label("Variant(s)")
        .min(3, "Please input 3 characters or more")
        .max(500, "Please input 500 characters or less")
    ),
    cost: Yup.number().min(0, "Zero or more."),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    // Generate the combinations
    const costsToCreate = [];
    for (const name of values.name) {
      if (values.variants.length === 0) {
        let reqObj = {
          code: values.code,
          disabled: values.disabled,
          cost: values.cost,
          orderNumber: values.orderNumber,
          createCorrespondingInput: values.createCorrespondingInput,
          modelId,
          name: "",
        };
        reqObj["name"] = name;
        costsToCreate.push(reqObj);
      } else {
        for (const variant of values.variants) {
          let reqObj = {
            code: values.code,
            disabled: values.disabled,
            cost: values.cost,
            orderNumber: values.orderNumber,
            createCorrespondingInput: values.createCorrespondingInput,
            modelId,
            name: "",
          };
          // Combine the name and variant and add it to the list
          const combinationName = `${variant} ${name}`;
          reqObj["name"] = combinationName;
          costsToCreate.push(reqObj);
        }
      }
    }

    // Map dispatch via props
    var createdCostBundle = await dispatch(fetchCreateCostBundle({ costBundles: costsToCreate }));
    if (createdCostBundle) {
      // Get new categories if any
      await dispatch(
        fetchSearchAssetInputFieldCategories({
          pageNumber: 1,
          pageSize: 20,
          modelId,
        })
      );

      // Get new inputs
      await dispatch(
        fetchSearchAssetInputFields({
          pageNumber: 1,
          pageSize: 500,
          modelId,
        })
      );
    }

    setSubmitting(false);

    if (createdCostBundle) {
      onCompleteCallback(createdCostBundle);
    }
  },
})(InnerForm);

export default FormCostBundleCreate;
