import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/Build";
import FormSolverHistoryUpdate from "../forms/FormSolverHistoryUpdate";
import { ISolverHistory } from "../../../utilities/types/SolverHistory";

export interface IModalSolverHistoryUpdateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(file?: ISolverHistory): void;
  solverHistory: ISolverHistory;
  canEdit: boolean;
}

function ModalSolverHistoryUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  solverHistory,
  canEdit,
}: IModalSolverHistoryUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={canEdit ? "Update history" : "View History"}
      subtitle={canEdit ? "Fill in the form to complete." : "Additional permission required to update."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormSolverHistoryUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          canEdit={canEdit}
          {...solverHistory}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSolverHistoryUpdate;
