import { IFetchCreateSolverJobProps } from "../../redux/solverJob/actions";
import { TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { ISolverJob } from "../../utilities/types/SolverJob";

/** Use this file for setting job create state defaults in development.
 * Get these values from the redux state after progressing through each step.
 */

export const USE_DEV_DEFAULTS = false;

export const AssetInputValuesObjectDefault: { [key: string]: TAssetInputFieldValue } = {
  // "5bd772fa-b273-44e2-bfb0-dd1c8df0589e": { "value": 1234, ... },
  // "3b3fef51-dc25-457d-8eb7-1664e6907dfe": { "value": 5678, ... },
};

export const AppliedDecisionsOutputDefault: { [_: string]: any } = {
  // "modelId": "...",
  // "assetInputFieldCategories": [ ... ],
};

export const GetSolverJobResponseDemo = (job: IFetchCreateSolverJobProps, solverJobId: string) =>
  ({
    solverJobId,
    solverId: job.solverId,
    modelId: job.modelId,
    name: job.jobName,
    // fileId: job.fileId,

    // status: 10,
    // summaryOutputBucket: '...',
    // inputValues: { ... },
  } as any as ISolverJob);
