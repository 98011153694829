import { ITask } from "../../utilities/types/Task";


export const ACTIONS_TASK = {
    INVALIDATE: 'INVALIDATE_TASKS',
    RECEIVE: 'RECEIVE_TASKS',
    DELETE: 'DELETE_TASK',
    UPDATE: 'UPDATE_TASKS',
}

interface ActionTasksInvalidate {
    type: typeof ACTIONS_TASK.INVALIDATE,
    byId: { [key: string]: ITask },
}

interface ActionTasksReceive {
    type: typeof ACTIONS_TASK.RECEIVE
    byId: { [key: string]: ITask },
}

export interface TaskState {
    byId: { [key: string]: ITask },
}

export enum TaskSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesTask = ActionTasksReceive | ActionTasksInvalidate;