import React from "react";
import { Container, Breadcrumbs, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HomeIconLink } from "../generic/HomeIconLink";
import LoaderAbsoluteCentred from "./loaders/LoaderAbsoluteCentred";

interface JobDetailPageWrapperProps {
  children: React.ReactNode;
  loading: boolean;
  jobLabel: string;
  jobDetailsLabel: string;
}

const JobDetailPageWrapper: React.FC<JobDetailPageWrapperProps> = ({
  children,
  jobLabel,
  jobDetailsLabel,
  loading,
}) => {
  return (
    <Container maxWidth="xl" style={{ paddingTop: 16 }} id="job-details-wrapper">
      <Breadcrumbs aria-label="breadcrumb">
        <HomeIconLink />
        <Link to={"/Dashboard"} component={RouterLink} color="inherit" key={`breadcrumb-dashboard`}>
          <Typography noWrap>{jobLabel}</Typography>
        </Link>
        <Link color="textPrimary" aria-current="page" underline="none">
          {jobDetailsLabel}
        </Link>
      </Breadcrumbs>

      {children}

      <LoaderAbsoluteCentred loading={loading} />
    </Container>
  );
};

export default JobDetailPageWrapper;
