import React from "react";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { GetAdminImportsPage } from "../../routes/RouteLinkHelpers";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import HeaderIcon from "@mui/icons-material/ImportExportTwoTone";

const AdminImportsWidget = () => {
  return (
    <WidgetSummaryCountAndButtons
      key="AdminImportsWidget"
      headerIcon={<HeaderIcon />}
      captionText="Imports"
      mainText="Manage imports"
      buttons={[
        <Button
          
          variant="outlined"
          size="small"
          key="btn-browse"
          component={NavLink}
          to={GetAdminImportsPage()}
        >
          Browse
        </Button>,
      ]}
    />
  );
};

export default AdminImportsWidget;
