import React from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import HeaderIcon from "@mui/icons-material/FilterDramaTwoTone";

const AdminManageBucketsWidget = () => {
  return (
    <WidgetSummaryCountAndButtons
      key="AdminManageBucketsWidget"
      headerIcon={<HeaderIcon />}
      captionText="Manage Buckets"
      mainText="Manage Buckets"
      buttons={[
        <Button size="small" variant="outlined" color="primary">
          View
        </Button>,
      ]}
    />
  );
};

export default AdminManageBucketsWidget;
