import React, { useState } from "react";
import { Button } from "@mui/material";
import ModelsIcon from "@mui/icons-material/PersonTwoTone";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetUserRoles } from "../../redux/userRole/selectors";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import UserRolesManagementContainerRow from "./UserRolesManagementContainerRow";
import ModalUserRoleCreate from "./modals/ModalUserRoleCreate";

const UserRoleManagementContainer = () => {
  const userRoles = useSelector((state: RootState) => selectorGetUserRoles(state));
  const [userRoleCreateModalOpen, setUserRoleCreateModalOpen] = useState(false);
  const toggleRoleCreateModal = () => setUserRoleCreateModalOpen(!userRoleCreateModalOpen);

  return (
    <WidgetSectionBase headerText="User Roles" subheaderText="Manage User Roles" headerIcon={<ModelsIcon />}>
      <div>
        {userRoles.map((userRole) => {
          return <UserRolesManagementContainerRow key={userRole.userRoleId} userRole={userRole} />;
        })}

        <LoaderAbsoluteCentred loading={false} />
      </div>
      <Button color="primary" variant="outlined" style={{ marginLeft: 16 }} onClick={toggleRoleCreateModal}>
        Create Role
      </Button>

      {userRoleCreateModalOpen && (
        <ModalUserRoleCreate
          open={userRoleCreateModalOpen}
          onCancelCallback={toggleRoleCreateModal}
          onCompleteCallback={toggleRoleCreateModal}
        />
      )}
    </WidgetSectionBase>
  );
};

export default UserRoleManagementContainer;
