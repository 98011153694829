import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/EventTwoTone";
import FormEventCreate from "../forms/FormEventCreate";
import { IEvent } from "../../../utilities/types/Event";

export interface IModalEventCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  onCompleteCallback(event?: IEvent): void;
}

function ModalEventCreate({ onCancelCallback, open, onCompleteCallback, modelId }: IModalEventCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create new event(s)"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormEventCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalEventCreate;
