import { RootState } from "..";
import { ISummaryOutputTypeInputField } from "../../utilities/types/SummaryOutputTypeInputField";


export function selectorGetSummaryOutputTypeInputFieldsBySummaryOutputTypeId(state: RootState, summaryOutputTypeId: string): ISummaryOutputTypeInputField[] {

    if (!state.summaryOutputTypeInputFields || !state.summaryOutputTypeInputFields.byId) return [];

    return sortSummaryOutputTypeInputFieldsByOrderNumberAscending(Object.values(state.summaryOutputTypeInputFields.byId).filter(x => x.summaryOutputTypeId === summaryOutputTypeId));
}

export function selectorGetSummaryOutputTypeInputFieldById(state: RootState, summaryOutputTypeInputFieldId: string): ISummaryOutputTypeInputField | undefined {

    if (!state.summaryOutputTypeInputFields || !state.summaryOutputTypeInputFields.byId) return undefined;

    return state.summaryOutputTypeInputFields.byId[summaryOutputTypeInputFieldId];
}


export function selectorGetSummaryOutputTypeInputFields(state: RootState) {

    if (!state.summaryOutputTypeInputFields || !state.summaryOutputTypeInputFields.byId) return [];

    return Object.values(state.summaryOutputTypeInputFields.byId);
}


export function selectorGetSummaryOutputTypeInputFieldTableNamesBySummaryOutputTypeId(state: RootState, summaryOutputTypeId: string) {

    var summaryOutputTypeInputFields = selectorGetSummaryOutputTypeInputFieldsBySummaryOutputTypeId(state, summaryOutputTypeId);
    var tableNames = summaryOutputTypeInputFields.filter(x => x.tableName && x.tableName !== '').map(x => x.tableName);
    var distinctTableNames = new Set(tableNames);

    return Array.from(distinctTableNames.values());
}


// function sortSummaryOutputTypeInputFieldsByOrderNumberDescending(summaryOutputTypeInputFields: ISummaryOutputTypeInputField[]) {
//     return summaryOutputTypeInputFields.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortSummaryOutputTypeInputFieldsByOrderNumberAscending(summaryOutputTypeInputFields: ISummaryOutputTypeInputField[]) {
    return summaryOutputTypeInputFields.sort((a, b) => a.orderNumber - b.orderNumber);
}