import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import HeaderIcon from "@mui/icons-material/ArtTrackTwoTone";
import FormIdentifierToModeMappingCreate from "../forms/FormIdentifierToModeMappingCreate";
import { IIdentifierMapping, IdentifierMappingTypeEnum } from "../../../utilities/types/IdentifierMapping";
import { selectorGetIdentifierMappingsByIdentifierIds } from "../../../redux/identifierMapping/selectors";
import { RootState } from "../../../redux";
import { useComponentAndFunctionList } from "../../component/linkToModesTable/useComponentAndFunctionList";

export interface IModalIdentifierToModeCreateMultipleProps {
  open: boolean;
  onCancelCallback(): void;
  identifierId: string;
  modelId: string;
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
}

function ModalIdentifierToModeCreateMultiple({
  onCancelCallback,
  open,
  onCompleteCallback,
  identifierId,
  modelId,
}: IModalIdentifierToModeCreateMultipleProps) {
  const store = useSelector((store: RootState) => store);
  const dispatch = useDispatch();
  const selectedIdentifiersMapping = selectorGetIdentifierMappingsByIdentifierIds(store, [identifierId]);
  const defaultSelectedTargetIds =
    selectedIdentifiersMapping.length > 0 ? selectedIdentifiersMapping.map((item) => item.targetId) : [];

  const { dataList } = useComponentAndFunctionList(modelId, true);

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Link identifers to mode(s)"}
      subtitle={"Select the modes that the identifier is on."}
      headerIcon={<HeaderIcon />}
      style={{ maxWidth: 800, width: "100%" }}
      showCloseBtn
    >
      <DialogContent style={{ paddingTop: 0, paddingBottom: 16 }}>
        <FormIdentifierToModeMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          identifierIds={[identifierId]}
          defaultSelectedTargetIds={defaultSelectedTargetIds}
          componentAndFunctionList={dataList}
          modelId={modelId}
          type={IdentifierMappingTypeEnum.FailureMode}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalIdentifierToModeCreateMultiple;
