import React from "react";
import { Typography, Grid, Divider, styled } from "@mui/material";
import { IEvent } from "../../utilities/types/Event";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import PlaceholderIcon from "@mui/icons-material/EventTwoTone";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { ICostBundle } from "../../utilities/types/CostBundle";
import { ITask } from "../../utilities/types/Task";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .eventWrapper": {
    padding: theme.spacing(2),
    position: "relative",
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0)",
    transition: "background 0.25s ease-out !important",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },
  "& .eventMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
}));

type IEventReviewDecisionsTabProps = {
  events: IEvent[];
  costBundles: ICostBundle[];
  tasks: ITask[];
};

const EventReviewDecisionsTab = ({ events, costBundles, tasks }: IEventReviewDecisionsTabProps) => {
  return <EventReviewDecisionsTabDisplay events={events} tasks={tasks} costBundles={costBundles} />;
};

type IEventReviewDecisionsTabDisplayProps = {
  events: IEvent[];
  costBundles: ICostBundle[];
  tasks: ITask[];
};

const EventReviewDecisionsTabDisplay = ({ events, costBundles, tasks }: IEventReviewDecisionsTabDisplayProps) => {
  return (
    <DivWrapper>
      <AnimationWrapper>
        <div>
          {events.map((event) => {
            return (
              <>
                <EventReviewElement event={event} costBundles={costBundles} tasks={tasks} />
                <Divider light={true} />
              </>
            );
          })}
          {(!events || !events.length) && (
            <WidgetNoResultsPlaceholder text="No events" icon={PlaceholderIcon} flip={true} />
          )}
        </div>
      </AnimationWrapper>
    </DivWrapper>
  );
};

interface IEventReviewElementProps {
  event: IEvent;
  costBundles: ICostBundle[];
  tasks: ITask[];
}

const EventReviewElement = ({ event, costBundles, tasks }: IEventReviewElementProps) => {
  const costBundle = costBundles.find((x) => x.costBundleId === event.costBundleId);
  const task = tasks.find((x) => x.taskId === event.taskId);

  return (
    <Grid container className="eventWrapper">
      <Grid item xs={12}>
        <div className="eventMainWrapper">
          <div>
            <Typography variant="body1" style={{ textTransform: "capitalize" }}>
              {event.name} - <span style={{ opacity: 0.7 }}>{event.description}</span>
            </Typography>
            <Typography variant="caption"></Typography>
          </div>
          <div>
            <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
              Cost Bundle:{" "}
            </Typography>
            <Typography variant="caption" component="span" color="textSecondary">
              {costBundle ? costBundle.name : "-"}{" "}
            </Typography>
            <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
              Task:{" "}
            </Typography>
            <Typography variant="caption" component="span" color="textSecondary">
              {task ? task.name : "-"}{" "}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default EventReviewDecisionsTab;
