import { Box, Button, IconButton, Tab, Tabs, Typography, styled } from "@mui/material";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetAssetInputFieldCategoriesByModelId } from "../../redux/assetInputFieldCategory/selectors";
import { TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { IAssetInputFieldCategory } from "../../utilities/types/AssetInputFieldCategory";
import AssetInputFieldTabPanel from "../assetInputField/AssetInputFieldTabPanel";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useFetchAssetInputFieldCategoriesPageHook } from "./Hooks";
import AssetInputFieldCategoryCreateModal from "./modals/ModalAssetInputFieldCategoryCreate";
import { grey } from "@mui/material/colors";

const SectionWrapper = styled("div")(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  "& .MuiTabs-root": {
    position: "relative",
    backgroundColor: grey[100],
    borderRadius: theme.spacing(1.5),
    minHeight: "unset",
    padding: theme.spacing(0.5),
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
    position: "relative",
    zIndex: 10,
  },

  "& .MuiTabs-fixed": {
    overflow: "unset !important",
    overflowX: "unset !important",
  },

  "& .MuiTabs-vertical": {
    height: "fit-content",
    "& .MuiTabs-indicator": {
      width: "100%",
    },
  },

  "& .MuiTabs-indicator": {
    height: "100%",
    borderRadius: theme.spacing(1),
    backgroundColor: "#fff",
    transition: "all 500ms ease",
  },
  "& .MuiTabs-scrollButtons": {
    color: theme.palette.primary.main,
  },
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.background.paper} !important`,
    boxShadow: "rgb(55 71 79 / 50%) 0rem 0.0625rem 0.3125rem 0.0625rem",
  },
  "& .MuiTab-root": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexShrink: 0,
    textAlign: "center",
    maxWidth: "unset !important",
    minWidth: "unset !important",
    minHeight: "unset !important",
    fontSize: theme.spacing(2),
    fontWeight: 400,
    textTransform: "none",
    lineHeight: "inherit",
    padding: `${theme.spacing(0.6)} ${theme.spacing(1.2)}`,
    borderRadius: theme.spacing(1),
    
    color: `${theme.palette.primary.main}`,
    opacity: "1 !important",

    "& .material-icons, .material-icons-round": {
      marginBottom: "0 !important",
    },

    "& svg": {
      marginBottom: "0 !important",
      marginRight: theme.spacing(1.2),
    },
  },
  "& .tabs": {
    minWidth: "25%",
  },
  "& .tabPanelWrapper": {
    width: "75%",
    maxWidth: "75%",
  },
}));

type AssetInputFieldsProps = {
  modelId: string;
  canEdit: boolean;
  enableInputs: boolean;
};

interface IAssetInputFieldCategoryTabsProps extends AssetInputFieldsProps {
  onAssetInputFieldValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
  defaultValueOverrides?: { [key: string]: TAssetInputFieldValue };
}

const AssetInputFieldCategoryTabs = ({
  modelId,
  canEdit,
  enableInputs,
  onAssetInputFieldValueChangeCallback,
  defaultValueOverrides,
}: IAssetInputFieldCategoryTabsProps) => {
  const assetInputFieldCategories = useSelector((store: RootState) =>
    selectorGetAssetInputFieldCategoriesByModelId(store, modelId)
  );

  const { fetching: fetchingAssetInputFields } = useFetchAssetInputFieldCategoriesPageHook({
    pageNumber: 1,
    pageSize: 20,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  return (
    <AssetInputFieldCategoryTabsDisplay
      modelId={modelId}
      canEdit={canEdit}
      loading={fetchingAssetInputFields}
      assetInputFieldCategories={assetInputFieldCategories}
      enableInputs={enableInputs}
      onValueChangeCallback={onAssetInputFieldValueChangeCallback}
      defaultValueOverrides={defaultValueOverrides}
    />
  );
};

type AssetInputFieldsDisplayProps = {
  modelId: string;
  canEdit: boolean;
  loading: boolean;
  assetInputFieldCategories: IAssetInputFieldCategory[];
  enableInputs: boolean;
  onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
  defaultValueOverrides?: { [key: string]: TAssetInputFieldValue };
};

const AssetInputFieldCategoryTabsDisplay = ({
  modelId,
  loading,
  assetInputFieldCategories,
  canEdit,
  enableInputs,
  defaultValueOverrides,
  onValueChangeCallback,
}: AssetInputFieldsDisplayProps) => {
  const [activeCategory, setActiveCategory] = React.useState(0);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const toggleShowCreateModal = () => setShowCreateModal(!showCreateModal);

  const handleTabChange = (e: ChangeEvent<{}>, selectedTab: number) => {
    setActiveCategory(selectedTab);
  };

  if (!modelId) return null;
  if (loading) return <LoaderAbsoluteCentred loading={true} />;

  return (
    <SectionWrapper>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeCategory}
        onChange={handleTabChange}
        className="tabs"
      >
        {assetInputFieldCategories.map((assetInputFieldCategory: IAssetInputFieldCategory) => {
          return (
            <Tab
              key={assetInputFieldCategory.assetInputFieldCategoryId}
              label={assetInputFieldCategory.name}
              className="tabWrapper"
              style={{
                overflowX: "hidden",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                alignItems: "flex-end",
              }}
            />
          );
        })}
        {canEdit ? (
          <Tab
            label={
              <IconButton aria-label="Add a new category" onClick={toggleShowCreateModal}>
                <AddCategoryIcon />
              </IconButton>
            }
          />
        ) : null}
      </Tabs>
      {assetInputFieldCategories.map((assetInputFieldCategory: IAssetInputFieldCategory, i: number) => {
        return (
          <AssetInputFieldCategoriesDisplayTabPanel
            value={activeCategory}
            index={i}
            key={assetInputFieldCategory.assetInputFieldCategoryId}
            canEdit={canEdit}
            enableInputs={enableInputs}
          >
            <AssetInputFieldTabPanel
              assetInputFieldCategory={assetInputFieldCategory}
              canEdit={canEdit}
              enableInputs={enableInputs}
              onValueChangeCallback={onValueChangeCallback}
              defaultValueOverrides={defaultValueOverrides}
            />
          </AssetInputFieldCategoriesDisplayTabPanel>
        );
      })}
      <AssetInputFieldCategoriesDisplayTabPanel
        value={activeCategory}
        index={assetInputFieldCategories.length}
        canEdit={canEdit}
        enableInputs={enableInputs}
      >
        <Typography variant="body1">Add a Category</Typography>
        <Typography variant="body2">In order to add inputs at least one category is required.</Typography>
        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 16 }}>
          <Button color="primary" variant="outlined" onClick={toggleShowCreateModal}>
            Add Category
          </Button>
        </div>
      </AssetInputFieldCategoriesDisplayTabPanel>
      <AssetInputFieldCategoryCreateModal
        open={showCreateModal}
        onCancelCallback={toggleShowCreateModal}
        onCompleteCallback={toggleShowCreateModal}
        modelId={modelId}
      />
    </SectionWrapper>
  );
};

interface IAssetInputFieldCategoriesDisplayTabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
  canEdit: boolean;
  enableInputs: boolean;
}

function AssetInputFieldCategoriesDisplayTabPanel({
  children,
  value,
  index,
}: IAssetInputFieldCategoriesDisplayTabPanelProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="tabPanelWrapper"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default AssetInputFieldCategoryTabs;
