import { IFavourite } from "../../utilities/types/Favourite";


export const ACTIONS_FAVOURITE = {
    INVALIDATE: 'INVALIDATE_FAVOURITES',
    RECEIVE: 'RECEIVE_FAVOURITES',
    DELETE: 'DELETE_FAVOURITE',
}

interface ActionFavouritesInvalidate {
    type: typeof ACTIONS_FAVOURITE.INVALIDATE,
    byId: { [key: string]: IFavourite },
}

interface ActionFavouritesReceive {
    type: typeof ACTIONS_FAVOURITE.RECEIVE
    byId: { [key: string]: IFavourite },
}

export interface FavouriteState {
    byId: { [key: string]: IFavourite },
}

export enum FavouriteSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesFavourite = ActionFavouritesReceive | ActionFavouritesInvalidate;