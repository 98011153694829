import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";
import LoaderAbsoluteCentred from "../components/generic/loaders/LoaderAbsoluteCentred";
import PageSingleAssetOutputDetails from "../components/singleAssetOutput/pages/PageSingleAssetOutputDetails";
import { useFetchSingleAssetOutputPageHook } from "../components/singleAssetOutput/Hooks";

const RouteOutput = () => {
  let match = useRouteMatch();

  const { fetching } = useFetchSingleAssetOutputPageHook({
    pageNumber: 1,
    pageSize: 1,
    minPageNumberToFetch: 1,
  });

  if (fetching) return <LoaderAbsoluteCentred loading={fetching} />;

  return (
    <Switch>
      <Route
        path={`${match.path}`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageSingleAssetOutputDetails {...props} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteOutput;
