import React from "react";
import { Grid, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetFileById } from "../../../redux/file/selectors";
import { IFile, FileTypeEnum } from "../../../utilities/types/File";
import FileUploadIcon, { IFileUploadIconProps } from "../FileUploadIcon";
import NotFoundImg from "../../../assets/img/default-asset.png";
import { ImageWithFallback } from "../../generic/ImageWithFallback";

const GridWrapper = styled(Grid)({
  overflow: "hidden",
  position: "relative",
  "& .image": {
    objectFit: "cover",
    objectPosition: "50% 50%",
    width: "100%",
    transition: "transform 0.5s ease-in",
    transitionDelay: "0.1s",
    "&:hover": {
      transform: "scale(1.1)",
    },
    backgroundColor: "#EEE",
  },
});

interface IFileRendererSingleProps extends IFileUploadIconProps {
  fileId: string;
  canUpload: boolean;
  fileType: FileTypeEnum;
  height?: number;
  imageUploadHeight?: number;
  imageUploadWidth?: number;
  onUploadComplete?(): void;
}

function FileRendererSingle(props: IFileRendererSingleProps) {
  const { fileId } = props;
  const file = useSelector((state: RootState) => selectorGetFileById(state, fileId));

  return <FileRendererSingleDisplay {...props} file={file} />;
}

interface IFileRendererSingleDisplayProps extends IFileRendererSingleProps {
  file?: IFile;
}

function FileRendererSingleDisplay({
  file,
  canUpload,
  entityId,
  entityType,
  description,
  fileType,
  onUploadComplete,
  height = 75,
  imageUploadHeight,
  imageUploadWidth,
}: IFileRendererSingleDisplayProps) {
  let url = file ? file.preSignedUrl : NotFoundImg;

  return (
    <GridWrapper
      item
      xs={12}
      style={{
        padding: 0,
      }}
    >
      <ImageWithFallback src={url} className="image" style={{ height }} alt={description} />
      {canUpload ? (
        <div style={{ position: "absolute", bottom: 60, right: 60 }}>
          <FileUploadIcon
            entityId={entityId}
            entityType={entityType}
            description={description}
            fileType={fileType}
            onUploadComplete={onUploadComplete}
            imageHeight={imageUploadHeight}
            imageWidth={imageUploadWidth}
          />
        </div>
      ) : null}
    </GridWrapper>
  );
}

export default FileRendererSingle;
