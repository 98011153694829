import { ActionTypesUserPermission, UserPermissionState, ACTIONS_USER_PERMISSION } from "./types";

const initialState: UserPermissionState = {
    byId: {},
}

export function userPermissionsReducer(
    state = initialState,
    action: ActionTypesUserPermission
): UserPermissionState {
    switch (action.type) {
        case ACTIONS_USER_PERMISSION.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_USER_PERMISSION.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        case ACTIONS_USER_PERMISSION.DELETE: {
            
            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        }
        default:
            return state;
    }
}