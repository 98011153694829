import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
  styled,
} from "@mui/material";
import { blueGrey, green, orange, red, yellow } from "@mui/material/colors";
import { Build, SignalCellular1Bar, SignalCellular3Bar, SignalCellular4Bar } from "@mui/icons-material";
import ModelIcon from "@mui/icons-material/Category";
import GetAppIcon from "@mui/icons-material/GetApp";
import InfoIcon from "@mui/icons-material/Info";
import InputIcon from "@mui/icons-material/Input";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Alert } from "@mui/lab";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../redux";
import { selectorUserHasEntityPermission } from "../../redux/entityPermission/selectors";
import {
  selectorGetUserPermissionOfType,
} from "../../redux/userPermission/selectors";
import {
  GetModelLinkByModel,
  GetModelURLByModelId,
  GetSolverJobCreateLinkByModelId,
} from "../../routes/RouteLinkHelpers";
import { CheckStatus, GetDefaultHeaders, GetUserId } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { MaturityLabel } from "../../utilities/labels";
import { join } from "../../utilities/Styles";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { EntityPermissionTypeEnum } from "../../utilities/types/EntityPermission";
import { FileTypeEnum } from "../../utilities/types/File";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import { IModel, Maturity } from "../../utilities/types/Model";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import ConfirmModelContributorModal from "../contributor/modals/ModalConfirmRequest";
import FavouriteButton from "../favourite/FavouriteButton";
import FileRendererSingle from "../file/renderers/FileRendererSingle";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import ModalModelSearchResultDisplay from "./modals/ModalModelSearchResultDisplay";

const IMAGE_HEIGHT = 200;

const CardWrapper = styled(Card)(({ theme }) => ({
  "& .header": {
    height: 75,
  },
  "& .HeaderTitle": {
    fontWeight: "bold !important",
  },
  "& .updatedModelImageWrapper": {
    height: IMAGE_HEIGHT,
    overflow: "hidden",
    cursor: "pointer",
    position: "relative",
  },
  "& .floatingIdentifiers": {
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "16px",
  },
  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .avatar": {
    backgroundColor: blueGrey[800],
    textSizeAdjust: "small",
  },
  "& .actionContainer": {
    paddingRight: theme.spacing(2),
  },
  "& .underConstruction": {
    color: red[800],
  },
  "& .lowMaturity": {
    color: orange[800],
  },
  "& .mediumMaturity": {
    color: yellow[800],
  },
  "& .highMaturity": {
    color: green[800],
  },
}));

function ModelSearchResultWidget({ model }: { model: IModel | undefined; loading: boolean }) {
  const [viewDetails, setViewDetails] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();

  const toggleViewDetails = () => {
    setViewDetails(!viewDetails);
  };

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(GetModelURLByModelId(model?.modelId ?? "", model?.description ?? "Undefined"));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeMenu = () => setMenuAnchorEl(undefined);

  const isModelViewDetails = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.PageModelDetails)
  );
  const hasPermissionModelExport = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesModelExport)
  );
  const hasEntityPermissionContributor = useSelector((store: RootState) =>
    selectorUserHasEntityPermission(store, GetUserId(), EntityPermissionTypeEnum.Contributor, model?.modelId || "")
  );
  const hasPermissionViewFavourites = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesFavouriteRead)
  );

  const [contributorModalIsOpen, setContributorModalIsOpen] = useState(false);

  const handleModelsExport = async () => {
    if (!model) return;

    var headers = await GetDefaultHeaders(true, false, true);

    try {
      const response = await fetch(`${Configuration.BASE_API_URL}/models/export?modelId=${model.modelId}`, {
        method: "GET",
        headers: headers,
      });

      await CheckStatus(response);

      const blob = await response.blob();

      // Create a temporary URL to the blob
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const a = document.createElement("a");
      a.href = url;
      // Specify the filename for the download
      a.download = `model-export-${model.modelId}.xlsx`; // Update with your desired filename and extension
      // Append the link to the body
      document.body.appendChild(a);
      // Trigger the download
      a.click();
      // Cleanup
      window.URL.revokeObjectURL(url);
      closeMenu();
    } catch (e: unknown) {
      console.error("Unknown error:", e);
    }
  };

  return !model ? null : (
    <CardWrapper>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        action={
          <IconButton aria-label="settings" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={model.title}
        subheader={`Last Updated ${moment.utc(model.updated).fromNow()}`}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
        classes={{ title: "HeaderTitle" }}
      />

      <Menu
        style={{ padding: 0 }}
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        disableScrollLock={true}
        onClose={closeMenu}
      >
        <MenuItem key="btn-view-model" component={Link} to={GetModelLinkByModel(model)}>
          <ListItemIcon>
            <ModelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Model" />
        </MenuItem>

        <MenuItem key="btn-analyse" component={Link} to={GetSolverJobCreateLinkByModelId(model?.modelId)}>
          <ListItemIcon>
            <TimelineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Derive Specific Asset" />
        </MenuItem>

        {isModelViewDetails &&
          !hasEntityPermissionContributor && [
            <ConfirmModelContributorModal
              model={model}
              isOpen={contributorModalIsOpen}
              close={() => {
                setContributorModalIsOpen(false);
                closeMenu();
              }}
            />,
            <MenuItem key="btn-request-model" onClick={() => setContributorModalIsOpen(true)}>
              <ListItemIcon>
                <InputIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Become a Contributor" />
            </MenuItem>,
          ]}

        {hasPermissionModelExport && (
          <MenuItem key="btn-export-model" onClick={handleModelsExport}>
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Export" />
          </MenuItem>
        )}
      </Menu>

      <div className="updatedModelImageWrapper">
        <Link to={GetModelLinkByModel(model)}>
          <FileRendererSingle
            fileId={model.mainImageId || ""}
            canUpload={false}
            fileType={FileTypeEnum.AssetImage}
            entityId={String(model.modelId)}
            entityType={EntityTypeEnum.Model}
            height={IMAGE_HEIGHT}
          />
        </Link>
        <div className="floatingIdentifiers">
          <IdentifierMappingsRenderer
            canEdit={true}
            modelId={model.modelId}
            type={IdentifierMappingTypeEnum.Model}
            targetId={String(model.modelId)}
            loading={false}
            showValues={false}
          />
        </div>
      </div>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {model.description}
        </Typography>
      </CardContent>

      <CardActions disableSpacing className="actionContainer">
        <IconButton aria-label="Additional info" title="Additional info" onClick={toggleViewDetails}>
          <InfoIcon />
        </IconButton>
        {hasPermissionViewFavourites && (
          <FavouriteButton entityType={EntityTypeEnum.Model} entityId={model?.modelId ?? ""} />
        )}
        <IconButton aria-label="Share" title="Share" onClick={handleClick}>
          <ShareIcon />
        </IconButton>
        <div style={{ flex: 1 }} />
        <ModelMaturityIcon maturity={model.maturity} />
      </CardActions>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Link copied to clipboard!
        </Alert>
      </Snackbar>
      <ModalModelSearchResultDisplay
        open={viewDetails}
        onCancelCallback={toggleViewDetails}
        onCompleteCallback={toggleViewDetails}
        model={model}
      />
    </CardWrapper>
  );
}

export function ModelMaturityIcon(props: { maturity: Maturity; className?: string }) {
  const cls = (extraClass: string) => join(props.className, extraClass);

  switch (props.maturity) {
    case Maturity.UnderConstruction:
      return (
        <Build
          aria-label="under-construction-icon"
          titleAccess={MaturityLabel.UnderConstruction}
          className={cls("underConstruction")}
        />
      );

    case Maturity.Low:
      return (
        <SignalCellular1Bar
          aria-label="maturity-low-icon"
          titleAccess={MaturityLabel.Low}
          className={cls("lowMaturity")}
        />
      );

    case Maturity.Medium:
      return (
        <SignalCellular3Bar
          aria-label="maturity-medium-icon"
          titleAccess={MaturityLabel.Medium}
          className={cls("mediumMaturity")}
        />
      );

    case Maturity.High:
      return (
        <SignalCellular4Bar
          aria-label="maturity-high-icon"
          titleAccess={MaturityLabel.High}
          className={cls("highMaturity")}
        />
      );

    default:
      return null;
  }
}

export default ModelSearchResultWidget;
