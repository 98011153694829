import React, { useState } from "react";
import {
  Grid,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import { useSelector } from "react-redux";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { fetchCloneFunctionalFailure } from "../../../redux/functionalFailure/actions";
import { IFunction } from "../../../utilities/types/Function";
import { selectorGetFunctionsByModelId } from "../../../redux/function/selectors";
import { selectorGetComponentsByModelId } from "../../../redux/component/selectors";

interface FormValues {
  sourceId: string;
  destinationId: string;
  includeIdentifiers: boolean;
  entityType: EntityTypeEnum;
}

interface FormProps {
  modelId: string;
  functionalFailureId: string;
  onCompleteCallback(failureModes?: IFailureMode[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IFailureMode, AnyAction>;
}

const FormFunctionalFailureClone = (formProps: FormProps) => {
  const { onCancelCallback, functionalFailureId } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const functions = useSelector((store: RootState) => selectorGetFunctionsByModelId(store, formProps.modelId));
  const components = useSelector((store: RootState) => selectorGetComponentsByModelId(store, formProps.modelId));

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    setSubmitting(true);

    // Map dispatch via props
    var updatedFailureModes = await dispatch(
      fetchCloneFunctionalFailure({
        ...values,
      })
    );

    setSubmitting(false);
    if (updatedFailureModes) {
      UpdateMessage({ ...message, text: "Cloned", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedFailureModes);
    }
  };

  return (
    <Formik
      initialValues={{
        sourceId: functionalFailureId,
        destinationId: "",
        includeIdentifiers: true,
        entityType: EntityTypeEnum.FunctionalFailure,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={functionalFailureId} name="functionalFailureId" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-model-type" variant="standard">
                    Destination Function
                  </InputLabel>

                  <Select
                    labelId="lbl-model-type"
                    variant="standard"
                    {...getFormikFieldProps(props, "destinationId", "Destination")}
                    onChange={(e) => {
                      props.setFieldValue("destinationId", e.target.value);
                    }}
                    id="txt-model-type"
                  >
                    {functions.map((functionItem: IFunction) => {
                      return (
                        <MenuItem value={functionItem.functionId}>
                          {components[components.findIndex((x) => x.componentId === functionItem.componentId)].name +
                            "/" +
                            functionItem.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ alignSelf: "flex-end" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.values.includeIdentifiers}
                      onChange={() => props.setFieldValue("includeIdentifiers", !props.values.includeIdentifiers)}
                      value={true}
                      color="primary"
                    />
                  }
                  label="Include Identifiers"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Clone
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting} />
              </Grid>
            </Grid>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({});
      }}
    />
  );
};

export default FormFunctionalFailureClone;
