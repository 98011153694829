import DialogContent from "@mui/material/DialogContent";
import BuildIcon from "@mui/icons-material/LabelTwoTone";
import React from "react";
import { useDispatch } from "react-redux";
import { useFetchUserRolesPageHook, useFetchUserToUserRolesPageHook } from "../Hooks";
import { IUserToRoleMapping } from "../../../utilities/types/UserToRoleMapping";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormUserRoleMappingCreate from "../forms/FormUserRoleMappingCreate";

export interface IModalUserRoleMappingCreateProps {
  open: boolean;
  onCancelCallback(): void;
  userDetailId: string;
  onCompleteCallback(UserToRoleMappingPicklistProps?: IUserToRoleMapping[]): void;
}

function ModalUserRoleMappingCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  userDetailId,
}: IModalUserRoleMappingCreateProps) {
  const dispatch = useDispatch();

  const { fetching: fetchingRoles } = useFetchUserRolesPageHook({
    pageNumber: 1,
    pageSize: 20,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingUserRole } = useFetchUserToUserRolesPageHook({
    pageNumber: 1,
    pageSize: 20,
    minPageNumberToFetch: 1,
    userDetailId: userDetailId,
  });

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Set a user roles"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
      style={{ maxWidth: 800 }}
    >
      <DialogContent style={{ paddingTop: 0, paddingBottom: 16 }}>
        <FormUserRoleMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          userDetailId={userDetailId}
          fetchingUserRole={fetchingUserRole || fetchingRoles}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalUserRoleMappingCreate;
