import React, { useState } from "react";
import { Grid, Button, Avatar, styled } from "@mui/material";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch, useSelector } from "react-redux";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel } from "../../utilities/types/Model";
import { green } from "@mui/material/colors";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import ModalFunctionalFailureCreate from "./modals/ModalFunctionalFailureCreate";
import { selectorGetModelById } from "../../redux/model/selectors";
import { RootState } from "../../redux";
import chatgptLogo from "../../assets/icons/chatgpt.svg";
import ModalAiSuggestFunctionFailure from "./modals/ModalAiSuggestFunctionFailure";

const GridWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
  "& .btnWrapper": {
    display: "flex",
    alignItems: "stretch",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  "& .addComponentButton": {
    color: green[500],
    fontWeight: 500,
    textTransform: "capitalize",
    transition: "background 200ms ease-out",
    width: "100%",
  },
  "& .aiButton": {
    flexShrink: 0,
  },
  "& .icon": {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}));

type IFFModelNewFFProps = {
  modelId: string;
  functionId: string;
  componentId: string;
};

const ModelBuilderNewFunctionalFailure = ({ modelId, componentId, functionId }: IFFModelNewFFProps) => {
  const [showCreateFunctionalFailureModal, setShowCreateFunctionalFailureModal] = useState(false);
  const [loading] = useState(false);
  const [showAiSuggestModal, setShowAiSuggestModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));

  function toggleShowCreateFunctionalFailureModal() {
    setShowCreateFunctionalFailureModal(!showCreateFunctionalFailureModal);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setShowCreateFunctionalFailureModal(!showCreateFunctionalFailureModal);
  }

  function toggleShowAiSuggestModal() {
    setShowAiSuggestModal((prev) => !prev);
  }

  function onCompleteAiSuggestCallback() {
    toggleShowAiSuggestModal();
  }

  return (
    <GridWrapper container>
      <Grid item xs={12}>
        <div className="btnWrapper">
          <Button
            id="btn-create-function"
            variant="outlined"
            className="addComponentButton"
            onClick={toggleShowCreateFunctionalFailureModal}
          >
            <AddCategoryIcon style={{ marginRight: "8px" }} /> Add a new functional failure
          </Button>

          <Button id="btn-create-component" variant="outlined" className="aiButton" onClick={toggleShowAiSuggestModal}>
            <Avatar src={chatgptLogo} className="icon" />
          </Button>
        </div>
      </Grid>
      <ModalFunctionalFailureCreate
        open={showCreateFunctionalFailureModal}
        onCancelCallback={toggleShowCreateFunctionalFailureModal}
        onCompleteCallback={onCompleteCallback}
        modelId={model?.modelId ?? ""}
        functionId={functionId}
      />
      <ModalAiSuggestFunctionFailure
        open={showAiSuggestModal}
        modelId={model?.modelId ?? ""}
        functionId={functionId}
        componentId={componentId}
        onCompleteCallback={onCompleteAiSuggestCallback}
        onCancelCallback={toggleShowAiSuggestModal}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </GridWrapper>
  );
};

export default ModelBuilderNewFunctionalFailure;
