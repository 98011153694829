import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";

import LayersIcon from "@mui/icons-material/Layers";
import FormWorkflowCreate from "../forms/FormWorkflowCreate";
import { IModalWorkflowCreateProps } from "../types";

const ModalWorkflowCreate: React.FC<IModalWorkflowCreateProps> = ({ open, onCancelCallback, onCompleteCallback }) => {
  const dispatch = useDispatch();
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title="Create a new workflow"
      subtitle="Fill in the form to complete."
      headerIcon={<LayersIcon />}
    >
      <DialogContent>
        <FormWorkflowCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalWorkflowCreate;
