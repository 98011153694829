import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormModelHistoryCreate from "../forms/FormModelHistoryCreate";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/Build";
import { IModelHistory } from "../../../utilities/types/ModelHistory";

export interface IModalModelHistoryCreateProps {
  open: boolean;
  modelId: string;
  versionNumber?: number;
  onCancelCallback(): void;
  onCompleteCallback(modelHistory?: IModelHistory): void;
}

function ModalModelHistoryCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  versionNumber,
}: IModalModelHistoryCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Add history"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormModelHistoryCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          versionNumber={versionNumber}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalModelHistoryCreate;
