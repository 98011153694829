import React, { useState } from "react";
import { TextField, Grid, Divider, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";
import { DecisionActionTypeEnum, IDecision } from "../../../utilities/types/Decision";
import { fetchUpdateDecision, fetchDeleteDecision } from "../../../redux/decision/actions";
import { ModelStatusEnum } from "../../../utilities/types/Model";

interface FormValues {
  name: string;
  description: string;
  orderNumber: number;
  actionType: DecisionActionTypeEnum;
}

interface FormProps {
  decision: IDecision;
  onCompleteCallback(decision?: IDecision): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IDecision, AnyAction>;
}

const FormDecisionUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    decision: { decisionId },
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, decision } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedDecision = await dispatch(
      fetchUpdateDecision({
        ...values,
        decisionId: decision.decisionId,
      })
    );

    setSubmitting(false);
    if (updatedDecision) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedDecision);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.decision,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteDecision({ decisionId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={decisionId} name="decisionId" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "name", "Name")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={9}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-model-type" variant="standard">
                    Status
                  </InputLabel>
                  <Select
                    labelId="lbl-model-type"
                    {...getFormikFieldProps(props, "status", "Status")}
                    onChange={(e) => {
                      props.setFieldValue("status", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-type"
                    variant="standard"
                  >
                    <MenuItem value={ModelStatusEnum.Private}>Private</MenuItem>
                    <MenuItem value={ModelStatusEnum.Public}>Public</MenuItem>
                    <MenuItem value={ModelStatusEnum.Draft}>Draft</MenuItem>
                    <MenuItem value={ModelStatusEnum.PendingEdits}>Pending Edits</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-model-type" variant="standard">
                    Type
                  </InputLabel>
                  <Select
                    labelId="lbl-model-type"
                    {...getFormikFieldProps(props, "actionType", "Action Type")}
                    onChange={(e) => {
                      props.setFieldValue("actionType", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-type"
                    variant="standard"
                  >
                    <MenuItem value={DecisionActionTypeEnum.Enable}>Enable</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.ChangeBeta}>ChangeBeta</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.ChangeEta}>ChangeEta</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.ChangeGamma}>ChangeGamma</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.DoNothing}>DoNothing</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.NewEta}>NewEta</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.NewBeta}>NewBeta</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.NewGamma}>NewGamma</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.InitialAge}>InitialAge</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.ChangePF}>ChangePF</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.SetIAtoPercentOfEta}>SetIAtoPercentOfEta</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.SetQuantity}>SetQuantity</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.AddPercentOfEtatoIA}>AddPercentOfEtatoIA</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.AddToInitialAge}>AddToInitialAge</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.AddToEta}>AddToEta</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.OverwriteCostBundles}>OverwriteCostBundles</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.OverwritePlanIntervals}>OverwritePlanIntervals</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.ScaleCosts}>ScaleCosts</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.DisablePlan}>DisablePlan</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.AddToCosts}>AddToCosts</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.SetPlanInterval}>SetPlanInterval</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.SetPlanOffset}>SetPlanOffset</MenuItem>
                    <MenuItem value={DecisionActionTypeEnum.AddToCostsAndSplitout}>AddToCostsAndSplitout</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete decision"
                  subtitle="Confirm decision delete"
                  description="Are you sure that you'd like to remove this decision?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          name: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a name"),
          description: Yup.string().label("Description").max(2500, "Please input 2500 characters or less"),
          orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
        });
      }}
    />
  );
};

export default FormDecisionUpdate;
