import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { CSSObject, Theme, styled } from "@mui/material";
import { grey } from "@mui/material/colors";

import { GetLocalStorageItem, SetLocalStorageItem } from "../../utilities/Helpers";
import { ScrollPretty } from "../../utilities/Styles";
import MessagesContainer from "../messages/MessageContainer";
import LayoutSidebar from "./LayoutSidebar";
import LayoutDefaultAppBar from "./LayoutDefaultAppBar";
import { DrawerWidth } from "./LayoutConstants";

const openedMixin = (theme: Theme): CSSObject => ({
  marginLeft: DrawerWidth,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(9),
  },
});

const RootDiv = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  ...ScrollPretty,
  "& .content": {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(9),
    backgroundColor: grey[50],
    minHeight: "100vh",
  },
}));

const ToolbarDiv = styled("div", { shouldForwardProp: (prop: any) => prop !== "open" })<{ open: boolean }>(
  ({ theme, open }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    ...(open && {
      ...openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
    }),
  })
);

interface ILayoutDefaultProps {
  routeProps: RouteComponentProps<any>;
  children: React.ReactNode;
}

const SidebarToggleLocalStorageKey = "SidebarToggle";

function LayoutDefault({ children }: ILayoutDefaultProps) {
  const [open, setOpen] = React.useState(GetLocalStorageItem(SidebarToggleLocalStorageKey));

  const ChangeSidebarState = (newState: boolean) => {
    SetLocalStorageItem(SidebarToggleLocalStorageKey, newState);
    setOpen(newState);
  };

  return (
    <RootDiv>
      <LayoutDefaultAppBar open={open} setDrawerOpen={ChangeSidebarState} />
      <LayoutSidebar open={open} setDrawerOpen={ChangeSidebarState} />
      <main className="content">
        <ToolbarDiv open={open}>{children}</ToolbarDiv>
      </main>
      <MessagesContainer />
    </RootDiv>
  );
}

export default LayoutDefault;
