import React from "react";
import { Typography, Grid, Divider, Chip, styled } from "@mui/material";

import { IPlan } from "../../utilities/types/Plan";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import MapIcon from "@mui/icons-material/MapRounded";
import { IEvent } from "../../utilities/types/Event";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .planWrapper": {
    padding: theme.spacing(2),
    position: "relative",
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0)",
    transition: "background 0.25s ease-out !important",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },

  "& .planMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
}));

type IPlanReviewDecisionsTabProps = {
  plans: IPlan[];
  events: IEvent[];
};

const PlanReviewDecisionsTab = ({ plans, events }: IPlanReviewDecisionsTabProps) => {
  return <PlanReviewDecisionsTabDisplay plans={plans} events={events} />;
};

type IPlanReviewDecisionsTabDisplayProps = {
  events: IEvent[];
  plans: IPlan[];
};

const PlanReviewDecisionsTabDisplay = ({ plans, events }: IPlanReviewDecisionsTabDisplayProps) => {
  return (
    <DivWrapper>
      <AnimationWrapper>
        <div>
          {plans.map((plan) => {
            return (
              <>
                <Grid container className="planWrapper">
                  <Grid item xs={12}>
                    <PlanContainer plan={plan} events={events} />
                  </Grid>
                </Grid>
                <Divider />
              </>
            );
          })}
          {(!plans || !plans.length) && <WidgetNoResultsPlaceholder text="No plans" icon={MapIcon} flip={true} />}
        </div>
      </AnimationWrapper>
    </DivWrapper>
  );
};

function getEventById(events: IEvent[], eventId: string | undefined): IEvent | undefined {
  if (!events) return undefined;

  return events.find((x) => x.eventId === eventId);
}

const PlanContainer = ({ plan, events }: { plan: IPlan; events: IEvent[] }) => {
  const primaryEvent = getEventById(events, plan.primaryEventId);

  return (
    <div className="planMainWrapper">
      <div>
        <Typography variant="body1" style={{ textTransform: "capitalize" }}>
          {plan.code} - <span style={{ opacity: 0.7 }}>{plan.name}</span>
        </Typography>
        <Typography variant="caption">
          Primary Event:{" "}
          <span style={{ opacity: 0.7 }}>
            {primaryEvent ? primaryEvent.name : "-"} - {plan.eventInterval}
          </span>
        </Typography>
        {plan.eventInterval === -1 ? (
          <Chip
            label="Disabled"
            variant="outlined"
            size="small"
            style={{
              transform: "scale(0.85)",
              marginLeft: -4,
              color: "rgb(255 255 255)",
              backgroundColor: "rgba(226, 18, 18, 0.90)",
              borderColor: "#ff7777",
            }}
          />
        ) : null}
        {plan.offset > 0 ? (
          <Chip
            label={"Offset: " + plan.offset}
            variant="outlined"
            size="small"
            style={{
              transform: "scale(0.85)",
              marginLeft: -4,
              color: "rgb(255 255 255)",
              backgroundColor: "rgba(226, 18, 18, 0.90)",
              borderColor: "#ff7777",
            }}
          />
        ) : null}
        <br />
      </div>
    </div>
  );
};

export default PlanReviewDecisionsTab;
