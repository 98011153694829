import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/FunctionsOutlined";
import FormFunctionCreate from "../forms/FormFunctionCreate";
import { IFunction } from "../../../utilities/types/Function";

export interface IModalFunctionCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  componentId: string;
  onCompleteCallback(componentFunction?: IFunction): void;
}

function ModalFunctionCreate({
  onCancelCallback,
  componentId,
  open,
  onCompleteCallback,
  modelId,
}: IModalFunctionCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a function"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormFunctionCreate
          componentId={componentId}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFunctionCreate;
