import React from 'react';
import RouteSolver from './RouteSolver';
import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import PageSolverSearch from '../components/solver/pages/PageSolverSearch';


const RouteSolvers = () => {

    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:solverId`}><RouteSolver/></Route>
            <Route path={`/solvers`} render={props => <LayoutDefault routeProps={props}><PageSolverSearch {...props} /></LayoutDefault>} />
        </Switch>
    )
}


export default RouteSolvers;