import React from "react";
import { Button } from "@mui/material";
import WidgetSummaryCountAndButtons from "../generic/widgets/summaries/WidgetSummaryCountAndButtons";
import HeaderIcon from "@mui/icons-material/FilterDramaTwoTone";
import { NavLink } from "react-router-dom";
import { GetAdminSolversPage } from "../../routes/RouteLinkHelpers";

const AdminManageSolversWidget = () => {
  return (
    <WidgetSummaryCountAndButtons
      key="AdminManageSolversWidget"
      headerIcon={<HeaderIcon />}
      captionText="Solvers"
      mainText="Solvers"
      buttons={[
        <Button size="small" variant="outlined" color="primary" component={NavLink} to={GetAdminSolversPage()}>
          Manage
        </Button>,
      ]}
    />
  );
};

export default AdminManageSolversWidget;
