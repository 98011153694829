import React from 'react';
import {
    Route, Switch, useParams, useRouteMatch
} from "react-router-dom";
import LoaderAbsoluteCentred from '../components/generic/loaders/LoaderAbsoluteCentred';
import LayoutDefault from '../components/layout/LayoutDefault';
import { useFetchSolversPageHook } from '../components/solver/Hooks';
import { useFetchSolverHistoriesPageHook } from '../components/solverHistory/Hooks';
import PageSolverDetails from '../components/solverDetails/pages/PageSolverDetails';

interface RouteParams {
    solverId: string; // You can adjust the type accordingly
}

const RouteSolver = () => {
    let match = useRouteMatch();
    let { solverId } = useParams<RouteParams>();

    const { fetching } = useFetchSolversPageHook({
        pageNumber: 1,
        pageSize: 1,
        solverId,
        minPageNumberToFetch: 1,
    });

    const { fetching: fetchingSolverHistory } = useFetchSolverHistoriesPageHook({
        pageNumber: 1,
        pageSize: 20,
        solverId: solverId,
        minPageNumberToFetch: 1,
    });

    if (fetching) return <LoaderAbsoluteCentred loading={fetching || fetchingSolverHistory} />

    return (
        <Switch>
           <Route path={`${match.path}`} render={props => <LayoutDefault routeProps={props}><PageSolverDetails {...props} /></LayoutDefault>} />
        </Switch>
    )
}


export default RouteSolver