/**
 * Populates and styles a table.
 * - All properties are optional and will only be rendered if provided.
 * - All styles directly map to JSX arguments so are easily extensible.
 * - All CSS colours are supported like '#eee', 'blue', etc. @see https://www.w3schools.com/cssref/css_colors.asp
 *
 * @example - An example API
 * tables: [{
 *      // (Optional) Hides this specific table. Any JS truthy value will hide the table.
 *      hidden: true,
 *
 *      // (Optional) Displays a title above the table.
 *      title: `Temporal Flux Oscillation Performance`,
 *
 *      // (Optional) Header values. Leave blank to hide the row.
 *      headers: ["Property", "Description", "Benchmark"],
 *
 *      // Main table values. Supports numbers and strings.
 *      data: [
 *          ["Flux capacitor",         "Powers space time conversion engine",  12.34],
 *          ["Temporal defibrillator", "Enables transphasic time warp",        456.78],
 *          ["Spacial oscillator",     "Enables subspace dimensional travel", "90.12"],
 *          ["Average",                 undefined,                            "186.41"],
 *      ],
 *
 *      // (Optional) Style the header row. Set to '{}' to remove default styling.
 *      formatHead: {
 *          fontWeight: "bolder",
 *          backgroundColor: 'Lavender',
 *      },
 *
 *      // (Optional) Style columns. Note the size does not have to match the data table size.
 *      formatCol: [
 *          undefined,
 *          { align: "center" },
 *          { align: "right", fontWeight: 600 }, // 600 = semibold.
 *      ],
 *
 *      // (Optional) Style rows. Note the size does not have to match the data table size.
 *      formatRow: [
 *          {}, null, undefined,
 *          { fontWeight: 600, backgroundColor: '#f8f8f8' },
 *      ],
 * }],
 * */

export type ISolverUIResultSetTable = {
    data: string[][],
    hidden?: boolean,
    title?: string,
    headers?: string[],
    formatHead?: TableCellFormat,
    formatRow?: (TableCellFormat | undefined)[],
    formatCol?: (TableCellFormat | undefined)[],
}

export type TableCellFormat = {
    align?: "center" | "left" | "right",
    fontWeight?: "bold" | "bolder" | number, // CSS weights e.g. 'bold' or '600'
    backgroundColor?: string, // CSS colours like 'blue' or '#ff0000'
}

export const DefaultFormatHead: TableCellFormat = {
    fontWeight: 600,
    backgroundColor: 'AliceBlue',
}
