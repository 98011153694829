import { RootState } from "..";
import { IWorkflow } from "../../utilities/types/Workflow";

export function selectorGetSelectedWorkflow(state: RootState): IWorkflow | undefined {
  if (!state.workflows || !state.workflows.selectedWorkflowId) return undefined;

  return state.workflows.byId[state.workflows.selectedWorkflowId];
}

export function selectorGetAllWorkflows(state: RootState): IWorkflow[] {
  if (!state.workflows || !state.workflows.byId) return [];

  return Object.values(state.workflows.byId);
}
