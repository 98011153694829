import React, { useState } from "react";
import { TextField, Grid, Divider, Button, Autocomplete } from "@mui/material";
import { Formik } from "formik";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { NODE_TYPES, assetValidationSchema } from "../constants";
import { IAssetDetails, IAssetFormValues } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { useFetchModelsPageHook } from "../../model/Hooks";
import { selectorGetModelsByUpdatedDesc } from "../../../redux/model/selectors";

interface FormAddUpdateAssetProps {
  assetDetails: IAssetDetails;
  onCancelCallback(): void;
  onCompleteCallback(newAssetDetails: IAssetFormValues, nodeType: NODE_TYPES, nodeId?: string): void;
  onDelete(nodeId: string, nodeType: NODE_TYPES): void;
}

const FormAddUpdateAsset: React.FC<FormAddUpdateAssetProps> = ({
  assetDetails: { isEditMode, nodeId = "", assetFileName = "", assetName = "", assetModelId = "" },
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const assetModels = useSelector((state: RootState) => selectorGetModelsByUpdatedDesc(state));
  useFetchModelsPageHook({
    pageNumber: 1,
    pageSize: 500,
    minPageNumberToFetch: 1,
  });

  async function deleteHandler() {
    setShowDeleteConfirmation(false);
    onDelete(nodeId, NODE_TYPES.ASSET);
  }

  const handleSubmit = async (values: IAssetFormValues) => {
    const selectedAsset = assetModels.find((asset) => asset.modelId === values.assetModelId)?.title ?? "";
    const reqObj = {
      assetFileName: values.assetFileName,
      assetModelId: values.assetModelId,
      assetName: selectedAsset,
    };

    if (isEditMode) {
      onCompleteCallback(reqObj, NODE_TYPES.ASSET, nodeId);
      return;
    }
    onCompleteCallback(reqObj, NODE_TYPES.ASSET);
  };

  return (
    <Formik
      initialValues={{ assetName, assetFileName, assetModelId }}
      validationSchema={assetValidationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={props.handleChange}
                {...getFormikFieldProps(props, "assetFileName", "File Name")}
                fullWidth
                margin="normal"
                variant="standard"
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                {...getFormikFieldProps(props, "assetModelObj", "Select Asset Modal")}
                style={{ display: "block" }}
                defaultValue={assetModels.find((asset) => {
                  return asset.modelId === props.values.assetModelId;
                })}
                options={assetModels}
                getOptionLabel={(option) => option.title}
                onChange={(_, value) => {
                  props.setFieldValue("assetModelObj", value);
                  props.setFieldValue("assetModelId", value ? value.modelId : "");
                }}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(props.errors.assetModelId)}
                    helperText={props.errors.assetModelId}
                    variant="standard"
                    {...params}
                    label="Select Asset Modal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider light={true} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {isEditMode && (
                <Button
                  color="secondary"
                  disabled={props.isSubmitting}
                  style={{ marginRight: 16 }}
                  variant="contained"
                  onClick={toggleDeleteConfirmation}
                >
                  Delete
                </Button>
              )}
              <Button
                disabled={props.isSubmitting}
                variant="text"
                style={{ marginRight: 16 }}
                onClick={onCancelCallback}
              >
                Close
              </Button>
              <Button disabled={props.isSubmitting} variant="outlined" color="primary" onClick={props.submitForm}>
                {isEditMode ? "Update" : "Add"}
              </Button>
            </Grid>
            <LoaderAbsoluteCentred loading={props.isSubmitting} />
            <WidgetModalConfirmationDialog
              open={showDeleteConfirmation}
              title="Delete asset"
              subtitle="Confirm asset delete"
              description="Are you sure that you'd like to remove this asset?"
              onCancelCallback={toggleDeleteConfirmation}
              onConfirmCallback={deleteHandler}
              confirmButtonText="Delete"
            />
          </Grid>
        );
      }}
    </Formik>
  );
};

export default FormAddUpdateAsset;
