import { ActionTypesTermsAgreement, TermsAgreementState, ACTIONS_TERMS_AGREEMENT } from "./types";

const initialState: TermsAgreementState = {
    byId: {},
}


export function termsAgreementsReducer(
    state = initialState,
    action: ActionTypesTermsAgreement
): TermsAgreementState {
    switch(action.type){
        case ACTIONS_TERMS_AGREEMENT.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_TERMS_AGREEMENT.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_TERMS_AGREEMENT.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}