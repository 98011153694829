import { ISingleAssetOutput } from "../../utilities/types/SingleAssetOutput";

export const ACTIONS_SINGLE_ASSET_OUTPUT = {
  INVALIDATE: "INVALIDATE_SINGLE_ASSET_OUTPUTS",
  RECEIVE: "RECEIVE_SINGLE_ASSET_OUTPUTS",
  DELETE: "DELETE_SINGLE_ASSET_OUTPUT",
  UPDATE: "UPDATE_SINGLE_ASSET_OUTPUTS",
};

interface ActionSingleAssetOutputInvalidate {
  type: typeof ACTIONS_SINGLE_ASSET_OUTPUT.INVALIDATE;
  byId: { [key: string]: ISingleAssetOutput };
}

interface ActionSingleAssetOutputReceive {
  type: typeof ACTIONS_SINGLE_ASSET_OUTPUT.RECEIVE;
  byId: { [key: string]: ISingleAssetOutput };
}

export interface SingleAssetOutputState {
  byId: { [key: string]: ISingleAssetOutput };
}

export enum SingleAssetOutputSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type ActionTypesSingleAssetOutput = ActionSingleAssetOutputReceive | ActionSingleAssetOutputInvalidate;
