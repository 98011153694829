import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { ISolverSummaryOutputTypeMapping } from "../../utilities/types/SolverSummaryOutputTypeMapping";
import { fetchSearchSolverSummaryOutputTypeMappings } from "../../redux/solverSummaryOutputTypeMapping/actions";


export interface IUseFetchSolverSummaryOutputTypeMappingsPageHookProps {
    pageNumber: number,
    pageSize: number,
    minPageNumberToFetch?: number,
    solverId?: string,
    solverSummaryOutputTypeMappingId?: string,
}


export const useFetchSolverSummaryOutputTypeMappingsPageHook = ({ pageNumber, minPageNumberToFetch = 1, pageSize, solverSummaryOutputTypeMappingId, solverId }: IUseFetchSolverSummaryOutputTypeMappingsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ISolverSummaryOutputTypeMapping[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ISolverSummaryOutputTypeMapping[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve results
                var resp: any = await dispatch(fetchSearchSolverSummaryOutputTypeMappings({
                    pageSize,
                    pageNumber,
                    solverId,
                    solverSummaryOutputTypeMappingId,
                }));

                // Check if there are more pages to show
                if (resp && resp.success && resp.data && resp.data.solverSummaryOutputTypeMappings && resp.data.solverSummaryOutputTypeMappings.length) {
                    setMorePages(resp.data.solverSummaryOutputTypeMappings.length >= pageSize);
                    setLastResultSet(resp.data.solverSummaryOutputTypeMappings);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [pageNumber, pageSize, dispatch, minPageNumberToFetch, solverId, solverSummaryOutputTypeMappingId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.solverSummaryOutputTypeMappingId === x.solverSummaryOutputTypeMappingId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}