import React, { useState } from "react";
import {
  Typography,
  Button,
  Avatar,
  Grid,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { IIdentifier } from "../../utilities/types/Identifier";
import { useFetchIdentifiersPageHook } from "./Hooks";
import { selectorGetGlobalIdentifiers } from "../../redux/identifier/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalIdentifierCreate from "./modals/ModalIdentifierCreate";
import { FileTypeEnum } from "../../utilities/types/File";
import { useFileSourceSingleHook } from "../file/Hooks";
import { fetchSearchIdentifiers } from "../../redux/identifier/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalIdentifierUpdate from "./modals/ModalIdentifierUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/LabelTwoTone";
import EditIcon from "@mui/icons-material/EditTwoTone";
import MenuIcon from "@mui/icons-material/MenuOutlined";

const imageDiameter = 50;

const DivWrapper = styled("div")(({ theme }) => ({
  "& .identifierWrapper": {
    padding: theme.spacing(1),
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
    transition: "background 0.25s ease-out !important",
    textAlign: "left",
  },
  "& .identifierImageWrapper": {
    width: imageDiameter,
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .identifierMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .btnAddIdentifier": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
  },
}));

type IIdentifierGlobalProps = {
  canEdit: boolean;
};

const IdentifierGlobal = ({ canEdit }: IIdentifierGlobalProps) => {
  const dispatch = useDispatch();
  const { fetching } = useFetchIdentifiersPageHook({
    pageNumber: 1,
    pageSize: 100,
    minPageNumberToFetch: 1,
  });
  const identifiers = useSelector((store: RootState) => selectorGetGlobalIdentifiers(store));
  const onUploadComplete = async (identifierId: string) => {
    await dispatch(fetchSearchIdentifiers({ identifierId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <IdentifierGlobalDisplay
      canEdit={canEdit}
      identifiers={identifiers}
      loading={fetching}
      onUploadComplete={onUploadComplete}
    />
  );
};

type IIdentifierGlobalDisplayProps = {
  canEdit: boolean;
  identifiers: IIdentifier[];
  loading: boolean;
  onUploadComplete(identifierId: string): void;
};

const IdentifierGlobalDisplay = ({
  identifiers,
  loading,
  canEdit,
  onUploadComplete,
}: IIdentifierGlobalDisplayProps) => {
  const [showCreateIdentifierModal, setShowCreateIdentifierModal] = useState(false);
  const [identifierToUpdate, setIdentifierToUpdate] = useState<IIdentifier>();

  function toggleShowCreateIdentifierModal() {
    setShowCreateIdentifierModal(!showCreateIdentifierModal);
  }

  function onIdentifierClick(identifier: IIdentifier) {
    if (canEdit) setIdentifierToUpdate(identifier);
  }

  function hideIdentifierUpdate() {
    setIdentifierToUpdate(undefined);
  }

  return (
    <DivWrapper>
      {canEdit ? (
        <Button
          color="primary"
          variant="outlined"
          className="btnAddIdentifier"
          onClick={toggleShowCreateIdentifierModal}
        >
          Add Identifier
        </Button>
      ) : null}
      <AnimationWrapper>
        <div>
          {identifiers.map((identifier) => {
            return (
              <>
                <GlobalIdentifier
                  identifier={identifier}
                  canEdit={canEdit}
                  onUploadComplete={onUploadComplete}
                  onIdentifierClick={onIdentifierClick}
                />
                <Divider light={true} />
              </>
            );
          })}
          {(!identifiers || !identifiers.length) && (
            <WidgetNoResultsPlaceholder text="No identifiers" icon={PlaceholderIcon} flip={true} />
          )}
        </div>
      </AnimationWrapper>

      {loading ? <LoaderAbsoluteCentred loading={loading} /> : null}

      <ModalIdentifierCreate
        open={showCreateIdentifierModal}
        onCancelCallback={toggleShowCreateIdentifierModal}
        onCompleteCallback={toggleShowCreateIdentifierModal}
      />

      {/* Update */}
      {identifierToUpdate && (
        <ModalIdentifierUpdate
          open={identifierToUpdate !== undefined}
          onCancelCallback={hideIdentifierUpdate}
          onCompleteCallback={hideIdentifierUpdate}
          identifier={identifierToUpdate}
          canEdit={canEdit}
        />
      )}
    </DivWrapper>
  );
};

interface IGlobalIdentifierProps {
  identifier: IIdentifier;
  canEdit: boolean;
  onUploadComplete(identifierId: string): void;
  onIdentifierClick(identifier: IIdentifier): void;
}

function GlobalIdentifier({ identifier, onIdentifierClick, canEdit }: IGlobalIdentifierProps) {
  const imageUrl = useFileSourceSingleHook({ fileId: identifier.mainImageId || "", fileType: FileTypeEnum.Image });
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function onEditIdentifierClick() {
    setMenuAnchorEl(undefined);
    onIdentifierClick(identifier);
  }

  return (
    <Grid container className="identifierWrapper">
      <Grid item xs={1}>
        <div className="identifierImageWrapper">
          <Avatar alt={identifier.code} src={imageUrl} style={{ border: "1px solid rgba(0,0,0,0.1)" }} />
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className="identifierMainWrapper">
          <div>
            <Typography variant="body1">{identifier.code}</Typography>
            <Typography variant="caption">{identifier.name}</Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={3} style={{ textAlign: "right" }}>
        {canEdit ? (
          <>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              <MenuItem onClick={onEditIdentifierClick}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit identifier" />
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Grid>
      <LoaderAbsoluteCentred loading={loading} />
    </Grid>
  );
}

export default IdentifierGlobal;
