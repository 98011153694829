import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Divider, DialogActions, Button, styled, TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { lightBlue } from "@mui/material/colors";
import { fetchCreateFile } from "../../../redux/file/actions";
import { FileTypeEnum, IFile } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { useDispatch } from "react-redux";
import { createMessage } from "../../../redux/message/actions";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import AvatarEditor from "react-avatar-editor";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { ShowMessage, UpdateMessage, DataURLtoBlob } from "../../../utilities/Helpers";
import { IApiResponse } from "../../../utilities/types/Api";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

const DivWrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  border: "2px dotted #CCC",
  padding: theme.spacing(3),
  paddingBottom: 0,
  margin: theme.spacing(1),
  backgroundColor: "#EEE",
  minHeight: 165,
  "& .dragAndDropContainerActive": {
    borderColor: `${lightBlue[500]}`,
    backgroundColor: `${lightBlue[100]}`,
    color: `${lightBlue[500]}`,
    "& $uploadIcon": {
      color: `${lightBlue[500]}`,
    },
  },
  "& .uploadIcon": {
    color: "rgba(0,0,0,0.4)",
    transform: "translate(-50%, -50%)",
    fontSize: 40,
    marginTop: theme.spacing(3),
    marginLeft: 40,
  },

  "& .imageEditorIcons": {
    transition: "background-color 0.2s ease-in-out",
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
    padding: "3px",
    boxShadow: "1px 1px 1px rgba(255, 255, 255, 0.1)",
    cursor: "pointer",
    fontSize: 30,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
    },
    borderRadius: theme.shape.borderRadius,
  },
  "& .avatarEditActions": {
    textAlign: "center",
  },
  "& .avatarEditorWrapper": {
    position: "relative",
    textAlign: "center",
  },
}));

interface FormFileUploadProps {
  entityId: string;
  entityType: EntityTypeEnum;
  fileType?: FileTypeEnum;
  description?: string;
  onUploadCompleteCallback(file?: IFile): void;
  onCancelCallback(): void;
  imageHeight?: number;
  imageWidth?: number;
  canSpecifyPath: boolean;
  defaultFileBucketPath?: string;
}

const FormFileUpload = ({
  entityId,
  entityType,
  fileType,
  description,
  onUploadCompleteCallback,
  onCancelCallback,
  imageHeight,
  imageWidth,
  canSpecifyPath = false,
  defaultFileBucketPath = "",
}: FormFileUploadProps) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [activeFile, setActiveFile] = useState<File>();
  const [editor, setEditor] = useState<any>();
  const [fileBucket, setFileBucket] = useState<string>(defaultFileBucketPath); // Step 1: Add state for fileBucket

  const upload = useCallback(
    async function upload(file: File) {
      setUploading(true);

      var message = await ShowMessage("Uploading...", MessageTypeEnum.Information);

      // Upload the file
      var resp = (await dispatch(
        fetchCreateFile({
          fileType: fileType ? fileType : FileTypeEnum.Default,
          entityId,
          description: description ? description : "",
          entityType,
          file: file,
          fileBucket,
        })
      )) as unknown as IApiResponse;

      if (resp?.success) {
        UpdateMessage({ ...message, text: "Upload complete", type: MessageTypeEnum.Success });
        onUploadCompleteCallback(resp && resp.success && resp.data ? resp.data.file : null);
      } else {
        if (resp?.messages?.length > 0) {
          UpdateMessage({ ...message, text: resp?.messages[0], type: MessageTypeEnum.Error });
        } else {
          UpdateMessage({
            ...message,
            text: "Network error or CORS issue occurred while uploading the file. Please try again later.",
            type: MessageTypeEnum.Error,
          });
        }
      }

      setUploading(false);
    },
    [description, dispatch, fileBucket, entityId, entityType, fileType, onUploadCompleteCallback]
  );

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles == null || acceptedFiles.length === 0) {
        dispatch(createMessage("Please select a valid file.", MessageTypeEnum.Error));
        return;
      }

      // If it's an image we want the user to crop it
      if (fileType === FileTypeEnum.Image) {
        setActiveFile(acceptedFiles[0]);
        return;
      }
      // If canSpecifyPath is true, don't automatically upload
      if (!canSpecifyPath) {
        // If it's not an image we can just upload it now
        upload(acceptedFiles[0]);
      } else {
        // If canSpecifyPath is true, store the file for later upload
        setActiveFile(acceptedFiles[0]);
      }
    },
    [dispatch, fileType, upload, canSpecifyPath]
  );

  async function onUploadClick() {
    if (canSpecifyPath) {
      // If canSpecifyPath is true, check if the user has specified a path
      if (!fileBucket) {
        // Show a message or handle it as needed
        dispatch(createMessage("Please specify a file path.", MessageTypeEnum.Error));
        return;
      }
    }
    if (fileType === FileTypeEnum.Image) {
      const canvas = editor.getImageScaledToCanvas();
      var blobAsFile: any = DataURLtoBlob(canvas.toDataURL("image/jpeg"));
      blobAsFile.lastModified = activeFile?.lastModified || new Date().getTime();
      blobAsFile.name = activeFile?.name || "";

      upload(blobAsFile as File);
    } else {
      upload(activeFile as File);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {!activeFile && (
        <DivWrapper {...getRootProps()} className={`${isDragActive ? "dragAndDropContainerActive" : ""}`}>
          <input {...getInputProps()} />

          <AnimationWrapper>
            <div style={{ width: activeFile ? 750 : "inherit" }}>
              {!uploading ? (
                <>
                  <CloudUploadIcon className="uploadIcon" />
                  <p>
                    Drag and drop some files here
                    <br /> or click to select files
                  </p>
                </>
              ) : (
                <CloudUploadIcon className="uploadIcon" />
              )}
            </div>
          </AnimationWrapper>
        </DivWrapper>
      )}
      {canSpecifyPath && (
        <TextField
          label="File Bucket"
          value={fileBucket}
          onChange={(e) => setFileBucket(e.target.value)}
          fullWidth
          margin="normal"
          variant="standard"
        />
      )}
      <div>
        {activeFile && fileType === FileTypeEnum.Image ? (
          <EditImageSection
            height={imageHeight || 250}
            width={imageWidth || 800}
            image={activeFile}
            editor={editor}
            setEditor={setEditor}
          />
        ) : null}
      </div>

      <Divider variant="fullWidth" light={true} />
      <DialogActions>
        <Button onClick={onCancelCallback}>Close</Button>
        {activeFile && (
          <Button color="primary" variant="contained" onClick={onUploadClick}>
            Upload
          </Button>
        )}
      </DialogActions>
      <LoaderAbsoluteCentred loading={uploading} />
    </>
  );
};

function EditImageSection({
  width,
  height,
  image,
  setEditor,
}: {
  width: number;
  height: number;
  image: File;
  editor: any;
  setEditor: any;
}) {
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);

  return (
    <div className="avatarEditorWrapper">
      <AvatarEditor
        ref={setEditor}
        image={image}
        width={rotation % 360 === 0 || rotation % 360 === 180 ? width : height}
        height={rotation % 360 === 0 || rotation % 360 === 180 ? height : width}
        border={4}
        color={[255, 255, 255, 0.3]} // RGBA
        scale={scale}
        rotate={rotation}
        style={{ backgroundColor: "#000" }}
      />
      <div className="avatarEditActions">
        <ZoomOutIcon className="imageEditorIcons" onClick={() => setScale(scale * 0.9)} />
        <ZoomInIcon className="imageEditorIcons" onClick={() => setScale(scale * 1.1)} />
        <RotateRightIcon className="imageEditorIcons" onClick={() => setRotation(rotation + 90)} />
        <RotateLeftIcon className="imageEditorIcons" onClick={() => setRotation(rotation - 90)} />
      </div>
    </div>
  );
}

export default FormFileUpload;
