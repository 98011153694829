import React from "react";
import DialogContent from "@mui/material/DialogContent";
import HeaderIcon from "@mui/icons-material/FilterDramaTwoTone";

import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { IModalAddUpdateBucketNodeProps } from "../types";
import FormAddUpdateBucket from "../forms/FormAddUpdateBucket";

const ModalAddUpdateBucketNode: React.FC<IModalAddUpdateBucketNodeProps> = ({
  bucketDetails,
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={bucketDetails.open}
      title={bucketDetails.isEditMode ? "Update path" : "Add a new path"}
      subtitle="Fill in the form to complete."
      headerIcon={<HeaderIcon />}
    >
      <DialogContent>
        <FormAddUpdateBucket
          onCancelCallback={onCancelCallback}
          bucketDetails={bucketDetails}
          onCompleteCallback={onCompleteCallback}
          onDelete={onDelete}
        />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalAddUpdateBucketNode;
