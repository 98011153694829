import { ACTIONS_TERMS_AGREEMENT } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ITermsAgreement } from '../../utilities/types/TermsAgreement';


export const receiveTermsAgreements = (termsAgreements: ITermsAgreement[]) => {

    var byIdObjectToDispatch: { [key: string]: ITermsAgreement } = {};

    for (var i = 0; i < termsAgreements.length; i++) {
        byIdObjectToDispatch[termsAgreements[i].termsAgreementId] = termsAgreements[i];
    }

    return {
        type: ACTIONS_TERMS_AGREEMENT.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const fetchCreateTermsAgreement = (): AppThunk<Promise<ITermsAgreement>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);
    var clientDetails = "";

    try {

        // Retrieve client details where available
        clientDetails = await (await fetch("https://www.cloudflare.com/cdn-cgi/trace"))?.text();
    }
    catch { }

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/termsAgreements`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody({
                details: clientDetails,
            }),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.termsAgreements) {
            dispatch(receiveTermsAgreements(parsedResp.data.termsAgreements));
            return parsedResp.data.termsAgreements;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating termsAgreement.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating termsAgreement.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchTermsAgreementsProps {
    pageNumber: number,
    pageSize: number,
    termsAgreementId?: string,
    userId: string,
}


export const fetchSearchTermsAgreements = (searchParams: IFetchSearchTermsAgreementsProps): AppThunk<Promise<ITermsAgreement[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/termsAgreements`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.termsAgreements) {

            // Remove unmapped image property
            for (var termsAgreement of parsedResp.data.termsAgreements) {
                delete termsAgreement.mainImage;
            }

            dispatch(receiveTermsAgreements(parsedResp.data.termsAgreements));
            return parsedResp.data.termsAgreements;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching termsAgreement.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching termsAgreement.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}