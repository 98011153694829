import { Maturity } from './types/Model'

export const MaturityLabel = {
    UnderConstruction: "Under Construction",
    Low: "Low Maturity",
    Medium: "Medium Maturity",
    High: "High Maturity",
}

export const MaturityLabelMap = {
    [Maturity.UnderConstruction]: MaturityLabel.UnderConstruction,
    [Maturity.Low]: MaturityLabel.Low,
    [Maturity.Medium]: MaturityLabel.Medium,
    [Maturity.High]: MaturityLabel.High,
}
