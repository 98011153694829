import { ActionTypesUserRole, UserRoleState, ACTIONS_USER_ROLES } from "./types";

const initialState: UserRoleState = {
    byId: {},
}


export function userRolesReducer(
    state = initialState,
    action: ActionTypesUserRole
): UserRoleState {
    switch(action.type){
        case ACTIONS_USER_ROLES.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_USER_ROLES.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        case ACTIONS_USER_ROLES.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        default:
            return state;
    }
}