import React from "react";
import { Grid as MuiGrid, Breadcrumbs, Link, styled } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import { GetAdminPage } from "../../../routes/RouteLinkHelpers";
import SolverEnquiryContainer from "../SolverEnquiryContainer";
import { HomeIconLink } from "../../generic/HomeIconLink";

const Grid = styled(MuiGrid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

interface IPageSolverManagementProps {
  routeProps: RouteComponentProps<any>;
}

const PageSolverManagement = ({ routeProps }: IPageSolverManagementProps) => {
  return (
    <Container maxWidth="xl">
      <AnimationWrapper>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIconLink />
            <Link color="inherit" href={GetAdminPage()}>
              Admin
            </Link>
            <Link color="textPrimary" aria-current="page" style={{ textDecoration: "none" }}>
              Solvers
            </Link>
          </Breadcrumbs>
        </Grid>
      </AnimationWrapper>
      <AnimationWrapper>
        <Grid container spacing={5} style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <SolverEnquiryContainer canEdit={true} />
          </Grid>
        </Grid>
      </AnimationWrapper>
    </Container>
  );
};

export default PageSolverManagement;
