import { ISolverInputField } from "../../utilities/types/SolverInputField";


export const ACTIONS_SOLVER_INPUT_FIELD = {
    INVALIDATE: 'INVALIDATE_SOLVER_INPUT_FIELDS',
    RECEIVE: 'RECEIVE_SOLVER_INPUT_FIELDS',
    DELETE: 'DELETE_SOLVER_INPUT_FIELD',
    UPDATE: 'UPDATE_SOLVER_INPUT_FIELDS',
}

interface ActionSolverInputFieldsInvalidate {
    type: typeof ACTIONS_SOLVER_INPUT_FIELD.INVALIDATE,
    byId: { [key: string]: ISolverInputField },
}

interface ActionSolverInputFieldsReceive {
    type: typeof ACTIONS_SOLVER_INPUT_FIELD.RECEIVE
    byId: { [key: string]: ISolverInputField },
}

export interface SolverInputFieldState {
    byId: { [key: string]: ISolverInputField },
}

export enum SolverInputFieldSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
    VersionNumberAsc = 30,
    VersionNumberDesc = 40,
}

export type ActionTypesSolverInputField = ActionSolverInputFieldsReceive | ActionSolverInputFieldsInvalidate;