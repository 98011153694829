import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormSummaryOutputTypeCreate from "../forms/FormSummaryOutputTypeCreate";
import { ISummaryOutputType } from "../../../utilities/types/SummaryOutputType";
import MapIcon from "@mui/icons-material/MapRounded";

export interface IModalSummaryOutputTypeCreateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(summaryOutputType?: ISummaryOutputType): void;
}

function ModalSummaryOutputTypeCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
}: IModalSummaryOutputTypeCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new summary output type"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormSummaryOutputTypeCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSummaryOutputTypeCreate;
