export const ScrollPretty = {
  "*::-webkit-scrollbar": {
    width: "0.5em",
    height: 6,
  },
  "*::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    padding: "3px 2px",
    border: "1px solid rgba(0,0,0,0.075)",
    borderRadius: 6,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.25)",
    border: "none",
    borderRadius: 6,
  },
};

type ClassProp = string | boolean | undefined | null;

/** Easily join CSS class names together.
 *
 * @example Join as many groups as you like.
 *      join("class1", "class2 class3")
 *      -> "class1 class2 class3"
 *
 * @example Falsy parameters are omitted. Useful when using classes with flags.
 *      join("class1", false && "class2", true && "class3")
 *      -> "class1 class3"
 *
 * @example Falsy parameters are omitted. Useful when variables are nullable.
 *      join("class1", props.className) // className is optional on props so might be null
 *      -> "class1"
 */
export function join(...classes: ClassProp[]): string {
  return joinRaw(classes, " ");
}
export function joinRaw(classes: ClassProp[], separator: string): string {
  return classes.filter((x) => !!x).join(separator);
}
