import { RootState } from "..";
import { ISummaryOutputType } from "../../utilities/types/SummaryOutputType";
import { selectorGetSolverSummaryOutputTypeMappingsBySolverId } from "../solverSummaryOutputTypeMapping/selectors";


export function selectorGetSummaryOutputTypeById(state: RootState, summaryOutputTypeId: string) : ISummaryOutputType | undefined {

    if (!state.summaryOutputTypes || !state.summaryOutputTypes.byId) return undefined;

    return state.summaryOutputTypes.byId[summaryOutputTypeId];
}


export function selectorGetSummaryOutputTypes(state: RootState) : ISummaryOutputType[] {

    if (!state.summaryOutputTypes || !state.summaryOutputTypes.byId) return [];

    return sortSummaryOutputTypesByNameDescending(Object.values(state.summaryOutputTypes.byId));
}


export function selectorGetSummaryOutputTypesBySolverId(state: RootState, solverId: string) : ISummaryOutputType[] {

    if(!state.summaryOutputTypes || !state.summaryOutputTypes.byId || !state.solverSummaryOutputTypeMappings || !state.solverSummaryOutputTypeMappings.byId) return [];

    // Retrieve all mappings that are relevant for this solver
    var mappings = selectorGetSolverSummaryOutputTypeMappingsBySolverId(state, solverId);
    if(!mappings.length) return [];

    // Retrieve output types that have been mapped
    var summaryOutputTypes = selectorGetSummaryOutputTypes(state);
    var results = summaryOutputTypes.filter(x =>  mappings.some(m => m.summaryOutputTypeId === x.summaryOutputTypeId));

    return results;
}
export function sortSummaryOutputTypesByNameAsc(outputTypes: ISummaryOutputType[]) : ISummaryOutputType[] {
    return outputTypes.sort((a, b) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);
}

function sortSummaryOutputTypesByNameDescending(summaryOutputTypes: ISummaryOutputType[]) {
    return summaryOutputTypes.sort((a, b) => {
        return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
    });
}