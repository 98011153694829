import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchDecisionAssetInputFieldMappingsProps, fetchSearchDecisionAssetInputFieldMappings } from "../../redux/decisionAssetInputFieldMapping/actions";
import { IDecisionAssetInputFieldMapping } from "../../utilities/types/DecisionAssetInputFieldMapping";


interface IUseFetchDecisionAssetInputFieldMappingsPageHookProps extends IFetchSearchDecisionAssetInputFieldMappingsProps {
    minPageNumberToFetch: number;
}


export const useFetchDecisionAssetInputFieldMappingsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchDecisionAssetInputFieldMappingsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IDecisionAssetInputFieldMapping[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IDecisionAssetInputFieldMapping[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var decisionAssetInputFieldMappings = await dispatch(fetchSearchDecisionAssetInputFieldMappings({
                    pageSize,
                    pageNumber,
                    modelId,
                })) as unknown as IDecisionAssetInputFieldMapping[];

                if (decisionAssetInputFieldMappings && decisionAssetInputFieldMappings.length) {
                    setMorePages(decisionAssetInputFieldMappings.length >= pageSize)
                    setLastResultSet(decisionAssetInputFieldMappings);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.decisionAssetInputFieldMappingId === x.decisionAssetInputFieldMappingId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}