import { RootState } from "..";
import { GetUserId } from "../../utilities/ApiUtils";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { EntityPermissionTypeEnum, IEntityPermission } from "../../utilities/types/EntityPermission";


export function selectorGetEntityPermissionsByUserId(state: RootState, userId: string): IEntityPermission[] {

    if (!state.entityPermissions || !state.entityPermissions.byId) return [];

    return sortEntityPermissionsByType(
        Object.values(state.entityPermissions.byId).filter(x => x.userDetailId === userId)
    );
}


export function selectorGetEntityPermissionsByUserIdAndEntityId(state: RootState, userId: string, entityId: string): IEntityPermission[] {

    if (!state.entityPermissions || !state.entityPermissions.byId) return [];

    return sortEntityPermissionsByType(
        Object.values(state.entityPermissions.byId).filter(x => x.userDetailId === userId && x.entityId === entityId)
    );
}


export function selectorGetEntityPermissionsByEntity(state: RootState, entityId: string, entityType: EntityTypeEnum): IEntityPermission[] {
    if (!state.entityPermissions || !state.entityPermissions.byId) return [];

    return sortEntityPermissionsByType(
        Object.values(state.entityPermissions.byId).filter(x => x.entityId === entityId && x.entityType === entityType)
    );
}


export function selectorGetEntityPermissionOfType(state: RootState, userId: string = GetUserId(), entityPermissionType: EntityPermissionTypeEnum): boolean {

    if (!state.entityPermissions || !state.entityPermissions.byId) return false;

    return Object.values(state.entityPermissions.byId).some(x => x.userDetailId === userId && x.type === entityPermissionType);
}


export function selectorUserHasEntityPermission(state: RootState, userId: string = GetUserId(), entityPermissionType: EntityPermissionTypeEnum, entityId: string) {

    if (!state.entityPermissions || !state.entityPermissions.byId) return false;

    return Object.values(state.entityPermissions.byId)
        .some(x => x.userDetailId === userId
            && x.type === entityPermissionType
            && x.entityId === entityId
        );
}


function sortEntityPermissionsByType(entityPermissions: IEntityPermission[]) {
    return entityPermissions.sort((a, b) => a.type - b.type);
}