import { ACTIONS_COMPONENT, ComponentSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IComponent } from '../../utilities/types/Component';
import { selectorGetComponentsByModelId } from './selectors';
import { IModel } from '../../utilities/types/Model';
import { enableFunctionsByModelId } from '../function/actions';
import { enableFunctionalFailuresByModelId } from '../functionalFailure/actions';
import { enableFailureModesByModelId } from '../failureMode/actions';


export const receiveComponents = (components: IComponent[]) => {

    var byIdObjectToDispatch: { [key: string]: IComponent } = {};

    for (var i = 0; i < components.length; i++) {
        byIdObjectToDispatch[components[i].componentId] = components[i];
    }

    return {
        type: ACTIONS_COMPONENT.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteComponent = (component: IComponent) => ({
    type: ACTIONS_COMPONENT.DELETE,
    byId: { [component.componentId]: component }
});


export interface IFetchCreateComponentProps {
    modelId: string,
    name: string,
    description: string,
    orderNumber: number,
}

export interface IFetchCreateComponentsProps {
    components: IFetchCreateComponentProps[]
}


export const fetchCreateComponent = (componentsToCreate: IFetchCreateComponentsProps): AppThunk<Promise<IComponent>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/components`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(componentsToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.components) {
            dispatch(receiveComponents(parsedResp.data.components));
            return parsedResp.data.components;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating component.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating component.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateComponentProps {
    componentId: string,
    name: string,
    description: string,
    primary: boolean,
    orderNumber: number,
}


export const fetchUpdateComponent = (props: IFetchUpdateComponentProps): AppThunk<Promise<IComponent>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/components`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.components) {
            dispatch(receiveComponents(parsedResp.data.components));
            return parsedResp.data.components;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating component.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating component.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchComponentsProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    componentId?: string,
    text?: string,
    createdBy?: string,
    orderType?: ComponentSearchOrderTypeEnum,
}


export const fetchSearchComponents = (searchParams: IFetchSearchComponentsProps): AppThunk<Promise<IComponent[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/components`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.components) {
            dispatch(receiveComponents(parsedResp.data.components));
            return parsedResp.data.components;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching component.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching component.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteComponentProps {
    componentId: string,
}


export const fetchDeleteComponent = (props: IFetchDeleteComponentProps): AppThunk<Promise<IComponent>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/components`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.components && parsedResp.data.components.length) {
            dispatch(requestDeleteComponent(parsedResp.data.components[0]));
            return parsedResp.data.components[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting component.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting component.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}



export interface IEnableComponentsByModelIdProps {
    modelId: string,
}

export const enableComponentsByModelId = ({ modelId }: IEnableComponentsByModelIdProps): AppThunk<Promise<void>> => async (dispatch, getStore) => {
    dispatch(receiveComponents(
        selectorGetComponentsByModelId(
            getStore(), modelId).map((component) => {
                return { ...component, disabled: false };
            })));
}


export interface IEnableComponentHierachyProps {
    model: IModel,
}


export const EnableComponentHierachy = ({ model }: IEnableComponentHierachyProps): AppThunk<Promise<void>> => async dispatch => {
    if (model && model.modelId) {
        dispatch(enableComponentsByModelId({ modelId: model.modelId }));
        dispatch(enableFunctionsByModelId({ modelId: model.modelId }));
        dispatch(enableFunctionalFailuresByModelId({ modelId: model.modelId }));
        dispatch(enableFailureModesByModelId({ modelId: model.modelId }));
    }
}