import { Button, Typography } from "@mui/material";
import HeaderIcon from "@mui/icons-material/AccountTree";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchSearchModels } from "../../redux/model/actions";
import { selectorGetModelById } from "../../redux/model/selectors";
import { selectorGetMaxModelHistoryVersionNumber } from "../../redux/modelHistory/selectors";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { ShowMessage, UpdateMessage } from "../../utilities/Helpers";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { IModel } from "../../utilities/types/Model";

import FileRendererSingle from "../file/renderers/FileRendererSingle";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";

import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import ModelHistoryCreateModal from "../modelHistory/modals/ModalModelHistoryCreate";
import ModelHistoryList from "../modelHistory/ModelHistoryList";

import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { GetUserId } from "../../utilities/ApiUtils";
import { useFetchModelHistoriesPageHook } from "../modelHistory/Hooks";

type ModelDetailsSummaryProps = {
  modelId: string;
};

const ModelDetailsSummary = ({ modelId }: ModelDetailsSummaryProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const canEdit = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesModelUpdate)
  );

  useFetchModelHistoriesPageHook({
    pageNumber: 1,
    pageSize: 20,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  return <ModelDetailsSummaryDisplay model={model} canEdit={canEdit} />;
};

type ModelDetailsSummaryDisplayProps = {
  model?: IModel;
  canEdit: boolean;
};

const ModelDetailsSummaryDisplay = ({ model, canEdit }: ModelDetailsSummaryDisplayProps) => {
  const [fetchingModel, setFetchingModel] = useState(false);
  const dispatch = useDispatch();

  const [modelHistoryCreateModalOpen, setModelHistoryCreateModalOpen] = useState(false);

  const toggleHistoryCreateModal = () => setModelHistoryCreateModalOpen(!modelHistoryCreateModalOpen);
  const maxModelHistoryVersionNumber = useSelector((store: RootState) =>
    selectorGetMaxModelHistoryVersionNumber(store, model ? model.modelId : "")
  );

  if (!model) {
    return <WidgetNoResultsPlaceholder text="Model not found" fade={true} icon={HeaderIcon} />;
  }

  // Used to refresh the image link when an image is uploaded
  const onUploadComplete = async () => {
    var message = await ShowMessage("Retrieving model", MessageTypeEnum.Information);
    setFetchingModel(true);

    // Fetch the updated model
    var models = await dispatch(
      fetchSearchModels({ modelId: model.modelId, pageNumber: 1, pageSize: 1, status: model.status })
    );
    if (models && models.length) {
      UpdateMessage({ ...message, text: "Model updated", type: MessageTypeEnum.Success });
    } else {
      UpdateMessage({ ...message, text: "Unable retrieve updated model", type: MessageTypeEnum.Error });
    }

    setFetchingModel(false);
  };

  return (
    <div>
      <div>
        {!model && <Typography variant="caption">Model not found.</Typography>}
        {model && (
          <FileRendererSingle
            fileId={model.mainImageId}
            canUpload={canEdit}
            fileType={FileTypeEnum.Image}
            entityId={String(model.modelId)}
            entityType={EntityTypeEnum.Model}
            height={150}
            onUploadComplete={onUploadComplete}
          />
        )}
        <LoaderAbsoluteCentred loading={fetchingModel} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ width: "90%" }}>
          <ModelHistoryList modelId={model.modelId} canEdit={canEdit} />
        </div>
        {canEdit ? (
          <Button onClick={toggleHistoryCreateModal}>
            <AddIcon />
          </Button>
        ) : null}
      </div>

      <ModelHistoryCreateModal
        versionNumber={maxModelHistoryVersionNumber + 1}
        open={modelHistoryCreateModalOpen}
        onCancelCallback={toggleHistoryCreateModal}
        onCompleteCallback={toggleHistoryCreateModal}
        modelId={model.modelId}
      />
    </div>
  );
};

export default ModelDetailsSummary;
