export const Configuration = {
  TEST: process.env.NODE_ENV,
  BASE_HOST: process.env.REACT_APP_BASE_HOST,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  NO_IMAGE_URL: process.env.REACT_APP_NO_IMAGE_URL,
  COGNITO_ENDPOINT: process.env.REACT_APP_COGNITO_ENDPOINT,
  COGNITO_SIGNUP_ENDPOINT: process.env.REACT_APP_COGNITO_SIGNUP_ENDPOINT,
  COGNITO_ENDPOINT_LOGOUT: process.env.REACT_APP_COGNITO_LOGOUT_ENDPOINT,
  GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
};

export const MessageTypes = {
  ERROR: 0,
  SUCCESS: 1,
  INFORMATION: 2,
  WARNING: 3,
  PERMISSIONS: 4,
};

export const StorageKeys = {
  ACCESS_TOKEN: "internal",
  USERNAME: "username",
  EXPIRES_AT: "expires_at",
  USER_ID: "userId",
};

export const Defaults = {
  CREDIT_ACCOUNT_BALANCE: 25, // Match with API 'Constants.DefaultCreditAccountBalance' value
};

export const Colors = [
  { name: "Red", hex: "#FF0000" },
  { name: "Green", hex: "#008000" },
  { name: "Blue", hex: "#0000FF" },
  { name: "Yellow", hex: "#FFFF00" },
  { name: "Orange", hex: "#FFA500" },
  { name: "Purple", hex: "#800080" },
  { name: "Black", hex: "#000000" },
];
