import { RootState } from "..";
import { IUserPermission, UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { GetUserId } from "../../utilities/ApiUtils";


export function selectorGetUserPermissionsByUserId(state: RootState, userId: string) : IUserPermission[] {

    if (!state.userPermissions || !state.userPermissions.byId) return [];

    return sortUserPermissionsByType(
        Object.values(state.userPermissions.byId).filter(x => x.userDetailId === userId)
    );
}

export function selectorGetUserPermissionIsAdmin(state: RootState, userId: string = GetUserId()) : boolean {

    if(!state.userPermissions || !state.userPermissions.byId) return false;

    return Object.values(state.userPermissions.byId).some(x => x.userDetailId === userId && x.type === UserPermissionTypeEnum.Admin)
}

export function selectorGetUserPermissionOfType(state: RootState, userId: string = GetUserId(), userPermissionType: UserPermissionTypeEnum) : boolean {

    if(!state.userPermissions || !state.userPermissions.byId) return false;
    userId = userId ?? "00000000-0000-0000-0000-000000000000"
    return Object.values(state.userPermissions.byId).some(x => x.userDetailId === userId && x.type === userPermissionType);
}

function sortUserPermissionsByType(userPermissions: IUserPermission[]) {
    return userPermissions.sort((a, b) => a.type - b.type);
}