import { ISolverSummaryOutputTypeMapping } from "../../utilities/types/SolverSummaryOutputTypeMapping";


export const ACTIONS_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPING = {
    INVALIDATE: 'INVALIDATE_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPINGS',
    RECEIVE: 'RECEIVE_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPINGS',
    DELETE: 'DELETE_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPING',
    UPDATE: 'UPDATE_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPINGS',
}

interface ActionSolverSummaryOutputTypeMappingsInvalidate {
    type: typeof ACTIONS_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPING.INVALIDATE,
    byId: { [key: string]: ISolverSummaryOutputTypeMapping },
}

interface ActionSolverSummaryOutputTypeMappingsReceive {
    type: typeof ACTIONS_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPING.RECEIVE
    byId: { [key: string]: ISolverSummaryOutputTypeMapping },
}

export interface SolverSummaryOutputTypeMappingState {
    byId: { [key: string]: ISolverSummaryOutputTypeMapping },
}

export enum SolverSummaryOutputTypeMappingSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverSummaryOutputTypeMapping = ActionSolverSummaryOutputTypeMappingsReceive | ActionSolverSummaryOutputTypeMappingsInvalidate;