import { RootState } from "..";
import { ICostBundle } from "../../utilities/types/CostBundle";


export function selectorGetCostBundlesByModelId(state: RootState, modelId: string) : ICostBundle[] {

    if (!state.costBundles || !state.costBundles.byId) return [];

    return sortCostBundlesByOrderNumberAscending(Object.values(state.costBundles.byId).filter(x => x.modelId === modelId));
}


export function selectorGetCostBundleById(state: RootState, costBundleId: string) : ICostBundle | undefined {

    if (!state.costBundles || !state.costBundles.byId) return undefined;

    return state.costBundles.byId[costBundleId];
}


// function sortCostBundlesByOrderNumberDescending(costBundles: ICostBundle[]) {
//     return costBundles.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortCostBundlesByOrderNumberAscending(costBundles: ICostBundle[]) {
    return costBundles.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.name < a.name) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}