import { IOutputJob } from "../../utilities/types/SingleAssetOutputJob";

export const ACTIONS_SINGLE_ASSET_OUTPUT_JOB = {
  INVALIDATE: "INVALIDATE_SOLVER_JOB_TASK_REPORTS",
  RECEIVE: "RECEIVE_SOLVER_JOB_TASK_REPORTS",
  DELETE: "DELETE_SOLVER_JOB_TASK_REPORT",
  UPDATE: "UPDATE_SOLVER_JOB_TASK_REPORTS",
};

interface ActionSingleAssetOutputJobInvalidate {
  type: typeof ACTIONS_SINGLE_ASSET_OUTPUT_JOB.INVALIDATE;
  byId: { [key: string]: IOutputJob };
}

interface ActionSingleAssetOutputJobReceive {
  type: typeof ACTIONS_SINGLE_ASSET_OUTPUT_JOB.RECEIVE;
  byId: { [key: string]: IOutputJob };
}

export interface SingleAssetOutputJobState {
  byId: { [key: string]: IOutputJob };
}

export enum SingleAssetOutputJobSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type ActionTypesSingleAssetOutputJob = ActionSingleAssetOutputJobReceive | ActionSingleAssetOutputJobInvalidate;
