import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormSolverHistoryCreate from "../forms/FormSolverHistoryCreate";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/Build";
import { ISolverHistory } from "../../../utilities/types/SolverHistory";

export interface IModalSolverHistoryCreateProps {
  open: boolean;
  solverId: string;
  versionNumber?: number;
  onCancelCallback(): void;
  onCompleteCallback(solverHistory?: ISolverHistory): void;
}

function ModalSolverHistoryCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  solverId,
  versionNumber,
}: IModalSolverHistoryCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Add history"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormSolverHistoryCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          solverId={solverId}
          versionNumber={versionNumber}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSolverHistoryCreate;
