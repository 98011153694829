import React from "react";
import { AssetInputFieldTypeEnum, IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import AssetInputFieldDisplayDateTime from "./AssetInputFieldDisplayDateTime";
import AssetInputFieldDisplayEnumeration from "./AssetInputFieldDisplayEnumeration";
import AssetInputFieldDisplayNumber from "./AssetInputFieldDisplayNumber";
import AssetInputFieldDisplayString from "./AssetInputFieldDisplayString";

interface IAssetInputFieldDisplayProps {
    assetInputField: IAssetInputField,
    readOnly?: boolean,
    onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void,
    defaultValueOverride?: TAssetInputFieldValue,
}

export default function AssetInputFieldDisplay(props: IAssetInputFieldDisplayProps) {
    switch (props.assetInputField.type) {
        case AssetInputFieldTypeEnum.Number:
            return <AssetInputFieldDisplayNumber {...props} />;
        case AssetInputFieldTypeEnum.CustomEnumeration:
            return <AssetInputFieldDisplayEnumeration {...props} />;
        case AssetInputFieldTypeEnum.String:
            return <AssetInputFieldDisplayString {...props} />;
        case AssetInputFieldTypeEnum.DateTime:
            return <AssetInputFieldDisplayDateTime {...props} />;
    }
    return null
}
