import { RootState } from "..";
import { ITaskApplicability } from "../../utilities/types/TaskApplicability";


export function selectorGetTaskApplicabilitiesByModelId(state: RootState, modelId: string) : ITaskApplicability[] {

    if (!state.taskApplicabilities || !state.taskApplicabilities.byId) return [];

    return sortTaskApplicabilitiesByOrderNumberAscending(Object.values(state.taskApplicabilities.byId).filter(x => x.modelId === modelId));
}


export function selectorGetTaskApplicabilitiesByTaskId(state: RootState, taskId: string) : ITaskApplicability[] {

    if (!state.taskApplicabilities || !state.taskApplicabilities.byId) return [];

    return sortTaskApplicabilitiesByOrderNumberAscending(Object.values(state.taskApplicabilities.byId).filter(x => x.taskId === taskId));
}


export function selectorGetTaskApplicabilityById(state: RootState, taskApplicabilityId: string) : ITaskApplicability | undefined {

    if (!state.taskApplicabilities || !state.taskApplicabilities.byId) return undefined;

    return state.taskApplicabilities.byId[taskApplicabilityId];
}


// function sortTaskApplicabilitiesByOrderNumberDescending(taskApplicabilities: ITaskApplicability[]) {
//     return taskApplicabilities.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortTaskApplicabilitiesByOrderNumberAscending(taskApplicabilities: ITaskApplicability[]) {
    return taskApplicabilities.sort((a, b) => a.orderNumber - b.orderNumber);
}