import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import FormSolverUpdate from "../forms/FormSolverUpdate";
import { ISolver } from "../../../utilities/types/Solver";
import MapIcon from "@mui/icons-material/MapRounded";
import { RootState } from "../../../redux";
import { selectorGetSolverById } from "../../../redux/solver/selectors";
import { fetchSearchSolvers } from "../../../redux/solver/actions";
import FileRendererSingle from "../../file/renderers/FileRendererSingle";
import { FileTypeEnum } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

export interface IModalSolverUpdateProps {
  open: boolean;
  solver: ISolver;
  onCancelCallback(): void;
  onCompleteCallback(solver?: ISolver): void;
  canEdit: boolean;
}

function ModalSolverUpdate({ onCancelCallback, open, onCompleteCallback, solver, canEdit }: IModalSolverUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a solver"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <RenderImage solverId={solver.solverId} canEdit={canEdit} />
        <FormSolverUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          solver={solver}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

interface IRenderImageProps {
  solverId: string;
  canEdit: boolean;
}

function RenderImage({ solverId, canEdit }: IRenderImageProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const identifier = useSelector((store: RootState) => selectorGetSolverById(store, solverId));

  if (!identifier) return null;

  const onUploadComplete = async () => {
    setLoading(true);
    await dispatch(
      fetchSearchSolvers({
        solverId,
        pageNumber: 1,
        pageSize: 1,
      })
    );
    setLoading(false);
  };

  return (
    <div style={{ borderRadius: 4, position: "relative" }}>
      <FileRendererSingle
        fileId={identifier.mainImageId || ""}
        canUpload={canEdit}
        fileType={FileTypeEnum.Image}
        entityId={String(identifier.solverId)}
        entityType={EntityTypeEnum.Solver}
        height={150}
        onUploadComplete={onUploadComplete}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </div>
  );
}

export default ModalSolverUpdate;
