import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useSelector } from "react-redux";
import MapIcon from "@mui/icons-material/MapRounded";
import { selectorGetSingleAssetOutputById } from "../../../redux/singleAssetOutput/selectors";
import { RootState } from "../../../redux";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import { Typography, DialogActions, Button, styled } from "@mui/material";

const PreBlock = styled("pre")(({ theme }) => ({
  width: "100%",
  overflow: "scroll",
  border: "none",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(0,0,0,0.8)",
  color: "#FFF",
  fontFamily:
    "Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif",
  padding: theme.spacing(1),
  maxHeight: 150,
  marginTop: 0,
}));

export interface IModalOutputTypeDefinitionsProps {
  open: boolean;
  onCancelCallback(): void;
  outputTypeId: string;
}

function ModalSingleAssetOutputDefinitions({ onCancelCallback, open, outputTypeId }: IModalOutputTypeDefinitionsProps) {
  const singleAssetOutput = useSelector((store: RootState) => selectorGetSingleAssetOutputById(store, outputTypeId));

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Output Type Class Definition"}
      subtitle={"Copy and paste into the report project."}
      headerIcon={<MapIcon />}
      style={{ maxWidth: "1200px" }}
    >
      <DialogContent>
        {!singleAssetOutput ? (
          <WidgetNoResultsPlaceholder text="No output type" />
        ) : (
          <>
            <Typography variant="overline">Output Type Id</Typography>
            <PreBlock>public const string Id = "{singleAssetOutput.outputTypeId}";</PreBlock>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth={true} onClick={onCancelCallback}>
          Close
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

export default ModalSingleAssetOutputDefinitions;
