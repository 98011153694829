import { ITermsAgreement } from "../../utilities/types/TermsAgreement";


export const ACTIONS_TERMS_AGREEMENT = {
    INVALIDATE: 'INVALIDATE_TERMS_AGREEMENTS',
    RECEIVE: 'RECEIVE_TERMS_AGREEMENTS',
    DELETE: 'DELETE_TERMS_AGREEMENT',
    UPDATE: 'UPDATE_TERMS_AGREEMENTS',
}

interface ActionTermsAgreementsInvalidate {
    type: typeof ACTIONS_TERMS_AGREEMENT.INVALIDATE,
    byId: { [key: string]: ITermsAgreement },
}

interface ActionTermsAgreementsReceive {
    type: typeof ACTIONS_TERMS_AGREEMENT.RECEIVE
    byId: { [key: string]: ITermsAgreement },
}

export interface TermsAgreementState {
    byId: { [key: string]: ITermsAgreement },
}

export enum TermsAgreementSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesTermsAgreement = ActionTermsAgreementsReceive | ActionTermsAgreementsInvalidate;