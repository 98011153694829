import { ISummaryOutputType } from "../../utilities/types/SummaryOutputType";


export const ACTIONS_SUMMARY_OUTPUT_TYPE = {
    INVALIDATE: 'INVALIDATE_SUMMARY_OUTPUT_TYPES',
    RECEIVE: 'RECEIVE_SUMMARY_OUTPUT_TYPES',
    DELETE: 'DELETE_SUMMARY_OUTPUT_TYPE',
    UPDATE: 'UPDATE_SUMMARY_OUTPUT_TYPES',
}

interface ActionSummaryOutputTypesInvalidate {
    type: typeof ACTIONS_SUMMARY_OUTPUT_TYPE.INVALIDATE,
    byId: { [key: string]: ISummaryOutputType },
}

interface ActionSummaryOutputTypesReceive {
    type: typeof ACTIONS_SUMMARY_OUTPUT_TYPE.RECEIVE
    byId: { [key: string]: ISummaryOutputType },
}

export interface SummaryOutputTypeState {
    byId: { [key: string]: ISummaryOutputType },
}

export enum SummaryOutputTypeSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSummaryOutputType = ActionSummaryOutputTypesReceive | ActionSummaryOutputTypesInvalidate;