import * as Yup from "yup";
import { AssetInputFieldTypeEnum as FieldType } from "../types/AssetInputField";
import { MAX_DECIMAL_PLACES, MAX_VALUE } from "./numberFieldUtils";

type RequireableSchema = Yup.StringSchema | Yup.NumberSchema | Yup.DateSchema;

/** Creates a Yup string field that is required when the expected type equals the form's current 'type' field value. */
export function requireForType<T extends RequireableSchema>(typeExpected: FieldType, field: T): T {
  return field.when("type", (typeActual: FieldType) => {
    return typeExpected === typeActual ? field.required() : field.notRequired();
  }) as any;
}

/** Required a field when the 'type' field is {@link FieldType.Number}. */
export function forNumber<T extends RequireableSchema>(field: T) {
  return requireForType(FieldType.Number, field);
}

/** Required a field when the 'type' field is {@link FieldType.String}. */
export function forString<T extends RequireableSchema>(field: T) {
  return requireForType(FieldType.String, field);
}

/** Required a field when the 'type' field is {@link FieldType.DateTime}. */
export function forDateTime<T extends RequireableSchema>(field: T) {
  return requireForType(FieldType.DateTime, field);
}

const commonYupInputFieldsValidation = {
  defaultString: forString(Yup.string().label("Default Text").max(500, "Please input 500 characters or less")),
  defaultValue: forNumber(
    Yup.number()
      .label("Default Value")
      .when(["minValue", "maxValue"], (min: number, max: number, schema) => schema.min(min).max(max))
  ),
  minValue: forNumber(
    Yup.number()
      .label("Min Value")
      //.min(0, 'Zero or more')
      .max(MAX_VALUE, `Less than ${MAX_VALUE}`)
  ),
  maxValue: forNumber(
    Yup.number()
      .label("Max Value")
      .max(MAX_VALUE, `Less than ${MAX_VALUE}`)
      .when("minValue", (min: number, schema) => schema.min(min))
  ),
  decimalPlaces: forNumber(
    Yup.number()
      .label("Decimal Places")
      .min(0, "Zero or more")
      .max(MAX_DECIMAL_PLACES, `Less than ${MAX_DECIMAL_PLACES}`)
  ),
};

export const yumInputFieldsWithDefaultDateTime = {
  ...commonYupInputFieldsValidation,
  defaultDateTime: forDateTime(Yup.date().label("Default Date Time")),
};

export const yumInputFields = {
  ...commonYupInputFieldsValidation,
  defaultDate: forDateTime(Yup.date().label("Default Date")),
  defaultTime: forDateTime(Yup.string().label("Default Time").max(5, "Please input a valid time like '09:00'")),
};
