import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormFileUpload from "../forms/FormFileUpload";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { FileTypeEnum, IFile } from "../../../utilities/types/File";

export interface ModalFileUploadProps {
  handleCancel(): void;
  open: boolean;
  entityId: string;
  entityType: EntityTypeEnum;
  description?: string;
  fileType?: FileTypeEnum;
  onUploadCompleteCallback(file?: IFile): void;
  imageHeight?: number;
  imageWidth?: number;
  canSpecifyPath?: boolean;
  defaultFileBucketPath?: string;
}

function ModalFileUpload({
  handleCancel,
  open,
  entityId,
  entityType,
  description,
  fileType,
  imageHeight,
  imageWidth,
  canSpecifyPath = false,
  defaultFileBucketPath,
  onUploadCompleteCallback,
}: ModalFileUploadProps) {
  return (
    <WidgetModalBase
      handleCancel={handleCancel}
      open={open}
      title={"Upload a New File"}
      subtitle={"Drag and drop a file or click to browse."}
      style={{ maxWidth: "875px" }}
    >
      <DialogContent>
        <FormFileUpload
          entityId={entityId}
          entityType={entityType}
          description={description}
          fileType={fileType}
          onUploadCompleteCallback={onUploadCompleteCallback}
          onCancelCallback={handleCancel}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
          canSpecifyPath={canSpecifyPath}
          defaultFileBucketPath={defaultFileBucketPath}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFileUpload;
