import React, { memo, useCallback, useEffect, useState } from "react";
import { TextField, Grid, Divider, Button, Autocomplete } from "@mui/material";
import { Formik } from "formik";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { outputValidationSchema, NODE_TYPES } from "../constants";
import { IOutputDetails, IOutputFormValues } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import {
  selectorGetSummaryOutputTypeById,
  selectorGetSummaryOutputTypes,
} from "../../../redux/summaryOutputType/selectors";
import { TSummaryOutputTypeInputFieldValue } from "../../../utilities/types/SummaryOutputType";
import SummaryOutputTypeInputFieldTabPanel from "../../summaryOutputTypeInputField/SummaryOutputTypeInputFieldTabPanel";
import { ISummaryOutputTypeInputField } from "../../../utilities/types/SummaryOutputTypeInputField";
import useJobCreateState from "../../jobs/WizardState";
import {
  applyFieldChangeToValueMap,
  getReportInputsFromValueMap,
  setupFieldValueMap,
} from "../../summaryOutputTypeInputField/valueMapHelper";

const ShowOutputParameters = memo(
  ({
    summaryOutputTypeId,
    summaryOutputTypeInputFieldValueMap,
    isEditMode,
  }: {
    summaryOutputTypeId: string;
    summaryOutputTypeInputFieldValueMap?: IOutputDetails["summaryOutputTypeInputFieldValueMap"];
    isEditMode: boolean;
  }) => {
    const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

    const summaryOutputInputValuesObjectMap = useJobCreateState((s) => s.summaryOutputInputValuesObjectMap);

    const solverSummaryOutputType = useSelector((store: RootState) =>
      selectorGetSummaryOutputTypeById(store, summaryOutputTypeId)
    );

    const setupSummaryOutputFieldValues = useCallback((fields: ISummaryOutputTypeInputField[]) => {
      const valuesMap = useJobCreateState.getState().summaryOutputInputValuesObjectMap;
      const newValuesMap = setupFieldValueMap(valuesMap, fields);

      useJobCreateState.setState({ summaryOutputInputValuesObjectMap: newValuesMap });
      setInitialLoaded(true);
    }, []);

    const applySummaryOutputFieldValueChange = useCallback(
      (summaryOutputFieldTypeId: string, fieldId: string, value: TSummaryOutputTypeInputFieldValue) => {
        const valuesMap = useJobCreateState.getState().summaryOutputInputValuesObjectMap;
        const newValuesMap = applyFieldChangeToValueMap(valuesMap, summaryOutputFieldTypeId, fieldId, value);

        useJobCreateState.setState({ summaryOutputInputValuesObjectMap: newValuesMap });
      },
      []
    );

    // Prepare inital values for solver input fields
    useEffect(() => {
      if (
        initialLoaded &&
        isEditMode &&
        solverSummaryOutputType &&
        summaryOutputTypeInputFieldValueMap &&
        Object.keys(summaryOutputTypeInputFieldValueMap).length > 0
      ) {
        const valuesMap = useJobCreateState.getState().summaryOutputInputValuesObjectMap;
        let initialValues =
          valuesMap && valuesMap.hasOwnProperty(solverSummaryOutputType.summaryOutputTypeId)
            ? valuesMap[solverSummaryOutputType.summaryOutputTypeId]
            : {};

        useJobCreateState.setState({
          summaryOutputInputValuesObjectMap: {
            [solverSummaryOutputType.summaryOutputTypeId]: { ...initialValues, ...summaryOutputTypeInputFieldValueMap },
          },
        });
      }
    }, [initialLoaded, isEditMode, summaryOutputTypeInputFieldValueMap, solverSummaryOutputType]);

    if (!solverSummaryOutputType) return null;

    return (
      <SummaryOutputTypeInputFieldTabPanel
        solverSummaryOutputType={solverSummaryOutputType}
        defaultValueOverrides={summaryOutputInputValuesObjectMap[solverSummaryOutputType.summaryOutputTypeId] || {}}
        onValueChangeCallback={applySummaryOutputFieldValueChange}
        onInputFieldsChanged={setupSummaryOutputFieldValues}
        onLoadChange={() => {}}
        key={solverSummaryOutputType.summaryOutputTypeId}
        showDivider={false}
      />
    );
  }
);

interface FormAddUpdateOutputProps {
  outputDetails: IOutputDetails;
  onCancelCallback(): void;
  onCompleteCallback(newOutputDetails: IOutputFormValues, nodeType: NODE_TYPES, nodeId?: string): void;
  onDelete(nodeId: string, nodeType: NODE_TYPES): void;
}

const FormAddUpdateOutput: React.FC<FormAddUpdateOutputProps> = ({
  outputDetails: {
    isEditMode,
    nodeId = "",
    outputName = "",
    outputId = "",
    summaryOutputTypeInputFieldValueMap,
  },
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const outputList = useSelector((store: RootState) => selectorGetSummaryOutputTypes(store));

  async function deleteHandler() {
    const selectedOutput = outputList.find((output) => {
      return output.summaryOutputTypeId === outputId;
    });
    const state = useJobCreateState.getState();

    if (
      selectedOutput &&
      state?.summaryOutputInputValuesObjectMap &&
      state?.summaryOutputInputValuesObjectMap[selectedOutput.summaryOutputTypeId]
    ) {
      let newSummaryOutputInputValuesObjectMap = state?.summaryOutputInputValuesObjectMap;
      delete newSummaryOutputInputValuesObjectMap[selectedOutput.summaryOutputTypeId];
      useJobCreateState.setState({ summaryOutputInputValuesObjectMap: newSummaryOutputInputValuesObjectMap });
    }
    setShowDeleteConfirmation(false);

    onDelete(nodeId, NODE_TYPES.OUTPUT);
  }

  const handleSubmit = async (values: IOutputFormValues) => {
    const selectedOutput = outputList.find((output) => {
      return output.summaryOutputTypeId === values.outputId;
    });

    const { summaryOutputInputValuesObjectMap } = useJobCreateState.getState();
    const summaryOutputTypeInputFieldValues = selectedOutput
      ? getReportInputsFromValueMap(summaryOutputInputValuesObjectMap, [selectedOutput as any])
      : null;

    const reqObj = {
      outputId: values.outputId,
      outputName: selectedOutput?.name ?? "",
      summaryOutputTypeInputFieldValueMap: summaryOutputTypeInputFieldValues
        ? summaryOutputTypeInputFieldValues[values.outputId]?.inputFieldValueMap
        : null,
    };

    if (isEditMode) {
      onCompleteCallback(reqObj, NODE_TYPES.OUTPUT, nodeId);
      return;
    }
    onCompleteCallback(reqObj, NODE_TYPES.OUTPUT);
  };

  return (
    <Formik
      initialValues={{ outputName, outputId }}
      validationSchema={outputValidationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                {...getFormikFieldProps(props, "outputObj", "Select Output")}
                style={{ display: "block" }}
                defaultValue={outputList.find((output) => {
                  return output.summaryOutputTypeId === props.values.outputId;
                })}
                options={outputList}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => {
                  props.setFieldValue("outputObj", value);
                  props.setFieldValue("outputId", value ? value.summaryOutputTypeId : "");
                }}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(props.errors.outputId)}
                    helperText={props.errors.outputId}
                    variant="standard"
                    {...params}
                    label="Select Output"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ShowOutputParameters
                summaryOutputTypeId={props.values.outputId}
                summaryOutputTypeInputFieldValueMap={summaryOutputTypeInputFieldValueMap}
                isEditMode={isEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider light={true} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {isEditMode && (
                <Button
                  color="secondary"
                  disabled={props.isSubmitting}
                  style={{ marginRight: 16 }}
                  variant="contained"
                  onClick={toggleDeleteConfirmation}
                >
                  Delete
                </Button>
              )}
              <Button
                disabled={props.isSubmitting}
                variant="text"
                style={{ marginRight: 16 }}
                onClick={onCancelCallback}
              >
                Close
              </Button>
              <Button disabled={props.isSubmitting} variant="outlined" color="primary" onClick={props.submitForm}>
                {isEditMode ? "Update" : "Add"}
              </Button>
            </Grid>
            <LoaderAbsoluteCentred loading={props.isSubmitting} />
            <WidgetModalConfirmationDialog
              open={showDeleteConfirmation}
              title="Delete output"
              subtitle="Confirm output delete"
              description="Are you sure that you'd like to remove this output?"
              onCancelCallback={toggleDeleteConfirmation}
              onConfirmCallback={deleteHandler}
              confirmButtonText="Delete"
            />
          </Grid>
        );
      }}
    </Formik>
  );
};

export default FormAddUpdateOutput;
