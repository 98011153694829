import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import FormSummaryOutputTypeUpdate from "../forms/FormSummaryOutputTypeUpdate";
import { ISummaryOutputType } from "../../../utilities/types/SummaryOutputType";
import MapIcon from "@mui/icons-material/MapRounded";
import { RootState } from "../../../redux";
import FileRendererSingle from "../../file/renderers/FileRendererSingle";
import { FileTypeEnum } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetSummaryOutputTypeById } from "../../../redux/summaryOutputType/selectors";
import { fetchSearchSummaryOutputTypes } from "../../../redux/summaryOutputType/actions";

export interface IModalSummaryOutputTypeUpdateProps {
  open: boolean;
  summaryOutputType: ISummaryOutputType;
  onCancelCallback(): void;
  onCompleteCallback(summaryOutputType?: ISummaryOutputType): void;
  canEdit: boolean;
}

function ModalSummaryOutputTypeUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  summaryOutputType,
  canEdit,
}: IModalSummaryOutputTypeUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a summary output type"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <RenderImage summaryOutputTypeId={summaryOutputType.summaryOutputTypeId} canEdit={canEdit} />
        <FormSummaryOutputTypeUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          summaryOutputType={summaryOutputType}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

interface IRenderImageProps {
  summaryOutputTypeId: string;
  canEdit: boolean;
}

function RenderImage({ summaryOutputTypeId, canEdit }: IRenderImageProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const identifier = useSelector((store: RootState) => selectorGetSummaryOutputTypeById(store, summaryOutputTypeId));

  if (!identifier) return null;

  const onUploadComplete = async () => {
    setLoading(true);
    await dispatch(
      fetchSearchSummaryOutputTypes({
        summaryOutputTypeId,
        pageNumber: 1,
        pageSize: 1,
      })
    );
    setLoading(false);
  };

  return (
    <div style={{ borderRadius: 4, position: "relative" }}>
      <FileRendererSingle
        fileId={identifier.mainImageId || ""}
        canUpload={canEdit}
        fileType={FileTypeEnum.Image}
        entityId={String(identifier.summaryOutputTypeId)}
        entityType={EntityTypeEnum.SummaryOutputType}
        height={150}
        onUploadComplete={onUploadComplete}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </div>
  );
}

export default ModalSummaryOutputTypeUpdate;
