import { RootState } from "..";
import { ISolver } from "../../utilities/types/Solver";

export function selectorGetSolverById(state: RootState, solverId: string) : ISolver | undefined {

    if (!state.solvers || !state.solvers.byId) return undefined;

    return state.solvers.byId[solverId];
}


export function selectorGetSolvers(state: RootState) : ISolver[] {

    if (!state.solvers || !state.solvers.byId) return [];

    return sortSolversByNameDescending(Object.values(state.solvers.byId));
}

export function sortSolversByNameAsc(solvers: ISolver[]) : ISolver[] {
    return solvers.sort((a, b) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);
}

function sortSolversByNameDescending(solvers: ISolver[]) {
    return solvers.sort((a, b) => {
        return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
    });
}