import { Handle, Position } from "reactflow";

import { INodeData } from "../types";

interface CustomOutputNodeProps {
  data: INodeData;
}

export function CustomOutputNode({ data }: CustomOutputNodeProps) {
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div className="node-wrapper">
        <label htmlFor="text">{data.label}</label>
      </div>
      <Handle type="source" position={Position.Right} />
    </>
  );
}
