import { Card as MuiCard, CardActionArea, Grid, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetAssetInputFieldById } from "../../redux/assetInputField/selectors";
import { fetchDeleteDecisionAssetInputFieldMapping } from "../../redux/decisionAssetInputFieldMapping/actions";
import { selectorGetDecisionAssetInputFieldMappingById } from "../../redux/decisionAssetInputFieldMapping/selectors";
import { ShowMessage, UpdateMessage } from "../../utilities/Helpers";
import { IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { IDecisionAssetInputFieldMapping } from "../../utilities/types/DecisionAssetInputFieldMapping";
import { MessageTypeEnum } from "../../utilities/types/Message";
import AssetInputFieldDisplay from "../assetInputField/AssetInputFieldDisplay";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import ModalDecisionAssetInputFieldMappingUpdate from "./modals/ModalDecisionAssetInputFieldMappingUpdate";
import { selectorGetAssetInputFieldListValuesByAssetInputFieldId } from "../../redux/assetInputFieldListValue/selectors";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";

const Card = styled(MuiCard)(({ theme }) => ({
  width: "100%",
  "& .inputWrapper": {
    padding: theme.spacing(2),
  },
}));

type IDecisionAssetInputFieldRendererProps = {
  canEdit: boolean;
  decisionAssetInputFieldMappingId: string;
  modelId: string;
};

const DecisionAssetInputFieldRenderer = (props: IDecisionAssetInputFieldRendererProps) => {
  const { decisionAssetInputFieldMappingId, canEdit } = props;
  const decisionAssetInputFieldMapping = useSelector((store: RootState) =>
    selectorGetDecisionAssetInputFieldMappingById(store, decisionAssetInputFieldMappingId)
  );
  const assetInputField = useSelector((store: RootState) =>
    selectorGetAssetInputFieldById(
      store,
      decisionAssetInputFieldMapping ? decisionAssetInputFieldMapping.assetInputFieldId : ""
    )
  );
  const assetInputFieldValues = useSelector((store: RootState) =>
    selectorGetAssetInputFieldListValuesByAssetInputFieldId(
      store,
      assetInputField ? assetInputField.assetInputFieldId : ""
    )
  );

  const [mappingToUpdate, setMappingToUpdate] = useState<IDecisionAssetInputFieldMapping>();

  if (!decisionAssetInputFieldMapping) {
    console.error(`Decision field mapping not found: ${decisionAssetInputFieldMappingId}`);
    return null;
  }

  if (!assetInputField) {
    console.error(`Asset input field not found: ${decisionAssetInputFieldMapping.assetInputFieldId}`);
    return null;
  }

  function onMappingClick() {
    setMappingToUpdate(decisionAssetInputFieldMapping);
  }

  function closeUpdateModal() {
    setMappingToUpdate(undefined);
  }

  return (
    <>
      {canEdit ? (
        <DecisionAssetInputFieldRendererDisplay
          decisionAssetInputFieldMapping={decisionAssetInputFieldMapping}
          assetInputField={assetInputField}
          onMappingClick={onMappingClick}
          {...props}
          onAssetInputFieldValueChangeCallback={() => {}}
        />
      ) : (
        <DecisionAssetInputFieldRendererDisplay
          decisionAssetInputFieldMapping={decisionAssetInputFieldMapping}
          assetInputField={assetInputField}
          onMappingClick={() => {}}
          {...props}
          onAssetInputFieldValueChangeCallback={() => {}}
        />
      )}
      {assetInputFieldValues.map((assetInputListValue) => {
        return (
          <Grid container>
            <Grid item xs={4}>
              <Typography>{assetInputListValue.displayText}</Typography>
            </Grid>
            <Grid item xs={8}>
              <IdentifierMappingsRenderer
                canEdit={true}
                modelId={props.modelId}
                secondaryTargetId={assetInputListValue.assetInputFieldListValueId}
                targetId={decisionAssetInputFieldMapping.decisionId}
                loading={false}
                type={IdentifierMappingTypeEnum.Decision}
                showValues={true}
              ></IdentifierMappingsRenderer>
            </Grid>
          </Grid>
        );
      })}
      {mappingToUpdate && (
        <ModalDecisionAssetInputFieldMappingUpdate
          open={true}
          decisionAssetInputFieldMapping={mappingToUpdate}
          onCancelCallback={closeUpdateModal}
          onCompleteCallback={closeUpdateModal}
          canEdit={canEdit}
        />
      )}
    </>
  );
};

interface IDecisionAssetInputFieldRendererDisplayProps extends IDecisionAssetInputFieldRendererProps {
  decisionAssetInputFieldMapping: IDecisionAssetInputFieldMapping;
  assetInputField: IAssetInputField;
  onMappingClick(): void;
  onAssetInputFieldValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
}

const DecisionAssetInputFieldRendererDisplay = (props: IDecisionAssetInputFieldRendererDisplayProps) => {
  const { decisionAssetInputFieldMapping, assetInputField, onMappingClick } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteModal(!showDeleteModal);
  const dispatch = useDispatch();
  const [, setDeleting] = useState(false);

  async function deleteHandler() {
    // Inform user
    setDeleting(true);
    var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
    setShowDeleteModal(false);

    // Perform delete
    var resp = await dispatch(
      fetchDeleteDecisionAssetInputFieldMapping({
        decisionAssetInputFieldMappingId: decisionAssetInputFieldMapping.decisionAssetInputFieldMappingId,
      })
    );

    // Cleanup
    setDeleting(false);
    if (resp != null) {
      UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
      toggleDeleteConfirmation();
    }
  }

  return (
    <>
      <Card onClick={onMappingClick}>
        <CardActionArea style={{ padding: 0 }}>
          <div className="inputWrapper">
            <AssetInputFieldDisplay
              assetInputField={assetInputField}
              onValueChangeCallback={props.onAssetInputFieldValueChangeCallback}
              readOnly={true}
            />
          </div>
        </CardActionArea>
      </Card>

      <WidgetModalConfirmationDialog
        open={showDeleteModal}
        title="Delete confirmation"
        subtitle="Confirm delete mapping"
        description="Are you sure that you'd like to remove this mapping?"
        onCancelCallback={toggleDeleteConfirmation}
        onConfirmCallback={deleteHandler}
        confirmButtonText="Delete"
      />
    </>
  );
};

export default DecisionAssetInputFieldRenderer;
