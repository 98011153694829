import { DecimalPlaces } from "../inputFields/numberFieldUtils";

export interface ISolverInputField {
    solverInputFieldId: string,
    solverId: string,
    label: string,
    description: string,
    defaultValue: number,
    defaultString: string,
    defaultDateTime: Date,
    defaultSolverInputFieldListValueId?: string,
    minValue: number,
    maxValue: number,
    decimalPlaces: DecimalPlaces,
    tableName: string,
    orderNumber: number,
    type: SolverInputFieldTypeEnum,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}


export enum SolverInputFieldTypeEnum {
    Number = 10,
    CustomEnumeration = 20,
    String = 30,
    DateTime = 40,
}