import { RootState } from "..";
import { IComponent } from "../../utilities/types/Component";
import { selectorGetFunctionById } from "../function/selectors";
import { selectorGetFunctionalFailureById } from "../functionalFailure/selectors";


export function selectorGetComponentsByModelId(state: RootState, modelId: string) : IComponent[] {

    if (!state.components || !state.components.byId) return [];

    return sortComponentsByOrderNumberAscending(Object.values(state.components.byId).filter(x => x.modelId === modelId));
}


export function selectorGetComponentById(state: RootState, componentId: string) : IComponent | undefined {

    if (!state.components || !state.components.byId) return undefined;

    return state.components.byId[componentId];
}

export function selectorGetComponentByFunctionalFailureId(state: RootState, functionalFailureId: string) : IComponent | undefined {

    if (!state.components || !state.components.byId) return undefined;
    if (!state.functions || !state.functions.byId) return undefined;
    if (!state.functionalFailures || !state.functionalFailures.byId) return undefined;
    const FunctionFailure = selectorGetFunctionalFailureById(state, functionalFailureId);
    const Function = selectorGetFunctionById(state, FunctionFailure?.functionId ?? "");
    const Component = selectorGetComponentById(state, Function?.componentId ?? "");
    return state.components.byId[Component?.componentId ?? ""];
}


function sortComponentsByOrderNumberAscending(components: IComponent[]) {
    return components.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.name < a.name) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}