import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import HeaderIcon from "@mui/icons-material/ListAlt";
import FormSolverInputFieldListValueUpdate from "../forms/FormSolverInputFieldListValueUpdate";
import { ISolverInputFieldListValue } from "../../../utilities/types/SolverInputFieldListValue";

export interface IModalSolverInputFieldListValueUpdateProps {
  open: boolean;
  solverInputFieldListValue: ISolverInputFieldListValue;
  onCancelCallback(): void;
  onCompleteCallback(solverInputFieldListValue?: ISolverInputFieldListValue): void;
}

function ModalSolverInputFieldListValueUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  solverInputFieldListValue,
}: IModalSolverInputFieldListValueUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update list value"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent>
        <FormSolverInputFieldListValueUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          solverInputFieldListValue={solverInputFieldListValue}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSolverInputFieldListValueUpdate;
