import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchTaskApplicabilitiesProps, fetchSearchTaskApplicabilities } from "../../redux/taskApplicability/actions";
import { ITaskApplicability } from "../../utilities/types/TaskApplicability";


interface IUseFetchTaskApplicabilitiesPageHookProps extends IFetchSearchTaskApplicabilitiesProps {
    minPageNumberToFetch: number;
    taskId: string,
}


export const useFetchTaskApplicabilitiesPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId, taskId }: IUseFetchTaskApplicabilitiesPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ITaskApplicability[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ITaskApplicability[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var taskApplicabilities = await dispatch(fetchSearchTaskApplicabilities({
                    pageSize,
                    pageNumber,
                    modelId,
                    taskId,
                })) as unknown as ITaskApplicability[];

                if (taskApplicabilities && taskApplicabilities.length) {
                    setMorePages(taskApplicabilities.length >= pageSize)
                    setLastResultSet(taskApplicabilities);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId, taskId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.taskApplicabilityId === x.taskApplicabilityId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}