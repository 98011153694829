export interface ITask {
    taskId: string,
    modelId: string,
    name: string,
    description: string,
    primaryLabour: PrimaryLabourEnum,
    rampTime: number,
    duration: number,
    orderNumber: number,
    type: TaskTypeEnum,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum TaskSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export enum TaskTypeEnum {
    Impact = 10,
    Applicability = 20,
}

export enum PrimaryLabourEnum {
    Electrical = 10,
    Mechanical = 20,
    Operations = 30,
    ConditionMonitoring = 40,
    Fitter = 50,
    InstrumentTech = 60,
}