import React from "react";
import Collapse from "@mui/material/Collapse";

import { useDispatch } from "react-redux";
import FormDiscussionUpdate from "../forms/FormDiscussionUpdate";
import { IDiscussion } from "../../../utilities/types/Discussion";

export interface IModalDiscussionUpdateProps {
  open: boolean;
  discussion: IDiscussion;
  onCancelCallback(): void;
  onCompleteCallback(discussion?: IDiscussion): void;
  canEdit: boolean;
}

function ModalDiscussionUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  discussion,
  canEdit,
}: IModalDiscussionUpdateProps) {
  const dispatch = useDispatch();

  return (
    <Collapse in={open}>
      <FormDiscussionUpdate
        dispatch={dispatch}
        onCancelCallback={onCancelCallback}
        onCompleteCallback={onCompleteCallback}
        discussion={discussion}
        canEdit={canEdit}
      />
    </Collapse>
  );
}

export default ModalDiscussionUpdate;
