import { AppThunk } from '..';
import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { Configuration } from '../../utilities/Constants';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { EntityTypeEnum } from '../../utilities/types/Entity';
import { EntityPermissionTypeEnum, IEntityPermission } from '../../utilities/types/EntityPermission';
import { selectorGetEntityPermissionsByUserId, selectorGetEntityPermissionsByUserIdAndEntityId } from './selectors';
import { ACTIONS_ENTITY_PERMISSION } from './types';


export const receiveEntityPermissions = (entityPermissions: IEntityPermission[]) => {

    var byIdObjectToDispatch: { [key: string]: IEntityPermission } = {};

    for (var i = 0; i < entityPermissions.length; i++) {
        byIdObjectToDispatch[entityPermissions[i].entityPermissionId] = entityPermissions[i];
    }

    return {
        type: ACTIONS_ENTITY_PERMISSION.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteEntityPermission = (entityPermission: IEntityPermission) => ({
    type: ACTIONS_ENTITY_PERMISSION.DELETE,
    byId: { [entityPermission.entityPermissionId]: entityPermission }
});



export interface IFetchSearchEntityPermissions {
    entityPermissionId?: string,
    pageSize?: number,
    pageNumber?: number,
    userDetailId?: string,
    type?: EntityPermissionTypeEnum,
    entityId?: string,
    entityType?: EntityTypeEnum,
}


export const fetchSearchEntityPermissions = (searchParams: IFetchSearchEntityPermissions): AppThunk<Promise<IEntityPermission[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/entityPermissions`, searchParams), {
            method: 'GET',
            headers: headers
        });

        var json = await CheckStatus(apiResponse);
        if (json && json.success && json.data) {

            if (json.data.entityPermissions && json.data.entityPermissions.length > 0) {
                dispatch(receiveEntityPermissions(json.data.entityPermissions));
            }
        }

        return json;
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving user permissions.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export const fetchEntityPermissionsByUserIdIfNeeded = ({ userDetailId }: { userDetailId: string }): AppThunk<Promise<void>> => async (dispatch, getState) => {
    if (!selectorGetEntityPermissionsByUserId(getState(), userDetailId)?.length)
        await dispatch(fetchSearchEntityPermissions({ userDetailId, pageNumber: 1, pageSize: 100, }))
}


export const fetchContributorPermissionsForEntity = ({ entityId, entityType }: { entityId: string, entityType: EntityTypeEnum }): AppThunk<Promise<void>> => async (dispatch, getState) => {
    await dispatch(fetchSearchEntityPermissions({ pageNumber: 1, pageSize: 100, entityId, entityType, type: EntityPermissionTypeEnum.Contributor }))
}


export interface IFetchCreateEntityPermissionsProps {
    entityPermissions: number[],
    targetUserId: string,
    entityId: string,
    entityType: EntityTypeEnum,
}


export const fetchCreateEntityPermissions = (params: IFetchCreateEntityPermissionsProps): AppThunk<Promise<IEntityPermission>> => async (dispatch, getState) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/entityPermissions`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(params),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.entityPermissions) {

            // Remove any existing entity permissions for this user and entity
            selectorGetEntityPermissionsByUserIdAndEntityId(getState(), params?.targetUserId, params?.entityId)
                .map(x => dispatch(requestDeleteEntityPermission(x)));

            // Store the updated list
            dispatch(receiveEntityPermissions(parsedResp.data.entityPermissions));
            return parsedResp.data.entityPermissions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating entity permission.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating entity permission.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchDeleteEntityPermissionsProps {
    entityPermissionId: string,
}

export const fetchDeleteEntityPermission = (params: IFetchDeleteEntityPermissionsProps): AppThunk<Promise<IEntityPermission>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/entityPermissions`, {
            method: 'DELETE',
            headers: headers,
            body: PrepareBody(params),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.entityPermissions && parsedResp.data.entityPermissions.length) {
            const permission = parsedResp.data.entityPermissions[0];
            dispatch(requestDeleteEntityPermission(permission));
            return permission;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting entity permission.");
                return null;
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting entity permission.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchEntityIsValid { entityId: string, entityType: EntityTypeEnum }

export async function fetchEntityisValid(params: IFetchEntityIsValid): Promise<boolean> {
    var headers = await GetDefaultHeaders(true, false, true);
    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/entityIsValid`, params), { method: 'GET', headers: headers });
        var json = await CheckStatus(apiResponse);
        return json && json.success
    }
    catch (e) {
        ShowExceptionAsMessage(e)
        return false
    }
}