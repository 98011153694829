import { RootState } from "..";
import { IDecisionAssetInputFieldMapping } from "../../utilities/types/DecisionAssetInputFieldMapping";


export function selectorGetDecisionAssetInputFieldMappingsByModelId(state: RootState, modelId: string) : IDecisionAssetInputFieldMapping[] {

    if (!state.decisionAssetInputFieldMappings || !state.decisionAssetInputFieldMappings.byId) return [];

    return sortDecisionAssetInputFieldMappingsByOrderNumberAscending(Object.values(state.decisionAssetInputFieldMappings.byId).filter(x => x.modelId === modelId));
}


export function selectorGetDecisionAssetInputFieldMappingsByDecisionId(state: RootState, decisionId: string) : IDecisionAssetInputFieldMapping[] {

    if (!state.decisionAssetInputFieldMappings || !state.decisionAssetInputFieldMappings.byId) return [];

    return sortDecisionAssetInputFieldMappingsByOrderNumberAscending(Object.values(state.decisionAssetInputFieldMappings.byId).filter(x => x.decisionId === decisionId));
}


export function selectorGetDecisionAssetInputFieldMappingById(state: RootState, decisionAssetInputFieldMappingId: string) : IDecisionAssetInputFieldMapping | undefined {

    if (!state.decisionAssetInputFieldMappings || !state.decisionAssetInputFieldMappings.byId) return undefined;

    return state.decisionAssetInputFieldMappings.byId[decisionAssetInputFieldMappingId];
}


// function sortDecisionAssetInputFieldMappingsByOrderNumberDescending(decisionAssetInputFieldMappings: IDecisionAssetInputFieldMapping[]) {
//     return decisionAssetInputFieldMappings.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortDecisionAssetInputFieldMappingsByOrderNumberAscending(decisionAssetInputFieldMappings: IDecisionAssetInputFieldMapping[]) {
    return decisionAssetInputFieldMappings.sort((a, b) => a.orderNumber - b.orderNumber);
}