import { ISolverUIResultSet } from "../../utilities/types/SolverUIResultSet";


export const ACTIONS_SOLVER_JOB_SUMMARY_RESULT_SET = {
    INVALIDATE: 'INVALIDATE_SOLVER_JOB_SUMMARY_RESULT_SETS',
    RECEIVE: 'RECEIVE_SOLVER_JOB_SUMMARY_RESULT_SETS',
    DELETE: 'DELETE_SOLVER_JOB_SUMMARY_RESULT_SET',
    UPDATE: 'UPDATE_SOLVER_JOB_SUMMARY_RESULT_SETS',
}

interface ActionSolverUIResultSetsInvalidate {
    type: typeof ACTIONS_SOLVER_JOB_SUMMARY_RESULT_SET.INVALIDATE,
    byId: { [key: string]: ISolverUIResultSet },
}

interface ActionSolverUIResultSetsReceive {
    type: typeof ACTIONS_SOLVER_JOB_SUMMARY_RESULT_SET.RECEIVE
    byId: { [key: string]: ISolverUIResultSet },
}

export interface SolverUIResultSetState {
    byId: { [key: string]: ISolverUIResultSet },
}

export enum SolverUIResultSetSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverUIResultSet = ActionSolverUIResultSetsReceive | ActionSolverUIResultSetsInvalidate;