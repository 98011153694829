import React, { useState } from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";
import { IUserRole } from "../../../utilities/types/UserRole";
import { fetchDeleteUserRole, fetchUpdateUserRole } from "../../../redux/userRole/actions";

interface FormValues {
  userRoleDescription: string;
  userRoleId: string;
}

interface FormProps {
  userRole: IUserRole;
  onCompleteCallback(component?: IUserRole): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IUserRole, AnyAction>;
}

const FormUserRoleUpdate = (formProps: FormProps) => {
  const { onCancelCallback, userRole } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, userRole } = formProps;

    setSubmitting(true);
    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    userRole.userRoleDescription = values.userRoleDescription;
    // Map dispatch via props
    var updatedUserRole = await dispatch(
      fetchUpdateUserRole({
        ...values,
      })
    );

    setSubmitting(false);
    if (updatedUserRole) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedUserRole);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.userRole,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteUserRole({ userRoleId: userRole.userRoleId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={userRole.userRoleId} name="userRoleId" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "userRoleDescription", "User Role Description")}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete user role"
                  subtitle="Confirm user role delete"
                  description="Are you sure that you'd like to remove this user role?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          userRoleDescription: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a role description"),
        });
      }}
    />
  );
};

export default FormUserRoleUpdate;
