import React from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { withFormik, FormikProps } from "formik";

import { getFormikFieldProps } from "../../../utilities/Helpers";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { RootState } from "../../../redux";
import { fetchCreateWorkflow } from "../../../redux/workflow/actions";
import { IWorkflow } from "../../../utilities/types/Workflow";
import { workflowValidationSchema } from "../constants";

interface FormValues {
  clientName: string;
  workflowName: string;
  clientBaseBucket: string;
}

interface FormProps {
  onCompleteCallback(): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IWorkflow, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "clientName", "Client Name")}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "workflowName", "Workflow Name")}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "clientBaseBucket", "Client Bucket")}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            disabled={props.isSubmitting}
            variant="text"
            style={{ marginRight: 16 }}
            onClick={props.onCancelCallback}
          >
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
        </Grid>
        <LoaderAbsoluteCentred loading={props.isSubmitting} />
      </Grid>
    </form>
  );
};

const FormWorkflowCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    clientName: "",
    workflowName: "",
    clientBaseBucket: "",
  }),
  validationSchema: workflowValidationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch } = props;

    // Map dispatch via props
    var createWorkflowType = await dispatch(
      fetchCreateWorkflow({
        ...values,
      })
    );

    setSubmitting(false);

    if (createWorkflowType) onCompleteCallback();
  },
})(InnerForm);

export default FormWorkflowCreate;
