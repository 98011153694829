import { IUserContribution } from "../../utilities/types/UserContributions";

export const ACTIONS_USER_CONTRIBUTIONS = {
    INVALIDATE: 'INVALIDATE_USER_CONTRIBUTIONS',
    RECEIVE: 'RECEIVE_USER_CONTRIBUTIONS',
    UPDATE: 'UPDATE_USER_CONTRIBUTIONS',
    DELETE: 'DELETE_USER_CONTRIBUTIONS',
}

export interface ActionUserContributionsReceive {
    type: typeof ACTIONS_USER_CONTRIBUTIONS.RECEIVE
    byId: { [key: string]: IUserContribution },
    totalContributions: number,
}

interface ActionUserContributionsInvalidate {
    type: typeof ACTIONS_USER_CONTRIBUTIONS.INVALIDATE,
    byId: { [key: string]: IUserContribution },

}

interface ActionUserContributionsUpdate {
    type: typeof ACTIONS_USER_CONTRIBUTIONS.UPDATE
    byId: { [key: string]: IUserContribution },

}

// interface ActionUserContributionsDelete {
//     type: typeof ACTIONS_USER_CONTRIBUTIONS.DELETE
//     byId: { [key: string]: IUserContribution },

// }

export interface UserContributionsState {
    byId: { [key: string]: IUserContribution },
    totalContributions: number,
}

export type ActionTypesUserContributions = ActionUserContributionsInvalidate | ActionUserContributionsReceive | ActionUserContributionsUpdate;