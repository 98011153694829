export interface IIdentifierMapping {
    identifierMappingId: string,
    identifierId: string,
    modelId: string,
    targetId: string,
    identifierMappingValue?: number,
    secondaryTargetId?: string,
    mappingType: IdentifierMappingTypeEnum,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum IdentifierMappingTypeEnum {
    Component = 10,
    Function = 20,
    FunctionalFailure = 30,
    FailureMode = 40,
    Decision = 50,
    TaskApplicability = 60,
    Model=70,
    CostBundle=80,
    Plan=90,
}