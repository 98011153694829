import { useState, useCallback, useEffect } from "react"

export const MAX_VALUE = 999999999;
export const DEFAULT_DECIMAL_PLACES = 5
export const MAX_DECIMAL_PLACES = 10

export type DecimalPlaces = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

type MaybeNum = number | string | undefined | null

export function useDecimalValue(defaultValue: MaybeNum, decimalPlaces: DecimalPlaces): [MaybeNum, (_: MaybeNum) => void] {
    const [value, setValue] = useState(defaultValue)
    const setDecimalValue = useCallback((newValue: MaybeNum) => {
        setValue(roundToDecimalPlaces(newValue, decimalPlaces))
    }, [setValue, decimalPlaces])
    useEffect(() => void setValue(defaultValue), [defaultValue])
    return [value, setDecimalValue]
}

export function roundToDecimalPlaces(value: MaybeNum, decimalPlaces: number): MaybeNum {
    const places = Math.max(0, Math.min(decimalPlaces, MAX_DECIMAL_PLACES))
    return value ? parseFloat(Number(value).toFixed(places)) : value === 0 ? 0 : null
}