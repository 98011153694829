import { ListItemButton, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import React, { useEffect, useState } from "react";
import { IUserRole } from "../../../utilities/types/UserRole";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

const GridWrapper = styled(Grid)(({ theme }) => ({
  margin: "auto",
  width: "100%",
  "& .paper": {
    height: 230,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
  },
  "& .listContainer": {
    flex: 1,
    minWidth: 200,
  },
  "& .list": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
  },
}));

interface UserToRoleMappingPicklistProps {
  userRoles: IUserRole[];
  defaultUserRoles?: IUserRole[];
  onChange: (selectedRoles: IUserRole[]) => void;
  fetchingUserRole: boolean;
}

function not(a: IUserRole[], b: IUserRole[]) {
  return a.filter((value) => !b.some((c) => c.userRoleId === value.userRoleId));
}

export default function IdentifierMappingPicklist({
  userRoles,
  defaultUserRoles = [],
  onChange,
  fetchingUserRole,
}: UserToRoleMappingPicklistProps) {
  const [selected, setSelected] = useState<IUserRole[]>(defaultUserRoles);
  const available = not(userRoles, selected);

  // Allow the parent to receive updates
  useEffect(() => void onChange(selected), [onChange, selected]);

  useEffect(() => {
    if (!fetchingUserRole && selected !== defaultUserRoles) {
      setSelected(defaultUserRoles);
    }
  }, [fetchingUserRole]);

  const selectItem = (clickedItem: IUserRole) => setSelected([...selected, clickedItem]);
  const deselectItem = (clickedItem: IUserRole) =>
    setSelected(selected.filter((x) => x.userRoleId !== clickedItem.userRoleId));

  const customList = (items: IUserRole[], onClick: (_: IUserRole) => void) => {
    return (
      <Paper className="paper list">
        <List dense component="div" role="list">
          {items.map((userToRoleMapping) => {
            return (
              <ListItemButton
                key={userToRoleMapping.userRoleId}
                role="listitem"
                className="listItem"
                onClick={() => onClick(userToRoleMapping)}
              >
                <ListItemText primary={userToRoleMapping.userRoleDescription} />
              </ListItemButton>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <GridWrapper container spacing={2} alignItems="center">
      <Grid item className="listContainer">
        <Typography variant="overline">Available</Typography>
        {customList(available, selectItem)}
      </Grid>
      <Grid item className="listContainer">
        <Typography variant="overline">Selected</Typography>
        {customList(selected, deselectItem)}
      </Grid>
      <LoaderAbsoluteCentred loading={fetchingUserRole} />
    </GridWrapper>
  );
}
