import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormDiscussionCreate from "../forms/FormDiscussionCreate";
import { IDiscussion } from "../../../utilities/types/Discussion";
import MapIcon from "@mui/icons-material/MapRounded";
import { EntityTypeEnum } from "../../../utilities/types/Entity";

export interface IModalDiscussionCreateProps {
  open: boolean;
  entityId: string;
  entityType: EntityTypeEnum;
  onCancelCallback(): void;
  onCompleteCallback(discussion?: IDiscussion): void;
}

function ModalDiscussionCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  entityId,
  entityType,
}: IModalDiscussionCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new discussion"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormDiscussionCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          entityType={entityType}
          entityId={entityId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalDiscussionCreate;
