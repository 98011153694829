import { RootState } from "..";
import { IComment } from "../../utilities/types/Comment";


export function selectorGetCommentsByDiscussionAndParentId(state: RootState, discussionId: string, parentId: string | null): IComment[] {

    if (!state.comments || !state.comments.byId) return [];

    return sortCommentsByCreatedDescending(Object.values(state.comments.byId).filter(x => x.discussionId === discussionId && x.parentId === parentId));
}


export function selectorGetCommentById(state: RootState, commentId: string): IComment | undefined {

    if (!state.comments || !state.comments.byId) return undefined;

    return state.comments.byId[commentId];
}


function sortCommentsByCreatedDescending(comments: IComment[]) {
    return comments.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}