import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { useFetchComponentsPageHook } from "../components/component/Hooks";
import { useFetchEventsPageHook } from "../components/event/Hooks";
import { useFetchFailureModesPageHook } from "../components/failureMode/Hooks";
import { useFetchFunctionsPageHook } from "../components/function/Hooks";
import { useFetchFunctionalFailuresPageHook } from "../components/functionalFailure/Hooks";
import LoaderAbsoluteCentred from "../components/generic/loaders/LoaderAbsoluteCentred";
import LayoutDefault from "../components/layout/LayoutDefault";
import { useFetchModelsPageHook } from "../components/model/Hooks";
import PageModelDefinitions from "../components/model/pages/PageModelDefinitions";
import PageModelDetails from "../components/model/pages/PageModelDetails";
import { useFetchModelHistoriesPageHook } from "../components/modelHistory/Hooks";
import { ModelStatusEnum } from "../utilities/types/Model";
import RouteTask from "./RouteTasks";

interface RouteParams {
  modelId: string; // You can adjust the type accordingly
}

const RouteModel = () => {
  let match = useRouteMatch();
  let { modelId } = useParams<RouteParams>();

  const { fetching, setReFetch } = useFetchModelsPageHook({
    pageNumber: 1,
    pageSize: 1,
    modelId,
    minPageNumberToFetch: 1,
    status: ModelStatusEnum.Private,
  });

  const { fetching: fetchingModelHistory } = useFetchModelHistoriesPageHook({
    pageNumber: 1,
    pageSize: 20,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingComponents } = useFetchComponentsPageHook({
    pageNumber: 1,
    pageSize: 150,
    modelId: modelId || "",
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingFailureModes } = useFetchFailureModesPageHook({
    pageNumber: 1,
    pageSize: 1500,
    modelId: modelId || "",
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingFunctionalFailures } = useFetchFunctionalFailuresPageHook({
    pageNumber: 1,
    pageSize: 1000,
    modelId: modelId || "",
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingFunctions } = useFetchFunctionsPageHook({
    pageNumber: 1,
    pageSize: 1000,
    modelId: modelId || "",
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingEvents } = useFetchEventsPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: modelId || "",
    minPageNumberToFetch: 1,
  });

  if (fetching)
    return (
      <LoaderAbsoluteCentred
        loading={
          fetching ||
          fetchingModelHistory ||
          fetchingComponents ||
          fetchingFailureModes ||
          fetchingFunctionalFailures ||
          fetchingFunctions ||
          fetchingEvents
        }
      />
    );

  return (
    <Switch>
      <Route path={`${match.path}/tasks`}>
        <RouteTask />
      </Route>
      <Route
        path={`${match.path}/definitions`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageModelDefinitions {...props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageModelDetails {...props} handleRefetchModal={() => setReFetch(true)} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteModel;
