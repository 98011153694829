import { IDiscussion, DiscussionStatusEnum } from "../../utilities/types/Discussion";
import { Collapse, Chip, Typography, Button, styled } from "@mui/material";
import { useState } from "react";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import DiscussionUserAvatar from "./DiscussionUserAvatar";
import moment from "moment";
import { useFetchCommentsPageHook } from "../comment/Hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetCommentsByDiscussionAndParentId } from "../../redux/comment/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import React from "react";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import CommentRenderer from "../comment/CommentRenderer";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { selectorGetUserDetailById } from "../../redux/userDetail/selectors";
import { ShowMessage, UpdateMessage } from "../../utilities/Helpers";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { fetchUpdateDiscussion } from "../../redux/discussion/actions";
import DoneIcon from "@mui/icons-material/Done";
import GreyColors from "@mui/material/colors/grey";
import CommentActions from "../comment/CommentActions";

const DivWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  "& .discussionRow": {
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    alignItems: "start",
    gap: theme.spacing(1.5),
    ".discussion-content": {
      flex: 1,
    },
  },
  "& .linkText": {
    fontWeight: 500,
    color: GreyColors[700],
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  "& .discussionText": {
    whiteSpace: "pre-line",
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.5),
  },
  "& .deleted": {
    filter: "grayscale(1)",
    fontStyle: "italic",
    opacity: 0.5,
  },
  "& .comment-wrapper": {
    position: "relative",
    marginLeft: theme.spacing(3.4),
    paddingLeft: theme.spacing(1),
  },
  "& .collapser": {
    position: "absolute",
    left: "4px",
    width: "28px",
    top: "60px",
    bottom: "10px",
    cursor: "pointer",
    ".collapser-bar": {
      position: "absolute",
      top: 0,
      left: "50%",
      transform: " translate(-50%)",
      height: "100%",
      width: "2px",
      backgroundColor: GreyColors[300],
      borderRadius: "20px",
      transition: "all linear 0.3s ease-in-out",
    },
    "&:hover": {
      ".collapser-bar": {
        backgroundColor: GreyColors[600],
      },
    },
  },
}));

interface IDiscussionRowProps {
  discussion: IDiscussion;
  showAdminDetails: boolean;
  commentLevel: number;
  handleRefetch: () => void;
}

function DiscussionRow({ discussion, handleRefetch, commentLevel }: IDiscussionRowProps) {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState<boolean>(discussion.status !== DiscussionStatusEnum.Resolved);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const isAdmin = useSelector((store: RootState) => selectorGetUserPermissionIsAdmin(store));
  const canEdit =
    (isAdmin || GetUserId() === discussion.createdBy) && discussion.status === DiscussionStatusEnum.Active;

  const userDetails = useSelector((store: RootState) => selectorGetUserDetailById(store, discussion.createdBy));

  const markAsResolved = async () => {
    setIsUpdating(true);
    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedDiscussion: any = await dispatch(
      fetchUpdateDiscussion({
        title: discussion.title,
        text: discussion.text,
        entityId: discussion.entityId,
        status: DiscussionStatusEnum.Resolved,
        discussionId: discussion.discussionId,
      })
    );

    if (updatedDiscussion) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
    }
    setIsUpdating(false);
  };

  return (
    <AnimationWrapper>
      <DivWrapper>
        <div className={`${discussion.status === DiscussionStatusEnum.Deleted ? "deleted" : null}`}>
          <div className="discussionRow">
            <DiscussionUserAvatar
              userId={
                [DiscussionStatusEnum.Active, DiscussionStatusEnum.Resolved].includes(discussion.status)
                  ? discussion.createdBy
                  : ""
              }
              showName={false}
            />

            <div className="discussion-content">
              <Typography variant="caption" style={{ fontWeight: 600 }}>
                {userDetails?.displayName || ""}{" "}
              </Typography>
              <Typography variant="caption" style={{ fontSize: "0.6rem" }}>
                ({moment.utc(discussion.created).fromNow()})
              </Typography>
              {discussion.status === DiscussionStatusEnum.Resolved && (
                <Chip
                  label="Resolved"
                  color="success"
                  size="small"
                  avatar={<DoneIcon style={{ color: "#ffffff" }} />}
                  sx={{
                    marginLeft: 1,
                  }}
                />
              )}
              {discussion.status === DiscussionStatusEnum.Active && (
                <Typography style={{ fontSize: "0.85rem", fontWeight: 600 }}>{discussion.title}</Typography>
              )}
              <Typography className="discussionText" style={{ fontSize: "0.75rem", lineHeight: 1 }}>
                {discussion.text}
              </Typography>
              <CommentActions
                canEdit={canEdit}
                commentLevel={commentLevel}
                showResolvedAction={discussion.status === DiscussionStatusEnum.Active && commentLevel === 0}
                entityId={discussion.entityId}
                entityType={discussion.entityType}
                isUpdating={isUpdating}
                markAsResolved={markAsResolved}
                discussionOrComment={discussion}
                handleRefetch={() => {
                  if (!showMore) {
                    setShowMore(true);
                  }
                  handleRefetch();
                }}
                isComment={false}
              />

              {!showMore && discussion.commentCount > 0 && (
                <Typography variant="caption" className="linkText" onClick={() => setShowMore((prev) => !prev)}>
                  View {discussion.commentCount} Replies
                </Typography>
              )}
            </div>
          </div>

          <Collapse in={showMore}>
            <CommentsSection
              discussionId={discussion.discussionId}
              entityId={discussion.entityId}
              entityType={discussion.entityType}
              commentLevel={commentLevel + 1}
            />
          </Collapse>

          {showMore && (
            <div className="collapser" onClick={() => setShowMore((prev) => !prev)}>
              <div className="collapser-bar" />
            </div>
          )}
        </div>
      </DivWrapper>
    </AnimationWrapper>
  );
}

interface ICommentsSectionProps {
  discussionId: string;
  entityId: string;
  entityType: EntityTypeEnum;
  commentLevel: number;
}

function CommentsSection({ discussionId, entityId, commentLevel, entityType }: ICommentsSectionProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const { fetching, morePages, setReFetch } = useFetchCommentsPageHook({
    pageNumber: pageNumber,
    pageSize: 20,
    minPageNumberToFetch: 1,
    discussionId,
    parentId: "",
  });
  const comments = useSelector((store: RootState) =>
    selectorGetCommentsByDiscussionAndParentId(store, discussionId, null)
  );

  const handleRefetchComment = () => {
    setReFetch(true);
  };

  return (
    <AnimationWrapper>
      <div className="comment-wrapper">
        {comments.map((comment) => (
          <CommentRenderer
            key={comment.commentId}
            comment={comment}
            entityId={entityId}
            entityType={entityType}
            commentLevel={commentLevel + 1}
            handleRefetchComment={handleRefetchComment}
          />
        ))}
        <LoaderAbsoluteCentred loading={fetching} />

        {morePages && (
          <div style={{ textAlign: "center" }}>
            <Button
              disabled={fetching}
              onClick={() => setPageNumber(pageNumber + 1)}
              variant="outlined"
              style={{ marginLeft: 8 }}
            >
              More
            </Button>
          </div>
        )}
      </div>
    </AnimationWrapper>
  );
}

export default DiscussionRow;
