import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import { Typography, styled } from "@mui/material";
import { selectorGetUserDetailById } from "../../redux/userDetail/selectors";
import React from "react";
import { useHistory } from "react-router-dom";
import { GetUserLinkByUserId } from "../../routes/RouteLinkHelpers";
import { ImageWithFallback } from "../generic/ImageWithFallback";

const DivWrapper = styled("div")(({ theme }) => ({
  "&.cursor-pointer": {
    cursor: "pointer",
  },
  "& .avatarImage": {
    width: "35px",
    height: "35px",
    objectFit: "cover",
    borderRadius: "50%",
    objectPosition: "50% 50%",
    backgroundColor: "#EEE",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1.2),
    border: "1px solid rgba(0,0,0,0.2)",
  },
  "& .textWrapper": {
    width: 50,
    textAlign: "center",
  },
}));

interface IDiscussionUserAvatarProps {
  userId: string;
  showName?: boolean;
}

function DiscussionUserAvatar({ userId, showName }: IDiscussionUserAvatarProps) {
  const userDetails = useSelector((store: RootState) => selectorGetUserDetailById(store, userId));
  const imageUrl = useFileSourceSingleHook({ fileId: userDetails?.fileId || "", fileType: FileTypeEnum.Avatar });
  const history = useHistory();

  return (
    <DivWrapper
      onClick={() => {
        if (userId) history.push(GetUserLinkByUserId(userId));
      }}
      className={userId ? "cursor-pointer" : ""}
    >
      <ImageWithFallback src={imageUrl} className="avatarImage" alt={userDetails?.displayName} />
      {showName === true && (
        <div className="textWrapper">
          <Typography variant="caption" component="p" noWrap>
            {userDetails?.displayName || ""}
          </Typography>
        </div>
      )}
    </DivWrapper>
  );
}

export default DiscussionUserAvatar;
