import { RootState } from "..";
import { ITermsAgreement } from "../../utilities/types/TermsAgreement";
import { GetUserId } from "../../utilities/ApiUtils";

export function selectorGetTermsAgreementById(state: RootState, termsAgreementId: string): ITermsAgreement | undefined {

    if (!state.termsAgreements || !state.termsAgreements.byId) return undefined;

    return state.termsAgreements.byId[termsAgreementId];
}


export function selectorGetTermsAgreementsByUserId(state: RootState, userId: string = GetUserId()): ITermsAgreement[] {

    if (!state.termsAgreements || !state.termsAgreements.byId) return [];

    return Object.values(state.termsAgreements.byId).filter(x => x.userId === userId);
}

