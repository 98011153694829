import { RootState } from "..";
import { ISolverSummaryOutputTypeMapping } from "../../utilities/types/SolverSummaryOutputTypeMapping";


export function selectorGetSolverSummaryOutputTypeMappingsBySolverId(state: RootState, solverId: string) : ISolverSummaryOutputTypeMapping[] {

    if (!state.solverSummaryOutputTypeMappings || !state.solverSummaryOutputTypeMappings.byId) return [];

    return Object.values(state.solverSummaryOutputTypeMappings.byId)
        .filter(x => x.solverId === solverId);
}


export function selectorGetSolverSummaryOutputTypeMappingById(state: RootState, solverSummaryOutputTypeMappingId: string) : ISolverSummaryOutputTypeMapping | undefined {

    if (!state.solverSummaryOutputTypeMappings || !state.solverSummaryOutputTypeMappings.byId) return undefined;

    return state.solverSummaryOutputTypeMappings.byId[solverSummaryOutputTypeMappingId];
}