import React from "react";
import DialogContent from "@mui/material/DialogContent";
import BuildIcon from "@mui/icons-material/SmsFailedRounded";

import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormFunctionFailureAiSuggest from "../forms/FormFunctionFailureAiSuggest";

export interface IModalFunctionFailureProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  componentId: string;
  functionId: string;
  onCompleteCallback(): void;
}

const ModalAiSuggestFunctionFailure: React.FC<IModalFunctionFailureProps> = ({
  open,
  onCancelCallback,
  modelId,
  componentId,
  functionId,
  onCompleteCallback,
}) => {
  return (
    <WidgetModalBase
      style={{ maxWidth: 900, width: "100%" }}
      open={open}
      handleCancel={onCancelCallback}
      title="AI Suggestions (Beta)"
      subtitle=""
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormFunctionFailureAiSuggest
          modelId={modelId}
          componentId={componentId}
          functionId={functionId}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalAiSuggestFunctionFailure;
