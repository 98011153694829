import { Button, IconButton, Typography, styled } from "@mui/material";
import EditIcon from "@mui/icons-material/EditTwoTone";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetAssetInputFieldsByCategoryId } from "../../redux/assetInputField/selectors";
import { IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { IAssetInputFieldCategory } from "../../utilities/types/AssetInputFieldCategory";
import ModalAssetInputFieldCategoryUpdate from "../assetInputFieldCategory/modals/ModalAssetInputFieldCategoryUpdate";
import { useFetchAssetInputFieldListValuesPageHook } from "../assetInputFieldListValue/Hooks";
import AssetInputFieldDisplay from "./AssetInputFieldDisplay";
import { useFetchAssetInputFieldsPageHook } from "./Hooks";
import ModalAssetInputFieldCreate from "./modals/ModalAssetInputFieldCreate";
import ModalAssetInputFieldUpdate from "./modals/ModalAssetInputFieldUpdate";

const InputFieldsWrapper = styled("div")(({ theme }) => ({
  height: 350,
  overflowY: "scroll",
  overflowX: "hidden",
  "& .headerWrapper": {
    marginBottom: theme.spacing(2),
  },
  "& .inputFieldWrapper": {
    display: "flex",
    flexDirection: "row",
    flexBasis: "100%",
    margin: theme.spacing(2),
  },
  "& .inputFieldWrapperButton": {
    flexBasis: "25%",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  "& .inputFieldWrapperField": {
    flexBasis: "70%",
    maxWidth: "70%",
  },
  "& .inputFieldWrapperNoEdit": {
    flexBasis: "100%",
  },
}));

interface IAssetInputFieldTabPanelProps {
  assetInputFieldCategory: IAssetInputFieldCategory;
  canEdit: boolean;
  enableInputs: boolean;
  defaultValueOverrides?: { [key: string]: TAssetInputFieldValue };
  onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
}

const AssetInputFieldTabPanel = ({
  assetInputFieldCategory,
  canEdit,
  enableInputs,
  defaultValueOverrides,
  onValueChangeCallback,
}: IAssetInputFieldTabPanelProps) => {
  const assetInputFields = useSelector((store: RootState) =>
    selectorGetAssetInputFieldsByCategoryId(store, assetInputFieldCategory.assetInputFieldCategoryId)
  );

  useFetchAssetInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: assetInputFieldCategory.modelId,
    minPageNumberToFetch: 1,
  });

  useFetchAssetInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: assetInputFieldCategory.modelId,
    minPageNumberToFetch: 1,
  });
  return (
    <AssetInputFieldTabPanelDisplay
      assetInputFields={assetInputFields}
      canEdit={canEdit}
      assetInputFieldCategory={assetInputFieldCategory}
      enableInputs={enableInputs}
      onValueChangeCallback={onValueChangeCallback}
      defaultValueOverrides={defaultValueOverrides}
    />
  );
};

interface IAssetInputFieldTabPanelDisplayProps {
  canEdit: boolean;
  assetInputFields: IAssetInputField[];
  assetInputFieldCategory: IAssetInputFieldCategory;
  enableInputs: boolean;
  onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
  defaultValueOverrides?: { [key: string]: TAssetInputFieldValue };
}

function AssetInputFieldTabPanelDisplay({
  assetInputFields,
  defaultValueOverrides,
  assetInputFieldCategory,
  canEdit,
  enableInputs,
  onValueChangeCallback,
}: IAssetInputFieldTabPanelDisplayProps) {
  const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState(false);
  const [assetInputFieldToUpdate, setAssetInputFieldToUpdate] = useState<IAssetInputField>();
  const [showAssetInputFieldCreateModal, setShowAssetInputFieldCreateModal] = useState(false);

  const toggleUpdateCategoryModal = () => setShowUpdateCategoryModal(!showUpdateCategoryModal);
  const toggleAssetInputFieldCreateModal = () => setShowAssetInputFieldCreateModal(!showAssetInputFieldCreateModal);
  const closeModalAssetInputFieldUpdate = () => setAssetInputFieldToUpdate(undefined);

  return (
    <InputFieldsWrapper>
      <div className="headerWrapper">
        <Typography variant="subtitle1" style={{ textTransform: "capitalize" }}>
          {assetInputFieldCategory.name}
        </Typography>
        <Typography variant="body2">{assetInputFieldCategory.description || "No description."}</Typography>
      </div>
      <div>
        {assetInputFields.map((assetInputField: IAssetInputField) => (
          <div key={assetInputField.assetInputFieldId} className="inputFieldWrapper">
            <div className={`inputFieldWrapperField ${canEdit ? "" : "inputFieldWrapperNoEdit"}`}>
              <AssetInputFieldDisplay
                assetInputField={assetInputField}
                readOnly={!enableInputs}
                onValueChangeCallback={onValueChangeCallback}
                defaultValueOverride={
                  defaultValueOverrides ? defaultValueOverrides[assetInputField.assetInputFieldId] : undefined
                }
              />
            </div>
                
            {canEdit ? <div className="inputFieldWrapperButton">
                <IconButton
                  onClick={() => setAssetInputFieldToUpdate(assetInputField)}
                  style={{ border: "1px solid #EEE" }}
                >
                  <EditIcon style={{ fontSize: 15, opacity: 0.7 }} />
                </IconButton>
              </div> 
              : null
              }
          </div>
        ))}
      </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="outlined"
            style={{ display: "flex", flexGrow: 1, marginRight: 16 }}
            onClick={toggleUpdateCategoryModal}
          >
            Edit Category
          </Button>
          <Button
            color="primary"
            variant="outlined"
            style={{ display: "flex", flexGrow: 1 }}
            onClick={toggleAssetInputFieldCreateModal}
          >
            Add Field
          </Button>
        </div>


      <ModalAssetInputFieldCategoryUpdate
        open={showUpdateCategoryModal}
        onCancelCallback={toggleUpdateCategoryModal}
        onCompleteCallback={toggleUpdateCategoryModal}
        assetInputFieldCategory={assetInputFieldCategory}
      />

      <ModalAssetInputFieldCreate
        open={showAssetInputFieldCreateModal}
        onCancelCallback={toggleAssetInputFieldCreateModal}
        onCompleteCallback={toggleAssetInputFieldCreateModal}
        assetInputFieldCategoryId={assetInputFieldCategory.assetInputFieldCategoryId}
        modelId={assetInputFieldCategory.modelId}
      />

      {assetInputFieldToUpdate !== undefined ? (
        <ModalAssetInputFieldUpdate
          open={true}
          onCancelCallback={closeModalAssetInputFieldUpdate}
          onCompleteCallback={closeModalAssetInputFieldUpdate}
          assetInputField={assetInputFieldToUpdate}
          canEdit={canEdit}
        />
      ) : null}
    </InputFieldsWrapper>
  );
}

export default AssetInputFieldTabPanel;
