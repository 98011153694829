import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import HeaderIcon from "@mui/icons-material/ListAlt";
import FormSummaryOutputTypeInputFieldListValueUpdate from "../forms/FormSummaryOutputTypeInputFieldListValueUpdate";
import { ISummaryOutputTypeInputFieldListValue } from "../../../utilities/types/SummaryOutputTypeInputFieldListValue";

export interface IModalSummaryOutputTypeInputFieldListValueUpdateProps {
  open: boolean;
  summaryOutputTypeInputFieldListValue: ISummaryOutputTypeInputFieldListValue;
  onCancelCallback(): void;
  onCompleteCallback(summaryOutputTypeInputFieldListValue?: ISummaryOutputTypeInputFieldListValue): void;
}

function ModalSummaryOutputTypeInputFieldListValueUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  summaryOutputTypeInputFieldListValue,
}: IModalSummaryOutputTypeInputFieldListValueUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update list value"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent>
        <FormSummaryOutputTypeInputFieldListValueUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          summaryOutputTypeInputFieldListValue={summaryOutputTypeInputFieldListValue}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSummaryOutputTypeInputFieldListValueUpdate;
