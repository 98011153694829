import { ActionWorkflowType, WorkflowState, ACTIONS_WORKFLOW_TYPE } from "./types";

const initialState: WorkflowState = {
  byId: {},
  selectedWorkflowId: undefined,
};

export function workflowsReducer(state = initialState, action: ActionWorkflowType): WorkflowState {
  switch (action.type) {
    case ACTIONS_WORKFLOW_TYPE.INVALIDATE:
      return {
        ...initialState,
      };

    case ACTIONS_WORKFLOW_TYPE.DELETE:
      let currentById = Object.assign({}, state.byId);
      const deletedWorkflowId = Object.keys(action.byId)[0];
      delete currentById[Object.keys(action.byId)[0]];
      return {
        ...state,
        byId: currentById,
        selectedWorkflowId: state.selectedWorkflowId === deletedWorkflowId ? undefined : state.selectedWorkflowId,
      };

    case ACTIONS_WORKFLOW_TYPE.RECEIVE:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        selectedWorkflowId: !state.selectedWorkflowId && Object.keys(action.byId).length ? Object.keys(action.byId)[0] : state.selectedWorkflowId,
      };

    case ACTIONS_WORKFLOW_TYPE.SET_SELECTED:
      return {
        ...state,
        selectedWorkflowId: action.workflowId,
      };

    default:
      return state;
  }
}
