import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ISummaryOutputType } from "../../utilities/types/SummaryOutputType";
import { IFetchSearchSummaryOutputTypesProps, fetchSearchSummaryOutputTypes } from "../../redux/summaryOutputType/actions";


interface IUseFetchSummaryOutputTypesPageHookProps extends IFetchSearchSummaryOutputTypesProps {
    minPageNumberToFetch: number;
}


export const useFetchSummaryOutputTypesPageHook = ({ pageNumber, minPageNumberToFetch, pageSize }: IUseFetchSummaryOutputTypesPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ISummaryOutputType[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ISummaryOutputType[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var summaryOutputTypes = await dispatch(fetchSearchSummaryOutputTypes({
                    pageSize,
                    pageNumber,
                })) as unknown as ISummaryOutputType[];

                if (summaryOutputTypes && summaryOutputTypes.length) {
                    setMorePages(summaryOutputTypes.length >= pageSize)
                    setLastResultSet(summaryOutputTypes);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.summaryOutputTypeId === x.summaryOutputTypeId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}