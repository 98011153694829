import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/InputTwoTone";
import FormDecisionAssetInputFieldMappingUpdate from "../forms/FormDecisionAssetInputFieldMappingUpdate";
import { IDecisionAssetInputFieldMapping } from "../../../utilities/types/DecisionAssetInputFieldMapping";

export interface IModalDecisionAssetInputFieldMappingUpdateProps {
  open: boolean;
  decisionAssetInputFieldMapping: IDecisionAssetInputFieldMapping;
  onCancelCallback(): void;
  onCompleteCallback(decisionAssetInputFieldMapping?: IDecisionAssetInputFieldMapping): void;
  canEdit: boolean;
}

function ModalDecisionAssetInputFieldMappingUpdate({
  onCancelCallback,
  open,
  canEdit,
  onCompleteCallback,
  decisionAssetInputFieldMapping,
}: IModalDecisionAssetInputFieldMappingUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update decision field mapping"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormDecisionAssetInputFieldMappingUpdate
          canEdit={canEdit}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          decisionAssetInputFieldMapping={decisionAssetInputFieldMapping}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalDecisionAssetInputFieldMappingUpdate;
