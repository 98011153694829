import { Card as MuiCard, CardContent, CardHeader, Typography, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import AddImage from "../../assets/img/Add.png";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GetModelLinkByModel } from "../../routes/RouteLinkHelpers";
import { IModel, ModelStatusEnum } from "../../utilities/types/Model";
import ModelCreateModal from "../model/modals/ModalModelCreate";

const IMAGE_HEIGHT = 200;

const Card = styled(MuiCard)(({ theme }) => ({
  height: "100%",
  "& .updatedModelImageWrapper": {
    height: IMAGE_HEIGHT,
    overflow: "hidden",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
  },
  "& .plusImage": {
    alignItems: "center",
    height: IMAGE_HEIGHT,
    left: "50%",
  },

  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .avatar": {
    backgroundColor: blueGrey[800],
    textSizeAdjust: "small",
  },
  "& .header": {
    height: 75,
  },
}));

function AddNewModelWidget() {
  const [modelCreateModalOpen, setModelCreateModalOpen] = useState(false);
  const history = useHistory();

  const handleModelCreateModalToggle = () => setModelCreateModalOpen(!modelCreateModalOpen);
  const onModelCreateCancelCallback = () => setModelCreateModalOpen(false);
  const viewModelDetails = (model: IModel) => history.push(GetModelLinkByModel(model));

  const onModelCreateCompleteCallback = (model?: IModel) => {
    if (model) {
      setModelCreateModalOpen(false);
      viewModelDetails(model);
    }
  };

  return (
    <Card>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        title={"Suggest a new asset type"}
        subheader={`Creates a new draft asset type to share`}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
      />
      <div className="updatedModelImageWrapper">
        <img src={AddImage} alt="AddImage" className="plusImage" onClick={handleModelCreateModalToggle} />
      </div>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {"Start a new page here"}
        </Typography>
      </CardContent>
      <ModelCreateModal
        status={ModelStatusEnum.Draft}
        onCompleteCallback={onModelCreateCompleteCallback}
        onCancelCallback={onModelCreateCancelCallback}
        open={modelCreateModalOpen}
      />
    </Card>
  );
}

export default AddNewModelWidget;
