import { useSelector, useDispatch } from "react-redux";
import { FileSearchOrderType, FileTypeEnum, IFile } from "../../utilities/types/File";
import { RootState } from "../../redux";
import { selectorGetFileById } from "../../redux/file/selectors";
import { useState, useEffect } from "react";
import { IFetchSearchFiles, fetchSearchFiles } from "../../redux/file/actions";
import NotFoundImg from "../../assets/img/image-not-found.png";
import avatarImage from "../../assets/img/default-user.png";
import assetImage from "../../assets/img/default-asset.png";

interface IUseFileSourceSingleProps {
  fileId: string;
  fileType: FileTypeEnum;
}

export const useFileSourceSingleHook = ({ fileId, fileType }: IUseFileSourceSingleProps) => {
  const file = useSelector((state: RootState) => selectorGetFileById(state, fileId));
  const defaultImage = NotFoundImg;
  switch (fileType) {
    case FileTypeEnum.Image:
      return file ? file.preSignedUrl : defaultImage;
    case FileTypeEnum.Avatar:
      return file ? file.preSignedUrl : avatarImage;
    case FileTypeEnum.AssetImage:
      return file ? file.preSignedUrl : assetImage;
    default:
      return file ? file.preSignedUrl : defaultImage;
  }
};

interface IUseFetchFilesPageHookProps extends IFetchSearchFiles {
  minPageNumberToFetch: number;
  filter?: {
    fileType?: FileTypeEnum;
    orderType?: FileSearchOrderType;
    text?: string;
  };
}

export const useFetchFilesPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
  entityType,
  filter,
}: IUseFetchFilesPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IFile[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IFile[]>([]);
  const [reFetch, setReFetch] = useState<boolean>(false);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);
      if (reFetch) setReFetch(false);
      try {
        // Retrieve models
        var files = (await dispatch(
          fetchSearchFiles({
            pageSize,
            pageNumber,
            entityType,
            ...filter,
          })
        )) as unknown as IFile[];

        if (files && files.length) {
          setMorePages(files.length >= pageSize);
          setLastResultSet(files);
        } else {
          setLastResultSet([]);
          setMorePages(false);
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, entityType, reFetch, filter]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.fileId === x.fileId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
    setReFetch,
  };
};
