import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { selectorGetSolverSummaryOutputTypeMappingsBySolverId } from './selectors';
import { ACTIONS_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPING, SolverSummaryOutputTypeMappingSearchOrderTypeEnum } from './types';
import { ISolverSummaryOutputTypeMapping } from '../../utilities/types/SolverSummaryOutputTypeMapping';


export const receiveSolverSummaryOutputTypeMappings = (solverSummaryOutputTypeMappings: ISolverSummaryOutputTypeMapping[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverSummaryOutputTypeMapping } = {};

    for (var i = 0; i < solverSummaryOutputTypeMappings.length; i++) {
        byIdObjectToDispatch[solverSummaryOutputTypeMappings[i].solverSummaryOutputTypeMappingId] = solverSummaryOutputTypeMappings[i];
    }

    return {
        type: ACTIONS_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPING.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverSummaryOutputTypeMapping = (solverSummaryOutputTypeMapping: ISolverSummaryOutputTypeMapping) => ({
    type: ACTIONS_SOLVER_SUMMARY_OUTPUT_TYPE_MAPPING.DELETE,
    byId: { [solverSummaryOutputTypeMapping.solverSummaryOutputTypeMappingId]: solverSummaryOutputTypeMapping }
});


export interface IFetchCreateSolverSummaryOutputTypeMappingProps {
    solverId: string,
    summaryOutputTypeIds: string[],
}


export const fetchCreateSolverSummaryOutputTypeMapping = (request: IFetchCreateSolverSummaryOutputTypeMappingProps): AppThunk<Promise<ISolverSummaryOutputTypeMapping>> => async (dispatch, getStore) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverSummaryOutputTypeMappings`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(request),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverSummaryOutputTypeMappings) {

            // Remove all previous mappings for solver to ensure we don't create zombie (client-side) state
            var existingMappings = selectorGetSolverSummaryOutputTypeMappingsBySolverId(getStore(), request.solverId);
            for(var mapping of existingMappings){
                dispatch(requestDeleteSolverSummaryOutputTypeMapping(mapping));
            }

            // Apply recieved ones
            dispatch(receiveSolverSummaryOutputTypeMappings(parsedResp.data.solverSummaryOutputTypeMappings));
            return parsedResp.data.solverSummaryOutputTypeMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating solver output type mapping.");
                return;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export const fetchSummaryOutputTypeMappingsBySolverIdIfNeeded = (solverId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetSolverSummaryOutputTypeMappingsBySolverId(getState(), solverId).length) {
        await dispatch(fetchSearchSolverSummaryOutputTypeMappings({ solverId, pageNumber: 1, pageSize: 100 }));
    }

    return;
}


export interface IFetchSearchSolverSummaryOutputTypeMappingsProps {
    pageNumber: number,
    pageSize: number,
    solverSummaryOutputTypeMappingId?: string,
    solverId?: string,
    orderType?: SolverSummaryOutputTypeMappingSearchOrderTypeEnum,
}


export const fetchSearchSolverSummaryOutputTypeMappings = (searchParams: IFetchSearchSolverSummaryOutputTypeMappingsProps): AppThunk<Promise<ISolverSummaryOutputTypeMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverSummaryOutputTypeMappings`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverSummaryOutputTypeMappings) {
            dispatch(receiveSolverSummaryOutputTypeMappings(parsedResp.data.solverSummaryOutputTypeMappings));
            return parsedResp.data.solverSummaryOutputTypeMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching solver output type mapping.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSolverSummaryOutputTypeMappingProps {
    solverSummaryOutputTypeMappingId: string,
}


export const fetchDeleteSolverSummaryOutputTypeMapping = (props: IFetchDeleteSolverSummaryOutputTypeMappingProps): AppThunk<Promise<ISolverSummaryOutputTypeMapping>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverSummaryOutputTypeMappings`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverSummaryOutputTypeMappings && parsedResp.data.solverSummaryOutputTypeMappings.length) {
            dispatch(requestDeleteSolverSummaryOutputTypeMapping(parsedResp.data.solverSummaryOutputTypeMappings[0]));
            return parsedResp.data.solverSummaryOutputTypeMappings[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting solver output type mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}