import { IFile, FileTypeEnum, FileSearchOrderType } from '../../utilities/types/File'
import { ACTIONS_FILE } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, AddQueryStringsToUrl, CheckStatus, ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { EntityTypeEnum } from '../../utilities/types/Entity';
import { MessageTypeEnum } from '../../utilities/types/Message';
import { createMessage } from '../message/actions';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';


export const receiveFiles = (files: IFile[]) => {

    var byIdObjectToDispatch: { [key: string]: IFile } = {};

    for (var i = 0; i < files.length; i++) {
        byIdObjectToDispatch[files[i].fileId] = files[i];
    }

    return {
        type: ACTIONS_FILE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteFile = (file: IFile) => ({
    type: ACTIONS_FILE.DELETE,
    byId: { [file.fileId]: file }
});


export interface IFetchSearchFiles {
    userIds?: number[],
    pageSize: number,
    pageNumber: number,
    fileId?: string,
    text?: string,
    entityType?: EntityTypeEnum,
    fileType?: FileTypeEnum;
    orderType?: FileSearchOrderType;
}


export const fetchSearchFiles = ({ userIds = [], pageSize, entityType, pageNumber, fileId, text ,fileType,orderType}: IFetchSearchFiles): AppThunk<Promise<File[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/files`, {
            userIds: userIds.join('_'),
            pageSize,
            pageNumber,
            fileId,
            text,
            entityType,
            fileType,
            orderType
        }), {
            method: 'GET',
            headers: headers
        });

        var json = await CheckStatus(apiResponse);
        if (json && json.success && json.data) {

            if (json.data.files && json.data.files.length > 0) {
                dispatch(receiveFiles(json.data.files));
            }
        }

        return json.data.files;
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving files.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


interface IFetchDeleteFileProps {
    fileId: string,
}


export const fetchDeleteFile = (props: IFetchDeleteFileProps): AppThunk<Promise<IFile>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/files/delete`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.files && parsedResp.data.files.length) {
            dispatch(requestDeleteFile(parsedResp.data.files[0]));
            return parsedResp.data.files[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting file.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting file.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


interface FetchCreateFileProps {
    fileType: FileTypeEnum,
    entityId: string,
    description: string,
    file: File,
    entityType: EntityTypeEnum,
    fileBucket: string,
}


export const fetchCreateFile = ({ fileType, entityId, entityType, description, file, fileBucket }: FetchCreateFileProps): AppThunk<Promise<IFile>> => async dispatch => {

    try {

        var createFileRequestFormData = new FormData();
        var headers = await GetDefaultHeaders(true, false, false);

        // Add image to form data
        createFileRequestFormData.append('type', String(fileType));
        createFileRequestFormData.append('description', description || "");
        createFileRequestFormData.append('entityId', entityId);
        createFileRequestFormData.append('entityType', String(entityType));
        createFileRequestFormData.append('filename', file.name || "");
        createFileRequestFormData.append('fileBucket', fileBucket);

        // Retrieve the upload url
        var getPresignedUrlResp = await fetch(`${Configuration.BASE_API_URL}/files`, {
            method: 'POST',
            headers: headers,
            body: createFileRequestFormData,
        });

        // Process response
        var json = await CheckStatus(getPresignedUrlResp);
        if (!json || !json.success || !json.data || !json.data.preSignedUrl || !json.data.file) {
            if (!json || !json.messages || !json.messages.length) {
                dispatch(createMessage("Error while uploading image", MessageTypeEnum.Error));
            }

            return json;
        }

        // Upload the image to s3 using the presigned url
        var putUploadFileResponse = await fetch(json.data.preSignedUrl, {
            method: 'PUT',
            body: file,
        });

        // Process upload response
        if (putUploadFileResponse && putUploadFileResponse.ok) {
            dispatch(receiveFiles([json.data.file]))
            return json;
        }

            // Handle different error scenarios based on the response status
            let errorMessage = "An error occurred while uploading the file.";
            if (putUploadFileResponse.status === 403) {
            errorMessage = "Invalid or expired presigned URL. Please try uploading again.";
            } else if (putUploadFileResponse.status === 413) {
            errorMessage = "File size is too large. Please upload a smaller file.";
            } else if (putUploadFileResponse.status === 500) {
            errorMessage = "Internal server error occurred. Please try again later.";
            } else if (!putUploadFileResponse.ok) {
            errorMessage = `S3 Upload failed with status: ${putUploadFileResponse.status}`;
            }

            return { ...putUploadFileResponse, messages: [errorMessage] };
    }
    catch (e) {
        ShowExceptionAsMessage(e);
        return;
    }
}