import { SystemActionTypes, RECEIVE_SYSTEM, INVALIDATE_SYSTEM, SystemState } from "./types";

const initialState: SystemState = {
    loggedIn: false,
    session: '',
    username: '',
}


export function systemReducer(
    state = initialState,
    action: SystemActionTypes
): SystemState {
    switch (action.type) {
        case RECEIVE_SYSTEM: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case INVALIDATE_SYSTEM: {
            return {
                ...initialState,
            }
        }
        default:
            return state;
    }
}