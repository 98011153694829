import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { IFunctionalFailure } from "../../utilities/types/FunctionalFailure";

export interface IFetchSearchAiSuggestFunctionalFailureProps {
  pageNumber: number;
  pageSize: number;
  modelId: string;
  componentId: string;
  functionId: string;
}

export const fetchSearchAiSuggestFunctionalFailure = async (searchParams: IFetchSearchAiSuggestFunctionalFailureProps): Promise<IFunctionalFailure[]> => {
  const headers = await GetDefaultHeaders(true, false, true);

  try {
    const apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/AISuggest/FunctionalFailures`, searchParams), {
      method: "GET",
      headers: headers,
    });

    const parsedResp: IApiResponse = await CheckStatus(apiResponse);
    if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functionalFailures) {
      return parsedResp.data.functionalFailures;
    } else {
      if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
        ShowError("Error searching ai suggest functional failures");
      }
      return [];
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      ShowExceptionAsMessage(e);
      console.log("Error searching ai suggest functional failures", e.stack);
    } else {
      // Handle other types of exceptions or unknown errors.
      console.error("Unknown error:", e);
    }
    return [];
  }
};
