import { ACTIONS_SINGLE_ASSET_OUTPUT_JOB, SingleAssetOutputJobSearchOrderTypeEnum } from "./types";
import { AppThunk } from "..";
import { Configuration } from "../../utilities/Constants";
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from "../../utilities/ApiUtils";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { IOutputJob, OutputJobStatusEnum } from "../../utilities/types/SingleAssetOutputJob";

export const receiveSingleAssetOutputJob = (singleAssetOutputJob: IOutputJob[]) => {
  var byIdObjectToDispatch: { [key: string]: IOutputJob } = {};

  for (var i = 0; i < singleAssetOutputJob.length; i++) {
    byIdObjectToDispatch[singleAssetOutputJob[i].solverJobTaskReportId] = singleAssetOutputJob[i];
  }

  return {
    type: ACTIONS_SINGLE_ASSET_OUTPUT_JOB.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteSingleAssetOutputJob = (singleAssetOutputJob: IOutputJob) => ({
  type: ACTIONS_SINGLE_ASSET_OUTPUT_JOB.DELETE,
  byId: { [singleAssetOutputJob.solverJobTaskReportId]: singleAssetOutputJob },
});

export interface IFetchCreateSingleAssetOutputJobProps {
  solverJobTaskId: string;
  solverJobId: string;
  outputTypeIds: string[];
}

export const fetchCreateSingleAssetOutputJob =
  (request: IFetchCreateSingleAssetOutputJobProps): AppThunk<Promise<IOutputJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverJobTaskReports`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(request),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobTaskReports) {
        dispatch(receiveSingleAssetOutputJob(parsedResp.data.solverJobTaskReports));
        return parsedResp.data.solverJobTaskReports;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating reports.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating reports.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchUpdateSingleAssetOutputJobProps {
  solverJobTaskReportId: string;
  name: string;
  description: string;
  orderNumber: number;
}

export const fetchUpdateSingleAssetOutputJob =
  (props: IFetchUpdateSingleAssetOutputJobProps): AppThunk<Promise<IOutputJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverJobTaskReports`, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobTaskReports) {
        dispatch(receiveSingleAssetOutputJob(parsedResp.data.solverJobTaskReports));
        return parsedResp.data.solverJobTaskReports;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error updating report.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error updating report.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchSingleAssetOutputJobProps {
  pageNumber: number;
  pageSize: number;
  solverJobTaskReportId?: string;
  solverJobTaskId?: string;
  outputTypeId?: string;
  text?: string;
  status?: OutputJobStatusEnum;
  createdBy?: string;
  orderType?: SingleAssetOutputJobSearchOrderTypeEnum;
}

export const fetchSearchSingleAssetOutputJob =
  (searchParams: IFetchSearchSingleAssetOutputJobProps): AppThunk<Promise<IOutputJob[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(
        AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverJobTaskReports`, searchParams),
        {
          method: "GET",
          headers: headers,
        }
      );

      // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobTaskReports) {
        dispatch(receiveSingleAssetOutputJob(parsedResp.data.solverJobTaskReports));
        return parsedResp.data.solverJobTaskReports;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching reports.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching reports.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

export interface IFetchDeleteSingleAssetOutputJobProps {
  solverJobTaskReportId: string;
}

export const fetchDeleteSingleAssetOutputJob =
  (props: IFetchDeleteSingleAssetOutputJobProps): AppThunk<Promise<IOutputJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverJobTaskReports`, props), {
        method: "DELETE",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (
        parsedResp &&
        parsedResp.success &&
        parsedResp.data &&
        parsedResp.data.solverJobTaskReports &&
        parsedResp.data.solverJobTaskReports.length
      ) {
        dispatch(requestDeleteSingleAssetOutputJob(parsedResp.data.solverJobTaskReports[0]));
        return parsedResp.data.solverJobTaskReports[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting report.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting report.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
