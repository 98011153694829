import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Configuration } from "../../../utilities/Constants";


const usePageTracking = () => {

    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window?.location?.href?.includes("localhost")) {
            ReactGA.initialize(String(Configuration.GOOGLE_ANALYTICS_ID));
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};


export default usePageTracking; 