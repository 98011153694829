import { IUserToRoleMapping } from "../../utilities/types/UserToRoleMapping";

export const ACTIONS_USER_TO_ROLE_MAPPING = {
    INVALIDATE: 'INVALIDATE_USER_TO_ROLE_MAPPINGS',
    RECEIVE: 'RECEIVE_USER_TO_ROLE_MAPPINGS',
    UPDATE: 'UPDATE_USER_TO_ROLE_MAPPINGS',
    DELETE: 'DELETE_USER_TO_ROLE_MAPPING',
}

interface ActionUserToRoleMappingInvalidate {
    type: typeof ACTIONS_USER_TO_ROLE_MAPPING.INVALIDATE,
    byId: { [key: string]: IUserToRoleMapping },
}

interface ActionUserToRoleMappingReceive {
    type: typeof ACTIONS_USER_TO_ROLE_MAPPING.RECEIVE
    byId: { [key: string]: IUserToRoleMapping },
}

interface ActionUserToRoleMappingUpdate {
    type: typeof ACTIONS_USER_TO_ROLE_MAPPING.UPDATE
    byId: { [key: string]: IUserToRoleMapping },
}

// interface ActionUserRolePermissionDelete {
//     type: typeof ACTIONS_USER_TO_ROLE_MAPPING.DELETE
//     byId: { [key: string]: IUserToRoleMapping },
// }

export interface UserToRoleMappingState {
    byId: { [key: string]: IUserToRoleMapping },
}

export type ActionTypesUserRolePermission = ActionUserToRoleMappingInvalidate | ActionUserToRoleMappingReceive | ActionUserToRoleMappingUpdate;