import React from "react";
import { Grid, Breadcrumbs, Link, styled } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import UserDetailsManagementContainer from "../UserDetailsManagementContainer";
import { GetAdminPage } from "../../../routes/RouteLinkHelpers";
import { HomeIconLink } from "../../generic/HomeIconLink";

const GridWrapper = styled(Grid)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
}));

interface IPageUserManagementProps {
  routeProps: RouteComponentProps<any>;
}

const PageUserManagement = ({ routeProps }: IPageUserManagementProps) => {
  return (
    <Container maxWidth="xl">
      <AnimationWrapper>
        <GridWrapper item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIconLink />
            <Link color="inherit" href={GetAdminPage()}>
              Admin
            </Link>
            <Link color="textPrimary" aria-current="page" style={{ textDecoration: "none" }}>
              Users
            </Link>
          </Breadcrumbs>
        </GridWrapper>
      </AnimationWrapper>
      <AnimationWrapper>
        <Grid container spacing={5} style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <UserDetailsManagementContainer />
          </Grid>
        </Grid>
      </AnimationWrapper>
    </Container>
  );
};

export default PageUserManagement;
