import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
  styled,
  Link,
} from "@mui/material";
import HeaderColor from "@mui/material/colors/blueGrey";

import { Link as RouterLink } from "react-router-dom";

import PlaceholderIcon from "@mui/icons-material/AssessmentTwoTone";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import HeaderIcon from "@mui/icons-material/GetApp";
import CheckboxIconFilled from "@mui/icons-material/RadioButtonCheckedRounded";
import CheckboxIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchSearchSingleAssetOutput } from "../../redux/singleAssetOutput/actions";
import { selectorGetSingleAssetOutputById } from "../../redux/singleAssetOutput/selectors";
import { sortSingleAssetOutputJobCombinedReportsByCreatedDescending } from "../../redux/singleAssetOutputJob/selectors";
import { fetchSearchSummaryOutputTypes } from "../../redux/summaryOutputType/actions";
import { selectorGetSummaryOutputTypeById } from "../../redux/summaryOutputType/selectors";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { ShowMessage, ToTitleCase } from "../../utilities/Helpers";
import { FileTypeEnum } from "../../utilities/types/File";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { IOutputJobReport, OutputJobStatusEnum } from "../../utilities/types/SingleAssetOutputJob";
import { useFileSourceSingleHook } from "../file/Hooks";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchSingleAssetOutputJobCombinedReportPageHook } from "./Hooks";
import { GetOutputJobLinkByOutputId } from "../../routes/RouteLinkHelpers";
import { displayFormattedDate } from "../../utilities/formatter";

const DivWrapper = styled("div")(({ theme }) => ({
  minHeight: 500,
  "& .searchRoot": {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid #DEDEDE",
  },
  "& .creatorBar": {
    width: 5,
    height: "80%",
    marginLeft: theme.spacing(1),
    borderRadius: 28,
    background: HeaderColor[800],
  },
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: 28,
    margin: 4,
  },
  "& .rowsWrapper": {
    maxHeight: 400,
    overflowY: "auto",
  },
}));

type IOutputJobsEnquiryContainerProps = {
  canEdit: boolean;
};

const OutputJobsEnquiryContainer = ({ canEdit }: IOutputJobsEnquiryContainerProps) => {
  const [searchStatus, setSearchStatusInternal] = useState<OutputJobStatusEnum>();
  const [searchText, setSearchTextInternal] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  const { reports, fetching, morePages } = useFetchSingleAssetOutputJobCombinedReportPageHook({
    pageNumber: pageNumber,
    pageSize: 20,
    status: searchStatus,
    searchText,
  });

  function setSearchStatus(status?: OutputJobStatusEnum) {
    setSearchStatusInternal(status);
    setPageNumber(1);
  }

  function setSearchText(text?: string) {
    setSearchTextInternal(text);
    setPageNumber(1);
  }

  useEffect(() => {
    dispatch(fetchSearchSingleAssetOutput({ pageNumber: 1, pageSize: 30 }));
    dispatch(fetchSearchSummaryOutputTypes({ pageNumber: 1, pageSize: 30 }));
  }, [dispatch]);

  return (
    <OutputJobEnquiryContainerDisplay
      canEdit={canEdit}
      loading={fetching}
      solverJobReports={sortSingleAssetOutputJobCombinedReportsByCreatedDescending(reports as IOutputJobReport[])}
      onSetSearchStatus={setSearchStatus}
      onSetSearchText={setSearchText}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      hasMorePages={morePages}
    />
  );
};

type ISingleAssetOutputJobEnquiryContainerDisplayProps = {
  canEdit: boolean;
  solverJobReports: IOutputJobReport[];
  loading: boolean;
  onSetSearchStatus(newStatus?: OutputJobStatusEnum): void;
  onSetSearchText(newText?: string): void;
  pageNumber: number;
  setPageNumber(page: number): void;
  hasMorePages: boolean;
};

const OutputJobEnquiryContainerDisplay = ({
  solverJobReports,
  hasMorePages,
  setPageNumber,
  pageNumber,
  loading,
  onSetSearchStatus,
  onSetSearchText,
}: ISingleAssetOutputJobEnquiryContainerDisplayProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [statusFilter, setJobStatusToFilterBy] = useState<OutputJobStatusEnum>();
  const [searchText, setSearchTextFilter] = useState<string>();

  function updateJobStatusViaMenu(newStatus?: OutputJobStatusEnum) {
    setJobStatusToFilterBy(newStatus);
    setMenuAnchorEl(undefined);
    onSetSearchStatus(newStatus);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  function onSearchClick(e: any) {
    e.preventDefault();
    onSetSearchText(searchText);
    return false;
  }

  return (
    <WidgetSectionBase
      headerText="Task and Summary Outputs"
      subheaderText="Browse generated task and summary level outputs."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DivWrapper>
        <Paper component="form" className="searchRoot" onSubmit={onSearchClick}>
          <Button className="iconButton" aria-label="menu" onClick={handleMenuClick} size="small">
            {ToTitleCase(statusFilter ? OutputJobStatusEnum[statusFilter].toString() : "All")}
          </Button>
          <Divider className="divider" orientation="vertical" />
          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(undefined)}
          >
            <div style={{ paddingRight: 16, paddingLeft: 16 }}>
              <Typography variant="overline">Status Filters</Typography>
            </div>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(undefined)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === undefined} />
              </ListItemIcon>
              <ListItemText primary="None" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(OutputJobStatusEnum.Pending)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === OutputJobStatusEnum.Pending} />
              </ListItemIcon>
              <ListItemText primary="Pending" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(OutputJobStatusEnum.InProgress)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === OutputJobStatusEnum.InProgress} />
              </ListItemIcon>
              <ListItemText primary="In Progress" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(OutputJobStatusEnum.Failed)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === OutputJobStatusEnum.Failed} />
              </ListItemIcon>
              <ListItemText primary="Failed" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(OutputJobStatusEnum.Complete)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === OutputJobStatusEnum.Complete} />
              </ListItemIcon>
              <ListItemText primary="Complete" />
            </MenuItem>
          </Menu>
          <InputBase
            className="input"
            placeholder="Search Reports"
            inputProps={{ "aria-label": "Search Reports" }}
            onChange={onSearchTextChangeHandler}
            onSubmit={onSearchClick}
          />
          <Divider className="divider" orientation="vertical" />
          <IconButton className="iconButton" aria-label="search" onClick={onSearchClick}>
            <SearchIcon />
          </IconButton>
        </Paper>

        <div className="rowsWrapper">
          {loading ? (
            <LoaderAbsoluteCentred loading={loading} />
          ) : (
            <AnimationWrapper>
              <div>
                {solverJobReports.length ? (
                  solverJobReports.map((report, i) => <SolverJobReportRow key={i} report={report} />)
                ) : (
                  <div style={{ marginTop: 24 }}>
                    <WidgetNoResultsPlaceholder text="No reports" flip={true} icon={PlaceholderIcon} />
                  </div>
                )}
              </div>
            </AnimationWrapper>
          )}
        </div>
      </DivWrapper>
      <div style={{ textAlign: "center" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              onClick={() => setPageNumber(pageNumber - 1)}
              variant="outlined"
              fullWidth={true}
              {...(pageNumber === 1 ? { disabled: true } : {})}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => setPageNumber(pageNumber + 1)}
              variant="outlined"
              fullWidth={true}
              {...(!hasMorePages ? { disabled: true } : {})}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <LoaderAbsoluteCentred loading={loading} />
      </div>
    </WidgetSectionBase>
  );
};

function StatusFilterIcon({ checked }: { checked: boolean }) {
  return checked ? <CheckboxIconFilled fontSize="small" /> : <CheckboxIcon fontSize="small" />;
}

const GridWrapper = styled(Grid)(({ theme }) => ({
  border: "1px solid #EEE",
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  "& .image": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    borderRadius: `${theme.shape.borderRadius}px`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .imageWrapper": {
    width: "100%",
    height: "80%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
    maxWidth: "100%",
    left: 0,
    bottom: 0,
    top: 0,
    overflow: "hidden",
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& .reportContent": {
    marginLeft: theme.spacing(1),
  },
  "& .reportActions": {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  "& .imageGrid": {
    display: "flex",
    alignItems: "center",
  },
}));

export function SolverJobReportRow({ report }: { report: IOutputJobReport }) {
  const isSummaryReport = !!report.solverJobSummaryReportId;
  const failed = report.status === OutputJobStatusEnum.Failed;

  const outputTypeSummary = useSelector((store: RootState) =>
    selectorGetSummaryOutputTypeById(store, report.summaryOutputTypeId ?? "")
  );
  const outputTypeTask = useSelector((store: RootState) =>
    selectorGetSingleAssetOutputById(store, report.outputTypeId as string)
  );
  const singleAssetOutput = outputTypeSummary ?? outputTypeTask;

  const imageUrl = useFileSourceSingleHook({
    fileId: singleAssetOutput?.mainImageId ?? "",
    fileType: FileTypeEnum.Image,
  });

  const currentUserId = GetUserId();
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state, currentUserId));

  function addUrlToClipboardIfLocal() {
    if (report.localPath) {
      navigator.clipboard.writeText(report.preSignedUrl);
      ShowMessage("Path copied to clipboard", MessageTypeEnum.Success);
    }
  }

  return (
    <>
      <Link
        component={RouterLink}
        to={GetOutputJobLinkByOutputId(
          isSummaryReport ? (report.solverJobSummaryReportId as string) : (report.solverJobTaskReportId as string)
        )}
        style={{ textDecoration: "none" }}
      >
        <GridWrapper container>
          <Grid item xs={3} className="imageGrid">
            <div className="imageWrapper">
              <div className="image" style={{ backgroundImage: `url("${imageUrl}")` }} />
            </div>
            {isAdmin && report.createdBy === currentUserId ? <div className="creatorBar"></div> : null}
          </Grid>
          <Grid item xs={8}>
            <div className="reportContent">
              <Typography variant="body2">
                {isSummaryReport ? report.name : singleAssetOutput?.name} - {displayFormattedDate(report.created, "")}
              </Typography>

              <Chip
                label={isSummaryReport ? "Summary Report" : "Task Report"}
                variant="outlined"
                size="small"
                style={{ transform: "scale(0.85)", marginLeft: -4 }}
              />

              <Chip
                label={OutputJobStatusEnum[report.status]}
                variant="outlined"
                size="small"
                style={{ transform: "scale(0.85)", marginLeft: -4, color: failed ? "rgb(200,0,0)" : undefined }}
              />
            </div>
          </Grid>
          <Grid item xs={1} className="reportActions" onClick={(event) => event.stopPropagation()}>
            {report.status === OutputJobStatusEnum.Complete ? (
              <a
                href={report.preSignedUrl}
                onClick={addUrlToClipboardIfLocal}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </a>
            ) : null}
          </Grid>
        </GridWrapper>
      </Link>
    </>
  );
}

export default OutputJobsEnquiryContainer;
