import React, { useState } from "react";

import { Collapse, Card as MuiCard, Typography, styled, IconButton } from "@mui/material";
import { ImageWithFallback } from "../generic/ImageWithFallback";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetFileById } from "../../redux/file/selectors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import { ISolver } from "../../utilities/types/Solver";
import ModalSolverUpdate from "../solver/modals/ModalSolverUpdate";
import { selectorGetMaxSolverHistoryVersionNumber } from "../../redux/solverHistory/selectors";
import SolverDetailsDiscussionsDisplay from "./SolverDetailsDiscussions";
import { ISolverDetails } from "../../utilities/types/SolverDetails";

interface SolverDetailsCollapseCardProps {
  solver: ISolver;
  showCollapse: boolean;
  setShowCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  canEdit: boolean;
  solverDetails?: ISolverDetails;
}

const Card = styled(MuiCard)(({ theme }) => ({
  overflow: "unset",
  marginTop: `${theme.spacing(4)} !important`,
  ".inner-wrapper": {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: theme.spacing(2),
    flexWrap: "wrap",
    ".iconContainer": {
      position: "absolute",
      left: 0,
      top: "-28px",
      zIndex: 11,
      display: "grid",
      placeContent: "center",
      height: "75px",
      width: "75px",
      backgroundColor: "#37474f",
      borderRadius: "4px",
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      svg: {
        color: "#FFF",
        width: "1.3em",
        height: "1.3em",
      },
    },
    ".icon-btn": {
      backgroundColor: "#FFF",
      border: "1px solid #EEE",
    },
    ".content-wrapper": {
      cursor: "pointer",
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      gap: 16,
      flex: 1,
      flexShrink: 0,
      position: "relative",
      paddingLeft: theme.spacing(11),
    },
    ".right-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    ".validation-status": {
      display: "flex",
      alignItems: "center",
      gap: 2,
    },
  },
  ".card-img": {
    width: 75,
    height: 75,
    borderRadius: 3,
    objectFit: "cover",
  },
  ".collapse-wrapper": {
    ".MuiPaper-elevation": {
      boxShadow: "none",
    },
  },
}));

const SolverDetailsCollapseCard: React.FC<SolverDetailsCollapseCardProps> = ({
  solver,
  showCollapse,
  setShowCollapse,
  children,
  canEdit,
  solverDetails,
}) => {
  const state = useSelector((state: RootState) => state);

  const maxSolverHistoryVersionNumber = useSelector((store: RootState) =>
    selectorGetMaxSolverHistoryVersionNumber(store, solver ? solver.solverId : "")
  );
  const file = solver?.mainImageId ? selectorGetFileById(state, solver.mainImageId) : "";
  const [solverUpdateModalOpen, setSolverUpdateModalOpen] = useState(false);

  const latestModelHisotry = maxSolverHistoryVersionNumber;

  const toggleUpdateModal = () => setSolverUpdateModalOpen((prev) => !prev);
  const toggleCollapse = () => setShowCollapse((prev) => !prev);

  return (
    <Card>
      <div className="inner-wrapper">
        <div className="content-wrapper" onClick={toggleCollapse}>
          <div className="iconContainer">
            <ImageWithFallback className="card-img" src={file ? file?.preSignedUrl : ""} />
          </div>
          <div style={{ flex: 1 }}>
            <Typography fontWeight={600}>{solver.name}</Typography>
            {latestModelHisotry && (
              <Typography component="span" variant="body2" className="inline" color="textPrimary">
                Version {latestModelHisotry}
              </Typography>
            )}
          </div>
        </div>

        <div className="right-wrapper">
          <SolverDetailsDiscussionsDisplay solverDetails={solverDetails} />
          {canEdit && (
            <IconButton aria-label="edit" aria-controls="edit-menu" aria-haspopup="true" onClick={toggleUpdateModal}>
              <EditIcon />
            </IconButton>
          )}

          <IconButton aria-label="collapse" aria-controls="collapse-menu" aria-haspopup="true" onClick={toggleCollapse}>
            {showCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      <div>
        <Collapse className={showCollapse ? "collapse-wrapper" : ""} in={showCollapse}>
          {children}
        </Collapse>
      </div>

      <ModalSolverUpdate
        open={solverUpdateModalOpen}
        solver={solver}
        onCancelCallback={toggleUpdateModal}
        onCompleteCallback={toggleUpdateModal}
        canEdit={canEdit}
      />
    </Card>
  );
};

export default SolverDetailsCollapseCard;
