import { ActionTypesCostBundle, CostBundleState, ACTIONS_COST_BUNDLE } from "./types";

const initialState: CostBundleState = {
    byId: {},
}


export function costBundlesReducer(
    state = initialState,
    action: ActionTypesCostBundle
): CostBundleState {
    switch(action.type){
        case ACTIONS_COST_BUNDLE.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_COST_BUNDLE.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_COST_BUNDLE.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}