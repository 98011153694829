import React, { useState } from "react";
import { Box, Input, Button } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { IDiscussion } from "../../../utilities/types/Discussion";
import { fetchUpdateDiscussion, fetchDeleteDiscussion } from "../../../redux/discussion/actions";
import { EntityTypeEnum } from "../../../utilities/types/Entity";

interface FormValues {
  discussionId: string;
  title: string;
  text: string;
  entityType: EntityTypeEnum;
  entityId: string;
}

interface FormProps {
  discussion: IDiscussion;
  onCompleteCallback(discussion?: IDiscussion): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IDiscussion, AnyAction>;
  canEdit: boolean;
}

const FormDiscussionUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    discussion: { discussionId },
    canEdit,
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, discussion } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedDiscussion = await dispatch(
      fetchUpdateDiscussion({
        ...values,
        discussionId: discussion.discussionId,
        entityId: discussion.entityId,
      })
    );

    setSubmitting(false);
    if (updatedDiscussion) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedDiscussion);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.discussion,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        const { dispatch } = formProps;

        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteDiscussion({ discussionId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <Box sx={{ boxShadow: 3, margin: "10px 0 8px 0", padding: 0, borderRadius: 2, position: "relative" }}>
            <form onSubmit={props.handleSubmit}>
              <input type="hidden" value={discussionId} name="discussionId" />

              <div>
                <Input
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "title", "Title")}
                  fullWidth
                  style={{ padding: 8 }}
                />

                <Input
                  onChange={props.handleChange}
                  multiline
                  rows={4}
                  fullWidth
                  style={{ padding: 8 }}
                  {...getFormikFieldProps(props, "text", "Text")}
                />

                <div style={{ display: "flex", alignItems: "center", gap: 8, padding: "12px" }}>
                  {canEdit && (
                    <>
                      <Button
                        type="submit"
                        disabled={submitting}
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: "8px" }}
                      >
                        Update
                      </Button>
                      <Button
                        style={{ borderRadius: "8px" }}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={toggleDeleteConfirmation}
                      >
                        Delete
                      </Button>
                    </>
                  )}

                  <Button disabled={submitting} size="small" variant="text" onClick={onCancelCallback}>
                    Close
                  </Button>

                  <LoaderAbsoluteCentred loading={submitting || deleting} />
                  <WidgetModalConfirmationDialog
                    open={showDeleteConfirmation}
                    title="Delete discussion"
                    subtitle="Confirm discussion delete"
                    description="Are you sure that you'd like to remove this discussion?"
                    onCancelCallback={toggleDeleteConfirmation}
                    onConfirmCallback={deleteHandler}
                    confirmButtonText="Delete"
                  />
                </div>
              </div>
            </form>
          </Box>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          title: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(200, "Please input 200 characters or less")
            .required("Please provide a name"),
          text: Yup.string().label("Text").max(500, "Please input 2500 characters or less"),
        });
      }}
    />
  );
};

export default FormDiscussionUpdate;
