import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/ReceiptTwoTone";
import FormDecisionUpdate from "../forms/FormDecisionUpdate";
import { IDecision } from "../../../utilities/types/Decision";

export interface IModalDecisionUpdateProps {
  open: boolean;
  decision: IDecision;
  onCancelCallback(): void;
  onCompleteCallback(decision?: IDecision): void;
  canEdit: boolean;
}

function ModalDecisionUpdate({ onCancelCallback, open, onCompleteCallback, decision }: IModalDecisionUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update cause"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormDecisionUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          decision={decision}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalDecisionUpdate;
