import { FormControl, FormHelperText, Grid, InputLabel, TextField } from "@mui/material";
import React, { useCallback } from "react";
import { useSplitDateTime, useUpdateSplitDateTime } from "../../utilities/inputFields/dateTimeFieldUtils";
import { IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";

interface IAssetInputFieldDisplayNumberProps {
  assetInputField: IAssetInputField;
  readOnly?: boolean;
  onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
  defaultValueOverride?: TAssetInputFieldValue;
  label?: string;
  error?: string;
}

export default function AssetInputFieldDisplayDateTime({
  assetInputField,
  defaultValueOverride,
  onValueChangeCallback,
  label,
  readOnly = false,
  error,
}: IAssetInputFieldDisplayNumberProps) {
  const onDateChange = useCallback(
    (valueDateTime: any) => onValueChangeCallback(assetInputField.assetInputFieldId, { valueDateTime }),
    [assetInputField.assetInputFieldId, onValueChangeCallback]
  );

  const defaultValue = defaultValueOverride?.valueDateTime || assetInputField.defaultDateTime;
  const [dateState, timeState, setDate, setTime, innerError] = useUpdateSplitDateTime(defaultValue, onDateChange);

  // If the field is display then show the default value
  const [dateDisplay, timeDisplay] = useSplitDateTime(defaultValue);
  const date = readOnly ? dateDisplay : dateState;
  const time = readOnly ? timeDisplay : timeState;

  return (
    <FormControl fullWidth>
      <InputLabel style={{ position: "relative", transform: "translate(0, 1.5px) scale(0.75)" }}>
        {label || assetInputField.description}
      </InputLabel>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            type="date"
            value={date}
            onChange={(e) => setDate(e.currentTarget.value)}
            error={!!innerError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={readOnly}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            type="time"
            value={time}
            onChange={(e) => setTime(e.currentTarget.value)}
            error={!!error}
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }}
            disabled={readOnly}
            variant="standard"
          />
        </Grid>
      </Grid>
      {innerError && <FormHelperText error>{innerError}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}
