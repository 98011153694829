import React from "react";
import { ISolverInputField, SolverInputFieldTypeEnum } from "../../utilities/types/SolverInputField";
import { TSolverInputFieldValue } from "../../utilities/types/SolverJob";
import SolverInputFieldDisplayDateTime from "./SolverInputFieldDisplayDateTime";
import SolverInputFieldDisplayNumber from "./SolverInputFieldDisplayNumber";
import SolverInputFieldDisplayString from "./SolverInputFieldDisplayString";
import SolverInputFieldDisplayEnumeration from "./SolverInputFieldEnumeration";


interface ISolverInputFieldDisplayProps {
    solverInputField: ISolverInputField,
    disabled?: boolean,
    onValueChangeCallback(fieldId: string, value: TSolverInputFieldValue): void,
    overriddenDefaultValue?: TSolverInputFieldValue,
}

function SolverInputFieldDisplay(props: ISolverInputFieldDisplayProps) {
    return (
        <DynamicTypeField {...props} />
    )
}


function DynamicTypeField(props: ISolverInputFieldDisplayProps) {
    switch (props.solverInputField.type) {
        case SolverInputFieldTypeEnum.Number:
            return <SolverInputFieldDisplayNumber {...props} />;
        case SolverInputFieldTypeEnum.CustomEnumeration:
            return <SolverInputFieldDisplayEnumeration {...props} />;
        case SolverInputFieldTypeEnum.String:
            return <SolverInputFieldDisplayString {...props} />;
        case SolverInputFieldTypeEnum.DateTime:
            return <SolverInputFieldDisplayDateTime {...props} />;
        default:
            return <>Invalid field type</>;
    }
}


export default SolverInputFieldDisplay;