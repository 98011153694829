import { ISolverHistory } from "../../utilities/types/SolverHistory";


export const ACTIONS_MODEL_HISTORY = {
    INVALIDATE: 'INVALIDATE_MODEL_HISTORIES',
    RECEIVE: 'RECEIVE_MODEL_HISTORIES',
    DELETE: 'DELETE_MODEL_HISTORY',
    UPDATE: 'UPDATE_MODEL_HISTORIES',
}

interface ActionSolverHistoriesInvalidate {
    type: typeof ACTIONS_MODEL_HISTORY.INVALIDATE,
    byId: { [key: string]: ISolverHistory },
}

interface ActionSolverHistoriesReceive {
    type: typeof ACTIONS_MODEL_HISTORY.RECEIVE
    byId: { [key: string]: ISolverHistory },
}

export interface SolverHistoryState {
    byId: { [key: string]: ISolverHistory },
}

export enum SolverHistorySearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
    VersionNumberAsc = 30,
    VersionNumberDesc = 40,
}

export type ActionTypesSolverHistory = ActionSolverHistoriesReceive | ActionSolverHistoriesInvalidate;