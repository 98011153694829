import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { ISolverOutputTypeMapping } from "../../utilities/types/SolverOutputTypeMapping";
import { ISingleAssetOutput } from "../../utilities/types/SingleAssetOutput";
import { ListItemAvatar, Avatar, Typography, styled } from "@mui/material";
import { FileTypeEnum } from "../../utilities/types/File";
import { useFileSourceSingleHook } from "../file/Hooks";

const GridWrapper = styled(Grid)(({ theme }) => ({
  margin: "auto",
  "& .paper": {
    width: 400,
    height: 230,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
  },
  "& .button": {
    margin: theme.spacing(0.5, 0),
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
  },
  "& .listLeft": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listRight": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
}));

function not(a: ISingleAssetOutput[], b: ISingleAssetOutput[]) {
  return a.filter((value) => !b.some((c) => c.outputTypeId === value.outputTypeId));
}

function getSingleAssetOutputsByMappings(
  singleAssetOutputs: ISingleAssetOutput[],
  mappings: ISolverOutputTypeMapping[]
) {
  return singleAssetOutputs.filter((x) => mappings.some((y) => y.outputTypeId === x.outputTypeId));
}

interface ISolverSingleAssetOutputsPickListProps {
  solverId: string;
  onSelectedItemsChange(mappings: ISingleAssetOutput[]): void;
  singleAssetOutputs: ISingleAssetOutput[];
  outputTypeMappings: ISolverOutputTypeMapping[];
  leftLabel?: string;
  rightLabel?: string;
}

export default function SolverSingleAssetOutputsPickList({
  singleAssetOutputs,
  outputTypeMappings,
  onSelectedItemsChange,
  leftLabel,
  rightLabel,
}: ISolverSingleAssetOutputsPickListProps) {
  const [right, setRight] = useState<ISingleAssetOutput[]>(
    getSingleAssetOutputsByMappings(singleAssetOutputs, outputTypeMappings)
  );
  const [left, setLeft] = useState<ISingleAssetOutput[]>(not(singleAssetOutputs, right));
  const [assignedMappingsCount, setAssignedMappingsCount] = useState<number>(outputTypeMappings.length);
  const [outputTypesCount, setOutputTypesCount] = useState<number>(singleAssetOutputs.length);

  function selectItem(clickedItem: ISingleAssetOutput, rightItems: boolean) {
    if (rightItems) {
      let mergedItems = [...left, clickedItem];
      setLeft(mergedItems);
      setRight(not(singleAssetOutputs, mergedItems));
    } else {
      let mergedItems = [...right, clickedItem];
      setRight(mergedItems);
      setLeft(not(singleAssetOutputs, mergedItems));
    }
  }

  // Allow parent component to hook into current items
  useEffect(() => {
    onSelectedItemsChange(right);
  }, [right, onSelectedItemsChange]);

  // Ensure that mappings not initially available are still catered for
  useEffect(() => {
    if (outputTypeMappings.length !== assignedMappingsCount || singleAssetOutputs.length !== outputTypesCount) {
      setAssignedMappingsCount(outputTypeMappings.length);
      setOutputTypesCount(singleAssetOutputs.length);

      var rightOutputTypes = getSingleAssetOutputsByMappings(singleAssetOutputs, outputTypeMappings);
      setRight(rightOutputTypes);
      setLeft(not(singleAssetOutputs, rightOutputTypes));
    }
  }, [outputTypeMappings, assignedMappingsCount, singleAssetOutputs, outputTypesCount]);

  const customList = (items: ISingleAssetOutput[], right: boolean) => (
    <Paper className={`paper ${right ? "listRight" : "listLeft"}`}>
      <List dense component="div" role="list" style={{ paddingTop: 0 }}>
        {items.map((value) => {
          return <CustomListItem singleAssetOutput={value} onClick={() => selectItem(value, right)} />;
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <GridWrapper container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="overline">{leftLabel || "Unlinked"}</Typography>
        {customList(left, false)}
      </Grid>
      <Grid item>
        <Typography variant="overline">{rightLabel || "Linked"}</Typography>
        {customList(right, true)}
      </Grid>
    </GridWrapper>
  );
}

function CustomListItem({ singleAssetOutput, onClick }: { singleAssetOutput: ISingleAssetOutput; onClick(): void }) {
  const labelId = `transfer-list-item-${singleAssetOutput.outputTypeId}-label`;
  const imageUrl = useFileSourceSingleHook({
    fileId: singleAssetOutput && singleAssetOutput.mainImageId ? singleAssetOutput.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  return (
    <ListItem key={singleAssetOutput.outputTypeId} role="listitem" button onClick={onClick} className="listItem">
      <ListItemAvatar>
        <Avatar alt={singleAssetOutput.name} src={imageUrl} />
      </ListItemAvatar>
      <ListItemText id={labelId} primary={singleAssetOutput.name} primaryTypographyProps={{ noWrap: true }} />
    </ListItem>
  );
}
