import { ICostBundle } from "../../utilities/types/CostBundle";


export const ACTIONS_COST_BUNDLE = {
    INVALIDATE: 'INVALIDATE_COST_BUNDLES',
    RECEIVE: 'RECEIVE_COST_BUNDLES',
    DELETE: 'DELETE_COST_BUNDLE',
    UPDATE: 'UPDATE_COST_BUNDLES',
}

interface ActionCostBundlesInvalidate {
    type: typeof ACTIONS_COST_BUNDLE.INVALIDATE,
    byId: { [key: string]: ICostBundle },
}

interface ActionCostBundlesReceive {
    type: typeof ACTIONS_COST_BUNDLE.RECEIVE
    byId: { [key: string]: ICostBundle },
}

export interface CostBundleState {
    byId: { [key: string]: ICostBundle },
}

export enum CostBundleSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesCostBundle = ActionCostBundlesReceive | ActionCostBundlesInvalidate;