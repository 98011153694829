import { RootState } from "..";
import { ISolverInputFieldListValue } from "../../utilities/types/SolverInputFieldListValue";


export function selectorGetSolverInputFieldListValuesBySolverId(state: RootState, solverId: string) : ISolverInputFieldListValue[] {

    if (!state.solverInputFieldListValues || !state.solverInputFieldListValues.byId) return [];

    return sortSolverInputFieldListValuesByOrderNumberAscending(Object.values(state.solverInputFieldListValues.byId).filter(x => x.solverId === solverId));
}


export function selectorGetSolverInputFieldListValuesBySolverInputFieldId(state: RootState, solverInputFieldId: string) : ISolverInputFieldListValue[] {

    if (!state.solverInputFieldListValues || !state.solverInputFieldListValues.byId) return [];

    return sortSolverInputFieldListValuesByOrderNumberAscending(Object.values(state.solverInputFieldListValues.byId).filter(x => x.solverInputFieldId === solverInputFieldId));
}


export function selectorGetSolverInputFieldListValueById(state: RootState, solverInputFieldListValueId: string) : ISolverInputFieldListValue | undefined {

    if (!state.solverInputFieldListValues || !state.solverInputFieldListValues.byId) return undefined;

    return state.solverInputFieldListValues.byId[solverInputFieldListValueId];
}


// function sortSolverInputFieldListValuesByOrderNumberDescending(solverInputFieldListValues: ISolverInputFieldListValue[]) {
//     return solverInputFieldListValues.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortSolverInputFieldListValuesByOrderNumberAscending(solverInputFieldListValues: ISolverInputFieldListValue[]) {
    return solverInputFieldListValues.sort((a, b) => a.orderNumber - b.orderNumber);
}