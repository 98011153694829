import * as React from "react";
import NotFoundImg from "../../assets/img/image-not-found.png";

export interface ImageWithFallbackProps extends React.HTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string;
  alt?: string;
  src: string;
}

export function ImageWithFallback({ src, alt, fallbackSrc = NotFoundImg, ...rest }: ImageWithFallbackProps) {
  const [currentSrc, setCurrentSrc] = React.useState<string>(fallbackSrc);

  const onError = () => setCurrentSrc(fallbackSrc);

  React.useEffect(() => {
    setCurrentSrc(src);
  }, [src]);

  return <img src={currentSrc} loading="lazy" onError={onError} alt={alt || "image"} {...rest} />;
}
