import { RootState } from "..";
import { IUserRole } from "../../utilities/types/UserRole";

export function selectorGetUserRoleById(state: RootState, userRoleId: string) : IUserRole | undefined {

    if (!state.userRoles || !state.userRoles.byId) return undefined;

    return state.userRoles.byId[userRoleId];
}
export function selectorGetUserRoles(state: RootState) : IUserRole[] {

    if (!state.userRoles || !state.userRoles.byId) return [];

    return sortUserRolesByNameDescending(Object.values(state.userRoles.byId));
}

function sortUserRolesByNameDescending(userRoles: IUserRole[]) {
    return userRoles.sort((a, b) => {
        return a.userRoleDescription === b.userRoleDescription ? 0 : a.userRoleDescription < b.userRoleDescription ? -1 : 1;
    });
}