import { ACTIONS_COMMENT, CommentSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IComment } from '../../utilities/types/Comment';
import { EntityTypeEnum } from '../../utilities/types/Entity';


export const receiveComments = (comments: IComment[]) => {

    var byIdObjectToDispatch: { [key: string]: IComment } = {};

    for (var i = 0; i < comments.length; i++) {
        byIdObjectToDispatch[comments[i].commentId] = comments[i];
    }

    return {
        type: ACTIONS_COMMENT.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteComment = (comment: IComment) => ({
    type: ACTIONS_COMMENT.DELETE,
    byId: { [comment.commentId]: comment }
});


export interface IFetchCreateCommentProps {
    discussionId: string,
    parentId: string,
    text: string,
    entityId: string,
    entityType: EntityTypeEnum,
}


export const fetchCreateComment = (commentToCreate: IFetchCreateCommentProps): AppThunk<Promise<IComment>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/comments`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(commentToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.comments) {
            dispatch(receiveComments(parsedResp.data.comments));
            return parsedResp.data.comments;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating comment.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating comment.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateCommentProps {
    commentId: string,
    text: string,
}


export const fetchUpdateComment = (props: IFetchUpdateCommentProps): AppThunk<Promise<IComment>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/comments`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.comments) {
            dispatch(receiveComments(parsedResp.data.comments));
            return parsedResp.data.comments;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating comment.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating comment.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchCommentsProps {
    pageNumber: number,
    pageSize: number,
    commentId?: string,
    discussionId?: string,
    text?: string,
    createdBy?: string,
    parentId?: string,
    orderType?: CommentSearchOrderTypeEnum,
}


export const fetchSearchComments = (searchParams: IFetchSearchCommentsProps): AppThunk<Promise<IComment[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/comments`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.comments) {
            dispatch(receiveComments(parsedResp.data.comments));
            return parsedResp.data.comments;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching comment.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching comment.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteCommentProps {
    commentId: string,
}


export const fetchDeleteComment = (props: IFetchDeleteCommentProps): AppThunk<Promise<IComment>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/comments`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.comments && parsedResp.data.comments.length) {
            dispatch(receiveComments(parsedResp.data.comments));
            return parsedResp.data.comments[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting comment.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting comment.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}