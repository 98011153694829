import { FormControl, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { ISummaryOutputTypeInputField } from "../../utilities/types/SummaryOutputTypeInputField";
import { TSummaryOutputTypeInputFieldValue } from "../../utilities/types/SummaryOutputType";

interface ISummaryOutputTypeInputFieldDisplayStringProps {
  summaryOutputTypeInputField: ISummaryOutputTypeInputField;
  disabled?: boolean;
  onValueChangeCallback(fieldId: string, value: TSummaryOutputTypeInputFieldValue): void;
  overriddenDefaultValue?: TSummaryOutputTypeInputFieldValue;
}

export default function SummaryOutputTypeInputFieldDisplayString({
  summaryOutputTypeInputField,
  overriddenDefaultValue,
  onValueChangeCallback,
  disabled = false,
}: ISummaryOutputTypeInputFieldDisplayStringProps) {
  const defaultValue = overriddenDefaultValue?.valueString || summaryOutputTypeInputField.defaultString;

  const [error, setError] = useState("");
  const [value, setValue] = useState(defaultValue);

  function changeValue(e: any) {
    var valueString = e.target?.value;
    setValue(valueString); // Allow empty values

    if (valueString) {
      setError("");
      onValueChangeCallback(summaryOutputTypeInputField.summaryOutputTypeInputFieldId, { valueString });
    } else setError("A string is required.");
  }

  return (
    <FormControl fullWidth>
      <InputLabel style={{ position: "relative", transform: "translate(0, 1.5px) scale(0.75)" }}>
        {summaryOutputTypeInputField.description}
      </InputLabel>
      <TextField
        type="text"
        value={disabled ? defaultValue : value}
        onChange={changeValue}
        error={!!error}
        helperText={error}
        fullWidth
        {...(disabled === true ? { disabled } : null)}
        variant="standard"
      />
    </FormControl>
  );
}
