import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import HeaderIcon from "@mui/icons-material/InputTwoTone";
import { DialogActions, Button } from "@mui/material";
import ModalSolverInputFieldCreate from "./ModalSolverInputFieldCreate";
import SolverInputFieldsList from "../SolverInputFieldsList";

export interface IModalSolverInputFieldsProps {
  open: boolean;
  onCloseCallback(): void;
  solverId: string;
  canEdit: boolean;
}

function ModalSolverInputFields({ onCloseCallback, open, solverId, canEdit }: IModalSolverInputFieldsProps) {
  const [showSolverInputFieldCreateModal, setShowSolverInputFieldCreateModal] = useState(false);

  function toggleShowSolverInputFieldCreateModal() {
    setShowSolverInputFieldCreateModal(!showSolverInputFieldCreateModal);
  }

  return (
    <WidgetModalBase
      handleCancel={onCloseCallback}
      open={open}
      title={"Manage Solver Fields"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <SolverInputFieldsList solverId={solverId} canEdit={canEdit} />
      </DialogContent>
      <DialogActions>
        <Button  variant="outlined" style={{ display: "flex", flexGrow: 1 }} onClick={onCloseCallback}>
          Close
        </Button>
        <Button
          color="primary"
          variant="outlined"
          style={{ display: "flex", flexGrow: 1 }}
          onClick={toggleShowSolverInputFieldCreateModal}
        >
          Add Field
        </Button>
      </DialogActions>

      <ModalSolverInputFieldCreate
        open={showSolverInputFieldCreateModal}
        onCancelCallback={toggleShowSolverInputFieldCreateModal}
        onCompleteCallback={toggleShowSolverInputFieldCreateModal}
        solverId={solverId}
      />
    </WidgetModalBase>
  );
}

export default ModalSolverInputFields;
