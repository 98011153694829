import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/AssignmentTwoTone";
import FormTaskUpdate from "../forms/FormTaskUpdate";
import { ITask } from "../../../utilities/types/Task";

export interface IModalTaskUpdateProps {
  open: boolean;
  task: ITask;
  onCancelCallback(): void;
  onCompleteCallback(task?: ITask): void;
  canEdit: boolean;
}

function ModalTaskUpdate({ onCancelCallback, open, onCompleteCallback, task, canEdit }: IModalTaskUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update task"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormTaskUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          task={task}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalTaskUpdate;
