import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchPlansProps, fetchSearchPlans } from "../../redux/plan/actions";
import { IPlan } from "../../utilities/types/Plan";


interface IUseFetchPlansPageHookProps extends IFetchSearchPlansProps {
    minPageNumberToFetch: number;
}


export const useFetchPlansPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchPlansPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IPlan[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IPlan[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var plans = await dispatch(fetchSearchPlans({
                    pageSize,
                    pageNumber,
                    modelId,
                })) as unknown as IPlan[];

                if (plans && plans.length) {
                    setMorePages(plans.length >= pageSize)
                    setLastResultSet(plans);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.planId === x.planId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}