import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import FormSingleAssetOutputUpdate from "../forms/FormSingleAssetOutputUpdate";
import { ISingleAssetOutput } from "../../../utilities/types/SingleAssetOutput";
import MapIcon from "@mui/icons-material/MapRounded";
import { RootState } from "../../../redux";
import { selectorGetSingleAssetOutputById } from "../../../redux/singleAssetOutput/selectors";
import { fetchSearchSingleAssetOutput } from "../../../redux/singleAssetOutput/actions";
import FileRendererSingle from "../../file/renderers/FileRendererSingle";
import { FileTypeEnum } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

export interface IModalOutputTypeUpdateProps {
  open: boolean;
  singleAssetOutput: ISingleAssetOutput;
  onCancelCallback(): void;
  onCompleteCallback(singleAssetOutput?: ISingleAssetOutput): void;
  canEdit: boolean;
}

function ModalOutputTypeUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  singleAssetOutput,
  canEdit,
}: IModalOutputTypeUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a singleAssetOutput"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <RenderImage outputTypeId={singleAssetOutput.outputTypeId} canEdit={canEdit} />
        <FormSingleAssetOutputUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          singleAssetOutput={singleAssetOutput}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

interface IRenderImageProps {
  outputTypeId: string;
  canEdit: boolean;
}

function RenderImage({ outputTypeId, canEdit }: IRenderImageProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const identifier = useSelector((store: RootState) => selectorGetSingleAssetOutputById(store, outputTypeId));

  if (!identifier) return null;

  const onUploadComplete = async () => {
    setLoading(true);
    await dispatch(
      fetchSearchSingleAssetOutput({
        outputTypeId,
        pageNumber: 1,
        pageSize: 1,
      })
    );
    setLoading(false);
  };

  return (
    <div style={{ borderRadius: 4, position: "relative" }}>
      <FileRendererSingle
        fileId={identifier.mainImageId || ""}
        canUpload={canEdit}
        fileType={FileTypeEnum.Image}
        entityId={String(identifier.outputTypeId)}
        entityType={EntityTypeEnum.SingleAssetOutput}
        height={150}
        onUploadComplete={onUploadComplete}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </div>
  );
}

export default ModalOutputTypeUpdate;
