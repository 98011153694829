import { ACTIONS_ASSET_INPUT_FIELD, AssetInputFieldSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IAssetInputField, AssetInputFieldTypeEnum } from '../../utilities/types/AssetInputField';


export const receiveAssetInputFields = (assetInputFields: IAssetInputField[]) => {

    var byIdObjectToDispatch: { [key: string]: IAssetInputField } = {};

    for (var i = 0; assetInputFields.length && i < assetInputFields.length; i++) {
        byIdObjectToDispatch[assetInputFields[i].assetInputFieldId] = assetInputFields[i];
    }

    return {
        type: ACTIONS_ASSET_INPUT_FIELD.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteAssetInputField = (assetInputField: IAssetInputField) => ({
    type: ACTIONS_ASSET_INPUT_FIELD.DELETE,
    byId: { [assetInputField.assetInputFieldId]: assetInputField }
});


export interface IFetchCreateAssetInputFieldProps {
    assetInputFieldCategoryId: string,
    modelId: string,
    description?: string,
    defaultValue: number,
    minValue?: number,
    maxValue?: number,
    orderNumber: number,
    type: AssetInputFieldTypeEnum,
}

export interface IFetchCreateAssetInputFieldsProps {
    assetInputFields: IFetchCreateAssetInputFieldProps[]
}


export const fetchCreateAssetInputField = (assetInputFieldsToCreate: IFetchCreateAssetInputFieldsProps): AppThunk<Promise<IAssetInputField[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/assetInputFields`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(assetInputFieldsToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFields) {
            dispatch(receiveAssetInputFields(parsedResp.data.assetInputFields));
            return parsedResp.data.assetInputFields;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateAssetInputFieldProps {
    assetInputFieldId: string,
    label: string,
    description?: string,
    defaultValue?: number,
    defaultString?: string,
    defaultDateTime?: Date,
    minValue?: number,
    maxValue?: number,
    decimalPlaces?: number,
    orderNumber: number,
    type: AssetInputFieldTypeEnum,
}


export const fetchUpdateAssetInputField = (props: IFetchUpdateAssetInputFieldProps): AppThunk<Promise<IAssetInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/assetInputFields`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputField) {
            dispatch(receiveAssetInputFields([parsedResp.data.assetInputField]));
            return parsedResp.data.assetInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchAssetInputFieldsProps {
    pageNumber: number,
    pageSize: number,
    modelId?: string,
    text?: string,
    createdBy?: string,
    orderType?: AssetInputFieldSearchOrderTypeEnum,
}


export const fetchSearchAssetInputFields = (searchParams: IFetchSearchAssetInputFieldsProps): AppThunk<Promise<IAssetInputField[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/assetInputFields`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputFields) {
            dispatch(receiveAssetInputFields(parsedResp.data.assetInputFields));
            return parsedResp.data.assetInputFields;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching input field.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error Searching input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteAssetInputFieldProps {
    assetInputFieldId: string,
}


export const fetchDeleteAssetInputField = (props: IFetchDeleteAssetInputFieldProps): AppThunk<Promise<IAssetInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/assetInputFields`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.assetInputField) {
            dispatch(requestDeleteAssetInputField(parsedResp.data.assetInputField));
            return parsedResp.data.assetInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}