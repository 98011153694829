import { RootState } from "..";
import { IUserToRoleMapping } from "../../utilities/types/UserToRoleMapping";


export function selectorGetUserToRoleMappingByUserDetailId(state: RootState, userDetailsId: string) : IUserToRoleMapping[] {

    if (!state.userToRoleMappings || !state.userToRoleMappings.byId) return [];

    return Object.values(state.userToRoleMappings.byId).filter(x => x.userDetailId === userDetailsId);
}
