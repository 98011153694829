import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/LabelTwoTone";
import FormIdentifierCreate from "../forms/FormIdentifierCreate";
import { IIdentifier } from "../../../utilities/types/Identifier";

export interface IModalIdentifierCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId?: string;
  onCompleteCallback(identifier?: IIdentifier): void;
}

function ModalIdentifierCreate({ onCancelCallback, open, onCompleteCallback, modelId }: IModalIdentifierCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create new identifier(s)"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormIdentifierCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalIdentifierCreate;
