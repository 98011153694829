import { Auth } from 'aws-amplify';
import { useSelector } from "react-redux";
import { receiveFiles } from "../redux/file/actions";
import store, { RootState } from '../redux/index';
import { receiveSystemState } from "../redux/systemState/actions";
import { receiveUserDetails } from "../redux/userDetail/actions";
import { receiveUserPermissions } from "../redux/userPermission/actions";
import { MessageTypes, StorageKeys } from "./Constants";
import { GetCookieValue, GetLocalStorageItem, SetLocalStorageItem, ShowMessage } from './Helpers';
import { IApiResponse } from "./types/Api";
import { IFile } from "./types/File";
import Message from "./types/Message";
import { ACTIONS_INSUFFICIENT_PERMISSION_MAPPING } from "../redux/insufficientPermission/types";


export const SetUsername = (username: string) => {
    return SetLocalStorageItem(StorageKeys.USERNAME, username);
}

export const GetUsername = function () {
    return GetLocalStorageItem(StorageKeys.USERNAME);
}

/* Show an exception as an error message */
export const ShowExceptionAsMessage = (e: unknown) => {

    if (e) {
        console.error(e);
    }

    return ShowMessage("An error has occurred.", MessageTypes.ERROR);
}


/* Retrieve the current user's id */
export const GetUserId = function () {

    if (store.getState()?.system?.userId) {
        return store.getState()?.system?.userId;
    }
    else {

        var userId = GetLocalStorageItem(StorageKeys.USER_ID);
        if (userId) {
            store.dispatch(receiveSystemState({
                ...store.getState().system,
                userId,
            }));
        }

        return userId;
    }
}

export function useUserId() {
    const userId = useSelector((s: RootState) => s?.system?.userId)
    return userId
}

export function useIsLoggedIn() {
    const userId = useUserId()
    return !!userId
}


/* Processes the response and updates/displays messages if required */
export const CheckStatus = async (response: any) => {

    // Ensure that the user's session hasn't expired
    if (response && response.status === 401) {
        ShowMessage("Please login.", MessageTypes.WARNING);
        return response;
    }

    // If there are no headers return the resp
    if (!response.headers) {
        return response;
    }

    // Just return the response if there's no value to parse
    var contentType = response.headers.get("content-type");
    if (!contentType || contentType.indexOf("application/json") === -1) {
        return response;
    }

    // This is a json response so we can parse any messages that we receive
    var data: IApiResponse = await response.json();

    // Hydrate files if provided
    if (data && data.data && data.data.files && data.data.files.length) {
        store.dispatch(receiveFiles(data.data.files as IFile[]));
    }

    // Hydrate user permissions if provided
    if (data && data.data && data.data.userPermissions && data.data.userPermissions.length) {
        store.dispatch(receiveUserPermissions(data.data.userPermissions));
    }

    // Hydrate user details if provided
    if (data && data.data && data.data.userDetails && data.data.userDetails.length) {
        store.dispatch(receiveUserDetails(data.data.userDetails));
    }

    // Handle messages if provided
    if (data && data.messages && data.messages.length && data.messages.length > 0) {

        data.messages.forEach((message: Message) => {

            switch (message.type) {
                case 0:
                    ShowMessage(message.text, MessageTypes.ERROR);
                    break;

                case 1:
                    ShowMessage(message.text, MessageTypes.SUCCESS);
                    break;

                case 2:
                    ShowMessage(message.text, MessageTypes.INFORMATION);
                    break;

                case 3:
                    ShowMessage(message.text, MessageTypes.WARNING);
                    break;

                case 4:
                    store.dispatch({
                        type: ACTIONS_INSUFFICIENT_PERMISSION_MAPPING.SET,
                        message: message.text,
                      });
                    break;

                default:
                    ShowMessage(message.text, MessageTypes.INFORMATION);
                    console.error("Unexpected message type.");
                    break;
            }
        });
    }

    // Set user details if provided
    if (data && data.userId && data.userId !== "00000000-0000-0000-0000-000000000000") SetUserId(data.userId);
    if (data && data.username) SetUsername(data.username);

    // Resolve and return data
    return data;
}

/* Clears all local storage etc. IMPORTANT: Do not redirect here, services use this for resetting state before setting auth etc. */
export const Logout = () => {
    EraseCookie(StorageKeys.ACCESS_TOKEN);
    EraseCookie(StorageKeys.EXPIRES_AT);
    EraseCookie(StorageKeys.USER_ID);
    DeleteLocalStorageItem(StorageKeys.USERNAME);
    DeleteLocalStorageItem(StorageKeys.EXPIRES_AT);
    DeleteLocalStorageItem(StorageKeys.USER_ID);
    DeleteLocalStorageItem(StorageKeys.ACCESS_TOKEN);
    localStorage.clear();
}


export const DeleteLocalStorageItem = (key: string) => {
    localStorage.removeItem(key);
}


/* https://stackoverflow.com/a/5886746/522859 */
export const EraseCookie = (name: string) => {

    // This function will attempt to remove a cookie from all paths.
    var pathBits = window.location.pathname.split('/');
    var pathCurrent = ' path=';

    // Do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (var i = 0; i < pathBits.length; i++) {
        pathCurrent += ((pathCurrent.substr(-1) !== '/') ? '/' : '') + pathBits[i];
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
}


/* Sets the current user's id */
export const SetUserId = (userId: string) => {

    var resp = SetLocalStorageItem(StorageKeys.USER_ID, userId);
    store.dispatch(receiveSystemState(
        { ...store.getState().system, userId }
    ));

    return resp;
}


/* Get default headers for requests */
export const    GetDefaultHeaders = async (includeAuth = true, includeJsonContentType = true, includeTextContentType = false) => {

    var headers = new Headers();

    if (includeAuth) {
        const session = await Auth.currentSession().catch(() => null)
        if (session) headers.append("Authorization", "Bearer " + session.getIdToken()?.getJwtToken());
    }

    if (includeJsonContentType) {
        headers.append("Content-Type", "application/json");
    }

    if (includeTextContentType) {
        headers.append("Content-Type", "text/plain");
    }

    return headers;
}


/* Retrieves the user's access token */
export const GetAccessToken = function () {
    return GetCookieValue(StorageKeys.ACCESS_TOKEN);
}


/* Add gets params to a url */
export const AddQueryStringsToUrl = (url: string, params: { [key: string]: any }) => {

    if (!params) return url;

    let concatenatorTouse = "?";

    for (var key of Object.keys(params)) {
        // Ensure that the key has a value
        if (params[key]) {
            url += `${concatenatorTouse}${key}=${params[key]}`;
            concatenatorTouse = "&";
        }
    }

    return url;
}