import { ActionTypesSingleAssetOutput, SingleAssetOutputState, ACTIONS_SINGLE_ASSET_OUTPUT } from "./types";

const initialState: SingleAssetOutputState = {
  byId: {},
};

export function singleAssetOutputReducer(
  state = initialState,
  action: ActionTypesSingleAssetOutput
): SingleAssetOutputState {
  switch (action.type) {
    case ACTIONS_SINGLE_ASSET_OUTPUT.INVALIDATE: {
      return {
        ...initialState,
      };
    }
    case ACTIONS_SINGLE_ASSET_OUTPUT.DELETE:
      let currentById = Object.assign({}, state.byId);
      delete currentById[Object.keys(action.byId)[0]];

      return {
        ...state,
        byId: currentById,
      };
    case ACTIONS_SINGLE_ASSET_OUTPUT.RECEIVE: {
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
      };
    }
    default:
      return state;
  }
}
