import { ACTIONS_WORKFLOW_TYPE } from "./types";
import { AppThunk } from "..";
import { Configuration } from "../../utilities/Constants";
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from "../../utilities/ApiUtils";
import { PrepareBody, ShowError, ShowMessage } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { IWorkflow } from "../../utilities/types/Workflow";
import { MessageTypeEnum } from "../../utilities/types/Message";

const WORKFLOW_BASE_ENDPOINT = `${Configuration.BASE_API_URL}/workflows`;

export const receiveWorkflow = (workflow: IWorkflow[]) => {
  var byIdObjectToDispatch: { [key: string]: IWorkflow } = {};

  for (var i = 0; i < workflow.length; i++) {
    byIdObjectToDispatch[workflow[i].workflowId] = workflow[i];
  }

  return {
    type: ACTIONS_WORKFLOW_TYPE.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteWorkflow = (workflow: IWorkflow) => ({
  type: ACTIONS_WORKFLOW_TYPE.DELETE,
  byId: { [workflow.workflowId]: workflow },
});

export interface IFetchCreateWorkflowProps {
  clientName: string;
  workflowName: string;
  clientBaseBucket: string;
}

export const fetchCreateWorkflow =
  (workflowToCreate: IFetchCreateWorkflowProps): AppThunk<Promise<IWorkflow>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(WORKFLOW_BASE_ENDPOINT, {
        method: "POST",
        headers: headers,
        body: PrepareBody(workflowToCreate),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.workflows) {
        dispatch(receiveWorkflow(parsedResp.data.workflows));
        return parsedResp.data.workflows;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating workflow.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating workflow.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchUpdateWorkflowProps extends IFetchCreateWorkflowProps {
  workflowId: string;
  workflowObject?: string | JSON | Object;
}

export const fetchUpdateWorkflow =
  (props: IFetchUpdateWorkflowProps): AppThunk<Promise<IWorkflow | null>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(WORKFLOW_BASE_ENDPOINT, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.workflows) {
        dispatch(receiveWorkflow(parsedResp.data.workflows));
        return parsedResp.data.workflows;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error updating workflow.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error updating workflow.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchDeleteWorkflowProps {
  workflowId: string;
}

export const fetchDeleteWorkflow =
  (props: IFetchDeleteWorkflowProps): AppThunk<Promise<IWorkflow>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(WORKFLOW_BASE_ENDPOINT, props), {
        method: "DELETE",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (
        parsedResp &&
        parsedResp.success &&
        parsedResp.data &&
        parsedResp.data.workflows &&
        parsedResp.data.workflows.length
      ) {
        dispatch(requestDeleteWorkflow(parsedResp.data.workflows[0]));
        return parsedResp.data.workflows[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting workflow.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting workflow.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchWorkflowProps {
  page: number;
  maxPageSize: number;
  workflowName?: string;
  WorfklowId?: string;
  clientName?: string;
}

export const fetchSearchWorkflows =
  (searchParams: IFetchSearchWorkflowProps): AppThunk<Promise<IWorkflow[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(WORKFLOW_BASE_ENDPOINT, searchParams), {
        method: "GET",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.workflows) {
        dispatch(receiveWorkflow(parsedResp.data.workflows));
        return parsedResp.data.workflows;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching workflows.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching workflows.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

export interface IFetchRunWorkflowProps {
  workflowId: string;
}

export const fetchRunWorkflow =
  (props: IFetchRunWorkflowProps): AppThunk<Promise<null | undefined>> =>
  async () => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${WORKFLOW_BASE_ENDPOINT}/run`, props), {
        method: "POST",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success) {
        ShowMessage("Success", MessageTypeEnum.Success);
        return null;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error run workflow.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error run workflow.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return null;
    }
  };
