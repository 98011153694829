import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormSolverCreate from "../forms/FormSolverCreate";
import { ISolver } from "../../../utilities/types/Solver";
import MapIcon from "@mui/icons-material/MapRounded";

export interface IModalSolverCreateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(file?: ISolver): void;
}

function ModalSolverCreate({ onCancelCallback, open, onCompleteCallback }: IModalSolverCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new solver"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormSolverCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSolverCreate;
