import { ACTIONS_MODEL_HISTORY, ModelHistorySearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IModelHistory } from '../../utilities/types/ModelHistory';


export const receiveModelHistories = (modelHistories: IModelHistory[]) => {

    var byIdObjectToDispatch: { [key: string]: IModelHistory } = {};

    for (var i = 0; i < modelHistories.length; i++) {
        byIdObjectToDispatch[modelHistories[i].modelHistoryId] = modelHistories[i];
    }

    return {
        type: ACTIONS_MODEL_HISTORY.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteModelHistory = (modelHistory: IModelHistory) => ({
    type: ACTIONS_MODEL_HISTORY.DELETE,
    byId: { [modelHistory.modelHistoryId]: modelHistory }
});


export interface IFetchCreateModelHistoryProps {
    title: string,
    description?: string,
    versionNumber: number,
    modelId: string,
}


export const fetchCreateModelHistory = (modelHistoryToCreate: IFetchCreateModelHistoryProps): AppThunk<Promise<IModelHistory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/modelHistories`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(modelHistoryToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.modelHistory) {
            dispatch(receiveModelHistories([parsedResp.data.modelHistory]));
            return parsedResp.data.modelHistory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while creating history.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateModelHistoryProps {
    title: string,
    description?: string,
    versionNumber: number,
    modelHistoryId: string,
}


export const fetchUpdateModelHistory = (props: IFetchUpdateModelHistoryProps): AppThunk<Promise<IModelHistory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/modelHistories`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.modelHistory) {
            dispatch(receiveModelHistories([parsedResp.data.modelHistory]));
            return parsedResp.data.modelHistory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating history.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchModelHistoriesProps {
    pageNumber: number,
    pageSize: number,
    modelId?: string,
    text?: string,
    minimumVersionNumber?: number,
    createdBy?: string,
    orderType?: ModelHistorySearchOrderTypeEnum,
}


export const fetchSearchModelHistories = (searchParams: IFetchSearchModelHistoriesProps): AppThunk<Promise<IModelHistory[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/modelHistories`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.modelHistories) {
            dispatch(receiveModelHistories(parsedResp.data.modelHistories));
            return parsedResp.data.modelHistories;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching history.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteModelHistoryProps {
    modelHistoryId: string,
}


export const fetchDeleteModelHistory = (props: IFetchDeleteModelHistoryProps): AppThunk<Promise<IModelHistory>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/modelHistories`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.modelHistory) {
            dispatch(requestDeleteModelHistory(parsedResp.data.modelHistory));
            return parsedResp.data.modelHistory;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting history.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting history.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}