import { ActionTypesUserContributions, ActionUserContributionsReceive, UserContributionsState, ACTIONS_USER_CONTRIBUTIONS } from "./types";

const initialState: UserContributionsState = {
    byId: {},
    totalContributions: 0,
}

export function userContributionsReducer(
    state = initialState,
    action: ActionTypesUserContributions
): UserContributionsState {
    switch (action.type) {
        case ACTIONS_USER_CONTRIBUTIONS.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_USER_CONTRIBUTIONS.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
                totalContributions: (action as ActionUserContributionsReceive).totalContributions,
                }
            }
        case ACTIONS_USER_CONTRIBUTIONS.DELETE: {
            
            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        }
        default:
            return state;
    }
}