import { IconButton, ListItemButton, Paper, TextField, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import React, { useEffect, useMemo, useState } from "react";
import { IIdentifier } from "../../../utilities/types/Identifier";
import useDebouncedSearch from "../../hooks/useDebouncedSearch";
import { usePinnedIdentifiersHook } from "./usePinnedIdentifiers.hook";
import PushPinOutlined from "@mui/icons-material/PushPinOutlined";
import PushPin from "@mui/icons-material/PushPin";
import blue from "@mui/material/colors/blue";

const GridWrapper = styled(Grid)(({ theme }) => ({
  margin: "auto",
  width: "100%",

  "& .paper": {
    height: 230,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listContainer": {
    flex: 1,
    minWidth: 200,
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
    "& .listItem-button": {
      padding: theme.spacing(1),
    },
  },
}));

interface IdentifierMappingPicklistProps {
  identifiers: IIdentifier[];
  onChange: (selectedIdentifiers: IIdentifier[]) => void;
}

function not(a: IIdentifier[], b: IIdentifier[]) {
  return a.filter((value) => !b.some((c) => c === value));
}

export default function IdentifierMappingPicklist({ identifiers, onChange }: IdentifierMappingPicklistProps) {
  const [selected, setSelected] = useState<IIdentifier[]>([]);
  const available = not(identifiers, selected);
  const { searchText, handleChange } = useDebouncedSearch();
  const { ids: pinnedIds, addRemovePinnedId } = usePinnedIdentifiersHook();

  // Allow the parent to receive updates
  useEffect(() => void onChange(selected), [onChange, selected]);

  const selectItem = (clickedItem: IIdentifier) => setSelected([...selected, clickedItem]);
  const deselectItem = (clickedItem: IIdentifier) =>
    setSelected(selected.filter((x) => x.identifierId !== clickedItem.identifierId));

  const customList = (items: IIdentifier[], onClick: (_: IIdentifier) => void) => {
    return (
      <Paper className="paper">
        <List dense component="div" role="list">
          {items.map((identifier) => {
            return (
              <ListItem
                key={identifier.code}
                className="listItem"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="pin"
                    size="small"
                    style={
                      pinnedIds.includes(identifier.identifierId)
                        ? {
                            rotate: "0deg",
                            color: blue[600],
                          }
                        : { rotate: "30deg" }
                    }
                    onClick={() => addRemovePinnedId(identifier.identifierId)}
                  >
                    {pinnedIds.includes(identifier.identifierId) ? (
                      <PushPin fontSize="small" />
                    ) : (
                      <PushPinOutlined fontSize="small" />
                    )}
                  </IconButton>
                }
                role="listitem"
                disablePadding
              >
                <ListItemButton className="listItem-button" onClick={() => onClick(identifier)}>
                  <ListItemText primary={identifier.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  function sortByPinnedIds(list: IIdentifier[], pinnedIds: string[]) {
    return list.sort((a, b) => {
      const indexA = pinnedIds.indexOf(a.identifierId);
      const indexB = pinnedIds.indexOf(b.identifierId);

      if (indexA === -1 && indexB === -1) {
        return 0;
      }
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    });
  }

  const filteredAvailableList = useMemo(() => {
    let filteredList = available;
    let search = searchText && searchText?.trim() ? searchText : "";
    if (search) {
      filteredList = filteredList.filter((avalOpt: IIdentifier) =>
        avalOpt.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );
    }

    if (pinnedIds.length > 0) {
      filteredList = sortByPinnedIds(filteredList, pinnedIds);
    }
    return filteredList;
  }, [available, searchText, pinnedIds]);

  const selectedList = useMemo(() => {
    if (pinnedIds.length === 0) return selected;

    return sortByPinnedIds(selected, pinnedIds);
  }, [selected, pinnedIds]);

  return (
    <>
      <GridWrapper container spacing={2} alignItems="center">
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            id="outlined-search"
            label="Search Available Options"
            type="search"
            size="small"
            variant="standard"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item className="listContainer">
          <Typography variant="overline">Available</Typography>
          {customList(filteredAvailableList, selectItem)}
        </Grid>
        <Grid item className="listContainer">
          <Typography variant="overline">Selected</Typography>
          {customList(selectedList, deselectItem)}
        </Grid>
      </GridWrapper>
    </>
  );
}
