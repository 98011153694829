import React from "react";
import { Paper as MuiPaper, Typography, styled } from "@mui/material";
import HeaderColor from "@mui/material/colors/blueGrey";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";

const Paper = styled(MuiPaper)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  height: "100%",
  "& .iconContainer": {
    position: "absolute",
    left: theme.spacing(2),
    top: `-${theme.spacing(2)}`,
    height: 75,
    width: 75,
    backgroundColor: HeaderColor[800],
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    "& svg": {
      color: "#FFF",
      fontSize: 30,
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    zIndex: 1,
  },
  "& .fullWidthHeader": {
    right: theme.spacing(2),
    width: "auto",
    "& svg": {
      left: theme.spacing(6),
    },
  },
  "& .headerText": {
    textAlign: "right",
  },
  "& .buttonContainer": {
    paddingTop: theme.spacing(2),
    textAlign: "right",
    paddingRight: 0,
  },
}));

type WidgetSummaryBaseProps = {
  children: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerColor?: string;
  fullWidthHeader?: boolean;
  style?: { [key: string]: any };
  captionText?: string;
};

const WidgetSummaryBase = ({
  children,
  style,
  headerIcon = <UpdateOutlinedIcon />,
  headerColor = HeaderColor[800],
  fullWidthHeader = false,
  captionText,
}: WidgetSummaryBaseProps) => {
  return (
    <Paper style={style}>
      <div
        className={`iconContainer ${fullWidthHeader ? "fullWidthHeader" : ""}`}
        style={{ backgroundColor: headerColor }}
      >
        {headerIcon}
      </div>
      <div className="headerText">
        <Typography variant="caption" style={{ opacity: 0.75, marginBottom: 0 }}>
          {captionText}
        </Typography>
      </div>
      {children}
    </Paper>
  );
};

export default WidgetSummaryBase;
