import { IModelHistory } from "../../utilities/types/ModelHistory";


export const ACTIONS_MODEL_HISTORY = {
    INVALIDATE: 'INVALIDATE_MODEL_HISTORIES',
    RECEIVE: 'RECEIVE_MODEL_HISTORIES',
    DELETE: 'DELETE_MODEL_HISTORY',
    UPDATE: 'UPDATE_MODEL_HISTORIES',
}

interface ActionModelHistoriesInvalidate {
    type: typeof ACTIONS_MODEL_HISTORY.INVALIDATE,
    byId: { [key: string]: IModelHistory },
}

interface ActionModelHistoriesReceive {
    type: typeof ACTIONS_MODEL_HISTORY.RECEIVE
    byId: { [key: string]: IModelHistory },
}

export interface ModelHistoryState {
    byId: { [key: string]: IModelHistory },
}

export enum ModelHistorySearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
    VersionNumberAsc = 30,
    VersionNumberDesc = 40,
}

export type ActionTypesModelHistory = ActionModelHistoriesReceive | ActionModelHistoriesInvalidate;