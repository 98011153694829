import { ACTIONS_DECISION, DecisionSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { DecisionActionTypeEnum, IDecision } from '../../utilities/types/Decision';


export const receiveDecisions = (decisions: IDecision[]) => {

    var byIdObjectToDispatch: { [key: string]: IDecision } = {};

    for (var i = 0; i < decisions.length; i++) {
        byIdObjectToDispatch[decisions[i].decisionId] = decisions[i];
    }

    return {
        type: ACTIONS_DECISION.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteDecision = (decision: IDecision) => ({
    type: ACTIONS_DECISION.DELETE,
    byId: { [decision.decisionId]: decision }
});


export interface IFetchCreateDecisionProps {
    modelId: string,
    name: string,
    description: string,
    orderNumber: number,
    actionType: DecisionActionTypeEnum,
}


export const fetchCreateDecision = (decisionToCreate: IFetchCreateDecisionProps): AppThunk<Promise<IDecision>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/decisions`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(decisionToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisions) {
            dispatch(receiveDecisions(parsedResp.data.decisions));
            return parsedResp.data.decisions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating decision.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateDecisionProps {
    decisionId: string,
    name: string,
    description: string,
    orderNumber: number,
}


export const fetchUpdateDecision = (props: IFetchUpdateDecisionProps): AppThunk<Promise<IDecision>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/decisions`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisions) {
            dispatch(receiveDecisions(parsedResp.data.decisions));
            return parsedResp.data.decisions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating decision.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchDecisionsProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    decisionId?: string,
    text?: string,
    createdBy?: string,
    orderType?: DecisionSearchOrderTypeEnum,
}


export const fetchSearchDecisions = (searchParams: IFetchSearchDecisionsProps): AppThunk<Promise<IDecision[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/decisions`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisions) {
            dispatch(receiveDecisions(parsedResp.data.decisions));
            return parsedResp.data.decisions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching decision.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteDecisionProps {
    decisionId: string,
}


export const fetchDeleteDecision = (props: IFetchDeleteDecisionProps): AppThunk<Promise<IDecision>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/decisions`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.decisions && parsedResp.data.decisions.length) {
            dispatch(requestDeleteDecision(parsedResp.data.decisions[0]));
            return parsedResp.data.decisions[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting decision.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}