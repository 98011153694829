import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import InputIcon from "@mui/icons-material/ReceiptTwoTone";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import FormCostBundleDuplicate from "../forms/FormCostBundleDuplicate";

export interface IModalCostBundleUpdateProps {
  open: boolean;
  costBundle: ICostBundle;
  onCancelCallback(): void;
  onCompleteCallback(): void;
}

function ModalCostBundleDuplicate({
  onCancelCallback,
  open,
  onCompleteCallback,
  costBundle,
}: IModalCostBundleUpdateProps) {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Duplicate a cost bundle(s)"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormCostBundleDuplicate
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          costBundle={costBundle}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalCostBundleDuplicate;
