import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import RouteJob from "./RouteJob";
import PageJobCreate from "../components/jobs/pages/PageJobCreate";
import LayoutDefault from "../components/layout/LayoutDefault";
import PageJobsEnquiry from "../components/jobs/pages/PageJobsEnquiry";

const RouteJobs = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/create/:modelId`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageJobCreate {...props} />
          </LayoutDefault>
        )}
      />
      <Route path={`${match.path}/:jobId`}>
        <RouteJob />
      </Route>
      <Route
        path={`${match.path}`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageJobsEnquiry {...props} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteJobs;
