import React from "react";
import { ISolverJob, SolverJobStatusEnum } from "../../utilities/types/SolverJob";
import { green, red, lightBlue, grey, blueGrey } from "@mui/material/colors";
import HeaderIcon from "@mui/icons-material/DonutLarge";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, Tooltip, Legend);

type IJobsStatusChart = {
  solverJobs: ISolverJob[];
  loading: boolean;
};

const JobsStatusChart = ({ solverJobs }: IJobsStatusChart) => {
  const jobData = {
    labels: ["Pending", "Progress", "Failed", "Complete", "Cancelled"],
    datasets: [
      {
        data: [
          solverJobs.filter((x) => x.status === SolverJobStatusEnum.Pending).length,
          solverJobs.filter((x) => x.status === SolverJobStatusEnum.Progress).length,
          solverJobs.filter((x) => x.status === SolverJobStatusEnum.Failed).length,
          solverJobs.filter((x) => x.status === SolverJobStatusEnum.Complete).length,
          solverJobs.filter((x) => x.status === SolverJobStatusEnum.Cancelled).length,
        ],
        backgroundColor: [blueGrey[300], lightBlue[300], red[300], green[300], grey[300]],
        hoverBackgroundColor: [blueGrey[500], lightBlue[500], red[500], green[500], grey[500]],
      },
    ],
  };

  var noJobsData = {
    labels: ["No results"],
    datasets: [
      {
        data: [1],
        backgroundColor: [grey[300]],
        hoverBackgroundColor: [grey[500]],
      },
    ],
  };

  return (
    <WidgetSectionBase
      headerText="Status"
      subheaderText="Status of all recent Solver Jobs."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={false}
    >
      <Doughnut
        data={solverJobs.length ? jobData : noJobsData}
        options={{
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        }}
      />
    </WidgetSectionBase>
  );
};

export default JobsStatusChart;
