import React, { useState } from "react";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemText, Typography, Divider, styled } from "@mui/material";

import { RootState } from "../../redux";
import { selectorGetModelHistoriesByModelId } from "../../redux/modelHistory/selectors";
import ModelHistoryUpdateModal from "./modals/ModalModelHistoryUpdate";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { ScrollPretty } from "../../utilities/Styles";
import { IModelHistory } from "../../utilities/types/ModelHistory";

const SectionWrapper = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2),
  position: "relative",
  ...ScrollPretty,
  "& .inline": {
    display: "inline",
  },
  "& .listItem": {
    transition: "background-color 0.3s ease-out",
    backgroundColor: "rgba(0,0,0,0.001)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.03)",
    },
    "&:active": {
      cursor: "pointer",
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  "& .list": {
    height: 100,
    overflowY: "scroll",
    textTransform: "capitalize",
    opacity: 0.8,
    border: "1px solid #EEE",
    position: "relative",
  },
  "& .truncateText": {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .opacityWrapper": {
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 0,
    height: 30,
    zIndex: 2,
    background: "linear-gradient(179deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9))",
  },
}));

type ModelHistoryListProps = {
  modelId: string;
  canEdit: boolean;
};

const ModelHistoryList = ({ modelId, canEdit }: ModelHistoryListProps) => {
  const modelHistories = useSelector((state: RootState) => selectorGetModelHistoriesByModelId(state, modelId));
  const [modelHistoryToUpdate, setModelHistoryToUpdate] = useState<IModelHistory>();

  return (
    <SectionWrapper>
      <List className="list">
        {modelHistories.length === 0 && (
          <Typography variant="caption" component="p" style={{ textAlign: "center" }}>
            No history
          </Typography>
        )}
        {modelHistories.map((modelHistory) => {
          return (
            <AnimationWrapper key={modelHistory.modelId}>
              <div>
                <ListItem onClick={() => setModelHistoryToUpdate(modelHistory)} className="listItem">
                  <ListItemText
                    className="truncateText"
                    primary={modelHistory.title}
                    secondary={
                      <div className="truncateText">
                        <Typography component="span" variant="body2" className="inline" color="textPrimary">
                          Version {modelHistory.versionNumber}
                        </Typography>
                        {modelHistory.description ? (
                          <Typography variant="body2" component="span" color="textSecondary">
                            {" "}
                            — {modelHistory.description}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                  />
                </ListItem>
                <Divider light={true} variant="middle" />
              </div>
            </AnimationWrapper>
          );
        })}
      </List>
      <div className="opacityWrapper">&nbsp;</div>
      {modelHistoryToUpdate ? (
        <ModelHistoryUpdateModal
          open={modelHistoryToUpdate !== undefined}
          onCancelCallback={() => setModelHistoryToUpdate(undefined)}
          onCompleteCallback={() => setModelHistoryToUpdate(undefined)}
          modelHistory={modelHistoryToUpdate}
          canEdit={canEdit}
        />
      ) : null}
    </SectionWrapper>
  );
};

export default ModelHistoryList;
