import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormTaskApplicabilityUpdate from "../forms/FormTaskApplicabilityUpdate";
import { ITaskApplicability } from "../../../utilities/types/TaskApplicability";
import HeaderIcon from "@mui/icons-material/ArtTrackTwoTone";

export interface IModalTaskApplicabilityUpdateProps {
  open: boolean;
  taskApplicability: ITaskApplicability;
  onCancelCallback(): void;
  onCompleteCallback(taskApplicability?: ITaskApplicability): void;
  canEdit: boolean;
}

function ModalTaskApplicabilityUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  taskApplicability,
}: IModalTaskApplicabilityUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update an applicability"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent>
        <FormTaskApplicabilityUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          taskApplicability={taskApplicability}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalTaskApplicabilityUpdate;
