import DialogContent from "@mui/material/DialogContent";
import BuildIcon from "@mui/icons-material/LabelTwoTone";
import React from "react";
import { useDispatch } from "react-redux";
import { IdentifierMappingTypeEnum, IIdentifierMapping } from "../../../utilities/types/IdentifierMapping";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormIdentifierMappingCreate from "../forms/FormIdentifierMappingCreate";

export interface IModalIdentifierMappingCreateProps {
  open: boolean;
  onCancelCallback(): void;
  identifierMappingType: IdentifierMappingTypeEnum;
  targetId: string;
  modelId: string;
  secondaryTargetId?: string;
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
}

function ModalIdentifierMappingCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  identifierMappingType,
  targetId,
  secondaryTargetId,
}: IModalIdentifierMappingCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Link an identifier"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
      style={{ maxWidth: 800 }}
    >
      <DialogContent style={{ paddingTop: 0, paddingBottom: 16 }} onClick={(e) => e.stopPropagation()}>
        <FormIdentifierMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          targetIds={[targetId]}
          secondaryTargetId={secondaryTargetId}
          type={identifierMappingType}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalIdentifierMappingCreate;
