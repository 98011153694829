import { RootState } from "..";
import { IEvent } from "../../utilities/types/Event";


export function selectorGetEventsByModelId(state: RootState, modelId: string) : IEvent[] {

    if (!state.events || !state.events.byId) return [];

    return sortEventsByNameAscending(Object.values(state.events.byId).filter(x => x.modelId === modelId));
}


export function selectorGetEventById(state: RootState, eventId: string) : IEvent | undefined {

    if (!state.events || !state.events.byId) return undefined;

    return state.events.byId[eventId];
}


function sortEventsByNameAscending(events: IEvent[]) {
    return events.sort((a, b) => b.name > a.name  ? -1 : 1);
}