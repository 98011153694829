import { ISolverInputFieldListValue } from "../../utilities/types/SolverInputFieldListValue";


export const ACTIONS_SOLVER_INPUT_FIELD_LIST_VALUE = {
    INVALIDATE: 'INVALIDATE_SOLVER_INPUT_FIELD_LIST_VALUES',
    RECEIVE: 'RECEIVE_SOLVER_INPUT_FIELD_LIST_VALUES',
    DELETE: 'DELETE_SOLVER_INPUT_FIELD_LIST_VALUE',
    UPDATE: 'UPDATE_SOLVER_INPUT_FIELD_LIST_VALUES',
}

interface ActionSolverInputFieldListValuesInvalidate {
    type: typeof ACTIONS_SOLVER_INPUT_FIELD_LIST_VALUE.INVALIDATE,
    byId: { [key: string]: ISolverInputFieldListValue },
}

interface ActionSolverInputFieldListValuesReceive {
    type: typeof ACTIONS_SOLVER_INPUT_FIELD_LIST_VALUE.RECEIVE
    byId: { [key: string]: ISolverInputFieldListValue },
}

export interface SolverInputFieldListValueState {
    byId: { [key: string]: ISolverInputFieldListValue },
}

export enum SolverInputFieldListValueSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverInputFieldListValue = ActionSolverInputFieldListValuesReceive | ActionSolverInputFieldListValuesInvalidate;