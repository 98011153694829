import { ACTIONS_TASK, TaskSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ITask } from '../../utilities/types/Task';


export const receiveTasks = (tasks: ITask[]) => {

    var byIdObjectToDispatch: { [key: string]: ITask } = {};

    for (var i = 0; i < tasks.length; i++) {
        byIdObjectToDispatch[tasks[i].taskId] = tasks[i];
    }

    return {
        type: ACTIONS_TASK.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteTask = (task: ITask) => ({
    type: ACTIONS_TASK.DELETE,
    byId: { [task.taskId]: task }
});


export interface IFetchCreateTaskProps {
    modelId: string,
    name: string,
    description: string,
    rampTime: number,
    duration: number,
    orderNumber: number,
}
export interface IFetchCreateTasksProps {
    tasks: IFetchCreateTaskProps[]
}


export const fetchCreateTask = (tasksToCreate: IFetchCreateTasksProps): AppThunk<Promise<ITask>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/tasks`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(tasksToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.tasks && parsedResp.data.tasks.length) {
            dispatch(receiveTasks(parsedResp.data.tasks));
            return parsedResp.data.tasks[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating task.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating task.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateTaskProps {
    taskId: string,
    name: string,
    description: string,
    rampTime: number,
    duration: number,
    orderNumber: number,
}


export const fetchUpdateTask = (props: IFetchUpdateTaskProps): AppThunk<Promise<ITask>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/tasks`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.tasks && parsedResp.data.tasks.length) {
            dispatch(receiveTasks(parsedResp.data.tasks));
            return parsedResp.data.tasks[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating task.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating task.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchTasksProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    taskId?: string,
    text?: string,
    createdBy?: string,
    orderType?: TaskSearchOrderTypeEnum,
}


export const fetchSearchTasks = (searchParams: IFetchSearchTasksProps): AppThunk<Promise<ITask[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/tasks`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.tasks && parsedResp.data.tasks) {
            dispatch(receiveTasks(parsedResp.data.tasks));
            return parsedResp.data.tasks;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching task.");
            }

            return [];
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching task.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteTaskProps {
    taskId: string,
}


export const fetchDeleteTask = (props: IFetchDeleteTaskProps): AppThunk<Promise<ITask>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/tasks`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.tasks && parsedResp.data.tasks.length) {
            dispatch(requestDeleteTask(parsedResp.data.tasks[0]));
            return parsedResp.data.tasks[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting task.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting task.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}