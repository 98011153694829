import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useSelector } from "react-redux";
import HeaderIcon from "@mui/icons-material/InputTwoTone";
import { ISummaryOutputTypeInputField } from "../../../utilities/types/SummaryOutputTypeInputField";
import { DialogActions, Button, Grid, IconButton, Typography } from "@mui/material";
import ModalSummaryOutputTypeInputFieldCreate from "./ModalSummaryOutputTypeInputFieldCreate";
import { selectorGetSummaryOutputTypeInputFieldsBySummaryOutputTypeId } from "../../../redux/summaryOutputTypeInputField/selectors";
import { RootState } from "../../../redux";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import NoResultsIcon from "@mui/icons-material/InputTwoTone";
import { useFetchSummaryOutputTypeInputFieldsPageHook } from "../Hooks";
import SummaryOutputTypeInputFieldDisplay from "../SummaryOutputTypeInputFieldDisplay";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import EditIcon from "@mui/icons-material/EditTwoTone";
import ModalSummaryOutputTypeInputFieldUpdate from "./ModalSummaryOutputTypeInputFieldUpdate";
import { useFetchSummaryOutputTypeInputFieldListValuesPageHook } from "../../summaryOutputTypeInputFieldListValue/Hooks";

export interface IModalSummaryOutputTypeInputFieldsProps {
  open: boolean;
  onCloseCallback(): void;
  summaryOutputTypeId: string;
  canEdit: boolean;
}

function ModalSummaryOutputTypeInputFields({
  onCloseCallback,
  open,
  summaryOutputTypeId,
  canEdit,
}: IModalSummaryOutputTypeInputFieldsProps) {
  const [showSummaryOutputTypeInputFieldCreateModal, setShowSummaryOutputTypeInputFieldCreateModal] = useState(false);
  const summaryOutputTypeInputFields = useSelector((store: RootState) =>
    selectorGetSummaryOutputTypeInputFieldsBySummaryOutputTypeId(store, summaryOutputTypeId)
  );
     // Group the fields by `Tablename`
     const groupedFields = summaryOutputTypeInputFields.reduce((groups, field) => {
      const group = groups[field.tableName] || [];
      group.push(field);
      groups[field.tableName] = group;
      return groups;
    }, {} as { [Tablename: string]: ISummaryOutputTypeInputField[] });

  const [summaryOutputTypeInputFieldToUpdate, setSummaryOutputTypeInputFieldToUpdate] =
    useState<ISummaryOutputTypeInputField>();

  const { fetching: fetchingFields } = useFetchSummaryOutputTypeInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 100,
    minPageNumberToFetch: 1,
    summaryOutputTypeId,
  });

  const { fetching: fetchingFieldListValues } = useFetchSummaryOutputTypeInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    minPageNumberToFetch: 1,
    summaryOutputTypeId,
  });

  function toggleShowSummaryOutputTypeInputFieldCreateModal() {
    setShowSummaryOutputTypeInputFieldCreateModal(!showSummaryOutputTypeInputFieldCreateModal);
  }

  function closeEditModal() {
    setSummaryOutputTypeInputFieldToUpdate(undefined);
  }

  return (
    <WidgetModalBase
      handleCancel={onCloseCallback}
      open={open}
      title={"Manage Summary Output Type Fields"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
      <Grid container spacing={1}>
          {!summaryOutputTypeInputFields.length ? (
            <Grid item xs={12}>
              <WidgetNoResultsPlaceholder
                icon={NoResultsIcon}
                text="No summary output type fields"
                fade={true}
                spin={true}
              />
            </Grid>
          ) : null}

          {
          Object.entries(groupedFields).map(([Tablename, fields]) => (
            <Grid item xs={12} >
            <div key={Tablename}>
            <Typography variant="overline" component="p" style={{ marginBottom: 8 }}>
              {Tablename}
            </Typography>
            <Grid container spacing={1}>
              {fields.map((summaryOutputTypeInputField) => (
                <React.Fragment key={summaryOutputTypeInputField.label}>
                  <Grid item xs={9} >
                  <SummaryOutputTypeInputFieldDisplay
                    summaryOutputTypeInputField={summaryOutputTypeInputField}
                    disabled={true}
                    onValueChangeCallback={() => {}}
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                    onClick={() => setSummaryOutputTypeInputFieldToUpdate(summaryOutputTypeInputField)}
                    style={{ border: "1px solid #EEE", marginBottom: -40 }}
                  >
                    <EditIcon style={{ fontSize: 15, opacity: 0.7 }} />
                  </IconButton>
                  </Grid>
                  </React.Fragment>
              ))}
              </Grid>
            </div>
            </Grid>
          ))
          }

          <LoaderAbsoluteCentred loading={fetchingFields || fetchingFieldListValues} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button  variant="outlined" style={{ display: "flex", flexGrow: 1 }} onClick={onCloseCallback}>
          Close
        </Button>
        <Button
          color="primary"
          variant="outlined"
          style={{ display: "flex", flexGrow: 1 }}
          onClick={toggleShowSummaryOutputTypeInputFieldCreateModal}
        >
          Add Field
        </Button>
      </DialogActions>

      <ModalSummaryOutputTypeInputFieldCreate
        open={showSummaryOutputTypeInputFieldCreateModal}
        onCancelCallback={toggleShowSummaryOutputTypeInputFieldCreateModal}
        onCompleteCallback={toggleShowSummaryOutputTypeInputFieldCreateModal}
        summaryOutputTypeId={summaryOutputTypeId}
      />

      {summaryOutputTypeInputFieldToUpdate !== undefined ? (
        <ModalSummaryOutputTypeInputFieldUpdate
          open={true}
          onCancelCallback={closeEditModal}
          onCompleteCallback={closeEditModal}
          summaryOutputTypeInputField={summaryOutputTypeInputFieldToUpdate}
          canEdit={canEdit}
        />
      ) : null}
    </WidgetModalBase>
  );
}

export default ModalSummaryOutputTypeInputFields;
