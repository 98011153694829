import { IEvent } from "../../utilities/types/Event";


export const ACTIONS_EVENT = {
    INVALIDATE: 'INVALIDATE_EVENTS',
    RECEIVE: 'RECEIVE_EVENTS',
    DELETE: 'DELETE_EVENT',
    UPDATE: 'UPDATE_EVENTS',
}

interface ActionEventsInvalidate {
    type: typeof ACTIONS_EVENT.INVALIDATE,
    byId: { [key: string]: IEvent },
}

interface ActionEventsReceive {
    type: typeof ACTIONS_EVENT.RECEIVE
    byId: { [key: string]: IEvent },
}

export interface EventState {
    byId: { [key: string]: IEvent },
}

export enum EventSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesEvent = ActionEventsReceive | ActionEventsInvalidate;