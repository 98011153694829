import { EntityTypeEnum } from "./Entity";

export interface IFile {
    fileId: string,
    type: FileTypeEnum,
    filename: string,
    description: string,
    extension: string,
    entityId: string,
    entityType: EntityTypeEnum,
    status: FileStatusEnum,
    created: Date,
    createdBy: string,
    updated: Date,
    preSignedUrl: string,
    fileBucket: string,
}


export enum FileTypeEnum {
    Default = 10,
    Image = 20,
    Avatar = 30,
    AssetImage = 40,
}


export enum FileStatusEnum {
    Active = 10,
}


export enum FileSearchOrderType {
    CreatedDate = 10,
    FileId = 20,
}