import { ACTIONS_SOLVER_JOB_SUMMARY_RESULT_SET } from './types';
import { AppThunk } from '..';
import { ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { ShowError } from '../../utilities/Helpers';
import { ISolverUIResultSet } from '../../utilities/types/SolverUIResultSet';
import { receiveSolverUIResultSetCharts } from '../solverUIResultSetChart/actions';


export const receiveSolverUIResultSets = (SolverUIResultSets: ISolverUIResultSet[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverUIResultSet } = {};

    for (var i = 0; i < SolverUIResultSets.length; i++) {
        byIdObjectToDispatch[SolverUIResultSets[i].solverUIResultSetId] = SolverUIResultSets[i];
    }

    return {
        type: ACTIONS_SOLVER_JOB_SUMMARY_RESULT_SET.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverUIResultSet = (SolverUIResultSet: ISolverUIResultSet) => ({
    type: ACTIONS_SOLVER_JOB_SUMMARY_RESULT_SET.DELETE,
    byId: { [SolverUIResultSet.solverUIResultSetId]: SolverUIResultSet }
});


export interface IFetchSearchSolverUIResultSetsProps {
    solverJobTaskId: string,
    preSignedUrl: string,
}


export const fetchSearchSolverUIResultSets = ({ preSignedUrl, solverJobTaskId }: IFetchSearchSolverUIResultSetsProps): AppThunk<Promise<ISolverUIResultSet | undefined>> => async dispatch => {

    try {

        var apiResponse = await fetch(preSignedUrl, {
            method: 'GET',
        });

        if (apiResponse.ok) {

            var json = await apiResponse.json() as ISolverUIResultSet;
            if (json?.solverUIResultSetId) {
                json["solverJobTaskId"] = solverJobTaskId;

                if(json?.charts?.length){

                    for(var chart of json.charts){
                        chart["solverJobTaskId"] = solverJobTaskId;
                    }
                    
                    dispatch(receiveSolverUIResultSetCharts(json?.charts || []));
                }

                delete json?.charts;
                dispatch(receiveSolverUIResultSets([json]));

                return json;
            }
            else {
                ShowError("Failed to retrieve task summary.");
                return;
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching solver job tasks.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
} 