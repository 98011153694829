import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import {
  fetchDeleteSummaryOutputTypeInputField,
  fetchUpdateSummaryOutputTypeInputField,
} from "../../../redux/summaryOutputTypeInputField/actions";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { toDateTime, useSplitDateTime } from "../../../utilities/inputFields/dateTimeFieldUtils";
import {
  DEFAULT_DECIMAL_PLACES,
  MAX_DECIMAL_PLACES,
  MAX_VALUE,
  roundToDecimalPlaces,
} from "../../../utilities/inputFields/numberFieldUtils";
import { yumInputFields } from "../../../utilities/inputFields/yumUitls";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import {
  ISummaryOutputTypeInputField,
  SummaryOutputTypeInputFieldTypeEnum as FieldType,
} from "../../../utilities/types/SummaryOutputTypeInputField";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import SummaryOutputTypeInputFieldListValueMaintenanceSection from "../../summaryOutputTypeInputFieldListValue/SummaryOutputTypeInputFieldListValueMaintenanceSection";

interface FormValues {
  summaryOutputTypeInputFieldId: string;
  label: string;
  description: string;
  defaultValue: number;
  defaultDate?: string; // Split from asset field 'DateTime' field
  defaultTime?: string; // Split from asset field 'DateTime' field
  minValue: number;
  maxValue: number;
  decimalPlaces?: number;
  tableName: string;
  orderNumber: number;
  type: FieldType;
}

interface FormProps {
  summaryOutputTypeInputField: ISummaryOutputTypeInputField;
  onCompleteCallback(summaryOutputTypeInputField?: ISummaryOutputTypeInputField): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ISummaryOutputTypeInputField, AnyAction>;
  canEdit: boolean;
}

const FormSummaryOutputTypeInputFieldUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    summaryOutputTypeInputField: { summaryOutputTypeInputFieldId },
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (formValues: FormValues) => {
    const { onCompleteCallback, dispatch, summaryOutputTypeInputField } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    const { defaultDate, defaultTime, ...values } = formValues;

    // Map dispatch via props
    var updatedSummaryOutputTypeInputField = await dispatch(
      fetchUpdateSummaryOutputTypeInputField({
        ...values,
        defaultDateTime: toDateTime(defaultDate, defaultTime),
        summaryOutputTypeInputFieldId: summaryOutputTypeInputField.summaryOutputTypeInputFieldId,
      })
    );

    setSubmitting(false);
    if (updatedSummaryOutputTypeInputField) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedSummaryOutputTypeInputField);
    }
  };

  // Split 'DateTime' field into separate values for the form
  const [defaultDate, defaultTime] = useSplitDateTime(formProps.summaryOutputTypeInputField.defaultDateTime);

  return (
    <Formik
      initialValues={{
        ...formProps.summaryOutputTypeInputField,
        defaultString: formProps.summaryOutputTypeInputField.defaultString ?? undefined,
        defaultDate,
        defaultTime,
        decimalPlaces: formProps.summaryOutputTypeInputField.decimalPlaces ?? DEFAULT_DECIMAL_PLACES,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteSummaryOutputTypeInputField({ summaryOutputTypeInputFieldId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        const decimalPlaces = props.values.decimalPlaces;

        /** Handle numeric field updates that should limit their decimal places to the field value. */
        function handleDecimalFieldChange(field: keyof ISummaryOutputTypeInputField) {
          return (e: React.ChangeEvent<any>) => {
            const newValue = roundToDecimalPlaces(parseFloat(e.currentTarget.value as string), decimalPlaces);
            props.setFieldValue(field, newValue);
          };
        }

        /** Handle the 'decimal places' field updates and limit dependent fields to the new value */
        function handleDecimalPlaceChange(...dependentFields: (keyof ISummaryOutputTypeInputField)[]) {
          return (e: React.ChangeEvent<any>) => {
            const newValue = e.currentTarget.value as number | undefined;
            if (newValue) {
              const newDecimalPlaces = Math.round(e.currentTarget.value); // force integer
              props.setFieldValue("decimalPlaces", newDecimalPlaces);

              // Round all dependent fields to the new decimal places
              for (const field of dependentFields) {
                const newValue = roundToDecimalPlaces(parseFloat(props.values[field] as string), newDecimalPlaces);
                props.setFieldValue(field, newValue);
              }
            } else {
              props.setFieldValue("decimalPlaces", newValue); // allow clearing the field
            }
          };
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={summaryOutputTypeInputFieldId} name="summaryOutputTypeInputFieldId" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "label", "Label")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={props.handleChange}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  {...getFormikFieldProps(props, "tableName", "Table name")}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-model-type" variant="standard">
                    Type
                  </InputLabel>
                  <Select
                    labelId="lbl-model-type"
                    variant="standard"
                    {...getFormikFieldProps(props, "type", "Type")}
                    onChange={(e) => {
                      props.setFieldValue("type", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-type"
                  >
                    <MenuItem value={FieldType.Number}>Number</MenuItem>
                    <MenuItem value={FieldType.String}>Text</MenuItem>
                    <MenuItem value={FieldType.DateTime}>Date</MenuItem>
                    <MenuItem value={FieldType.CustomEnumeration}>Custom Enumeration</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {props.values.type === FieldType.Number && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleDecimalFieldChange("minValue")}
                      type="number"
                      fullWidth
                      variant="standard"
                      margin="normal"
                      inputProps={{ min: 0, max: MAX_VALUE, step: "any" }}
                      {...getFormikFieldProps(props, "minValue", "Min Value")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleDecimalFieldChange("maxValue")}
                      type="number"
                      fullWidth
                      variant="standard"
                      margin="normal"
                      inputProps={{ min: 0, max: MAX_VALUE, step: "any" }}
                      {...getFormikFieldProps(props, "maxValue", "Max Value")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleDecimalPlaceChange("minValue", "maxValue", "defaultValue")}
                      type="number"
                      fullWidth
                      variant="standard"
                      margin="normal"
                      inputProps={{ min: 0, max: MAX_DECIMAL_PLACES, step: "0" }}
                      {...getFormikFieldProps(props, "decimalPlaces", "Decimal Places")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleDecimalFieldChange("defaultValue")}
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="standard"
                      inputProps={{ min: 0, max: MAX_VALUE, step: "any" }}
                      {...getFormikFieldProps(props, "defaultValue", "Default Value")}
                    />
                  </Grid>
                </>
              )}

              {props.values.type === FieldType.CustomEnumeration && (
                <>
                  <Grid item xs={12}>
                    <SummaryOutputTypeInputFieldListValueMaintenanceSection
                      canEdit={true}
                      summaryOutputTypeInputFieldId={summaryOutputTypeInputFieldId}
                    />
                  </Grid>
                </>
              )}

              {props.values.type === FieldType.String && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={props.handleChange}
                      {...getFormikFieldProps(props, "defaultString", "Default Text")}
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="standard"
                    />
                  </Grid>
                </>
              )}

              {props.values.type === FieldType.DateTime && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...getFormikFieldProps(props, "defaultDate", "Default Date")}
                      onChange={props.handleChange}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      style={{ width: "100%" }}
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...getFormikFieldProps(props, "defaultTime", "Default Time")}
                      onChange={props.handleChange}
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 300 }}
                      style={{ width: "100%" }}
                      variant="standard"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete confirmation"
                  subtitle="Confirm category delete"
                  description="Are you sure that you'd like to remove this category?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          ...yumInputFields,
          label: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a label"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          orderNumber: Yup.number()
            .label("Max Value")
            .min(0, "Zero or more.")
            .required("Please provide an order number")
            .max(999, "Less than 999"),
        });
      }}
    />
  );
};

export default FormSummaryOutputTypeInputFieldUpdate;
