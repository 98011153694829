import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { IFailureMode } from "../../utilities/types/FailureMode";

export interface IFetchSearchAiSuggestFailureModeProps {
  pageNumber: number;
  pageSize: number;
  modelId: string;
  componentId: string;
  functionId: string;
  functionalFailureId: string;
}

export const fetchSearchAiSuggestFailureMode = async (searchParams: IFetchSearchAiSuggestFailureModeProps): Promise<IFailureMode[]> => {
  const headers = await GetDefaultHeaders(true, false, true);

  try {
    const apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/AISuggest/FailureModes`, searchParams), {
      method: "GET",
      headers: headers,
    });

    const parsedResp: IApiResponse = await CheckStatus(apiResponse);

    if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.failureModes) {
      return parsedResp.data.failureModes;
    } else {
      if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
        ShowError("Error searching ai suggest failure modes");
      }
      return [];
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      ShowExceptionAsMessage(error);
      console.log("Error searching ai suggest failure modes", error.stack);
    } else {
      // Handle other types of exceptions or unknown errors.
      console.error("Unknown error:", error);
    }
    return [];
  }
};
