import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import PageUserDetails from '../components/userDetails/pages/PageUserDetails';
import { fetchUserDetailsByIdIfNeeded } from '../redux/userDetail/actions';
import { useDispatch } from 'react-redux';
import LoaderAbsoluteCentred from '../components/generic/loaders/LoaderAbsoluteCentred';

interface RouteParams {
    userId: string; // You can adjust the type accordingly
}

const RouteUser = () => {

    let match = useRouteMatch();
    let { userId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    // Only own user details are loaded by index route
    useEffect(() => {
        (async() => {
            setLoading(true);
            if (userId) await dispatch(fetchUserDetailsByIdIfNeeded(userId));
            setLoading(false);
        })()
    }, [userId, dispatch]);

    if(loading) return <LoaderAbsoluteCentred loading={true} />;

    return (
        <Switch>
            <Route path={`${match.path}`} render={props => <LayoutDefault routeProps={props}><PageUserDetails routeProps={props} userId={userId} /></LayoutDefault>} />
        </Switch>
    )
}


export default RouteUser;