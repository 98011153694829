export interface IFeedback {
    feedbackId: string,
    userId: string,
    type: FeedbackTypeEnum,
    text: string,
    pleaseContact: boolean,
    currentUrl: string,
    platformDetails: string,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
    contributorModelId?: string, // Required for 'Contributor' feedback types
}

export enum FeedbackTypeEnum {
    Default = 0,
    Bug = 10,
    UIUX = 20,
    FeatureRequest = 30,
    SolverRequest = 40,
    OutputRequest = 50,
    Other = 60,
    Contributor = 70,
}
