import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { Button, IconButton, Typography } from "@mui/material";
import ModalDiscussionCreate from "./ModalDiscussionCreate";
import DiscussionEnquiryContainer from "../DiscussionEnquiryContainer";
import { IDiscussion } from "../../../utilities/types/Discussion";
import GreyColors from "@mui/material/colors/grey";

const DrawerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  ".drawer-header": {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    ".drawer-header-inner": {
      color: GreyColors[50],
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    ".white-btn": {
      backgroundColor: GreyColors[50],
    },
  },
  ".drawer-body": {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
}));

export interface IModalDiscussionsListProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(discussion?: IDiscussion): void;
  entityId: string;
  entityType: EntityTypeEnum;
  canDisccuss?: boolean;
}

function ModalDiscussionsList({
  onCancelCallback,
  open,
  entityId,
  entityType,
  canDisccuss = true,
}: IModalDiscussionsListProps) {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const toggleShowCreateModal = () => setShowCreateModal(!showCreateModal);

  return (
    <MuiDrawer
      sx={{
        zIndex: 1222,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: 800,
          boxSizing: "border-box",
        },
      }}
      anchor="right"
      open={open}
      onClose={onCancelCallback}
    >
      <DrawerContainer>
        <div className="drawer-header">
          <div className="drawer-header-inner">
            <IconButton size="small" onClick={onCancelCallback}>
              <CloseIcon style={{ fill: GreyColors[50] }} />
            </IconButton>
            <div>
              <Typography variant="body1">View Discussions </Typography>
              <Typography variant="body2" color={GreyColors[300]}>
                Select a discussion to view comments.
              </Typography>
            </div>
          </div>
          {canDisccuss && (
            <Button className="white-btn" variant="outlined" onClick={toggleShowCreateModal}>
              Create
            </Button>
          )}
        </div>
        <div className="drawer-body">
          <DiscussionEnquiryContainer entityId={entityId} entityType={entityType} canEdit={false} />
        </div>

        <ModalDiscussionCreate
          open={showCreateModal}
          onCancelCallback={toggleShowCreateModal}
          onCompleteCallback={toggleShowCreateModal}
          entityId={entityId}
          entityType={entityType}
        />
      </DrawerContainer>
    </MuiDrawer>
  );
}

export default ModalDiscussionsList;
