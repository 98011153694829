import { Skeleton } from "@mui/material";
import { Suspense } from "react";
import LoaderAbsoluteCentred from "./loaders/LoaderAbsoluteCentred";

const SuspenseWrapper: React.FC<{ children: React.ReactNode; showFullLoader?: boolean }> = ({
  children,
  showFullLoader = true,
}) => {
  return (
    <Suspense
      fallback={
        showFullLoader ? <LoaderAbsoluteCentred loading={true} /> : <Skeleton variant="rectangular" height={50} />
      }
    >
      {children}
    </Suspense>
  );
};

export default SuspenseWrapper;
