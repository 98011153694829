import {
  InsufficientPermissionState,
  ACTIONS_INSUFFICIENT_PERMISSION_MAPPING,
  ActionTypesInsufficientPermissionMapping,
} from "./types";

const initialState: InsufficientPermissionState = {
  message: "",
  showModal: false,
};

export function insufficientPermissionReducer(
  state = initialState,
  action: ActionTypesInsufficientPermissionMapping
): InsufficientPermissionState {
  switch (action.type) {
    case ACTIONS_INSUFFICIENT_PERMISSION_MAPPING.RESET: {
      return {
        ...initialState,
      };
    }
    case ACTIONS_INSUFFICIENT_PERMISSION_MAPPING.SET:
      return {
        ...state,
        message: action.message,
        showModal: true,
      };

    default:
      return state;
  }
}
