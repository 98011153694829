import { Card as MuiCard, CardContent, CardHeader, Typography, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import AddImage from "../../assets/img/Add.png";

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GetSolverLinkBySolver } from "../../routes/RouteLinkHelpers";
import SolverCreateModal from "./modals/ModalSolverCreate";
import { ISolver } from "../../utilities/types/Solver";

const IMAGE_HEIGHT = 200;

const Card = styled(MuiCard)(({ theme }) => ({
  height: "100%",
  "& .updatedModelImageWrapper": {
    height: IMAGE_HEIGHT,
    overflow: "hidden",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
  },
  "& .plusImage": {
    alignItems: "center",
    height: IMAGE_HEIGHT,
    left: "50%",
  },
  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .avatar": {
    backgroundColor: blueGrey[800],
    textSizeAdjust: "small",
  },
  "& .header": {
    height: 75,
  },
}));

function AddNewSolverWidget() {
  const [solverCreateModalOpen, setsolverCreateModalOpen] = useState(false);
  const history = useHistory();

  const handleSolverCreateModalToggle = () => setsolverCreateModalOpen(!solverCreateModalOpen);
  const onSolverCreateCancelCallback = () => setsolverCreateModalOpen(false);
  const viewSolverDetails = (solver: ISolver) => {
    history.push(GetSolverLinkBySolver(solver));
  };
  const onSolverCreateCompleteCallback = (solver?: ISolver) => {
    if (solver) {
      setsolverCreateModalOpen(false);
      viewSolverDetails(solver);
    }
  };

  return (
    <Card>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        title={"Suggest a new solver"}
        subheader={`Creates a new draft solver to share`}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
      />
      <div className="updatedModelImageWrapper">
        <img src={AddImage} alt="AddImage" className="plusImage" onClick={handleSolverCreateModalToggle} />
      </div>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {"Start a new page here"}
        </Typography>
      </CardContent>
      <SolverCreateModal
        onCompleteCallback={onSolverCreateCompleteCallback}
        onCancelCallback={onSolverCreateCancelCallback}
        open={solverCreateModalOpen}
      />
    </Card>
  );
}

export default AddNewSolverWidget;
