import { ACTIONS_FUNCTION, FunctionSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IFunction } from '../../utilities/types/Function';
import { selectorGetFunctionsByModelId } from './selectors';
import { receiveIdentifierMappings } from '../identifierMapping/actions';


export const receiveFunctions = (functions: IFunction[]) => {

    var byIdObjectToDispatch: { [key: string]: IFunction } = {};

    for (var i = 0; i < functions.length; i++) {
        byIdObjectToDispatch[functions[i].functionId] = functions[i];
    }

    return {
        type: ACTIONS_FUNCTION.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteFunction = (componentFunction: IFunction) => ({
    type: ACTIONS_FUNCTION.DELETE,
    byId: { [componentFunction.functionId]: componentFunction }
});


export interface IFetchCreateFunctionProps {
    modelId: string,
    componentId: string,
    name: string,
    description: string,
    orderNumber: number,
}

export interface IFetchCreateFunctionsProps {
    functions: IFetchCreateFunctionProps[]
}


export const fetchCreateFunction = (functionsToCreate: IFetchCreateFunctionsProps): AppThunk<Promise<IFunction>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/functions`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(functionsToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data) {
            if (parsedResp.data.functions) dispatch(receiveFunctions(parsedResp.data.functions));
            if (parsedResp.data.identifierMappings) dispatch(receiveIdentifierMappings(parsedResp.data.identifierMappings));
            return parsedResp.data.functions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating function.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating function.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateFunctionProps {
    functionId: string,
    name: string,
    description: string,
    orderNumber: number,
}


export const fetchUpdateFunction = (props: IFetchUpdateFunctionProps): AppThunk<Promise<IFunction>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/functions`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functions) {
            dispatch(receiveFunctions(parsedResp.data.functions));
            return parsedResp.data.functions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating function.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating function.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchFunctionsProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    functionId?: string,
    text?: string,
    createdBy?: string,
    orderType?: FunctionSearchOrderTypeEnum,
}


export const fetchSearchFunctions = (searchParams: IFetchSearchFunctionsProps): AppThunk<Promise<IFunction[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/functions`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functions) {
            dispatch(receiveFunctions(parsedResp.data.functions));
            return parsedResp.data.functions;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching function.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching function.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteFunctionProps {
    functionId: string,
}


export const fetchDeleteFunction = (props: IFetchDeleteFunctionProps): AppThunk<Promise<IFunction>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/functions`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.functions && parsedResp.data.functions.length) {
            dispatch(requestDeleteFunction(parsedResp.data.functions[0]));
            return parsedResp.data.functions[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting function.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting function.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IEnableFunctionsByModelIdProps {
    modelId: string,
}

export const enableFunctionsByModelId = ({ modelId }: IEnableFunctionsByModelIdProps): AppThunk<Promise<void>> => async (dispatch, getStore) => {
    dispatch(receiveFunctions(
        selectorGetFunctionsByModelId(
            getStore(), modelId).map((componentFunction) => {
        return { ...componentFunction, disabled: false };
    })));
}