import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/EventTwoTone";
import { IEvent } from "../../../utilities/types/Event";
import FormEventDuplicate from "../forms/FormEventDuplicate";

export interface ModalEventDuplicateProps {
  open: boolean;
  event: IEvent;
  onCancelCallback(): void;
  modelId: string;
  onCompleteCallback(event?: IEvent): void;
}

function ModalEventDuplicate({ event, onCancelCallback, open, onCompleteCallback, modelId }: ModalEventDuplicateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Duplicate new event(s)"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormEventDuplicate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          event={event}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalEventDuplicate;
