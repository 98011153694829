import cloneDeep from "lodash/cloneDeep";
import { useCallback, useState } from "react";
import { ISummaryOutputTypeInput } from "../../redux/summaryOutputJob/actions";
import { ISummaryOutputType, TSummaryOutputTypeInputFieldValue } from "../../utilities/types/SummaryOutputType";
import { ISummaryOutputTypeInputField } from "../../utilities/types/SummaryOutputTypeInputField";
import { getSummaryOutputTypeDefaultFieldValue } from "../assetInputField/defaultFieldHelper";
import { SummaryOutputInputValuesObjectMap } from "../jobs/WizardState";

/** Returns whether any item is loading in a map */
export function useItemIsLoading(): [boolean, (id: string, val: boolean) => void] {
  const [itemIsLoadingMap, setItemIsLoadingMap] = useState<{ [id: string]: boolean }>({});
  const setItemIsLoading = useCallback(
    (id: string, isLoading: boolean) => setItemIsLoadingMap((s) => ({ ...s, [id]: isLoading })),
    []
  );
  const itemIsLoading = Object.values(itemIsLoadingMap).some((isLoading) => isLoading);
  return [itemIsLoading, setItemIsLoading];
}

export function setupFieldValueMap(
  valuesMap: SummaryOutputInputValuesObjectMap,
  fields: ISummaryOutputTypeInputField[]
) {
  let newValuesMap: SummaryOutputInputValuesObjectMap = {};
  for (let inputField of fields) {
    let fieldValue;
    if (
      valuesMap[inputField.summaryOutputTypeId] &&
      valuesMap[inputField.summaryOutputTypeId][inputField.summaryOutputTypeInputFieldId]
    ) {
      fieldValue = valuesMap[inputField.summaryOutputTypeId][inputField.summaryOutputTypeInputFieldId];
    } else {
      fieldValue = getSummaryOutputTypeDefaultFieldValue(inputField);
    }

    let valuesObject = newValuesMap[inputField.summaryOutputTypeId] || {};
    valuesObject[inputField.summaryOutputTypeInputFieldId] = fieldValue;
    newValuesMap[inputField.summaryOutputTypeId] = valuesObject;
  }
  return { ...valuesMap, ...newValuesMap };
}

export function applyFieldChangeToValueMap(
  valuesMap: SummaryOutputInputValuesObjectMap,
  summaryOutputFieldTypeId: string,
  fieldId: string,
  value: TSummaryOutputTypeInputFieldValue
) {
  const _valuesMap = cloneDeep(valuesMap);
  const summaryOutputInputValuesObject = _valuesMap[summaryOutputFieldTypeId] || {};
  _valuesMap[summaryOutputFieldTypeId] = { ...summaryOutputInputValuesObject, [fieldId]: value };
  return _valuesMap;
}

export function getReportInputsFromValueMap(
  valuesMap: SummaryOutputInputValuesObjectMap,
  selectedSummaryOutputTypes: ISummaryOutputType[]
): { [id: string]: ISummaryOutputTypeInput } {
  return selectedSummaryOutputTypes
    .map<ISummaryOutputTypeInput>(({ summaryOutputTypeId }) => {
      let displayName = "",
        inputFieldValueMap = {};
      let summaryOutputTypeInput = valuesMap[summaryOutputTypeId];
      if (summaryOutputTypeInput) {
        let { displayName: displayNameField, ...fields } = summaryOutputTypeInput;
        inputFieldValueMap = { ...fields };
        displayName = displayNameField?.value?.toString() ?? "";
      }
      return { summaryOutputTypeId, inputFieldValueMap, displayName };
    })
    .reduce((out, type) => ({ ...out, [type.summaryOutputTypeId]: type }), {});
}
