import { ACTIONS_PLAN, PlanSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IPlan } from '../../utilities/types/Plan';
import { receiveEvents } from '../event/actions';
import { receiveAssetInputFields } from '../assetInputField/actions';


export const receivePlans = (plans: IPlan[]) => {

    var byIdObjectToDispatch: { [key: string]: IPlan } = {};

    for (var i = 0; i < plans.length; i++) {
        byIdObjectToDispatch[plans[i].planId] = plans[i];
    }

    return {
        type: ACTIONS_PLAN.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeletePlan = (plan: IPlan) => ({
    type: ACTIONS_PLAN.DELETE,
    byId: { [plan.planId]: plan }
});


export interface IFetchCreatePlanProps {
    modelId: string,
    name: string,
    code: string,
    orderNumber: number,
    eventInterval: number,
    doNotOptimise: boolean,
}
export interface IFetchCreatePlansProps {
    plans: IFetchCreatePlanProps[]
}


export const fetchCreatePlan = (planToCreate: IFetchCreatePlansProps): AppThunk<Promise<IPlan>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/plans`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(planToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data) {
            if (parsedResp.data.plans)
            {
                dispatch(receivePlans(parsedResp.data.plans));
            }
            if (parsedResp.data.events)
            {
                dispatch(receiveEvents(parsedResp.data.events));
            }
            if (parsedResp.data.assetInputFields)
            {
                dispatch(receiveAssetInputFields(parsedResp.data.assetInputFields));
            }
            return parsedResp.data.plans;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating plan.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating plan.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdatePlanProps {
    planId: string,
    name: string,
    code: string,
    orderNumber: number,
}


export const fetchUpdatePlan = (props: IFetchUpdatePlanProps): AppThunk<Promise<IPlan>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/plans`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.plans) {
            dispatch(receivePlans(parsedResp.data.plans));
            return parsedResp.data.plans;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating plan.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating plan.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchPlansProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    planId?: string,
    text?: string,
    createdBy?: string,
    orderType?: PlanSearchOrderTypeEnum,
}


export const fetchSearchPlans = (searchParams: IFetchSearchPlansProps): AppThunk<Promise<IPlan[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/plans`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.plans) {
            dispatch(receivePlans(parsedResp.data.plans));
            return parsedResp.data.plans;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching plan.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching plan.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeletePlanProps {
    planId: string,
}


export const fetchDeletePlan = (props: IFetchDeletePlanProps): AppThunk<Promise<IPlan>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/plans`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.plans && parsedResp.data.plans.length) {
            dispatch(requestDeletePlan(parsedResp.data.plans[0]));
            return parsedResp.data.plans[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting plan.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting plan.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}