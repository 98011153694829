import { IFunction } from "../../utilities/types/Function";


export const ACTIONS_FUNCTION = {
    INVALIDATE: 'INVALIDATE_FUNCTIONS',
    RECEIVE: 'RECEIVE_FUNCTIONS',
    DELETE: 'DELETE_FUNCTION',
    UPDATE: 'UPDATE_FUNCTIONS',
}

interface ActionFunctionsInvalidate {
    type: typeof ACTIONS_FUNCTION.INVALIDATE,
    byId: { [key: string]: IFunction },
}

interface ActionFunctionsReceive {
    type: typeof ACTIONS_FUNCTION.RECEIVE
    byId: { [key: string]: IFunction },
}

export interface FunctionState {
    byId: { [key: string]: IFunction },
}

export enum FunctionSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesFunction = ActionFunctionsReceive | ActionFunctionsInvalidate;