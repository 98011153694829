import { Badge, IconButton } from "@mui/material";

import DisplayIcon from "@mui/icons-material/QuestionAnswerOutlined";
import React, { useState } from "react";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { ISolverDetails } from "../../utilities/types/SolverDetails";

import ModalDiscussionsList from "../discussion/modals/ModalDiscussionsList";
import { useSelector } from "react-redux";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { RootState } from "../../redux";

type SolverDetailsDiscussionsDisplayProps = {
  solverDetails?: ISolverDetails;
};

const SolverDetailsDiscussionsDisplay = ({ solverDetails }: SolverDetailsDiscussionsDisplayProps) => {
  const [showDiscussionsList, setShowDiscussionsList] = useState(false);
  const hasDiscussionCreatePermissions = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesDiscussionCreate)
  );
  const toggleDiscussionsList = () => setShowDiscussionsList(!showDiscussionsList);

  const discussionCount = solverDetails?.discussionCount ? solverDetails?.discussionCount : 0;

  if (!solverDetails) {
    return null;
  }

  return (
    <>
      <IconButton onClick={toggleDiscussionsList}>
        <Badge badgeContent={discussionCount > 9 ? "9+" : discussionCount} color="error">
          <DisplayIcon />
        </Badge>
      </IconButton>

      {showDiscussionsList && (
        <ModalDiscussionsList
          open={showDiscussionsList}
          onCancelCallback={toggleDiscussionsList}
          onCompleteCallback={toggleDiscussionsList}
          entityId={solverDetails.solverDetailsId}
          entityType={EntityTypeEnum.SolverDetails}
          canDisccuss={hasDiscussionCreatePermissions}
        />
      )}
    </>
  );
};

export default SolverDetailsDiscussionsDisplay;
