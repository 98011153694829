import { ActionTypesIdentifierMapping, IdentifierMappingState, ACTIONS_IDENTIFIER_MAPPING } from "./types";

const initialState: IdentifierMappingState = {
    byId: {},
}


export function identifierMappingsReducer(
    state = initialState,
    action: ActionTypesIdentifierMapping
): IdentifierMappingState {
    switch(action.type){
        case ACTIONS_IDENTIFIER_MAPPING.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_IDENTIFIER_MAPPING.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_IDENTIFIER_MAPPING.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        case ACTIONS_IDENTIFIER_MAPPING.DELETE_MANY: {
            let currentById = { ...state.byId };

            Object.keys(action.byId).forEach((id) => {
                delete currentById[id];
            });

            return {
                ...state,
                byId: currentById,
            };
        }
        default:
            return state;
    }
}