import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/ReceiptTwoTone";
import FormCostBundleUpdate from "../forms/FormCostBundleUpdate";
import { ICostBundle } from "../../../utilities/types/CostBundle";

export interface IModalCostBundleUpdateProps {
  open: boolean;
  costBundle: ICostBundle;
  onCancelCallback(): void;
  onCompleteCallback(costBundle?: ICostBundle): void;
  canEdit: boolean;
}

function ModalCostBundleUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  costBundle,
  canEdit,
}: IModalCostBundleUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a cost bundle"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormCostBundleUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          costBundle={costBundle}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalCostBundleUpdate;
