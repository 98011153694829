import React, { forwardRef } from "react";
import { Grow } from "@mui/material";

interface IAnimationWrapperProps {
  children?: React.ReactElement<any, any>;
}

const AnimationWrapper = forwardRef<HTMLDivElement, IAnimationWrapperProps>((props, ref) => {
  if (!props.children) return null;

  return <Grow in={true}>{React.cloneElement(props.children, { ref })}</Grow>;
});

export default AnimationWrapper;
