import { RootState } from "..";
import { IDecision } from "../../utilities/types/Decision";


export function selectorGetDecisionsByModelId(state: RootState, modelId: string) : IDecision[] {

    if (!state.decisions || !state.decisions.byId) return [];

    return sortDecisionsByOrderNumberAscending(Object.values(state.decisions.byId).filter(x => x.modelId === modelId));
}


export function selectorGetDecisionById(state: RootState, decisionId: string) : IDecision | undefined {

    if (!state.decisions || !state.decisions.byId) return undefined;

    return state.decisions.byId[decisionId];
}


// function sortDecisionsByOrderNumberDescending(decisions: IDecision[]) {
//     return decisions.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortDecisionsByOrderNumberAscending(decisions: IDecision[]) {
    return decisions.sort((a, b) => a.orderNumber - b.orderNumber);
}