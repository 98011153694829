import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchFeedbacksProps, fetchSearchFeedbacks } from "../../redux/feedback/actions";
import { IFeedback } from "../../utilities/types/Feedback";


export interface IUseFetchFeedbacksPageHookProps extends IFetchSearchFeedbacksProps {
    minPageNumberToFetch: number;
}


export const useFetchFeedbacksPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, text, orderType }: IUseFetchFeedbacksPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IFeedback[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IFeedback[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var feedbacks = await dispatch(fetchSearchFeedbacks({
                    pageSize,
                    pageNumber,
                    text,
                    orderType,
                })) as unknown as IFeedback[];

                if (feedbacks) {
                    setMorePages(feedbacks.length >= pageSize)
                    setLastResultSet(feedbacks);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, text, orderType]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.feedbackId === x.feedbackId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}