import { IModel } from "../../utilities/types/Model";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { fetchSearchModels, FetchSearchModelsReturnType, IFetchSearchModelsProps } from "../../redux/model/actions";
import { fetchSearchFavourites } from "../../redux/favourite/actions";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { GetUserId } from "../../utilities/ApiUtils";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { RootState } from "../../redux";

export interface IUseFetchModelsPageHookProps extends IFetchSearchModelsProps {
  minPageNumberToFetch: number;
}

export const useFetchModelsPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
  modelId,
  status,
  text,
  orderType,
  favouriteFilterType,
  maturityFilterType,
  modelFilterType,
}: IUseFetchModelsPageHookProps) => {
  const hasPermissionViewFavourites = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesFavouriteRead)
  );

  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IModel[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IModel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [reFetch, setReFetch] = useState<boolean>(false);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      if (reFetch) {
        setReFetch(false);
      } else {
        setFetching(true);
      }

      try {
        // Retrieve models
        var { models, totalCount } = (await dispatch(
          fetchSearchModels({
            pageSize,
            pageNumber,
            modelId,
            status,
            text,
            orderType,
            favouriteFilterType,
            maturityFilterType,
            modelFilterType,
          })
        )) as unknown as FetchSearchModelsReturnType;

        setTotalCount(totalCount);

        if (models.length) {
          setMorePages(models.length >= pageSize);
          setLastResultSet(models);

          if (hasPermissionViewFavourites) {
            await dispatch(
              fetchSearchFavourites({
                entityIds: models.map((x) => x.modelId),
                entityType: EntityTypeEnum.Model,
                pageNumber,
                pageSize,
              })
            );
          }
        } else {
          setMorePages(false);
          setLastResultSet([]);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [
    minPageNumberToFetch,
    dispatch,
    pageNumber,
    pageSize,
    modelId,
    status,
    text,
    orderType,
    favouriteFilterType,
    maturityFilterType,
    modelFilterType,
    hasPermissionViewFavourites,
    reFetch,
  ]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.modelId === x.modelId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
    totalCount,
    setReFetch,
  };
};

/** Loads models that haven't been loaded yet so that the redux store contains useful metadata like images. */
export function useLoadNewModels(modelIds: string[]) {
  // Get unique model IDs that don't exist in the store yet
  const dispatch = useDispatch();
  const modelIdStore = useSelector((store: RootState) => store.models.byId);
  const uniqueIds = Array.from(new Set(modelIds));
  const newIds = uniqueIds.filter((id) => !modelIdStore[id]);

  // Track model IDs that are in progress and that may or may not be in the store
  const [modelsInProgress, setModelsInProgress] = useState<{ [id: string]: boolean }>({});

  const fetchModel = useCallback(
    async (modelIds: string) => {
      if (modelsInProgress[modelIds] !== undefined) return; // Skip IDs in progress

      setModelsInProgress({ ...modelsInProgress, [modelIds]: false }); // Ensure we don't check again while it's in progress
      await dispatch(fetchSearchModels({ modelId: modelIds, pageSize: 1, pageNumber: 1 })); // Fetch the model which will populate the store and related metadata
      setModelsInProgress({ ...modelsInProgress, [modelIds]: true }); // Update the useState so the component refreshes with new metadata
    },
    [dispatch, modelsInProgress]
  );

  useEffect(() => {
    if (newIds.length > 0) {
      fetchModel(newIds.join(","));
    }
  }, [newIds, fetchModel]);
}
