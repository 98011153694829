import { IUserPermission } from "../../utilities/types/UserPermission";

export const ACTIONS_USER_PERMISSION = {
    INVALIDATE: 'INVALIDATE_USER_PERMISSIONS',
    RECEIVE: 'RECEIVE_USER_PERMISSIONS',
    UPDATE: 'UPDATE_USER_PERMISSIONS',
    DELETE: 'DELETE_USER_PERMISSION',
}

interface ActionUserPermissionsInvalidate {
    type: typeof ACTIONS_USER_PERMISSION.INVALIDATE,
    byId: { [key: string]: IUserPermission },
}

interface ActionUserPermissionsReceive {
    type: typeof ACTIONS_USER_PERMISSION.RECEIVE
    byId: { [key: string]: IUserPermission },
}

interface ActionUserPermissionsUpdate {
    type: typeof ACTIONS_USER_PERMISSION.UPDATE
    byId: { [key: string]: IUserPermission },
}

// interface ActionUserPermissionDelete {
//     type: typeof ACTIONS_USER_PERMISSION.DELETE
//     byId: { [key: string]: IUserPermission },
// }

export interface UserPermissionState {
    byId: { [key: string]: IUserPermission },
}

export type ActionTypesUserPermission = ActionUserPermissionsInvalidate | ActionUserPermissionsReceive | ActionUserPermissionsUpdate;