import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import HeaderIcon from "@mui/icons-material/ListAlt";
import FormAssetInputFieldListValueUpdate from "../forms/FormAssetInputFieldListValueUpdate";
import { IAssetInputFieldListValue } from "../../../utilities/types/AssetInputFieldListValue";

export interface IModalAssetInputFieldListValueUpdateProps {
  open: boolean;
  assetInputFieldListValue: IAssetInputFieldListValue;
  onCancelCallback(): void;
  onCompleteCallback(assetInputFieldListValue?: IAssetInputFieldListValue): void;
}

function ModalAssetInputFieldListValueUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  assetInputFieldListValue,
}: IModalAssetInputFieldListValueUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update list value"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent>
        <FormAssetInputFieldListValueUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          assetInputFieldListValue={assetInputFieldListValue}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalAssetInputFieldListValueUpdate;
