import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ISolverInputFieldListValue } from "../../utilities/types/SolverInputFieldListValue";
import { IFetchSearchSolverInputFieldListValuesProps, fetchSearchSolverInputFieldListValues } from "../../redux/solverInputFieldListValue/actions";


interface IUseFetchSolverInputFieldListValuesPageHookProps extends IFetchSearchSolverInputFieldListValuesProps {
    minPageNumberToFetch: number;
}


export const useFetchSolverInputFieldListValuesPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, solverId }: IUseFetchSolverInputFieldListValuesPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ISolverInputFieldListValue[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ISolverInputFieldListValue[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var solverInputFieldListValues = await dispatch(fetchSearchSolverInputFieldListValues({
                    pageSize,
                    pageNumber,
                    solverId,
                })) as unknown as ISolverInputFieldListValue[];

                if (solverInputFieldListValues && solverInputFieldListValues.length) {
                    setMorePages(solverInputFieldListValues.length >= pageSize)
                    setLastResultSet(solverInputFieldListValues);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, solverId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.solverInputFieldId === x.solverInputFieldId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}