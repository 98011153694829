import React, { lazy, useState } from "react";
import { Grid } from "@mui/material";

import { RouteComponentProps } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "../../redux";

import { selectorGetSummaryOutputJobById } from "../../redux/summaryOutputJob/selectors";
import JobDetailPageWrapper from "../generic/JobDetailPageWrapper";
import OutputJobCollapseCard from "./OutputJobCollapseCard";
import SuspenseWrapper from "../generic/SuspenseWrapper";

const LazyPrerequisiteJobsEnquiryWidget = lazy(() => import("../generic/widgets/PrerequisiteJobsEnquiryWidget"));
interface IPageOutputJobDetailsProps {
  routeProps: RouteComponentProps<any>;
  solverJobSummaryReportId: string;
  loading: boolean;
  setReFetchOutputJob: React.Dispatch<React.SetStateAction<boolean>>;
}

const PageOutputJobDetail = ({
  solverJobSummaryReportId,
  loading,
  setReFetchOutputJob,
}: IPageOutputJobDetailsProps) => {
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const solverJobSummaryReport = useSelector((store: RootState) =>
    selectorGetSummaryOutputJobById(store, solverJobSummaryReportId)
  );

  return (
    <JobDetailPageWrapper jobLabel="Output Jobs" jobDetailsLabel="Output Job Details" loading={loading}>
      <OutputJobCollapseCard
        solverJobSummaryReport={solverJobSummaryReport}
        showCollapse={showCollapse}
        setShowCollapse={setShowCollapse}
        setReFetchOutputJob={setReFetchOutputJob}
      >
        <Grid container rowSpacing={4} columnSpacing={2} style={{ padding: 16 }}>
          {solverJobSummaryReport && (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <SuspenseWrapper>
                <LazyPrerequisiteJobsEnquiryWidget prerequisites={solverJobSummaryReport.prerequisites} />
              </SuspenseWrapper>
            </Grid>
          )}
        </Grid>
      </OutputJobCollapseCard>
    </JobDetailPageWrapper>
  );
};

export default PageOutputJobDetail;
