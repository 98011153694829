import React, { useState } from "react";
import {
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
  InputBase,
  Divider,
  styled,
} from "@mui/material";

import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchUserDetailsPageHook } from "./Hooks";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import SearchIcon from "@mui/icons-material/Search";
import CheckboxIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import CheckboxIconFilled from "@mui/icons-material/RadioButtonCheckedRounded";
import { ToTitleCase } from "../../utilities/Helpers";
import { SolverJobTaskStatusEnum } from "../../utilities/types/SolverJobTask";
import UserDetailsManagementContainerRow from "./UserDetailsManagementContainerRow";
import { IUserDetail } from "../../utilities/types/UserDetail";
import HeaderIcon from "@mui/icons-material/PersonTwoTone";
import HeaderColor from "@mui/material/colors/blueGrey";

const DivWrapper = styled("div")(({ theme }) => ({
  minHeight: 500,
  "& .searchRoot": {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid #DEDEDE",
  },
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: 28,
    margin: 4,
  },
}));

const UserDetailsManagementContainer = () => {
  const [, setSearchStatusInternal] = useState<SolverJobTaskStatusEnum>();
  const [searchText, setSearchTextInternal] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);

  const {
    fetching,
    lastResultSet: userDetails,
    morePages,
  } = useFetchUserDetailsPageHook({
    pageNumber: pageNumber,
    pageSize: 20,
    minPageNumberToFetch: 1,
    text: searchText,
  });

  function setSearchStatus(status?: SolverJobTaskStatusEnum) {
    setSearchStatusInternal(status);
    setPageNumber(1);
  }

  function setSearchText(text?: string) {
    setSearchTextInternal(text);
    setPageNumber(1);
  }

  return (
    <UserDetailsManagementContainerDisplay
      userDetails={userDetails}
      loading={fetching}
      onSetSearchStatus={(newStatus: SolverJobTaskStatusEnum) => setSearchStatus(newStatus)}
      onSetSearchText={(newText: string) => setSearchText(newText)}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      hasMorePages={morePages}
    />
  );
};

type IUserDetailsManagementContainerDisplayProps = {
  userDetails: IUserDetail[];
  loading: boolean;
  onSetSearchStatus(newStatus?: SolverJobTaskStatusEnum): void;
  onSetSearchText(newText?: string): void;
  pageNumber: number;
  setPageNumber(page: number): void;
  hasMorePages: boolean;
};

const UserDetailsManagementContainerDisplay = ({
  userDetails,
  hasMorePages,
  setPageNumber,
  pageNumber,
  loading,
  onSetSearchStatus,
  onSetSearchText,
}: IUserDetailsManagementContainerDisplayProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [statusFilter, setJobStatusToFilterBy] = useState<SolverJobTaskStatusEnum>();
  const [searchText, setSearchTextFilter] = useState<string>();

  function updateJobStatusViaMenu(newStatus?: SolverJobTaskStatusEnum) {
    setJobStatusToFilterBy(newStatus);
    setMenuAnchorEl(undefined);
    onSetSearchStatus(newStatus);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  function onSearchClick(e: any) {
    e.preventDefault();
    onSetSearchText(searchText);
    return false;
  }

  return (
    <WidgetSectionBase
      headerText="User Details"
      subheaderText="Browse current users."
      headerIcon={<HeaderIcon />}
      headerColor={HeaderColor[600]}
      fullWidthHeader={true}
    >
      <DivWrapper>
        <Paper component="form" className="searchRoot" onSubmit={onSearchClick}>
          <Button className="iconButton" aria-label="menu" onClick={handleMenuClick} size="small">
            {ToTitleCase(statusFilter ? SolverJobTaskStatusEnum[statusFilter].toString() : "All")}
          </Button>
          <Divider className="divider" orientation="vertical" />
          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(undefined)}
          >
            <div style={{ paddingRight: 16, paddingLeft: 16 }}>
              <Typography variant="overline">Status Filters</Typography>
            </div>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(undefined)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === undefined} />
              </ListItemIcon>
              <ListItemText primary="None" />
            </MenuItem>
            <Divider />
            {/* <MenuItem onClick={() => updateJobStatusViaMenu(UserDetailStatusEnum.Pending)}>
                            <ListItemIcon>
                                <StatusFilterIcon checked={statusFilter === SolverJobTaskStatusEnum.Pending} />
                            </ListItemIcon>
                            <ListItemText primary="Pending" />
                        </MenuItem> */}
          </Menu>
          <InputBase
            className="input"
            placeholder="Search Users"
            inputProps={{ "aria-label": "Search Users" }}
            onChange={onSearchTextChangeHandler}
            type="text"
            onSubmit={onSearchClick}
          />
          <Divider className="divider" orientation="vertical" />
          <IconButton className="iconButton" aria-label="search" onClick={onSearchClick}>
            <SearchIcon />
          </IconButton>
        </Paper>

        {loading ? (
          <LoaderAbsoluteCentred loading={loading} />
        ) : (
          <AnimationWrapper>
            <div>
              {userDetails.map((userDetail) => {
                return <UserDetailsManagementContainerRow userDetail={userDetail} />;
              })}
              {(!userDetails || !userDetails.length) && (
                <div style={{ marginTop: 24 }}>
                  <WidgetNoResultsPlaceholder text="No Users" icon={HeaderIcon} flip={true} />
                </div>
              )}
            </div>
          </AnimationWrapper>
        )}
      </DivWrapper>
      <div style={{ textAlign: "center" }}>
        {pageNumber > 1 ? (
          <Button onClick={() => setPageNumber(pageNumber - 1)} variant="contained">
            Previous
          </Button>
        ) : null}
        {hasMorePages ? (
          <Button onClick={() => setPageNumber(pageNumber + 1)} variant="contained">
            Next
          </Button>
        ) : null}
        <LoaderAbsoluteCentred loading={loading} />
      </div>
    </WidgetSectionBase>
  );
};

function StatusFilterIcon({ checked }: { checked: boolean }) {
  return checked ? <CheckboxIconFilled fontSize="small" /> : <CheckboxIcon fontSize="small" />;
}

export default UserDetailsManagementContainer;
