import { IFile } from "./File";

export interface IModel {
  modelId: string;
  title: string;
  description: string;
  versionNumber: number;
  mainImageId: string;
  mainImage: IFile;
  embedCode: string;
  status: ModelStatusEnum;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
  maturity: Maturity;
  discussionCount: number;
}

export enum ModelStatusEnum {
  Private = 10,
  Public = 20,
  Draft = 30,
  PendingEdits = 40,
}

export enum ModelSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
  TitleAsc = 30,
}

export enum Maturity {
  UnderConstruction = 10,
  Low = 20,
  Medium = 30,
  High = 40,
}

export enum ModelFilterEnum {
  None = 10,
  Public_Models = 20,
  My_Models = 30,
  Shared_Private_Models = 40,
}
