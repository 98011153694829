import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import FormModelCreate from "../forms/FormModelCreate";
import { ModelStatusEnum, IModel } from "../../../utilities/types/Model";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/Build";

export interface IModalModelCreateProps {
  open: boolean;
  onCancelCallback(): void;
  status: ModelStatusEnum;
  onCompleteCallback(file?: IModel): void;
}

function ModalModelCreate({ onCancelCallback, open, onCompleteCallback, status }: IModalModelCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new asset type"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormModelCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          status={status}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalModelCreate;
