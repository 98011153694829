import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";

import LayersIcon from "@mui/icons-material/Layers";
import FormWorkflowUpdate from "../forms/FormWorkflowUpdate";
import { IModalWorkflowUpdateProps } from "../types";

const ModalWorkflowUpdate: React.FC<IModalWorkflowUpdateProps> = ({
  open,
  workflow,
  onCancelCallback,
  onCompleteCallback,
}) => {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title="Update a new workflow"
      subtitle="Fill in the form to complete."
      headerIcon={<LayersIcon />}
    >
      <DialogContent>
        <FormWorkflowUpdate
          onCancelCallback={onCancelCallback}
          workflow={workflow}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
};

export default ModalWorkflowUpdate;
