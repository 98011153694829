import React from "react";
import { Typography, styled } from "@mui/material";

import SummaryWidgetBase from "./WidgetSummaryBase";
import { MenuDropdown } from "./MenuDropdown";

const DivWrapper = styled("div")(({ theme }) => ({
  textAlign: "left",
  paddingLeft: 100,
  zIndex: 2,
  position: "absolute",
  maxWidth: "calc(100% - 30px)",
  "&.fullWidthHeader": {
    color: theme.palette.background.paper,
    top: 0,
    right: "auto",
  },
}));

const CustomStyledMenuDropdown = styled(MenuDropdown)(({ theme }) => ({
  "& .custom-menu-items": {
    zIndex: 2,
    position: "absolute",
    paddingRight: "32px",
  },
}));

const DivContent = styled("div")({
  marginTop: 65,
});

type WidgetSectionBaseProps = {
  headerIcon?: React.ReactElement<JSX.Element>;
  headerColor?: string;
  headerText: string;
  subheaderText: string;
  tag?: JSX.Element;
  menuItems?: React.ReactNode;
  children: React.ReactNode;
  fullWidthHeader?: boolean;
  style?: { [key: string]: any };
};

const WidgetSectionBase = ({
  headerText,
  subheaderText,
  tag,
  headerIcon,
  headerColor,
  menuItems,
  children,
  style,
  fullWidthHeader = false,
}: WidgetSectionBaseProps) => {
  return (
    <SummaryWidgetBase
      headerIcon={headerIcon}
      headerColor={headerColor}
      fullWidthHeader={fullWidthHeader}
      style={style}
    >
      <DivWrapper className={`${fullWidthHeader ? "fullWidthHeader" : null}`}>
        <Typography variant="body1" noWrap={true}>
          {headerText}
        </Typography>
        <Typography variant="caption" component={"p"} noWrap={true}>
          {subheaderText}
        </Typography>
      </DivWrapper>

      <CustomStyledMenuDropdown menuItems={menuItems} className="custom-menu-items" />
      {tag}
      <DivContent>{children}</DivContent>
    </SummaryWidgetBase>
  );
};

export default WidgetSectionBase;
