import { IDecision } from "../../utilities/types/Decision";


export const ACTIONS_DECISION = {
    INVALIDATE: 'INVALIDATE_DECISIONS',
    RECEIVE: 'RECEIVE_DECISIONS',
    DELETE: 'DELETE_DECISION',
    UPDATE: 'UPDATE_DECISIONS',
}

interface ActionDecisionsInvalidate {
    type: typeof ACTIONS_DECISION.INVALIDATE,
    byId: { [key: string]: IDecision },
}

interface ActionDecisionsReceive {
    type: typeof ACTIONS_DECISION.RECEIVE
    byId: { [key: string]: IDecision },
}

export interface DecisionState {
    byId: { [key: string]: IDecision },
}

export enum DecisionSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesDecision = ActionDecisionsReceive | ActionDecisionsInvalidate;