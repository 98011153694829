import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { IDecisionAssetInputFieldMapping } from "../../utilities/types/DecisionAssetInputFieldMapping";
import { selectorGetDecisionAssetInputFieldMappingsByDecisionId } from "../../redux/decisionAssetInputFieldMapping/selectors";
import DecisionAssetInputFieldMappingRenderer from "./DecisionAssetInputFieldMappingRenderer";
import ModalDecisionAssetInputFieldMappingCreate from "./modals/ModalDecisionAssetInputFieldMappingCreate";

type IDecisionAssetInputFieldMappingsRendererProps = {
  canEdit: boolean;
  modelId: string;
  decisionId: string;
  loading: boolean;
};

const DecisionAssetInputFieldMappingsRenderer = (props: IDecisionAssetInputFieldMappingsRendererProps) => {
  const { decisionId } = props;
  const decisionAssetInputFieldMappings = useSelector((store: RootState) =>
    selectorGetDecisionAssetInputFieldMappingsByDecisionId(store, decisionId)
  );

  return (
    <>
      <DecisionAssetInputFieldMappingsRendererDisplay
        {...props}
        decisionAssetInputFieldMappings={decisionAssetInputFieldMappings}
      />
    </>
  );
};

interface IDecisionAssetInputFieldMappingsRendererDisplayProps extends IDecisionAssetInputFieldMappingsRendererProps {
  decisionAssetInputFieldMappings: IDecisionAssetInputFieldMapping[];
}

function DecisionAssetInputFieldMappingsRendererDisplay(props: IDecisionAssetInputFieldMappingsRendererDisplayProps) {
  const { decisionAssetInputFieldMappings, modelId, decisionId, canEdit } = props;

  const [loading] = useState(false);
  const [showCreateMappingModal, setShowCreateMappingModal] = useState(false);

  function toggleCreateMappingModal() {
    setShowCreateMappingModal(!showCreateMappingModal);
  }

  return (
    <Grid container spacing={1}>
      {decisionAssetInputFieldMappings.map((decisionAssetInputFieldMapping) => {
        return (
          <Grid item xs={12}>
            <DecisionAssetInputFieldMappingRenderer
              decisionAssetInputFieldMappingId={decisionAssetInputFieldMapping.decisionAssetInputFieldMappingId}
              modelId={modelId}
              canEdit={canEdit}
            />
          </Grid>
        );
      })}

      <ModalDecisionAssetInputFieldMappingCreate
        open={showCreateMappingModal}
        onCancelCallback={toggleCreateMappingModal}
        onCompleteCallback={toggleCreateMappingModal}
        modelId={modelId}
        decisionId={decisionId}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </Grid>
  );
}

export default DecisionAssetInputFieldMappingsRenderer;
