import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { ISolver } from "../../utilities/types/Solver";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalSolverCreate from "./modals/ModalSolverCreate";
import { fetchSearchSolvers } from "../../redux/solver/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalSolverUpdate from "./modals/ModalSolverUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { selectorGetSolvers } from "../../redux/solver/selectors";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import HeaderIcon from "@mui/icons-material/Brightness4TwoTone";
import { useFetchSolversPageHook } from "./Hooks";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import EditSolverIcon from "@mui/icons-material/EditOutlined";
import ModalSolverInputFields from "../solverInputField/modals/ModalSolverInputFields";
import ModalSolverDefinitions from "./modals/ModalSolverDefinitions";
import ModalSolverOutputTypeMappings from "../solverOutputMappingType/modals/ModalSolverOutputTypeMappings";
import OutputMappingsIcon from "@mui/icons-material/BubbleChartTwoTone";
import SummaryOutputMappingsIcon from "@mui/icons-material/BubbleChart";
import ManageFieldsIcon from "@mui/icons-material/InputTwoTone";
import DownloadIcon from "@mui/icons-material/CloudDownloadTwoTone";
import ClassDefinitionsIcon from "@mui/icons-material/MapTwoTone";
import { DownloadObjectAsFile } from "../../utilities/Helpers";
import { fetchSearchSolverInputFields } from "../../redux/solverInputField/actions";
import { fetchSearchSolverInputFieldListValues } from "../../redux/solverInputFieldListValue/actions";
import { selectorGetFileById } from "../../redux/file/selectors";
import ModalSolverSummaryOutputTypeMappings from "../solverSummaryOutputMappingType/modals/ModalSolverSummaryOutputTypeMappings";

const imageDiameter = 50;

const DivWrapper = styled("div")(({ theme }) => ({
  "& .solverWrapper": {
    border: "1px solid #EEE",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  "& .solverImageWrapper": {
    width: imageDiameter,
    maxWidth: "8.3%",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    overflow: "hidden",
    borderRight: "rgba(0,0,0,0.1)",
  },
  "& .solverImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .solverMainWrapper": {
    marginLeft: theme.spacing(1),
  },
  "& .btnAddSolver": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
  },
}));

type ISolverEnquiryContainerProps = {
  canEdit: boolean;
};

const SolverEnquiryContainer = ({ canEdit }: ISolverEnquiryContainerProps) => {
  const dispatch = useDispatch();
  const solvers = useSelector((store: RootState) => selectorGetSolvers(store));

  const { fetching: fetchs } = useFetchSolversPageHook({
    pageNumber: 1,
    pageSize: 1,
    minPageNumberToFetch: 1,
  });

  const onUploadComplete = async (solverId: string) => {
    await dispatch(fetchSearchSolvers({ solverId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <SolverEnquiryContainerDisplay
      canEdit={canEdit}
      solvers={solvers}
      loading={fetchs}
      onUploadComplete={onUploadComplete}
    />
  );
};

type ISolverEnquiryContainerDisplayProps = {
  canEdit: boolean;
  solvers: ISolver[];
  loading: boolean;
  onUploadComplete(solverId: string): void;
};

const SolverEnquiryContainerDisplay = ({ solvers, loading, canEdit }: ISolverEnquiryContainerDisplayProps) => {
  const [showCreateSolverModal, setShowCreateSolverModal] = useState(false);
  const [solverToUpdate, setSolverToUpdate] = useState<ISolver>();

  function toggleShowCreateSolverModal() {
    setShowCreateSolverModal(!showCreateSolverModal);
  }

  function onSolverClick(solver: ISolver) {
    setSolverToUpdate(solver);
  }

  function hideSolverUpdate() {
    setSolverToUpdate(undefined);
  }

  return (
    <WidgetSectionBase
      headerText="Solvers"
      subheaderText="Create and maintain Solvers."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DivWrapper>
        {canEdit ? (
          <Button color="primary" variant="outlined" className="btnAddSolver" onClick={toggleShowCreateSolverModal}>
            Add Solver
          </Button>
        ) : null}

        {loading ? (
          <LoaderAbsoluteCentred loading={loading} />
        ) : (
          <AnimationWrapper>
            <div>
              {solvers.map((solver) => {
                return <SolverRow key={solver.solverId} solver={solver} canEdit={canEdit} onRowClick={onSolverClick} />;
              })}
              {(!solvers || !solvers.length) && (
                <WidgetNoResultsPlaceholder text="No solver job" icon={HeaderIcon} flip={true} />
              )}
            </div>
          </AnimationWrapper>
        )}

        <ModalSolverCreate
          open={showCreateSolverModal}
          onCancelCallback={toggleShowCreateSolverModal}
          onCompleteCallback={toggleShowCreateSolverModal}
        />

        {/* Update */}
        {solverToUpdate && (
          <ModalSolverUpdate
            open={solverToUpdate !== undefined}
            onCancelCallback={hideSolverUpdate}
            onCompleteCallback={hideSolverUpdate}
            solver={solverToUpdate}
            canEdit={canEdit}
          />
        )}
      </DivWrapper>
    </WidgetSectionBase>
  );
};

function SolverRow({
  solver,
  canEdit,
  onRowClick,
}: {
  solver: ISolver;
  canEdit: boolean;
  onRowClick(solver: ISolver): void;
}) {
  const imageUrl = useFileSourceSingleHook({ fileId: solver.mainImageId || "", fileType: FileTypeEnum.Image });
  const mainImage = useSelector((store: RootState) => selectorGetFileById(store, solver.mainImageId));
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showInputsModal, setShowInputsModal] = useState(false);
  const [showSolverDefinitions, setShowSolverDefinitions] = useState(false);
  const [showOutputMappings, setShowOutputMappings] = useState(false);
  const [showSummaryOutputMappings, setShowSummaryOutputMappings] = useState(false);
  const [downloadingExport, setDownloadingExport] = useState(false);
  const dispatch = useDispatch();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onEditClick() {
    setMenuAnchorEl(undefined);
    onRowClick(solver);
  }

  function toggleShowInputsModal() {
    setMenuAnchorEl(undefined);
    setShowInputsModal(!showInputsModal);
  }

  function toggleOutputMappingsModal() {
    setMenuAnchorEl(undefined);
    setShowOutputMappings(!showOutputMappings);
  }

  function toggleSummaryOutputMappingsModal() {
    setMenuAnchorEl(undefined);
    setShowSummaryOutputMappings(!showSummaryOutputMappings);
  }

  function toggleSolverDefinitionsModal() {
    setMenuAnchorEl(undefined);
    setShowSolverDefinitions(!showSolverDefinitions);
  }

  async function downloadSolver() {
    setDownloadingExport(true);
    setMenuAnchorEl(undefined);

    try {
      var solverInputFields = await dispatch(
        fetchSearchSolverInputFields({ solverId: solver?.solverId, pageNumber: 1, pageSize: 500 })
      );
      var solverInputFieldListValues = await dispatch(
        fetchSearchSolverInputFieldListValues({ solverId: solver?.solverId, pageNumber: 1, pageSize: 500 })
      );

      var outputObj = {
        solver,
        solverInputFields,
        solverInputFieldListValues,
        files: [mainImage],
      };

      DownloadObjectAsFile(outputObj, `${solver.name}.json`);
    } finally {
      setDownloadingExport(false);
    }
  }

  return (
    <>
      <Grid container className="solverWrapper">
        <LoaderAbsoluteCentred loading={downloadingExport} />
        <Grid item xs={3} md={2} lg={1}>
          <div className="solverImageWrapper">
            <div className="solverImage" style={{ backgroundImage: `url("${imageUrl}")` }} />
          </div>
        </Grid>
        <Grid item xs={6} md={7} lg={8} style={{ paddingLeft: 8 }}>
          <div className="solverMainWrapper">
            <div>
              <Typography variant="body1" noWrap={true}>
                {solver.name}
              </Typography>
              <Typography style={{ opacity: 0.8, fontSize: "85%" }} noWrap={true}>
                {solver.description}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          {canEdit ? (
            <>
              <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(undefined)}
              >
                <MenuItem onClick={onEditClick}>
                  <ListItemIcon>
                    <EditSolverIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Edit" />
                </MenuItem>
                <MenuItem onClick={toggleShowInputsModal}>
                  <ListItemIcon>
                    <ManageFieldsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Manage Fields" />
                </MenuItem>
                <MenuItem onClick={() => setShowSolverDefinitions(true)}>
                  <ListItemIcon>
                    <ClassDefinitionsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Class Definitions" />
                </MenuItem>
                <MenuItem onClick={() => setShowOutputMappings(true)}>
                  <ListItemIcon>
                    <OutputMappingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Output Mappings" />
                </MenuItem>
                <MenuItem onClick={() => setShowSummaryOutputMappings(true)}>
                  <ListItemIcon>
                    <SummaryOutputMappingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Summary Output Mappings" />
                </MenuItem>
                <MenuItem onClick={downloadSolver}>
                  <ListItemIcon>
                    <DownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Export" />
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </Grid>
      </Grid>

      {showInputsModal && (
        <ModalSolverInputFields
          open={showInputsModal}
          onCloseCallback={toggleShowInputsModal}
          solverId={solver.solverId}
          canEdit={canEdit}
        />
      )}

      {showSolverDefinitions && (
        <ModalSolverDefinitions
          open={showSolverDefinitions}
          onCancelCallback={toggleSolverDefinitionsModal}
          solverId={solver?.solverId ?? ""}
        />
      )}

      {showOutputMappings && (
        <ModalSolverOutputTypeMappings
          open={showOutputMappings}
          onCancelCallback={toggleOutputMappingsModal}
          solverId={solver?.solverId ?? ""}
        />
      )}

      {showSummaryOutputMappings && (
        <ModalSolverSummaryOutputTypeMappings
          open={showSummaryOutputMappings}
          onCancelCallback={toggleSummaryOutputMappingsModal}
          solverId={solver?.solverId ?? ""}
        />
      )}
    </>
  );
}

export default SolverEnquiryContainer;
