export interface IComment {
    commentId: string,
    discussionId: string,
    text: string,
    parentId: string,
    replyCount: number,
    status: CommentStatusEnum,
    created: number,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum CommentStatusEnum {
    Active = 10,
    Deleted = 20,
}

export enum CommentSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}