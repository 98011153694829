import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/InputTwoTone";
import FormDecisionAssetInputFieldMappingCreate from "../forms/FormDecisionAssetInputFieldMappingCreate";
import { IDecisionAssetInputFieldMapping } from "../../../utilities/types/DecisionAssetInputFieldMapping";

export interface IModalDecisionAssetInputFieldMappingCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  decisionId: string;
  onCompleteCallback(decisionAssetInputFieldMapping?: IDecisionAssetInputFieldMapping): void;
}

function ModalDecisionAssetInputFieldMappingCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  decisionId,
}: IModalDecisionAssetInputFieldMappingCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Link an input field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormDecisionAssetInputFieldMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          decisionId={decisionId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalDecisionAssetInputFieldMappingCreate;
