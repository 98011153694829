import { ActionTypesSolverJobTask, SolverJobTaskState, ACTIONS_SOLVER_JOB_TASK } from "./types";

const initialState: SolverJobTaskState = {
    byId: {},
}


export function solverJobTasksReducer(
    state = initialState,
    action: ActionTypesSolverJobTask
): SolverJobTaskState {
    switch(action.type){
        case ACTIONS_SOLVER_JOB_TASK.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_SOLVER_JOB_TASK.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_SOLVER_JOB_TASK.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}