import { RootState } from "..";
import { IAssetInputField } from "../../utilities/types/AssetInputField";


export function selectorGetAssetInputFieldsByModelId(state: RootState, modelId: string) : IAssetInputField[] {

    if (!state.assetInputFields || !state.assetInputFields.byId) return [];

    return sortAssetInputFieldsByOrderNumberAscending(Object.values(state.assetInputFields.byId).filter(x => x.modelId === modelId));
}


export function selectorGetAssetInputFieldsByCategoryId(state: RootState, assetInputFieldCategoryId: string) : IAssetInputField[] {

    if (!state.assetInputFields || !state.assetInputFields.byId) return [];

    return sortAssetInputFieldsByOrderNumberAscending(Object.values(state.assetInputFields.byId).filter(x => x.assetInputFieldCategoryId === assetInputFieldCategoryId));
}


export function selectorGetAssetInputFieldById(state: RootState, assetInputFieldId: string) : IAssetInputField | undefined {

    if (!state.assetInputFields || !state.assetInputFields.byId) return undefined;

    return state.assetInputFields.byId[assetInputFieldId];
}


// function sortAssetInputFieldsByCreatedDescending(assetInputFields: IAssetInputField[]) {
//     return assetInputFields.sort((a, b) => a.created.getTime() - b.created.getTime());
// }


// function sortAssetInputFieldsByOrderNumberDescending(assetInputFields: IAssetInputField[]) {
//     return assetInputFields.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortAssetInputFieldsByOrderNumberAscending(assetInputFields: IAssetInputField[]) {
    return assetInputFields.sort((a, b) => ((a.orderNumber - b.orderNumber) === 0) ? ((b.label.toLowerCase < a.label.toLowerCase) ? 1 : -1) : (a.orderNumber - b.orderNumber)) ;
}