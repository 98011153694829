export interface IPlan {
    planId: string,
    modelId: string,
    name: string,
    code: string,
    offset: number,
    primaryEventId?: string,
    eventInterval: number,
    orderNumber: number,
    doNotOptimise: boolean,
    planType: PlanTypeEnum,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum PlanSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export enum PlanTypeEnum {
    Recurring = 10,
    OneOff = 20,
}