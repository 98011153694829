import { RootState } from "..";
import { ISolverJob } from "../../utilities/types/SolverJob";


export function selectorGetSolverJobsByModelId(state: RootState, modelId: string) : ISolverJob[] {

    if (!state.solverJobs || !state.solverJobs.byId) return [];

    return sortSolverJobsByCreatedDescending(Object.values(state.solverJobs.byId).filter(x => x.modelId === modelId));
}


export function selectorGetSolverJobs(state: RootState) : ISolverJob[] {

    if (!state.solverJobs || !state.solverJobs.byId) return [];

    return sortSolverJobsByCreatedDescending(Object.values(state.solverJobs.byId));
}


export function selectorGetSolverJobById(state: RootState, solverJobId: string) : ISolverJob | undefined {

    if (!state.solverJobs || !state.solverJobs.byId) return undefined;

    return state.solverJobs.byId[solverJobId];
}


export function sortSolverJobsByCreatedDescending(solverJobs: ISolverJob[]) {
    return solverJobs.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}