import { Box, Grid, IconButton, Tab, Tabs, Typography, styled } from "@mui/material";

import {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";

import { Chart, registerables } from "chart.js";
import HeaderIcon from "@mui/icons-material/BarChart";
import ChartsPlaceholderIcon from "@mui/icons-material/BarChartTwoTone";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetSolverJobById } from "../../redux/solverJob/selectors";
import { selectorGetSolverJobTasksByJobId } from "../../redux/solverJobTask/selectors";
import { fetchSearchSolverUIResultSets } from "../../redux/solverUIResultSet/actions";
import {
  selectorGetSolverUIResultSetsBySolverJobTaskId,
  selectorGetSolverUIResultSetsBySolverJobTaskIds,
} from "../../redux/solverUIResultSet/selectors";
import { selectorGetSolverUIResultSetChartsBySolverJobTaskIds } from "../../redux/solverUIResultSetChart/selectors";
import { SolverJobStatusEnum } from "../../utilities/types/SolverJob";
import { ISolverJobTask, SolverJobTaskStatusEnum } from "../../utilities/types/SolverJobTask";
import { SolverUIResultSetChartTypeEnum } from "../../utilities/types/SolverUIResultSetChart";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { RenderTable } from "./SolverUIResultSetTableWidget";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

Chart.register(...registerables);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className="tabPanel"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const DivWrapper = styled("div")(({ theme }) => ({
  borderRight: "1px solid #EEE",
  maxHeight: 600,
  overflowY: "auto",
  overflowX: "hidden",
  paddingLeft: theme.spacing(1),

  "& .assetSelectorDisplayInternalWrapper": {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    borderRadius: theme.shape.borderRadius,
  },
  "& .loadAssetButton": {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-2),
    marginBottom: 0,
  },
}));

interface ISolverUIResultSetChartWidgetProps {
  solverJobId: string;
}

interface ChartData {
  name: string;
  labels: string[];
  datasets: ChartDataDataset[];
  chartType: SolverUIResultSetChartTypeEnum;
}

interface ChartDataDataset {
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  data: number[];
  label: string;
}

const SolverUIResultSetChartWidget = ({ solverJobId }: ISolverUIResultSetChartWidgetProps) => {
  const dispatch = useDispatch();
  const [addedSolverJobTasks, setAddedSolverJobTasks] = useState<ISolverJobTask[]>([]);

  const resultSets = useSelector((store: RootState) =>
    selectorGetSolverUIResultSetsBySolverJobTaskIds(
      store,
      addedSolverJobTasks.map((x) => x.solverJobTaskId)
    )
  );
  const charts = useSelector((store: RootState) =>
    selectorGetSolverUIResultSetChartsBySolverJobTaskIds(
      store,
      addedSolverJobTasks.map((x) => x.solverJobTaskId)
    )
  );

  const [tabValue, setTabValue] = useState(0);
  const [jobChartData, setChartData] = useState<ChartData[]>();

  const [chartsBuilt, setChartsBuilt] = useState(false);
  const [buildingCharts, setBuildingCharts] = useState(false);

  const solverJobTasks = useSelector((store: RootState) => selectorGetSolverJobTasksByJobId(store, solverJobId));
  const solverJob = useSelector((store: RootState) => selectorGetSolverJobById(store, solverJobId));

  async function toggleSolverJobTaskInCharts(solverJobTask: ISolverJobTask) {
    setBuildingCharts(true);

    if (addedSolverJobTasks.some((x) => x.solverJobTaskId === solverJobTask.solverJobTaskId)) {
      setAddedSolverJobTasks([
        ...addedSolverJobTasks.filter((x) => x.solverJobTaskId !== solverJobTask.solverJobTaskId),
      ]);
    } else {
      // Fetch the chart
      if (solverJobTask.preSignedUrl) {
        await dispatch(
          fetchSearchSolverUIResultSets({
            solverJobTaskId: solverJobTask.solverJobTaskId,
            preSignedUrl: solverJobTask.preSignedUrl,
          })
        );
      }

      setAddedSolverJobTasks([
        ...addedSolverJobTasks.filter((x) => x.solverJobTaskId !== solverJobTask.solverJobTaskId),
        solverJobTask,
      ]);
    }

    // Flag chart rebuild required
    setChartsBuilt(false);
  }

  const handleTabChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (chartsBuilt) return;

    function GetChartsByName(chartName: string) {
      return charts.filter((x) => x.name === chartName);
    }

    function GetRelevantTasks(solverJobTaskId: string) {
      return addedSolverJobTasks.find((x) => x.solverJobTaskId === solverJobTaskId);
    }

    var newCharts: ChartData[] = [];

    // Loop through each chart type
    var distinctChartNames = charts
      .filter((c) => !c.hidden)
      .map((x) => x.name)
      .filter((v, i, a) => a.indexOf(v) === i);
    for (var distinctChartName of distinctChartNames) {
      var chartsByName = GetChartsByName(distinctChartName);

      // Prepare each chart's data
      var newChartData = {
        name: distinctChartName,
        labels: [],
        datasets: [],
        chartType: chartsByName[0].chartType,
      } as ChartData;

      newChartData.labels = chartsByName[0]?.chartPlots?.map((x) => String(x[0]));

      // Grab all chart's with current name
      for (var i = 0; i < chartsByName.length; i++) {
        var chart = chartsByName[i];
        var relevantTask = GetRelevantTasks(chart.solverJobTaskId);
        var dataset = {
          label:
            chart?.solverUIResultSetChartId ||
            relevantTask?.assetId ||
            relevantTask?.solverJobTaskId ||
            "Task not found.",
          data: chart?.chartPlots?.map((x) => x[1]),
          backgroundColor: [`${ChartColors[i]}25`],
          hoverBackgroundColor: [ChartColors[i]],
          borderColor: [`${ChartColors[i]}50`],
          pointRadius: 0,
          fill: false,
        };

        newChartData.datasets.push(dataset);
      }

      // Add to the pending list of charts
      newCharts.push(newChartData);
    }

    // Add all charts to state now that they're ready
    setChartData(newCharts);
    setChartsBuilt(true);
    setBuildingCharts(false);
  }, [addedSolverJobTasks, charts, chartsBuilt]);

  function a11yProps(index: number) {
    return {
      id: `solver-auto-tab-${index}`,
      "aria-controls": `solver-auto-tabpanel-${index}`,
    };
  }

  return (
    <WidgetSectionBase
      headerText="Charts"
      subheaderText="Click on an asset tag to add it to your charts."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={false}
    >
      <Grid container spacing={3}>
        {solverJob?.status !== SolverJobStatusEnum.Complete ? (
          <Grid item xs={12}>
            <WidgetNoResultsPlaceholder
              text="Charts not ready"
              description="Data available when solver job completes."
              icon={ChartsPlaceholderIcon}
              flip={true}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={3} lg={2}>
              <DivWrapper>
                <Grid container>
                  {solverJobTasks.map((x) => {
                    return (
                      <Grid item xs={12}>
                        <AssetSelectorDisplay
                          onClick={(clickedTask: ISolverJobTask) => toggleSolverJobTaskInCharts(clickedTask)}
                          selected={addedSolverJobTasks.some((y) => y.solverJobTaskId === x.solverJobTaskId)}
                          solverJobTask={x}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </DivWrapper>
            </Grid>

            <Grid item xs={9} lg={10}>
              {resultSets?.length ? (
                <>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="View Meta Data" {...a11yProps(0)} />
                    <Tab label="View Charts" {...a11yProps(1)} />
                  </Tabs>

                  <TabPanel value={tabValue} index={0}>
                    {resultSets?.map((x) => (
                      <>
                        {x.metaData && !x.hideMetaData && (
                          <Grid item xs={12} spacing={2}>
                            <RenderTable title="Meta Data" data={Object.entries(x.metaData)} />
                          </Grid>
                        )}

                        {x.tables
                          ?.filter((t) => !t.hidden)
                          .map((t) => (
                            <Grid item xs={12} spacing={2}>
                              <RenderTable {...t} />
                            </Grid>
                          ))}
                      </>
                    ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={3} style={{ marginTop: 4 }}>
                      {jobChartData?.map((x) => (
                        <RenderChartType key={x.name} {...x} />
                      ))}
                    </Grid>
                  </TabPanel>
                </>
              ) : (
                <Grid item xs={12} style={{ marginTop: 64 }}>
                  <WidgetNoResultsPlaceholder
                    text="No assets selected"
                    description="Click on an asset to generate charts"
                    icon={ChartsPlaceholderIcon}
                    flip={true}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <LoaderAbsoluteCentred loading={buildingCharts} />
    </WidgetSectionBase>
  );
};

interface IAssetSelectorDisplayProps {
  solverJobTask: ISolverJobTask;
  onClick(solverJobTask: ISolverJobTask): void;
  selected: boolean;
}

function RenderChartType(_chart: ChartData) {
  const chart = { ..._chart }; // Fix read-only property error
  switch (chart.chartType) {
    case SolverUIResultSetChartTypeEnum.Line:
      return (
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" align="center">
            {chart.name}
          </Typography>
          <Line
            data={chart}
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
            }}
          />
        </Grid>
      );
    case SolverUIResultSetChartTypeEnum.BarVertical:
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              {chart.name}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ height: "30vh" }}>
            <Bar
              data={chart}
              options={{
                plugins: {
                  legend: {
                    position: "right",
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    beginAtZero: true,
                    stacked: true,
                  },
                  y: {
                    beginAtZero: true,
                    stacked: true,
                  },
                },
              }}
            />
          </Grid>
        </>
      );
    case SolverUIResultSetChartTypeEnum.StackedLine:
      return (
        <Grid item style={{ width: '100%', maxHeight: '600px' }}>
          <Typography variant="subtitle1" align="center">
            {chart.name}
          </Typography>
          <Line
            data={chart}
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  beginAtZero: true,
                  stacked: true,
                },
                y: {
                  beginAtZero: true,
                  stacked: true,
                },
              },
            }}
          />
        </Grid>
      );
    default:
      return null;
  }
}

function AssetSelectorDisplay({ solverJobTask, onClick, selected }: IAssetSelectorDisplayProps) {
  const summaryData = useSelector((store: RootState) =>
    selectorGetSolverUIResultSetsBySolverJobTaskId(store, solverJobTask.solverJobTaskId)
  );

  return (
    <div className="assetSelectorDisplayInternalWrapper">
      <Typography variant="subtitle1" onClick={() => onClick(solverJobTask)} style={{ cursor: "pointer" }}>
        {summaryData && (
          <IconButton
            color={selected ? "primary" : "default"}
            className="loadAssetButton"
            {...(solverJobTask.status !== SolverJobTaskStatusEnum.Complete ? { disabled: true } : null)}
          >
            {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </IconButton>
        )}
        {solverJobTask.assetId || `Single Asset ${solverJobTask.solverJobTaskId.substring(0, -4)}`}
      </Typography>
    </div>
  );
}

const ChartColors = [
  blue[500],
  amber[500],
  cyan[500],
  deepOrange[500],
  deepPurple[500],
  green[500],
  grey[500],
  indigo[500],
  lightBlue[500],
  lightGreen[500],
  lime[500],
  orange[500],
  pink[500],
  purple[500],
  red[500],
  teal[500],
  yellow[500],
  brown[500],
];

export default SolverUIResultSetChartWidget;
