import { RootState } from "..";
import { IModel } from "../../utilities/types/Model";


export function selectorGetModelById(state: RootState, modelId: string) : IModel | undefined {

    if (!state.models || !state.messages.byId) return undefined;

    return state.models.byId[modelId];
}


export function selectorGetModelsByUpdatedDesc(state: RootState) : IModel[] {

    if(!state.models || !state.models.byId) return [];

    return sortModelsByUpdatedDescending(Object.values(state.models.byId));
}


export function sortModelsByTitleAsc(models: IModel[]) : IModel[] {
     return models.sort((a, b) => a.title === b.title ? 0 : a.title < b.title ? -1 : 1);
}


function sortModelsByUpdatedDescending(models: IModel[]) {
    return models.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
}