import { RootState } from "..";
import { ISummaryOutputJob } from "../../utilities/types/SummaryOutputJob";

export function selectorGetSummaryOutputJobByJobId(state: RootState, solverJobId: string): ISummaryOutputJob[] {
  if (!state.summaryOutputJob || !state.summaryOutputJob.byId) return [];

  return sortSummaryOutputJobByCreatedDescending(
    Object.values(state.summaryOutputJob.byId).filter((x) => x.solverJobId === solverJobId)
  );
}

export function selectorGetSummaryOutputJobById(
  state: RootState,
  solverJobSummaryReportId: string
): ISummaryOutputJob | undefined {
  if (!state.summaryOutputJob || !state.summaryOutputJob.byId) return undefined;

  return state.summaryOutputJob.byId[solverJobSummaryReportId];
}

export function sortSummaryOutputJobByCreatedDescending(summaryOutputJob: ISummaryOutputJob[]) {
  return summaryOutputJob.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}
