import { RootState } from "..";
import { ISolverDetails } from "../../utilities/types/SolverDetails";

export function selectorGetSolverDetailsBySolverDetailsId(state: RootState, solverDetailsId: string) : ISolverDetails | undefined {

    if (!state.solverDetails || !state.solverDetails.byId) return undefined;

    return state.solverDetails.byId[solverDetailsId];
}

export function selectorGetSolverDetailsBySolverId(state: RootState, solverId: string) : ISolverDetails | undefined {

    if (!state.solverDetails || !state.solverDetails.byId) return undefined;
    return filterSolverDetailsBySolver(Object.values(state.solverDetails.byId), solverId);
}


export function selectorGetSolverDetails(state: RootState) : ISolverDetails[] {

    if (!state.solverDetails || !state.solverDetails.byId) return [];

    return Object.values(state.solverDetails.byId);
}

export function filterSolverDetailsBySolver(solverDetails: ISolverDetails[], solverId: string) : ISolverDetails |undefined {
    if (solverDetails.length === 0) return undefined;
    return solverDetails.filter(x => x.solverId === solverId)[0];
}

