import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import RouteTask from './RouteTask';


const RouteTasks = () => {

    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:taskId`}><RouteTask /></Route>
        </Switch>
    )
}


export default RouteTasks;