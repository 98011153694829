import { IUserDetail } from '../../utilities/types/UserDetail'
import { ACTIONS_USER_DETAIL } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, AddQueryStringsToUrl, CheckStatus, ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { selectorGetUserDetailById } from './selectors';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';


export const receiveUserDetails = (userDetails: IUserDetail[]) => {

    var byIdObjectToDispatch: { [key: string]: IUserDetail } = {};

    for (var i = 0; i < userDetails.length; i++) {
        byIdObjectToDispatch[userDetails[i].userDetailId] = userDetails[i];
    }

    return {
        type: ACTIONS_USER_DETAIL.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const fetchUserDetailsByIdIfNeeded = (userDetailId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetUserDetailById(getState(), userDetailId)) {
        await dispatch(fetchSearchUserDetails({ userDetailId, pageNumber: 1, pageSize: 1 }));
    }

    return;
}


export interface IFetchSearchUserDetails {
    userIds?: number[],
    pageSize?: number,
    pageNumber?: number,
    userDetailId?: string,
    text?: string,
}


export const fetchSearchUserDetails = ({ userIds = [], pageSize, pageNumber, userDetailId, text }: IFetchSearchUserDetails): AppThunk<Promise<IUserDetail[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/userDetails`, {
            userIds: userIds.join('_'),
            pageSize,
            pageNumber,
            userDetailId,
            text,
        }), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        return await CheckStatus(apiResponse);
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving user details.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchUpdateUserDetailProps {
    userDetailId: string,
    displayName: string,
    company?: string,
    linkedIn?: string,
    city?: string,
    country?: string,
    aboutMe?: string,
}


export const fetchUpdateUserDetail = (props: IFetchUpdateUserDetailProps): AppThunk<Promise<IUserDetail>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/userDetails`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userDetails && parsedResp.data.userDetails.length) {
            dispatch(receiveUserDetails(parsedResp.data.userDetails));
            return parsedResp.data.userDetails[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating userDetail.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating userDetail.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}