import { IAssetJob } from "../../utilities/types/AssetJobs";

export const ACTIONS_ASSET_JOB = {
  INVALIDATE: "INVALIDATE_ASSET_JOBS",
  RECEIVE: "RECEIVE_ASSET_JOBS",
  DELETE: "DELETE_ASSET_JOB",
  UPDATE: "UPDATE_ASSET_JOBS",
};

interface ActionAssetJobsInvalidate {
  type: typeof ACTIONS_ASSET_JOB.INVALIDATE;
  byId: { [key: string]: IAssetJob };
}

interface ActionAssetJobsReceive {
  type: typeof ACTIONS_ASSET_JOB.RECEIVE;
  byId: { [key: string]: IAssetJob };
}

export interface AssetJobState {
  byId: { [key: string]: IAssetJob };
}

export enum AssetJobSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type ActionTypesAssetJob = ActionAssetJobsReceive | ActionAssetJobsInvalidate;
