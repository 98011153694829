import { Typography, Collapse, styled } from "@mui/material";
import { IComment, CommentStatusEnum } from "../../utilities/types/Comment";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetCommentsByDiscussionAndParentId } from "../../redux/comment/selectors";
import { useFetchCommentsPageHook } from "./Hooks";
import DiscussionUserAvatar from "../discussion/DiscussionUserAvatar";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import moment from "moment";
import { selectorGetUserDetailById } from "../../redux/userDetail/selectors";

import CommentActions from "./CommentActions";

const DivWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  "& .truncate": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    wordBreak: "break-all",
  },
  "& .discussionText": {
    whiteSpace: "pre-line",
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.5),
  },
  "& .deleted": {
    filter: "grayscale(1)",
    fontStyle: "italic",
    opacity: 0.5,
  },
  "& .discussionRow": {
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    alignItems: "start",
    gap: theme.spacing(1.5),
    "&.pt-0": {
      paddingTop: 0,
    },
  },

  "& .linkText": {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

function CommentRenderer({
  comment,
  entityId,
  entityType,
  commentLevel,
  handleRefetchComment,
}: {
  comment: IComment;
  entityId: string;
  entityType: EntityTypeEnum;
  commentLevel: number;
  handleRefetchComment: () => void;
}) {
  const [pageNumber, setPageNumber] = useState(0);
  const childComments = useSelector((store: RootState) =>
    selectorGetCommentsByDiscussionAndParentId(store, comment.discussionId, comment.commentId)
  );
  const isAdmin = useSelector((store: RootState) => selectorGetUserPermissionIsAdmin(store));
  const canEdit = (isAdmin || comment.createdBy === GetUserId()) && comment.status === CommentStatusEnum.Active;

  const userDetails = useSelector((store: RootState) => selectorGetUserDetailById(store, comment.createdBy));

  // For retrieving replies
  useFetchCommentsPageHook({
    pageNumber: pageNumber,
    pageSize: 10,
    minPageNumberToFetch: 1,
    discussionId: comment.discussionId,
    parentId: comment.commentId,
  });

  const getNextPageOfComments = () => setPageNumber(pageNumber + 1);
  const closeComments = () => setPageNumber(0);

  const handleRefetch = () => {
    handleRefetchComment();
    if (pageNumber === 0) {
      getNextPageOfComments();
    } else {
      setPageNumber(pageNumber);
    }
  };

  return (
    <>
      <DivWrapper>
        <div className={` ${comment.status === CommentStatusEnum.Deleted ? "deleted" : ""}`}>
          <div className="discussionRow pt-0">
            <DiscussionUserAvatar
              userId={comment.status === CommentStatusEnum.Active ? comment.createdBy : ""}
              showName={false}
            />

            <div className="discussion-content">
              <Typography variant="caption" style={{ fontWeight: 600 }}>
                {userDetails?.displayName || ""}{" "}
              </Typography>
              <Typography variant="caption" style={{ fontSize: "0.55rem" }}>
                ({moment.utc(comment.created).fromNow()})
              </Typography>
              <Typography style={{ fontSize: "0.75rem" }} className="discussionText">
                {comment.text}
              </Typography>
              <CommentActions
                canEdit={canEdit}
                commentLevel={commentLevel}
                entityId={entityId}
                entityType={entityType}
                discussionOrComment={comment}
                isComment={true}
                handleRefetch={handleRefetch}
              />

              {comment.replyCount > 0 && pageNumber === 0 && (
                <AnimationWrapper>
                  <Typography variant="caption" className="linkText" onClick={getNextPageOfComments}>
                    View {comment.replyCount} Replies
                  </Typography>
                </AnimationWrapper>
              )}
            </div>
          </div>
        </div>

        {comment.replyCount > 0 && pageNumber !== 0 && (
          <div className="collapser" onClick={closeComments}>
            <div className="collapser-bar" />
          </div>
        )}
        {childComments.length > 0 ? (
          <div style={{ paddingLeft: 34 }}>
            <Collapse in={pageNumber !== 0}>
              {childComments.map((childComment) => {
                return (
                  <div>
                    <CommentRenderer
                      comment={childComment}
                      entityId={entityId}
                      entityType={entityType}
                      commentLevel={commentLevel + 1}
                      handleRefetchComment={handleRefetch}
                    />
                  </div>
                );
              })}
            </Collapse>
          </div>
        ) : null}
      </DivWrapper>
    </>
  );
}

export default CommentRenderer;
