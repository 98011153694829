import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  InputBase,
  ListItemText,
  Menu,
  MenuItem,
  Paper as MuiPaper,
  PaperProps,
  Typography,
  styled,
} from "@mui/material";

import FilterIcon from "@mui/icons-material/FilterList";
import CheckboxIconFilled from "@mui/icons-material/RadioButtonCheckedRounded";
import CheckboxIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  IFetchSearchModelsFilterProps as FilterProps,
  ModelSearchFavouriteFilterType as FavFilterType,
} from "../../redux/model/actions";
import { MaturityLabelMap } from "../../utilities/labels";
import { IModel, Maturity, ModelFilterEnum, ModelSearchOrderTypeEnum } from "../../utilities/types/Model";
import { FavouriteIcon } from "../favourite/FavouriteButton";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { IUseFetchModelsPageHookProps, useFetchModelsPageHook } from "./Hooks";
import { ModelMaturityIcon } from "./ModelSearchResultWidget";

const Paper = styled(MuiPaper)<PaperProps>(({ theme }) => ({
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  boxShadow: "none",
  border: "1px solid #DEDEDE",
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: "28px !important",
    margin: "4px !important",
  },
  "& .filterMenuContainer": {
    minWidth: 200,
    paddingRight: theme.spacing(1),
  },
  "& .filterMenuItemRadio": {
    width: "1.2em",
    marginRight: theme.spacing(1),
  },
  "& .filterMenuItemIcon": {
    width: "1.2em",
    marginLeft: theme.spacing(1),
  },
}));

interface IModelsSearchWidgetProps {
  setReturnedResultSet(models: IModel[], morePages: boolean): void;
}

export const ModelsSearchWidget = ({ setReturnedResultSet }: IModelsSearchWidgetProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 11; // Set the desired page size here

  const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchModelsPageHookProps>({
    pageNumber: currentPage,
    pageSize: pageSize,
    minPageNumberToFetch: currentPage,
    text: "",
    orderType: ModelSearchOrderTypeEnum.TitleAsc,
  });

  // Fetch models for the current page
  const {
    fetching: fetchingModels,
    lastResultSet,
    morePages,
    totalCount,
  } = useFetchModelsPageHook({
    ...currentSearchValues,
  });

  // Function to handle page change
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setCurrentSearchValues({ ...currentSearchValues, pageNumber: newPage });
  };
  const GetNextPage = (newSearchValues: IUseFetchModelsPageHookProps) => {
    setCurrentSearchValues(newSearchValues);
  };

  useEffect(() => {
    setReturnedResultSet(lastResultSet, morePages);
  }, [lastResultSet, setReturnedResultSet, morePages]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <ModelSearchWidgetCriteriaDisplay
          loading={fetchingModels}
          currentSearchValues={{ ...currentSearchValues }}
          setCurrentSearchValues={GetNextPage}
          onSearch={() => {
            setCurrentPage(1);
          }}
        />
      </Grid>
      <Grid item xs={3} style={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
        <Pagination currentPage={currentPage} pageSize={pageSize} totalItems={totalCount} onChange={handlePageChange} />
      </Grid>
      <LoaderAbsoluteCentred loading={fetchingModels} />
    </Grid>
  );
};

interface IModelSearchWidgetCriteriaDisplay {
  currentSearchValues: IUseFetchModelsPageHookProps;
  setCurrentSearchValues(props: IUseFetchModelsPageHookProps): void;
  onSearch: () => void; // Add this prop for handling search initiation
  loading: boolean;
}

function ModelSearchWidgetCriteriaDisplay({
  currentSearchValues,
  setCurrentSearchValues,
  onSearch,
  loading,
}: IModelSearchWidgetCriteriaDisplay) {
  const [searchText, setSearchTextFilter] = useState<string>();

  const searchWithFilters = (filters?: FilterProps) =>
    setCurrentSearchValues({ ...currentSearchValues, ...filters, text: searchText });
  function runSearch(e: any) {
    currentSearchValues.pageNumber = 1; //Set page back to 1 for a new search
    searchWithFilters();
    e?.preventDefault?.();
    onSearch(); // Call the onSearch prop when the search is initiated
  }

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  return (
    <Paper component="form" onSubmit={runSearch}>
      <FilterButton onFilterChange={searchWithFilters} />
      <Divider className="divider" orientation="vertical" />
      <InputBase
        className="input"
        placeholder="e.g. Pump"
        inputProps={{ "aria-label": "Search Asset Types" }}
        onChange={onSearchTextChangeHandler}
        type="text"
        onSubmit={runSearch}
      />
      <Divider className="divider" orientation="vertical" />
      <IconButton
        className="iconButton"
        aria-label="search"
        onClick={runSearch}
        disabled={loading}
        id="btn-models-search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

function FilterButton(props: { onFilterChange: (filters: FilterProps) => void }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const openMenu = (event: any) => setMenuAnchorEl(event.currentTarget);
  const closeMenu = () => setMenuAnchorEl(undefined);

  // 'true/false' to filter by favourites or not, and 'undefined' for no filter.
  const [filterFav, setFilterFav] = useState<boolean>();

  // Set to 'undefined' to not filter.
  const [filterMaturity, setFilterMaturity] = useState<Maturity>();
  const [filterModel, setFilteModel] = useState<ModelFilterEnum>(ModelFilterEnum.None);
  const maturityFilters = [Maturity.UnderConstruction, Maturity.Low, Maturity.Medium, Maturity.High];
  const modelsFilters = Object.entries(ModelFilterEnum)
    .filter(([key, _]) => isNaN(Number(key))) // Filter out numeric keys
    .map(([key, value]) => ({
      label: key.replace(/_/g, " "),
      value: value as number, // Ensure value is of type number
    }));

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded)
      props.onFilterChange({
        favouriteFilterType: filterFav ? FavFilterType.IsFavourite : undefined,
        maturityFilterType: filterMaturity,
        modelFilterType: filterModel,
      });
    else setLoaded(true);
    // eslint-disable-next-line
  }, [loaded, filterFav, filterMaturity, filterModel]);

  return (
    <>
      <Button aria-label="menu" onClick={openMenu} style={{ minWidth: 32 }}>
        <FilterIcon />
      </Button>
      <Menu
        className="filterMenuContainer"
        id="filter-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={!!menuAnchorEl}
        onClose={closeMenu}
      >
        <div style={{ paddingRight: 16, paddingLeft: 16 }}>
          <Typography variant="overline">Favourite Filter</Typography>
        </div>
        <Divider />
        <MenuItem onClick={() => setFilterFav(undefined)}>
          <StatusFilterIcon checked={filterFav === undefined} />
          <ListItemText primary="None" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setFilterFav(true)}>
          <StatusFilterIcon checked={filterFav === true} />
          <ListItemText primary="My Favourites" />
          <FavouriteIcon className="filterMenuItemIcon" isFavourite />
        </MenuItem>
        <Divider />
        <div style={{ paddingRight: 16, paddingLeft: 16 }}>
          <Typography variant="overline">Maturity Filter</Typography>
        </div>
        <MenuItem onClick={() => setFilterMaturity(undefined)}>
          <StatusFilterIcon checked={!filterMaturity} />
          <ListItemText primary="None" />
        </MenuItem>
        {maturityFilters.map((maturity) => [
          <Divider />,
          <MenuItem onClick={() => setFilterMaturity(maturity)}>
            <StatusFilterIcon checked={filterMaturity === maturity} />
            <ListItemText primary={MaturityLabelMap[maturity]} />
            <ModelMaturityIcon maturity={maturity} className="filterMenuItemIcon" />
          </MenuItem>,
        ])}
        <Divider />
        <div style={{ paddingRight: 16, paddingLeft: 16 }}>
          <Typography variant="overline">Models Filter</Typography>
        </div>
        {modelsFilters.map(({ label, value }) => [
          <Divider />,
          <MenuItem onClick={() => setFilteModel(value)}>
            <StatusFilterIcon checked={value === filterModel} />
            <ListItemText primary={label} />
          </MenuItem>,
        ])}
      </Menu>
    </>
  );
}

function StatusFilterIcon({ checked }: { checked: boolean }) {
  const className = "filterMenuItemRadio";
  return checked ? (
    <CheckboxIconFilled fontSize="small" className={className} />
  ) : (
    <CheckboxIcon fontSize="small" className={className} />
  );
}

export default ModelsSearchWidget;

interface IPaginationProps {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  onChange: (newPage: number) => void;
}

const Pagination = ({ currentPage, pageSize, totalItems, onChange }: IPaginationProps) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  // Helper function to generate an array of page numbers
  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div>
      {totalPages > 1 && (
        <ButtonGroup variant="outlined" color="primary">
          {getPageNumbers().map((page) => (
            <Button
              key={page}
              color={currentPage === page ? "primary" : undefined}
              variant={currentPage === page ? "contained" : "outlined"}
              onClick={() => onChange(page)}
            >
              {page}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </div>
  );
};
