import React, { useState } from "react";
import { TextField, Grid, Divider, Button, Switch, FormControlLabel } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { getFormikFieldProps } from "../../../utilities/Helpers";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { fetchCreateCostBundle } from "../../../redux/costBundle/actions";
import { fetchSearchAssetInputFieldCategories } from "../../../redux/assetInputFieldCategory/actions";
import { fetchSearchAssetInputFields } from "../../../redux/assetInputField/actions";
import StringListInput from "../../generic/widgets/StringListInput";

const formValidationSchema = Yup.object().shape({
  name: Yup.array().of(Yup.string().label("Name").min(3, "Please input 3 characters or more").max(500, "Please input 500 characters or less")),
  cost: Yup.number().min(0, "Zero or more."),
  orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
});

interface FormValues {
  name: string[];
  code: string;
  cost: number;
  orderNumber: number;
  createCorrespondingInput: boolean;
  disabled: boolean;
}

interface FormProps {
  costBundle: ICostBundle;
  onCompleteCallback(): void;
  onCancelCallback(): void;
}

const FormCostBundleDuplicate = ({ onCompleteCallback, onCancelCallback, costBundle }: FormProps) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values: FormValues) => {
    setSubmitting(true);
    const costsToCreate = values.name.map((name: string) => ({
      ...values,
      modelId: costBundle.modelId,
      name,
      code: name,
    }));

    // Map dispatch via props
    var createdCostBundle: any = await dispatch(fetchCreateCostBundle({ costBundles: costsToCreate }));

    setSubmitting(false);

    if (createdCostBundle) {
      // Get new categories if any
      await dispatch(
        fetchSearchAssetInputFieldCategories({
          pageNumber: 1,
          pageSize: 20,
          modelId: costBundle.modelId,
        })
      );

      // Get new inputs
      await dispatch(
        fetchSearchAssetInputFields({
          pageNumber: 1,
          pageSize: 500,
          modelId: costBundle.modelId,
        })
      );
      onCompleteCallback();
    }
  };

  return (
    <Formik
      initialValues={{
        code: costBundle.code,
        cost: costBundle.cost,
        orderNumber: costBundle.orderNumber,
        name: [costBundle.name],
        createCorrespondingInput: true,
        disabled: false,
      }}
      validationSchema={formValidationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StringListInput
                label="Name(s)"
                onStringListChange={(newStringList) =>
                  props.handleChange({
                    target: {
                      name: "name",
                      value: newStringList,
                    },
                  })
                }
                stringList={props.values.name}
                errors={props.errors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                onChange={props.handleChange}
                type="number"
                fullWidth
                variant="standard"
                margin="normal"
                {...getFormikFieldProps(props, "cost", "Cost")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                onChange={props.handleChange}
                type="number"
                fullWidth
                margin="normal"
                variant="standard"
                {...getFormikFieldProps(props, "orderNumber", "Order Number")}
              />
            </Grid>

            <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.values.createCorrespondingInput}
                    onChange={() => {
                      props.setFieldValue("createCorrespondingInput", !props.values.createCorrespondingInput);
                    }}
                    name="CreateInput"
                  />
                }
                label="Create Corresponding Input"
              />
            </Grid>

            <Grid item xs={12}>
              <Divider light={true} />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button variant="text"  onClick={onCancelCallback}>
                Close
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button type="submit" disabled={submitting} variant="outlined" color="primary">
                Create
              </Button>
              <LoaderAbsoluteCentred loading={submitting} />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default FormCostBundleDuplicate;
