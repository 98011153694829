import { Breadcrumbs, Container, Grid, Paper, Typography, Step, StepButton, Stepper, styled } from "@mui/material";
import Link from "@mui/material/Link";

import NoResultsIcon from "@mui/icons-material/WorkOffTwoTone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, RouteComponentProps, useHistory } from "react-router-dom";
import { RootState } from "../../../redux";
import { fetchSearchAssetInputFields } from "../../../redux/assetInputField/actions";
import { selectorGetAssetInputFieldsByModelId } from "../../../redux/assetInputField/selectors";
import { fetchSearchAssetInputFieldListValues } from "../../../redux/assetInputFieldListValue/actions";
import { fetchModelByIdIfNeeded } from "../../../redux/model/actions";
import { selectorGetModelById } from "../../../redux/model/selectors";
import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";
import { GetDashboardPage } from "../../../routes/RouteLinkHelpers";
import { getAssetDefaultFieldValue } from "../../assetInputField/defaultFieldHelper";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import JobCreateSolverJobStep from "../JobCreateSolverJobStep";
import JobCreateCustomiseStep from "../JobCreateCustomiseStep";
import JobCreateReportsStep from "../JobCreateReportsStep";
import JobCreateReviewStep from "../JobCreateReviewStep";
import useJobCreateState, { InitJobCreateState, useJobCreateSummaryReports, WizardStep } from "../WizardState";
import { AssetInputValuesObjectDefault, USE_DEV_DEFAULTS } from "../WizardStateDefaults";
import blueGrey from "@mui/material/colors/blueGrey";
import { HomeIconLink } from "../../generic/HomeIconLink";
import { useFetchSolversPageHook } from "../../solver/Hooks";

const ContainerWrapper = styled(Container)(({ theme }) => ({
  "& .breadcrumbWrapper": {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  "& .wrapper": {
    position: "relative",
    paddingTop: theme.spacing(8),
  },
  "& .steps-nav-wrapper": {
    position: "absolute",
    left: theme.spacing(2),
    top: `-${theme.spacing(2)}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: blueGrey[800],
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    right: theme.spacing(2),
    width: "auto",
    "& .MuiStepLabel-label": {
      color: blueGrey[100],
      "&.Mui-active,&.Mui-completed": {
        color: "#fff",
      },
    },
    "& .MuiStepLabel-alternativeLabel": {
      marginTop: 2,
    },
    "& .MuiStepIcon-root": {
      color: blueGrey[100],
      "& .MuiStepIcon-text": {
        display: "none",
      },
      "&.Mui-active,&.Mui-completed": {
        color: "#fff",
      },
    },
  },
  "& .sectionBody": {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(6),
    minHeight: 400,
  },
}));

export default function PageJobCreate(routeProps: RouteComponentProps<any>) {
  const {
    match: {
      params: { modelId, modelName },
    },
  } = routeProps;
  const dispatch = useDispatch();

  useFetchSolversPageHook({
    pageNumber: 1,
    pageSize: 100,
    minPageNumberToFetch: 1,
  });

  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));
  const [fetchingModel, setFetchingModel] = useState(true);
  const [fetchingAssetInputFields, setFetchingAssetInputFields] = useState(true);
  const [fetchingAssetInputFieldListValues, setFetchingAssetInputFieldListValues] = useState(true);
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state)) && false;

  useEffect(() => {
    (async () => {
      await dispatch(fetchModelByIdIfNeeded(modelId));
      setFetchingModel(false);
    })();

    (async () => {
      await dispatch(fetchSearchAssetInputFields({ modelId, pageNumber: 1, pageSize: 500 }));
      setFetchingAssetInputFields(false);
    })();

    (async () => {
      await dispatch(fetchSearchAssetInputFieldListValues({ modelId, pageNumber: 1, pageSize: 500 }));
      setFetchingAssetInputFieldListValues(false);
    })();
  }, [dispatch, modelId]);

  return (
    <ContainerWrapper maxWidth="lg">
      <Grid container>
        <Grid item xs={12} className="breadcrumbWrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIconLink />
            <Link to={"/Search"} component={RouterLink} color="inherit" key={`breadcrumb-models`}>
              <Typography noWrap>Search</Typography>
            </Link>
            <Link
              to={`/Search/${modelName}/${modelId}`}
              component={RouterLink}
              color="inherit"
              key={`breadcrumb-specific-model`}
            >
              <Typography noWrap>{model?.title || "Model"}</Typography>
            </Link>
            <Link color="textPrimary" aria-current="page" underline="none">
              Create Solver Job
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} id="job-create-wrapper">
          {fetchingModel || fetchingAssetInputFields || fetchingAssetInputFieldListValues ? (
            <LoaderAbsoluteCentred loading={true} />
          ) : null}

          {!modelId || !model ? (
            <WidgetNoResultsPlaceholder icon={NoResultsIcon} text="Model not found" fade={true} />
          ) : (
            <JobCreateWizard modelId={modelId} canEdit={isAdmin} />
          )}
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
}

interface IJobCreateWizard {
  modelId: string;
  canEdit: boolean;
}

const jobWizardStepsArray = [
  {
    label: "Model Inputs",
    value: WizardStep.Customise,
  },
  {
    label: "Specific Asset",
    value: WizardStep.Review,
  },
  {
    label: "Solver Job",
    value: WizardStep.SolverJob,
  },
  {
    label: "Outputs",
    value: WizardStep.Reports,
  },
];

function JobCreateWizard({ modelId, canEdit }: IJobCreateWizard) {
  useEffect(() => InitJobCreateState(modelId, canEdit), [modelId, canEdit]);

  const currentWizardStep = useJobCreateState((s) => s.step);
  const useSummaryReports = useJobCreateSummaryReports();
  const assetInputValuesObject = useJobCreateState((s) => s.assetInputValuesObject);

  const assetInputFields = useSelector((store: RootState) => selectorGetAssetInputFieldsByModelId(store, modelId));

  // Create a base object that is used to track user input field changes
  useEffect(() => {
    var assignedFields = Object.values(assetInputValuesObject);
    if (!assignedFields.length && assetInputFields.length) {
      if (USE_DEV_DEFAULTS) {
        useJobCreateState.setState({ assetInputValuesObject: AssetInputValuesObjectDefault });
        return;
      }

      var newValuesObject = {};
      for (let field of assetInputFields) {
        let value = getAssetDefaultFieldValue(field);
        newValuesObject = { ...newValuesObject, [field.assetInputFieldId]: value };
      }
      useJobCreateState.setState({ assetInputValuesObject: newValuesObject });
    }
  }, [assetInputFields, assetInputValuesObject]);

  const setStep = (step: WizardStep) => () => useJobCreateState.setState({ step });
  const setPrevStep = useJobCreateState((s) => s.setPrevStep);

  const history = useHistory();
  const gotoDashboard = () => history.push(GetDashboardPage());

  const handleOnClickStep = (stepKey: WizardStep) => {
    if (stepKey === WizardStep.Customise && currentWizardStep > WizardStep.Customise) {
      setPrevStep(WizardStep.Customise);
    } else if (stepKey === WizardStep.Review && currentWizardStep > WizardStep.Review) {
      setPrevStep(WizardStep.Review);
    } else if (stepKey === WizardStep.SolverJob && currentWizardStep > WizardStep.SolverJob) {
      setPrevStep(WizardStep.SolverJob);
    }
  };

  const isStepButtonDisabled = (stepKey: WizardStep): boolean => {
    if (stepKey === WizardStep.Review) {
      return currentWizardStep > stepKey && !useSummaryReports;
    } else {
      return currentWizardStep > stepKey;
    }
  };

  return (
    <Paper className="wrapper">
      <div className="steps-nav-wrapper">
        <Stepper nonLinear activeStep={currentWizardStep - 1} alternativeLabel>
          {jobWizardStepsArray.map(({ label, value }) => (
            <Step key={value} active={value <= currentWizardStep}>
              <StepButton
                icon={null}
                color="inherit"
                disabled={!isStepButtonDisabled(value)}
                onClick={() => handleOnClickStep(value)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="sectionBody">
        {(() => {
          switch (currentWizardStep) {
            case WizardStep.Customise:
              return <JobCreateCustomiseStep onCompleteCallback={setStep(WizardStep.Review)} />;
            case WizardStep.Review:
              return <JobCreateReviewStep onCompleteCallback={setStep(WizardStep.SolverJob)} />;
            case WizardStep.SolverJob:
              return <JobCreateSolverJobStep onCompleteCallback={setStep(WizardStep.Reports)} />;
            case WizardStep.Reports:
              return <JobCreateReportsStep onCompleteCallback={gotoDashboard} />;
            default:
              return <Typography>Invalid step.</Typography>;
          }
        })()}
      </div>
    </Paper>
  );
}
