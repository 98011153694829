import { ActionTypesAssetInputFieldCategory, AssetInputFieldCategoryState, ACTIONS_ASSET_INPUT_FIELD_CATEGORY } from "./types";

const initialState: AssetInputFieldCategoryState = {
    byId: {},
}


export function assetInputFieldCategoriesReducer(
    state = initialState,
    action: ActionTypesAssetInputFieldCategory
): AssetInputFieldCategoryState {
    switch(action.type){
        case ACTIONS_ASSET_INPUT_FIELD_CATEGORY.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_ASSET_INPUT_FIELD_CATEGORY.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_ASSET_INPUT_FIELD_CATEGORY.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}