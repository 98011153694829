import { FormControl, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { DEFAULT_DECIMAL_PLACES, useDecimalValue } from "../../utilities/inputFields/numberFieldUtils";
import { IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";

interface IAssetInputFieldDisplayNumberProps {
  assetInputField: IAssetInputField;
  readOnly?: boolean;
  onValueChangeCallback(fieldId: string, value: TAssetInputFieldValue): void;
  defaultValueOverride?: TAssetInputFieldValue;
}

function AssetInputFieldDisplayNumber({
  assetInputField,
  defaultValueOverride,
  onValueChangeCallback,
  readOnly = false,
}: IAssetInputFieldDisplayNumberProps) {
  const defaultValue = defaultValueOverride?.value || assetInputField.defaultValue;
  const decimalPlaces = assetInputField.decimalPlaces || DEFAULT_DECIMAL_PLACES;

  const [error, setError] = useState("");
  const [value, setValue] = useDecimalValue(defaultValue, decimalPlaces);

  function changeValue(e: any) {
    if (isNaN(e.target.value)) {
      setError("A valid number is required.");
      return;
    }

    setValue(e.target.value); // Allow empty values

    var value = Number(e.target.value);
    if (value < assetInputField.minValue || value > assetInputField.maxValue) {
      setError(`Must be a value between ${assetInputField.minValue} and ${assetInputField.maxValue}`);
      return;
    }

    setError("");
    onValueChangeCallback(assetInputField.assetInputFieldId, { value });
  }

  return (
    <FormControl fullWidth>
      <InputLabel style={{ position: "relative", transform: "translate(0, 1.5px) scale(0.75)" }}>
        {assetInputField.description}
      </InputLabel>
      <TextField
        type="number"
        inputProps={{
          min: assetInputField.minValue,
          max: assetInputField.maxValue,
          step: "any",
        }}
        value={readOnly ? defaultValue : value}
        variant="standard"
        onChange={changeValue}
        error={!!error}
        helperText={error}
        fullWidth
        disabled={readOnly}
      />
    </FormControl>
  );
}

export default AssetInputFieldDisplayNumber;
