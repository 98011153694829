import { ACTIONS_SOLVER_INPUT_FIELD, SolverInputFieldSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ISolverInputField, SolverInputFieldTypeEnum } from '../../utilities/types/SolverInputField';


export const receiveSolverInputFields = (solverInputFields: ISolverInputField[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverInputField } = {};

    for (var i = 0; i < solverInputFields.length; i++) {
        byIdObjectToDispatch[solverInputFields[i].solverInputFieldId] = solverInputFields[i];
    }

    return {
        type: ACTIONS_SOLVER_INPUT_FIELD.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverInputField = (solverInputField: ISolverInputField) => ({
    type: ACTIONS_SOLVER_INPUT_FIELD.DELETE,
    byId: { [solverInputField.solverInputFieldId]: solverInputField }
});


export interface IFetchCreateSolverInputFieldProps {
    solverId: string,
    label: string,
    description?: string,
    defaultValue: number,
    minValue?: number,
    maxValue?: number,
    orderNumber: number,
    type: SolverInputFieldTypeEnum,
}


export const fetchCreateSolverInputField = (solverInputFieldToCreate: IFetchCreateSolverInputFieldProps): AppThunk<Promise<ISolverInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverInputFields`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(solverInputFieldToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputField) {
            dispatch(receiveSolverInputFields([parsedResp.data.solverInputField]));
            return parsedResp.data.solverInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSolverInputFieldProps {
    solverInputFieldId: string,
    label: string,
    description?: string,
    defaultValue: number,
    defaultString?: string,
    defaultDateTime?: Date,
    minValue?: number,
    maxValue?: number,
    decimalPlaces?: number,
    orderNumber: number,
    type: SolverInputFieldTypeEnum,
}


export const fetchUpdateSolverInputField = (props: IFetchUpdateSolverInputFieldProps): AppThunk<Promise<ISolverInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverInputFields`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputField) {
            dispatch(receiveSolverInputFields([parsedResp.data.solverInputField]));
            return parsedResp.data.solverInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchSolverInputFieldsProps {
    pageNumber: number,
    pageSize: number,
    solverId?: string,
    text?: string,
    createdBy?: string,
    orderType?: SolverInputFieldSearchOrderTypeEnum,
}


export const fetchSearchSolverInputFields = (searchParams: IFetchSearchSolverInputFieldsProps): AppThunk<Promise<ISolverInputField[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverInputFields`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputFields) {
            dispatch(receiveSolverInputFields(parsedResp.data.solverInputFields));
            return parsedResp.data.solverInputFields;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching input field.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSolverInputFieldProps {
    solverInputFieldId: string,
}


export const fetchDeleteSolverInputField = (props: IFetchDeleteSolverInputFieldProps): AppThunk<Promise<ISolverInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverInputFields`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverInputField) {
            dispatch(requestDeleteSolverInputField(parsedResp.data.solverInputField));
            return parsedResp.data.solverInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}