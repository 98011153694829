import { EntityTypeEnum } from "./Entity";

export interface IDiscussion {
  discussionId: string;
  entityType: EntityTypeEnum;
  entityId: string;
  title: string;
  text: string;
  status: DiscussionStatusEnum;
  commentCount: number;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
}

export enum DiscussionSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
  LastCommentDateDesc = 30,
}

export enum DiscussionStatusEnum {
  Active = 10,
  Deleted = 20,
  Resolved = 30,
}
