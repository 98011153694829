import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchFunctionsProps, fetchSearchFunctions } from "../../redux/function/actions";
import { IFunction } from "../../utilities/types/Function";
import { IFetchSearchAiSuggestFunctionProps, fetchSearchAiSuggestFunction } from "../../redux/aiSuggestFunction/action";

interface IUseFetchFunctionsPageHookProps extends IFetchSearchFunctionsProps {
  minPageNumberToFetch: number;
}

export const useFetchFunctionsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchFunctionsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IFunction[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IFunction[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var functions = (await dispatch(
          fetchSearchFunctions({
            pageSize,
            pageNumber,
            modelId,
          })
        )) as unknown as IFunction[];

        if (functions && functions.length) {
          setMorePages(functions.length >= pageSize);
          setLastResultSet(functions);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.functionId === x.functionId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};

interface IUseFetchAiSuggestFunctionPageHookProps extends IFetchSearchAiSuggestFunctionProps {
  minPageNumberToFetch: number;
}

export interface ISelectedFunction extends IFunction {
  selected?: boolean;
}

export const useFetchAiSuggestFunctionPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId, componentId }: IUseFetchAiSuggestFunctionPageHookProps) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IFunction[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<ISelectedFunction[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        const functions = (await fetchSearchAiSuggestFunction({
          pageSize,
          pageNumber,
          modelId,
          componentId,
        })) as unknown as IFunction[];

        if (functions && functions.length) {
          setMorePages(functions.length >= pageSize);
          setLastResultSet(functions);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, pageNumber, pageSize, modelId, componentId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.functionId === x.functionId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
