import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";

import FeedbackIcon from "@mui/icons-material/FeedbackRounded";
import FeedbackSubmittedIcon from "@mui/icons-material/CheckCircle";
import ModalFeedbackCreate from "../feedback/modals/ModalFeedbackCreate";
import { IFeedback } from "../../utilities/types/Feedback";


const FeedbackBtn = styled(Button)(({ theme }) => ({
  backgroundColor: green[500],
  color: "#FFF",
  textTransform: "capitalize",
  marginRight: theme.spacing(3),
  transition: "background 200ms ease-out",
  textShadow: "1px 1px 1px #00000052",
  "&:hover": {
    backgroundColor: green[400],
  },
  "& .feedbackIcon" :{
    marginLeft: 8,
    fontSize: 18,
  }
}));


const FeedbackButton = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const toggleShowFeedbackModal = () => setShowFeedbackModal(!showFeedbackModal);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const onCompleteCallback = (feedback?: IFeedback) => {
    if (feedback) setFeedbackSubmitted(true);
    toggleShowFeedbackModal();
  };

  const openFeedbackModal = () => {
    setShowFeedbackModal(true);
    setFeedbackSubmitted(false);
  };

  return (
    <>
      {showFeedbackModal ? (
        <ModalFeedbackCreate
          open={true}
          onCancelCallback={toggleShowFeedbackModal}
          onCompleteCallback={onCompleteCallback}
          titleText={undefined}
          subtitleText={undefined}
          feedbackType={undefined}
          feedbackText={undefined}
        />
      ) : null}

      <FeedbackBtn id="btn-feedback" variant="outlined" onClick={openFeedbackModal}>
        {feedbackSubmitted ? (
          <>
            Submitted <FeedbackSubmittedIcon className="feedbackIcon" />
          </>
        ) : (
          <>
            Feedback <FeedbackIcon className="feedbackIcon" />
          </>
        )}
      </FeedbackBtn>
    </>
  );
};
export default FeedbackButton;
