import { ActionTypesUserRolePermission, UserRolePermissionState, ACTIONS_USER_ROLE_PERMISSION } from "./types";

const initialState: UserRolePermissionState = {
    byId: {},
}

export function userRolePermissionsReducer(
    state = initialState,
    action: ActionTypesUserRolePermission
): UserRolePermissionState {
    switch (action.type) {
        case ACTIONS_USER_ROLE_PERMISSION.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_USER_ROLE_PERMISSION.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        case ACTIONS_USER_ROLE_PERMISSION.DELETE: {
            
            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        }
        default:
            return state;
    }
}