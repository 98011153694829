import { ACTIONS_COST_BUNDLE, CostBundleSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { ICostBundle } from '../../utilities/types/CostBundle';


export const receiveCostBundles = (costBundles: ICostBundle[]) => {

    var byIdObjectToDispatch: { [key: string]: ICostBundle } = {};

    for (var i = 0; i < costBundles.length; i++) {
        byIdObjectToDispatch[costBundles[i].costBundleId] = costBundles[i];
    }

    return {
        type: ACTIONS_COST_BUNDLE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteCostBundle = (costBundle: ICostBundle) => ({
    type: ACTIONS_COST_BUNDLE.DELETE,
    byId: { [costBundle.costBundleId]: costBundle }
});


export interface IFetchCreateCostBundleProps {
    modelId: string,
    name: string,
    code: string,
    orderNumber: number,
}
export interface IFetchCreateCostBundlesProps {
    costBundles: IFetchCreateCostBundleProps[]
}




export const fetchCreateCostBundle = (costBundlesToCreate: IFetchCreateCostBundlesProps): AppThunk<Promise<ICostBundle>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/costBundles`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(costBundlesToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.costBundles) {
            dispatch(receiveCostBundles(parsedResp.data.costBundles));
            return parsedResp.data.costBundles;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating costBundle.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating costbundle.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateCostBundleProps {
    costBundleId: string,
    name: string,
    code: string,
    cost: number,
    orderNumber: number,
}


export const fetchUpdateCostBundle = (props: IFetchUpdateCostBundleProps): AppThunk<Promise<ICostBundle>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/costBundles`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.costBundles) {
            dispatch(receiveCostBundles(parsedResp.data.costBundles));
            return parsedResp.data.costBundles;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating costBundle.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating costbundle.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchCostBundlesProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    costBundleId?: string,
    text?: string,
    createdBy?: string,
    orderType?: CostBundleSearchOrderTypeEnum,
}


export const fetchSearchCostBundles = (searchParams: IFetchSearchCostBundlesProps): AppThunk<Promise<ICostBundle[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/costBundles`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.costBundles) {
            dispatch(receiveCostBundles(parsedResp.data.costBundles));
            return parsedResp.data.costBundles;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching costBundle.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching costbundle.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteCostBundleProps {
    costBundleId: string,
}


export const fetchDeleteCostBundle = (props: IFetchDeleteCostBundleProps): AppThunk<Promise<ICostBundle>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/costBundles`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.costBundles && parsedResp.data.costBundles.length) {
            dispatch(requestDeleteCostBundle(parsedResp.data.costBundles[0]));
            return parsedResp.data.costBundles[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting costBundle.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting costbundle.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}