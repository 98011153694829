import { RootState } from "..";
import { ISolverJobTask } from "../../utilities/types/SolverJobTask";


export function selectorGetSolverJobTasksByJobId(state: RootState, solverJobId: string) : ISolverJobTask[] {

    if (!state.solverJobTasks || !state.solverJobTasks.byId) return [];

    return sortSolverJobsByCreatedDescending(Object.values(state.solverJobTasks.byId).filter(x => x.solverJobId === solverJobId));
}

export function selectorGetSolverJobTaskById(state: RootState, solverJobTaskId: string) : ISolverJobTask | undefined {

    if (!state.solverJobTasks || !state.solverJobTasks.byId) return undefined;

    return state.solverJobTasks.byId[solverJobTaskId];
}

function sortSolverJobsByCreatedDescending(solverJobTasks: ISolverJobTask[]) {
    return solverJobTasks.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}
