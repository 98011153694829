import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ISolverInputField } from "../../utilities/types/SolverInputField";
import { useFetchSolverInputFieldListValuesPageHook } from "../solverInputFieldListValue/Hooks";
import {
  selectorGetSolverInputFieldsBySolverId,
  selectorGetSolverInputFieldTableNamesBySolverId,
} from "../../redux/solverInputField/selectors";
import SolverInputFieldDisplay from "./SolverInputFieldDisplay";
import { RootState } from "../../redux";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import NoResultsIcon from "@mui/icons-material/InputTwoTone";
import { useFetchSolverInputFieldsPageHook } from "./Hooks";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import EditIcon from "@mui/icons-material/EditTwoTone";
import ModalSolverInputFieldUpdate from "./modals/ModalSolverInputFieldUpdate";

interface ISolverInputFieldsListProps {
  solverId: string;
  canEdit: boolean;
}

const SolverInputFieldsList = ({ solverId, canEdit }: ISolverInputFieldsListProps) => {
  const solverInputFields = useSelector((store: RootState) => selectorGetSolverInputFieldsBySolverId(store, solverId));
  const solverInputFieldTableNames = useSelector((store: RootState) =>
    selectorGetSolverInputFieldTableNamesBySolverId(store, solverId)
  );

  const { fetching: fetchingFields } = useFetchSolverInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 100,
    minPageNumberToFetch: 1,
    solverId,
  });

  const { fetching: fetchingFieldListValues } = useFetchSolverInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    minPageNumberToFetch: 1,
    solverId,
  });

  return (
    <SolverInputFieldsListDisplay
      solverId={solverId}
      canEdit={canEdit}
      solverInputFields={solverInputFields}
      solverInputFieldTableNames={solverInputFieldTableNames}
      loading={fetchingFields || fetchingFieldListValues}
      enableInputs={false}
    />
  );
};

type SolverInputFieldsSectionDisplayProps = {
  solverId: string;
  canEdit: boolean;
  solverInputFields: ISolverInputField[];
  solverInputFieldTableNames: string[];
  loading: boolean;
  enableInputs: boolean;
};

const SolverInputFieldsListDisplay = ({
  solverId,
  loading,
  canEdit,
  enableInputs,
  solverInputFields,
  solverInputFieldTableNames,
}: SolverInputFieldsSectionDisplayProps) => {
  const [solverInputFieldToUpdate, setSolverInputFieldToUpdate] = useState<ISolverInputField>();

  function closeEditModal() {
    setSolverInputFieldToUpdate(undefined);
  }

  if (!solverId) return null;

  return (
    <Grid container spacing={1}>
      {!solverInputFields.length ? (
        <Grid item xs={12}>
          <WidgetNoResultsPlaceholder icon={NoResultsIcon} text="No solver fields" fade={true} spin={true} />
        </Grid>
      ) : null}

      {solverInputFields.map((field) => {
        if (field.tableName) return null;

        return (
          <React.Fragment key={field.solverInputFieldId}>
            <Grid item xs={9}>
              <SolverInputFieldDisplay solverInputField={field} disabled={true} onValueChangeCallback={() => {}} />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <IconButton
                onClick={() => setSolverInputFieldToUpdate(field)}
                style={{ border: "1px solid #EEE", marginBottom: -40 }}
              >
                <EditIcon style={{ fontSize: 15, opacity: 0.7 }} />
              </IconButton>
            </Grid>
          </React.Fragment>
        );
      })}
      {solverInputFieldTableNames.map((x, index) => {
        var tableFields = solverInputFields
          .filter((y) => y.tableName === x)
          .sort((a, b) => a.orderNumber - b.orderNumber);

        return (
          <Grid key={index + "grid-solver"} item xs={12}>
            <Typography variant="overline" component="p" style={{ marginBottom: 8 }}>
              {x}
            </Typography>
            <Grid container spacing={1}>
              {tableFields.map((tableField, tableIdx) => (
                <React.Fragment key={tableField.solverId + index + tableIdx}>
                  <Grid item xs={9}>
                    <SolverInputFieldDisplay
                      solverInputField={tableField}
                      disabled={true}
                      onValueChangeCallback={() => {}}
                    />
                  </Grid>

                  <Grid item xs={3} style={{ textAlign: "right" }}>
                    <IconButton
                      onClick={() => setSolverInputFieldToUpdate(tableField)}
                      style={{ border: "1px solid #EEE", marginBottom: -40 }}
                    >
                      <EditIcon style={{ fontSize: 15, opacity: 0.7 }} />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        );
      })}

      <LoaderAbsoluteCentred loading={loading} />
      {solverInputFieldToUpdate !== undefined ? (
        <ModalSolverInputFieldUpdate
          open={true}
          onCancelCallback={closeEditModal}
          onCompleteCallback={closeEditModal}
          solverInputField={solverInputFieldToUpdate}
          canEdit={canEdit}
        />
      ) : null}
    </Grid>
  );
};

export default SolverInputFieldsList;
