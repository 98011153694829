import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import BuildIcon from "@mui/icons-material/FunctionsOutlined";
import FormFunctionAiSuggest from "../forms/FormFunctionAiSuggest";

export interface IModalAiSuggestFunctionProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  componentId: string;
  onCompleteCallback(): void;
}

function ModalAiSuggestFunction({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  componentId,
}: IModalAiSuggestFunctionProps) {
  return (
    <WidgetModalBase
      style={{ maxWidth: 900, width: "100%" }}
      handleCancel={onCancelCallback}
      open={open}
      title="AI Suggestions (Beta)"
      subtitle=""
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormFunctionAiSuggest
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          componentId={componentId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalAiSuggestFunction;
