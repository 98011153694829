import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/Build";
import FormAssetInputFieldCreate from "../forms/FormAssetInputFieldCreate";
import { IAssetInputField } from "../../../utilities/types/AssetInputField";

export interface IModalAssetInputFieldCreateProps {
  open: boolean;
  onCancelCallback(): void;
  assetInputFieldCategoryId: string;
  modelId: string;
  onCompleteCallback(assetInputFields?: IAssetInputField[]): void;
}

function ModalAssetInputFieldCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  assetInputFieldCategoryId,
}: IModalAssetInputFieldCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new field"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<BuildIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <FormAssetInputFieldCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          assetInputFieldCategoryId={assetInputFieldCategoryId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalAssetInputFieldCreate;
