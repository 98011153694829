export function displayFormattedDate(date: Date | string | null | undefined, fallbackMessage: string = "NA"): string {
  if (!date) return fallbackMessage;

  return new Date(date).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
