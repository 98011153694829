import React from "react";
import { Collapse, Card as MuiCard, Typography, styled, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ImageWithFallback } from "../generic/ImageWithFallback";
import { ISingleAssetOutput } from "../../utilities/types/SingleAssetOutput";
import moment from "moment";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";

const Card = styled(MuiCard)(({ theme }) => ({
  overflow: "unset",
  marginTop: `${theme.spacing(4)} !important`,
  ".inner-wrapper": {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: theme.spacing(2),
    flexWrap: "wrap",
    ".iconContainer": {
      position: "absolute",
      left: 0,
      top: "-28px",
      zIndex: 11,
      display: "grid",
      placeContent: "center",
      height: "75px",
      width: "75px",
      backgroundColor: "#37474f",
      borderRadius: "4px",
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      svg: {
        color: "#FFF",
        width: "1.3em",
        height: "1.3em",
      },
    },
    ".icon-btn": {
      backgroundColor: "#FFF",
      border: "1px solid #EEE",
    },
    ".content-wrapper": {
      cursor: "pointer",
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      gap: 16,
      flex: 1,
      flexShrink: 0,
      position: "relative",
      paddingLeft: theme.spacing(11),
    },
    ".right-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
  },
  ".card-img": {
    width: 75,
    height: 75,
    borderRadius: 3,
    objectFit: "cover",
  },
  ".collapse-wrapper": {},
}));

interface OutputCollapseCardProps {
  showCollapse: boolean;
  setShowCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  singleAssetOutput?: ISingleAssetOutput;
  children: React.ReactNode;
}

const OutputCollapseCard = ({
  showCollapse,
  setShowCollapse,
  singleAssetOutput,
  children,
}: OutputCollapseCardProps) => {
  const toggleCollapse = () => setShowCollapse((prev) => !prev);

  const imageUrl = useFileSourceSingleHook({
    fileId: singleAssetOutput && singleAssetOutput.mainImageId ? singleAssetOutput.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  return (
    <Card>
      <div className="inner-wrapper">
        <div className="content-wrapper" onClick={toggleCollapse}>
          <div className="iconContainer">
            <ImageWithFallback className="card-img" src={imageUrl} />
          </div>
          <div style={{ flex: 1 }}>
            <Typography fontWeight={600}>{singleAssetOutput?.name || "SingleAssetOutput"}</Typography>

            <Typography component="span" variant="body2" className="inline" color="textPrimary">
              Last updated {moment.utc(singleAssetOutput?.updated).fromNow()}
            </Typography>
          </div>
        </div>

        <div className="right-wrapper">
          <IconButton aria-label="collapse" aria-controls="collapse-menu" aria-haspopup="true" onClick={toggleCollapse}>
            {showCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      <div>
        <Collapse className={showCollapse ? "collapse-wrapper" : ""} in={showCollapse}>
          {children}
        </Collapse>
      </div>
    </Card>
  );
};

export default OutputCollapseCard;
