import React, { useState } from "react";
import { IconButton as MuiIconButton, styled } from "@mui/material";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import UploadIcon from "@mui/icons-material/CloudUpload";
import ModalFileUpload from "./modals/ModalFileUpload";

export interface IFileUploadIconProps {
  entityId: string;
  entityType: EntityTypeEnum;
  description?: string;
  fileType?: FileTypeEnum;
  onUploadComplete?(): void;
  styles?: {};
  imageHeight?: number;
  imageWidth?: number;
}

const IconButton = styled(MuiIconButton)({
  position: "absolute",
  left: "50%",
  top: "50%",
  color: "#FFF",
  zIndex: 2,
  backgroundColor: "rgba(0,0,0,0.1)",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "2px 2px 2px rgba(0,0,0,0.2)",
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  "&:active": {
    cursor: "pointer",
    boxShadow: "inset 2px 2px 2px rgba(0,0,0,0.2)",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
});

function FileUploadIcon({
  entityId,
  entityType,
  description,
  fileType,
  onUploadComplete,
  styles,
  imageHeight,
  imageWidth,
}: IFileUploadIconProps) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const internalUploadComplete = () => {
    toggleOpen();
    if (onUploadComplete) onUploadComplete();
  };

  return (
    <>
      <IconButton
        onClick={toggleOpen}
        color="secondary"
        aria-label="Upload"
        className="uploadButton"
        size="medium"
        style={styles}
      >
        <UploadIcon />
      </IconButton>
      <ModalFileUpload
        {...{ entityId, entityType, description, fileType, open, imageHeight, imageWidth }}
        onUploadCompleteCallback={internalUploadComplete}
        handleCancel={toggleOpen}
      />
    </>
  );
}

export default FileUploadIcon;
