import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import HeaderIcon from "@mui/icons-material/BubbleChartTwoTone";
import { DialogActions, Button, Divider } from "@mui/material";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { useFetchSolverSummaryOutputTypeMappingsPageHook } from "../Hooks";
import { ISummaryOutputType } from "../../../utilities/types/SummaryOutputType";
import { fetchCreateSolverSummaryOutputTypeMapping } from "../../../redux/solverSummaryOutputTypeMapping/actions";
import { useDispatch, useSelector } from "react-redux";
import SolverSummaryOutputTypePickList from "../SolverSummaryOutputTypePickList";
import { selectorGetSolverSummaryOutputTypeMappingsBySolverId } from "../../../redux/solverSummaryOutputTypeMapping/selectors";
import { selectorGetSummaryOutputTypes } from "../../../redux/summaryOutputType/selectors";
import { RootState } from "../../../redux";
import { useFetchSummaryOutputTypesPageHook } from "../../summaryOutputType/Hooks";

export interface IModalSolverSummaryOutputTypeMappingsProps {
  open: boolean;
  onCancelCallback(): void;
  solverId: string;
}

function ModalSolverSummaryOutputTypeMappings({
  onCancelCallback,
  open,
  solverId,
}: IModalSolverSummaryOutputTypeMappingsProps) {
  const dispatch = useDispatch();
  const [selectedSolverSummaryOutputTypes, setSelectedSummaryOutputTypes] = useState<ISummaryOutputType[]>([]);
  const [saving, setSaving] = useState(false);
  const { fetching: fetchingValues } = useFetchSolverSummaryOutputTypeMappingsPageHook({
    minPageNumberToFetch: 0,
    pageNumber: 1,
    pageSize: 500,
    solverId: solverId,
  });

  const { fetching: fetchingOValues } = useFetchSummaryOutputTypesPageHook({
    minPageNumberToFetch: 0,
    pageNumber: 1,
    pageSize: 500,
  });

  const summaryOutputTypes = useSelector((store: RootState) => selectorGetSummaryOutputTypes(store));
  const summaryOutputTypeMappings = useSelector((store: RootState) =>
    selectorGetSolverSummaryOutputTypeMappingsBySolverId(store, solverId)
  );

  async function onSaveClick() {
    setSaving(true);

    var resp = await dispatch(
      fetchCreateSolverSummaryOutputTypeMapping({
        solverId,
        summaryOutputTypeIds: selectedSolverSummaryOutputTypes.map((x) => x.summaryOutputTypeId),
      })
    );

    setSaving(false);
    if (resp !== null) onCancelCallback();
  }

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Manage solver output type mappings"}
      subtitle={"Configure output types that can be used with this solver."}
      headerIcon={<HeaderIcon />}
      style={{ minWidth: 475 }}
    >
      <DialogContent style={{ marginBottom: 16 }}>
        <SolverSummaryOutputTypePickList
          summaryOutputTypes={summaryOutputTypes}
          summaryOutputTypeMappings={summaryOutputTypeMappings}
          solverId={solverId}
          onSelectedItemsChange={setSelectedSummaryOutputTypes}
        />
        <LoaderAbsoluteCentred loading={fetchingValues || fetchingOValues || saving} />
      </DialogContent>
      <Divider light={true} />
      <DialogActions style={{ marginTop: 8 }}>
        <Button
          
          variant="outlined"
          // style={{ display: 'flex', flexGrow: 1 }}
          onClick={onCancelCallback}
          fullWidth={true}
        >
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          // style={{ display: 'flex', flexGrow: 1 }}
          onClick={onSaveClick}
          fullWidth={true}
        >
          Submit
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

export default ModalSolverSummaryOutputTypeMappings;
