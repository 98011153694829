import { ActionTypesTaskImpact, TaskImpactState, ACTIONS_TASK_IMPACT } from "./types";

const initialState: TaskImpactState = {
    byId: {},
}


export function taskImpactsReducer(
    state = initialState,
    action: ActionTypesTaskImpact
): TaskImpactState {
    switch(action.type){
        case ACTIONS_TASK_IMPACT.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_TASK_IMPACT.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_TASK_IMPACT.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}