import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/Input";
import FormSolverInputFieldUpdate from "../forms/FormSolverInputFieldUpdate";
import { ISolverInputField } from "../../../utilities/types/SolverInputField";

export interface IModalSolverInputFieldUpdateProps {
  open: boolean;
  solverInputField: ISolverInputField;
  onCancelCallback(): void;
  onCompleteCallback(solverInputField?: ISolverInputField): void;
  canEdit: boolean;
}

function ModalSolverInputFieldUpdate({
  onCancelCallback,
  open,
  canEdit,
  onCompleteCallback,
  solverInputField,
}: IModalSolverInputFieldUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <FormSolverInputFieldUpdate
          canEdit={canEdit}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          solverInputField={solverInputField}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalSolverInputFieldUpdate;
